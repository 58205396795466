import React from "react";
import useFirestoreRealtimeDoc from "../hooks/useFirestoreRealtimeDoc";
import { db } from "../config/firebase";
import { useAuth } from "./AuthProvider";

interface GamificationContextInterface {
  template?: {
    version: number;
    card_categories: {
      id: string;
      name: string;
      long_name: string;
      description: string;
    }[];
    cards: {
      id: string;
      name: string;
      description: string;
      multiplier: number;
      status: string;
      category: string;
      duration?: number;
      unit_of_measurement?: string;
      completion_threshold?: number;
      default_user_status?: "active" | "inactive";
    }[];
  };
  userGamificationData?: {
    cards: {
      exp_at: number;
      id: string;
      progress: number;
      status: "active" | "inactive";
    }[];
  };
}

export const GamificationContext = React.createContext(
  {} as GamificationContextInterface
);

const GamificationProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();

  const { data: template } = useFirestoreRealtimeDoc({
    deps: [],
    query: db.collection("gamification_templates").doc("base"),
    select: (doc) => doc?.data() as any,
  });
  const { data: userGamificationData } = useFirestoreRealtimeDoc({
    deps: [template, user?.uid],
    query: db.collection("gamification_data").doc(user?.uid || "-"),
    select: (doc) => doc?.data() as any,
  });

  return (
    <GamificationContext.Provider value={{ userGamificationData, template }}>
      {children}
    </GamificationContext.Provider>
  );
};

export default GamificationProvider;
