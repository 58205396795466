import { Location } from "./Location"

export class Corporate {

    constructor(data: any) {
        Object.assign(this, data)

        this.branches = data?.branches?.map((branch: any) => new CorporateBranch(branch, data))
    }

    id!: string
    name!: string
    branches!: CorporateBranch[]
    activated_bonuses!: string[]
    status?: 'active' | 'inactive'
    subscription?: {
        id: string
        items: [
            {
                id: string,
                price: string,
                product: string
            }
        ],
        status: 'active' | 'inactive' | 'incomplete',
        cancel_at?: number
    }
}

export class CorporateBranch {

    constructor(data: any, corporateData: any) {
        Object.assign(this, data)
        this.corporateData = corporateData
    }

    name!: string
    location!: Location
    public?: boolean
    nft_enabled?: boolean
    type='corporate-branch'
    private corporateData: any

    get marker() {
        return { location: this.location, type: 'corporate-branch' as const, data: this }
    }

    getCorporate() {
        return new Corporate(this.corporateData)
    }
}