import { FC, useMemo } from "react";
import { TripDirection } from "../../../../assets/data/CarpoolsData";
import useCarpool from "../../../../hooks/useCarpool";
import { StatItem } from "../../../../pages/Statistics";
import { useAuth } from "../../../../providers/AuthProvider";
import { Carpool } from "../../../../types/Carpool";

interface Props {
  filteredCarpools?: Carpool[];
}
const CarpoolsToWork: FC<Props> = ({ filteredCarpools }): JSX.Element => {
  const { userData } = useAuth();
  const value = useMemo(
    () =>
      filteredCarpools?.filter(
        (c) => c.destination.id?.split(".")?.[0] === userData?.corporate
      ).length,
    [filteredCarpools, userData?.corporate]
  );

  return (
    <StatItem
      background="#D3E9B9"
      title="Carpools To Work"
      subTitle=""
      unit="Carpool"
      icon="car_side"
      decimalPoints={0}
      value={value}
    />
  );
};

export default CarpoolsToWork;
