import { ReactNode } from "react";
import colors from "../../assets/colors";
import { shadow } from "../../assets/styles";

const Card = ({ children, style, className }: { children?: ReactNode, style?: React.CSSProperties, className?: string }) => (
    <div
        className={className}
        style={{ ...shadow, borderRadius: 10, backgroundColor: colors.WHITE, display: 'flex', flexDirection: 'column', ...style }} >
        {children}
    </div>
)

export default Card