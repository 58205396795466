import ModalHeader from "../common/ModalHeader";
import { useAuth } from "../../providers/AuthProvider";
import ControlledInput from "../common/ControlledInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { Option } from "../common/Tabs";
import colors from "../../assets/colors";
import { useEffect, useMemo, useState } from "react";
import CustomCheckbox from "../common/CustomCheckbox";
import CustomButton from "../common/CustomButton";
import { BsApple } from "react-icons/bs";
import { Roles } from "../../types/Role";
import { Companies, Teams } from "../../assets/data/CarpoolsData";
import { getPortal } from "../../common/Utils";

type Fields = {
  full_name: string;
  email: string;
  password: string;
  "confirm-password": string;
  role: string;
};

const SignInSignUp = ({
  hide,
  hideHeader,
  onSuccess,
}: {
  hide?: () => void;
  hideHeader?: boolean;
  onSuccess?: () => void;
}) => {
  const { loginWithEmail, signUp, user, signInWithApple, signInWithGoogle } =
    useAuth();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<Fields>({
    defaultValues: {
      email: user?.email || "",
      role: Roles.ClientCarpoolingUser,
    },
  });

  const formData = watch();

  const [authOption, setAuthOption] =
    useState<(typeof options)[number]["value"]>("sign-in");

  const portal = useMemo(() => {
    if (authOption === "sign-in") return getPortal(formData.email);
  }, [formData.email, authOption]);

  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const onSubmit: SubmitHandler<Fields> = (data) => {
    if (authOption === "sign-in") {
      if (portal) {
        window.open(portal.url, "_self")
      } else {
        loginWithEmail.mutate(data, {
          onSuccess: () => {
            hide?.();
            onSuccess?.();
          },
        });
      }
    } else {
      signUp.mutate(
        {
          ...data,
          extra_user_data: { company: Companies.Company1, team: Teams.TeamA }, // dummy data
        },
        {
          onSuccess: () => {
            hide?.();
            onSuccess?.();
          },
        }
      );
    }
  };

  useEffect(()=>{
    if(user) hide?.()
  }, [user, hide])

  return (
    <div
      className="transition-all"
      style={{ margin: 0, marginBottom: 0, minWidth: 500 }}
    >
      {!hideHeader && (
        <ModalHeader
          style={{
            padding: 30,
            paddingBottom: 10,
            margin: -30,
            marginBottom: 0,
          }}
          hide={() => hide?.()}
          title={authOption === "sign-in" ? "Sign In" : "Sign Up"}
          subtitle={
            authOption === "sign-in"
              ? "Sign In with your carbon games account."
              : "Create a carbon games account."
          }
        />
      )}

      <div className="flex flex-col">
        {!user && (
          <Tabs options={options} value={authOption} setValue={setAuthOption} />
        )}

        {authOption === "sign-up" && !user && (
          <ControlledInput
            label="Full Name"
            inputProps={{ placeholder: "John Doe" }}
            containerStyle={{ marginBottom: 15 }}
            formProps={{
              register,
              errors,
              name: "full_name",
              options: { required: true },
            }}
          />
        )}

        <ControlledInput
          label="Email"
          inputProps={{
            placeholder: "john.doe@yourcorp.co",
            type: "email",
            disabled: !!user,
          }}
          containerStyle={{ marginBottom: user ? 0 : 15 }}
          formProps={{
            register,
            errors,
            name: "email",
            options: {
              required: true,
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            },
          }}
        />

        {!user && !portal && (
          <ControlledInput
            label="Password"
            inputProps={{
              placeholder: "--  --  --  --  --  --  --",
              type: "password",
            }}
            containerStyle={{ marginBottom: 15 }}
            formProps={{
              register,
              errors,
              name: "password",
              options: { required: true },
            }}
          />
        )}

        {authOption === "sign-up" && !user && (
          <ControlledInput
            label="Confirm Password"
            inputProps={{
              placeholder: "--  --  --  --  --  --  --",
              type: "password",
            }}
            containerStyle={{ marginBottom: 15 }}
            formProps={{
              register,
              errors,
              name: "confirm-password",
              options: { required: true },
            }}
          />
        )}

        {authOption === "sign-up" && !user && (
          <CustomCheckbox
            containerStyle={{ paddingBottom: 15 }}
            label="I agree to the Terms and Conditions"
            value={agreedToTerms}
            onClick={() => setAgreedToTerms((v) => !v)}
          />
        )}

        {!user && (
          <CustomButton
            loading={loginWithEmail.isLoading}
            style={{ marginTop: 15 }}
            label="Submit"
            onClick={handleSubmit(onSubmit)}
          />
        )}

        {!user && (
          <div className="h-[1px] bg-gray-200 my-8 flex justify-center items-center">
            <p className="px-1 bg-white text-gray-400">OR</p>
          </div>
        )}

        {!user && (
          <div className="flex gap-4 mb-4">
            <CustomButton
              loading={signInWithGoogle.isLoading}
              secondary
              style={{
                background: colors.WHITE,
                flex: 1,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: colors.BLACK_20,
              }}
              labelStyle={{ color: colors.BLACK_100 }}
              icon="google"
              label="Sign In With Google"
              onClick={() =>
                signInWithGoogle.mutate(undefined, { onSuccess: hide })
              }
            />

            <CustomButton
              loading={signInWithApple.isLoading}
              style={{ background: colors.BLACK_100, flex: 1 }}
              label="Sign In With Apple"
              IconComp={
                <BsApple style={{ color: colors.WHITE, fontSize: 14 }} />
              }
              onClick={() =>
                signInWithApple.mutate(undefined, { onSuccess: hide })
              }
            />
          </div>
        )}

        {authOption === "sign-in" && !user && (
          <p className="text-gray-400 text-xs text-center mt-2">
            You can use the same login information that was used to sign up for
            the Carbon Games mobile app, if applicable
          </p>
        )}
      </div>
    </div>
  );
};

export default SignInSignUp;

const Tabs = <TValue,>({
  options,
  value,
  setValue,
}: {
  options: Option<TValue>[];
  value: string;
  setValue: (v: TValue) => void;
}) => {
  return (
    <div className="flex gap-4 mb-4">
      {options.map((option, index) => (
        <Tab
          key={index}
          onClick={() => setValue(option.value)}
          selected={value === option.value}
          option={option}
        />
      ))}
    </div>
  );
};

const Tab = <TValue,>({
  option,
  onClick,
  selected,
}: {
  option: Option<TValue>;
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <div
      style={{ background: selected ? colors.PRIMARY : colors.WHITE }}
      onClick={onClick}
      className="border rounded flex-1 p-3 cursor-pointer"
    >
      <div>
        <p
          className="text-lg"
          style={{ color: selected ? colors.WHITE : colors.BLACK_100 }}
        >
          {option.label}
        </p>
        <p
          className="text-xs opacity-50"
          style={{ color: selected ? colors.WHITE : colors.BLACK_100 }}
        >
          {(option as any).subTitle}
        </p>
      </div>
    </div>
  );
};

const options = [
  { label: "Sign In", value: "sign-in", subTitle: "Already have an account" },
  { label: "Sign Up", value: "sign-up", subTitle: "Create new account" },
];
