import { Location } from "./Location"

export class Bounty  {

    constructor(data:  any){
        Object.assign(this, data)
    }

    claimants?:string[]
    events! : string[]
    id?: string
    event_bounties! : {
        event_id: string,
        amount: number,
        location : Location,
    }[]
    type! : 'shared'|'winner_takes_all'
    max_shared! : number
    createdBy!:string

    get total_bounty (){
        return this.event_bounties.reduce((t, c)=>(t||0)+(parseFloat(c.amount+'')||0), 0)
    }
}