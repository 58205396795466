import * as Popover from "@radix-ui/react-popover";
import { OverlayViewF, OverlayView } from "@react-google-maps/api";
import { keyframes, styled } from "@stitches/react";
import { GrClose } from "react-icons/gr";
import colors from "../../assets/colors";
import MarkerIcons from "../../assets/markers";
import { shadow } from "../../assets/styles";
import { coordinateToLatLng } from "../../common/Utils";
import { Event } from "../../types/Event";
import { Marker as MarkerType } from "../../types/Marker";

type Props = {
  marker: MarkerType;
};

const EventMarker = ({ marker }: Props) => {
  const event = marker.data as Event;

  return (
    <OverlayViewF
      getPixelPositionOffset={(width, height) => ({ x: -22, y: -60 })}
      position={coordinateToLatLng(marker.location.coordinates)}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Popover.Root>
        <Popover.Trigger
          style={{ border: 0, backgroundColor: colors.TRANSPARENT }}
        >
          <img
            alt="event"
            style={{ height: 60 }}
            src={MarkerIcons.event_black}
          />
        </Popover.Trigger>

        <Popover.Portal>
          {event && <EventPopover event={event} />}
        </Popover.Portal>
      </Popover.Root>
    </OverlayViewF>
  );
};

export default EventMarker;

const EventPopover = ({ event }: { event: Event }) => {
  const slideDown = keyframes({
    "0%": { opacity: 1, transform: "translateY(0px)" },
    "100%": { opacity: 0, transform: "translateY(-10px)" },
  });

  const slideUp = keyframes({
    "0%": { opacity: 0, transform: "translateY(-10px)" },
    "100%": { opacity: 1, transform: "translateY(0px)" },
  });

  const StyledContent = styled(Popover.Content, {
    '&[data-state="open"]': { animation: `${slideUp} 0.2s ease` },
    '&[data-state="closed"]': { animation: `${slideDown} 0.2s ease` },
  });

  return (
    <StyledContent>
      <Popover.Arrow fill="white" />
      <div
        style={{
          padding: 10,
          borderRadius: 10,
          background: "white",
          minWidth: 350,
          ...shadow,
        }}
      >
        <Popover.Close
          style={{
            height: 30,
            width: 30,
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderColor: colors.BLACK_30,
            borderWidth: 0.5,
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <GrClose style={{}} />
        </Popover.Close>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginTop: 10,
            gap: 5,
          }}
        >
          <p style={{ fontWeight: "600", fontSize: 16 }}>{event.name}</p>
          <p style={{ color: colors.BLACK_70, fontSize: 14 }}>
            {event.location.address?.split(",")[0]}
          </p>
          <p style={{ color: colors.BLACK_70, fontSize: 14 }}>
            {event.eventFormattedDate}
          </p>
        </div>
      </div>
    </StyledContent>
  );
};
