import { Menu, MenuItem } from "@szhsin/react-menu"
import { GrDown } from "react-icons/gr"
import Icons from "../../assets/Icons"
import DropdownOption from "../../types/Common"
import ImageIcon from "./ImageIcon"

type Props = {
    label?: string,
    value: string,
    options: DropdownOption[]
}

const Dropdown = ({ label, value, options }: Props) => {
    return (
        <Menu
            menuStyle={{ borderRadius: 10 }}
            menuButton={(
                <div className='bg-orange-100 rounded-full hover:bg-orange-200 transition-all px-4 py-1' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <p style={{ fontWeight: '500', fontSize: 14 }} >{label || options.find(o=>o.value==value)?.label}</p>
                    <GrDown style={{ fontSize: 12, marginLeft: 5 }} />
                </div>
            )}
            transition>
            {options.map((option, index) => <CustomMenuItem key={index} {...option} />)}
        </Menu>
    )
}

const CustomMenuItem = ({ icon, label, tint, value, onClick }: DropdownOption) => {
    return (
        <MenuItem  >
            <div onClick={onClick} style={{ display: 'flex', alignItems: 'center', gap: 5 }} >
                {icon && <ImageIcon size={18} name={icon} />}
                <p style={{ fontSize: 14, color: tint }} >{label}</p>
            </div>
        </MenuItem>
    )
}

export default Dropdown