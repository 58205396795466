import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useToggle } from "react-use"
import colors from "../../assets/colors"
import Icons from "../../assets/Icons"
import useEvent from "../../hooks/useEvent"
import { Event, EventReviewStatus } from "../../types/Event"
import ActivityIndicator from "../common/ActivityIndicator"
import ImageIcon from "../common/ImageIcon"
import ModalHeader from "../common/ModalHeader"

export type EventSubmissionModalProps = {
    onSelectEvent: (event: Event) => void
}

type Fields = {
    amount: number,
    currency: string
}

const EventSubmissions = ({ hide, props }: { hide: () => void, props: EventSubmissionModalProps }) => {
    const [donateAnonymously, toggleDonateAnonymously] = useToggle(false)
    const { register, control, handleSubmit, formState: { errors } } = useForm<Fields>({ defaultValues: { currency: 'near' } })
    const { useMyEvents } = useEvent()
    const { data: events = [], isLoading } = useMyEvents()

    const EventItem = ({ event }: { event: Event }) => {

        const color = useMemo(() => {
            switch (event.review_status) {
                case 'declined': return  colors.RED+'20'
                case 'in_review': return colors.BLACK_10 
                default: return  colors.PRIMARY +'20'
            }
        }, [event.review_status])

        const onClick = () => {
            hide()
            props.onSelectEvent(event)
        }

        const Detail = ({ icon, label, bold }: { icon: keyof typeof Icons, label?: string, bold?: boolean }) => (
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }} >
                <ImageIcon name={icon} />
                <p style={{ fontSize: bold ? 12 : 10, fontWeight: bold ? '600' : 'normal' }} >{label}</p>
            </div>
        )

        const Status = ({ status }: { status?: EventReviewStatus }) => {
            const { label, icon, color } = useMemo<{ label: string, icon: keyof typeof Icons, color: string }>(() => {
                switch (status) {
                    case 'declined': return { label: 'Failed', icon: 'review_failed', color: colors.RED }
                    case 'in_review': return { label: 'In Review', icon: 'timer', color: colors.BLACK_100 }
                    default: return { label: 'Approved', icon: 'verified', color: colors.PRIMARY }
                }
            }, [status])
            return (
                <div style={{ display: 'flex', alignItems: 'center', background: colors.WHITE, borderRadius: 50, padding: '7px 10px', gap: 5, borderWidth: 1, borderStyle: 'solid', borderColor: colors.BLACK_20 }} >
                    <ImageIcon name={icon} />
                    <p style={{ color, fontSize: 10, fontWeight: 'bold' }} >{label}</p>
                </div>
            )
        }

        return (
            <div
                onClick={(event.review_status !== 'declined' && event.review_status !== 'in_review') ? onClick : undefined}
                className={(event.review_status !== 'declined' && event.review_status !== 'in_review') ? 'hover-border' : ''}
                style={{ cursor: 'pointer', background: color, borderRadius: 8, gap: 10, padding: 15, display: 'flex', alignItems: 'center', }} >
                <ImageIcon name="globe" size={30} containerStyle={{ backgroundColor: colors.WHITE, borderRadius: 50, padding: 8 }} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }} >
                    <Detail bold label={event.name} icon='verified' />
                    <Detail label={event.location.address} icon='location' />
                    <Detail label={event.eventFormattedDate} icon='event_account' />
                </div>
                <div style={{ flex: 1, flexDirection: 'column', alignSelf: 'stretch', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }} >
                    <Status status={event.review_status} />
                    <ImageIcon name="arrow_circle_right" />
                </div>
            </div>
        )
    }

    return (
        <div style={{ margin: -30, width: 500 }}>
            <ModalHeader style={{ padding: 30, paddingBottom: 10 }} hide={hide} title="Event Submissions" subtitle="View and track your event submissions " />

            <div style={{ height: 1, background: colors.BLACK_20 }} />

            <div style={{ overflowY: 'scroll', flex: 1, display: 'flex', flexDirection: 'column', paddingTop: 20, maxHeight: '75vh', padding: 30, gap: 20 }} >
                {events.map((event, index) => <EventItem event={event} key={index} />)}
                {isLoading && <ActivityIndicator size={30} />}
            </div>

        </div>
    )
}

export default EventSubmissions
