import React, { ReactNode, useContext, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { auth, db, functions } from "../config/firebase";
import useSubscriber from "../hooks/useSubscriber";
import { Plan } from "../types/Plan";
import { User } from "../types/User";
import { walletConnection } from "../hooks/useNearWallet";
import Firebase from "firebase/compat/app";
import { Roles } from "../types/Role";
import { Companies, Teams } from "../assets/data/CarpoolsData";
import { selectRandom } from "../common/Utils";
import { useApp } from "./AppProvider";

interface AuthContextInterface {
  user?: firebase.default.User | null;
  userData?: User;
  isTester?: boolean;
}

const usersColRef = db.collection("users");
const teamsColRef = db.collection("teams");

export const userDataRef = React.createRef<User | undefined>();

const AuthContext = React.createContext({} as AuthContextInterface);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { config } = useApp();
  const { data: user } = useSubscriber(
    (observer: (value: firebase.default.User | null) => void) =>
      auth.onAuthStateChanged((v) => observer(v))
  );

  const isTester = useMemo(
    () => config?.testers.includes(user?.email || ""),
    [user?.email, config?.testers]
  );

  const { data: userData } = useSubscriber(
    (observer: (userData: User) => void) => {
      const unSubscribe = usersColRef.doc(user?.uid).onSnapshot(
        (snapshot) => {
          const data = new User(
            { ...snapshot?.data(), id: snapshot.id },
            user?.uid
          );
          //@ts-ignore
          userDataRef.current = data;
          return observer(data);
        },
        (err) => console.log("user data listening", err)
      );
      return unSubscribe;
    },
    [user]
  );

  return (
    <AuthContext.Provider value={{ user, userData, isTester }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);
  const { user } = context;

  const setUserDoc = async (res: firebase.default.auth.UserCredential) => {
    await usersColRef.doc(res.user?.uid).set({
      email: res.user?.email,
      full_name: res.user?.displayName,
      credits: 0,
      role: Roles.ClientCarpoolingUser,
      company: Companies.Company1, // dummy data
      team: Teams.TeamA, // dummy data
    });
  };

  return {
    ...context,

    loginWithEmail: useMutation({
      mutationFn: async (data: {
        email: string;
        password: string;
        extra_user_data?: { [key: string]: any };
      }) => {
        const res = await auth.signInWithEmailAndPassword(
          data.email,
          data.password
        );
        if (data.extra_user_data)
          await usersColRef.doc(res.user?.uid).update(data.extra_user_data);
        const userData = (await usersColRef.doc(res.user?.uid).get()).data();
        let stripe_customer_id;
        if (!userData?.stripe?.customer_id && userData) {
          const createCustomerFunction = functions.httpsCallable(
            "createStripeCustomer"
          );
          const { data: stripeCustomer } = await createCustomerFunction({
            name: userData.full_name,
          });
          await usersColRef
            .doc(res.user?.uid)
            .update("stripe.customer_id", stripeCustomer.id);
          stripe_customer_id = stripeCustomer.id;
        }
        return { uid: res.user?.uid, stripe_customer_id };
      },
      onError: (error: any) => {
        console.log(error);
        if (error.code === "auth/user-not-found")
          alert("There was no user found with the email you used!");

        if (error.code === "auth/wrong-password")
          alert("Wrong password! Please try again.");

        if (error.code === "auth/invalid-email")
          alert("That email address is invalid!");
      },
    }),

    signInWithApple: useMutation({
      mutationFn: async () => {
        let provider = new Firebase.auth.OAuthProvider("apple.com");
        const res = await Firebase.auth().signInWithPopup(provider);
        const userData = (await usersColRef.doc(res.user?.uid).get()).data();
        let stripe_customer_id;
        if (!userData?.stripe?.customer_id && userData) {
          const createCustomerFunction = functions.httpsCallable(
            "createStripeCustomer"
          );
          const { data: stripeCustomer } = await createCustomerFunction({
            name: userData.full_name,
          });
          await usersColRef
            .doc(res.user?.uid)
            .update("stripe.customer_id", stripeCustomer.id);
          stripe_customer_id = stripeCustomer.id;
        }
        if (!userData) {
          await setUserDoc(res);
        }
        return { uid: res.user?.uid, stripe_customer_id };
      },
      onError: (error: any) => {
        console.log(error);
        if (error.code === "auth/user-not-found")
          alert("There was no user found with the email you used!");

        if (error.code === "auth/wrong-password")
          alert("Wrong password! Please try again.");

        if (error.code === "auth/invalid-email")
          alert("That email address is invalid!");
      },
    }),

    signInWithGoogle: useMutation({
      mutationFn: async () => {
        let provider = new Firebase.auth.GoogleAuthProvider();
        const res = await Firebase.auth().signInWithPopup(provider);
        const userData = (await usersColRef.doc(res.user?.uid).get()).data();
        let stripe_customer_id;
        if (!userData?.stripe?.customer_id && userData) {
          const createCustomerFunction = functions.httpsCallable(
            "createStripeCustomer"
          );
          const { data: stripeCustomer } = await createCustomerFunction({
            name: userData.full_name,
          });
          await usersColRef
            .doc(res.user?.uid)
            .update("stripe.customer_id", stripeCustomer.id);
          stripe_customer_id = stripeCustomer.id;
        }
        if (!userData) {
          await setUserDoc(res);
        }
        return { uid: res.user?.uid, stripe_customer_id };
      },
      onError: (error: any) => {
        console.log(error);
        if (error.code === "auth/user-not-found")
          alert("There was no user found with the email you used!");

        if (error.code === "auth/wrong-password")
          alert("Wrong password! Please try again.");

        if (error.code === "auth/invalid-email")
          alert("That email address is invalid!");
      },
    }),

    signUp: useMutation({
      mutationFn: async (data: {
        email: string;
        password: string;
        full_name: string;
        role: string;
        extra_user_data?: { [key: string]: any };
      }) => {
        const res = await auth.createUserWithEmailAndPassword(
          data.email,
          data.password
        );
        await usersColRef.doc(res.user?.uid).set({
          email: data.email,
          full_name: data.full_name,
          credits: 0,
          role: data.role,
          ...data.extra_user_data,
        });
        const createCustomerFunction = functions.httpsCallable(
          "createStripeCustomer"
        );
        const { data: stripeCustomer } = await createCustomerFunction({
          name: data.full_name,
        });
        return { uid: res.user?.uid, stripe_customer_id: stripeCustomer.id };
      },
      onError: (error: any) => {
        console.log(error);

        if (error.code === "auth/email-already-in-use")
          alert("That email address is already in use!");

        if (error.code === "auth/operation-not-allowed")
          alert("Operation not allowed!");

        if (error.code === "auth/weak-password")
          alert("Please insert a stronger password.");

        if (error.code === "auth/invalid-email")
          alert("That email address is invalid!");
      },
    }),

    updateUserData: useMutation(async (params?: Partial<User>) => {
      const userDocRef = db.collection("users").doc(user?.uid || "");
      return userDocRef.update(params!);
    }),

    loginWithNear: useMutation(async (params?: { account_id: string }) => {
      const instance = functions.httpsCallable("loginWithNear");
      const data = await instance(params);
      if (data.data.success) {
        const token = data.data.token;
        const res = await auth.signInWithCustomToken(token);
        const userData = (await usersColRef.doc(res.user?.uid).get()).data();
        if (!userData?.stripe?.customer_id && userData) {
          const createCustomerFunction = functions.httpsCallable(
            "createStripeCustomer"
          );
          await createCustomerFunction({ name: userData.full_name });
        }
        if (!userData) {
          await setUserDoc(res);
        }
        return res;
      } else throw data.data;
    }),

    signOut: async () => {
      walletConnection.current?.signOut();
      await auth.signOut();
      window.location.replace('/')
    },

    upgradeAccount: (type: Plan) => {
      const redirect_link = `https://thecarbongames.web.app/upgrade-account?plan_type=${type}&redirect=true`;
      window.open(redirect_link, "popup", "width=600,height=550");
    },

    loginWithOkta: useMutation(async (params?: { token: string }) => {
      const corporateId = "7Dbgl5Ja0KEjUgwzQkTD";
      if (params?.token) {
        const result = await auth.signInWithCustomToken(params.token);
        if (result.user === null) throw new Error("Token invalid");
        const userDoc = await usersColRef.doc(result.user.uid).get();
        if (!userDoc.get("corporate")) {
          const brisaTeams = await teamsColRef
            .where("corporate_id", "==", corporateId)
            .get();
          await userDoc.ref.update({
            corporate: corporateId,
            corporate_team: selectRandom(brisaTeams.docs.map((doc) => doc.id)),
          });
        }
      } else throw new Error("Token invalid");
    }),
  };
};
