
import * as Popover from "@radix-ui/react-popover"
import { OverlayView } from "@react-google-maps/api"
import { keyframes, styled } from "@stitches/react"
import moment from "moment"
import { GrClose } from "react-icons/gr"
import colors from "../../assets/colors"
import MarkerIcons from "../../assets/markers"
import { shadow } from "../../assets/styles"
import { coordinateToLatLng } from "../../common/Utils"
import { Carpool } from "../../types/Carpool"
import { Marker as MarkerType } from '../../types/Marker'
import Avatar from "../common/Avatar"
import { Fragment } from "react"

type Props = {
    marker: MarkerType
}

const CarMarker = ({ marker }: Props) => {
    const carpool = marker.data as Carpool


    const slideDown = keyframes({
        '0%': { opacity: 1, transform: 'translateY(0px)' },
        '100%': { opacity: 0, transform: 'translateY(-10px)' },
    });

    const slideUp = keyframes({
        '0%': { opacity: 0, transform: 'translateY(-10px)' },
        '100%': { opacity: 1, transform: 'translateY(0px)' },
    });

    const StyledContent = styled(Popover.Content, {
        '&[data-state="open"]': { animation: `${slideUp} 0.2s ease` },
        '&[data-state="closed"]': { animation: `${slideDown} 0.2s ease` },
    });

    return (
        < OverlayView
            position={coordinateToLatLng(marker.location.coordinates)}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >

            <Popover.Root>

                <Popover.Trigger style={{ border: 0, backgroundColor: colors.TRANSPARENT }} >
                    <img alt="car_top" style={{ height: 60, transform: 'rotate(45deg)' }} src={MarkerIcons.car_top} />
                </Popover.Trigger>

                <Popover.Anchor />
                <Popover.Portal>

                    <StyledContent  >
                        <Popover.Arrow fill='white' />
                        <div style={{ padding: 10, borderRadius: 10, background: 'white', minWidth: 350, ...shadow }} >
                            <Popover.Close style={{ height: 30, width: 30, borderRadius: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: colors.BLACK_30, borderWidth: .5, position: 'absolute', top: 10, right: 10 }} >
                                <GrClose style={{}} />
                            </Popover.Close>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center' }} >
                                {carpool.passengersArray.reverse().map((passenger, index) => (
                                    <Avatar
                                        key={index}
                                        style={{ marginRight: -5 }}
                                        size={30} name={passenger.name} id={passenger.id} url={passenger.avatar} />
                                ))}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: 10, gap: 5 }} >
                                <p style={{ fontWeight: '600', fontSize: 16 }} >{carpool.destination.address?.split(',')[0]}</p>
                                <p style={{ color: colors.BLACK_70, fontSize: 14 }} >From Home via {carpool.car?.make} {carpool.car?.model}</p>
                                <p style={{ color: colors.BLACK_70, fontSize: 14, marginBottom: 10 }} >{moment(carpool.createdAt).format('Do MMMM, YYYY. HH:mm a')}</p>

                                <div style={{ display: 'inline', color: colors.BLACK_70, fontSize: 14 }} >
                                    <p style={{ display: 'inline' }} >Participants : </p>
                                    {carpool.passengersArray.map((passenger, index, arr) => (
                                        <Fragment key={index} >
                                            <p style={{ color: colors.BLACK_70, display: 'inline' }} >{index === 0 ? '' : arr.length - 1 === index ? ' and ' : ', '}</p>
                                            <p
                                                key={index}
                                                style={{
                                                    display: 'inline',
                                                    color: passenger.isOwner ? colors.TURQUOISE : colors.DARK_YELLOW
                                                }}>{passenger.username || passenger.name}</p>
                                        </Fragment>

                                    ))}
                                </div>
                            </div>
                        </div>

                    </StyledContent>
                </Popover.Portal>

            </Popover.Root>

        </OverlayView>
    )
}


export default CarMarker