/* eslint-disable no-extend-native */
import humanizeDuration from "humanize-duration";
import { Coordinate } from "../types/Location";

declare global {
  interface String {
    capitalizeEveryWord(): string;
    capitalizeFirstLetter(): string;
    generateColor(): string | undefined;
    getInitials(): string | null;
  }

  interface Number {
    formatDuration(params?: any): string;
  }
}

//strings
String.prototype.capitalizeEveryWord = function () {
  const words = this.valueOf()?.split(" ");
  return words
    .map((word) => {
      return word?.[0]?.toUpperCase() + word?.substring(1);
    })
    ?.join(" ");
};

String.prototype.capitalizeFirstLetter = function () {
  return this.valueOf()
    ? this.valueOf().charAt(0).toUpperCase() + this.valueOf().slice(1)
    : "null";
};

String.prototype.generateColor = function () {
  if (!this.valueOf()?.trim()) return undefined;
  var colors = ["#B9BE95", "#D4883A", "#FF7852", "#42495B", "#C7BE95"];
  var sum = 0;
  for (var i = 0; i < this.valueOf().length; i++) {
    sum += this.valueOf().charCodeAt(i);
  }
  return colors[sum % colors.length];
};

String.prototype.getInitials = function () {
  const name = this.valueOf();
  if (!name) return null;
  return name
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase())
    .join()
    .replaceAll(/,/g, "");
};

//numbers
Number.prototype.formatDuration = function (params = {}) {
  return humanizeDuration(this.valueOf() * 1000, {
    conjunction: " and ",
    largest: 1,
    ...params,
  });
};

export const getPropValue = function (
  sourceObject: any,
  dotNotationPath: string
) {
  let returnData = sourceObject;

  dotNotationPath.split(".").forEach((subPath) => {
    returnData = returnData?.[subPath];
  });

  return returnData;
};

export const coordinateToLatLng = (coordinate?: Coordinate) => {
  if (!coordinate) return { lat: 0, lng: 0 };
  return { lat: coordinate.latitude, lng: coordinate.longitude };
};

export const cleanObject = <TObj>(obj: TObj & { [key: string]: any }) => {
  if (!obj) return obj;
  const clone = { ...obj };
  for (let index = 0; index < Object.keys(clone).length; index++) {
    const key = Object.keys(clone)[index];
    if (clone[key] === undefined) delete obj[key];
  }
  return obj;
};

export const convertToId = (str?: string) => {
  if (!str) return "";
  return str.replace(/\W/g, "_").toLowerCase();
};

export const waitFor = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getPortal = (email?: string) => {
  const domain = email?.split("@")?.reverse()?.[0];
  switch (domain) {
    // case 'gmail.com':
    //   return {
    //     url: `https://dev-62316704.okta.com/app/dev-62316704_samltest_1/exkg1vkgadUm28Mx65d7/sso/saml?username=${email}`,
    //     name: 'TEST OKTA',
    //     corporate_id: '',
    //   };
    case "brisa.pt":
      return {
        url: `https://brisa.okta-emea.com/app/brisa_thecarbongames_1/exkcotebiq6GAeytY0i7/sso/saml?username=${email}`,
        name: "Brisa",
        corporate_id: "",
      };
    case "viaverde.pt":
      return {
        url: `https://brisa.okta-emea.com/app/brisa_thecarbongames_1/exkcotebiq6GAeytY0i7/sso/saml?username=${email}`,
        name: "Brisa",
        corporate_id: "",
      };
    default:
      return undefined;
  }
};

export const selectRandom = <T>(arr?: T[]) => {
  if (!arr || !arr?.length) return;
  const index = Math.ceil(Math.random() * arr.length - 1);
  return arr[index];
};

export const capitalizeFirstLetter = function (str?: string) {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1);
};
