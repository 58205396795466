import Images from "../assets/Images"
import Constants from "../common/Constants"
import CustomButton from "../components/common/CustomButton"
import ImageIcon from "../components/common/ImageIcon"
import colors from '../assets/colors'
import Dropdown from "../components/common/Dropdown"
import DropdownOption from "../types/Common"
import ProjectItem from "../components/common/ProjectItem"
import { useNavigate } from "react-router-dom"

const DonationHistory = () => {

    return (
        <div className="flex-1" >
            <div
                className="p-12 overflow-y-auto"
                style={{ maxHeight: `calc(100vh - ${Constants.header_height}px)` }} >

                <div className="flex items-start justify-between mb-12" >
                    <p className="text-2xl font-bold" >Donation History</p>
                </div>

                <div className="flex gap-6" >
                    {details.map((detail, index) => <Detail {...detail} key={index} />)}
                </div>

                <div className="flex gap-6 mt-6" >
                    <div style={{ flex: 2.05 }} className="flex items-start flex-col gap-4" >
                        <p className="mr-4 font-semibold text-lg" >Donation History</p>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => <HistoryItem key={index} />)}
                    </div>
                    <div className="flex items-start flex-col" style={{ flex: 1 }} >
                        <p className="mr-4 font-semibold text-lg mb-4" >Impact</p>
                        <div className="flex w-full">
                            <div className="flex flex-col flex-1" >
                                <TopDonation />
                                <SocialImpact />
                            </div>
                            <DonationThemes />
                        </div>
                    </div>
                </div>

            </div>

        </div>

    )
}

export default DonationHistory

const Detail = ({ title, subTitle, icon, subDetail, bgColor }: { title: string, subTitle: string, icon: string, subDetail: string, bgColor: string }) => {
    return (
        <div style={{ background: bgColor }} className="flex p-6 rounded-xl flex-1" >
            <div className="flex-1 justify-between">
                <p className="text-start mb-2" >{subTitle}</p>
                <p className="text-3xl font-semibold text-start mb-4" >{title}</p>
                <div className="flex gap-2 items-center text-sm" >
                    <ImageIcon name="arrow_trending" size={16} />
                    <p>{subDetail}</p>
                </div>

            </div>
            <ImageIcon containerStyle={{ backgroundColor: colors.WHITE, borderRadius: 100, padding: 7, alignSelf: 'start' }} size={26} name="vote" />
        </div>
    )
}

const details = [
    {
        title: '56 Voting Power',
        subTitle: 'Total Voting Power Donated',
        subDetail: 'More than 85% of Carbon Games users',
        icon: 'vote',
        bgColor: '#D3E9B9'
    },
    {
        title: '7 Projects',
        subTitle: 'Unique Projects',
        subDetail: 'You helped 7 projects reach their goals this year',
        icon: 'earth',
        bgColor: '#F8CBAA'
    },
    {
        title: 'Environmentalist',
        subTitle: 'Donation Profile',
        subDetail: 'You donated the most to environmental causes. ',
        icon: 'leaf',
        bgColor: '#D0E6DE'
    }
]


const HistoryItem = () => {
    return (
        <div className="bg-gray-100 items-center rounded-xl flex w-full p-4 px-8">
            <ImageIcon containerStyle={{ background: colors.PRIMARY + '15', padding: 6, borderRadius: 40 }} size={25} color={colors.PRIMARY} name='donation' />
            <div className="ml-4">
                <p className="text-2xl font-semibold text-start" >10,000 Trees for the future</p>
                <p className="text-start" >Support the aforestation of greater Lisbon area</p>
            </div>
            <div className="ml-auto flex flex-col">
                <div className="flex items-end self-end" >
                    <p className="text-xl font-semibold mr-2" >10</p>
                    <p className="text-start text-sm" >Voting Power</p>
                </div>
                <p className="text-start text-sm">Donated on: 23/01/23</p>
            </div>
        </div>
    )
}

const TopDonation = () => {
    return (
        <div style={{ background: "#D3E9B9" }} className="p-4 flex flex-col rounded-xl w-full mb-4">
            <p className="mb-4 text-start text-sm" >Top Donation</p>
            <p className="mb-8 text-start text-2xl font-semibold" >10 VP</p>
            <p className="text-start text-sm font-semibold" >10,000 Trees for the future</p>
        </div>
    )
}

const SocialImpact = () => {
    return (
        <div style={{ background: "#D0E6DE" }} className="p-4 flex flex-col rounded-xl w-full">
            <p className="mb-4 text-start text-sm" >Top Donation</p>
            <p className="mb-8 text-start text-2xl font-semibold" >+2.04%</p>
            <p className="text-start text-sm font-semibold" >10,000 Trees for the future</p>
        </div>
    )
}

const DonationThemes = () => {
    return (
        <div style={{ background: "#F8CBAA" }} className="flex flex-col items-start p-4 rounded-xl  flex-1 ml-4" >
            <img style={{ width: 125, height: 90, objectFit: 'contain', alignSelf :'center', margin : 20 }} src={Images.illustration_2} />
            <p className="text-sm text-start mb-6" >Donation Themes</p>
            <div className="flex flex-col items-start justify-between flex-1" >
                <p className="text-sm text-start" >Environmental - 35%</p>
                <p className="text-sm text-start" >Climate Change - 30%</p>
                <p className="text-sm text-start" >Animals - 24%</p>
                <p className="text-sm text-start" >Social good - 11%</p>
            </div>
        </div>
    )
}