export class NFTToken {

    constructor(data: any) {
        Object.assign(this, data)
    }

    get emission() {
        const value = parseFloat(this?.metadata?.title?.split(' Kgs')[0])
        if (isNaN(value)) return 0
        return value
    }
    get city() {
        return this?.metadata?.title?.split('from ')[1]?.split(' on')[0]
    }

    get date() {
        return this?.metadata?.title?.split(' on ')[1]
    }


    token_id!: string;
    owner_id!: string;
    metadata!: {
        title: string;
        description: string;
        media: string;
        media_hash: null;
        copies: null;
        issued_at: null;
        expires_at: null;
        starts_at: null;
        updated_at: null;
        extra: null;
        reference: null;
        reference_hash: null;
    };
    approved_account_ids!: {};
    royalty!: {};
}