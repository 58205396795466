import { useQuery } from "react-query"
import { db } from "../config/firebase"
import Bonus from "../types/Bonus"

const bonusesColRef = db.collection('bonuses')

const useBonus = () => {
    return {
        useAllBonuses: () => useQuery({
            queryKey: ['all-bonuses'],
            queryFn: () => bonusesColRef.get(),
            select: snapshot => snapshot.docs.map(doc => new Bonus({ ...doc.data(), id: doc.id }))
        }),


    }
}

export default useBonus