import { FC, useMemo, useState } from "react";

import CarpoolsActive from "./carpool/CarpoolsActive";
import CarpoolsCompleted from "./carpool/CarpoolsCompleted";
import CarpoolsFromWork from "./carpool/CarpoolsFromWork";
import CarpoolsToWork from "./carpool/CarpoolsToWork";
import { User } from "../../../types/User";
import TotalCO2Company from "./CO2/TotalCO2Company";
import TotalCO2User from "./CO2/TotalCO2User";
import { Roles } from "../../../types/Role";
import useCarpool, { TimeFilter } from "../../../hooks/useCarpool";
import Tabs from "../../common/Tabs";
import { CarpoolStatus } from "../../../types/Carpool";

interface Props {
  userData: User | undefined;
}
const DashboardCards: FC<Props> = ({ userData }): JSX.Element => {
  const timeFilters = useMemo(
    () => [
      { label: "Day", value: TimeFilter.Day },
      { label: "Week", value: TimeFilter.Week },
      { label: "Month", value: TimeFilter.Month },
      { label: "Year", value: TimeFilter.Year },
      { label: "All", value: TimeFilter.All },
    ],
    []
  );
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<TimeFilter>();

  const { useFilterCarpoolsByTimePeriod } = useCarpool();
  const { data: filteredCarpools } = useFilterCarpoolsByTimePeriod(
    selectedTimeFilter,
    CarpoolStatus.Completed
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: 400,
        height: "100%",
        overflowY: "auto",
        scrollbarWidth: "none", // Hide scrollbar in Firefox
        msOverflowStyle: "none", // Hide scrollbar in IE and Edge
      }}
    >
      <Tabs
        value={selectedTimeFilter}
        options={timeFilters}
        onSelect={setSelectedTimeFilter}
      />

      {selectedTimeFilter !== undefined && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginTop: 20,
          }}
        >
          {/* CO2 Cards */}
          {userData?.corporate && (
            <TotalCO2Company
              filteredCarpools={filteredCarpools}
              company={userData?.corporate}
            />
          )}

          {userData?.id &&
            (userData.role === Roles.Admin ||
              userData.role === Roles.ClientCarpoolingUser) && (
              <TotalCO2User
                filteredCarpools={filteredCarpools}
                userId={userData.id}
                userName={userData.username || userData.full_name}
              />
            )}

          {/* We don't have the team data in the carpool so it is hidden for now */}
          {/* {userData?.corporate_team && (
            <TotalCO2Team
              filteredCarpools={filteredCarpools}
              team={userData.corporate_team}
              company={userData.corporate}
            />
          )} */}

          {/* Carpools Cards*/}
          {userData &&
            (userData.role === Roles.Admin ||
              userData.role === Roles.ClientCarpoolingUser) && (
              <>
                <CarpoolsActive />
                <CarpoolsCompleted filteredCarpools={filteredCarpools} />
                <CarpoolsFromWork
                  filteredCarpools={filteredCarpools.filter(
                    (c) =>
                      c.destination.id?.split(".")[0] !== userData.corporate
                  )}
                />
                <CarpoolsToWork
                  filteredCarpools={filteredCarpools.filter(
                    (c) =>
                      c.destination.id?.split(".")[0] === userData.corporate
                  )}
                />
              </>
            )}
          {/* <TotalDistanceTraveled /> */}
        </div>
      )}

      {/* ADD & DELETE TEST DATA */}
      {/* <button onClick={addDummyData}>add test data</button>
      <button onClick={deleteDummyData}>delete test data</button> */}
    </div>
  );
};

export default DashboardCards;
