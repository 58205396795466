import { FC } from "react";
import useCarpool from "../../../../hooks/useCarpool";
import { StatItem } from "../../../../pages/Statistics";

interface Props {}
const CarpoolsActive: FC<Props> = (): JSX.Element => {
  const { useAllActiveCarpools } = useCarpool();
  const { data } = useAllActiveCarpools();

  return (
    <StatItem
      background="#D3E9B9"
      title="Active Carpools"
      subTitle=""
      unit="Carpool"
      icon="car_side"
      decimalPoints={0}
      value={data?.length}
    />
  );
};

export default CarpoolsActive;
