import Images from "../assets/Images";
import Constants from "../common/Constants";
import CustomButton from "../components/common/CustomButton";
import ImageIcon from "../components/common/ImageIcon";
import colors from "../assets/colors";
import Dropdown from "../components/common/Dropdown";
import DropdownOption from "../types/Common";
import ProjectItem from "../components/common/ProjectItem";
import { useNavigate } from "react-router-dom";
import Tabs from "../components/common/Tabs";
import { useEffect, useMemo, useState } from "react";
import CustomCheckbox from "../components/common/CustomCheckbox";
import NFTTokenItem from "../components/common/NFTTokenItem";
import { useNearWallet } from "../hooks/useNearWallet";
import { useAuth } from "../providers/AuthProvider";
import { useMutation, useQueryClient } from "react-query";
import { waitFor } from "../common/Utils";
import React from "react";
import { useConnect, useReadContract } from "thirdweb/react";
import { createWallet, injectedProvider } from "thirdweb/wallets";
import { buyFromListing } from "thirdweb/extensions/marketplace";
import {
  createThirdwebClient,
  getContract,
  prepareContractCall,
  readContract,
  resolveMethod,
  sendTransaction,
} from "thirdweb";
import { download } from "thirdweb/storage";
import {
  ThirdwebProvider,
  useActiveAccount,
  ConnectButton,
  useWalletBalance,
} from "thirdweb/react";
import { sepolia } from "thirdweb/chains";
import { useCreateDirectListing } from "@thirdweb-dev/react";
const clientId = "40a1c30bc9886515a5a326f5a0e2abac";
const client = createThirdwebClient({ clientId });
const metadata = {};
// @ts-ignore: TS2554
const contract = getContract({
  client: client,
  chain: sepolia,
  address: "0xcC9602A66B1EABf1e472D22C2C163A37eCBbeA17",
});
// @ts-ignore: TS2554
const marketContract = getContract({
  client: client,
  chain: sepolia,
  address: "0x2858E63e966b245bb8caD4eBc4437E7c84b8414E",
});
const Marketplace = () => {
  const navigate = useNavigate();

  //states
  const [marketplaceMode, setMarketPlaceMode] = useState<
    "donate" | "nft-marketplace" | "my-nfts"
  >("donate");

  //default-hooks

  //higher-hooks

  //sub-hooks

  return (
    <div className="flex-1">
      <div
        className="p-12 overflow-y-auto"
        style={{ maxHeight: `calc(100vh - ${Constants.header_height}px)` }}
      >
        <div className="flex mb-12 items-center gap-4">
          <p className="text-2xl font-bold">Marketplace</p>

          <div style={{ flex: 1 }} />

          <Tabs
            style={{ minWidth: 300 }}
            tabStyle={{ padding: "9px 16px" }}
            hidable={false}
            value={marketplaceMode}
            options={carpoolsTypes}
            onSelect={(v) => setMarketPlaceMode(v as any)}
          />

          <CustomButton
            onClick={() => navigate("donation-history")}
            icon="donation"
            label="Donation History"
          />
        </div>

        {marketplaceMode === "donate" && <DonateModeView />}
        {marketplaceMode === "nft-marketplace" && <NFTMarketPlaceView />}
        {marketplaceMode === "my-nfts" && <MyNFTMarketPlaceView />}
      </div>
    </div>
  );
};

export default Marketplace;

const DonateModeView = () => {
  const InfoBox = useMemo(() => {
    return (
      <div className="flex p-8 items-center bg-gray-100 rounded-xl gap-8">
        <div className="flex-1 flex flex-col items-start justify-between self-stretch">
          <p className="text-4xl font-semibold text-primary text-left">
            {"Contribute to life-changing causes with your voting power"}
          </p>
          <div className="flex items-center gep-4">
            <p
              style={{ backgroundColor: colors.PRIMARY + "15" }}
              className="text-primary px-6 py-3 font-semibold rounded-full shrink-0 mr-4 text-sm"
            >
              10 tokens = 1 voting power
            </p>
            <p className="text-start text-sm">
              Your donation not only supports these causes but also gives you a
              voice. Every 10 tokens gives you 1 voting power. The more tokens
              you earn, the higher voting power you have and the more impact you
              can have on our communities..
            </p>
          </div>
          <div className="flex items-end mt-8">
            <ImageIcon
              containerStyle={{
                backgroundColor: colors.WHITE,
                borderRadius: 100,
                padding: 7,
                marginRight: 16,
              }}
              size={26}
              name="vote"
            />
            <div className="flex items-end">
              <p className="font-bold text-4xl mr-2">60</p>
              <p className="font-semibold">voting power available</p>
            </div>
          </div>
        </div>
        <img
          alt="illustration-3"
          className="h-[200px] w-[400px] object-contain"
          src={Images.illustration_3}
        />
      </div>
    );
  }, []);

  return (
    <>
      {InfoBox}
      <div className="flex gap-2 items-center mt-12">
        <p className="mr-4 font-semibold">Projects</p>
        <Dropdown options={countries} value="worldwide" />
        <Dropdown options={themes} value="all-themes" />
      </div>

      <div className="grid grid-cols-4 gap-4 mt-6">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6].map((bonus, index) => (
          <ProjectItem key={index} />
        ))}
      </div>
    </>
  );
};

const NFTMarketPlaceView = () => {
  const [selectedTokens, setSelectedTokens] = useState<string[]>([]);
  const [nftMetadata, setNftMetadata] = useState<any[]>([]);
  const [selectedNFT, setSelectedNFT] = useState<any>(null);
  const [showPopup, setShowPopup] = useState(false);
  const { user } = useAuth();
  const { useMyNftTokens, useAllNFTs, purchaseNFT } = useNearWallet();

  const { data: my_nft_tokens = [], isFetching: isFetchingMyNftTokens } =
    useMyNftTokens();
  const {
    data,
    fetchNextPage,
    isFetching: isFetchingAllNftTokens,
  } = useAllNFTs();
  const all_nft_tokens = useMemo(
    () => data?.pages.reduce((t, c) => [...t, ...c], []) || [],
    [data]
  );

  const onTokenSelect = (token_id: string) => {
    setSelectedTokens((v) => {
      if (v.includes(token_id)) return v.filter((id) => id !== token_id);
      else return [...v, token_id];
    });
  };

  const fetchData = async () => {
    try {
      // @ts-ignore: TS2554
      const data = await readContract({
        contract: marketContract,
        method: "function totalListings() view returns (uint256)",
        params: [],
      });
      const numericValue = parseInt(data.toString().replace("n", ""), 10);
      if (!isNaN(numericValue)) {
        const fetchedMetadata = [];
        for (let i = 1; i < numericValue; i++) {
          // @ts-ignore: TS2554
          const d = await readContract({
            contract: marketContract,
            method:
              "function getListing(uint256 _listingId) view returns ((uint256 listingId, uint256 tokenId, uint256 quantity, uint256 pricePerToken, uint128 startTimestamp, uint128 endTimestamp, address listingCreator, address assetContract, address currency, uint8 tokenType, uint8 status, bool reserved) listing)",
            params: [BigInt(i)],
          });
          const tokenId = parseInt(d.tokenId.toString().replace("n", ""), 10);
          // @ts-ignore: TS2554
          const uri = await readContract({
            contract,
            method: "function tokenURI(uint256 _tokenId) view returns (string)",
            params: [BigInt(tokenId)],
          });
          const file = await download({ client: client, uri: uri });
          const metadataResponse = await fetch(file.url);
          const meta = await metadataResponse.json();
          console.log(d);
          fetchedMetadata.push({
            ...meta,
            token_id: i.toString(),
            listing: d,
          });
        }
        setNftMetadata(fetchedMetadata);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredNFTs = useMemo(() => {
    return nftMetadata.filter((nft) => nft.owner !== user?.uid);
  }, [nftMetadata, user]);

  const handlePurchase = async (listing: any) => {
    try {
      //@ts-ignore: TS2554
      const wallet = createWallet("io.metamask");
      const account = await wallet.connect({
        client,
      });

      const { listingId, quantity, currency, pricePerToken } = listing;
      // @ts-ignore: TS2554
      const transaction = buyFromListing({
        contract: marketContract,
        listingId: listingId,
        quantity: quantity,
        recipient: account.address,
      });
      console.log(transaction);
      const { transactionHash } = await sendTransaction({
        transaction,
        account: account,
      });

      console.log("Transaction successful:", transactionHash);
      setShowPopup(false);
      setNftMetadata((prev) =>
        prev.filter((nft) => nft.token_id !== selectedNFT.token_id)
      );
    } catch (error) {
      console.error("Purchase failed:", error);
    }
  };

  const InfoBox = useMemo(() => {
    return (
      <div className="flex p-8 items-center bg-gray-100 rounded-xl gap-8">
        <div className="flex-1 flex flex-col items-start justify-between self-stretch">
          <p className="text-4xl font-semibold text-primary text-left">
            {"Contribute to sustainability by offsetting Carbon emissions"}
          </p>
          <div className="flex items-center gap-4">
            <p className="text-start text-sm">
              By purchasing environmental sustainability NFTs, you contribute a
              positive impact on the environment. To offset Carbon emissions,
              simply input the amount of Carbon emissions you want to offset and
              we’d handle the selection. You can also individually select the
              NFTs you want to purchase.
            </p>
          </div>
          <div className="flex gap-3 items-center">
            <div className="bg-white rounded-xl flex p-3 items-center gap-4">
              <ImageIcon size={20} color={colors.BLACK_60} name="fuel" />
              <input className="flex-1" placeholder="Emissions to offset" />
              <ImageIcon
                containerStyle={{
                  background: colors.PRIMARY50 + "aa",
                  height: 30,
                  width: 30,
                  borderRadius: 100,
                  marginTop: -10,
                  marginBottom: -10,
                }}
                name="exchange_arrow"
              />
              <input className="flex-1" placeholder="Price in USD" />
              <ImageIcon size={20} color={colors.BLACK_60} name="money" />
            </div>
            <CustomButton
              onClick={() =>
                purchaseNFT.mutate({ token_id: selectedTokens[0] })
              }
              icon="purchase"
              loading={purchaseNFT.isLoading}
              label="Purchase"
            />
          </div>
          <CustomCheckbox
            value={true}
            label="Auto-select NFTs to meet emissions goal"
          />
        </div>
        <div>
          <img
            alt="illustration-2"
            className="h-[154px] w-[222px] object-contain"
            src={Images.illustration_2}
          />
          <p
            className="p-4 rounded-full mt-4"
            style={{
              color: colors.PRIMARY,
              background: colors.PRIMARY50 + "80",
            }}
          >
            {selectedTokens.length} selected ={" "}
            {all_nft_tokens
              .filter((token) => selectedTokens.includes(token.token_id))
              .map((token) => token.emission)
              .reduce((t, c) => t + c, 0)}{" "}
            kg
          </p>
        </div>
      </div>
    );
  }, [selectedTokens, all_nft_tokens, purchaseNFT]);

  return (
    <>
      {InfoBox}
      <div className="flex gap-2 items-center mt-12">
        <p className="mr-4 font-semibold">NFTs</p>
        <Dropdown options={countries} value="worldwide" />
        <Dropdown options={emissionAmounts} value="1" />
      </div>
      <div className="grid grid-cols-3 gap-4 mt-6">
        {nftMetadata.length === 0 && (
          <p className="text-4xl text-gray-400 text-center mt-20">Loading...</p>
        )}
        {nftMetadata.map((meta, index) => (
          <div
            key={index}
            className="border p-4 rounded-lg cursor-pointer"
            onClick={() => {
              setSelectedNFT(meta);
              setShowPopup(true);
            }}
          >
            <h3 className="text-xl font-bold">{meta.name}</h3>
            <p>{meta.description}</p>
            {meta.image && (
              <img src={meta.image} alt={meta.name} className="w-full h-auto" />
            )}
          </div>
        ))}
      </div>
      {showPopup && selectedNFT && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-semibold mb-4">{selectedNFT.name}</h2>
            <p className="mb-4">{selectedNFT.description}</p>
            {selectedNFT.image && (
              <img
                src={selectedNFT.image}
                alt={selectedNFT.name}
                className="w-full h-auto mb-4"
              />
            )}
            <div className="flex justify-end gap-2">
              <CustomButton
                label="Purchase"
                onClick={() => handlePurchase(selectedNFT.listing)}
              />
              <CustomButton onClick={() => setShowPopup(false)} label="Close" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const MyNFTMarketPlaceView = () => {
  // const [hideNFTs, setHideNFTs] = useState(false);
  const [selectedTokens, setSelectedTokens] = useState<string[]>([]);
  const [nftMetadata, setNftMetadata] = useState<any[]>([]);
  const [selectedNFT, setSelectedNFT] = useState<any>(null); // State to manage selected NFT for popup
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const [hasRun, setHasRun] = useState(false);

  const { user } = useAuth();
  const { useMyNftTokens, useAllNFTs, burnNFT, purchaseNFT } = useNearWallet();

  const { data: my_nft_tokens = [], isFetching: isFetchingMyNftTokens } =
    useMyNftTokens();
  const {
    data,
    fetchNextPage,
    isFetching: isFetchingAllNftTokens,
  } = useAllNFTs();
  const all_nft_tokens = useMemo(
    () => data?.pages.reduce((t, c) => [...t, ...c], []) || [],
    [data]
  );

  const onTokenSelect = (token_id: string) => {
    setSelectedTokens((v) => {
      if (v.includes(token_id)) return v.filter((id) => id !== token_id);
      else return [...v, token_id];
    });
  };

  // console.log({ all_nft_tokens });

  const nftList = useMemo(() => {
    return all_nft_tokens;
    if (user) return my_nft_tokens;
    else return all_nft_tokens;
  }, [user, my_nft_tokens, all_nft_tokens]);
  const fetchData = async () => {
    try {
      // @ts-ignore: TS2554
      const wallet = createWallet("io.metamask");
      const account = await wallet.connect({
        client,
      });
      // @ts-ignore: TS2554
      const data = await readContract({
        contract,
        method: "function nextTokenIdToMint() view returns (uint256)",
        params: [],
      });
      const numericValue = parseInt(data.toString().replace("n", ""), 10);
      if (!isNaN(numericValue)) {
        const fetchedMetadata = [];
        for (let i = 0; i < 1; i++) {
          // @ts-ignore: TS2554
          const acc = await readContract({
            contract,
            method: "function ownerOf(uint256 tokenId) view returns (address)",
            params: [BigInt(i)],
          });
          if (acc == account.address) {
            // @ts-ignore: TS2554
            const uri = await readContract({
              contract,
              method:
                "function tokenURI(uint256 _tokenId) view returns (string)",
              params: [BigInt(i)],
            });
            const file = await download({
              client: client,
              uri: uri,
            });
            const metadataResponse = await fetch(file.url);
            const meta = await metadataResponse.json();
            console.log(meta.attributes[0].value);
            fetchedMetadata.push({ ...meta, token_id: i.toString() });
          }
        }
        setNftMetadata(fetchedMetadata);
      }
    } catch (error) {
      console.error("Error reading contract:", error);
      // setNftMetadata(fetchedMetadata);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleNFTClick = (nft: any) => {
    setSelectedNFT(nft);
    setShowPopup(true);
  };
  // Function to handle burning an NFT
  const handleBurnNFT = async (tokenId: any) => {
    try {
      // @ts-ignore: TS2554
      const wallet = createWallet("io.metamask");
      const account = await wallet.connect({
        client,
      });
      // @ts-ignore: TS2554
      const transaction = await prepareContractCall({
        contract,
        method: "function burn(uint256 tokenId)",
        params: [BigInt(tokenId)],
      });
      const { transactionHash } = await sendTransaction({
        transaction: transaction,
        account: account,
      });
    } catch (error) {
      console.error("Error burning NFT:", error);
      // Handle error
    }
  };

  // Function to handle putting an NFT in the marketplace
  const handlePutInMarketplace = async () => {
    try {
      // @ts-ignore: TS2554
      const wallet = createWallet("io.metamask");
      const account = await wallet.connect({
        client,
      });
      const _params = {
        assetContract: contract.address,
        tokenId: BigInt(selectedNFT.token_id),
        quantity: BigInt(1),
        currency: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        pricePerToken: BigInt(1),
        startTimestamp: new Date().getTime(),
        endTimestamp: new Date().getTime() + 1000000,
        reserved: false,
      };
      // console.log(_params);
      // @ts-ignore: TS2554
      const transaction = await prepareContractCall({
        contract: marketContract,
        method:
          "function createListing((address assetContract, uint256 tokenId, uint256 quantity, address currency, uint256 pricePerToken, uint128 startTimestamp, uint128 endTimestamp, bool reserved) _params) returns (uint256 listingId)",
        params: [_params],
      });
      console.log(transaction);
      // @ts-ignore: TS2554
      const { transactionHash } = await sendTransaction({
        transaction: transaction,
        account: account,
      });
      console.log(transactionHash);
      // console.log("Putting NFT in marketplace:", selectedNFT);
      // You can implement the marketplace functionality here
    } catch (error) {
      console.error("Error putting NFT in marketplace:", error);
      // Handle error
    }
  };

  // console.log(all_nft_tokens)
  const InfoBox = useMemo(() => {
    return (
      <div className="flex p-8 items-center bg-gray-100 rounded-xl gap-8">
        <div className="flex-1 flex flex-col items-start justify-between self-stretch">
          <p className="text-4xl font-semibold text-primary text-left">
            {"Contribute to sustainability by offsetting Carbon emissions"}
          </p>
          <div className="flex items-center gep-4">
            <p className="text-start text-sm">
              By purchasing environmental sustainability NFTs, you contribute a
              positive impact on the environment. To offset Carbon emissions,
              simply input the amount of Carbon emissions you want to offset and
              we’d handle the selection. You can also individually select the
              NFTs you want to purchase.
            </p>
          </div>
          <div className="flex gap-3 items-center">
            <div className="bg-white rounded-xl flex p-3 items-center gap-4">
              <ImageIcon size={20} color={colors.BLACK_60} name="fuel" />
              <input className="flex-1" placeholder="Emissions to offset" />
              <ImageIcon
                containerStyle={{
                  background: colors.PRIMARY50 + "aa",
                  height: 30,
                  width: 30,
                  borderRadius: 100,
                  marginTop: -10,
                  marginBottom: -10,
                }}
                name="exchange_arrow"
              />
              <input className="flex-1" placeholder="Price in USD" />
              <ImageIcon size={20} color={colors.BLACK_60} name="money" />
            </div>
            <CustomButton
              onClick={() =>
                purchaseNFT.mutate({ token_id: selectedTokens[0] })
              }
              icon="purchase"
              loading={purchaseNFT.isLoading}
              label="Purchase"
            />
          </div>
          <CustomCheckbox
            value={true}
            label="Auto-select NFTs to meet emissions goal"
          />
        </div>
        <div>
          <img
            alt="illustration-2"
            className="h-[154px] w-[222px] object-contain"
            src={Images.illustration_2}
          />
          <p
            className="p-4 rounded-full mt-4"
            style={{
              color: colors.PRIMARY,
              background: colors.PRIMARY50 + "80",
            }}
          >
            {selectedTokens.length} selected ={" "}
            {all_nft_tokens
              .filter((token) => selectedTokens.includes(token.token_id))
              .map((token) => token.emission)
              .reduce((t, c) => t + c, 0)}{" "}
            kg
          </p>
        </div>
      </div>
    );
  }, [selectedTokens, all_nft_tokens, purchaseNFT]);

  return (
    <>
      {InfoBox}
      <div className="flex gap-2 items-center mt-12">
        <p className="mr-4 font-semibold">NFTs</p>
        <Dropdown options={countries} value="worldwide" />
        <Dropdown options={emissionAmounts} value="1" />
      </div>
      <div className="grid grid-cols-3 gap-4 mt-6">
        {nftMetadata.length === 0 && (
          <p className="text-4xl text-gray-400 text-center mt-20">Loading...</p>
        )}
        {nftMetadata.map((meta, index) => (
          <div
            key={index}
            className="border p-4 rounded-lg cursor-pointer"
            onClick={() => handleNFTClick(meta)}
          >
            <h3 className="text-xl font-bold">{meta.name}</h3>
            <p>{meta.description}</p>
            {meta.image && (
              <img src={meta.image} alt={meta.name} className="w-full h-auto" />
            )}
          </div>
        ))}
      </div>
      {/* Popup component for NFT details and actions */}
      {showPopup && selectedNFT && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-semibold mb-4">{selectedNFT.name}</h2>
            <p className="mb-4">{selectedNFT.description}</p>
            {selectedNFT.image && (
              <img
                src={selectedNFT.image}
                alt={selectedNFT.name}
                className="w-full h-auto mb-4"
              />
            )}
            <div className="flex justify-end gap-2">
              <CustomButton
                onClick={() => handleBurnNFT(selectedNFT.token_id)}
                label="Burn NFT"
              />
              <CustomButton
                onClick={() => handlePutInMarketplace()}
                label="Put in Marketplace"
              />
              <CustomButton onClick={() => setShowPopup(false)} label="Close" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const countries: DropdownOption[] = [
  {
    value: "worldwide",
    label: "Worldwide",
  },
  {
    value: "us",
    label: "United States",
  },
  {
    value: "portugal",
    label: "portugal",
  },
];

const themes: DropdownOption[] = [
  {
    value: "all-themes",
    label: "All Themes",
  },
  {
    value: "wild-life",
    label: "Wild List",
  },
  {
    value: "river-side",
    label: "River Side",
  },
  {
    value: "healthcare",
    label: "Healthcare",
  },
];

const emissionAmounts: DropdownOption[] = [
  {
    value: "1",
    label: "0-1 kgs",
  },
  {
    value: "10",
    label: "1-10 kgs",
  },
  {
    value: "20",
    label: "10-20 kgs",
  },
  {
    value: "100",
    label: "20-100 kgs",
  },
];

const carpoolsTypes = [
  { label: "Donate", value: "donate" },
  { label: "NFT Marketplace", value: "nft-marketplace" },
  { label: "My NFTs", value: "my-nfts" },
];
