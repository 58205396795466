import { FC, useMemo } from "react";
import { Carpool } from "../../../../types/Carpool";
import { StatItem } from "../../../../pages/Statistics";

interface Props {
  userId: string;
  userName?: string;
  filteredCarpools: Carpool[];
}
const TotalCO2User: FC<Props> = ({
  filteredCarpools,
  userId,
  userName,
}): JSX.Element => {
  const totalCO2Savings = useMemo(() => {
    return filteredCarpools
      .filter((carpool) =>
        carpool.passengersArray.map((p) => p.id).includes(userId)
      )
      .reduce(
        (total, carpool) =>
          total + (carpool.passengers[userId]?.co2Amount || 0),
        0
      );
  }, [filteredCarpools, userId]);

  return (
    <div>
      <StatItem
        background="#D0E6DE"
        title={`CO2 Savings - User (${userName})`}
        subTitle="Saved 80% of actual CO2 emission"
        unit="Kg"
        icon="fuel"
        value={totalCO2Savings}
      />
    </div>
  );
};

export default TotalCO2User;
