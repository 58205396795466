import { useLocation } from "react-use"
import firebase from "firebase/compat/app"
import queryString from 'query-string'
import { useEffect } from "react"
import useEvent, { useSelectedEvent } from "./useEvent"
import { useMapCenter } from "../components/common/MapComponent"
import useCorporate from "./useCorporate"

const useSuccessCallback = () => {
    const location = useLocation()
    const { updateEvent, getSingleEvent } = useEvent()
    const { updateCorporate } = useCorporate()
    const [selectedEvent, setSelectedEvent] = useSelectedEvent()
    const [center, setCenter] = useMapCenter()

    const onSuccessCallback = async (params: any) => {
        switch (params.success) {

            case 'event_submitted': {
                await updateEvent.mutateAsync({ event_id: params.event_id as string, data: { status: 'active' } })
                const event = await getSingleEvent(params.event_id)
                setCenter({ lat: event.location.coordinates.latitude, lng: event.location.coordinates.longitude })
                setSelectedEvent(event)
                break
            }

            case 'event_bounty_created': {
                await updateEvent.mutateAsync({ event_id: params.event_id as string, data: { bounty: firebase.firestore.FieldValue.increment(params.amount) as any } })
                break
            }

            case 'corporate_created': {
                console.log('coporate createdd')
                await updateCorporate.mutateAsync({status: 'active', id : params.corporate_id })
                break
            }

            default: break
        }
     
        if (params.success) window.history.pushState({}, document.title, "/");
    }

    const useSuccessSearchParams = () => useEffect(() => {
        const params = queryString.parse(location.search || '')
    
        if (params.success) {
            onSuccessCallback(params)
        }
    }, [location.search])

    return { useSuccessSearchParams }
}

export default useSuccessCallback