import { buildStyles, CircularProgressbar } from "react-circular-progressbar"
import colors from "../../assets/colors"
import useCarpool from "../../hooks/useCarpool"
import Card from "../common/Card"
import StatCardShoulder from "../common/StatCardShoulder"

const CarsStat = () => {

  const {useCarpoolStats} = useCarpool()
  const {data : {onlineCarpools=0, offlineCarpools=0, activeToday=0, joinedToday=0}} = useCarpoolStats()

  return (
    <Card style={{ marginTop: 20, padding: 30, flexDirection: 'row', alignItems: 'center', position: 'relative', pointerEvents: 'all', overflow : 'hidden' }} >

      <StatCardShoulder hueRotation={90} label='Cars' />

      <div style={{ flex: .7 }} />
      <div style={{ height: 150, width: 150, margin: '0 10px', position: 'relative' }} >
        <CircularProgressbar
          styles={buildStyles({
            rotation: 0.25,
            strokeLinecap: 'butt',
            textSize: '16px',
            pathTransitionDuration: 0.5,
            pathColor: colors.PRIMARY,
            trailColor: colors.PRIMARY50,
            backgroundColor: '#3e98c7',
          })}
          strokeWidth={13}
          value={100-onlineCarpools*100/(offlineCarpools+onlineCarpools)} />
        <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
          <p style={{ fontWeight: '900', fontSize: 18 }} >{onlineCarpools}</p>
          <p style={{ fontSize: 10 }} >online</p>
          <p style={{ fontSize: 12, marginTop: 15 }} >{offlineCarpools} offline</p>
        </div>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignSelf: 'stretch' }} >
        {[
          { label: 'active today', value: activeToday },
          { label: 'joined today', value: joinedToday }
        ].map((item, index) => (
          <div key={index} >
            <p style={{ color: item.value < 0 ? colors.RED : colors.GREEN, fontWeight: '900', fontSize: 18 }} >{item.value}</p>
            <p style={{ fontSize: 10 }} >{item.label}</p>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default CarsStat