
import React, { useState } from 'react'
import { FieldErrorsImpl, Path, RegisterOptions, Controller, Control, ControllerRenderProps } from "react-hook-form";
import colors from '../../assets/colors';
import useStorage from '../../hooks/useStorage';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import ImageIcon from './ImageIcon';

type Props<FieldValues> = {
    containerStyle?: React.CSSProperties
    formProps: {
        errors: Partial<FieldErrorsImpl<{ [x: string]: any; }>>
        name: Path<FieldValues>
        options?: Partial<RegisterOptions>
        control: Control<any, any>
    }
}

const ControlledFileInput = <FieldValues,>({ formProps, containerStyle }: Props<FieldValues>) => {


    const ControllerRender = ({ field: { onChange, onBlur, value } }: {
        field: ControllerRenderProps<any, Path<FieldValues>>
    }) => {

        const { selectFile, uploadFile } = useStorage()
        const [selectedFile, setSelectedFile] = useState<File>()
        const [uploadProgress, setUploadProgress] = useState<number>()
        const [url, setUrl] = useState<string>(value)

        const onAddFile = async () => {
            const files = await selectFile(false)
            setSelectedFile(files[0])
            const uploadTask = uploadFile({
                file: files[0],
                path: `event_logo`,
                onProgressChanged: setUploadProgress
            })
            onChange('uploading')
            uploadTask.then(taskSnapshot => taskSnapshot.ref.getDownloadURL().then(url => {
                onChange(url)
                setUrl(url)
                setUploadProgress(undefined)
            }))
        }

        const Progress = () => (
            <div style={{ height: 30, width: 30, margin: '0 10px', position: 'relative', }} >
                <CircularProgressbar
                    styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: 'butt',
                        textSize: '16px',
                        pathTransitionDuration: 0.5,
                        pathColor: colors.PRIMARY,
                        trailColor: colors.PRIMARY50,
                        backgroundColor: '#3e98c7',
                    })}
                    strokeWidth={4}
                    value={uploadProgress||0} />
                <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                    <p style={{ fontSize: 10 }} >{uploadProgress?.toFixed(0)}%</p>
                </div>
            </div>
        )

        return (
            <div
                className='hover-opacity hover-scale'
                onClick={onAddFile}
                style={{ height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', background: colors.BLACK_10, overflow: 'hidden', borderRadius: 10, cursor: 'pointer', ...containerStyle }} >
                {uploadProgress !== undefined && <Progress />}
                {url && <img alt='url' src={url} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />}
                {!url && uploadProgress === undefined && !selectedFile && <ImageIcon name='add_image' size={16} containerStyle={{ background: colors.PRIMARY50, padding: 8, borderRadius: 20 }} />}
            </div>
        )
    }


    return (

        <Controller
            name={formProps.name}
            control={formProps.control}
            rules={formProps.options}
            render={ControllerRender} />


    )
}

export default ControlledFileInput