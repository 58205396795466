import { FC, useMemo } from "react";
import { Carpool } from "../../../../types/Carpool";
import { StatItem } from "../../../../pages/Statistics";

interface Props {
  company: string;
  filteredCarpools: Carpool[];
}
const TotalCO2Company: FC<Props> = ({
  filteredCarpools,
  company,
}): JSX.Element => {
  const totalCO2Savings = useMemo(() => {
    return filteredCarpools
      .filter((carpool) => carpool.destination?.id?.split(".")?.[0] === company)
      .reduce((total, carpool) => total + carpool.co2Amount, 0);
  }, [filteredCarpools, company]);

  return (
    <div>
      <StatItem
        background="#D0E6DE"
        title={`CO2 Savings - All company users`}
        subTitle="Saved 80% of actual CO2 emission"
        unit="Kg"
        icon="fuel"
        value={totalCO2Savings}
      />
    </div>
  );
};

export default TotalCO2Company;
