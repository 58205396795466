// import { loadStripe } from "@stripe/stripe-js";
// import { useEffect } from "react";
import { useMutation } from "react-query";
import { functions } from "../config/firebase";
// import { useAuth } from "../providers/AuthProvider";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// export const stripePromise = loadStripe('pk_test_51Mjc4lHWvXB4mTcEQzBytlc5kYJDSED2AL66ptQ0AYfrR334YvxspwSsKxx7aY9XivoF6oe1gs1nqe3RHblOBdci006PWQBK3W');


const useStripe = () => {

    const createCustomer = async ({ full_name }: { full_name: string }) => {
        const createCustomerFunction = functions.httpsCallable('createStripeCustomer')
        await createCustomerFunction({ name: full_name })
    }

    const createCheckoutSession = useMutation({
        mutationFn: async ({ corporate_id, event_id }: { corporate_id?: string, event_id?: string }) => {
            const createCheckoutSession = functions.httpsCallable('createCheckoutSession')
            const { data: session } = await createCheckoutSession({ corporate_id, event_id })
            window.location.replace(session.url)
        }
    })

    const redirectToStripeCustomerPortal = useMutation({
        mutationFn: async () => {
            const redirectToStripeCustomerPortal = functions.httpsCallable('redirectToStripeCustomerPortal')
            const { data: session } = await redirectToStripeCustomerPortal()
            window.location.replace(session.url)
        }
    })

    return {
        createCustomer,
        createCheckoutSession,
        redirectToStripeCustomerPortal
    }
}

export default useStripe