class Bonus  {
    constructor(data : any){
        Object.assign(this, data)
    }

    name!:string
    description!:string
    id! : string
    value! : {
        type : 'added'|'multiplied'|'multiplied_per_km',
        amount : number
    }
    remark?: string
}


export default Bonus