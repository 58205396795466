import { useMemo, useRef, useState } from "react";
import Icons from "../../assets/Icons";
import { useSelectedCarpool } from "../../hooks/useCarpool";
import useModal from "../../hooks/useModal";
import { Carpool } from "../../types/Carpool";
import Avatar from "../common/Avatar";
import Card from "../common/Card";
import Empty from "../common/Empty";
import StatCardShoulder from "../common/StatCardShoulder";
import EventSummary, { EventSummaryModalProps } from "../modals/EventSummary";
import colors from "../../assets/colors";
import humanizeDuration from "humanize-duration";
import moment from "moment";

const Pools = ({ carpools }: { carpools: Carpool[] }) => {
  const listHeaderRef = useRef<HTMLDivElement>(null);

  const [selectedCarpool, setSelectedCarpool] = useSelectedCarpool();

  return (
    <Card
      style={{
        marginTop: 20,
        position: "relative",
        pointerEvents: "all",
        flex: 1,
        overflowY: "auto",
        minWidth: 400,
      }}
    >
      <div
        ref={listHeaderRef}
        style={{
          padding: 20,
          paddingBottom: 10,
          borderStyle: "dashed",
          borderColor: colors.BLACK_20,
          borderWidth: 0,
          borderBottomWidth: 0,
        }}
      >
        <p style={{ textAlign: "start", fontWeight: "600" }}>Carpools</p>
      </div>
      {carpools.length === 0 && <Empty message="No carpools" />}

      <div
        onScrollCapture={(e) =>
          (listHeaderRef.current!.style.borderBottomWidth =
            (e.target as any).scrollTop > 25 ? "1px" : "0px")
        }
        style={{
          gap: 10,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          padding: 20,
        }}
      >
        {carpools.map((item, index) => (
          <CarpoolItem
            carpool={item}
            key={index}
            selectedCarpool={selectedCarpool}
            setSelectedCarpool={setSelectedCarpool}
          />
        ))}
      </div>
    </Card>
  );
};

export default Pools;

type bonus = {
  name: string;
  description: string;
  multiplier: number;
  condition: (params: any) => boolean;
};
// carpool, userData,

const CarpoolItem = ({
  carpool,
  selectedCarpool,
  setSelectedCarpool,
}: {
  carpool: Carpool;
  selectedCarpool?: Carpool;
  setSelectedCarpool: (carpool?: Carpool) => void;
}) => {
  const selected = selectedCarpool?.id === carpool.id;

  const onClick = async () => {
    if (selected) setSelectedCarpool(undefined);
    else {
      await setSelectedCarpool(undefined);
      setSelectedCarpool(carpool);
    }
  };

  return (
    <div
      onClick={onClick}
      className={`hover-tint ${selected ? "selected" : ""}`}
      style={{
        display: "flex",
        alignItems: "center",
        padding: 10,
        cursor: "pointer",
      }}
    >
      <Avatar
        url={carpool.ownerData?.avatar}
        id={carpool.owner}
        name={carpool.ownerData?.username || carpool.ownerData?.name}
        size={45}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "flex-start",
          marginLeft: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontSize: 10 }}>Carpool • </p>
          <img alt="fuel" style={{ height: 12 }} src={Icons.fuel} />
        </div>

        <p style={{ fontWeight: "800" }}>{carpool.ownerData?.name}</p>
        <p style={{ fontSize: 10, textAlign: "start" }}>
          Carpool Time:{" "}
          {humanizeDuration(carpool.completedAt - carpool.createdAt, {
            largest: 1,
            maxDecimalPoints: 1,
          })}
          {"\n"}
        </p>
        <p style={{ fontSize: 10, textAlign: "start" }}>
          Vehicle: {carpool.car?.make} {carpool.car?.model}
        </p>
        <p style={{ fontSize: 10, textAlign: "start" }}>
          Started At: {moment(carpool.createdAt).format("MMM DD YYYY hh:mm a")}
        </p>
      </div>
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: 8,
        }}
      >
        <PassengerList carpool={carpool} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <img alt="hexagon" style={{ height: 18 }} src={Icons.hexagon} />
          <p style={{ fontWeight: "900", fontSize: 14, marginLeft: 5 }}>
            {carpool.ownerData?.credits || "- -"}
          </p>
        </div>
        <p style={{ fontSize: 10 }}>
          {carpool.co2Amount.toFixed(1)} kilo CO2 saved
        </p>
      </div>
    </div>
  );
};

const PassengerList = ({ carpool }: { carpool: Carpool }) => {
  const [showName, setShowNames] = useState(false);

  const validPassengers = useMemo(
    () =>
      carpool.passengersArray
        .filter(
          (p) =>
            ["accepted", "picked-up", "dropped-off"].includes(p.status) ||
            carpool.owner === p.id
        )
        .reverse(),
    [carpool]
  );
  return (
    <div
      onMouseEnter={() => setShowNames(true)}
      onMouseLeave={() => setShowNames(false)}
      style={{ height: 22.5 }}
      className="flex row items-center"
    >
      {showName ? (
        <p style={{ fontSize: 10, textAlign: "end" }}>
          {validPassengers.map((p) => p.name.split(" ")[0]).join(", ")}
        </p>
      ) : (
        <div className="flex row scale-75">
          {validPassengers.map((passenger, index) => (
            <Avatar
              key={index}
              style={{ marginRight: -5, borderWidth: 1 }}
              size={30}
              name={passenger.name}
              id={passenger.id}
              url={passenger.avatar}
            />
          ))}
        </div>
      )}
    </div>
  );
};
