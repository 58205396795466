import React, { ReactNode, useContext, useState } from "react";
import { db, functions } from "../config/firebase";
import useFirestoreRealtimeDoc from "../hooks/useFirestoreRealtimeDoc";

// functions.useEmulator('127.0.0.1', 5001)

interface AppContextInterface {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  config?: {
    admins: string[];
    testers: string[];
  };
}

// const correctPastValues = async () => {
//   const userId = "qhfldcTnMmWZIiAY49N68PDbQxm2";

//   const carpools = await db
//     .collection("carpools")
//     .where("status", "==", "completed")
//     .where(`passengers.${userId}.id`, "==", userId)
//     .get();

//   const filteredCarpools = carpools.docs.filter(
//     (doc) => doc.get("completedAt") - doc.get("createdAt") > 120000
//   );

//   const co2Savings = filteredCarpools
//     .map((doc) => doc.get(`passengers.${userId}.co2Amount`) || 0)
//     .reduce((t, c) => t + c, 0);

//   const credits = filteredCarpools
//     .map((doc) => doc.get(`passengers.${userId}.credits`) || 0)
//     .reduce((t, c) => t + c, 0);

//   const updateData = {
//     total_carpools: filteredCarpools.length,
//     total_offers: filteredCarpools.filter((doc) => doc.get("owner") === userId)
//       .length,
//     total_co2: co2Savings,
//     credits,
//   };

//   console.log(updateData);

//   await db.collection("users").doc(userId).update(updateData);
// };

// correctPastValues();

// const correction = async () => {
//   //7Dbgl5Ja0KEjUgwzQkTD_TEST

//   console.log("RUNNING CORRECTION");

//   const carpools = await db
//     .collection("carpools")
//     .where("owner", "==", "6aEs727dZTVDq3nqxu5QIyDumZB3")
//     .where("corporate", "==", "7Dbgl5Ja0KEjUgwzQkTD")
//     .get();

//   const batch = db.batch();

//   carpools.docs.forEach((doc) =>
//     batch.update(doc.ref, { corporate: "7Dbgl5Ja0KEjUgwzQkTD_TEST" })
//   );

//   await batch.commit();
// };

// correction();

const AppContext = React.createContext({} as AppContextInterface);

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);

  const { data: config } = useFirestoreRealtimeDoc({
    query: db.collection("config").doc("internal_dashboard_config"),
    select: (doc) => doc?.data() as { admins: string[]; testers: string[] },
  });

  return (
    <AppContext.Provider value={{ loading, setLoading, config }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useApp = () => {
  const context = useContext(AppContext);

  return {
    ...context,
  };
};
