import axios from "axios"

const useGeolocation = () => {
    return {
        getCurrentPosition: () => new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                position => resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude }),
                err => axios.get('https://ipapi.co/json/')
                    .then(res => resolve({ latitude: res.data.latitude, longitude: res.data.longitude }))
                    .catch(reject)
            )
        })as Promise<{latitude : number, longitude : number}>
    }
}

export default useGeolocation