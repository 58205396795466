export enum Companies {
  Company1 = "Company1",
  Company2 = "Company2",
  Company3 = "Company3",
}

export enum Teams {
  TeamA = "TeamA",
  TeamB = "TeamB",
  TeamC = "TeamC",
}

export enum TripDirection {
  FromWork = "from work",
  ToWork = "to work",
  // Add more directions as needed
}

export const CarpoolsData = [
  {
    raw: {
      id: "8292057714690",
      directions: {
        status: "OK",
        geocoded_waypoints: [
          {
            geocoder_status: "OK",
            types: ["establishment", "moving_company", "point_of_interest"],
            place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
          },
          {
            types: ["establishment", "general_contractor", "point_of_interest"],
            geocoder_status: "OK",
            place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
          },
          {
            place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
            geocoder_status: "OK",
            types: ["premise"],
          },
          {
            types: ["street_address"],
            geocoder_status: "OK",
            place_id:
              "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
          },
        ],
        routes: [
          {
            overview_polyline: {
              points:
                "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
            },
            waypoint_order: [0, 1],
            bounds: {
              northeast: { lat: 25.291958, lng: 51.518743 },
              southwest: { lat: 25.2132031, lng: 51.4319062 },
            },
            warnings: [],
            copyrights: "Map data ©2024 Google",
            summary: "Al Rayyan Rd",
            legs: [
              {
                distance: { value: 5407, text: "5.4 km" },
                start_address: "858, الدوحة، Qatar",
                via_waypoint: [],
                traffic_speed_entry: [],
                start_location: { lng: 51.4906083, lat: 25.2827107 },
                end_address: "7GP7+6RG, Doha, Qatar",
                steps: [
                  {
                    polyline: { points: "}_iyCiwgyHz@B" },
                    distance: { value: 33, text: "33 m" },
                    end_location: { lng: 51.4905936, lat: 25.2824136 },
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                    start_location: { lng: 51.4906083, lat: 25.2827107 },
                    duration: { value: 4, text: "1 min" },
                  },
                  {
                    end_location: { lat: 25.2801532, lng: 51.4871118 },
                    travel_mode: "DRIVING",
                    distance: { value: 574, text: "0.6 km" },
                    start_location: { lng: 51.4905936, lat: 25.2824136 },
                    html_instructions:
                      "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                    polyline: {
                      points:
                        "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                    },
                    duration: { text: "1 min", value: 49 },
                  },
                  {
                    start_location: { lng: 51.4871118, lat: 25.2801532 },
                    travel_mode: "DRIVING",
                    distance: { value: 426, text: "0.4 km" },
                    polyline: {
                      points:
                        "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                    },
                    maneuver: "turn-left",
                    end_location: { lat: 25.2801774, lng: 51.49079529999999 },
                    html_instructions: "Turn <b>left</b>",
                    duration: { value: 44, text: "1 min" },
                  },
                  {
                    travel_mode: "DRIVING",
                    duration: { value: 8, text: "1 min" },
                    maneuver: "straight",
                    html_instructions:
                      "Continue straight onto <b>Al Sadd St</b>",
                    distance: { value: 93, text: "93 m" },
                    end_location: { lng: 51.4917143, lat: 25.2802165 },
                    polyline: { points: "cphyCoxgyHEaBAsA" },
                    start_location: { lat: 25.2801774, lng: 51.49079529999999 },
                  },
                  {
                    end_location: { lat: 25.2804496, lng: 51.4940386 },
                    duration: { text: "1 min", value: 71 },
                    travel_mode: "DRIVING",
                    polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                    html_instructions: "Slight <b>left</b>",
                    distance: { text: "0.2 km", value: 236 },
                    maneuver: "turn-slight-left",
                    start_location: { lng: 51.4917143, lat: 25.2802165 },
                  },
                  {
                    polyline: { points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O" },
                    duration: { text: "1 min", value: 38 },
                    end_location: { lng: 51.4947254, lat: 25.281604 },
                    html_instructions:
                      'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                    travel_mode: "DRIVING",
                    maneuver: "turn-left",
                    distance: { text: "0.2 km", value: 163 },
                    start_location: { lat: 25.2804496, lng: 51.4940386 },
                  },
                  {
                    duration: { value: 76, text: "1 min" },
                    travel_mode: "DRIVING",
                    start_location: { lng: 51.4947254, lat: 25.281604 },
                    html_instructions:
                      "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                    polyline: {
                      points:
                        "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                    },
                    maneuver: "keep-left",
                    distance: { text: "1.1 km", value: 1109 },
                    end_location: { lat: 25.2914195, lng: 51.4965617 },
                  },
                  {
                    duration: { text: "3 mins", value: 209 },
                    travel_mode: "DRIVING",
                    start_location: { lat: 25.2914195, lng: 51.4965617 },
                    end_location: { lat: 25.2893902, lng: 51.51516729999999 },
                    distance: { value: 1922, text: "1.9 km" },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                    maneuver: "turn-right",
                    polyline: {
                      points:
                        "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                    },
                  },
                  {
                    distance: { text: "0.1 km", value: 144 },
                    maneuver: "turn-right",
                    duration: { value: 15, text: "1 min" },
                    start_location: { lng: 51.51516729999999, lat: 25.2893902 },
                    travel_mode: "DRIVING",
                    polyline: {
                      points:
                        "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                    },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Khaleej</b>",
                    end_location: { lng: 51.51591370000001, lat: 25.2884741 },
                  },
                  {
                    travel_mode: "DRIVING",
                    start_location: { lat: 25.2884741, lng: 51.51591370000001 },
                    duration: { text: "1 min", value: 11 },
                    polyline: { points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E" },
                    distance: { value: 122, text: "0.1 km" },
                    html_instructions:
                      'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                    end_location: { lat: 25.2874383, lng: 51.516228 },
                  },
                  {
                    duration: { text: "1 min", value: 21 },
                    end_location: { lng: 51.51678399999999, lat: 25.2849434 },
                    start_location: { lng: 51.516228, lat: 25.2874383 },
                    polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                    html_instructions:
                      'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                    travel_mode: "DRIVING",
                    maneuver: "turn-slight-right",
                    distance: { value: 283, text: "0.3 km" },
                  },
                  {
                    start_location: { lng: 51.51678399999999, lat: 25.2849434 },
                    duration: { value: 32, text: "1 min" },
                    travel_mode: "DRIVING",
                    end_location: { lng: 51.5155496, lat: 25.2848165 },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                    distance: { value: 125, text: "0.1 km" },
                    polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                    maneuver: "turn-right",
                  },
                  {
                    end_location: { lat: 25.2850716, lng: 51.5150352 },
                    maneuver: "turn-right",
                    duration: { text: "1 min", value: 21 },
                    distance: { value: 74, text: "74 m" },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                    travel_mode: "DRIVING",
                    polyline: { points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X" },
                    start_location: { lat: 25.2848165, lng: 51.5155496 },
                  },
                  {
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                    start_location: { lat: 25.2850716, lng: 51.5150352 },
                    end_location: { lat: 25.2856417, lng: 51.51497639999999 },
                    polyline: { points: "uniyC_plyHqBJ" },
                    duration: { text: "1 min", value: 23 },
                    maneuver: "turn-right",
                    travel_mode: "DRIVING",
                    distance: { value: 64, text: "64 m" },
                  },
                  {
                    polyline: { points: "griyCsolyH?p@DZ" },
                    duration: { text: "1 min", value: 14 },
                    distance: { text: "39 m", value: 39 },
                    end_location: { lng: 51.5145907, lat: 25.2856124 },
                    start_location: { lat: 25.2856417, lng: 51.51497639999999 },
                    travel_mode: "DRIVING",
                    html_instructions:
                      'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                    maneuver: "turn-left",
                  },
                ],
                duration: { text: "11 mins", value: 636 },
                end_location: { lng: 51.5145907, lat: 25.2856124 },
              },
              {
                traffic_speed_entry: [],
                duration: { text: "10 mins", value: 598 },
                start_location: { lng: 51.5145907, lat: 25.2856124 },
                distance: { text: "4.5 km", value: 4489 },
                steps: [
                  {
                    start_location: { lng: 51.5145907, lat: 25.2856124 },
                    end_location: { lat: 25.2859334, lng: 51.5165533 },
                    duration: { text: "1 min", value: 73 },
                    distance: { value: 201, text: "0.2 km" },
                    travel_mode: "DRIVING",
                    polyline: {
                      points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                    },
                    html_instructions:
                      "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                  },
                  {
                    travel_mode: "DRIVING",
                    polyline: {
                      points:
                        "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                    },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                    end_location: { lng: 51.518743, lat: 25.2796568 },
                    distance: { text: "0.7 km", value: 735 },
                    duration: { value: 78, text: "1 min" },
                    start_location: { lng: 51.5165533, lat: 25.2859334 },
                    maneuver: "turn-right",
                  },
                  {
                    html_instructions:
                      "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                    end_location: { lat: 25.2769643, lng: 51.5164061 },
                    polyline: {
                      points:
                        "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                    },
                    start_location: { lat: 25.2796568, lng: 51.518743 },
                    distance: { value: 393, text: "0.4 km" },
                    travel_mode: "DRIVING",
                    duration: { value: 59, text: "1 min" },
                    maneuver: "turn-slight-right",
                  },
                  {
                    end_location: { lng: 51.500835, lat: 25.2663847 },
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                    start_location: { lng: 51.5164061, lat: 25.2769643 },
                    maneuver: "merge",
                    distance: { value: 1963, text: "2.0 km" },
                    polyline: {
                      points:
                        "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                    },
                    duration: { text: "4 mins", value: 229 },
                  },
                  {
                    html_instructions:
                      "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                    distance: { value: 428, text: "0.4 km" },
                    duration: { text: "1 min", value: 40 },
                    start_location: { lng: 51.500835, lat: 25.2663847 },
                    end_location: { lat: 25.2643805, lng: 51.497229 },
                    maneuver: "turn-slight-right",
                    travel_mode: "DRIVING",
                    polyline: {
                      points:
                        "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                    },
                  },
                  {
                    start_location: { lat: 25.2643805, lng: 51.497229 },
                    maneuver: "roundabout-right",
                    polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                    html_instructions:
                      "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                    end_location: { lat: 25.2656775, lng: 51.4962302 },
                    distance: { value: 182, text: "0.2 km" },
                    travel_mode: "DRIVING",
                    duration: { text: "1 min", value: 22 },
                  },
                  {
                    maneuver: "turn-slight-right",
                    distance: { value: 25, text: "25 m" },
                    polyline: { points: "oueyCmzhyH]DKB" },
                    duration: { text: "1 min", value: 3 },
                    html_instructions:
                      "Slight <b>right</b> onto <b>Al Nasr St</b>",
                    end_location: { lng: 51.4961765, lat: 25.2658942 },
                    start_location: { lat: 25.2656775, lng: 51.4962302 },
                    travel_mode: "DRIVING",
                  },
                  {
                    distance: { text: "0.4 km", value: 389 },
                    html_instructions:
                      "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                    maneuver: "turn-slight-right",
                    travel_mode: "DRIVING",
                    duration: { value: 53, text: "1 min" },
                    polyline: {
                      points: "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                    },
                    end_location: { lat: 25.267547, lng: 51.4995317 },
                    start_location: { lng: 51.4961765, lat: 25.2658942 },
                  },
                  {
                    travel_mode: "DRIVING",
                    polyline: { points: "eafyCaoiyHQFiDdA" },
                    distance: { text: "0.1 km", value: 111 },
                    start_location: { lat: 25.267547, lng: 51.4995317 },
                    html_instructions:
                      "Turn <b>left</b> onto <b>Mohammed St</b>",
                    duration: { value: 22, text: "1 min" },
                    maneuver: "turn-left",
                    end_location: { lat: 25.2684856, lng: 51.499144 },
                  },
                  {
                    maneuver: "turn-left",
                    start_location: { lat: 25.2684856, lng: 51.499144 },
                    html_instructions:
                      'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                    end_location: { lng: 51.49855549999999, lat: 25.2683307 },
                    travel_mode: "DRIVING",
                    duration: { text: "1 min", value: 19 },
                    distance: { value: 62, text: "62 m" },
                    polyline: { points: "agfyCsliyH\\vABNAJ" },
                  },
                ],
                start_address: "7GP7+6RG, Doha, Qatar",
                end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                via_waypoint: [],
                end_address: "7F9X+C9V, Doha, Qatar",
              },
              {
                traffic_speed_entry: [],
                start_address: "7F9X+C9V, Doha, Qatar",
                start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                via_waypoint: [],
                end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                steps: [
                  {
                    polyline: { points: "affyC_iiyH@KCO]wA" },
                    duration: { value: 14, text: "1 min" },
                    end_location: { lng: 51.499144, lat: 25.2684856 },
                    html_instructions:
                      "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                    start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                    distance: { value: 62, text: "62 m" },
                    travel_mode: "DRIVING",
                  },
                  {
                    html_instructions:
                      "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                    start_location: { lat: 25.2684856, lng: 51.499144 },
                    end_location: { lng: 51.499066, lat: 25.2687298 },
                    polyline: { points: "agfyCsliyHo@L" },
                    travel_mode: "DRIVING",
                    duration: { text: "1 min", value: 9 },
                    distance: { text: "28 m", value: 28 },
                    maneuver: "turn-left",
                  },
                  {
                    start_location: { lat: 25.2687298, lng: 51.499066 },
                    travel_mode: "DRIVING",
                    distance: { value: 154, text: "0.2 km" },
                    maneuver: "turn-right",
                    duration: { text: "1 min", value: 32 },
                    end_location: { lat: 25.2692232, lng: 51.500496 },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Baraha</b>",
                    polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                  },
                  {
                    distance: { value: 69, text: "69 m" },
                    end_location: { lat: 25.269806, lng: 51.500265 },
                    start_location: { lng: 51.500496, lat: 25.2692232 },
                    polyline: { points: "skfyCcuiyH{@Ty@X" },
                    duration: { text: "1 min", value: 13 },
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                    maneuver: "turn-left",
                  },
                  {
                    end_location: { lat: 25.2705523, lng: 51.50232339999999 },
                    maneuver: "turn-right",
                    html_instructions:
                      "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                    start_location: { lng: 51.500265, lat: 25.269806 },
                    polyline: { points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA" },
                    duration: { value: 29, text: "1 min" },
                    distance: { text: "0.2 km", value: 223 },
                    travel_mode: "DRIVING",
                  },
                  {
                    end_location: { lat: 25.2679045, lng: 51.5033973 },
                    travel_mode: "DRIVING",
                    maneuver: "roundabout-right",
                    start_location: { lat: 25.2705523, lng: 51.50232339999999 },
                    duration: { value: 48, text: "1 min" },
                    polyline: {
                      points:
                        "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                    },
                    html_instructions:
                      "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                    distance: { value: 326, text: "0.3 km" },
                  },
                  {
                    html_instructions: "Merge onto <b>Salwa Rd</b>",
                    end_location: { lat: 25.2368244, lng: 51.45117 },
                    travel_mode: "DRIVING",
                    distance: { value: 6288, text: "6.3 km" },
                    start_location: { lng: 51.5033973, lat: 25.2679045 },
                    duration: { value: 283, text: "5 mins" },
                    polyline: {
                      points:
                        "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                    },
                    maneuver: "merge",
                  },
                  {
                    duration: { value: 10, text: "1 min" },
                    maneuver: "ramp-right",
                    start_location: { lat: 25.2368244, lng: 51.45117 },
                    html_instructions:
                      'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                    travel_mode: "DRIVING",
                    distance: { value: 194, text: "0.2 km" },
                    polyline: { points: "ca`yCy``yHpAnDlAdD" },
                    end_location: { lat: 25.2360177, lng: 51.4494587 },
                  },
                  {
                    maneuver: "merge",
                    html_instructions:
                      "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                    start_location: { lng: 51.4494587, lat: 25.2360177 },
                    distance: { value: 397, text: "0.4 km" },
                    duration: { text: "1 min", value: 33 },
                    end_location: { lng: 51.4461502, lat: 25.2340694 },
                    polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                    travel_mode: "DRIVING",
                  },
                  {
                    end_location: { lng: 51.4456607, lat: 25.2337942 },
                    html_instructions:
                      "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                    maneuver: "straight",
                    polyline: { points: "}o_yCma_yHP`@d@~@" },
                    distance: { text: "58 m", value: 58 },
                    duration: { value: 8, text: "1 min" },
                    travel_mode: "DRIVING",
                    start_location: { lat: 25.2340694, lng: 51.4461502 },
                  },
                  {
                    distance: { text: "1.5 km", value: 1451 },
                    maneuver: "straight",
                    start_location: { lat: 25.2337942, lng: 51.4456607 },
                    end_location: { lat: 25.2267513, lng: 51.43351759999999 },
                    travel_mode: "DRIVING",
                    duration: { text: "2 mins", value: 90 },
                    polyline: {
                      points:
                        "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                    },
                    html_instructions:
                      'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                  },
                  {
                    html_instructions:
                      "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                    travel_mode: "DRIVING",
                    polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                    start_location: { lat: 25.2267513, lng: 51.43351759999999 },
                    maneuver: "turn-slight-left",
                    distance: { value: 92, text: "92 m" },
                    end_location: { lat: 25.2262036, lng: 51.4328437 },
                    duration: { text: "1 min", value: 10 },
                  },
                  {
                    polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                    end_location: { lat: 25.2257335, lng: 51.4323655 },
                    start_location: { lng: 51.4328437, lat: 25.2262036 },
                    html_instructions: "Continue straight",
                    duration: { value: 19, text: "1 min" },
                    maneuver: "straight",
                    distance: { value: 71, text: "71 m" },
                    travel_mode: "DRIVING",
                  },
                  {
                    distance: { value: 813, text: "0.8 km" },
                    travel_mode: "DRIVING",
                    duration: { value: 72, text: "1 min" },
                    end_location: { lng: 51.4357808, lat: 25.2196178 },
                    html_instructions: "Continue onto <b>E Industrial Rd</b>",
                    start_location: { lat: 25.2257335, lng: 51.4323655 },
                    polyline: {
                      points:
                        "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                    },
                  },
                  {
                    duration: { text: "1 min", value: 27 },
                    html_instructions: "Turn <b>right</b> onto <b>Street 2</b>",
                    distance: { value: 263, text: "0.3 km" },
                    end_location: { lng: 51.43371639999999, lat: 25.2182661 },
                    start_location: { lng: 51.4357808, lat: 25.2196178 },
                    polyline: { points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA" },
                    maneuver: "turn-right",
                    travel_mode: "DRIVING",
                  },
                  {
                    travel_mode: "DRIVING",
                    distance: { value: 682, text: "0.7 km" },
                    start_location: { lat: 25.2182661, lng: 51.43371639999999 },
                    end_location: { lng: 51.4366206, lat: 25.2135618 },
                    duration: { value: 78, text: "1 min" },
                    polyline: {
                      points:
                        "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                    },
                    html_instructions:
                      "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                    maneuver: "roundabout-right",
                  },
                  {
                    maneuver: "turn-right",
                    html_instructions: "Turn <b>right</b> onto <b>Street 8</b>",
                    polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                    end_location: { lat: 25.2133289, lng: 51.4364944 },
                    travel_mode: "DRIVING",
                    duration: { text: "1 min", value: 12 },
                    start_location: { lng: 51.4366206, lat: 25.2135618 },
                    distance: { value: 29, text: "29 m" },
                  },
                  {
                    travel_mode: "DRIVING",
                    duration: { value: 8, text: "1 min" },
                    start_location: { lat: 25.2133289, lng: 51.4364944 },
                    polyline: { points: "in{xCae}xHXO" },
                    distance: { text: "16 m", value: 16 },
                    html_instructions: "Turn <b>left</b>",
                    maneuver: "turn-left",
                    end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                  },
                ],
                duration: { value: 795, text: "13 mins" },
                distance: { value: 11216, text: "11.2 km" },
                end_address: "28 Street 8, Ar Rayyan, Qatar",
              },
            ],
          },
        ],
      },
      completedAt: 1707942351003,
      createdAt: 1707942285310,
      owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
      destination: {
        coordinates: { longitude: 51.43657469999999, latitude: 25.213204 },
        address: "Street 8, Doha, Qatar",
        type: "corporate-branch",
        id: "9VZwCrYZfl03ob5FrD4F.0",
        geohash: "thkx6myvx1",
      },
      co2Amount: 12.612562888268984,
      origin: {
        coordinates: { longitude: 51.489361, latitude: 25.282848 },
        geohash: "thkxgdt7m0",
        address: "858, الدوحة، Qatar",
      },
      car: {
        year: 2021,
        type: "carbon-emitting",
        model: "LAND CRUSER WAGON 4WD",
        make: "Toyota",
        co2_p_km: 0.3379843740994449,
        seat: 3,
      },
      rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
      preferences: {
        female_passengers_only: null,
        verified_passengers_only: null,
      },
      passengers: {
        C1GECLApV4W0bVtgeFk4iRpZYRl1: {
          userId: "user_id",
          name: "Jassim",
          username: "abcd",
          location: {
            geohash: "thkxgdt7m0",
            coordinates: { latitude: 25.282848, longitude: 51.489361 },
            address: "858, الدوحة، Qatar",
          },
          credits: 27.8,
          co2Amount: 4.313694566631215,
          avatar:
            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
          seat: 0,
          id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
          wallet_id: "spencer_carbon.testnet",
          carpool: {
            id: "8292057714690",
            directions: {
              status: "OK",
              geocoded_waypoints: [
                {
                  geocoder_status: "OK",
                  types: [
                    "establishment",
                    "moving_company",
                    "point_of_interest",
                  ],
                  place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                },
                {
                  types: [
                    "establishment",
                    "general_contractor",
                    "point_of_interest",
                  ],
                  geocoder_status: "OK",
                  place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                },
                {
                  place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                  geocoder_status: "OK",
                  types: ["premise"],
                },
                {
                  types: ["street_address"],
                  geocoder_status: "OK",
                  place_id:
                    "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                },
              ],
              routes: [
                {
                  overview_polyline: {
                    points:
                      "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                  },
                  waypoint_order: [0, 1],
                  bounds: {
                    northeast: { lat: 25.291958, lng: 51.518743 },
                    southwest: { lat: 25.2132031, lng: 51.4319062 },
                  },
                  warnings: [],
                  copyrights: "Map data ©2024 Google",
                  summary: "Al Rayyan Rd",
                  legs: [
                    {
                      distance: { value: 5407, text: "5.4 km" },
                      start_address: "858, الدوحة، Qatar",
                      via_waypoint: [],
                      traffic_speed_entry: [],
                      start_location: { lng: 51.4906083, lat: 25.2827107 },
                      end_address: "7GP7+6RG, Doha, Qatar",
                      steps: [
                        {
                          polyline: { points: "}_iyCiwgyHz@B" },
                          distance: { value: 33, text: "33 m" },
                          end_location: { lng: 51.4905936, lat: 25.2824136 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                          start_location: { lng: 51.4906083, lat: 25.2827107 },
                          duration: { value: 4, text: "1 min" },
                        },
                        {
                          end_location: { lat: 25.2801532, lng: 51.4871118 },
                          travel_mode: "DRIVING",
                          distance: { value: 574, text: "0.6 km" },
                          start_location: { lng: 51.4905936, lat: 25.2824136 },
                          html_instructions:
                            "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                          polyline: {
                            points:
                              "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                          },
                          duration: { text: "1 min", value: 49 },
                        },
                        {
                          start_location: { lng: 51.4871118, lat: 25.2801532 },
                          travel_mode: "DRIVING",
                          distance: { value: 426, text: "0.4 km" },
                          polyline: {
                            points:
                              "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                          },
                          maneuver: "turn-left",
                          end_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                          html_instructions: "Turn <b>left</b>",
                          duration: { value: 44, text: "1 min" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          maneuver: "straight",
                          html_instructions:
                            "Continue straight onto <b>Al Sadd St</b>",
                          distance: { value: 93, text: "93 m" },
                          end_location: { lng: 51.4917143, lat: 25.2802165 },
                          polyline: { points: "cphyCoxgyHEaBAsA" },
                          start_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                        },
                        {
                          end_location: { lat: 25.2804496, lng: 51.4940386 },
                          duration: { text: "1 min", value: 71 },
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                          },
                          html_instructions: "Slight <b>left</b>",
                          distance: { text: "0.2 km", value: 236 },
                          maneuver: "turn-slight-left",
                          start_location: { lng: 51.4917143, lat: 25.2802165 },
                        },
                        {
                          polyline: {
                            points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                          },
                          duration: { text: "1 min", value: 38 },
                          end_location: { lng: 51.4947254, lat: 25.281604 },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          distance: { text: "0.2 km", value: 163 },
                          start_location: { lat: 25.2804496, lng: 51.4940386 },
                        },
                        {
                          duration: { value: 76, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.4947254, lat: 25.281604 },
                          html_instructions:
                            "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                          polyline: {
                            points:
                              "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                          },
                          maneuver: "keep-left",
                          distance: { text: "1.1 km", value: 1109 },
                          end_location: { lat: 25.2914195, lng: 51.4965617 },
                        },
                        {
                          duration: { text: "3 mins", value: 209 },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2914195, lng: 51.4965617 },
                          end_location: {
                            lat: 25.2893902,
                            lng: 51.51516729999999,
                          },
                          distance: { value: 1922, text: "1.9 km" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                          },
                        },
                        {
                          distance: { text: "0.1 km", value: 144 },
                          maneuver: "turn-right",
                          duration: { value: 15, text: "1 min" },
                          start_location: {
                            lng: 51.51516729999999,
                            lat: 25.2893902,
                          },
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej</b>",
                          end_location: {
                            lng: 51.51591370000001,
                            lat: 25.2884741,
                          },
                        },
                        {
                          travel_mode: "DRIVING",
                          start_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          duration: { text: "1 min", value: 11 },
                          polyline: {
                            points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                          },
                          distance: { value: 122, text: "0.1 km" },
                          html_instructions:
                            'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                          end_location: { lat: 25.2874383, lng: 51.516228 },
                        },
                        {
                          duration: { text: "1 min", value: 21 },
                          end_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          start_location: { lng: 51.516228, lat: 25.2874383 },
                          polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                          html_instructions:
                            'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                          travel_mode: "DRIVING",
                          maneuver: "turn-slight-right",
                          distance: { value: 283, text: "0.3 km" },
                        },
                        {
                          start_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          duration: { value: 32, text: "1 min" },
                          travel_mode: "DRIVING",
                          end_location: { lng: 51.5155496, lat: 25.2848165 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                          distance: { value: 125, text: "0.1 km" },
                          polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                          maneuver: "turn-right",
                        },
                        {
                          end_location: { lat: 25.2850716, lng: 51.5150352 },
                          maneuver: "turn-right",
                          duration: { text: "1 min", value: 21 },
                          distance: { value: 74, text: "74 m" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                          },
                          start_location: { lat: 25.2848165, lng: 51.5155496 },
                        },
                        {
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          start_location: { lat: 25.2850716, lng: 51.5150352 },
                          end_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          polyline: { points: "uniyC_plyHqBJ" },
                          duration: { text: "1 min", value: 23 },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          distance: { value: 64, text: "64 m" },
                        },
                        {
                          polyline: { points: "griyCsolyH?p@DZ" },
                          duration: { text: "1 min", value: 14 },
                          distance: { text: "39 m", value: 39 },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                          start_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          travel_mode: "DRIVING",
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          maneuver: "turn-left",
                        },
                      ],
                      duration: { text: "11 mins", value: 636 },
                      end_location: { lng: 51.5145907, lat: 25.2856124 },
                    },
                    {
                      traffic_speed_entry: [],
                      duration: { text: "10 mins", value: 598 },
                      start_location: { lng: 51.5145907, lat: 25.2856124 },
                      distance: { text: "4.5 km", value: 4489 },
                      steps: [
                        {
                          start_location: { lng: 51.5145907, lat: 25.2856124 },
                          end_location: { lat: 25.2859334, lng: 51.5165533 },
                          duration: { text: "1 min", value: 73 },
                          distance: { value: 201, text: "0.2 km" },
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                          },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                        },
                        {
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                          end_location: { lng: 51.518743, lat: 25.2796568 },
                          distance: { text: "0.7 km", value: 735 },
                          duration: { value: 78, text: "1 min" },
                          start_location: { lng: 51.5165533, lat: 25.2859334 },
                          maneuver: "turn-right",
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                          end_location: { lat: 25.2769643, lng: 51.5164061 },
                          polyline: {
                            points:
                              "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                          },
                          start_location: { lat: 25.2796568, lng: 51.518743 },
                          distance: { value: 393, text: "0.4 km" },
                          travel_mode: "DRIVING",
                          duration: { value: 59, text: "1 min" },
                          maneuver: "turn-slight-right",
                        },
                        {
                          end_location: { lng: 51.500835, lat: 25.2663847 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                          start_location: { lng: 51.5164061, lat: 25.2769643 },
                          maneuver: "merge",
                          distance: { value: 1963, text: "2.0 km" },
                          polyline: {
                            points:
                              "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                          },
                          duration: { text: "4 mins", value: 229 },
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                          distance: { value: 428, text: "0.4 km" },
                          duration: { text: "1 min", value: 40 },
                          start_location: { lng: 51.500835, lat: 25.2663847 },
                          end_location: { lat: 25.2643805, lng: 51.497229 },
                          maneuver: "turn-slight-right",
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                          },
                        },
                        {
                          start_location: { lat: 25.2643805, lng: 51.497229 },
                          maneuver: "roundabout-right",
                          polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                          end_location: { lat: 25.2656775, lng: 51.4962302 },
                          distance: { value: 182, text: "0.2 km" },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 22 },
                        },
                        {
                          maneuver: "turn-slight-right",
                          distance: { value: 25, text: "25 m" },
                          polyline: { points: "oueyCmzhyH]DKB" },
                          duration: { text: "1 min", value: 3 },
                          html_instructions:
                            "Slight <b>right</b> onto <b>Al Nasr St</b>",
                          end_location: { lng: 51.4961765, lat: 25.2658942 },
                          start_location: { lat: 25.2656775, lng: 51.4962302 },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { text: "0.4 km", value: 389 },
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                          maneuver: "turn-slight-right",
                          travel_mode: "DRIVING",
                          duration: { value: 53, text: "1 min" },
                          polyline: {
                            points:
                              "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                          },
                          end_location: { lat: 25.267547, lng: 51.4995317 },
                          start_location: { lng: 51.4961765, lat: 25.2658942 },
                        },
                        {
                          travel_mode: "DRIVING",
                          polyline: { points: "eafyCaoiyHQFiDdA" },
                          distance: { text: "0.1 km", value: 111 },
                          start_location: { lat: 25.267547, lng: 51.4995317 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohammed St</b>",
                          duration: { value: 22, text: "1 min" },
                          maneuver: "turn-left",
                          end_location: { lat: 25.2684856, lng: 51.499144 },
                        },
                        {
                          maneuver: "turn-left",
                          start_location: { lat: 25.2684856, lng: 51.499144 },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          end_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 19 },
                          distance: { value: 62, text: "62 m" },
                          polyline: { points: "agfyCsliyH\\vABNAJ" },
                        },
                      ],
                      start_address: "7GP7+6RG, Doha, Qatar",
                      end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                      via_waypoint: [],
                      end_address: "7F9X+C9V, Doha, Qatar",
                    },
                    {
                      traffic_speed_entry: [],
                      start_address: "7F9X+C9V, Doha, Qatar",
                      start_location: {
                        lng: 51.49855549999999,
                        lat: 25.2683307,
                      },
                      via_waypoint: [],
                      end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                      steps: [
                        {
                          polyline: { points: "affyC_iiyH@KCO]wA" },
                          duration: { value: 14, text: "1 min" },
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          distance: { value: 62, text: "62 m" },
                          travel_mode: "DRIVING",
                        },
                        {
                          html_instructions:
                            "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                          start_location: { lat: 25.2684856, lng: 51.499144 },
                          end_location: { lng: 51.499066, lat: 25.2687298 },
                          polyline: { points: "agfyCsliyHo@L" },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 9 },
                          distance: { text: "28 m", value: 28 },
                          maneuver: "turn-left",
                        },
                        {
                          start_location: { lat: 25.2687298, lng: 51.499066 },
                          travel_mode: "DRIVING",
                          distance: { value: 154, text: "0.2 km" },
                          maneuver: "turn-right",
                          duration: { text: "1 min", value: 32 },
                          end_location: { lat: 25.2692232, lng: 51.500496 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Baraha</b>",
                          polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                        },
                        {
                          distance: { value: 69, text: "69 m" },
                          end_location: { lat: 25.269806, lng: 51.500265 },
                          start_location: { lng: 51.500496, lat: 25.2692232 },
                          polyline: { points: "skfyCcuiyH{@Ty@X" },
                          duration: { text: "1 min", value: 13 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                          maneuver: "turn-left",
                        },
                        {
                          end_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          maneuver: "turn-right",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                          start_location: { lng: 51.500265, lat: 25.269806 },
                          polyline: {
                            points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                          },
                          duration: { value: 29, text: "1 min" },
                          distance: { text: "0.2 km", value: 223 },
                          travel_mode: "DRIVING",
                        },
                        {
                          end_location: { lat: 25.2679045, lng: 51.5033973 },
                          travel_mode: "DRIVING",
                          maneuver: "roundabout-right",
                          start_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          duration: { value: 48, text: "1 min" },
                          polyline: {
                            points:
                              "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                          },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                          distance: { value: 326, text: "0.3 km" },
                        },
                        {
                          html_instructions: "Merge onto <b>Salwa Rd</b>",
                          end_location: { lat: 25.2368244, lng: 51.45117 },
                          travel_mode: "DRIVING",
                          distance: { value: 6288, text: "6.3 km" },
                          start_location: { lng: 51.5033973, lat: 25.2679045 },
                          duration: { value: 283, text: "5 mins" },
                          polyline: {
                            points:
                              "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                          },
                          maneuver: "merge",
                        },
                        {
                          duration: { value: 10, text: "1 min" },
                          maneuver: "ramp-right",
                          start_location: { lat: 25.2368244, lng: 51.45117 },
                          html_instructions:
                            'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                          travel_mode: "DRIVING",
                          distance: { value: 194, text: "0.2 km" },
                          polyline: { points: "ca`yCy``yHpAnDlAdD" },
                          end_location: { lat: 25.2360177, lng: 51.4494587 },
                        },
                        {
                          maneuver: "merge",
                          html_instructions:
                            "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          start_location: { lng: 51.4494587, lat: 25.2360177 },
                          distance: { value: 397, text: "0.4 km" },
                          duration: { text: "1 min", value: 33 },
                          end_location: { lng: 51.4461502, lat: 25.2340694 },
                          polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                          travel_mode: "DRIVING",
                        },
                        {
                          end_location: { lng: 51.4456607, lat: 25.2337942 },
                          html_instructions:
                            "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          maneuver: "straight",
                          polyline: { points: "}o_yCma_yHP`@d@~@" },
                          distance: { text: "58 m", value: 58 },
                          duration: { value: 8, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2340694, lng: 51.4461502 },
                        },
                        {
                          distance: { text: "1.5 km", value: 1451 },
                          maneuver: "straight",
                          start_location: { lat: 25.2337942, lng: 51.4456607 },
                          end_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "2 mins", value: 90 },
                          polyline: {
                            points:
                              "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                          },
                          html_instructions:
                            'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                        },
                        {
                          html_instructions:
                            "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                          },
                          start_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          maneuver: "turn-slight-left",
                          distance: { value: 92, text: "92 m" },
                          end_location: { lat: 25.2262036, lng: 51.4328437 },
                          duration: { text: "1 min", value: 10 },
                        },
                        {
                          polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                          end_location: { lat: 25.2257335, lng: 51.4323655 },
                          start_location: { lng: 51.4328437, lat: 25.2262036 },
                          html_instructions: "Continue straight",
                          duration: { value: 19, text: "1 min" },
                          maneuver: "straight",
                          distance: { value: 71, text: "71 m" },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { value: 813, text: "0.8 km" },
                          travel_mode: "DRIVING",
                          duration: { value: 72, text: "1 min" },
                          end_location: { lng: 51.4357808, lat: 25.2196178 },
                          html_instructions:
                            "Continue onto <b>E Industrial Rd</b>",
                          start_location: { lat: 25.2257335, lng: 51.4323655 },
                          polyline: {
                            points:
                              "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                          },
                        },
                        {
                          duration: { text: "1 min", value: 27 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 2</b>",
                          distance: { value: 263, text: "0.3 km" },
                          end_location: {
                            lng: 51.43371639999999,
                            lat: 25.2182661,
                          },
                          start_location: { lng: 51.4357808, lat: 25.2196178 },
                          polyline: {
                            points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                          },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { value: 682, text: "0.7 km" },
                          start_location: {
                            lat: 25.2182661,
                            lng: 51.43371639999999,
                          },
                          end_location: { lng: 51.4366206, lat: 25.2135618 },
                          duration: { value: 78, text: "1 min" },
                          polyline: {
                            points:
                              "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                          },
                          html_instructions:
                            "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                          maneuver: "roundabout-right",
                        },
                        {
                          maneuver: "turn-right",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 8</b>",
                          polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                          end_location: { lat: 25.2133289, lng: 51.4364944 },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 12 },
                          start_location: { lng: 51.4366206, lat: 25.2135618 },
                          distance: { value: 29, text: "29 m" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          start_location: { lat: 25.2133289, lng: 51.4364944 },
                          polyline: { points: "in{xCae}xHXO" },
                          distance: { text: "16 m", value: 16 },
                          html_instructions: "Turn <b>left</b>",
                          maneuver: "turn-left",
                          end_location: {
                            lng: 51.43657289999999,
                            lat: 25.2132031,
                          },
                        },
                      ],
                      duration: { value: 795, text: "13 mins" },
                      distance: { value: 11216, text: "11.2 km" },
                      end_address: "28 Street 8, Ar Rayyan, Qatar",
                    },
                  ],
                },
              ],
            },
            completedAt: 1707942351003,
            createdAt: 1707942285310,
            owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
            destination: {
              coordinates: {
                longitude: 51.43657469999999,
                latitude: 25.213204,
              },
              address: "Street 8, Doha, Qatar",
              type: "corporate-branch",
              id: "9VZwCrYZfl03ob5FrD4F.0",
              geohash: "thkx6myvx1",
            },
            co2Amount: 12.612562888268984,
            origin: {
              coordinates: { longitude: 51.489361, latitude: 25.282848 },
              geohash: "thkxgdt7m0",
              address: "858, الدوحة، Qatar",
            },
            car: {
              year: 2021,
              type: "carbon-emitting",
              model: "LAND CRUSER WAGON 4WD",
              make: "Toyota",
              co2_p_km: 0.3379843740994449,
              seat: 3,
            },
            rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
            preferences: {
              female_passengers_only: null,
              verified_passengers_only: null,
            },
            skin: null,
            status: "completed",
            passengersArray: [
              {
                credits: 27.8,
                username: "abcd",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                seat: 0,
                id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                name: "Jassim",
                co2Amount: 4.313694566631215,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                location: {
                  geohash: "thkxgdt7m0",
                  coordinates: { latitude: 25.282848, longitude: 51.489361 },
                  address: "858, الدوحة، Qatar",
                },
                wallet_id: "spencer_carbon.testnet",
              },
              {
                status: "picked-up",
                id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                seat: 2,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                username: "sarah",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                wallet_id: "sarah_carbon.testnet",
                credits: 13,
                co2Amount: 3.7908327398993733,
                name: "Faisal",
                location: {
                  coordinates: { longitude: 51.498513, latitude: 25.268246 },
                  address: "7F9X+C9V, Doha, Qatar",
                  geohash: "thkxezun4q",
                },
              },
              {
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                status: "picked-up",
                location: {
                  geohash: "thkxu5pfc8",
                  address: "7GP7+6RG, Doha, Qatar",
                  coordinates: { longitude: 51.514615, latitude: 25.285509 },
                },
                username: "michaelscott",
                id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                credits: 13.9,
                seat: 1,
                name: "Ahmed",
                wallet_id: "sofia_carbon.testnet",
                co2Amount: 4.508035581738396,
              },
            ],
          },
          type: "carpool_passenger",
          corporate: "9VZwCrYZfl03ob5FrD4F",
        },
        "6Qj01PCY1lfLasd74HFgTxpcDRt1": {
          userId: "user_id",
          name: "Faisal",
          username: "sarah",
          location: {
            coordinates: { longitude: 51.498513, latitude: 25.268246 },
            address: "7F9X+C9V, Doha, Qatar",
            geohash: "thkxezun4q",
          },
          credits: 13,
          co2Amount: 3.7908327398993733,
          avatar:
            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
          seat: 2,
          id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
          wallet_id: "sarah_carbon.testnet",
          status: "picked-up",
          carpool: {
            id: "8292057714690",
            directions: {
              status: "OK",
              geocoded_waypoints: [
                {
                  geocoder_status: "OK",
                  types: [
                    "establishment",
                    "moving_company",
                    "point_of_interest",
                  ],
                  place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                },
                {
                  types: [
                    "establishment",
                    "general_contractor",
                    "point_of_interest",
                  ],
                  geocoder_status: "OK",
                  place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                },
                {
                  place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                  geocoder_status: "OK",
                  types: ["premise"],
                },
                {
                  types: ["street_address"],
                  geocoder_status: "OK",
                  place_id:
                    "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                },
              ],
              routes: [
                {
                  overview_polyline: {
                    points:
                      "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                  },
                  waypoint_order: [0, 1],
                  bounds: {
                    northeast: { lat: 25.291958, lng: 51.518743 },
                    southwest: { lat: 25.2132031, lng: 51.4319062 },
                  },
                  warnings: [],
                  copyrights: "Map data ©2024 Google",
                  summary: "Al Rayyan Rd",
                  legs: [
                    {
                      distance: { value: 5407, text: "5.4 km" },
                      start_address: "858, الدوحة، Qatar",
                      via_waypoint: [],
                      traffic_speed_entry: [],
                      start_location: { lng: 51.4906083, lat: 25.2827107 },
                      end_address: "7GP7+6RG, Doha, Qatar",
                      steps: [
                        {
                          polyline: { points: "}_iyCiwgyHz@B" },
                          distance: { value: 33, text: "33 m" },
                          end_location: { lng: 51.4905936, lat: 25.2824136 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                          start_location: { lng: 51.4906083, lat: 25.2827107 },
                          duration: { value: 4, text: "1 min" },
                        },
                        {
                          end_location: { lat: 25.2801532, lng: 51.4871118 },
                          travel_mode: "DRIVING",
                          distance: { value: 574, text: "0.6 km" },
                          start_location: { lng: 51.4905936, lat: 25.2824136 },
                          html_instructions:
                            "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                          polyline: {
                            points:
                              "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                          },
                          duration: { text: "1 min", value: 49 },
                        },
                        {
                          start_location: { lng: 51.4871118, lat: 25.2801532 },
                          travel_mode: "DRIVING",
                          distance: { value: 426, text: "0.4 km" },
                          polyline: {
                            points:
                              "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                          },
                          maneuver: "turn-left",
                          end_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                          html_instructions: "Turn <b>left</b>",
                          duration: { value: 44, text: "1 min" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          maneuver: "straight",
                          html_instructions:
                            "Continue straight onto <b>Al Sadd St</b>",
                          distance: { value: 93, text: "93 m" },
                          end_location: { lng: 51.4917143, lat: 25.2802165 },
                          polyline: { points: "cphyCoxgyHEaBAsA" },
                          start_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                        },
                        {
                          end_location: { lat: 25.2804496, lng: 51.4940386 },
                          duration: { text: "1 min", value: 71 },
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                          },
                          html_instructions: "Slight <b>left</b>",
                          distance: { text: "0.2 km", value: 236 },
                          maneuver: "turn-slight-left",
                          start_location: { lng: 51.4917143, lat: 25.2802165 },
                        },
                        {
                          polyline: {
                            points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                          },
                          duration: { text: "1 min", value: 38 },
                          end_location: { lng: 51.4947254, lat: 25.281604 },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          distance: { text: "0.2 km", value: 163 },
                          start_location: { lat: 25.2804496, lng: 51.4940386 },
                        },
                        {
                          duration: { value: 76, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.4947254, lat: 25.281604 },
                          html_instructions:
                            "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                          polyline: {
                            points:
                              "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                          },
                          maneuver: "keep-left",
                          distance: { text: "1.1 km", value: 1109 },
                          end_location: { lat: 25.2914195, lng: 51.4965617 },
                        },
                        {
                          duration: { text: "3 mins", value: 209 },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2914195, lng: 51.4965617 },
                          end_location: {
                            lat: 25.2893902,
                            lng: 51.51516729999999,
                          },
                          distance: { value: 1922, text: "1.9 km" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                          },
                        },
                        {
                          distance: { text: "0.1 km", value: 144 },
                          maneuver: "turn-right",
                          duration: { value: 15, text: "1 min" },
                          start_location: {
                            lng: 51.51516729999999,
                            lat: 25.2893902,
                          },
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej</b>",
                          end_location: {
                            lng: 51.51591370000001,
                            lat: 25.2884741,
                          },
                        },
                        {
                          travel_mode: "DRIVING",
                          start_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          duration: { text: "1 min", value: 11 },
                          polyline: {
                            points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                          },
                          distance: { value: 122, text: "0.1 km" },
                          html_instructions:
                            'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                          end_location: { lat: 25.2874383, lng: 51.516228 },
                        },
                        {
                          duration: { text: "1 min", value: 21 },
                          end_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          start_location: { lng: 51.516228, lat: 25.2874383 },
                          polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                          html_instructions:
                            'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                          travel_mode: "DRIVING",
                          maneuver: "turn-slight-right",
                          distance: { value: 283, text: "0.3 km" },
                        },
                        {
                          start_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          duration: { value: 32, text: "1 min" },
                          travel_mode: "DRIVING",
                          end_location: { lng: 51.5155496, lat: 25.2848165 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                          distance: { value: 125, text: "0.1 km" },
                          polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                          maneuver: "turn-right",
                        },
                        {
                          end_location: { lat: 25.2850716, lng: 51.5150352 },
                          maneuver: "turn-right",
                          duration: { text: "1 min", value: 21 },
                          distance: { value: 74, text: "74 m" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                          },
                          start_location: { lat: 25.2848165, lng: 51.5155496 },
                        },
                        {
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          start_location: { lat: 25.2850716, lng: 51.5150352 },
                          end_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          polyline: { points: "uniyC_plyHqBJ" },
                          duration: { text: "1 min", value: 23 },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          distance: { value: 64, text: "64 m" },
                        },
                        {
                          polyline: { points: "griyCsolyH?p@DZ" },
                          duration: { text: "1 min", value: 14 },
                          distance: { text: "39 m", value: 39 },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                          start_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          travel_mode: "DRIVING",
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          maneuver: "turn-left",
                        },
                      ],
                      duration: { text: "11 mins", value: 636 },
                      end_location: { lng: 51.5145907, lat: 25.2856124 },
                    },
                    {
                      traffic_speed_entry: [],
                      duration: { text: "10 mins", value: 598 },
                      start_location: { lng: 51.5145907, lat: 25.2856124 },
                      distance: { text: "4.5 km", value: 4489 },
                      steps: [
                        {
                          start_location: { lng: 51.5145907, lat: 25.2856124 },
                          end_location: { lat: 25.2859334, lng: 51.5165533 },
                          duration: { text: "1 min", value: 73 },
                          distance: { value: 201, text: "0.2 km" },
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                          },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                        },
                        {
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                          end_location: { lng: 51.518743, lat: 25.2796568 },
                          distance: { text: "0.7 km", value: 735 },
                          duration: { value: 78, text: "1 min" },
                          start_location: { lng: 51.5165533, lat: 25.2859334 },
                          maneuver: "turn-right",
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                          end_location: { lat: 25.2769643, lng: 51.5164061 },
                          polyline: {
                            points:
                              "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                          },
                          start_location: { lat: 25.2796568, lng: 51.518743 },
                          distance: { value: 393, text: "0.4 km" },
                          travel_mode: "DRIVING",
                          duration: { value: 59, text: "1 min" },
                          maneuver: "turn-slight-right",
                        },
                        {
                          end_location: { lng: 51.500835, lat: 25.2663847 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                          start_location: { lng: 51.5164061, lat: 25.2769643 },
                          maneuver: "merge",
                          distance: { value: 1963, text: "2.0 km" },
                          polyline: {
                            points:
                              "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                          },
                          duration: { text: "4 mins", value: 229 },
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                          distance: { value: 428, text: "0.4 km" },
                          duration: { text: "1 min", value: 40 },
                          start_location: { lng: 51.500835, lat: 25.2663847 },
                          end_location: { lat: 25.2643805, lng: 51.497229 },
                          maneuver: "turn-slight-right",
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                          },
                        },
                        {
                          start_location: { lat: 25.2643805, lng: 51.497229 },
                          maneuver: "roundabout-right",
                          polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                          end_location: { lat: 25.2656775, lng: 51.4962302 },
                          distance: { value: 182, text: "0.2 km" },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 22 },
                        },
                        {
                          maneuver: "turn-slight-right",
                          distance: { value: 25, text: "25 m" },
                          polyline: { points: "oueyCmzhyH]DKB" },
                          duration: { text: "1 min", value: 3 },
                          html_instructions:
                            "Slight <b>right</b> onto <b>Al Nasr St</b>",
                          end_location: { lng: 51.4961765, lat: 25.2658942 },
                          start_location: { lat: 25.2656775, lng: 51.4962302 },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { text: "0.4 km", value: 389 },
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                          maneuver: "turn-slight-right",
                          travel_mode: "DRIVING",
                          duration: { value: 53, text: "1 min" },
                          polyline: {
                            points:
                              "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                          },
                          end_location: { lat: 25.267547, lng: 51.4995317 },
                          start_location: { lng: 51.4961765, lat: 25.2658942 },
                        },
                        {
                          travel_mode: "DRIVING",
                          polyline: { points: "eafyCaoiyHQFiDdA" },
                          distance: { text: "0.1 km", value: 111 },
                          start_location: { lat: 25.267547, lng: 51.4995317 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohammed St</b>",
                          duration: { value: 22, text: "1 min" },
                          maneuver: "turn-left",
                          end_location: { lat: 25.2684856, lng: 51.499144 },
                        },
                        {
                          maneuver: "turn-left",
                          start_location: { lat: 25.2684856, lng: 51.499144 },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          end_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 19 },
                          distance: { value: 62, text: "62 m" },
                          polyline: { points: "agfyCsliyH\\vABNAJ" },
                        },
                      ],
                      start_address: "7GP7+6RG, Doha, Qatar",
                      end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                      via_waypoint: [],
                      end_address: "7F9X+C9V, Doha, Qatar",
                    },
                    {
                      traffic_speed_entry: [],
                      start_address: "7F9X+C9V, Doha, Qatar",
                      start_location: {
                        lng: 51.49855549999999,
                        lat: 25.2683307,
                      },
                      via_waypoint: [],
                      end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                      steps: [
                        {
                          polyline: { points: "affyC_iiyH@KCO]wA" },
                          duration: { value: 14, text: "1 min" },
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          distance: { value: 62, text: "62 m" },
                          travel_mode: "DRIVING",
                        },
                        {
                          html_instructions:
                            "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                          start_location: { lat: 25.2684856, lng: 51.499144 },
                          end_location: { lng: 51.499066, lat: 25.2687298 },
                          polyline: { points: "agfyCsliyHo@L" },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 9 },
                          distance: { text: "28 m", value: 28 },
                          maneuver: "turn-left",
                        },
                        {
                          start_location: { lat: 25.2687298, lng: 51.499066 },
                          travel_mode: "DRIVING",
                          distance: { value: 154, text: "0.2 km" },
                          maneuver: "turn-right",
                          duration: { text: "1 min", value: 32 },
                          end_location: { lat: 25.2692232, lng: 51.500496 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Baraha</b>",
                          polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                        },
                        {
                          distance: { value: 69, text: "69 m" },
                          end_location: { lat: 25.269806, lng: 51.500265 },
                          start_location: { lng: 51.500496, lat: 25.2692232 },
                          polyline: { points: "skfyCcuiyH{@Ty@X" },
                          duration: { text: "1 min", value: 13 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                          maneuver: "turn-left",
                        },
                        {
                          end_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          maneuver: "turn-right",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                          start_location: { lng: 51.500265, lat: 25.269806 },
                          polyline: {
                            points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                          },
                          duration: { value: 29, text: "1 min" },
                          distance: { text: "0.2 km", value: 223 },
                          travel_mode: "DRIVING",
                        },
                        {
                          end_location: { lat: 25.2679045, lng: 51.5033973 },
                          travel_mode: "DRIVING",
                          maneuver: "roundabout-right",
                          start_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          duration: { value: 48, text: "1 min" },
                          polyline: {
                            points:
                              "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                          },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                          distance: { value: 326, text: "0.3 km" },
                        },
                        {
                          html_instructions: "Merge onto <b>Salwa Rd</b>",
                          end_location: { lat: 25.2368244, lng: 51.45117 },
                          travel_mode: "DRIVING",
                          distance: { value: 6288, text: "6.3 km" },
                          start_location: { lng: 51.5033973, lat: 25.2679045 },
                          duration: { value: 283, text: "5 mins" },
                          polyline: {
                            points:
                              "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                          },
                          maneuver: "merge",
                        },
                        {
                          duration: { value: 10, text: "1 min" },
                          maneuver: "ramp-right",
                          start_location: { lat: 25.2368244, lng: 51.45117 },
                          html_instructions:
                            'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                          travel_mode: "DRIVING",
                          distance: { value: 194, text: "0.2 km" },
                          polyline: { points: "ca`yCy``yHpAnDlAdD" },
                          end_location: { lat: 25.2360177, lng: 51.4494587 },
                        },
                        {
                          maneuver: "merge",
                          html_instructions:
                            "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          start_location: { lng: 51.4494587, lat: 25.2360177 },
                          distance: { value: 397, text: "0.4 km" },
                          duration: { text: "1 min", value: 33 },
                          end_location: { lng: 51.4461502, lat: 25.2340694 },
                          polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                          travel_mode: "DRIVING",
                        },
                        {
                          end_location: { lng: 51.4456607, lat: 25.2337942 },
                          html_instructions:
                            "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          maneuver: "straight",
                          polyline: { points: "}o_yCma_yHP`@d@~@" },
                          distance: { text: "58 m", value: 58 },
                          duration: { value: 8, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2340694, lng: 51.4461502 },
                        },
                        {
                          distance: { text: "1.5 km", value: 1451 },
                          maneuver: "straight",
                          start_location: { lat: 25.2337942, lng: 51.4456607 },
                          end_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "2 mins", value: 90 },
                          polyline: {
                            points:
                              "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                          },
                          html_instructions:
                            'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                        },
                        {
                          html_instructions:
                            "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                          },
                          start_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          maneuver: "turn-slight-left",
                          distance: { value: 92, text: "92 m" },
                          end_location: { lat: 25.2262036, lng: 51.4328437 },
                          duration: { text: "1 min", value: 10 },
                        },
                        {
                          polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                          end_location: { lat: 25.2257335, lng: 51.4323655 },
                          start_location: { lng: 51.4328437, lat: 25.2262036 },
                          html_instructions: "Continue straight",
                          duration: { value: 19, text: "1 min" },
                          maneuver: "straight",
                          distance: { value: 71, text: "71 m" },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { value: 813, text: "0.8 km" },
                          travel_mode: "DRIVING",
                          duration: { value: 72, text: "1 min" },
                          end_location: { lng: 51.4357808, lat: 25.2196178 },
                          html_instructions:
                            "Continue onto <b>E Industrial Rd</b>",
                          start_location: { lat: 25.2257335, lng: 51.4323655 },
                          polyline: {
                            points:
                              "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                          },
                        },
                        {
                          duration: { text: "1 min", value: 27 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 2</b>",
                          distance: { value: 263, text: "0.3 km" },
                          end_location: {
                            lng: 51.43371639999999,
                            lat: 25.2182661,
                          },
                          start_location: { lng: 51.4357808, lat: 25.2196178 },
                          polyline: {
                            points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                          },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { value: 682, text: "0.7 km" },
                          start_location: {
                            lat: 25.2182661,
                            lng: 51.43371639999999,
                          },
                          end_location: { lng: 51.4366206, lat: 25.2135618 },
                          duration: { value: 78, text: "1 min" },
                          polyline: {
                            points:
                              "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                          },
                          html_instructions:
                            "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                          maneuver: "roundabout-right",
                        },
                        {
                          maneuver: "turn-right",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 8</b>",
                          polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                          end_location: { lat: 25.2133289, lng: 51.4364944 },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 12 },
                          start_location: { lng: 51.4366206, lat: 25.2135618 },
                          distance: { value: 29, text: "29 m" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          start_location: { lat: 25.2133289, lng: 51.4364944 },
                          polyline: { points: "in{xCae}xHXO" },
                          distance: { text: "16 m", value: 16 },
                          html_instructions: "Turn <b>left</b>",
                          maneuver: "turn-left",
                          end_location: {
                            lng: 51.43657289999999,
                            lat: 25.2132031,
                          },
                        },
                      ],
                      duration: { value: 795, text: "13 mins" },
                      distance: { value: 11216, text: "11.2 km" },
                      end_address: "28 Street 8, Ar Rayyan, Qatar",
                    },
                  ],
                },
              ],
            },
            completedAt: 1707942351003,
            createdAt: 1707942285310,
            owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
            destination: {
              coordinates: {
                longitude: 51.43657469999999,
                latitude: 25.213204,
              },
              address: "Street 8, Doha, Qatar",
              type: "corporate-branch",
              id: "9VZwCrYZfl03ob5FrD4F.0",
              geohash: "thkx6myvx1",
            },
            co2Amount: 12.612562888268984,
            origin: {
              coordinates: { longitude: 51.489361, latitude: 25.282848 },
              geohash: "thkxgdt7m0",
              address: "858, الدوحة، Qatar",
            },
            car: {
              year: 2021,
              type: "carbon-emitting",
              model: "LAND CRUSER WAGON 4WD",
              make: "Toyota",
              co2_p_km: 0.3379843740994449,
              seat: 3,
            },
            rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
            preferences: {
              female_passengers_only: null,
              verified_passengers_only: null,
            },
            skin: null,
            status: "completed",
            passengersArray: [
              {
                userId: "user_id",
                name: "Jassim",
                username: "abcd",
                location: {
                  geohash: "thkxgdt7m0",
                  coordinates: { latitude: 25.282848, longitude: 51.489361 },
                  address: "858, الدوحة، Qatar",
                },
                credits: 27.8,
                co2Amount: 4.313694566631215,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                seat: 0,
                id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                wallet_id: "spencer_carbon.testnet",
                carpool: {
                  id: "8292057714690",
                  directions: {
                    status: "OK",
                    geocoded_waypoints: [
                      {
                        geocoder_status: "OK",
                        types: [
                          "establishment",
                          "moving_company",
                          "point_of_interest",
                        ],
                        place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                      },
                      {
                        types: [
                          "establishment",
                          "general_contractor",
                          "point_of_interest",
                        ],
                        geocoder_status: "OK",
                        place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                      },
                      {
                        place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                        geocoder_status: "OK",
                        types: ["premise"],
                      },
                      {
                        types: ["street_address"],
                        geocoder_status: "OK",
                        place_id:
                          "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                      },
                    ],
                    routes: [
                      {
                        overview_polyline: {
                          points:
                            "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                        },
                        waypoint_order: [0, 1],
                        bounds: {
                          northeast: { lat: 25.291958, lng: 51.518743 },
                          southwest: { lat: 25.2132031, lng: 51.4319062 },
                        },
                        warnings: [],
                        copyrights: "Map data ©2024 Google",
                        summary: "Al Rayyan Rd",
                        legs: [
                          {
                            distance: { value: 5407, text: "5.4 km" },
                            start_address: "858, الدوحة، Qatar",
                            via_waypoint: [],
                            traffic_speed_entry: [],
                            start_location: {
                              lng: 51.4906083,
                              lat: 25.2827107,
                            },
                            end_address: "7GP7+6RG, Doha, Qatar",
                            steps: [
                              {
                                polyline: { points: "}_iyCiwgyHz@B" },
                                distance: { value: 33, text: "33 m" },
                                end_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                start_location: {
                                  lng: 51.4906083,
                                  lat: 25.2827107,
                                },
                                duration: { value: 4, text: "1 min" },
                              },
                              {
                                end_location: {
                                  lat: 25.2801532,
                                  lng: 51.4871118,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 574, text: "0.6 km" },
                                start_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                html_instructions:
                                  "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                polyline: {
                                  points:
                                    "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                },
                                duration: { text: "1 min", value: 49 },
                              },
                              {
                                start_location: {
                                  lng: 51.4871118,
                                  lat: 25.2801532,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 426, text: "0.4 km" },
                                polyline: {
                                  points:
                                    "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                },
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                                html_instructions: "Turn <b>left</b>",
                                duration: { value: 44, text: "1 min" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                maneuver: "straight",
                                html_instructions:
                                  "Continue straight onto <b>Al Sadd St</b>",
                                distance: { value: 93, text: "93 m" },
                                end_location: {
                                  lng: 51.4917143,
                                  lat: 25.2802165,
                                },
                                polyline: { points: "cphyCoxgyHEaBAsA" },
                                start_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                              },
                              {
                                end_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                                duration: { text: "1 min", value: 71 },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                },
                                html_instructions: "Slight <b>left</b>",
                                distance: { text: "0.2 km", value: 236 },
                                maneuver: "turn-slight-left",
                                start_location: {
                                  lng: 51.4917143,
                                  lat: 25.2802165,
                                },
                              },
                              {
                                polyline: {
                                  points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                },
                                duration: { text: "1 min", value: 38 },
                                end_location: {
                                  lng: 51.4947254,
                                  lat: 25.281604,
                                },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                distance: { text: "0.2 km", value: 163 },
                                start_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                              },
                              {
                                duration: { value: 76, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.4947254,
                                  lat: 25.281604,
                                },
                                html_instructions:
                                  "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                polyline: {
                                  points:
                                    "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                },
                                maneuver: "keep-left",
                                distance: { text: "1.1 km", value: 1109 },
                                end_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                              },
                              {
                                duration: { text: "3 mins", value: 209 },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                                end_location: {
                                  lat: 25.2893902,
                                  lng: 51.51516729999999,
                                },
                                distance: { value: 1922, text: "1.9 km" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                },
                              },
                              {
                                distance: { text: "0.1 km", value: 144 },
                                maneuver: "turn-right",
                                duration: { value: 15, text: "1 min" },
                                start_location: {
                                  lng: 51.51516729999999,
                                  lat: 25.2893902,
                                },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                end_location: {
                                  lng: 51.51591370000001,
                                  lat: 25.2884741,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                duration: { text: "1 min", value: 11 },
                                polyline: {
                                  points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                },
                                distance: { value: 122, text: "0.1 km" },
                                html_instructions:
                                  'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                                end_location: {
                                  lat: 25.2874383,
                                  lng: 51.516228,
                                },
                              },
                              {
                                duration: { text: "1 min", value: 21 },
                                end_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                start_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                polyline: {
                                  points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                },
                                html_instructions:
                                  'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                                travel_mode: "DRIVING",
                                maneuver: "turn-slight-right",
                                distance: { value: 283, text: "0.3 km" },
                              },
                              {
                                start_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                duration: { value: 32, text: "1 min" },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lng: 51.5155496,
                                  lat: 25.2848165,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                distance: { value: 125, text: "0.1 km" },
                                polyline: {
                                  points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                },
                                maneuver: "turn-right",
                              },
                              {
                                end_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                maneuver: "turn-right",
                                duration: { text: "1 min", value: 21 },
                                distance: { value: 74, text: "74 m" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                },
                                start_location: {
                                  lat: 25.2848165,
                                  lng: 51.5155496,
                                },
                              },
                              {
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                start_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                end_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                polyline: { points: "uniyC_plyHqBJ" },
                                duration: { text: "1 min", value: 23 },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                distance: { value: 64, text: "64 m" },
                              },
                              {
                                polyline: { points: "griyCsolyH?p@DZ" },
                                duration: { text: "1 min", value: 14 },
                                distance: { text: "39 m", value: 39 },
                                end_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                start_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                maneuver: "turn-left",
                              },
                            ],
                            duration: { text: "11 mins", value: 636 },
                            end_location: { lng: 51.5145907, lat: 25.2856124 },
                          },
                          {
                            traffic_speed_entry: [],
                            duration: { text: "10 mins", value: 598 },
                            start_location: {
                              lng: 51.5145907,
                              lat: 25.2856124,
                            },
                            distance: { text: "4.5 km", value: 4489 },
                            steps: [
                              {
                                start_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                end_location: {
                                  lat: 25.2859334,
                                  lng: 51.5165533,
                                },
                                duration: { text: "1 min", value: 73 },
                                distance: { value: 201, text: "0.2 km" },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                              },
                              {
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                end_location: {
                                  lng: 51.518743,
                                  lat: 25.2796568,
                                },
                                distance: { text: "0.7 km", value: 735 },
                                duration: { value: 78, text: "1 min" },
                                start_location: {
                                  lng: 51.5165533,
                                  lat: 25.2859334,
                                },
                                maneuver: "turn-right",
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                end_location: {
                                  lat: 25.2769643,
                                  lng: 51.5164061,
                                },
                                polyline: {
                                  points:
                                    "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                },
                                start_location: {
                                  lat: 25.2796568,
                                  lng: 51.518743,
                                },
                                distance: { value: 393, text: "0.4 km" },
                                travel_mode: "DRIVING",
                                duration: { value: 59, text: "1 min" },
                                maneuver: "turn-slight-right",
                              },
                              {
                                end_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.5164061,
                                  lat: 25.2769643,
                                },
                                maneuver: "merge",
                                distance: { value: 1963, text: "2.0 km" },
                                polyline: {
                                  points:
                                    "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                },
                                duration: { text: "4 mins", value: 229 },
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                distance: { value: 428, text: "0.4 km" },
                                duration: { text: "1 min", value: 40 },
                                start_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                end_location: {
                                  lat: 25.2643805,
                                  lng: 51.497229,
                                },
                                maneuver: "turn-slight-right",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                },
                              },
                              {
                                start_location: {
                                  lat: 25.2643805,
                                  lng: 51.497229,
                                },
                                maneuver: "roundabout-right",
                                polyline: {
                                  points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                end_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                distance: { value: 182, text: "0.2 km" },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 22 },
                              },
                              {
                                maneuver: "turn-slight-right",
                                distance: { value: 25, text: "25 m" },
                                polyline: { points: "oueyCmzhyH]DKB" },
                                duration: { text: "1 min", value: 3 },
                                html_instructions:
                                  "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                end_location: {
                                  lng: 51.4961765,
                                  lat: 25.2658942,
                                },
                                start_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { text: "0.4 km", value: 389 },
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                maneuver: "turn-slight-right",
                                travel_mode: "DRIVING",
                                duration: { value: 53, text: "1 min" },
                                polyline: {
                                  points:
                                    "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                },
                                end_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                start_location: {
                                  lng: 51.4961765,
                                  lat: 25.2658942,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                polyline: { points: "eafyCaoiyHQFiDdA" },
                                distance: { text: "0.1 km", value: 111 },
                                start_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohammed St</b>",
                                duration: { value: 22, text: "1 min" },
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                              },
                              {
                                maneuver: "turn-left",
                                start_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                end_location: {
                                  lng: 51.49855549999999,
                                  lat: 25.2683307,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 19 },
                                distance: { value: 62, text: "62 m" },
                                polyline: { points: "agfyCsliyH\\vABNAJ" },
                              },
                            ],
                            start_address: "7GP7+6RG, Doha, Qatar",
                            end_location: {
                              lat: 25.2683307,
                              lng: 51.49855549999999,
                            },
                            via_waypoint: [],
                            end_address: "7F9X+C9V, Doha, Qatar",
                          },
                          {
                            traffic_speed_entry: [],
                            start_address: "7F9X+C9V, Doha, Qatar",
                            start_location: {
                              lng: 51.49855549999999,
                              lat: 25.2683307,
                            },
                            via_waypoint: [],
                            end_location: {
                              lng: 51.43657289999999,
                              lat: 25.2132031,
                            },
                            steps: [
                              {
                                polyline: { points: "affyC_iiyH@KCO]wA" },
                                duration: { value: 14, text: "1 min" },
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                                start_location: {
                                  lng: 51.49855549999999,
                                  lat: 25.2683307,
                                },
                                distance: { value: 62, text: "62 m" },
                                travel_mode: "DRIVING",
                              },
                              {
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                start_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                                end_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                                polyline: { points: "agfyCsliyHo@L" },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 9 },
                                distance: { text: "28 m", value: 28 },
                                maneuver: "turn-left",
                              },
                              {
                                start_location: {
                                  lat: 25.2687298,
                                  lng: 51.499066,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 154, text: "0.2 km" },
                                maneuver: "turn-right",
                                duration: { text: "1 min", value: 32 },
                                end_location: {
                                  lat: 25.2692232,
                                  lng: 51.500496,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Baraha</b>",
                                polyline: {
                                  points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                },
                              },
                              {
                                distance: { value: 69, text: "69 m" },
                                end_location: {
                                  lat: 25.269806,
                                  lng: 51.500265,
                                },
                                start_location: {
                                  lng: 51.500496,
                                  lat: 25.2692232,
                                },
                                polyline: { points: "skfyCcuiyH{@Ty@X" },
                                duration: { text: "1 min", value: 13 },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                maneuver: "turn-left",
                              },
                              {
                                end_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                maneuver: "turn-right",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                                start_location: {
                                  lng: 51.500265,
                                  lat: 25.269806,
                                },
                                polyline: {
                                  points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                },
                                duration: { value: 29, text: "1 min" },
                                distance: { text: "0.2 km", value: 223 },
                                travel_mode: "DRIVING",
                              },
                              {
                                end_location: {
                                  lat: 25.2679045,
                                  lng: 51.5033973,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "roundabout-right",
                                start_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                duration: { value: 48, text: "1 min" },
                                polyline: {
                                  points:
                                    "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                distance: { value: 326, text: "0.3 km" },
                              },
                              {
                                html_instructions: "Merge onto <b>Salwa Rd</b>",
                                end_location: {
                                  lat: 25.2368244,
                                  lng: 51.45117,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 6288, text: "6.3 km" },
                                start_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                duration: { value: 283, text: "5 mins" },
                                polyline: {
                                  points:
                                    "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                },
                                maneuver: "merge",
                              },
                              {
                                duration: { value: 10, text: "1 min" },
                                maneuver: "ramp-right",
                                start_location: {
                                  lat: 25.2368244,
                                  lng: 51.45117,
                                },
                                html_instructions:
                                  'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                travel_mode: "DRIVING",
                                distance: { value: 194, text: "0.2 km" },
                                polyline: { points: "ca`yCy``yHpAnDlAdD" },
                                end_location: {
                                  lat: 25.2360177,
                                  lng: 51.4494587,
                                },
                              },
                              {
                                maneuver: "merge",
                                html_instructions:
                                  "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.4494587,
                                  lat: 25.2360177,
                                },
                                distance: { value: 397, text: "0.4 km" },
                                duration: { text: "1 min", value: 33 },
                                end_location: {
                                  lng: 51.4461502,
                                  lat: 25.2340694,
                                },
                                polyline: {
                                  points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                end_location: {
                                  lng: 51.4456607,
                                  lat: 25.2337942,
                                },
                                html_instructions:
                                  "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                maneuver: "straight",
                                polyline: { points: "}o_yCma_yHP`@d@~@" },
                                distance: { text: "58 m", value: 58 },
                                duration: { value: 8, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2340694,
                                  lng: 51.4461502,
                                },
                              },
                              {
                                distance: { text: "1.5 km", value: 1451 },
                                maneuver: "straight",
                                start_location: {
                                  lat: 25.2337942,
                                  lng: 51.4456607,
                                },
                                end_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "2 mins", value: 90 },
                                polyline: {
                                  points:
                                    "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                },
                                html_instructions:
                                  'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                              },
                              {
                                html_instructions:
                                  "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                },
                                start_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                maneuver: "turn-slight-left",
                                distance: { value: 92, text: "92 m" },
                                end_location: {
                                  lat: 25.2262036,
                                  lng: 51.4328437,
                                },
                                duration: { text: "1 min", value: 10 },
                              },
                              {
                                polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                                end_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                start_location: {
                                  lng: 51.4328437,
                                  lat: 25.2262036,
                                },
                                html_instructions: "Continue straight",
                                duration: { value: 19, text: "1 min" },
                                maneuver: "straight",
                                distance: { value: 71, text: "71 m" },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { value: 813, text: "0.8 km" },
                                travel_mode: "DRIVING",
                                duration: { value: 72, text: "1 min" },
                                end_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                                html_instructions:
                                  "Continue onto <b>E Industrial Rd</b>",
                                start_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                polyline: {
                                  points:
                                    "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                },
                              },
                              {
                                duration: { text: "1 min", value: 27 },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 2</b>",
                                distance: { value: 263, text: "0.3 km" },
                                end_location: {
                                  lng: 51.43371639999999,
                                  lat: 25.2182661,
                                },
                                start_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                                polyline: {
                                  points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { value: 682, text: "0.7 km" },
                                start_location: {
                                  lat: 25.2182661,
                                  lng: 51.43371639999999,
                                },
                                end_location: {
                                  lng: 51.4366206,
                                  lat: 25.2135618,
                                },
                                duration: { value: 78, text: "1 min" },
                                polyline: {
                                  points:
                                    "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                maneuver: "roundabout-right",
                              },
                              {
                                maneuver: "turn-right",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 8</b>",
                                polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                                end_location: {
                                  lat: 25.2133289,
                                  lng: 51.4364944,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 12 },
                                start_location: {
                                  lng: 51.4366206,
                                  lat: 25.2135618,
                                },
                                distance: { value: 29, text: "29 m" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                start_location: {
                                  lat: 25.2133289,
                                  lng: 51.4364944,
                                },
                                polyline: { points: "in{xCae}xHXO" },
                                distance: { text: "16 m", value: 16 },
                                html_instructions: "Turn <b>left</b>",
                                maneuver: "turn-left",
                                end_location: {
                                  lng: 51.43657289999999,
                                  lat: 25.2132031,
                                },
                              },
                            ],
                            duration: { value: 795, text: "13 mins" },
                            distance: { value: 11216, text: "11.2 km" },
                            end_address: "28 Street 8, Ar Rayyan, Qatar",
                          },
                        ],
                      },
                    ],
                  },
                  completedAt: 1707942351003,
                  createdAt: 1707942285310,
                  owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                  destination: {
                    coordinates: {
                      longitude: 51.43657469999999,
                      latitude: 25.213204,
                    },
                    address: "Street 8, Doha, Qatar",
                    type: "corporate-branch",
                    id: "9VZwCrYZfl03ob5FrD4F.0",
                    geohash: "thkx6myvx1",
                  },
                  co2Amount: 12.612562888268984,
                  origin: {
                    coordinates: { longitude: 51.489361, latitude: 25.282848 },
                    geohash: "thkxgdt7m0",
                    address: "858, الدوحة، Qatar",
                  },
                  car: {
                    year: 2021,
                    type: "carbon-emitting",
                    model: "LAND CRUSER WAGON 4WD",
                    make: "Toyota",
                    co2_p_km: 0.3379843740994449,
                    seat: 3,
                  },
                  rules: [
                    "No smoking",
                    "No eating or drinking",
                    "Keep off the AC",
                  ],
                  preferences: {
                    female_passengers_only: null,
                    verified_passengers_only: null,
                  },
                  skin: null,
                  status: "completed",
                  passengersArray: [
                    {
                      credits: 27.8,
                      username: "abcd",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      seat: 0,
                      id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                      name: "Jassim",
                      co2Amount: 4.313694566631215,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                      location: {
                        geohash: "thkxgdt7m0",
                        coordinates: {
                          latitude: 25.282848,
                          longitude: 51.489361,
                        },
                        address: "858, الدوحة، Qatar",
                      },
                      wallet_id: "spencer_carbon.testnet",
                    },
                    {
                      status: "picked-up",
                      id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                      seat: 2,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                      username: "sarah",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      wallet_id: "sarah_carbon.testnet",
                      credits: 13,
                      co2Amount: 3.7908327398993733,
                      name: "Faisal",
                      location: {
                        coordinates: {
                          longitude: 51.498513,
                          latitude: 25.268246,
                        },
                        address: "7F9X+C9V, Doha, Qatar",
                        geohash: "thkxezun4q",
                      },
                    },
                    {
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      status: "picked-up",
                      location: {
                        geohash: "thkxu5pfc8",
                        address: "7GP7+6RG, Doha, Qatar",
                        coordinates: {
                          longitude: 51.514615,
                          latitude: 25.285509,
                        },
                      },
                      username: "michaelscott",
                      id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                      credits: 13.9,
                      seat: 1,
                      name: "Ahmed",
                      wallet_id: "sofia_carbon.testnet",
                      co2Amount: 4.508035581738396,
                    },
                  ],
                },
                type: "carpool_passenger",
                corporate: "9VZwCrYZfl03ob5FrD4F",
              },
              {
                status: "picked-up",
                id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                seat: 2,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                username: "sarah",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                wallet_id: "sarah_carbon.testnet",
                credits: 13,
                co2Amount: 3.7908327398993733,
                name: "Faisal",
                location: {
                  coordinates: { longitude: 51.498513, latitude: 25.268246 },
                  address: "7F9X+C9V, Doha, Qatar",
                  geohash: "thkxezun4q",
                },
              },
              {
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                status: "picked-up",
                location: {
                  geohash: "thkxu5pfc8",
                  address: "7GP7+6RG, Doha, Qatar",
                  coordinates: { longitude: 51.514615, latitude: 25.285509 },
                },
                username: "michaelscott",
                id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                credits: 13.9,
                seat: 1,
                name: "Ahmed",
                wallet_id: "sofia_carbon.testnet",
                co2Amount: 4.508035581738396,
              },
            ],
          },
          type: "carpool_passenger",
          corporate: "9VZwCrYZfl03ob5FrD4F",
        },
        lpd29qTdlURFvrSDO0w5WBODcSp2: {
          userId: "user_id",
          name: "Ahmed",
          username: "michaelscott",
          location: {
            geohash: "thkxu5pfc8",
            address: "7GP7+6RG, Doha, Qatar",
            coordinates: { longitude: 51.514615, latitude: 25.285509 },
          },
          credits: 13.9,
          co2Amount: 4.508035581738396,
          avatar:
            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
          seat: 1,
          id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
          wallet_id: "sofia_carbon.testnet",
          status: "picked-up",
          carpool: {
            id: "8292057714690",
            directions: {
              status: "OK",
              geocoded_waypoints: [
                {
                  geocoder_status: "OK",
                  types: [
                    "establishment",
                    "moving_company",
                    "point_of_interest",
                  ],
                  place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                },
                {
                  types: [
                    "establishment",
                    "general_contractor",
                    "point_of_interest",
                  ],
                  geocoder_status: "OK",
                  place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                },
                {
                  place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                  geocoder_status: "OK",
                  types: ["premise"],
                },
                {
                  types: ["street_address"],
                  geocoder_status: "OK",
                  place_id:
                    "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                },
              ],
              routes: [
                {
                  overview_polyline: {
                    points:
                      "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                  },
                  waypoint_order: [0, 1],
                  bounds: {
                    northeast: { lat: 25.291958, lng: 51.518743 },
                    southwest: { lat: 25.2132031, lng: 51.4319062 },
                  },
                  warnings: [],
                  copyrights: "Map data ©2024 Google",
                  summary: "Al Rayyan Rd",
                  legs: [
                    {
                      distance: { value: 5407, text: "5.4 km" },
                      start_address: "858, الدوحة، Qatar",
                      via_waypoint: [],
                      traffic_speed_entry: [],
                      start_location: { lng: 51.4906083, lat: 25.2827107 },
                      end_address: "7GP7+6RG, Doha, Qatar",
                      steps: [
                        {
                          polyline: { points: "}_iyCiwgyHz@B" },
                          distance: { value: 33, text: "33 m" },
                          end_location: { lng: 51.4905936, lat: 25.2824136 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                          start_location: { lng: 51.4906083, lat: 25.2827107 },
                          duration: { value: 4, text: "1 min" },
                        },
                        {
                          end_location: { lat: 25.2801532, lng: 51.4871118 },
                          travel_mode: "DRIVING",
                          distance: { value: 574, text: "0.6 km" },
                          start_location: { lng: 51.4905936, lat: 25.2824136 },
                          html_instructions:
                            "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                          polyline: {
                            points:
                              "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                          },
                          duration: { text: "1 min", value: 49 },
                        },
                        {
                          start_location: { lng: 51.4871118, lat: 25.2801532 },
                          travel_mode: "DRIVING",
                          distance: { value: 426, text: "0.4 km" },
                          polyline: {
                            points:
                              "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                          },
                          maneuver: "turn-left",
                          end_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                          html_instructions: "Turn <b>left</b>",
                          duration: { value: 44, text: "1 min" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          maneuver: "straight",
                          html_instructions:
                            "Continue straight onto <b>Al Sadd St</b>",
                          distance: { value: 93, text: "93 m" },
                          end_location: { lng: 51.4917143, lat: 25.2802165 },
                          polyline: { points: "cphyCoxgyHEaBAsA" },
                          start_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                        },
                        {
                          end_location: { lat: 25.2804496, lng: 51.4940386 },
                          duration: { text: "1 min", value: 71 },
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                          },
                          html_instructions: "Slight <b>left</b>",
                          distance: { text: "0.2 km", value: 236 },
                          maneuver: "turn-slight-left",
                          start_location: { lng: 51.4917143, lat: 25.2802165 },
                        },
                        {
                          polyline: {
                            points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                          },
                          duration: { text: "1 min", value: 38 },
                          end_location: { lng: 51.4947254, lat: 25.281604 },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          distance: { text: "0.2 km", value: 163 },
                          start_location: { lat: 25.2804496, lng: 51.4940386 },
                        },
                        {
                          duration: { value: 76, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.4947254, lat: 25.281604 },
                          html_instructions:
                            "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                          polyline: {
                            points:
                              "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                          },
                          maneuver: "keep-left",
                          distance: { text: "1.1 km", value: 1109 },
                          end_location: { lat: 25.2914195, lng: 51.4965617 },
                        },
                        {
                          duration: { text: "3 mins", value: 209 },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2914195, lng: 51.4965617 },
                          end_location: {
                            lat: 25.2893902,
                            lng: 51.51516729999999,
                          },
                          distance: { value: 1922, text: "1.9 km" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                          },
                        },
                        {
                          distance: { text: "0.1 km", value: 144 },
                          maneuver: "turn-right",
                          duration: { value: 15, text: "1 min" },
                          start_location: {
                            lng: 51.51516729999999,
                            lat: 25.2893902,
                          },
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej</b>",
                          end_location: {
                            lng: 51.51591370000001,
                            lat: 25.2884741,
                          },
                        },
                        {
                          travel_mode: "DRIVING",
                          start_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          duration: { text: "1 min", value: 11 },
                          polyline: {
                            points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                          },
                          distance: { value: 122, text: "0.1 km" },
                          html_instructions:
                            'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                          end_location: { lat: 25.2874383, lng: 51.516228 },
                        },
                        {
                          duration: { text: "1 min", value: 21 },
                          end_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          start_location: { lng: 51.516228, lat: 25.2874383 },
                          polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                          html_instructions:
                            'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                          travel_mode: "DRIVING",
                          maneuver: "turn-slight-right",
                          distance: { value: 283, text: "0.3 km" },
                        },
                        {
                          start_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          duration: { value: 32, text: "1 min" },
                          travel_mode: "DRIVING",
                          end_location: { lng: 51.5155496, lat: 25.2848165 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                          distance: { value: 125, text: "0.1 km" },
                          polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                          maneuver: "turn-right",
                        },
                        {
                          end_location: { lat: 25.2850716, lng: 51.5150352 },
                          maneuver: "turn-right",
                          duration: { text: "1 min", value: 21 },
                          distance: { value: 74, text: "74 m" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                          },
                          start_location: { lat: 25.2848165, lng: 51.5155496 },
                        },
                        {
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          start_location: { lat: 25.2850716, lng: 51.5150352 },
                          end_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          polyline: { points: "uniyC_plyHqBJ" },
                          duration: { text: "1 min", value: 23 },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          distance: { value: 64, text: "64 m" },
                        },
                        {
                          polyline: { points: "griyCsolyH?p@DZ" },
                          duration: { text: "1 min", value: 14 },
                          distance: { text: "39 m", value: 39 },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                          start_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          travel_mode: "DRIVING",
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          maneuver: "turn-left",
                        },
                      ],
                      duration: { text: "11 mins", value: 636 },
                      end_location: { lng: 51.5145907, lat: 25.2856124 },
                    },
                    {
                      traffic_speed_entry: [],
                      duration: { text: "10 mins", value: 598 },
                      start_location: { lng: 51.5145907, lat: 25.2856124 },
                      distance: { text: "4.5 km", value: 4489 },
                      steps: [
                        {
                          start_location: { lng: 51.5145907, lat: 25.2856124 },
                          end_location: { lat: 25.2859334, lng: 51.5165533 },
                          duration: { text: "1 min", value: 73 },
                          distance: { value: 201, text: "0.2 km" },
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                          },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                        },
                        {
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                          end_location: { lng: 51.518743, lat: 25.2796568 },
                          distance: { text: "0.7 km", value: 735 },
                          duration: { value: 78, text: "1 min" },
                          start_location: { lng: 51.5165533, lat: 25.2859334 },
                          maneuver: "turn-right",
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                          end_location: { lat: 25.2769643, lng: 51.5164061 },
                          polyline: {
                            points:
                              "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                          },
                          start_location: { lat: 25.2796568, lng: 51.518743 },
                          distance: { value: 393, text: "0.4 km" },
                          travel_mode: "DRIVING",
                          duration: { value: 59, text: "1 min" },
                          maneuver: "turn-slight-right",
                        },
                        {
                          end_location: { lng: 51.500835, lat: 25.2663847 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                          start_location: { lng: 51.5164061, lat: 25.2769643 },
                          maneuver: "merge",
                          distance: { value: 1963, text: "2.0 km" },
                          polyline: {
                            points:
                              "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                          },
                          duration: { text: "4 mins", value: 229 },
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                          distance: { value: 428, text: "0.4 km" },
                          duration: { text: "1 min", value: 40 },
                          start_location: { lng: 51.500835, lat: 25.2663847 },
                          end_location: { lat: 25.2643805, lng: 51.497229 },
                          maneuver: "turn-slight-right",
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                          },
                        },
                        {
                          start_location: { lat: 25.2643805, lng: 51.497229 },
                          maneuver: "roundabout-right",
                          polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                          end_location: { lat: 25.2656775, lng: 51.4962302 },
                          distance: { value: 182, text: "0.2 km" },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 22 },
                        },
                        {
                          maneuver: "turn-slight-right",
                          distance: { value: 25, text: "25 m" },
                          polyline: { points: "oueyCmzhyH]DKB" },
                          duration: { text: "1 min", value: 3 },
                          html_instructions:
                            "Slight <b>right</b> onto <b>Al Nasr St</b>",
                          end_location: { lng: 51.4961765, lat: 25.2658942 },
                          start_location: { lat: 25.2656775, lng: 51.4962302 },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { text: "0.4 km", value: 389 },
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                          maneuver: "turn-slight-right",
                          travel_mode: "DRIVING",
                          duration: { value: 53, text: "1 min" },
                          polyline: {
                            points:
                              "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                          },
                          end_location: { lat: 25.267547, lng: 51.4995317 },
                          start_location: { lng: 51.4961765, lat: 25.2658942 },
                        },
                        {
                          travel_mode: "DRIVING",
                          polyline: { points: "eafyCaoiyHQFiDdA" },
                          distance: { text: "0.1 km", value: 111 },
                          start_location: { lat: 25.267547, lng: 51.4995317 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohammed St</b>",
                          duration: { value: 22, text: "1 min" },
                          maneuver: "turn-left",
                          end_location: { lat: 25.2684856, lng: 51.499144 },
                        },
                        {
                          maneuver: "turn-left",
                          start_location: { lat: 25.2684856, lng: 51.499144 },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          end_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 19 },
                          distance: { value: 62, text: "62 m" },
                          polyline: { points: "agfyCsliyH\\vABNAJ" },
                        },
                      ],
                      start_address: "7GP7+6RG, Doha, Qatar",
                      end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                      via_waypoint: [],
                      end_address: "7F9X+C9V, Doha, Qatar",
                    },
                    {
                      traffic_speed_entry: [],
                      start_address: "7F9X+C9V, Doha, Qatar",
                      start_location: {
                        lng: 51.49855549999999,
                        lat: 25.2683307,
                      },
                      via_waypoint: [],
                      end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                      steps: [
                        {
                          polyline: { points: "affyC_iiyH@KCO]wA" },
                          duration: { value: 14, text: "1 min" },
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          distance: { value: 62, text: "62 m" },
                          travel_mode: "DRIVING",
                        },
                        {
                          html_instructions:
                            "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                          start_location: { lat: 25.2684856, lng: 51.499144 },
                          end_location: { lng: 51.499066, lat: 25.2687298 },
                          polyline: { points: "agfyCsliyHo@L" },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 9 },
                          distance: { text: "28 m", value: 28 },
                          maneuver: "turn-left",
                        },
                        {
                          start_location: { lat: 25.2687298, lng: 51.499066 },
                          travel_mode: "DRIVING",
                          distance: { value: 154, text: "0.2 km" },
                          maneuver: "turn-right",
                          duration: { text: "1 min", value: 32 },
                          end_location: { lat: 25.2692232, lng: 51.500496 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Baraha</b>",
                          polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                        },
                        {
                          distance: { value: 69, text: "69 m" },
                          end_location: { lat: 25.269806, lng: 51.500265 },
                          start_location: { lng: 51.500496, lat: 25.2692232 },
                          polyline: { points: "skfyCcuiyH{@Ty@X" },
                          duration: { text: "1 min", value: 13 },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                          maneuver: "turn-left",
                        },
                        {
                          end_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          maneuver: "turn-right",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                          start_location: { lng: 51.500265, lat: 25.269806 },
                          polyline: {
                            points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                          },
                          duration: { value: 29, text: "1 min" },
                          distance: { text: "0.2 km", value: 223 },
                          travel_mode: "DRIVING",
                        },
                        {
                          end_location: { lat: 25.2679045, lng: 51.5033973 },
                          travel_mode: "DRIVING",
                          maneuver: "roundabout-right",
                          start_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          duration: { value: 48, text: "1 min" },
                          polyline: {
                            points:
                              "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                          },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                          distance: { value: 326, text: "0.3 km" },
                        },
                        {
                          html_instructions: "Merge onto <b>Salwa Rd</b>",
                          end_location: { lat: 25.2368244, lng: 51.45117 },
                          travel_mode: "DRIVING",
                          distance: { value: 6288, text: "6.3 km" },
                          start_location: { lng: 51.5033973, lat: 25.2679045 },
                          duration: { value: 283, text: "5 mins" },
                          polyline: {
                            points:
                              "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                          },
                          maneuver: "merge",
                        },
                        {
                          duration: { value: 10, text: "1 min" },
                          maneuver: "ramp-right",
                          start_location: { lat: 25.2368244, lng: 51.45117 },
                          html_instructions:
                            'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                          travel_mode: "DRIVING",
                          distance: { value: 194, text: "0.2 km" },
                          polyline: { points: "ca`yCy``yHpAnDlAdD" },
                          end_location: { lat: 25.2360177, lng: 51.4494587 },
                        },
                        {
                          maneuver: "merge",
                          html_instructions:
                            "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          start_location: { lng: 51.4494587, lat: 25.2360177 },
                          distance: { value: 397, text: "0.4 km" },
                          duration: { text: "1 min", value: 33 },
                          end_location: { lng: 51.4461502, lat: 25.2340694 },
                          polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                          travel_mode: "DRIVING",
                        },
                        {
                          end_location: { lng: 51.4456607, lat: 25.2337942 },
                          html_instructions:
                            "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          maneuver: "straight",
                          polyline: { points: "}o_yCma_yHP`@d@~@" },
                          distance: { text: "58 m", value: 58 },
                          duration: { value: 8, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2340694, lng: 51.4461502 },
                        },
                        {
                          distance: { text: "1.5 km", value: 1451 },
                          maneuver: "straight",
                          start_location: { lat: 25.2337942, lng: 51.4456607 },
                          end_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "2 mins", value: 90 },
                          polyline: {
                            points:
                              "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                          },
                          html_instructions:
                            'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                        },
                        {
                          html_instructions:
                            "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                          },
                          start_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          maneuver: "turn-slight-left",
                          distance: { value: 92, text: "92 m" },
                          end_location: { lat: 25.2262036, lng: 51.4328437 },
                          duration: { text: "1 min", value: 10 },
                        },
                        {
                          polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                          end_location: { lat: 25.2257335, lng: 51.4323655 },
                          start_location: { lng: 51.4328437, lat: 25.2262036 },
                          html_instructions: "Continue straight",
                          duration: { value: 19, text: "1 min" },
                          maneuver: "straight",
                          distance: { value: 71, text: "71 m" },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { value: 813, text: "0.8 km" },
                          travel_mode: "DRIVING",
                          duration: { value: 72, text: "1 min" },
                          end_location: { lng: 51.4357808, lat: 25.2196178 },
                          html_instructions:
                            "Continue onto <b>E Industrial Rd</b>",
                          start_location: { lat: 25.2257335, lng: 51.4323655 },
                          polyline: {
                            points:
                              "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                          },
                        },
                        {
                          duration: { text: "1 min", value: 27 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 2</b>",
                          distance: { value: 263, text: "0.3 km" },
                          end_location: {
                            lng: 51.43371639999999,
                            lat: 25.2182661,
                          },
                          start_location: { lng: 51.4357808, lat: 25.2196178 },
                          polyline: {
                            points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                          },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { value: 682, text: "0.7 km" },
                          start_location: {
                            lat: 25.2182661,
                            lng: 51.43371639999999,
                          },
                          end_location: { lng: 51.4366206, lat: 25.2135618 },
                          duration: { value: 78, text: "1 min" },
                          polyline: {
                            points:
                              "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                          },
                          html_instructions:
                            "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                          maneuver: "roundabout-right",
                        },
                        {
                          maneuver: "turn-right",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 8</b>",
                          polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                          end_location: { lat: 25.2133289, lng: 51.4364944 },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 12 },
                          start_location: { lng: 51.4366206, lat: 25.2135618 },
                          distance: { value: 29, text: "29 m" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          start_location: { lat: 25.2133289, lng: 51.4364944 },
                          polyline: { points: "in{xCae}xHXO" },
                          distance: { text: "16 m", value: 16 },
                          html_instructions: "Turn <b>left</b>",
                          maneuver: "turn-left",
                          end_location: {
                            lng: 51.43657289999999,
                            lat: 25.2132031,
                          },
                        },
                      ],
                      duration: { value: 795, text: "13 mins" },
                      distance: { value: 11216, text: "11.2 km" },
                      end_address: "28 Street 8, Ar Rayyan, Qatar",
                    },
                  ],
                },
              ],
            },
            completedAt: 1707942351003,
            createdAt: 1707942285310,
            owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
            destination: {
              coordinates: {
                longitude: 51.43657469999999,
                latitude: 25.213204,
              },
              address: "Street 8, Doha, Qatar",
              type: "corporate-branch",
              id: "9VZwCrYZfl03ob5FrD4F.0",
              geohash: "thkx6myvx1",
            },
            co2Amount: 12.612562888268984,
            origin: {
              coordinates: { longitude: 51.489361, latitude: 25.282848 },
              geohash: "thkxgdt7m0",
              address: "858, الدوحة، Qatar",
            },
            car: {
              year: 2021,
              type: "carbon-emitting",
              model: "LAND CRUSER WAGON 4WD",
              make: "Toyota",
              co2_p_km: 0.3379843740994449,
              seat: 3,
            },
            rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
            preferences: {
              female_passengers_only: null,
              verified_passengers_only: null,
            },
            skin: null,
            status: "completed",
            passengersArray: [
              {
                userId: "user_id",
                name: "Jassim",
                username: "abcd",
                location: {
                  geohash: "thkxgdt7m0",
                  coordinates: { latitude: 25.282848, longitude: 51.489361 },
                  address: "858, الدوحة، Qatar",
                },
                credits: 27.8,
                co2Amount: 4.313694566631215,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                seat: 0,
                id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                wallet_id: "spencer_carbon.testnet",
                carpool: {
                  id: "8292057714690",
                  directions: {
                    status: "OK",
                    geocoded_waypoints: [
                      {
                        geocoder_status: "OK",
                        types: [
                          "establishment",
                          "moving_company",
                          "point_of_interest",
                        ],
                        place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                      },
                      {
                        types: [
                          "establishment",
                          "general_contractor",
                          "point_of_interest",
                        ],
                        geocoder_status: "OK",
                        place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                      },
                      {
                        place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                        geocoder_status: "OK",
                        types: ["premise"],
                      },
                      {
                        types: ["street_address"],
                        geocoder_status: "OK",
                        place_id:
                          "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                      },
                    ],
                    routes: [
                      {
                        overview_polyline: {
                          points:
                            "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                        },
                        waypoint_order: [0, 1],
                        bounds: {
                          northeast: { lat: 25.291958, lng: 51.518743 },
                          southwest: { lat: 25.2132031, lng: 51.4319062 },
                        },
                        warnings: [],
                        copyrights: "Map data ©2024 Google",
                        summary: "Al Rayyan Rd",
                        legs: [
                          {
                            distance: { value: 5407, text: "5.4 km" },
                            start_address: "858, الدوحة، Qatar",
                            via_waypoint: [],
                            traffic_speed_entry: [],
                            start_location: {
                              lng: 51.4906083,
                              lat: 25.2827107,
                            },
                            end_address: "7GP7+6RG, Doha, Qatar",
                            steps: [
                              {
                                polyline: { points: "}_iyCiwgyHz@B" },
                                distance: { value: 33, text: "33 m" },
                                end_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                start_location: {
                                  lng: 51.4906083,
                                  lat: 25.2827107,
                                },
                                duration: { value: 4, text: "1 min" },
                              },
                              {
                                end_location: {
                                  lat: 25.2801532,
                                  lng: 51.4871118,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 574, text: "0.6 km" },
                                start_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                html_instructions:
                                  "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                polyline: {
                                  points:
                                    "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                },
                                duration: { text: "1 min", value: 49 },
                              },
                              {
                                start_location: {
                                  lng: 51.4871118,
                                  lat: 25.2801532,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 426, text: "0.4 km" },
                                polyline: {
                                  points:
                                    "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                },
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                                html_instructions: "Turn <b>left</b>",
                                duration: { value: 44, text: "1 min" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                maneuver: "straight",
                                html_instructions:
                                  "Continue straight onto <b>Al Sadd St</b>",
                                distance: { value: 93, text: "93 m" },
                                end_location: {
                                  lng: 51.4917143,
                                  lat: 25.2802165,
                                },
                                polyline: { points: "cphyCoxgyHEaBAsA" },
                                start_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                              },
                              {
                                end_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                                duration: { text: "1 min", value: 71 },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                },
                                html_instructions: "Slight <b>left</b>",
                                distance: { text: "0.2 km", value: 236 },
                                maneuver: "turn-slight-left",
                                start_location: {
                                  lng: 51.4917143,
                                  lat: 25.2802165,
                                },
                              },
                              {
                                polyline: {
                                  points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                },
                                duration: { text: "1 min", value: 38 },
                                end_location: {
                                  lng: 51.4947254,
                                  lat: 25.281604,
                                },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                distance: { text: "0.2 km", value: 163 },
                                start_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                              },
                              {
                                duration: { value: 76, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.4947254,
                                  lat: 25.281604,
                                },
                                html_instructions:
                                  "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                polyline: {
                                  points:
                                    "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                },
                                maneuver: "keep-left",
                                distance: { text: "1.1 km", value: 1109 },
                                end_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                              },
                              {
                                duration: { text: "3 mins", value: 209 },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                                end_location: {
                                  lat: 25.2893902,
                                  lng: 51.51516729999999,
                                },
                                distance: { value: 1922, text: "1.9 km" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                },
                              },
                              {
                                distance: { text: "0.1 km", value: 144 },
                                maneuver: "turn-right",
                                duration: { value: 15, text: "1 min" },
                                start_location: {
                                  lng: 51.51516729999999,
                                  lat: 25.2893902,
                                },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                end_location: {
                                  lng: 51.51591370000001,
                                  lat: 25.2884741,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                duration: { text: "1 min", value: 11 },
                                polyline: {
                                  points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                },
                                distance: { value: 122, text: "0.1 km" },
                                html_instructions:
                                  'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                                end_location: {
                                  lat: 25.2874383,
                                  lng: 51.516228,
                                },
                              },
                              {
                                duration: { text: "1 min", value: 21 },
                                end_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                start_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                polyline: {
                                  points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                },
                                html_instructions:
                                  'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                                travel_mode: "DRIVING",
                                maneuver: "turn-slight-right",
                                distance: { value: 283, text: "0.3 km" },
                              },
                              {
                                start_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                duration: { value: 32, text: "1 min" },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lng: 51.5155496,
                                  lat: 25.2848165,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                distance: { value: 125, text: "0.1 km" },
                                polyline: {
                                  points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                },
                                maneuver: "turn-right",
                              },
                              {
                                end_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                maneuver: "turn-right",
                                duration: { text: "1 min", value: 21 },
                                distance: { value: 74, text: "74 m" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                },
                                start_location: {
                                  lat: 25.2848165,
                                  lng: 51.5155496,
                                },
                              },
                              {
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                start_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                end_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                polyline: { points: "uniyC_plyHqBJ" },
                                duration: { text: "1 min", value: 23 },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                distance: { value: 64, text: "64 m" },
                              },
                              {
                                polyline: { points: "griyCsolyH?p@DZ" },
                                duration: { text: "1 min", value: 14 },
                                distance: { text: "39 m", value: 39 },
                                end_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                start_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                maneuver: "turn-left",
                              },
                            ],
                            duration: { text: "11 mins", value: 636 },
                            end_location: { lng: 51.5145907, lat: 25.2856124 },
                          },
                          {
                            traffic_speed_entry: [],
                            duration: { text: "10 mins", value: 598 },
                            start_location: {
                              lng: 51.5145907,
                              lat: 25.2856124,
                            },
                            distance: { text: "4.5 km", value: 4489 },
                            steps: [
                              {
                                start_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                end_location: {
                                  lat: 25.2859334,
                                  lng: 51.5165533,
                                },
                                duration: { text: "1 min", value: 73 },
                                distance: { value: 201, text: "0.2 km" },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                              },
                              {
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                end_location: {
                                  lng: 51.518743,
                                  lat: 25.2796568,
                                },
                                distance: { text: "0.7 km", value: 735 },
                                duration: { value: 78, text: "1 min" },
                                start_location: {
                                  lng: 51.5165533,
                                  lat: 25.2859334,
                                },
                                maneuver: "turn-right",
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                end_location: {
                                  lat: 25.2769643,
                                  lng: 51.5164061,
                                },
                                polyline: {
                                  points:
                                    "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                },
                                start_location: {
                                  lat: 25.2796568,
                                  lng: 51.518743,
                                },
                                distance: { value: 393, text: "0.4 km" },
                                travel_mode: "DRIVING",
                                duration: { value: 59, text: "1 min" },
                                maneuver: "turn-slight-right",
                              },
                              {
                                end_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.5164061,
                                  lat: 25.2769643,
                                },
                                maneuver: "merge",
                                distance: { value: 1963, text: "2.0 km" },
                                polyline: {
                                  points:
                                    "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                },
                                duration: { text: "4 mins", value: 229 },
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                distance: { value: 428, text: "0.4 km" },
                                duration: { text: "1 min", value: 40 },
                                start_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                end_location: {
                                  lat: 25.2643805,
                                  lng: 51.497229,
                                },
                                maneuver: "turn-slight-right",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                },
                              },
                              {
                                start_location: {
                                  lat: 25.2643805,
                                  lng: 51.497229,
                                },
                                maneuver: "roundabout-right",
                                polyline: {
                                  points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                end_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                distance: { value: 182, text: "0.2 km" },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 22 },
                              },
                              {
                                maneuver: "turn-slight-right",
                                distance: { value: 25, text: "25 m" },
                                polyline: { points: "oueyCmzhyH]DKB" },
                                duration: { text: "1 min", value: 3 },
                                html_instructions:
                                  "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                end_location: {
                                  lng: 51.4961765,
                                  lat: 25.2658942,
                                },
                                start_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { text: "0.4 km", value: 389 },
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                maneuver: "turn-slight-right",
                                travel_mode: "DRIVING",
                                duration: { value: 53, text: "1 min" },
                                polyline: {
                                  points:
                                    "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                },
                                end_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                start_location: {
                                  lng: 51.4961765,
                                  lat: 25.2658942,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                polyline: { points: "eafyCaoiyHQFiDdA" },
                                distance: { text: "0.1 km", value: 111 },
                                start_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohammed St</b>",
                                duration: { value: 22, text: "1 min" },
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                              },
                              {
                                maneuver: "turn-left",
                                start_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                end_location: {
                                  lng: 51.49855549999999,
                                  lat: 25.2683307,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 19 },
                                distance: { value: 62, text: "62 m" },
                                polyline: { points: "agfyCsliyH\\vABNAJ" },
                              },
                            ],
                            start_address: "7GP7+6RG, Doha, Qatar",
                            end_location: {
                              lat: 25.2683307,
                              lng: 51.49855549999999,
                            },
                            via_waypoint: [],
                            end_address: "7F9X+C9V, Doha, Qatar",
                          },
                          {
                            traffic_speed_entry: [],
                            start_address: "7F9X+C9V, Doha, Qatar",
                            start_location: {
                              lng: 51.49855549999999,
                              lat: 25.2683307,
                            },
                            via_waypoint: [],
                            end_location: {
                              lng: 51.43657289999999,
                              lat: 25.2132031,
                            },
                            steps: [
                              {
                                polyline: { points: "affyC_iiyH@KCO]wA" },
                                duration: { value: 14, text: "1 min" },
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                                start_location: {
                                  lng: 51.49855549999999,
                                  lat: 25.2683307,
                                },
                                distance: { value: 62, text: "62 m" },
                                travel_mode: "DRIVING",
                              },
                              {
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                start_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                                end_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                                polyline: { points: "agfyCsliyHo@L" },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 9 },
                                distance: { text: "28 m", value: 28 },
                                maneuver: "turn-left",
                              },
                              {
                                start_location: {
                                  lat: 25.2687298,
                                  lng: 51.499066,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 154, text: "0.2 km" },
                                maneuver: "turn-right",
                                duration: { text: "1 min", value: 32 },
                                end_location: {
                                  lat: 25.2692232,
                                  lng: 51.500496,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Baraha</b>",
                                polyline: {
                                  points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                },
                              },
                              {
                                distance: { value: 69, text: "69 m" },
                                end_location: {
                                  lat: 25.269806,
                                  lng: 51.500265,
                                },
                                start_location: {
                                  lng: 51.500496,
                                  lat: 25.2692232,
                                },
                                polyline: { points: "skfyCcuiyH{@Ty@X" },
                                duration: { text: "1 min", value: 13 },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                maneuver: "turn-left",
                              },
                              {
                                end_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                maneuver: "turn-right",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                                start_location: {
                                  lng: 51.500265,
                                  lat: 25.269806,
                                },
                                polyline: {
                                  points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                },
                                duration: { value: 29, text: "1 min" },
                                distance: { text: "0.2 km", value: 223 },
                                travel_mode: "DRIVING",
                              },
                              {
                                end_location: {
                                  lat: 25.2679045,
                                  lng: 51.5033973,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "roundabout-right",
                                start_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                duration: { value: 48, text: "1 min" },
                                polyline: {
                                  points:
                                    "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                distance: { value: 326, text: "0.3 km" },
                              },
                              {
                                html_instructions: "Merge onto <b>Salwa Rd</b>",
                                end_location: {
                                  lat: 25.2368244,
                                  lng: 51.45117,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 6288, text: "6.3 km" },
                                start_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                duration: { value: 283, text: "5 mins" },
                                polyline: {
                                  points:
                                    "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                },
                                maneuver: "merge",
                              },
                              {
                                duration: { value: 10, text: "1 min" },
                                maneuver: "ramp-right",
                                start_location: {
                                  lat: 25.2368244,
                                  lng: 51.45117,
                                },
                                html_instructions:
                                  'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                travel_mode: "DRIVING",
                                distance: { value: 194, text: "0.2 km" },
                                polyline: { points: "ca`yCy``yHpAnDlAdD" },
                                end_location: {
                                  lat: 25.2360177,
                                  lng: 51.4494587,
                                },
                              },
                              {
                                maneuver: "merge",
                                html_instructions:
                                  "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.4494587,
                                  lat: 25.2360177,
                                },
                                distance: { value: 397, text: "0.4 km" },
                                duration: { text: "1 min", value: 33 },
                                end_location: {
                                  lng: 51.4461502,
                                  lat: 25.2340694,
                                },
                                polyline: {
                                  points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                end_location: {
                                  lng: 51.4456607,
                                  lat: 25.2337942,
                                },
                                html_instructions:
                                  "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                maneuver: "straight",
                                polyline: { points: "}o_yCma_yHP`@d@~@" },
                                distance: { text: "58 m", value: 58 },
                                duration: { value: 8, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2340694,
                                  lng: 51.4461502,
                                },
                              },
                              {
                                distance: { text: "1.5 km", value: 1451 },
                                maneuver: "straight",
                                start_location: {
                                  lat: 25.2337942,
                                  lng: 51.4456607,
                                },
                                end_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "2 mins", value: 90 },
                                polyline: {
                                  points:
                                    "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                },
                                html_instructions:
                                  'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                              },
                              {
                                html_instructions:
                                  "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                },
                                start_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                maneuver: "turn-slight-left",
                                distance: { value: 92, text: "92 m" },
                                end_location: {
                                  lat: 25.2262036,
                                  lng: 51.4328437,
                                },
                                duration: { text: "1 min", value: 10 },
                              },
                              {
                                polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                                end_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                start_location: {
                                  lng: 51.4328437,
                                  lat: 25.2262036,
                                },
                                html_instructions: "Continue straight",
                                duration: { value: 19, text: "1 min" },
                                maneuver: "straight",
                                distance: { value: 71, text: "71 m" },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { value: 813, text: "0.8 km" },
                                travel_mode: "DRIVING",
                                duration: { value: 72, text: "1 min" },
                                end_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                                html_instructions:
                                  "Continue onto <b>E Industrial Rd</b>",
                                start_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                polyline: {
                                  points:
                                    "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                },
                              },
                              {
                                duration: { text: "1 min", value: 27 },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 2</b>",
                                distance: { value: 263, text: "0.3 km" },
                                end_location: {
                                  lng: 51.43371639999999,
                                  lat: 25.2182661,
                                },
                                start_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                                polyline: {
                                  points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { value: 682, text: "0.7 km" },
                                start_location: {
                                  lat: 25.2182661,
                                  lng: 51.43371639999999,
                                },
                                end_location: {
                                  lng: 51.4366206,
                                  lat: 25.2135618,
                                },
                                duration: { value: 78, text: "1 min" },
                                polyline: {
                                  points:
                                    "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                maneuver: "roundabout-right",
                              },
                              {
                                maneuver: "turn-right",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 8</b>",
                                polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                                end_location: {
                                  lat: 25.2133289,
                                  lng: 51.4364944,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 12 },
                                start_location: {
                                  lng: 51.4366206,
                                  lat: 25.2135618,
                                },
                                distance: { value: 29, text: "29 m" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                start_location: {
                                  lat: 25.2133289,
                                  lng: 51.4364944,
                                },
                                polyline: { points: "in{xCae}xHXO" },
                                distance: { text: "16 m", value: 16 },
                                html_instructions: "Turn <b>left</b>",
                                maneuver: "turn-left",
                                end_location: {
                                  lng: 51.43657289999999,
                                  lat: 25.2132031,
                                },
                              },
                            ],
                            duration: { value: 795, text: "13 mins" },
                            distance: { value: 11216, text: "11.2 km" },
                            end_address: "28 Street 8, Ar Rayyan, Qatar",
                          },
                        ],
                      },
                    ],
                  },
                  completedAt: 1707942351003,
                  createdAt: 1707942285310,
                  owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                  destination: {
                    coordinates: {
                      longitude: 51.43657469999999,
                      latitude: 25.213204,
                    },
                    address: "Street 8, Doha, Qatar",
                    type: "corporate-branch",
                    id: "9VZwCrYZfl03ob5FrD4F.0",
                    geohash: "thkx6myvx1",
                  },
                  co2Amount: 12.612562888268984,
                  origin: {
                    coordinates: { longitude: 51.489361, latitude: 25.282848 },
                    geohash: "thkxgdt7m0",
                    address: "858, الدوحة، Qatar",
                  },
                  car: {
                    year: 2021,
                    type: "carbon-emitting",
                    model: "LAND CRUSER WAGON 4WD",
                    make: "Toyota",
                    co2_p_km: 0.3379843740994449,
                    seat: 3,
                  },
                  rules: [
                    "No smoking",
                    "No eating or drinking",
                    "Keep off the AC",
                  ],
                  preferences: {
                    female_passengers_only: null,
                    verified_passengers_only: null,
                  },
                  skin: null,
                  status: "completed",
                  passengersArray: [
                    {
                      credits: 27.8,
                      username: "abcd",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      seat: 0,
                      id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                      name: "Jassim",
                      co2Amount: 4.313694566631215,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                      location: {
                        geohash: "thkxgdt7m0",
                        coordinates: {
                          latitude: 25.282848,
                          longitude: 51.489361,
                        },
                        address: "858, الدوحة، Qatar",
                      },
                      wallet_id: "spencer_carbon.testnet",
                    },
                    {
                      status: "picked-up",
                      id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                      seat: 2,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                      username: "sarah",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      wallet_id: "sarah_carbon.testnet",
                      credits: 13,
                      co2Amount: 3.7908327398993733,
                      name: "Faisal",
                      location: {
                        coordinates: {
                          longitude: 51.498513,
                          latitude: 25.268246,
                        },
                        address: "7F9X+C9V, Doha, Qatar",
                        geohash: "thkxezun4q",
                      },
                    },
                    {
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      status: "picked-up",
                      location: {
                        geohash: "thkxu5pfc8",
                        address: "7GP7+6RG, Doha, Qatar",
                        coordinates: {
                          longitude: 51.514615,
                          latitude: 25.285509,
                        },
                      },
                      username: "michaelscott",
                      id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                      credits: 13.9,
                      seat: 1,
                      name: "Ahmed",
                      wallet_id: "sofia_carbon.testnet",
                      co2Amount: 4.508035581738396,
                    },
                  ],
                },
                type: "carpool_passenger",
                corporate: "9VZwCrYZfl03ob5FrD4F",
              },
              {
                userId: "user_id",
                name: "Faisal",
                username: "sarah",
                location: {
                  coordinates: { longitude: 51.498513, latitude: 25.268246 },
                  address: "7F9X+C9V, Doha, Qatar",
                  geohash: "thkxezun4q",
                },
                credits: 13,
                co2Amount: 3.7908327398993733,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                seat: 2,
                id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                wallet_id: "sarah_carbon.testnet",
                status: "picked-up",
                carpool: {
                  id: "8292057714690",
                  directions: {
                    status: "OK",
                    geocoded_waypoints: [
                      {
                        geocoder_status: "OK",
                        types: [
                          "establishment",
                          "moving_company",
                          "point_of_interest",
                        ],
                        place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                      },
                      {
                        types: [
                          "establishment",
                          "general_contractor",
                          "point_of_interest",
                        ],
                        geocoder_status: "OK",
                        place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                      },
                      {
                        place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                        geocoder_status: "OK",
                        types: ["premise"],
                      },
                      {
                        types: ["street_address"],
                        geocoder_status: "OK",
                        place_id:
                          "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                      },
                    ],
                    routes: [
                      {
                        overview_polyline: {
                          points:
                            "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                        },
                        waypoint_order: [0, 1],
                        bounds: {
                          northeast: { lat: 25.291958, lng: 51.518743 },
                          southwest: { lat: 25.2132031, lng: 51.4319062 },
                        },
                        warnings: [],
                        copyrights: "Map data ©2024 Google",
                        summary: "Al Rayyan Rd",
                        legs: [
                          {
                            distance: { value: 5407, text: "5.4 km" },
                            start_address: "858, الدوحة، Qatar",
                            via_waypoint: [],
                            traffic_speed_entry: [],
                            start_location: {
                              lng: 51.4906083,
                              lat: 25.2827107,
                            },
                            end_address: "7GP7+6RG, Doha, Qatar",
                            steps: [
                              {
                                polyline: { points: "}_iyCiwgyHz@B" },
                                distance: { value: 33, text: "33 m" },
                                end_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                start_location: {
                                  lng: 51.4906083,
                                  lat: 25.2827107,
                                },
                                duration: { value: 4, text: "1 min" },
                              },
                              {
                                end_location: {
                                  lat: 25.2801532,
                                  lng: 51.4871118,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 574, text: "0.6 km" },
                                start_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                html_instructions:
                                  "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                polyline: {
                                  points:
                                    "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                },
                                duration: { text: "1 min", value: 49 },
                              },
                              {
                                start_location: {
                                  lng: 51.4871118,
                                  lat: 25.2801532,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 426, text: "0.4 km" },
                                polyline: {
                                  points:
                                    "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                },
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                                html_instructions: "Turn <b>left</b>",
                                duration: { value: 44, text: "1 min" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                maneuver: "straight",
                                html_instructions:
                                  "Continue straight onto <b>Al Sadd St</b>",
                                distance: { value: 93, text: "93 m" },
                                end_location: {
                                  lng: 51.4917143,
                                  lat: 25.2802165,
                                },
                                polyline: { points: "cphyCoxgyHEaBAsA" },
                                start_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                              },
                              {
                                end_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                                duration: { text: "1 min", value: 71 },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                },
                                html_instructions: "Slight <b>left</b>",
                                distance: { text: "0.2 km", value: 236 },
                                maneuver: "turn-slight-left",
                                start_location: {
                                  lng: 51.4917143,
                                  lat: 25.2802165,
                                },
                              },
                              {
                                polyline: {
                                  points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                },
                                duration: { text: "1 min", value: 38 },
                                end_location: {
                                  lng: 51.4947254,
                                  lat: 25.281604,
                                },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                distance: { text: "0.2 km", value: 163 },
                                start_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                              },
                              {
                                duration: { value: 76, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.4947254,
                                  lat: 25.281604,
                                },
                                html_instructions:
                                  "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                polyline: {
                                  points:
                                    "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                },
                                maneuver: "keep-left",
                                distance: { text: "1.1 km", value: 1109 },
                                end_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                              },
                              {
                                duration: { text: "3 mins", value: 209 },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                                end_location: {
                                  lat: 25.2893902,
                                  lng: 51.51516729999999,
                                },
                                distance: { value: 1922, text: "1.9 km" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                },
                              },
                              {
                                distance: { text: "0.1 km", value: 144 },
                                maneuver: "turn-right",
                                duration: { value: 15, text: "1 min" },
                                start_location: {
                                  lng: 51.51516729999999,
                                  lat: 25.2893902,
                                },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                end_location: {
                                  lng: 51.51591370000001,
                                  lat: 25.2884741,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                duration: { text: "1 min", value: 11 },
                                polyline: {
                                  points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                },
                                distance: { value: 122, text: "0.1 km" },
                                html_instructions:
                                  'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                                end_location: {
                                  lat: 25.2874383,
                                  lng: 51.516228,
                                },
                              },
                              {
                                duration: { text: "1 min", value: 21 },
                                end_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                start_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                polyline: {
                                  points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                },
                                html_instructions:
                                  'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                                travel_mode: "DRIVING",
                                maneuver: "turn-slight-right",
                                distance: { value: 283, text: "0.3 km" },
                              },
                              {
                                start_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                duration: { value: 32, text: "1 min" },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lng: 51.5155496,
                                  lat: 25.2848165,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                distance: { value: 125, text: "0.1 km" },
                                polyline: {
                                  points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                },
                                maneuver: "turn-right",
                              },
                              {
                                end_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                maneuver: "turn-right",
                                duration: { text: "1 min", value: 21 },
                                distance: { value: 74, text: "74 m" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                },
                                start_location: {
                                  lat: 25.2848165,
                                  lng: 51.5155496,
                                },
                              },
                              {
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                start_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                end_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                polyline: { points: "uniyC_plyHqBJ" },
                                duration: { text: "1 min", value: 23 },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                distance: { value: 64, text: "64 m" },
                              },
                              {
                                polyline: { points: "griyCsolyH?p@DZ" },
                                duration: { text: "1 min", value: 14 },
                                distance: { text: "39 m", value: 39 },
                                end_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                start_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                maneuver: "turn-left",
                              },
                            ],
                            duration: { text: "11 mins", value: 636 },
                            end_location: { lng: 51.5145907, lat: 25.2856124 },
                          },
                          {
                            traffic_speed_entry: [],
                            duration: { text: "10 mins", value: 598 },
                            start_location: {
                              lng: 51.5145907,
                              lat: 25.2856124,
                            },
                            distance: { text: "4.5 km", value: 4489 },
                            steps: [
                              {
                                start_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                end_location: {
                                  lat: 25.2859334,
                                  lng: 51.5165533,
                                },
                                duration: { text: "1 min", value: 73 },
                                distance: { value: 201, text: "0.2 km" },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                              },
                              {
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                end_location: {
                                  lng: 51.518743,
                                  lat: 25.2796568,
                                },
                                distance: { text: "0.7 km", value: 735 },
                                duration: { value: 78, text: "1 min" },
                                start_location: {
                                  lng: 51.5165533,
                                  lat: 25.2859334,
                                },
                                maneuver: "turn-right",
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                end_location: {
                                  lat: 25.2769643,
                                  lng: 51.5164061,
                                },
                                polyline: {
                                  points:
                                    "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                },
                                start_location: {
                                  lat: 25.2796568,
                                  lng: 51.518743,
                                },
                                distance: { value: 393, text: "0.4 km" },
                                travel_mode: "DRIVING",
                                duration: { value: 59, text: "1 min" },
                                maneuver: "turn-slight-right",
                              },
                              {
                                end_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.5164061,
                                  lat: 25.2769643,
                                },
                                maneuver: "merge",
                                distance: { value: 1963, text: "2.0 km" },
                                polyline: {
                                  points:
                                    "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                },
                                duration: { text: "4 mins", value: 229 },
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                distance: { value: 428, text: "0.4 km" },
                                duration: { text: "1 min", value: 40 },
                                start_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                end_location: {
                                  lat: 25.2643805,
                                  lng: 51.497229,
                                },
                                maneuver: "turn-slight-right",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                },
                              },
                              {
                                start_location: {
                                  lat: 25.2643805,
                                  lng: 51.497229,
                                },
                                maneuver: "roundabout-right",
                                polyline: {
                                  points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                end_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                distance: { value: 182, text: "0.2 km" },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 22 },
                              },
                              {
                                maneuver: "turn-slight-right",
                                distance: { value: 25, text: "25 m" },
                                polyline: { points: "oueyCmzhyH]DKB" },
                                duration: { text: "1 min", value: 3 },
                                html_instructions:
                                  "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                end_location: {
                                  lng: 51.4961765,
                                  lat: 25.2658942,
                                },
                                start_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { text: "0.4 km", value: 389 },
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                maneuver: "turn-slight-right",
                                travel_mode: "DRIVING",
                                duration: { value: 53, text: "1 min" },
                                polyline: {
                                  points:
                                    "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                },
                                end_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                start_location: {
                                  lng: 51.4961765,
                                  lat: 25.2658942,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                polyline: { points: "eafyCaoiyHQFiDdA" },
                                distance: { text: "0.1 km", value: 111 },
                                start_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohammed St</b>",
                                duration: { value: 22, text: "1 min" },
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                              },
                              {
                                maneuver: "turn-left",
                                start_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                end_location: {
                                  lng: 51.49855549999999,
                                  lat: 25.2683307,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 19 },
                                distance: { value: 62, text: "62 m" },
                                polyline: { points: "agfyCsliyH\\vABNAJ" },
                              },
                            ],
                            start_address: "7GP7+6RG, Doha, Qatar",
                            end_location: {
                              lat: 25.2683307,
                              lng: 51.49855549999999,
                            },
                            via_waypoint: [],
                            end_address: "7F9X+C9V, Doha, Qatar",
                          },
                          {
                            traffic_speed_entry: [],
                            start_address: "7F9X+C9V, Doha, Qatar",
                            start_location: {
                              lng: 51.49855549999999,
                              lat: 25.2683307,
                            },
                            via_waypoint: [],
                            end_location: {
                              lng: 51.43657289999999,
                              lat: 25.2132031,
                            },
                            steps: [
                              {
                                polyline: { points: "affyC_iiyH@KCO]wA" },
                                duration: { value: 14, text: "1 min" },
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                                start_location: {
                                  lng: 51.49855549999999,
                                  lat: 25.2683307,
                                },
                                distance: { value: 62, text: "62 m" },
                                travel_mode: "DRIVING",
                              },
                              {
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                start_location: {
                                  lat: 25.2684856,
                                  lng: 51.499144,
                                },
                                end_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                                polyline: { points: "agfyCsliyHo@L" },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 9 },
                                distance: { text: "28 m", value: 28 },
                                maneuver: "turn-left",
                              },
                              {
                                start_location: {
                                  lat: 25.2687298,
                                  lng: 51.499066,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 154, text: "0.2 km" },
                                maneuver: "turn-right",
                                duration: { text: "1 min", value: 32 },
                                end_location: {
                                  lat: 25.2692232,
                                  lng: 51.500496,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Baraha</b>",
                                polyline: {
                                  points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                },
                              },
                              {
                                distance: { value: 69, text: "69 m" },
                                end_location: {
                                  lat: 25.269806,
                                  lng: 51.500265,
                                },
                                start_location: {
                                  lng: 51.500496,
                                  lat: 25.2692232,
                                },
                                polyline: { points: "skfyCcuiyH{@Ty@X" },
                                duration: { text: "1 min", value: 13 },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                maneuver: "turn-left",
                              },
                              {
                                end_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                maneuver: "turn-right",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                                start_location: {
                                  lng: 51.500265,
                                  lat: 25.269806,
                                },
                                polyline: {
                                  points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                },
                                duration: { value: 29, text: "1 min" },
                                distance: { text: "0.2 km", value: 223 },
                                travel_mode: "DRIVING",
                              },
                              {
                                end_location: {
                                  lat: 25.2679045,
                                  lng: 51.5033973,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "roundabout-right",
                                start_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                duration: { value: 48, text: "1 min" },
                                polyline: {
                                  points:
                                    "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                distance: { value: 326, text: "0.3 km" },
                              },
                              {
                                html_instructions: "Merge onto <b>Salwa Rd</b>",
                                end_location: {
                                  lat: 25.2368244,
                                  lng: 51.45117,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 6288, text: "6.3 km" },
                                start_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                duration: { value: 283, text: "5 mins" },
                                polyline: {
                                  points:
                                    "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                },
                                maneuver: "merge",
                              },
                              {
                                duration: { value: 10, text: "1 min" },
                                maneuver: "ramp-right",
                                start_location: {
                                  lat: 25.2368244,
                                  lng: 51.45117,
                                },
                                html_instructions:
                                  'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                travel_mode: "DRIVING",
                                distance: { value: 194, text: "0.2 km" },
                                polyline: { points: "ca`yCy``yHpAnDlAdD" },
                                end_location: {
                                  lat: 25.2360177,
                                  lng: 51.4494587,
                                },
                              },
                              {
                                maneuver: "merge",
                                html_instructions:
                                  "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.4494587,
                                  lat: 25.2360177,
                                },
                                distance: { value: 397, text: "0.4 km" },
                                duration: { text: "1 min", value: 33 },
                                end_location: {
                                  lng: 51.4461502,
                                  lat: 25.2340694,
                                },
                                polyline: {
                                  points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                end_location: {
                                  lng: 51.4456607,
                                  lat: 25.2337942,
                                },
                                html_instructions:
                                  "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                maneuver: "straight",
                                polyline: { points: "}o_yCma_yHP`@d@~@" },
                                distance: { text: "58 m", value: 58 },
                                duration: { value: 8, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2340694,
                                  lng: 51.4461502,
                                },
                              },
                              {
                                distance: { text: "1.5 km", value: 1451 },
                                maneuver: "straight",
                                start_location: {
                                  lat: 25.2337942,
                                  lng: 51.4456607,
                                },
                                end_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "2 mins", value: 90 },
                                polyline: {
                                  points:
                                    "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                },
                                html_instructions:
                                  'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                              },
                              {
                                html_instructions:
                                  "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                },
                                start_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                maneuver: "turn-slight-left",
                                distance: { value: 92, text: "92 m" },
                                end_location: {
                                  lat: 25.2262036,
                                  lng: 51.4328437,
                                },
                                duration: { text: "1 min", value: 10 },
                              },
                              {
                                polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                                end_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                start_location: {
                                  lng: 51.4328437,
                                  lat: 25.2262036,
                                },
                                html_instructions: "Continue straight",
                                duration: { value: 19, text: "1 min" },
                                maneuver: "straight",
                                distance: { value: 71, text: "71 m" },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { value: 813, text: "0.8 km" },
                                travel_mode: "DRIVING",
                                duration: { value: 72, text: "1 min" },
                                end_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                                html_instructions:
                                  "Continue onto <b>E Industrial Rd</b>",
                                start_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                polyline: {
                                  points:
                                    "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                },
                              },
                              {
                                duration: { text: "1 min", value: 27 },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 2</b>",
                                distance: { value: 263, text: "0.3 km" },
                                end_location: {
                                  lng: 51.43371639999999,
                                  lat: 25.2182661,
                                },
                                start_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                                polyline: {
                                  points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { value: 682, text: "0.7 km" },
                                start_location: {
                                  lat: 25.2182661,
                                  lng: 51.43371639999999,
                                },
                                end_location: {
                                  lng: 51.4366206,
                                  lat: 25.2135618,
                                },
                                duration: { value: 78, text: "1 min" },
                                polyline: {
                                  points:
                                    "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                },
                                html_instructions:
                                  "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                maneuver: "roundabout-right",
                              },
                              {
                                maneuver: "turn-right",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 8</b>",
                                polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                                end_location: {
                                  lat: 25.2133289,
                                  lng: 51.4364944,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 12 },
                                start_location: {
                                  lng: 51.4366206,
                                  lat: 25.2135618,
                                },
                                distance: { value: 29, text: "29 m" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                start_location: {
                                  lat: 25.2133289,
                                  lng: 51.4364944,
                                },
                                polyline: { points: "in{xCae}xHXO" },
                                distance: { text: "16 m", value: 16 },
                                html_instructions: "Turn <b>left</b>",
                                maneuver: "turn-left",
                                end_location: {
                                  lng: 51.43657289999999,
                                  lat: 25.2132031,
                                },
                              },
                            ],
                            duration: { value: 795, text: "13 mins" },
                            distance: { value: 11216, text: "11.2 km" },
                            end_address: "28 Street 8, Ar Rayyan, Qatar",
                          },
                        ],
                      },
                    ],
                  },
                  completedAt: 1707942351003,
                  createdAt: 1707942285310,
                  owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                  destination: {
                    coordinates: {
                      longitude: 51.43657469999999,
                      latitude: 25.213204,
                    },
                    address: "Street 8, Doha, Qatar",
                    type: "corporate-branch",
                    id: "9VZwCrYZfl03ob5FrD4F.0",
                    geohash: "thkx6myvx1",
                  },
                  co2Amount: 12.612562888268984,
                  origin: {
                    coordinates: { longitude: 51.489361, latitude: 25.282848 },
                    geohash: "thkxgdt7m0",
                    address: "858, الدوحة، Qatar",
                  },
                  car: {
                    year: 2021,
                    type: "carbon-emitting",
                    model: "LAND CRUSER WAGON 4WD",
                    make: "Toyota",
                    co2_p_km: 0.3379843740994449,
                    seat: 3,
                  },
                  rules: [
                    "No smoking",
                    "No eating or drinking",
                    "Keep off the AC",
                  ],
                  preferences: {
                    female_passengers_only: null,
                    verified_passengers_only: null,
                  },
                  skin: null,
                  status: "completed",
                  passengersArray: [
                    {
                      userId: "user_id",
                      name: "Jassim",
                      username: "abcd",
                      location: {
                        geohash: "thkxgdt7m0",
                        coordinates: {
                          latitude: 25.282848,
                          longitude: 51.489361,
                        },
                        address: "858, الدوحة، Qatar",
                      },
                      credits: 27.8,
                      co2Amount: 4.313694566631215,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                      seat: 0,
                      id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                      wallet_id: "spencer_carbon.testnet",
                      carpool: {
                        id: "8292057714690",
                        directions: {
                          status: "OK",
                          geocoded_waypoints: [
                            {
                              geocoder_status: "OK",
                              types: [
                                "establishment",
                                "moving_company",
                                "point_of_interest",
                              ],
                              place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                            },
                            {
                              types: [
                                "establishment",
                                "general_contractor",
                                "point_of_interest",
                              ],
                              geocoder_status: "OK",
                              place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                            },
                            {
                              place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                              geocoder_status: "OK",
                              types: ["premise"],
                            },
                            {
                              types: ["street_address"],
                              geocoder_status: "OK",
                              place_id:
                                "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                            },
                          ],
                          routes: [
                            {
                              overview_polyline: {
                                points:
                                  "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                              },
                              waypoint_order: [0, 1],
                              bounds: {
                                northeast: { lat: 25.291958, lng: 51.518743 },
                                southwest: { lat: 25.2132031, lng: 51.4319062 },
                              },
                              warnings: [],
                              copyrights: "Map data ©2024 Google",
                              summary: "Al Rayyan Rd",
                              legs: [
                                {
                                  distance: { value: 5407, text: "5.4 km" },
                                  start_address: "858, الدوحة، Qatar",
                                  via_waypoint: [],
                                  traffic_speed_entry: [],
                                  start_location: {
                                    lng: 51.4906083,
                                    lat: 25.2827107,
                                  },
                                  end_address: "7GP7+6RG, Doha, Qatar",
                                  steps: [
                                    {
                                      polyline: { points: "}_iyCiwgyHz@B" },
                                      distance: { value: 33, text: "33 m" },
                                      end_location: {
                                        lng: 51.4905936,
                                        lat: 25.2824136,
                                      },
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                      start_location: {
                                        lng: 51.4906083,
                                        lat: 25.2827107,
                                      },
                                      duration: { value: 4, text: "1 min" },
                                    },
                                    {
                                      end_location: {
                                        lat: 25.2801532,
                                        lng: 51.4871118,
                                      },
                                      travel_mode: "DRIVING",
                                      distance: { value: 574, text: "0.6 km" },
                                      start_location: {
                                        lng: 51.4905936,
                                        lat: 25.2824136,
                                      },
                                      html_instructions:
                                        "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                      polyline: {
                                        points:
                                          "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                      },
                                      duration: { text: "1 min", value: 49 },
                                    },
                                    {
                                      start_location: {
                                        lng: 51.4871118,
                                        lat: 25.2801532,
                                      },
                                      travel_mode: "DRIVING",
                                      distance: { value: 426, text: "0.4 km" },
                                      polyline: {
                                        points:
                                          "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                      },
                                      maneuver: "turn-left",
                                      end_location: {
                                        lat: 25.2801774,
                                        lng: 51.49079529999999,
                                      },
                                      html_instructions: "Turn <b>left</b>",
                                      duration: { value: 44, text: "1 min" },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      duration: { value: 8, text: "1 min" },
                                      maneuver: "straight",
                                      html_instructions:
                                        "Continue straight onto <b>Al Sadd St</b>",
                                      distance: { value: 93, text: "93 m" },
                                      end_location: {
                                        lng: 51.4917143,
                                        lat: 25.2802165,
                                      },
                                      polyline: { points: "cphyCoxgyHEaBAsA" },
                                      start_location: {
                                        lat: 25.2801774,
                                        lng: 51.49079529999999,
                                      },
                                    },
                                    {
                                      end_location: {
                                        lat: 25.2804496,
                                        lng: 51.4940386,
                                      },
                                      duration: { text: "1 min", value: 71 },
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                      },
                                      html_instructions: "Slight <b>left</b>",
                                      distance: { text: "0.2 km", value: 236 },
                                      maneuver: "turn-slight-left",
                                      start_location: {
                                        lng: 51.4917143,
                                        lat: 25.2802165,
                                      },
                                    },
                                    {
                                      polyline: {
                                        points:
                                          "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                      },
                                      duration: { text: "1 min", value: 38 },
                                      end_location: {
                                        lng: 51.4947254,
                                        lat: 25.281604,
                                      },
                                      html_instructions:
                                        'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                                      travel_mode: "DRIVING",
                                      maneuver: "turn-left",
                                      distance: { text: "0.2 km", value: 163 },
                                      start_location: {
                                        lat: 25.2804496,
                                        lng: 51.4940386,
                                      },
                                    },
                                    {
                                      duration: { value: 76, text: "1 min" },
                                      travel_mode: "DRIVING",
                                      start_location: {
                                        lng: 51.4947254,
                                        lat: 25.281604,
                                      },
                                      html_instructions:
                                        "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                      polyline: {
                                        points:
                                          "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                      },
                                      maneuver: "keep-left",
                                      distance: { text: "1.1 km", value: 1109 },
                                      end_location: {
                                        lat: 25.2914195,
                                        lng: 51.4965617,
                                      },
                                    },
                                    {
                                      duration: { text: "3 mins", value: 209 },
                                      travel_mode: "DRIVING",
                                      start_location: {
                                        lat: 25.2914195,
                                        lng: 51.4965617,
                                      },
                                      end_location: {
                                        lat: 25.2893902,
                                        lng: 51.51516729999999,
                                      },
                                      distance: { value: 1922, text: "1.9 km" },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                      maneuver: "turn-right",
                                      polyline: {
                                        points:
                                          "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                      },
                                    },
                                    {
                                      distance: { text: "0.1 km", value: 144 },
                                      maneuver: "turn-right",
                                      duration: { value: 15, text: "1 min" },
                                      start_location: {
                                        lng: 51.51516729999999,
                                        lat: 25.2893902,
                                      },
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                      end_location: {
                                        lng: 51.51591370000001,
                                        lat: 25.2884741,
                                      },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      start_location: {
                                        lat: 25.2884741,
                                        lng: 51.51591370000001,
                                      },
                                      duration: { text: "1 min", value: 11 },
                                      polyline: {
                                        points:
                                          "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                      },
                                      distance: { value: 122, text: "0.1 km" },
                                      html_instructions:
                                        'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                                      end_location: {
                                        lat: 25.2874383,
                                        lng: 51.516228,
                                      },
                                    },
                                    {
                                      duration: { text: "1 min", value: 21 },
                                      end_location: {
                                        lng: 51.51678399999999,
                                        lat: 25.2849434,
                                      },
                                      start_location: {
                                        lng: 51.516228,
                                        lat: 25.2874383,
                                      },
                                      polyline: {
                                        points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                      },
                                      html_instructions:
                                        'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                                      travel_mode: "DRIVING",
                                      maneuver: "turn-slight-right",
                                      distance: { value: 283, text: "0.3 km" },
                                    },
                                    {
                                      start_location: {
                                        lng: 51.51678399999999,
                                        lat: 25.2849434,
                                      },
                                      duration: { value: 32, text: "1 min" },
                                      travel_mode: "DRIVING",
                                      end_location: {
                                        lng: 51.5155496,
                                        lat: 25.2848165,
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                      distance: { value: 125, text: "0.1 km" },
                                      polyline: {
                                        points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                      },
                                      maneuver: "turn-right",
                                    },
                                    {
                                      end_location: {
                                        lat: 25.2850716,
                                        lng: 51.5150352,
                                      },
                                      maneuver: "turn-right",
                                      duration: { text: "1 min", value: 21 },
                                      distance: { value: 74, text: "74 m" },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                      },
                                      start_location: {
                                        lat: 25.2848165,
                                        lng: 51.5155496,
                                      },
                                    },
                                    {
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                      start_location: {
                                        lat: 25.2850716,
                                        lng: 51.5150352,
                                      },
                                      end_location: {
                                        lat: 25.2856417,
                                        lng: 51.51497639999999,
                                      },
                                      polyline: { points: "uniyC_plyHqBJ" },
                                      duration: { text: "1 min", value: 23 },
                                      maneuver: "turn-right",
                                      travel_mode: "DRIVING",
                                      distance: { value: 64, text: "64 m" },
                                    },
                                    {
                                      polyline: { points: "griyCsolyH?p@DZ" },
                                      duration: { text: "1 min", value: 14 },
                                      distance: { text: "39 m", value: 39 },
                                      end_location: {
                                        lng: 51.5145907,
                                        lat: 25.2856124,
                                      },
                                      start_location: {
                                        lat: 25.2856417,
                                        lng: 51.51497639999999,
                                      },
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                      maneuver: "turn-left",
                                    },
                                  ],
                                  duration: { text: "11 mins", value: 636 },
                                  end_location: {
                                    lng: 51.5145907,
                                    lat: 25.2856124,
                                  },
                                },
                                {
                                  traffic_speed_entry: [],
                                  duration: { text: "10 mins", value: 598 },
                                  start_location: {
                                    lng: 51.5145907,
                                    lat: 25.2856124,
                                  },
                                  distance: { text: "4.5 km", value: 4489 },
                                  steps: [
                                    {
                                      start_location: {
                                        lng: 51.5145907,
                                        lat: 25.2856124,
                                      },
                                      end_location: {
                                        lat: 25.2859334,
                                        lng: 51.5165533,
                                      },
                                      duration: { text: "1 min", value: 73 },
                                      distance: { value: 201, text: "0.2 km" },
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                      },
                                      html_instructions:
                                        "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                      end_location: {
                                        lng: 51.518743,
                                        lat: 25.2796568,
                                      },
                                      distance: { text: "0.7 km", value: 735 },
                                      duration: { value: 78, text: "1 min" },
                                      start_location: {
                                        lng: 51.5165533,
                                        lat: 25.2859334,
                                      },
                                      maneuver: "turn-right",
                                    },
                                    {
                                      html_instructions:
                                        "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                      end_location: {
                                        lat: 25.2769643,
                                        lng: 51.5164061,
                                      },
                                      polyline: {
                                        points:
                                          "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                      },
                                      start_location: {
                                        lat: 25.2796568,
                                        lng: 51.518743,
                                      },
                                      distance: { value: 393, text: "0.4 km" },
                                      travel_mode: "DRIVING",
                                      duration: { value: 59, text: "1 min" },
                                      maneuver: "turn-slight-right",
                                    },
                                    {
                                      end_location: {
                                        lng: 51.500835,
                                        lat: 25.2663847,
                                      },
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                                      start_location: {
                                        lng: 51.5164061,
                                        lat: 25.2769643,
                                      },
                                      maneuver: "merge",
                                      distance: { value: 1963, text: "2.0 km" },
                                      polyline: {
                                        points:
                                          "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                      },
                                      duration: { text: "4 mins", value: 229 },
                                    },
                                    {
                                      html_instructions:
                                        "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                      distance: { value: 428, text: "0.4 km" },
                                      duration: { text: "1 min", value: 40 },
                                      start_location: {
                                        lng: 51.500835,
                                        lat: 25.2663847,
                                      },
                                      end_location: {
                                        lat: 25.2643805,
                                        lng: 51.497229,
                                      },
                                      maneuver: "turn-slight-right",
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                      },
                                    },
                                    {
                                      start_location: {
                                        lat: 25.2643805,
                                        lng: 51.497229,
                                      },
                                      maneuver: "roundabout-right",
                                      polyline: {
                                        points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                      },
                                      html_instructions:
                                        "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                      end_location: {
                                        lat: 25.2656775,
                                        lng: 51.4962302,
                                      },
                                      distance: { value: 182, text: "0.2 km" },
                                      travel_mode: "DRIVING",
                                      duration: { text: "1 min", value: 22 },
                                    },
                                    {
                                      maneuver: "turn-slight-right",
                                      distance: { value: 25, text: "25 m" },
                                      polyline: { points: "oueyCmzhyH]DKB" },
                                      duration: { text: "1 min", value: 3 },
                                      html_instructions:
                                        "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                      end_location: {
                                        lng: 51.4961765,
                                        lat: 25.2658942,
                                      },
                                      start_location: {
                                        lat: 25.2656775,
                                        lng: 51.4962302,
                                      },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      distance: { text: "0.4 km", value: 389 },
                                      html_instructions:
                                        "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                      maneuver: "turn-slight-right",
                                      travel_mode: "DRIVING",
                                      duration: { value: 53, text: "1 min" },
                                      polyline: {
                                        points:
                                          "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                      },
                                      end_location: {
                                        lat: 25.267547,
                                        lng: 51.4995317,
                                      },
                                      start_location: {
                                        lng: 51.4961765,
                                        lat: 25.2658942,
                                      },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      polyline: { points: "eafyCaoiyHQFiDdA" },
                                      distance: { text: "0.1 km", value: 111 },
                                      start_location: {
                                        lat: 25.267547,
                                        lng: 51.4995317,
                                      },
                                      html_instructions:
                                        "Turn <b>left</b> onto <b>Mohammed St</b>",
                                      duration: { value: 22, text: "1 min" },
                                      maneuver: "turn-left",
                                      end_location: {
                                        lat: 25.2684856,
                                        lng: 51.499144,
                                      },
                                    },
                                    {
                                      maneuver: "turn-left",
                                      start_location: {
                                        lat: 25.2684856,
                                        lng: 51.499144,
                                      },
                                      html_instructions:
                                        'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                      end_location: {
                                        lng: 51.49855549999999,
                                        lat: 25.2683307,
                                      },
                                      travel_mode: "DRIVING",
                                      duration: { text: "1 min", value: 19 },
                                      distance: { value: 62, text: "62 m" },
                                      polyline: {
                                        points: "agfyCsliyH\\vABNAJ",
                                      },
                                    },
                                  ],
                                  start_address: "7GP7+6RG, Doha, Qatar",
                                  end_location: {
                                    lat: 25.2683307,
                                    lng: 51.49855549999999,
                                  },
                                  via_waypoint: [],
                                  end_address: "7F9X+C9V, Doha, Qatar",
                                },
                                {
                                  traffic_speed_entry: [],
                                  start_address: "7F9X+C9V, Doha, Qatar",
                                  start_location: {
                                    lng: 51.49855549999999,
                                    lat: 25.2683307,
                                  },
                                  via_waypoint: [],
                                  end_location: {
                                    lng: 51.43657289999999,
                                    lat: 25.2132031,
                                  },
                                  steps: [
                                    {
                                      polyline: { points: "affyC_iiyH@KCO]wA" },
                                      duration: { value: 14, text: "1 min" },
                                      end_location: {
                                        lng: 51.499144,
                                        lat: 25.2684856,
                                      },
                                      html_instructions:
                                        "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                                      start_location: {
                                        lng: 51.49855549999999,
                                        lat: 25.2683307,
                                      },
                                      distance: { value: 62, text: "62 m" },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      html_instructions:
                                        "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                      start_location: {
                                        lat: 25.2684856,
                                        lng: 51.499144,
                                      },
                                      end_location: {
                                        lng: 51.499066,
                                        lat: 25.2687298,
                                      },
                                      polyline: { points: "agfyCsliyHo@L" },
                                      travel_mode: "DRIVING",
                                      duration: { text: "1 min", value: 9 },
                                      distance: { text: "28 m", value: 28 },
                                      maneuver: "turn-left",
                                    },
                                    {
                                      start_location: {
                                        lat: 25.2687298,
                                        lng: 51.499066,
                                      },
                                      travel_mode: "DRIVING",
                                      distance: { value: 154, text: "0.2 km" },
                                      maneuver: "turn-right",
                                      duration: { text: "1 min", value: 32 },
                                      end_location: {
                                        lat: 25.2692232,
                                        lng: 51.500496,
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Baraha</b>",
                                      polyline: {
                                        points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                      },
                                    },
                                    {
                                      distance: { value: 69, text: "69 m" },
                                      end_location: {
                                        lat: 25.269806,
                                        lng: 51.500265,
                                      },
                                      start_location: {
                                        lng: 51.500496,
                                        lat: 25.2692232,
                                      },
                                      polyline: { points: "skfyCcuiyH{@Ty@X" },
                                      duration: { text: "1 min", value: 13 },
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                      maneuver: "turn-left",
                                    },
                                    {
                                      end_location: {
                                        lat: 25.2705523,
                                        lng: 51.50232339999999,
                                      },
                                      maneuver: "turn-right",
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                                      start_location: {
                                        lng: 51.500265,
                                        lat: 25.269806,
                                      },
                                      polyline: {
                                        points:
                                          "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                      },
                                      duration: { value: 29, text: "1 min" },
                                      distance: { text: "0.2 km", value: 223 },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      end_location: {
                                        lat: 25.2679045,
                                        lng: 51.5033973,
                                      },
                                      travel_mode: "DRIVING",
                                      maneuver: "roundabout-right",
                                      start_location: {
                                        lat: 25.2705523,
                                        lng: 51.50232339999999,
                                      },
                                      duration: { value: 48, text: "1 min" },
                                      polyline: {
                                        points:
                                          "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                      },
                                      html_instructions:
                                        "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                      distance: { value: 326, text: "0.3 km" },
                                    },
                                    {
                                      html_instructions:
                                        "Merge onto <b>Salwa Rd</b>",
                                      end_location: {
                                        lat: 25.2368244,
                                        lng: 51.45117,
                                      },
                                      travel_mode: "DRIVING",
                                      distance: { value: 6288, text: "6.3 km" },
                                      start_location: {
                                        lng: 51.5033973,
                                        lat: 25.2679045,
                                      },
                                      duration: { value: 283, text: "5 mins" },
                                      polyline: {
                                        points:
                                          "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                      },
                                      maneuver: "merge",
                                    },
                                    {
                                      duration: { value: 10, text: "1 min" },
                                      maneuver: "ramp-right",
                                      start_location: {
                                        lat: 25.2368244,
                                        lng: 51.45117,
                                      },
                                      html_instructions:
                                        'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                      travel_mode: "DRIVING",
                                      distance: { value: 194, text: "0.2 km" },
                                      polyline: {
                                        points: "ca`yCy``yHpAnDlAdD",
                                      },
                                      end_location: {
                                        lat: 25.2360177,
                                        lng: 51.4494587,
                                      },
                                    },
                                    {
                                      maneuver: "merge",
                                      html_instructions:
                                        "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                      start_location: {
                                        lng: 51.4494587,
                                        lat: 25.2360177,
                                      },
                                      distance: { value: 397, text: "0.4 km" },
                                      duration: { text: "1 min", value: 33 },
                                      end_location: {
                                        lng: 51.4461502,
                                        lat: 25.2340694,
                                      },
                                      polyline: {
                                        points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                      },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      end_location: {
                                        lng: 51.4456607,
                                        lat: 25.2337942,
                                      },
                                      html_instructions:
                                        "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                      maneuver: "straight",
                                      polyline: { points: "}o_yCma_yHP`@d@~@" },
                                      distance: { text: "58 m", value: 58 },
                                      duration: { value: 8, text: "1 min" },
                                      travel_mode: "DRIVING",
                                      start_location: {
                                        lat: 25.2340694,
                                        lng: 51.4461502,
                                      },
                                    },
                                    {
                                      distance: { text: "1.5 km", value: 1451 },
                                      maneuver: "straight",
                                      start_location: {
                                        lat: 25.2337942,
                                        lng: 51.4456607,
                                      },
                                      end_location: {
                                        lat: 25.2267513,
                                        lng: 51.43351759999999,
                                      },
                                      travel_mode: "DRIVING",
                                      duration: { text: "2 mins", value: 90 },
                                      polyline: {
                                        points:
                                          "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                      },
                                      html_instructions:
                                        'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                                    },
                                    {
                                      html_instructions:
                                        "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                      },
                                      start_location: {
                                        lat: 25.2267513,
                                        lng: 51.43351759999999,
                                      },
                                      maneuver: "turn-slight-left",
                                      distance: { value: 92, text: "92 m" },
                                      end_location: {
                                        lat: 25.2262036,
                                        lng: 51.4328437,
                                      },
                                      duration: { text: "1 min", value: 10 },
                                    },
                                    {
                                      polyline: {
                                        points: "w~}xCgn|xHLRNNXZFDVTD@",
                                      },
                                      end_location: {
                                        lat: 25.2257335,
                                        lng: 51.4323655,
                                      },
                                      start_location: {
                                        lng: 51.4328437,
                                        lat: 25.2262036,
                                      },
                                      html_instructions: "Continue straight",
                                      duration: { value: 19, text: "1 min" },
                                      maneuver: "straight",
                                      distance: { value: 71, text: "71 m" },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      distance: { value: 813, text: "0.8 km" },
                                      travel_mode: "DRIVING",
                                      duration: { value: 72, text: "1 min" },
                                      end_location: {
                                        lng: 51.4357808,
                                        lat: 25.2196178,
                                      },
                                      html_instructions:
                                        "Continue onto <b>E Industrial Rd</b>",
                                      start_location: {
                                        lat: 25.2257335,
                                        lng: 51.4323655,
                                      },
                                      polyline: {
                                        points:
                                          "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                      },
                                    },
                                    {
                                      duration: { text: "1 min", value: 27 },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Street 2</b>",
                                      distance: { value: 263, text: "0.3 km" },
                                      end_location: {
                                        lng: 51.43371639999999,
                                        lat: 25.2182661,
                                      },
                                      start_location: {
                                        lng: 51.4357808,
                                        lat: 25.2196178,
                                      },
                                      polyline: {
                                        points:
                                          "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                      },
                                      maneuver: "turn-right",
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      distance: { value: 682, text: "0.7 km" },
                                      start_location: {
                                        lat: 25.2182661,
                                        lng: 51.43371639999999,
                                      },
                                      end_location: {
                                        lng: 51.4366206,
                                        lat: 25.2135618,
                                      },
                                      duration: { value: 78, text: "1 min" },
                                      polyline: {
                                        points:
                                          "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                      },
                                      html_instructions:
                                        "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                      maneuver: "roundabout-right",
                                    },
                                    {
                                      maneuver: "turn-right",
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Street 8</b>",
                                      polyline: {
                                        points: "wo{xC{e}xHLDJBHFHF",
                                      },
                                      end_location: {
                                        lat: 25.2133289,
                                        lng: 51.4364944,
                                      },
                                      travel_mode: "DRIVING",
                                      duration: { text: "1 min", value: 12 },
                                      start_location: {
                                        lng: 51.4366206,
                                        lat: 25.2135618,
                                      },
                                      distance: { value: 29, text: "29 m" },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      duration: { value: 8, text: "1 min" },
                                      start_location: {
                                        lat: 25.2133289,
                                        lng: 51.4364944,
                                      },
                                      polyline: { points: "in{xCae}xHXO" },
                                      distance: { text: "16 m", value: 16 },
                                      html_instructions: "Turn <b>left</b>",
                                      maneuver: "turn-left",
                                      end_location: {
                                        lng: 51.43657289999999,
                                        lat: 25.2132031,
                                      },
                                    },
                                  ],
                                  duration: { value: 795, text: "13 mins" },
                                  distance: { value: 11216, text: "11.2 km" },
                                  end_address: "28 Street 8, Ar Rayyan, Qatar",
                                },
                              ],
                            },
                          ],
                        },
                        completedAt: 1707942351003,
                        createdAt: 1707942285310,
                        owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                        destination: {
                          coordinates: {
                            longitude: 51.43657469999999,
                            latitude: 25.213204,
                          },
                          address: "Street 8, Doha, Qatar",
                          type: "corporate-branch",
                          id: "9VZwCrYZfl03ob5FrD4F.0",
                          geohash: "thkx6myvx1",
                        },
                        co2Amount: 12.612562888268984,
                        origin: {
                          coordinates: {
                            longitude: 51.489361,
                            latitude: 25.282848,
                          },
                          geohash: "thkxgdt7m0",
                          address: "858, الدوحة، Qatar",
                        },
                        car: {
                          year: 2021,
                          type: "carbon-emitting",
                          model: "LAND CRUSER WAGON 4WD",
                          make: "Toyota",
                          co2_p_km: 0.3379843740994449,
                          seat: 3,
                        },
                        rules: [
                          "No smoking",
                          "No eating or drinking",
                          "Keep off the AC",
                        ],
                        preferences: {
                          female_passengers_only: null,
                          verified_passengers_only: null,
                        },
                        skin: null,
                        status: "completed",
                        passengersArray: [
                          {
                            credits: 27.8,
                            username: "abcd",
                            corporate: "9VZwCrYZfl03ob5FrD4F",
                            seat: 0,
                            id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                            name: "Jassim",
                            co2Amount: 4.313694566631215,
                            avatar:
                              "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                            location: {
                              geohash: "thkxgdt7m0",
                              coordinates: {
                                latitude: 25.282848,
                                longitude: 51.489361,
                              },
                              address: "858, الدوحة، Qatar",
                            },
                            wallet_id: "spencer_carbon.testnet",
                          },
                          {
                            status: "picked-up",
                            id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                            seat: 2,
                            avatar:
                              "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                            username: "sarah",
                            corporate: "9VZwCrYZfl03ob5FrD4F",
                            wallet_id: "sarah_carbon.testnet",
                            credits: 13,
                            co2Amount: 3.7908327398993733,
                            name: "Faisal",
                            location: {
                              coordinates: {
                                longitude: 51.498513,
                                latitude: 25.268246,
                              },
                              address: "7F9X+C9V, Doha, Qatar",
                              geohash: "thkxezun4q",
                            },
                          },
                          {
                            avatar:
                              "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                            corporate: "9VZwCrYZfl03ob5FrD4F",
                            status: "picked-up",
                            location: {
                              geohash: "thkxu5pfc8",
                              address: "7GP7+6RG, Doha, Qatar",
                              coordinates: {
                                longitude: 51.514615,
                                latitude: 25.285509,
                              },
                            },
                            username: "michaelscott",
                            id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                            credits: 13.9,
                            seat: 1,
                            name: "Ahmed",
                            wallet_id: "sofia_carbon.testnet",
                            co2Amount: 4.508035581738396,
                          },
                        ],
                      },
                      type: "carpool_passenger",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                    },
                    {
                      status: "picked-up",
                      id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                      seat: 2,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                      username: "sarah",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      wallet_id: "sarah_carbon.testnet",
                      credits: 13,
                      co2Amount: 3.7908327398993733,
                      name: "Faisal",
                      location: {
                        coordinates: {
                          longitude: 51.498513,
                          latitude: 25.268246,
                        },
                        address: "7F9X+C9V, Doha, Qatar",
                        geohash: "thkxezun4q",
                      },
                    },
                    {
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      status: "picked-up",
                      location: {
                        geohash: "thkxu5pfc8",
                        address: "7GP7+6RG, Doha, Qatar",
                        coordinates: {
                          longitude: 51.514615,
                          latitude: 25.285509,
                        },
                      },
                      username: "michaelscott",
                      id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                      credits: 13.9,
                      seat: 1,
                      name: "Ahmed",
                      wallet_id: "sofia_carbon.testnet",
                      co2Amount: 4.508035581738396,
                    },
                  ],
                },
                type: "carpool_passenger",
                corporate: "9VZwCrYZfl03ob5FrD4F",
              },
              {
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                status: "picked-up",
                location: {
                  geohash: "thkxu5pfc8",
                  address: "7GP7+6RG, Doha, Qatar",
                  coordinates: { longitude: 51.514615, latitude: 25.285509 },
                },
                username: "michaelscott",
                id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                credits: 13.9,
                seat: 1,
                name: "Ahmed",
                wallet_id: "sofia_carbon.testnet",
                co2Amount: 4.508035581738396,
              },
            ],
          },
          type: "carpool_passenger",
          corporate: "9VZwCrYZfl03ob5FrD4F",
        },
      },
      skin: null,
      status: "completed",
    },
    userId: "user_id",
    company: Companies.Company1,
    team: Teams.TeamB,
    tripDirection: TripDirection.ToWork,
    directions: {
      status: "OK",
      geocoded_waypoints: [
        {
          geocoder_status: "OK",
          types: ["establishment", "moving_company", "point_of_interest"],
          place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
        },
        {
          types: ["establishment", "general_contractor", "point_of_interest"],
          geocoder_status: "OK",
          place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
        },
        {
          place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
          geocoder_status: "OK",
          types: ["premise"],
        },
        {
          types: ["street_address"],
          geocoder_status: "OK",
          place_id:
            "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
        },
      ],
      routes: [
        {
          overview_polyline: {
            points:
              "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
          },
          waypoint_order: [0, 1],
          bounds: {
            northeast: { lat: 25.291958, lng: 51.518743 },
            southwest: { lat: 25.2132031, lng: 51.4319062 },
          },
          warnings: [],
          copyrights: "Map data ©2024 Google",
          summary: "Al Rayyan Rd",
          legs: [
            {
              distance: { value: 5407, text: "5.4 km" },
              start_address: "858, الدوحة، Qatar",
              via_waypoint: [],
              traffic_speed_entry: [],
              start_location: { lng: 51.4906083, lat: 25.2827107 },
              end_address: "7GP7+6RG, Doha, Qatar",
              steps: [
                {
                  polyline: { points: "}_iyCiwgyHz@B" },
                  distance: { value: 33, text: "33 m" },
                  end_location: { lng: 51.4905936, lat: 25.2824136 },
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                  start_location: { lng: 51.4906083, lat: 25.2827107 },
                  duration: { value: 4, text: "1 min" },
                },
                {
                  end_location: { lat: 25.2801532, lng: 51.4871118 },
                  travel_mode: "DRIVING",
                  distance: { value: 574, text: "0.6 km" },
                  start_location: { lng: 51.4905936, lat: 25.2824136 },
                  html_instructions:
                    "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                  polyline: {
                    points:
                      "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                  },
                  duration: { text: "1 min", value: 49 },
                },
                {
                  start_location: { lng: 51.4871118, lat: 25.2801532 },
                  travel_mode: "DRIVING",
                  distance: { value: 426, text: "0.4 km" },
                  polyline: {
                    points:
                      "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                  },
                  maneuver: "turn-left",
                  end_location: { lat: 25.2801774, lng: 51.49079529999999 },
                  html_instructions: "Turn <b>left</b>",
                  duration: { value: 44, text: "1 min" },
                },
                {
                  travel_mode: "DRIVING",
                  duration: { value: 8, text: "1 min" },
                  maneuver: "straight",
                  html_instructions: "Continue straight onto <b>Al Sadd St</b>",
                  distance: { value: 93, text: "93 m" },
                  end_location: { lng: 51.4917143, lat: 25.2802165 },
                  polyline: { points: "cphyCoxgyHEaBAsA" },
                  start_location: { lat: 25.2801774, lng: 51.49079529999999 },
                },
                {
                  end_location: { lat: 25.2804496, lng: 51.4940386 },
                  duration: { text: "1 min", value: 71 },
                  travel_mode: "DRIVING",
                  polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                  html_instructions: "Slight <b>left</b>",
                  distance: { text: "0.2 km", value: 236 },
                  maneuver: "turn-slight-left",
                  start_location: { lng: 51.4917143, lat: 25.2802165 },
                },
                {
                  polyline: { points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O" },
                  duration: { text: "1 min", value: 38 },
                  end_location: { lng: 51.4947254, lat: 25.281604 },
                  html_instructions:
                    'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                  travel_mode: "DRIVING",
                  maneuver: "turn-left",
                  distance: { text: "0.2 km", value: 163 },
                  start_location: { lat: 25.2804496, lng: 51.4940386 },
                },
                {
                  duration: { value: 76, text: "1 min" },
                  travel_mode: "DRIVING",
                  start_location: { lng: 51.4947254, lat: 25.281604 },
                  html_instructions:
                    "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                  polyline: {
                    points:
                      "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                  },
                  maneuver: "keep-left",
                  distance: { text: "1.1 km", value: 1109 },
                  end_location: { lat: 25.2914195, lng: 51.4965617 },
                },
                {
                  duration: { text: "3 mins", value: 209 },
                  travel_mode: "DRIVING",
                  start_location: { lat: 25.2914195, lng: 51.4965617 },
                  end_location: { lat: 25.2893902, lng: 51.51516729999999 },
                  distance: { value: 1922, text: "1.9 km" },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                  maneuver: "turn-right",
                  polyline: {
                    points:
                      "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                  },
                },
                {
                  distance: { text: "0.1 km", value: 144 },
                  maneuver: "turn-right",
                  duration: { value: 15, text: "1 min" },
                  start_location: { lng: 51.51516729999999, lat: 25.2893902 },
                  travel_mode: "DRIVING",
                  polyline: {
                    points: "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                  },
                  html_instructions: "Turn <b>right</b> onto <b>Al Khaleej</b>",
                  end_location: { lng: 51.51591370000001, lat: 25.2884741 },
                },
                {
                  travel_mode: "DRIVING",
                  start_location: { lat: 25.2884741, lng: 51.51591370000001 },
                  duration: { text: "1 min", value: 11 },
                  polyline: { points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E" },
                  distance: { value: 122, text: "0.1 km" },
                  html_instructions:
                    'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                  end_location: { lat: 25.2874383, lng: 51.516228 },
                },
                {
                  duration: { text: "1 min", value: 21 },
                  end_location: { lng: 51.51678399999999, lat: 25.2849434 },
                  start_location: { lng: 51.516228, lat: 25.2874383 },
                  polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                  html_instructions:
                    'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                  travel_mode: "DRIVING",
                  maneuver: "turn-slight-right",
                  distance: { value: 283, text: "0.3 km" },
                },
                {
                  start_location: { lng: 51.51678399999999, lat: 25.2849434 },
                  duration: { value: 32, text: "1 min" },
                  travel_mode: "DRIVING",
                  end_location: { lng: 51.5155496, lat: 25.2848165 },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                  distance: { value: 125, text: "0.1 km" },
                  polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                  maneuver: "turn-right",
                },
                {
                  end_location: { lat: 25.2850716, lng: 51.5150352 },
                  maneuver: "turn-right",
                  duration: { text: "1 min", value: 21 },
                  distance: { value: 74, text: "74 m" },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                  travel_mode: "DRIVING",
                  polyline: { points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X" },
                  start_location: { lat: 25.2848165, lng: 51.5155496 },
                },
                {
                  html_instructions:
                    "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                  start_location: { lat: 25.2850716, lng: 51.5150352 },
                  end_location: { lat: 25.2856417, lng: 51.51497639999999 },
                  polyline: { points: "uniyC_plyHqBJ" },
                  duration: { text: "1 min", value: 23 },
                  maneuver: "turn-right",
                  travel_mode: "DRIVING",
                  distance: { value: 64, text: "64 m" },
                },
                {
                  polyline: { points: "griyCsolyH?p@DZ" },
                  duration: { text: "1 min", value: 14 },
                  distance: { text: "39 m", value: 39 },
                  end_location: { lng: 51.5145907, lat: 25.2856124 },
                  start_location: { lat: 25.2856417, lng: 51.51497639999999 },
                  travel_mode: "DRIVING",
                  html_instructions:
                    'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                  maneuver: "turn-left",
                },
              ],
              duration: { text: "11 mins", value: 636 },
              end_location: { lng: 51.5145907, lat: 25.2856124 },
            },
            {
              traffic_speed_entry: [],
              duration: { text: "10 mins", value: 598 },
              start_location: { lng: 51.5145907, lat: 25.2856124 },
              distance: { text: "4.5 km", value: 4489 },
              steps: [
                {
                  start_location: { lng: 51.5145907, lat: 25.2856124 },
                  end_location: { lat: 25.2859334, lng: 51.5165533 },
                  duration: { text: "1 min", value: 73 },
                  distance: { value: 201, text: "0.2 km" },
                  travel_mode: "DRIVING",
                  polyline: { points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q" },
                  html_instructions:
                    "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                },
                {
                  travel_mode: "DRIVING",
                  polyline: {
                    points:
                      "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                  },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                  end_location: { lng: 51.518743, lat: 25.2796568 },
                  distance: { text: "0.7 km", value: 735 },
                  duration: { value: 78, text: "1 min" },
                  start_location: { lng: 51.5165533, lat: 25.2859334 },
                  maneuver: "turn-right",
                },
                {
                  html_instructions:
                    "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                  end_location: { lat: 25.2769643, lng: 51.5164061 },
                  polyline: {
                    points:
                      "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                  },
                  start_location: { lat: 25.2796568, lng: 51.518743 },
                  distance: { value: 393, text: "0.4 km" },
                  travel_mode: "DRIVING",
                  duration: { value: 59, text: "1 min" },
                  maneuver: "turn-slight-right",
                },
                {
                  end_location: { lng: 51.500835, lat: 25.2663847 },
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                  start_location: { lng: 51.5164061, lat: 25.2769643 },
                  maneuver: "merge",
                  distance: { value: 1963, text: "2.0 km" },
                  polyline: {
                    points:
                      "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                  },
                  duration: { text: "4 mins", value: 229 },
                },
                {
                  html_instructions:
                    "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                  distance: { value: 428, text: "0.4 km" },
                  duration: { text: "1 min", value: 40 },
                  start_location: { lng: 51.500835, lat: 25.2663847 },
                  end_location: { lat: 25.2643805, lng: 51.497229 },
                  maneuver: "turn-slight-right",
                  travel_mode: "DRIVING",
                  polyline: {
                    points:
                      "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                  },
                },
                {
                  start_location: { lat: 25.2643805, lng: 51.497229 },
                  maneuver: "roundabout-right",
                  polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                  html_instructions:
                    "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                  end_location: { lat: 25.2656775, lng: 51.4962302 },
                  distance: { value: 182, text: "0.2 km" },
                  travel_mode: "DRIVING",
                  duration: { text: "1 min", value: 22 },
                },
                {
                  maneuver: "turn-slight-right",
                  distance: { value: 25, text: "25 m" },
                  polyline: { points: "oueyCmzhyH]DKB" },
                  duration: { text: "1 min", value: 3 },
                  html_instructions:
                    "Slight <b>right</b> onto <b>Al Nasr St</b>",
                  end_location: { lng: 51.4961765, lat: 25.2658942 },
                  start_location: { lat: 25.2656775, lng: 51.4962302 },
                  travel_mode: "DRIVING",
                },
                {
                  distance: { text: "0.4 km", value: 389 },
                  html_instructions:
                    "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                  maneuver: "turn-slight-right",
                  travel_mode: "DRIVING",
                  duration: { value: 53, text: "1 min" },
                  polyline: {
                    points: "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                  },
                  end_location: { lat: 25.267547, lng: 51.4995317 },
                  start_location: { lng: 51.4961765, lat: 25.2658942 },
                },
                {
                  travel_mode: "DRIVING",
                  polyline: { points: "eafyCaoiyHQFiDdA" },
                  distance: { text: "0.1 km", value: 111 },
                  start_location: { lat: 25.267547, lng: 51.4995317 },
                  html_instructions: "Turn <b>left</b> onto <b>Mohammed St</b>",
                  duration: { value: 22, text: "1 min" },
                  maneuver: "turn-left",
                  end_location: { lat: 25.2684856, lng: 51.499144 },
                },
                {
                  maneuver: "turn-left",
                  start_location: { lat: 25.2684856, lng: 51.499144 },
                  html_instructions:
                    'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                  end_location: { lng: 51.49855549999999, lat: 25.2683307 },
                  travel_mode: "DRIVING",
                  duration: { text: "1 min", value: 19 },
                  distance: { value: 62, text: "62 m" },
                  polyline: { points: "agfyCsliyH\\vABNAJ" },
                },
              ],
              start_address: "7GP7+6RG, Doha, Qatar",
              end_location: { lat: 25.2683307, lng: 51.49855549999999 },
              via_waypoint: [],
              end_address: "7F9X+C9V, Doha, Qatar",
            },
            {
              traffic_speed_entry: [],
              start_address: "7F9X+C9V, Doha, Qatar",
              start_location: { lng: 51.49855549999999, lat: 25.2683307 },
              via_waypoint: [],
              end_location: { lng: 51.43657289999999, lat: 25.2132031 },
              steps: [
                {
                  polyline: { points: "affyC_iiyH@KCO]wA" },
                  duration: { value: 14, text: "1 min" },
                  end_location: { lng: 51.499144, lat: 25.2684856 },
                  html_instructions:
                    "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                  start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                  distance: { value: 62, text: "62 m" },
                  travel_mode: "DRIVING",
                },
                {
                  html_instructions:
                    "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                  start_location: { lat: 25.2684856, lng: 51.499144 },
                  end_location: { lng: 51.499066, lat: 25.2687298 },
                  polyline: { points: "agfyCsliyHo@L" },
                  travel_mode: "DRIVING",
                  duration: { text: "1 min", value: 9 },
                  distance: { text: "28 m", value: 28 },
                  maneuver: "turn-left",
                },
                {
                  start_location: { lat: 25.2687298, lng: 51.499066 },
                  travel_mode: "DRIVING",
                  distance: { value: 154, text: "0.2 km" },
                  maneuver: "turn-right",
                  duration: { text: "1 min", value: 32 },
                  end_location: { lat: 25.2692232, lng: 51.500496 },
                  html_instructions: "Turn <b>right</b> onto <b>Al Baraha</b>",
                  polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                },
                {
                  distance: { value: 69, text: "69 m" },
                  end_location: { lat: 25.269806, lng: 51.500265 },
                  start_location: { lng: 51.500496, lat: 25.2692232 },
                  polyline: { points: "skfyCcuiyH{@Ty@X" },
                  duration: { text: "1 min", value: 13 },
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                  maneuver: "turn-left",
                },
                {
                  end_location: { lat: 25.2705523, lng: 51.50232339999999 },
                  maneuver: "turn-right",
                  html_instructions:
                    "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                  start_location: { lng: 51.500265, lat: 25.269806 },
                  polyline: { points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA" },
                  duration: { value: 29, text: "1 min" },
                  distance: { text: "0.2 km", value: 223 },
                  travel_mode: "DRIVING",
                },
                {
                  end_location: { lat: 25.2679045, lng: 51.5033973 },
                  travel_mode: "DRIVING",
                  maneuver: "roundabout-right",
                  start_location: { lat: 25.2705523, lng: 51.50232339999999 },
                  duration: { value: 48, text: "1 min" },
                  polyline: {
                    points:
                      "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                  },
                  html_instructions:
                    "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                  distance: { value: 326, text: "0.3 km" },
                },
                {
                  html_instructions: "Merge onto <b>Salwa Rd</b>",
                  end_location: { lat: 25.2368244, lng: 51.45117 },
                  travel_mode: "DRIVING",
                  distance: { value: 6288, text: "6.3 km" },
                  start_location: { lng: 51.5033973, lat: 25.2679045 },
                  duration: { value: 283, text: "5 mins" },
                  polyline: {
                    points:
                      "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                  },
                  maneuver: "merge",
                },
                {
                  duration: { value: 10, text: "1 min" },
                  maneuver: "ramp-right",
                  start_location: { lat: 25.2368244, lng: 51.45117 },
                  html_instructions:
                    'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                  travel_mode: "DRIVING",
                  distance: { value: 194, text: "0.2 km" },
                  polyline: { points: "ca`yCy``yHpAnDlAdD" },
                  end_location: { lat: 25.2360177, lng: 51.4494587 },
                },
                {
                  maneuver: "merge",
                  html_instructions:
                    "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                  start_location: { lng: 51.4494587, lat: 25.2360177 },
                  distance: { value: 397, text: "0.4 km" },
                  duration: { text: "1 min", value: 33 },
                  end_location: { lng: 51.4461502, lat: 25.2340694 },
                  polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                  travel_mode: "DRIVING",
                },
                {
                  end_location: { lng: 51.4456607, lat: 25.2337942 },
                  html_instructions:
                    "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                  maneuver: "straight",
                  polyline: { points: "}o_yCma_yHP`@d@~@" },
                  distance: { text: "58 m", value: 58 },
                  duration: { value: 8, text: "1 min" },
                  travel_mode: "DRIVING",
                  start_location: { lat: 25.2340694, lng: 51.4461502 },
                },
                {
                  distance: { text: "1.5 km", value: 1451 },
                  maneuver: "straight",
                  start_location: { lat: 25.2337942, lng: 51.4456607 },
                  end_location: { lat: 25.2267513, lng: 51.43351759999999 },
                  travel_mode: "DRIVING",
                  duration: { text: "2 mins", value: 90 },
                  polyline: {
                    points:
                      "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                  },
                  html_instructions:
                    'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                },
                {
                  html_instructions:
                    "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                  travel_mode: "DRIVING",
                  polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                  start_location: { lat: 25.2267513, lng: 51.43351759999999 },
                  maneuver: "turn-slight-left",
                  distance: { value: 92, text: "92 m" },
                  end_location: { lat: 25.2262036, lng: 51.4328437 },
                  duration: { text: "1 min", value: 10 },
                },
                {
                  polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                  end_location: { lat: 25.2257335, lng: 51.4323655 },
                  start_location: { lng: 51.4328437, lat: 25.2262036 },
                  html_instructions: "Continue straight",
                  duration: { value: 19, text: "1 min" },
                  maneuver: "straight",
                  distance: { value: 71, text: "71 m" },
                  travel_mode: "DRIVING",
                },
                {
                  distance: { value: 813, text: "0.8 km" },
                  travel_mode: "DRIVING",
                  duration: { value: 72, text: "1 min" },
                  end_location: { lng: 51.4357808, lat: 25.2196178 },
                  html_instructions: "Continue onto <b>E Industrial Rd</b>",
                  start_location: { lat: 25.2257335, lng: 51.4323655 },
                  polyline: {
                    points:
                      "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                  },
                },
                {
                  duration: { text: "1 min", value: 27 },
                  html_instructions: "Turn <b>right</b> onto <b>Street 2</b>",
                  distance: { value: 263, text: "0.3 km" },
                  end_location: { lng: 51.43371639999999, lat: 25.2182661 },
                  start_location: { lng: 51.4357808, lat: 25.2196178 },
                  polyline: { points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA" },
                  maneuver: "turn-right",
                  travel_mode: "DRIVING",
                },
                {
                  travel_mode: "DRIVING",
                  distance: { value: 682, text: "0.7 km" },
                  start_location: { lat: 25.2182661, lng: 51.43371639999999 },
                  end_location: { lng: 51.4366206, lat: 25.2135618 },
                  duration: { value: 78, text: "1 min" },
                  polyline: {
                    points:
                      "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                  },
                  html_instructions:
                    "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                  maneuver: "roundabout-right",
                },
                {
                  maneuver: "turn-right",
                  html_instructions: "Turn <b>right</b> onto <b>Street 8</b>",
                  polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                  end_location: { lat: 25.2133289, lng: 51.4364944 },
                  travel_mode: "DRIVING",
                  duration: { text: "1 min", value: 12 },
                  start_location: { lng: 51.4366206, lat: 25.2135618 },
                  distance: { value: 29, text: "29 m" },
                },
                {
                  travel_mode: "DRIVING",
                  duration: { value: 8, text: "1 min" },
                  start_location: { lat: 25.2133289, lng: 51.4364944 },
                  polyline: { points: "in{xCae}xHXO" },
                  distance: { text: "16 m", value: 16 },
                  html_instructions: "Turn <b>left</b>",
                  maneuver: "turn-left",
                  end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                },
              ],
              duration: { value: 795, text: "13 mins" },
              distance: { value: 11216, text: "11.2 km" },
              end_address: "28 Street 8, Ar Rayyan, Qatar",
            },
          ],
        },
      ],
    },
    passengers: {
      C1GECLApV4W0bVtgeFk4iRpZYRl1: {
        userId: "user_id",
        name: "Jassim",
        username: "abcd",
        location: {
          geohash: "thkxgdt7m0",
          coordinates: { latitude: 25.282848, longitude: 51.489361 },
          address: "858, الدوحة، Qatar",
        },
        credits: 27.8,
        co2Amount: 4.313694566631215,
        avatar:
          "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
        seat: 0,
        id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
        wallet_id: "spencer_carbon.testnet",
        carpool: {
          id: "8292057714690",
          directions: {
            status: "OK",
            geocoded_waypoints: [
              {
                geocoder_status: "OK",
                types: ["establishment", "moving_company", "point_of_interest"],
                place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
              },
              {
                types: [
                  "establishment",
                  "general_contractor",
                  "point_of_interest",
                ],
                geocoder_status: "OK",
                place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
              },
              {
                place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                geocoder_status: "OK",
                types: ["premise"],
              },
              {
                types: ["street_address"],
                geocoder_status: "OK",
                place_id:
                  "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
              },
            ],
            routes: [
              {
                overview_polyline: {
                  points:
                    "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                },
                waypoint_order: [0, 1],
                bounds: {
                  northeast: { lat: 25.291958, lng: 51.518743 },
                  southwest: { lat: 25.2132031, lng: 51.4319062 },
                },
                warnings: [],
                copyrights: "Map data ©2024 Google",
                summary: "Al Rayyan Rd",
                legs: [
                  {
                    distance: { value: 5407, text: "5.4 km" },
                    start_address: "858, الدوحة، Qatar",
                    via_waypoint: [],
                    traffic_speed_entry: [],
                    start_location: { lng: 51.4906083, lat: 25.2827107 },
                    end_address: "7GP7+6RG, Doha, Qatar",
                    steps: [
                      {
                        polyline: { points: "}_iyCiwgyHz@B" },
                        distance: { value: 33, text: "33 m" },
                        end_location: { lng: 51.4905936, lat: 25.2824136 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                        start_location: { lng: 51.4906083, lat: 25.2827107 },
                        duration: { value: 4, text: "1 min" },
                      },
                      {
                        end_location: { lat: 25.2801532, lng: 51.4871118 },
                        travel_mode: "DRIVING",
                        distance: { value: 574, text: "0.6 km" },
                        start_location: { lng: 51.4905936, lat: 25.2824136 },
                        html_instructions:
                          "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                        polyline: {
                          points:
                            "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                        },
                        duration: { text: "1 min", value: 49 },
                      },
                      {
                        start_location: { lng: 51.4871118, lat: 25.2801532 },
                        travel_mode: "DRIVING",
                        distance: { value: 426, text: "0.4 km" },
                        polyline: {
                          points:
                            "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                        },
                        maneuver: "turn-left",
                        end_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                        html_instructions: "Turn <b>left</b>",
                        duration: { value: 44, text: "1 min" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        maneuver: "straight",
                        html_instructions:
                          "Continue straight onto <b>Al Sadd St</b>",
                        distance: { value: 93, text: "93 m" },
                        end_location: { lng: 51.4917143, lat: 25.2802165 },
                        polyline: { points: "cphyCoxgyHEaBAsA" },
                        start_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                      },
                      {
                        end_location: { lat: 25.2804496, lng: 51.4940386 },
                        duration: { text: "1 min", value: 71 },
                        travel_mode: "DRIVING",
                        polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                        html_instructions: "Slight <b>left</b>",
                        distance: { text: "0.2 km", value: 236 },
                        maneuver: "turn-slight-left",
                        start_location: { lng: 51.4917143, lat: 25.2802165 },
                      },
                      {
                        polyline: {
                          points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                        },
                        duration: { text: "1 min", value: 38 },
                        end_location: { lng: 51.4947254, lat: 25.281604 },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        distance: { text: "0.2 km", value: 163 },
                        start_location: { lat: 25.2804496, lng: 51.4940386 },
                      },
                      {
                        duration: { value: 76, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.4947254, lat: 25.281604 },
                        html_instructions:
                          "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                        polyline: {
                          points:
                            "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                        },
                        maneuver: "keep-left",
                        distance: { text: "1.1 km", value: 1109 },
                        end_location: { lat: 25.2914195, lng: 51.4965617 },
                      },
                      {
                        duration: { text: "3 mins", value: 209 },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2914195, lng: 51.4965617 },
                        end_location: {
                          lat: 25.2893902,
                          lng: 51.51516729999999,
                        },
                        distance: { value: 1922, text: "1.9 km" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                        },
                      },
                      {
                        distance: { text: "0.1 km", value: 144 },
                        maneuver: "turn-right",
                        duration: { value: 15, text: "1 min" },
                        start_location: {
                          lng: 51.51516729999999,
                          lat: 25.2893902,
                        },
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej</b>",
                        end_location: {
                          lng: 51.51591370000001,
                          lat: 25.2884741,
                        },
                      },
                      {
                        travel_mode: "DRIVING",
                        start_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        duration: { text: "1 min", value: 11 },
                        polyline: {
                          points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                        },
                        distance: { value: 122, text: "0.1 km" },
                        html_instructions:
                          'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                        end_location: { lat: 25.2874383, lng: 51.516228 },
                      },
                      {
                        duration: { text: "1 min", value: 21 },
                        end_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        start_location: { lng: 51.516228, lat: 25.2874383 },
                        polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                        html_instructions:
                          'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                        travel_mode: "DRIVING",
                        maneuver: "turn-slight-right",
                        distance: { value: 283, text: "0.3 km" },
                      },
                      {
                        start_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        duration: { value: 32, text: "1 min" },
                        travel_mode: "DRIVING",
                        end_location: { lng: 51.5155496, lat: 25.2848165 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                        distance: { value: 125, text: "0.1 km" },
                        polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                        maneuver: "turn-right",
                      },
                      {
                        end_location: { lat: 25.2850716, lng: 51.5150352 },
                        maneuver: "turn-right",
                        duration: { text: "1 min", value: 21 },
                        distance: { value: 74, text: "74 m" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                        },
                        start_location: { lat: 25.2848165, lng: 51.5155496 },
                      },
                      {
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        start_location: { lat: 25.2850716, lng: 51.5150352 },
                        end_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        polyline: { points: "uniyC_plyHqBJ" },
                        duration: { text: "1 min", value: 23 },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        distance: { value: 64, text: "64 m" },
                      },
                      {
                        polyline: { points: "griyCsolyH?p@DZ" },
                        duration: { text: "1 min", value: 14 },
                        distance: { text: "39 m", value: 39 },
                        end_location: { lng: 51.5145907, lat: 25.2856124 },
                        start_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        travel_mode: "DRIVING",
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        maneuver: "turn-left",
                      },
                    ],
                    duration: { text: "11 mins", value: 636 },
                    end_location: { lng: 51.5145907, lat: 25.2856124 },
                  },
                  {
                    traffic_speed_entry: [],
                    duration: { text: "10 mins", value: 598 },
                    start_location: { lng: 51.5145907, lat: 25.2856124 },
                    distance: { text: "4.5 km", value: 4489 },
                    steps: [
                      {
                        start_location: { lng: 51.5145907, lat: 25.2856124 },
                        end_location: { lat: 25.2859334, lng: 51.5165533 },
                        duration: { text: "1 min", value: 73 },
                        distance: { value: 201, text: "0.2 km" },
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                        },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                      },
                      {
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                        end_location: { lng: 51.518743, lat: 25.2796568 },
                        distance: { text: "0.7 km", value: 735 },
                        duration: { value: 78, text: "1 min" },
                        start_location: { lng: 51.5165533, lat: 25.2859334 },
                        maneuver: "turn-right",
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                        end_location: { lat: 25.2769643, lng: 51.5164061 },
                        polyline: {
                          points:
                            "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                        },
                        start_location: { lat: 25.2796568, lng: 51.518743 },
                        distance: { value: 393, text: "0.4 km" },
                        travel_mode: "DRIVING",
                        duration: { value: 59, text: "1 min" },
                        maneuver: "turn-slight-right",
                      },
                      {
                        end_location: { lng: 51.500835, lat: 25.2663847 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                        start_location: { lng: 51.5164061, lat: 25.2769643 },
                        maneuver: "merge",
                        distance: { value: 1963, text: "2.0 km" },
                        polyline: {
                          points:
                            "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                        },
                        duration: { text: "4 mins", value: 229 },
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                        distance: { value: 428, text: "0.4 km" },
                        duration: { text: "1 min", value: 40 },
                        start_location: { lng: 51.500835, lat: 25.2663847 },
                        end_location: { lat: 25.2643805, lng: 51.497229 },
                        maneuver: "turn-slight-right",
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                        },
                      },
                      {
                        start_location: { lat: 25.2643805, lng: 51.497229 },
                        maneuver: "roundabout-right",
                        polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                        end_location: { lat: 25.2656775, lng: 51.4962302 },
                        distance: { value: 182, text: "0.2 km" },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 22 },
                      },
                      {
                        maneuver: "turn-slight-right",
                        distance: { value: 25, text: "25 m" },
                        polyline: { points: "oueyCmzhyH]DKB" },
                        duration: { text: "1 min", value: 3 },
                        html_instructions:
                          "Slight <b>right</b> onto <b>Al Nasr St</b>",
                        end_location: { lng: 51.4961765, lat: 25.2658942 },
                        start_location: { lat: 25.2656775, lng: 51.4962302 },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { text: "0.4 km", value: 389 },
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                        maneuver: "turn-slight-right",
                        travel_mode: "DRIVING",
                        duration: { value: 53, text: "1 min" },
                        polyline: {
                          points:
                            "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                        },
                        end_location: { lat: 25.267547, lng: 51.4995317 },
                        start_location: { lng: 51.4961765, lat: 25.2658942 },
                      },
                      {
                        travel_mode: "DRIVING",
                        polyline: { points: "eafyCaoiyHQFiDdA" },
                        distance: { text: "0.1 km", value: 111 },
                        start_location: { lat: 25.267547, lng: 51.4995317 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohammed St</b>",
                        duration: { value: 22, text: "1 min" },
                        maneuver: "turn-left",
                        end_location: { lat: 25.2684856, lng: 51.499144 },
                      },
                      {
                        maneuver: "turn-left",
                        start_location: { lat: 25.2684856, lng: 51.499144 },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        end_location: {
                          lng: 51.49855549999999,
                          lat: 25.2683307,
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 19 },
                        distance: { value: 62, text: "62 m" },
                        polyline: { points: "agfyCsliyH\\vABNAJ" },
                      },
                    ],
                    start_address: "7GP7+6RG, Doha, Qatar",
                    end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                    via_waypoint: [],
                    end_address: "7F9X+C9V, Doha, Qatar",
                  },
                  {
                    traffic_speed_entry: [],
                    start_address: "7F9X+C9V, Doha, Qatar",
                    start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                    via_waypoint: [],
                    end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                    steps: [
                      {
                        polyline: { points: "affyC_iiyH@KCO]wA" },
                        duration: { value: 14, text: "1 min" },
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                        start_location: {
                          lng: 51.49855549999999,
                          lat: 25.2683307,
                        },
                        distance: { value: 62, text: "62 m" },
                        travel_mode: "DRIVING",
                      },
                      {
                        html_instructions:
                          "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                        start_location: { lat: 25.2684856, lng: 51.499144 },
                        end_location: { lng: 51.499066, lat: 25.2687298 },
                        polyline: { points: "agfyCsliyHo@L" },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 9 },
                        distance: { text: "28 m", value: 28 },
                        maneuver: "turn-left",
                      },
                      {
                        start_location: { lat: 25.2687298, lng: 51.499066 },
                        travel_mode: "DRIVING",
                        distance: { value: 154, text: "0.2 km" },
                        maneuver: "turn-right",
                        duration: { text: "1 min", value: 32 },
                        end_location: { lat: 25.2692232, lng: 51.500496 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Baraha</b>",
                        polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                      },
                      {
                        distance: { value: 69, text: "69 m" },
                        end_location: { lat: 25.269806, lng: 51.500265 },
                        start_location: { lng: 51.500496, lat: 25.2692232 },
                        polyline: { points: "skfyCcuiyH{@Ty@X" },
                        duration: { text: "1 min", value: 13 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                        maneuver: "turn-left",
                      },
                      {
                        end_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        maneuver: "turn-right",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                        start_location: { lng: 51.500265, lat: 25.269806 },
                        polyline: {
                          points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                        },
                        duration: { value: 29, text: "1 min" },
                        distance: { text: "0.2 km", value: 223 },
                        travel_mode: "DRIVING",
                      },
                      {
                        end_location: { lat: 25.2679045, lng: 51.5033973 },
                        travel_mode: "DRIVING",
                        maneuver: "roundabout-right",
                        start_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        duration: { value: 48, text: "1 min" },
                        polyline: {
                          points:
                            "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                        },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                        distance: { value: 326, text: "0.3 km" },
                      },
                      {
                        html_instructions: "Merge onto <b>Salwa Rd</b>",
                        end_location: { lat: 25.2368244, lng: 51.45117 },
                        travel_mode: "DRIVING",
                        distance: { value: 6288, text: "6.3 km" },
                        start_location: { lng: 51.5033973, lat: 25.2679045 },
                        duration: { value: 283, text: "5 mins" },
                        polyline: {
                          points:
                            "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                        },
                        maneuver: "merge",
                      },
                      {
                        duration: { value: 10, text: "1 min" },
                        maneuver: "ramp-right",
                        start_location: { lat: 25.2368244, lng: 51.45117 },
                        html_instructions:
                          'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                        travel_mode: "DRIVING",
                        distance: { value: 194, text: "0.2 km" },
                        polyline: { points: "ca`yCy``yHpAnDlAdD" },
                        end_location: { lat: 25.2360177, lng: 51.4494587 },
                      },
                      {
                        maneuver: "merge",
                        html_instructions:
                          "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        start_location: { lng: 51.4494587, lat: 25.2360177 },
                        distance: { value: 397, text: "0.4 km" },
                        duration: { text: "1 min", value: 33 },
                        end_location: { lng: 51.4461502, lat: 25.2340694 },
                        polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                        travel_mode: "DRIVING",
                      },
                      {
                        end_location: { lng: 51.4456607, lat: 25.2337942 },
                        html_instructions:
                          "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        maneuver: "straight",
                        polyline: { points: "}o_yCma_yHP`@d@~@" },
                        distance: { text: "58 m", value: 58 },
                        duration: { value: 8, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2340694, lng: 51.4461502 },
                      },
                      {
                        distance: { text: "1.5 km", value: 1451 },
                        maneuver: "straight",
                        start_location: { lat: 25.2337942, lng: 51.4456607 },
                        end_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "2 mins", value: 90 },
                        polyline: {
                          points:
                            "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                        },
                        html_instructions:
                          'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                      },
                      {
                        html_instructions:
                          "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                        travel_mode: "DRIVING",
                        polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                        start_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        maneuver: "turn-slight-left",
                        distance: { value: 92, text: "92 m" },
                        end_location: { lat: 25.2262036, lng: 51.4328437 },
                        duration: { text: "1 min", value: 10 },
                      },
                      {
                        polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                        end_location: { lat: 25.2257335, lng: 51.4323655 },
                        start_location: { lng: 51.4328437, lat: 25.2262036 },
                        html_instructions: "Continue straight",
                        duration: { value: 19, text: "1 min" },
                        maneuver: "straight",
                        distance: { value: 71, text: "71 m" },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { value: 813, text: "0.8 km" },
                        travel_mode: "DRIVING",
                        duration: { value: 72, text: "1 min" },
                        end_location: { lng: 51.4357808, lat: 25.2196178 },
                        html_instructions:
                          "Continue onto <b>E Industrial Rd</b>",
                        start_location: { lat: 25.2257335, lng: 51.4323655 },
                        polyline: {
                          points:
                            "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                        },
                      },
                      {
                        duration: { text: "1 min", value: 27 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 2</b>",
                        distance: { value: 263, text: "0.3 km" },
                        end_location: {
                          lng: 51.43371639999999,
                          lat: 25.2182661,
                        },
                        start_location: { lng: 51.4357808, lat: 25.2196178 },
                        polyline: {
                          points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                        },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { value: 682, text: "0.7 km" },
                        start_location: {
                          lat: 25.2182661,
                          lng: 51.43371639999999,
                        },
                        end_location: { lng: 51.4366206, lat: 25.2135618 },
                        duration: { value: 78, text: "1 min" },
                        polyline: {
                          points:
                            "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                        },
                        html_instructions:
                          "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                        maneuver: "roundabout-right",
                      },
                      {
                        maneuver: "turn-right",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 8</b>",
                        polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                        end_location: { lat: 25.2133289, lng: 51.4364944 },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 12 },
                        start_location: { lng: 51.4366206, lat: 25.2135618 },
                        distance: { value: 29, text: "29 m" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        start_location: { lat: 25.2133289, lng: 51.4364944 },
                        polyline: { points: "in{xCae}xHXO" },
                        distance: { text: "16 m", value: 16 },
                        html_instructions: "Turn <b>left</b>",
                        maneuver: "turn-left",
                        end_location: {
                          lng: 51.43657289999999,
                          lat: 25.2132031,
                        },
                      },
                    ],
                    duration: { value: 795, text: "13 mins" },
                    distance: { value: 11216, text: "11.2 km" },
                    end_address: "28 Street 8, Ar Rayyan, Qatar",
                  },
                ],
              },
            ],
          },
          completedAt: 1707942351003,
          createdAt: 1707942285310,
          owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
          destination: {
            coordinates: { longitude: 51.43657469999999, latitude: 25.213204 },
            address: "Street 8, Doha, Qatar",
            type: "corporate-branch",
            id: "9VZwCrYZfl03ob5FrD4F.0",
            geohash: "thkx6myvx1",
          },
          co2Amount: 12.612562888268984,
          origin: {
            coordinates: { longitude: 51.489361, latitude: 25.282848 },
            geohash: "thkxgdt7m0",
            address: "858, الدوحة، Qatar",
          },
          car: {
            year: 2021,
            type: "carbon-emitting",
            model: "LAND CRUSER WAGON 4WD",
            make: "Toyota",
            co2_p_km: 0.3379843740994449,
            seat: 3,
          },
          rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
          preferences: {
            female_passengers_only: null,
            verified_passengers_only: null,
          },
          skin: null,
          status: "completed",
          passengersArray: [
            {
              credits: 27.8,
              username: "abcd",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              seat: 0,
              id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
              name: "Jassim",
              co2Amount: 4.313694566631215,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
              location: {
                geohash: "thkxgdt7m0",
                coordinates: { latitude: 25.282848, longitude: 51.489361 },
                address: "858, الدوحة، Qatar",
              },
              wallet_id: "spencer_carbon.testnet",
            },
            {
              status: "picked-up",
              id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
              seat: 2,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
              username: "sarah",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              wallet_id: "sarah_carbon.testnet",
              credits: 13,
              co2Amount: 3.7908327398993733,
              name: "Faisal",
              location: {
                coordinates: { longitude: 51.498513, latitude: 25.268246 },
                address: "7F9X+C9V, Doha, Qatar",
                geohash: "thkxezun4q",
              },
            },
            {
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              status: "picked-up",
              location: {
                geohash: "thkxu5pfc8",
                address: "7GP7+6RG, Doha, Qatar",
                coordinates: { longitude: 51.514615, latitude: 25.285509 },
              },
              username: "michaelscott",
              id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
              credits: 13.9,
              seat: 1,
              name: "Ahmed",
              wallet_id: "sofia_carbon.testnet",
              co2Amount: 4.508035581738396,
            },
          ],
        },
        type: "carpool_passenger",
        corporate: "9VZwCrYZfl03ob5FrD4F",
      },
      "6Qj01PCY1lfLasd74HFgTxpcDRt1": {
        userId: "user_id",
        name: "Faisal",
        username: "sarah",
        location: {
          coordinates: { longitude: 51.498513, latitude: 25.268246 },
          address: "7F9X+C9V, Doha, Qatar",
          geohash: "thkxezun4q",
        },
        credits: 13,
        co2Amount: 3.7908327398993733,
        avatar:
          "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
        seat: 2,
        id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
        wallet_id: "sarah_carbon.testnet",
        status: "picked-up",
        carpool: {
          id: "8292057714690",
          directions: {
            status: "OK",
            geocoded_waypoints: [
              {
                geocoder_status: "OK",
                types: ["establishment", "moving_company", "point_of_interest"],
                place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
              },
              {
                types: [
                  "establishment",
                  "general_contractor",
                  "point_of_interest",
                ],
                geocoder_status: "OK",
                place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
              },
              {
                place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                geocoder_status: "OK",
                types: ["premise"],
              },
              {
                types: ["street_address"],
                geocoder_status: "OK",
                place_id:
                  "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
              },
            ],
            routes: [
              {
                overview_polyline: {
                  points:
                    "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                },
                waypoint_order: [0, 1],
                bounds: {
                  northeast: { lat: 25.291958, lng: 51.518743 },
                  southwest: { lat: 25.2132031, lng: 51.4319062 },
                },
                warnings: [],
                copyrights: "Map data ©2024 Google",
                summary: "Al Rayyan Rd",
                legs: [
                  {
                    distance: { value: 5407, text: "5.4 km" },
                    start_address: "858, الدوحة، Qatar",
                    via_waypoint: [],
                    traffic_speed_entry: [],
                    start_location: { lng: 51.4906083, lat: 25.2827107 },
                    end_address: "7GP7+6RG, Doha, Qatar",
                    steps: [
                      {
                        polyline: { points: "}_iyCiwgyHz@B" },
                        distance: { value: 33, text: "33 m" },
                        end_location: { lng: 51.4905936, lat: 25.2824136 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                        start_location: { lng: 51.4906083, lat: 25.2827107 },
                        duration: { value: 4, text: "1 min" },
                      },
                      {
                        end_location: { lat: 25.2801532, lng: 51.4871118 },
                        travel_mode: "DRIVING",
                        distance: { value: 574, text: "0.6 km" },
                        start_location: { lng: 51.4905936, lat: 25.2824136 },
                        html_instructions:
                          "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                        polyline: {
                          points:
                            "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                        },
                        duration: { text: "1 min", value: 49 },
                      },
                      {
                        start_location: { lng: 51.4871118, lat: 25.2801532 },
                        travel_mode: "DRIVING",
                        distance: { value: 426, text: "0.4 km" },
                        polyline: {
                          points:
                            "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                        },
                        maneuver: "turn-left",
                        end_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                        html_instructions: "Turn <b>left</b>",
                        duration: { value: 44, text: "1 min" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        maneuver: "straight",
                        html_instructions:
                          "Continue straight onto <b>Al Sadd St</b>",
                        distance: { value: 93, text: "93 m" },
                        end_location: { lng: 51.4917143, lat: 25.2802165 },
                        polyline: { points: "cphyCoxgyHEaBAsA" },
                        start_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                      },
                      {
                        end_location: { lat: 25.2804496, lng: 51.4940386 },
                        duration: { text: "1 min", value: 71 },
                        travel_mode: "DRIVING",
                        polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                        html_instructions: "Slight <b>left</b>",
                        distance: { text: "0.2 km", value: 236 },
                        maneuver: "turn-slight-left",
                        start_location: { lng: 51.4917143, lat: 25.2802165 },
                      },
                      {
                        polyline: {
                          points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                        },
                        duration: { text: "1 min", value: 38 },
                        end_location: { lng: 51.4947254, lat: 25.281604 },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        distance: { text: "0.2 km", value: 163 },
                        start_location: { lat: 25.2804496, lng: 51.4940386 },
                      },
                      {
                        duration: { value: 76, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.4947254, lat: 25.281604 },
                        html_instructions:
                          "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                        polyline: {
                          points:
                            "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                        },
                        maneuver: "keep-left",
                        distance: { text: "1.1 km", value: 1109 },
                        end_location: { lat: 25.2914195, lng: 51.4965617 },
                      },
                      {
                        duration: { text: "3 mins", value: 209 },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2914195, lng: 51.4965617 },
                        end_location: {
                          lat: 25.2893902,
                          lng: 51.51516729999999,
                        },
                        distance: { value: 1922, text: "1.9 km" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                        },
                      },
                      {
                        distance: { text: "0.1 km", value: 144 },
                        maneuver: "turn-right",
                        duration: { value: 15, text: "1 min" },
                        start_location: {
                          lng: 51.51516729999999,
                          lat: 25.2893902,
                        },
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej</b>",
                        end_location: {
                          lng: 51.51591370000001,
                          lat: 25.2884741,
                        },
                      },
                      {
                        travel_mode: "DRIVING",
                        start_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        duration: { text: "1 min", value: 11 },
                        polyline: {
                          points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                        },
                        distance: { value: 122, text: "0.1 km" },
                        html_instructions:
                          'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                        end_location: { lat: 25.2874383, lng: 51.516228 },
                      },
                      {
                        duration: { text: "1 min", value: 21 },
                        end_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        start_location: { lng: 51.516228, lat: 25.2874383 },
                        polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                        html_instructions:
                          'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                        travel_mode: "DRIVING",
                        maneuver: "turn-slight-right",
                        distance: { value: 283, text: "0.3 km" },
                      },
                      {
                        start_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        duration: { value: 32, text: "1 min" },
                        travel_mode: "DRIVING",
                        end_location: { lng: 51.5155496, lat: 25.2848165 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                        distance: { value: 125, text: "0.1 km" },
                        polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                        maneuver: "turn-right",
                      },
                      {
                        end_location: { lat: 25.2850716, lng: 51.5150352 },
                        maneuver: "turn-right",
                        duration: { text: "1 min", value: 21 },
                        distance: { value: 74, text: "74 m" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                        },
                        start_location: { lat: 25.2848165, lng: 51.5155496 },
                      },
                      {
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        start_location: { lat: 25.2850716, lng: 51.5150352 },
                        end_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        polyline: { points: "uniyC_plyHqBJ" },
                        duration: { text: "1 min", value: 23 },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        distance: { value: 64, text: "64 m" },
                      },
                      {
                        polyline: { points: "griyCsolyH?p@DZ" },
                        duration: { text: "1 min", value: 14 },
                        distance: { text: "39 m", value: 39 },
                        end_location: { lng: 51.5145907, lat: 25.2856124 },
                        start_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        travel_mode: "DRIVING",
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        maneuver: "turn-left",
                      },
                    ],
                    duration: { text: "11 mins", value: 636 },
                    end_location: { lng: 51.5145907, lat: 25.2856124 },
                  },
                  {
                    traffic_speed_entry: [],
                    duration: { text: "10 mins", value: 598 },
                    start_location: { lng: 51.5145907, lat: 25.2856124 },
                    distance: { text: "4.5 km", value: 4489 },
                    steps: [
                      {
                        start_location: { lng: 51.5145907, lat: 25.2856124 },
                        end_location: { lat: 25.2859334, lng: 51.5165533 },
                        duration: { text: "1 min", value: 73 },
                        distance: { value: 201, text: "0.2 km" },
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                        },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                      },
                      {
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                        end_location: { lng: 51.518743, lat: 25.2796568 },
                        distance: { text: "0.7 km", value: 735 },
                        duration: { value: 78, text: "1 min" },
                        start_location: { lng: 51.5165533, lat: 25.2859334 },
                        maneuver: "turn-right",
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                        end_location: { lat: 25.2769643, lng: 51.5164061 },
                        polyline: {
                          points:
                            "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                        },
                        start_location: { lat: 25.2796568, lng: 51.518743 },
                        distance: { value: 393, text: "0.4 km" },
                        travel_mode: "DRIVING",
                        duration: { value: 59, text: "1 min" },
                        maneuver: "turn-slight-right",
                      },
                      {
                        end_location: { lng: 51.500835, lat: 25.2663847 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                        start_location: { lng: 51.5164061, lat: 25.2769643 },
                        maneuver: "merge",
                        distance: { value: 1963, text: "2.0 km" },
                        polyline: {
                          points:
                            "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                        },
                        duration: { text: "4 mins", value: 229 },
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                        distance: { value: 428, text: "0.4 km" },
                        duration: { text: "1 min", value: 40 },
                        start_location: { lng: 51.500835, lat: 25.2663847 },
                        end_location: { lat: 25.2643805, lng: 51.497229 },
                        maneuver: "turn-slight-right",
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                        },
                      },
                      {
                        start_location: { lat: 25.2643805, lng: 51.497229 },
                        maneuver: "roundabout-right",
                        polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                        end_location: { lat: 25.2656775, lng: 51.4962302 },
                        distance: { value: 182, text: "0.2 km" },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 22 },
                      },
                      {
                        maneuver: "turn-slight-right",
                        distance: { value: 25, text: "25 m" },
                        polyline: { points: "oueyCmzhyH]DKB" },
                        duration: { text: "1 min", value: 3 },
                        html_instructions:
                          "Slight <b>right</b> onto <b>Al Nasr St</b>",
                        end_location: { lng: 51.4961765, lat: 25.2658942 },
                        start_location: { lat: 25.2656775, lng: 51.4962302 },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { text: "0.4 km", value: 389 },
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                        maneuver: "turn-slight-right",
                        travel_mode: "DRIVING",
                        duration: { value: 53, text: "1 min" },
                        polyline: {
                          points:
                            "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                        },
                        end_location: { lat: 25.267547, lng: 51.4995317 },
                        start_location: { lng: 51.4961765, lat: 25.2658942 },
                      },
                      {
                        travel_mode: "DRIVING",
                        polyline: { points: "eafyCaoiyHQFiDdA" },
                        distance: { text: "0.1 km", value: 111 },
                        start_location: { lat: 25.267547, lng: 51.4995317 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohammed St</b>",
                        duration: { value: 22, text: "1 min" },
                        maneuver: "turn-left",
                        end_location: { lat: 25.2684856, lng: 51.499144 },
                      },
                      {
                        maneuver: "turn-left",
                        start_location: { lat: 25.2684856, lng: 51.499144 },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        end_location: {
                          lng: 51.49855549999999,
                          lat: 25.2683307,
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 19 },
                        distance: { value: 62, text: "62 m" },
                        polyline: { points: "agfyCsliyH\\vABNAJ" },
                      },
                    ],
                    start_address: "7GP7+6RG, Doha, Qatar",
                    end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                    via_waypoint: [],
                    end_address: "7F9X+C9V, Doha, Qatar",
                  },
                  {
                    traffic_speed_entry: [],
                    start_address: "7F9X+C9V, Doha, Qatar",
                    start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                    via_waypoint: [],
                    end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                    steps: [
                      {
                        polyline: { points: "affyC_iiyH@KCO]wA" },
                        duration: { value: 14, text: "1 min" },
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                        start_location: {
                          lng: 51.49855549999999,
                          lat: 25.2683307,
                        },
                        distance: { value: 62, text: "62 m" },
                        travel_mode: "DRIVING",
                      },
                      {
                        html_instructions:
                          "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                        start_location: { lat: 25.2684856, lng: 51.499144 },
                        end_location: { lng: 51.499066, lat: 25.2687298 },
                        polyline: { points: "agfyCsliyHo@L" },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 9 },
                        distance: { text: "28 m", value: 28 },
                        maneuver: "turn-left",
                      },
                      {
                        start_location: { lat: 25.2687298, lng: 51.499066 },
                        travel_mode: "DRIVING",
                        distance: { value: 154, text: "0.2 km" },
                        maneuver: "turn-right",
                        duration: { text: "1 min", value: 32 },
                        end_location: { lat: 25.2692232, lng: 51.500496 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Baraha</b>",
                        polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                      },
                      {
                        distance: { value: 69, text: "69 m" },
                        end_location: { lat: 25.269806, lng: 51.500265 },
                        start_location: { lng: 51.500496, lat: 25.2692232 },
                        polyline: { points: "skfyCcuiyH{@Ty@X" },
                        duration: { text: "1 min", value: 13 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                        maneuver: "turn-left",
                      },
                      {
                        end_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        maneuver: "turn-right",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                        start_location: { lng: 51.500265, lat: 25.269806 },
                        polyline: {
                          points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                        },
                        duration: { value: 29, text: "1 min" },
                        distance: { text: "0.2 km", value: 223 },
                        travel_mode: "DRIVING",
                      },
                      {
                        end_location: { lat: 25.2679045, lng: 51.5033973 },
                        travel_mode: "DRIVING",
                        maneuver: "roundabout-right",
                        start_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        duration: { value: 48, text: "1 min" },
                        polyline: {
                          points:
                            "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                        },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                        distance: { value: 326, text: "0.3 km" },
                      },
                      {
                        html_instructions: "Merge onto <b>Salwa Rd</b>",
                        end_location: { lat: 25.2368244, lng: 51.45117 },
                        travel_mode: "DRIVING",
                        distance: { value: 6288, text: "6.3 km" },
                        start_location: { lng: 51.5033973, lat: 25.2679045 },
                        duration: { value: 283, text: "5 mins" },
                        polyline: {
                          points:
                            "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                        },
                        maneuver: "merge",
                      },
                      {
                        duration: { value: 10, text: "1 min" },
                        maneuver: "ramp-right",
                        start_location: { lat: 25.2368244, lng: 51.45117 },
                        html_instructions:
                          'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                        travel_mode: "DRIVING",
                        distance: { value: 194, text: "0.2 km" },
                        polyline: { points: "ca`yCy``yHpAnDlAdD" },
                        end_location: { lat: 25.2360177, lng: 51.4494587 },
                      },
                      {
                        maneuver: "merge",
                        html_instructions:
                          "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        start_location: { lng: 51.4494587, lat: 25.2360177 },
                        distance: { value: 397, text: "0.4 km" },
                        duration: { text: "1 min", value: 33 },
                        end_location: { lng: 51.4461502, lat: 25.2340694 },
                        polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                        travel_mode: "DRIVING",
                      },
                      {
                        end_location: { lng: 51.4456607, lat: 25.2337942 },
                        html_instructions:
                          "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        maneuver: "straight",
                        polyline: { points: "}o_yCma_yHP`@d@~@" },
                        distance: { text: "58 m", value: 58 },
                        duration: { value: 8, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2340694, lng: 51.4461502 },
                      },
                      {
                        distance: { text: "1.5 km", value: 1451 },
                        maneuver: "straight",
                        start_location: { lat: 25.2337942, lng: 51.4456607 },
                        end_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "2 mins", value: 90 },
                        polyline: {
                          points:
                            "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                        },
                        html_instructions:
                          'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                      },
                      {
                        html_instructions:
                          "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                        travel_mode: "DRIVING",
                        polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                        start_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        maneuver: "turn-slight-left",
                        distance: { value: 92, text: "92 m" },
                        end_location: { lat: 25.2262036, lng: 51.4328437 },
                        duration: { text: "1 min", value: 10 },
                      },
                      {
                        polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                        end_location: { lat: 25.2257335, lng: 51.4323655 },
                        start_location: { lng: 51.4328437, lat: 25.2262036 },
                        html_instructions: "Continue straight",
                        duration: { value: 19, text: "1 min" },
                        maneuver: "straight",
                        distance: { value: 71, text: "71 m" },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { value: 813, text: "0.8 km" },
                        travel_mode: "DRIVING",
                        duration: { value: 72, text: "1 min" },
                        end_location: { lng: 51.4357808, lat: 25.2196178 },
                        html_instructions:
                          "Continue onto <b>E Industrial Rd</b>",
                        start_location: { lat: 25.2257335, lng: 51.4323655 },
                        polyline: {
                          points:
                            "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                        },
                      },
                      {
                        duration: { text: "1 min", value: 27 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 2</b>",
                        distance: { value: 263, text: "0.3 km" },
                        end_location: {
                          lng: 51.43371639999999,
                          lat: 25.2182661,
                        },
                        start_location: { lng: 51.4357808, lat: 25.2196178 },
                        polyline: {
                          points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                        },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { value: 682, text: "0.7 km" },
                        start_location: {
                          lat: 25.2182661,
                          lng: 51.43371639999999,
                        },
                        end_location: { lng: 51.4366206, lat: 25.2135618 },
                        duration: { value: 78, text: "1 min" },
                        polyline: {
                          points:
                            "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                        },
                        html_instructions:
                          "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                        maneuver: "roundabout-right",
                      },
                      {
                        maneuver: "turn-right",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 8</b>",
                        polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                        end_location: { lat: 25.2133289, lng: 51.4364944 },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 12 },
                        start_location: { lng: 51.4366206, lat: 25.2135618 },
                        distance: { value: 29, text: "29 m" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        start_location: { lat: 25.2133289, lng: 51.4364944 },
                        polyline: { points: "in{xCae}xHXO" },
                        distance: { text: "16 m", value: 16 },
                        html_instructions: "Turn <b>left</b>",
                        maneuver: "turn-left",
                        end_location: {
                          lng: 51.43657289999999,
                          lat: 25.2132031,
                        },
                      },
                    ],
                    duration: { value: 795, text: "13 mins" },
                    distance: { value: 11216, text: "11.2 km" },
                    end_address: "28 Street 8, Ar Rayyan, Qatar",
                  },
                ],
              },
            ],
          },
          completedAt: 1707942351003,
          createdAt: 1707942285310,
          owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
          destination: {
            coordinates: { longitude: 51.43657469999999, latitude: 25.213204 },
            address: "Street 8, Doha, Qatar",
            type: "corporate-branch",
            id: "9VZwCrYZfl03ob5FrD4F.0",
            geohash: "thkx6myvx1",
          },
          co2Amount: 12.612562888268984,
          origin: {
            coordinates: { longitude: 51.489361, latitude: 25.282848 },
            geohash: "thkxgdt7m0",
            address: "858, الدوحة، Qatar",
          },
          car: {
            year: 2021,
            type: "carbon-emitting",
            model: "LAND CRUSER WAGON 4WD",
            make: "Toyota",
            co2_p_km: 0.3379843740994449,
            seat: 3,
          },
          rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
          preferences: {
            female_passengers_only: null,
            verified_passengers_only: null,
          },
          skin: null,
          status: "completed",
          passengersArray: [
            {
              userId: "user_id",
              name: "Jassim",
              username: "abcd",
              location: {
                geohash: "thkxgdt7m0",
                coordinates: { latitude: 25.282848, longitude: 51.489361 },
                address: "858, الدوحة، Qatar",
              },
              credits: 27.8,
              co2Amount: 4.313694566631215,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
              seat: 0,
              id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
              wallet_id: "spencer_carbon.testnet",
              carpool: {
                id: "8292057714690",
                directions: {
                  status: "OK",
                  geocoded_waypoints: [
                    {
                      geocoder_status: "OK",
                      types: [
                        "establishment",
                        "moving_company",
                        "point_of_interest",
                      ],
                      place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                    },
                    {
                      types: [
                        "establishment",
                        "general_contractor",
                        "point_of_interest",
                      ],
                      geocoder_status: "OK",
                      place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                    },
                    {
                      place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                      geocoder_status: "OK",
                      types: ["premise"],
                    },
                    {
                      types: ["street_address"],
                      geocoder_status: "OK",
                      place_id:
                        "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                    },
                  ],
                  routes: [
                    {
                      overview_polyline: {
                        points:
                          "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                      },
                      waypoint_order: [0, 1],
                      bounds: {
                        northeast: { lat: 25.291958, lng: 51.518743 },
                        southwest: { lat: 25.2132031, lng: 51.4319062 },
                      },
                      warnings: [],
                      copyrights: "Map data ©2024 Google",
                      summary: "Al Rayyan Rd",
                      legs: [
                        {
                          distance: { value: 5407, text: "5.4 km" },
                          start_address: "858, الدوحة، Qatar",
                          via_waypoint: [],
                          traffic_speed_entry: [],
                          start_location: { lng: 51.4906083, lat: 25.2827107 },
                          end_address: "7GP7+6RG, Doha, Qatar",
                          steps: [
                            {
                              polyline: { points: "}_iyCiwgyHz@B" },
                              distance: { value: 33, text: "33 m" },
                              end_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                              start_location: {
                                lng: 51.4906083,
                                lat: 25.2827107,
                              },
                              duration: { value: 4, text: "1 min" },
                            },
                            {
                              end_location: {
                                lat: 25.2801532,
                                lng: 51.4871118,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 574, text: "0.6 km" },
                              start_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              html_instructions:
                                "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                              polyline: {
                                points:
                                  "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                              },
                              duration: { text: "1 min", value: 49 },
                            },
                            {
                              start_location: {
                                lng: 51.4871118,
                                lat: 25.2801532,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 426, text: "0.4 km" },
                              polyline: {
                                points:
                                  "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                              },
                              maneuver: "turn-left",
                              end_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                              html_instructions: "Turn <b>left</b>",
                              duration: { value: 44, text: "1 min" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              maneuver: "straight",
                              html_instructions:
                                "Continue straight onto <b>Al Sadd St</b>",
                              distance: { value: 93, text: "93 m" },
                              end_location: {
                                lng: 51.4917143,
                                lat: 25.2802165,
                              },
                              polyline: { points: "cphyCoxgyHEaBAsA" },
                              start_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                            },
                            {
                              end_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                              duration: { text: "1 min", value: 71 },
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                              },
                              html_instructions: "Slight <b>left</b>",
                              distance: { text: "0.2 km", value: 236 },
                              maneuver: "turn-slight-left",
                              start_location: {
                                lng: 51.4917143,
                                lat: 25.2802165,
                              },
                            },
                            {
                              polyline: {
                                points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                              },
                              duration: { text: "1 min", value: 38 },
                              end_location: { lng: 51.4947254, lat: 25.281604 },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              distance: { text: "0.2 km", value: 163 },
                              start_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                            },
                            {
                              duration: { value: 76, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.4947254,
                                lat: 25.281604,
                              },
                              html_instructions:
                                "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                              polyline: {
                                points:
                                  "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                              },
                              maneuver: "keep-left",
                              distance: { text: "1.1 km", value: 1109 },
                              end_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                            },
                            {
                              duration: { text: "3 mins", value: 209 },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                              end_location: {
                                lat: 25.2893902,
                                lng: 51.51516729999999,
                              },
                              distance: { value: 1922, text: "1.9 km" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                              },
                            },
                            {
                              distance: { text: "0.1 km", value: 144 },
                              maneuver: "turn-right",
                              duration: { value: 15, text: "1 min" },
                              start_location: {
                                lng: 51.51516729999999,
                                lat: 25.2893902,
                              },
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej</b>",
                              end_location: {
                                lng: 51.51591370000001,
                                lat: 25.2884741,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              duration: { text: "1 min", value: 11 },
                              polyline: {
                                points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                              },
                              distance: { value: 122, text: "0.1 km" },
                              html_instructions:
                                'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                              end_location: { lat: 25.2874383, lng: 51.516228 },
                            },
                            {
                              duration: { text: "1 min", value: 21 },
                              end_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              start_location: {
                                lng: 51.516228,
                                lat: 25.2874383,
                              },
                              polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                              html_instructions:
                                'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                              travel_mode: "DRIVING",
                              maneuver: "turn-slight-right",
                              distance: { value: 283, text: "0.3 km" },
                            },
                            {
                              start_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              duration: { value: 32, text: "1 min" },
                              travel_mode: "DRIVING",
                              end_location: {
                                lng: 51.5155496,
                                lat: 25.2848165,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                              distance: { value: 125, text: "0.1 km" },
                              polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                              maneuver: "turn-right",
                            },
                            {
                              end_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              maneuver: "turn-right",
                              duration: { text: "1 min", value: 21 },
                              distance: { value: 74, text: "74 m" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                              },
                              start_location: {
                                lat: 25.2848165,
                                lng: 51.5155496,
                              },
                            },
                            {
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              start_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              end_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              polyline: { points: "uniyC_plyHqBJ" },
                              duration: { text: "1 min", value: 23 },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              distance: { value: 64, text: "64 m" },
                            },
                            {
                              polyline: { points: "griyCsolyH?p@DZ" },
                              duration: { text: "1 min", value: 14 },
                              distance: { text: "39 m", value: 39 },
                              end_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              start_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              travel_mode: "DRIVING",
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              maneuver: "turn-left",
                            },
                          ],
                          duration: { text: "11 mins", value: 636 },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                        },
                        {
                          traffic_speed_entry: [],
                          duration: { text: "10 mins", value: 598 },
                          start_location: { lng: 51.5145907, lat: 25.2856124 },
                          distance: { text: "4.5 km", value: 4489 },
                          steps: [
                            {
                              start_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              end_location: {
                                lat: 25.2859334,
                                lng: 51.5165533,
                              },
                              duration: { text: "1 min", value: 73 },
                              distance: { value: 201, text: "0.2 km" },
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                              },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                            },
                            {
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                              end_location: { lng: 51.518743, lat: 25.2796568 },
                              distance: { text: "0.7 km", value: 735 },
                              duration: { value: 78, text: "1 min" },
                              start_location: {
                                lng: 51.5165533,
                                lat: 25.2859334,
                              },
                              maneuver: "turn-right",
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                              end_location: {
                                lat: 25.2769643,
                                lng: 51.5164061,
                              },
                              polyline: {
                                points:
                                  "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                              },
                              start_location: {
                                lat: 25.2796568,
                                lng: 51.518743,
                              },
                              distance: { value: 393, text: "0.4 km" },
                              travel_mode: "DRIVING",
                              duration: { value: 59, text: "1 min" },
                              maneuver: "turn-slight-right",
                            },
                            {
                              end_location: { lng: 51.500835, lat: 25.2663847 },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.5164061,
                                lat: 25.2769643,
                              },
                              maneuver: "merge",
                              distance: { value: 1963, text: "2.0 km" },
                              polyline: {
                                points:
                                  "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                              },
                              duration: { text: "4 mins", value: 229 },
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                              distance: { value: 428, text: "0.4 km" },
                              duration: { text: "1 min", value: 40 },
                              start_location: {
                                lng: 51.500835,
                                lat: 25.2663847,
                              },
                              end_location: { lat: 25.2643805, lng: 51.497229 },
                              maneuver: "turn-slight-right",
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                              },
                            },
                            {
                              start_location: {
                                lat: 25.2643805,
                                lng: 51.497229,
                              },
                              maneuver: "roundabout-right",
                              polyline: {
                                points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                              end_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              distance: { value: 182, text: "0.2 km" },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 22 },
                            },
                            {
                              maneuver: "turn-slight-right",
                              distance: { value: 25, text: "25 m" },
                              polyline: { points: "oueyCmzhyH]DKB" },
                              duration: { text: "1 min", value: 3 },
                              html_instructions:
                                "Slight <b>right</b> onto <b>Al Nasr St</b>",
                              end_location: {
                                lng: 51.4961765,
                                lat: 25.2658942,
                              },
                              start_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { text: "0.4 km", value: 389 },
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                              maneuver: "turn-slight-right",
                              travel_mode: "DRIVING",
                              duration: { value: 53, text: "1 min" },
                              polyline: {
                                points:
                                  "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                              },
                              end_location: { lat: 25.267547, lng: 51.4995317 },
                              start_location: {
                                lng: 51.4961765,
                                lat: 25.2658942,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              polyline: { points: "eafyCaoiyHQFiDdA" },
                              distance: { text: "0.1 km", value: 111 },
                              start_location: {
                                lat: 25.267547,
                                lng: 51.4995317,
                              },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohammed St</b>",
                              duration: { value: 22, text: "1 min" },
                              maneuver: "turn-left",
                              end_location: { lat: 25.2684856, lng: 51.499144 },
                            },
                            {
                              maneuver: "turn-left",
                              start_location: {
                                lat: 25.2684856,
                                lng: 51.499144,
                              },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              end_location: {
                                lng: 51.49855549999999,
                                lat: 25.2683307,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 19 },
                              distance: { value: 62, text: "62 m" },
                              polyline: { points: "agfyCsliyH\\vABNAJ" },
                            },
                          ],
                          start_address: "7GP7+6RG, Doha, Qatar",
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          via_waypoint: [],
                          end_address: "7F9X+C9V, Doha, Qatar",
                        },
                        {
                          traffic_speed_entry: [],
                          start_address: "7F9X+C9V, Doha, Qatar",
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          via_waypoint: [],
                          end_location: {
                            lng: 51.43657289999999,
                            lat: 25.2132031,
                          },
                          steps: [
                            {
                              polyline: { points: "affyC_iiyH@KCO]wA" },
                              duration: { value: 14, text: "1 min" },
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                              start_location: {
                                lng: 51.49855549999999,
                                lat: 25.2683307,
                              },
                              distance: { value: 62, text: "62 m" },
                              travel_mode: "DRIVING",
                            },
                            {
                              html_instructions:
                                "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                              start_location: {
                                lat: 25.2684856,
                                lng: 51.499144,
                              },
                              end_location: { lng: 51.499066, lat: 25.2687298 },
                              polyline: { points: "agfyCsliyHo@L" },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 9 },
                              distance: { text: "28 m", value: 28 },
                              maneuver: "turn-left",
                            },
                            {
                              start_location: {
                                lat: 25.2687298,
                                lng: 51.499066,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 154, text: "0.2 km" },
                              maneuver: "turn-right",
                              duration: { text: "1 min", value: 32 },
                              end_location: { lat: 25.2692232, lng: 51.500496 },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Baraha</b>",
                              polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                            },
                            {
                              distance: { value: 69, text: "69 m" },
                              end_location: { lat: 25.269806, lng: 51.500265 },
                              start_location: {
                                lng: 51.500496,
                                lat: 25.2692232,
                              },
                              polyline: { points: "skfyCcuiyH{@Ty@X" },
                              duration: { text: "1 min", value: 13 },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                              maneuver: "turn-left",
                            },
                            {
                              end_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              maneuver: "turn-right",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                              start_location: {
                                lng: 51.500265,
                                lat: 25.269806,
                              },
                              polyline: {
                                points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                              },
                              duration: { value: 29, text: "1 min" },
                              distance: { text: "0.2 km", value: 223 },
                              travel_mode: "DRIVING",
                            },
                            {
                              end_location: {
                                lat: 25.2679045,
                                lng: 51.5033973,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "roundabout-right",
                              start_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              duration: { value: 48, text: "1 min" },
                              polyline: {
                                points:
                                  "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                              distance: { value: 326, text: "0.3 km" },
                            },
                            {
                              html_instructions: "Merge onto <b>Salwa Rd</b>",
                              end_location: { lat: 25.2368244, lng: 51.45117 },
                              travel_mode: "DRIVING",
                              distance: { value: 6288, text: "6.3 km" },
                              start_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              duration: { value: 283, text: "5 mins" },
                              polyline: {
                                points:
                                  "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                              },
                              maneuver: "merge",
                            },
                            {
                              duration: { value: 10, text: "1 min" },
                              maneuver: "ramp-right",
                              start_location: {
                                lat: 25.2368244,
                                lng: 51.45117,
                              },
                              html_instructions:
                                'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                              travel_mode: "DRIVING",
                              distance: { value: 194, text: "0.2 km" },
                              polyline: { points: "ca`yCy``yHpAnDlAdD" },
                              end_location: {
                                lat: 25.2360177,
                                lng: 51.4494587,
                              },
                            },
                            {
                              maneuver: "merge",
                              html_instructions:
                                "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.4494587,
                                lat: 25.2360177,
                              },
                              distance: { value: 397, text: "0.4 km" },
                              duration: { text: "1 min", value: 33 },
                              end_location: {
                                lng: 51.4461502,
                                lat: 25.2340694,
                              },
                              polyline: {
                                points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              end_location: {
                                lng: 51.4456607,
                                lat: 25.2337942,
                              },
                              html_instructions:
                                "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              maneuver: "straight",
                              polyline: { points: "}o_yCma_yHP`@d@~@" },
                              distance: { text: "58 m", value: 58 },
                              duration: { value: 8, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2340694,
                                lng: 51.4461502,
                              },
                            },
                            {
                              distance: { text: "1.5 km", value: 1451 },
                              maneuver: "straight",
                              start_location: {
                                lat: 25.2337942,
                                lng: 51.4456607,
                              },
                              end_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "2 mins", value: 90 },
                              polyline: {
                                points:
                                  "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                              },
                              html_instructions:
                                'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                            },
                            {
                              html_instructions:
                                "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                              },
                              start_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              maneuver: "turn-slight-left",
                              distance: { value: 92, text: "92 m" },
                              end_location: {
                                lat: 25.2262036,
                                lng: 51.4328437,
                              },
                              duration: { text: "1 min", value: 10 },
                            },
                            {
                              polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                              end_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              start_location: {
                                lng: 51.4328437,
                                lat: 25.2262036,
                              },
                              html_instructions: "Continue straight",
                              duration: { value: 19, text: "1 min" },
                              maneuver: "straight",
                              distance: { value: 71, text: "71 m" },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { value: 813, text: "0.8 km" },
                              travel_mode: "DRIVING",
                              duration: { value: 72, text: "1 min" },
                              end_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                              html_instructions:
                                "Continue onto <b>E Industrial Rd</b>",
                              start_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              polyline: {
                                points:
                                  "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                              },
                            },
                            {
                              duration: { text: "1 min", value: 27 },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 2</b>",
                              distance: { value: 263, text: "0.3 km" },
                              end_location: {
                                lng: 51.43371639999999,
                                lat: 25.2182661,
                              },
                              start_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                              polyline: {
                                points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                              },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { value: 682, text: "0.7 km" },
                              start_location: {
                                lat: 25.2182661,
                                lng: 51.43371639999999,
                              },
                              end_location: {
                                lng: 51.4366206,
                                lat: 25.2135618,
                              },
                              duration: { value: 78, text: "1 min" },
                              polyline: {
                                points:
                                  "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                              maneuver: "roundabout-right",
                            },
                            {
                              maneuver: "turn-right",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 8</b>",
                              polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                              end_location: {
                                lat: 25.2133289,
                                lng: 51.4364944,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 12 },
                              start_location: {
                                lng: 51.4366206,
                                lat: 25.2135618,
                              },
                              distance: { value: 29, text: "29 m" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              start_location: {
                                lat: 25.2133289,
                                lng: 51.4364944,
                              },
                              polyline: { points: "in{xCae}xHXO" },
                              distance: { text: "16 m", value: 16 },
                              html_instructions: "Turn <b>left</b>",
                              maneuver: "turn-left",
                              end_location: {
                                lng: 51.43657289999999,
                                lat: 25.2132031,
                              },
                            },
                          ],
                          duration: { value: 795, text: "13 mins" },
                          distance: { value: 11216, text: "11.2 km" },
                          end_address: "28 Street 8, Ar Rayyan, Qatar",
                        },
                      ],
                    },
                  ],
                },
                completedAt: 1707942351003,
                createdAt: 1707942285310,
                owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                destination: {
                  coordinates: {
                    longitude: 51.43657469999999,
                    latitude: 25.213204,
                  },
                  address: "Street 8, Doha, Qatar",
                  type: "corporate-branch",
                  id: "9VZwCrYZfl03ob5FrD4F.0",
                  geohash: "thkx6myvx1",
                },
                co2Amount: 12.612562888268984,
                origin: {
                  coordinates: { longitude: 51.489361, latitude: 25.282848 },
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                },
                car: {
                  year: 2021,
                  type: "carbon-emitting",
                  model: "LAND CRUSER WAGON 4WD",
                  make: "Toyota",
                  co2_p_km: 0.3379843740994449,
                  seat: 3,
                },
                rules: [
                  "No smoking",
                  "No eating or drinking",
                  "Keep off the AC",
                ],
                preferences: {
                  female_passengers_only: null,
                  verified_passengers_only: null,
                },
                skin: null,
                status: "completed",
                passengersArray: [
                  {
                    credits: 27.8,
                    username: "abcd",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    seat: 0,
                    id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                    name: "Jassim",
                    co2Amount: 4.313694566631215,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                    location: {
                      geohash: "thkxgdt7m0",
                      coordinates: {
                        latitude: 25.282848,
                        longitude: 51.489361,
                      },
                      address: "858, الدوحة، Qatar",
                    },
                    wallet_id: "spencer_carbon.testnet",
                  },
                  {
                    status: "picked-up",
                    id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                    seat: 2,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                    username: "sarah",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    wallet_id: "sarah_carbon.testnet",
                    credits: 13,
                    co2Amount: 3.7908327398993733,
                    name: "Faisal",
                    location: {
                      coordinates: {
                        longitude: 51.498513,
                        latitude: 25.268246,
                      },
                      address: "7F9X+C9V, Doha, Qatar",
                      geohash: "thkxezun4q",
                    },
                  },
                  {
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    status: "picked-up",
                    location: {
                      geohash: "thkxu5pfc8",
                      address: "7GP7+6RG, Doha, Qatar",
                      coordinates: {
                        longitude: 51.514615,
                        latitude: 25.285509,
                      },
                    },
                    username: "michaelscott",
                    id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                    credits: 13.9,
                    seat: 1,
                    name: "Ahmed",
                    wallet_id: "sofia_carbon.testnet",
                    co2Amount: 4.508035581738396,
                  },
                ],
              },
              type: "carpool_passenger",
              corporate: "9VZwCrYZfl03ob5FrD4F",
            },
            {
              status: "picked-up",
              id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
              seat: 2,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
              username: "sarah",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              wallet_id: "sarah_carbon.testnet",
              credits: 13,
              co2Amount: 3.7908327398993733,
              name: "Faisal",
              location: {
                coordinates: { longitude: 51.498513, latitude: 25.268246 },
                address: "7F9X+C9V, Doha, Qatar",
                geohash: "thkxezun4q",
              },
            },
            {
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              status: "picked-up",
              location: {
                geohash: "thkxu5pfc8",
                address: "7GP7+6RG, Doha, Qatar",
                coordinates: { longitude: 51.514615, latitude: 25.285509 },
              },
              username: "michaelscott",
              id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
              credits: 13.9,
              seat: 1,
              name: "Ahmed",
              wallet_id: "sofia_carbon.testnet",
              co2Amount: 4.508035581738396,
            },
          ],
        },
        type: "carpool_passenger",
        corporate: "9VZwCrYZfl03ob5FrD4F",
      },
      lpd29qTdlURFvrSDO0w5WBODcSp2: {
        userId: "user_id",
        name: "Ahmed",
        username: "michaelscott",
        location: {
          geohash: "thkxu5pfc8",
          address: "7GP7+6RG, Doha, Qatar",
          coordinates: { longitude: 51.514615, latitude: 25.285509 },
        },
        credits: 13.9,
        co2Amount: 4.508035581738396,
        avatar:
          "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
        seat: 1,
        id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
        wallet_id: "sofia_carbon.testnet",
        status: "picked-up",
        carpool: {
          id: "8292057714690",
          directions: {
            status: "OK",
            geocoded_waypoints: [
              {
                geocoder_status: "OK",
                types: ["establishment", "moving_company", "point_of_interest"],
                place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
              },
              {
                types: [
                  "establishment",
                  "general_contractor",
                  "point_of_interest",
                ],
                geocoder_status: "OK",
                place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
              },
              {
                place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                geocoder_status: "OK",
                types: ["premise"],
              },
              {
                types: ["street_address"],
                geocoder_status: "OK",
                place_id:
                  "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
              },
            ],
            routes: [
              {
                overview_polyline: {
                  points:
                    "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                },
                waypoint_order: [0, 1],
                bounds: {
                  northeast: { lat: 25.291958, lng: 51.518743 },
                  southwest: { lat: 25.2132031, lng: 51.4319062 },
                },
                warnings: [],
                copyrights: "Map data ©2024 Google",
                summary: "Al Rayyan Rd",
                legs: [
                  {
                    distance: { value: 5407, text: "5.4 km" },
                    start_address: "858, الدوحة، Qatar",
                    via_waypoint: [],
                    traffic_speed_entry: [],
                    start_location: { lng: 51.4906083, lat: 25.2827107 },
                    end_address: "7GP7+6RG, Doha, Qatar",
                    steps: [
                      {
                        polyline: { points: "}_iyCiwgyHz@B" },
                        distance: { value: 33, text: "33 m" },
                        end_location: { lng: 51.4905936, lat: 25.2824136 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                        start_location: { lng: 51.4906083, lat: 25.2827107 },
                        duration: { value: 4, text: "1 min" },
                      },
                      {
                        end_location: { lat: 25.2801532, lng: 51.4871118 },
                        travel_mode: "DRIVING",
                        distance: { value: 574, text: "0.6 km" },
                        start_location: { lng: 51.4905936, lat: 25.2824136 },
                        html_instructions:
                          "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                        polyline: {
                          points:
                            "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                        },
                        duration: { text: "1 min", value: 49 },
                      },
                      {
                        start_location: { lng: 51.4871118, lat: 25.2801532 },
                        travel_mode: "DRIVING",
                        distance: { value: 426, text: "0.4 km" },
                        polyline: {
                          points:
                            "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                        },
                        maneuver: "turn-left",
                        end_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                        html_instructions: "Turn <b>left</b>",
                        duration: { value: 44, text: "1 min" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        maneuver: "straight",
                        html_instructions:
                          "Continue straight onto <b>Al Sadd St</b>",
                        distance: { value: 93, text: "93 m" },
                        end_location: { lng: 51.4917143, lat: 25.2802165 },
                        polyline: { points: "cphyCoxgyHEaBAsA" },
                        start_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                      },
                      {
                        end_location: { lat: 25.2804496, lng: 51.4940386 },
                        duration: { text: "1 min", value: 71 },
                        travel_mode: "DRIVING",
                        polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                        html_instructions: "Slight <b>left</b>",
                        distance: { text: "0.2 km", value: 236 },
                        maneuver: "turn-slight-left",
                        start_location: { lng: 51.4917143, lat: 25.2802165 },
                      },
                      {
                        polyline: {
                          points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                        },
                        duration: { text: "1 min", value: 38 },
                        end_location: { lng: 51.4947254, lat: 25.281604 },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        distance: { text: "0.2 km", value: 163 },
                        start_location: { lat: 25.2804496, lng: 51.4940386 },
                      },
                      {
                        duration: { value: 76, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.4947254, lat: 25.281604 },
                        html_instructions:
                          "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                        polyline: {
                          points:
                            "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                        },
                        maneuver: "keep-left",
                        distance: { text: "1.1 km", value: 1109 },
                        end_location: { lat: 25.2914195, lng: 51.4965617 },
                      },
                      {
                        duration: { text: "3 mins", value: 209 },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2914195, lng: 51.4965617 },
                        end_location: {
                          lat: 25.2893902,
                          lng: 51.51516729999999,
                        },
                        distance: { value: 1922, text: "1.9 km" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                        },
                      },
                      {
                        distance: { text: "0.1 km", value: 144 },
                        maneuver: "turn-right",
                        duration: { value: 15, text: "1 min" },
                        start_location: {
                          lng: 51.51516729999999,
                          lat: 25.2893902,
                        },
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej</b>",
                        end_location: {
                          lng: 51.51591370000001,
                          lat: 25.2884741,
                        },
                      },
                      {
                        travel_mode: "DRIVING",
                        start_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        duration: { text: "1 min", value: 11 },
                        polyline: {
                          points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                        },
                        distance: { value: 122, text: "0.1 km" },
                        html_instructions:
                          'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                        end_location: { lat: 25.2874383, lng: 51.516228 },
                      },
                      {
                        duration: { text: "1 min", value: 21 },
                        end_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        start_location: { lng: 51.516228, lat: 25.2874383 },
                        polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                        html_instructions:
                          'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                        travel_mode: "DRIVING",
                        maneuver: "turn-slight-right",
                        distance: { value: 283, text: "0.3 km" },
                      },
                      {
                        start_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        duration: { value: 32, text: "1 min" },
                        travel_mode: "DRIVING",
                        end_location: { lng: 51.5155496, lat: 25.2848165 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                        distance: { value: 125, text: "0.1 km" },
                        polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                        maneuver: "turn-right",
                      },
                      {
                        end_location: { lat: 25.2850716, lng: 51.5150352 },
                        maneuver: "turn-right",
                        duration: { text: "1 min", value: 21 },
                        distance: { value: 74, text: "74 m" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                        },
                        start_location: { lat: 25.2848165, lng: 51.5155496 },
                      },
                      {
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        start_location: { lat: 25.2850716, lng: 51.5150352 },
                        end_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        polyline: { points: "uniyC_plyHqBJ" },
                        duration: { text: "1 min", value: 23 },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        distance: { value: 64, text: "64 m" },
                      },
                      {
                        polyline: { points: "griyCsolyH?p@DZ" },
                        duration: { text: "1 min", value: 14 },
                        distance: { text: "39 m", value: 39 },
                        end_location: { lng: 51.5145907, lat: 25.2856124 },
                        start_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        travel_mode: "DRIVING",
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        maneuver: "turn-left",
                      },
                    ],
                    duration: { text: "11 mins", value: 636 },
                    end_location: { lng: 51.5145907, lat: 25.2856124 },
                  },
                  {
                    traffic_speed_entry: [],
                    duration: { text: "10 mins", value: 598 },
                    start_location: { lng: 51.5145907, lat: 25.2856124 },
                    distance: { text: "4.5 km", value: 4489 },
                    steps: [
                      {
                        start_location: { lng: 51.5145907, lat: 25.2856124 },
                        end_location: { lat: 25.2859334, lng: 51.5165533 },
                        duration: { text: "1 min", value: 73 },
                        distance: { value: 201, text: "0.2 km" },
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                        },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                      },
                      {
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                        end_location: { lng: 51.518743, lat: 25.2796568 },
                        distance: { text: "0.7 km", value: 735 },
                        duration: { value: 78, text: "1 min" },
                        start_location: { lng: 51.5165533, lat: 25.2859334 },
                        maneuver: "turn-right",
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                        end_location: { lat: 25.2769643, lng: 51.5164061 },
                        polyline: {
                          points:
                            "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                        },
                        start_location: { lat: 25.2796568, lng: 51.518743 },
                        distance: { value: 393, text: "0.4 km" },
                        travel_mode: "DRIVING",
                        duration: { value: 59, text: "1 min" },
                        maneuver: "turn-slight-right",
                      },
                      {
                        end_location: { lng: 51.500835, lat: 25.2663847 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                        start_location: { lng: 51.5164061, lat: 25.2769643 },
                        maneuver: "merge",
                        distance: { value: 1963, text: "2.0 km" },
                        polyline: {
                          points:
                            "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                        },
                        duration: { text: "4 mins", value: 229 },
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                        distance: { value: 428, text: "0.4 km" },
                        duration: { text: "1 min", value: 40 },
                        start_location: { lng: 51.500835, lat: 25.2663847 },
                        end_location: { lat: 25.2643805, lng: 51.497229 },
                        maneuver: "turn-slight-right",
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                        },
                      },
                      {
                        start_location: { lat: 25.2643805, lng: 51.497229 },
                        maneuver: "roundabout-right",
                        polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                        end_location: { lat: 25.2656775, lng: 51.4962302 },
                        distance: { value: 182, text: "0.2 km" },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 22 },
                      },
                      {
                        maneuver: "turn-slight-right",
                        distance: { value: 25, text: "25 m" },
                        polyline: { points: "oueyCmzhyH]DKB" },
                        duration: { text: "1 min", value: 3 },
                        html_instructions:
                          "Slight <b>right</b> onto <b>Al Nasr St</b>",
                        end_location: { lng: 51.4961765, lat: 25.2658942 },
                        start_location: { lat: 25.2656775, lng: 51.4962302 },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { text: "0.4 km", value: 389 },
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                        maneuver: "turn-slight-right",
                        travel_mode: "DRIVING",
                        duration: { value: 53, text: "1 min" },
                        polyline: {
                          points:
                            "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                        },
                        end_location: { lat: 25.267547, lng: 51.4995317 },
                        start_location: { lng: 51.4961765, lat: 25.2658942 },
                      },
                      {
                        travel_mode: "DRIVING",
                        polyline: { points: "eafyCaoiyHQFiDdA" },
                        distance: { text: "0.1 km", value: 111 },
                        start_location: { lat: 25.267547, lng: 51.4995317 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohammed St</b>",
                        duration: { value: 22, text: "1 min" },
                        maneuver: "turn-left",
                        end_location: { lat: 25.2684856, lng: 51.499144 },
                      },
                      {
                        maneuver: "turn-left",
                        start_location: { lat: 25.2684856, lng: 51.499144 },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        end_location: {
                          lng: 51.49855549999999,
                          lat: 25.2683307,
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 19 },
                        distance: { value: 62, text: "62 m" },
                        polyline: { points: "agfyCsliyH\\vABNAJ" },
                      },
                    ],
                    start_address: "7GP7+6RG, Doha, Qatar",
                    end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                    via_waypoint: [],
                    end_address: "7F9X+C9V, Doha, Qatar",
                  },
                  {
                    traffic_speed_entry: [],
                    start_address: "7F9X+C9V, Doha, Qatar",
                    start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                    via_waypoint: [],
                    end_location: { lng: 51.43657289999999, lat: 25.2132031 },
                    steps: [
                      {
                        polyline: { points: "affyC_iiyH@KCO]wA" },
                        duration: { value: 14, text: "1 min" },
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                        start_location: {
                          lng: 51.49855549999999,
                          lat: 25.2683307,
                        },
                        distance: { value: 62, text: "62 m" },
                        travel_mode: "DRIVING",
                      },
                      {
                        html_instructions:
                          "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                        start_location: { lat: 25.2684856, lng: 51.499144 },
                        end_location: { lng: 51.499066, lat: 25.2687298 },
                        polyline: { points: "agfyCsliyHo@L" },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 9 },
                        distance: { text: "28 m", value: 28 },
                        maneuver: "turn-left",
                      },
                      {
                        start_location: { lat: 25.2687298, lng: 51.499066 },
                        travel_mode: "DRIVING",
                        distance: { value: 154, text: "0.2 km" },
                        maneuver: "turn-right",
                        duration: { text: "1 min", value: 32 },
                        end_location: { lat: 25.2692232, lng: 51.500496 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Baraha</b>",
                        polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                      },
                      {
                        distance: { value: 69, text: "69 m" },
                        end_location: { lat: 25.269806, lng: 51.500265 },
                        start_location: { lng: 51.500496, lat: 25.2692232 },
                        polyline: { points: "skfyCcuiyH{@Ty@X" },
                        duration: { text: "1 min", value: 13 },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                        maneuver: "turn-left",
                      },
                      {
                        end_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        maneuver: "turn-right",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                        start_location: { lng: 51.500265, lat: 25.269806 },
                        polyline: {
                          points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                        },
                        duration: { value: 29, text: "1 min" },
                        distance: { text: "0.2 km", value: 223 },
                        travel_mode: "DRIVING",
                      },
                      {
                        end_location: { lat: 25.2679045, lng: 51.5033973 },
                        travel_mode: "DRIVING",
                        maneuver: "roundabout-right",
                        start_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        duration: { value: 48, text: "1 min" },
                        polyline: {
                          points:
                            "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                        },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                        distance: { value: 326, text: "0.3 km" },
                      },
                      {
                        html_instructions: "Merge onto <b>Salwa Rd</b>",
                        end_location: { lat: 25.2368244, lng: 51.45117 },
                        travel_mode: "DRIVING",
                        distance: { value: 6288, text: "6.3 km" },
                        start_location: { lng: 51.5033973, lat: 25.2679045 },
                        duration: { value: 283, text: "5 mins" },
                        polyline: {
                          points:
                            "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                        },
                        maneuver: "merge",
                      },
                      {
                        duration: { value: 10, text: "1 min" },
                        maneuver: "ramp-right",
                        start_location: { lat: 25.2368244, lng: 51.45117 },
                        html_instructions:
                          'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                        travel_mode: "DRIVING",
                        distance: { value: 194, text: "0.2 km" },
                        polyline: { points: "ca`yCy``yHpAnDlAdD" },
                        end_location: { lat: 25.2360177, lng: 51.4494587 },
                      },
                      {
                        maneuver: "merge",
                        html_instructions:
                          "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        start_location: { lng: 51.4494587, lat: 25.2360177 },
                        distance: { value: 397, text: "0.4 km" },
                        duration: { text: "1 min", value: 33 },
                        end_location: { lng: 51.4461502, lat: 25.2340694 },
                        polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                        travel_mode: "DRIVING",
                      },
                      {
                        end_location: { lng: 51.4456607, lat: 25.2337942 },
                        html_instructions:
                          "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        maneuver: "straight",
                        polyline: { points: "}o_yCma_yHP`@d@~@" },
                        distance: { text: "58 m", value: 58 },
                        duration: { value: 8, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2340694, lng: 51.4461502 },
                      },
                      {
                        distance: { text: "1.5 km", value: 1451 },
                        maneuver: "straight",
                        start_location: { lat: 25.2337942, lng: 51.4456607 },
                        end_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "2 mins", value: 90 },
                        polyline: {
                          points:
                            "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                        },
                        html_instructions:
                          'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                      },
                      {
                        html_instructions:
                          "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                        travel_mode: "DRIVING",
                        polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                        start_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        maneuver: "turn-slight-left",
                        distance: { value: 92, text: "92 m" },
                        end_location: { lat: 25.2262036, lng: 51.4328437 },
                        duration: { text: "1 min", value: 10 },
                      },
                      {
                        polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                        end_location: { lat: 25.2257335, lng: 51.4323655 },
                        start_location: { lng: 51.4328437, lat: 25.2262036 },
                        html_instructions: "Continue straight",
                        duration: { value: 19, text: "1 min" },
                        maneuver: "straight",
                        distance: { value: 71, text: "71 m" },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { value: 813, text: "0.8 km" },
                        travel_mode: "DRIVING",
                        duration: { value: 72, text: "1 min" },
                        end_location: { lng: 51.4357808, lat: 25.2196178 },
                        html_instructions:
                          "Continue onto <b>E Industrial Rd</b>",
                        start_location: { lat: 25.2257335, lng: 51.4323655 },
                        polyline: {
                          points:
                            "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                        },
                      },
                      {
                        duration: { text: "1 min", value: 27 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 2</b>",
                        distance: { value: 263, text: "0.3 km" },
                        end_location: {
                          lng: 51.43371639999999,
                          lat: 25.2182661,
                        },
                        start_location: { lng: 51.4357808, lat: 25.2196178 },
                        polyline: {
                          points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                        },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { value: 682, text: "0.7 km" },
                        start_location: {
                          lat: 25.2182661,
                          lng: 51.43371639999999,
                        },
                        end_location: { lng: 51.4366206, lat: 25.2135618 },
                        duration: { value: 78, text: "1 min" },
                        polyline: {
                          points:
                            "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                        },
                        html_instructions:
                          "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                        maneuver: "roundabout-right",
                      },
                      {
                        maneuver: "turn-right",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 8</b>",
                        polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                        end_location: { lat: 25.2133289, lng: 51.4364944 },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 12 },
                        start_location: { lng: 51.4366206, lat: 25.2135618 },
                        distance: { value: 29, text: "29 m" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        start_location: { lat: 25.2133289, lng: 51.4364944 },
                        polyline: { points: "in{xCae}xHXO" },
                        distance: { text: "16 m", value: 16 },
                        html_instructions: "Turn <b>left</b>",
                        maneuver: "turn-left",
                        end_location: {
                          lng: 51.43657289999999,
                          lat: 25.2132031,
                        },
                      },
                    ],
                    duration: { value: 795, text: "13 mins" },
                    distance: { value: 11216, text: "11.2 km" },
                    end_address: "28 Street 8, Ar Rayyan, Qatar",
                  },
                ],
              },
            ],
          },
          completedAt: 1707942351003,
          createdAt: 1707942285310,
          owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
          destination: {
            coordinates: { longitude: 51.43657469999999, latitude: 25.213204 },
            address: "Street 8, Doha, Qatar",
            type: "corporate-branch",
            id: "9VZwCrYZfl03ob5FrD4F.0",
            geohash: "thkx6myvx1",
          },
          co2Amount: 12.612562888268984,
          origin: {
            coordinates: { longitude: 51.489361, latitude: 25.282848 },
            geohash: "thkxgdt7m0",
            address: "858, الدوحة، Qatar",
          },
          car: {
            year: 2021,
            type: "carbon-emitting",
            model: "LAND CRUSER WAGON 4WD",
            make: "Toyota",
            co2_p_km: 0.3379843740994449,
            seat: 3,
          },
          rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
          preferences: {
            female_passengers_only: null,
            verified_passengers_only: null,
          },
          skin: null,
          status: "completed",
          passengersArray: [
            {
              userId: "user_id",
              name: "Jassim",
              username: "abcd",
              location: {
                geohash: "thkxgdt7m0",
                coordinates: { latitude: 25.282848, longitude: 51.489361 },
                address: "858, الدوحة، Qatar",
              },
              credits: 27.8,
              co2Amount: 4.313694566631215,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
              seat: 0,
              id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
              wallet_id: "spencer_carbon.testnet",
              carpool: {
                id: "8292057714690",
                directions: {
                  status: "OK",
                  geocoded_waypoints: [
                    {
                      geocoder_status: "OK",
                      types: [
                        "establishment",
                        "moving_company",
                        "point_of_interest",
                      ],
                      place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                    },
                    {
                      types: [
                        "establishment",
                        "general_contractor",
                        "point_of_interest",
                      ],
                      geocoder_status: "OK",
                      place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                    },
                    {
                      place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                      geocoder_status: "OK",
                      types: ["premise"],
                    },
                    {
                      types: ["street_address"],
                      geocoder_status: "OK",
                      place_id:
                        "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                    },
                  ],
                  routes: [
                    {
                      overview_polyline: {
                        points:
                          "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                      },
                      waypoint_order: [0, 1],
                      bounds: {
                        northeast: { lat: 25.291958, lng: 51.518743 },
                        southwest: { lat: 25.2132031, lng: 51.4319062 },
                      },
                      warnings: [],
                      copyrights: "Map data ©2024 Google",
                      summary: "Al Rayyan Rd",
                      legs: [
                        {
                          distance: { value: 5407, text: "5.4 km" },
                          start_address: "858, الدوحة، Qatar",
                          via_waypoint: [],
                          traffic_speed_entry: [],
                          start_location: { lng: 51.4906083, lat: 25.2827107 },
                          end_address: "7GP7+6RG, Doha, Qatar",
                          steps: [
                            {
                              polyline: { points: "}_iyCiwgyHz@B" },
                              distance: { value: 33, text: "33 m" },
                              end_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                              start_location: {
                                lng: 51.4906083,
                                lat: 25.2827107,
                              },
                              duration: { value: 4, text: "1 min" },
                            },
                            {
                              end_location: {
                                lat: 25.2801532,
                                lng: 51.4871118,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 574, text: "0.6 km" },
                              start_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              html_instructions:
                                "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                              polyline: {
                                points:
                                  "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                              },
                              duration: { text: "1 min", value: 49 },
                            },
                            {
                              start_location: {
                                lng: 51.4871118,
                                lat: 25.2801532,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 426, text: "0.4 km" },
                              polyline: {
                                points:
                                  "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                              },
                              maneuver: "turn-left",
                              end_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                              html_instructions: "Turn <b>left</b>",
                              duration: { value: 44, text: "1 min" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              maneuver: "straight",
                              html_instructions:
                                "Continue straight onto <b>Al Sadd St</b>",
                              distance: { value: 93, text: "93 m" },
                              end_location: {
                                lng: 51.4917143,
                                lat: 25.2802165,
                              },
                              polyline: { points: "cphyCoxgyHEaBAsA" },
                              start_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                            },
                            {
                              end_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                              duration: { text: "1 min", value: 71 },
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                              },
                              html_instructions: "Slight <b>left</b>",
                              distance: { text: "0.2 km", value: 236 },
                              maneuver: "turn-slight-left",
                              start_location: {
                                lng: 51.4917143,
                                lat: 25.2802165,
                              },
                            },
                            {
                              polyline: {
                                points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                              },
                              duration: { text: "1 min", value: 38 },
                              end_location: { lng: 51.4947254, lat: 25.281604 },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              distance: { text: "0.2 km", value: 163 },
                              start_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                            },
                            {
                              duration: { value: 76, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.4947254,
                                lat: 25.281604,
                              },
                              html_instructions:
                                "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                              polyline: {
                                points:
                                  "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                              },
                              maneuver: "keep-left",
                              distance: { text: "1.1 km", value: 1109 },
                              end_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                            },
                            {
                              duration: { text: "3 mins", value: 209 },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                              end_location: {
                                lat: 25.2893902,
                                lng: 51.51516729999999,
                              },
                              distance: { value: 1922, text: "1.9 km" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                              },
                            },
                            {
                              distance: { text: "0.1 km", value: 144 },
                              maneuver: "turn-right",
                              duration: { value: 15, text: "1 min" },
                              start_location: {
                                lng: 51.51516729999999,
                                lat: 25.2893902,
                              },
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej</b>",
                              end_location: {
                                lng: 51.51591370000001,
                                lat: 25.2884741,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              duration: { text: "1 min", value: 11 },
                              polyline: {
                                points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                              },
                              distance: { value: 122, text: "0.1 km" },
                              html_instructions:
                                'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                              end_location: { lat: 25.2874383, lng: 51.516228 },
                            },
                            {
                              duration: { text: "1 min", value: 21 },
                              end_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              start_location: {
                                lng: 51.516228,
                                lat: 25.2874383,
                              },
                              polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                              html_instructions:
                                'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                              travel_mode: "DRIVING",
                              maneuver: "turn-slight-right",
                              distance: { value: 283, text: "0.3 km" },
                            },
                            {
                              start_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              duration: { value: 32, text: "1 min" },
                              travel_mode: "DRIVING",
                              end_location: {
                                lng: 51.5155496,
                                lat: 25.2848165,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                              distance: { value: 125, text: "0.1 km" },
                              polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                              maneuver: "turn-right",
                            },
                            {
                              end_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              maneuver: "turn-right",
                              duration: { text: "1 min", value: 21 },
                              distance: { value: 74, text: "74 m" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                              },
                              start_location: {
                                lat: 25.2848165,
                                lng: 51.5155496,
                              },
                            },
                            {
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              start_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              end_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              polyline: { points: "uniyC_plyHqBJ" },
                              duration: { text: "1 min", value: 23 },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              distance: { value: 64, text: "64 m" },
                            },
                            {
                              polyline: { points: "griyCsolyH?p@DZ" },
                              duration: { text: "1 min", value: 14 },
                              distance: { text: "39 m", value: 39 },
                              end_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              start_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              travel_mode: "DRIVING",
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              maneuver: "turn-left",
                            },
                          ],
                          duration: { text: "11 mins", value: 636 },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                        },
                        {
                          traffic_speed_entry: [],
                          duration: { text: "10 mins", value: 598 },
                          start_location: { lng: 51.5145907, lat: 25.2856124 },
                          distance: { text: "4.5 km", value: 4489 },
                          steps: [
                            {
                              start_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              end_location: {
                                lat: 25.2859334,
                                lng: 51.5165533,
                              },
                              duration: { text: "1 min", value: 73 },
                              distance: { value: 201, text: "0.2 km" },
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                              },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                            },
                            {
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                              end_location: { lng: 51.518743, lat: 25.2796568 },
                              distance: { text: "0.7 km", value: 735 },
                              duration: { value: 78, text: "1 min" },
                              start_location: {
                                lng: 51.5165533,
                                lat: 25.2859334,
                              },
                              maneuver: "turn-right",
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                              end_location: {
                                lat: 25.2769643,
                                lng: 51.5164061,
                              },
                              polyline: {
                                points:
                                  "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                              },
                              start_location: {
                                lat: 25.2796568,
                                lng: 51.518743,
                              },
                              distance: { value: 393, text: "0.4 km" },
                              travel_mode: "DRIVING",
                              duration: { value: 59, text: "1 min" },
                              maneuver: "turn-slight-right",
                            },
                            {
                              end_location: { lng: 51.500835, lat: 25.2663847 },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.5164061,
                                lat: 25.2769643,
                              },
                              maneuver: "merge",
                              distance: { value: 1963, text: "2.0 km" },
                              polyline: {
                                points:
                                  "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                              },
                              duration: { text: "4 mins", value: 229 },
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                              distance: { value: 428, text: "0.4 km" },
                              duration: { text: "1 min", value: 40 },
                              start_location: {
                                lng: 51.500835,
                                lat: 25.2663847,
                              },
                              end_location: { lat: 25.2643805, lng: 51.497229 },
                              maneuver: "turn-slight-right",
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                              },
                            },
                            {
                              start_location: {
                                lat: 25.2643805,
                                lng: 51.497229,
                              },
                              maneuver: "roundabout-right",
                              polyline: {
                                points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                              end_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              distance: { value: 182, text: "0.2 km" },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 22 },
                            },
                            {
                              maneuver: "turn-slight-right",
                              distance: { value: 25, text: "25 m" },
                              polyline: { points: "oueyCmzhyH]DKB" },
                              duration: { text: "1 min", value: 3 },
                              html_instructions:
                                "Slight <b>right</b> onto <b>Al Nasr St</b>",
                              end_location: {
                                lng: 51.4961765,
                                lat: 25.2658942,
                              },
                              start_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { text: "0.4 km", value: 389 },
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                              maneuver: "turn-slight-right",
                              travel_mode: "DRIVING",
                              duration: { value: 53, text: "1 min" },
                              polyline: {
                                points:
                                  "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                              },
                              end_location: { lat: 25.267547, lng: 51.4995317 },
                              start_location: {
                                lng: 51.4961765,
                                lat: 25.2658942,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              polyline: { points: "eafyCaoiyHQFiDdA" },
                              distance: { text: "0.1 km", value: 111 },
                              start_location: {
                                lat: 25.267547,
                                lng: 51.4995317,
                              },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohammed St</b>",
                              duration: { value: 22, text: "1 min" },
                              maneuver: "turn-left",
                              end_location: { lat: 25.2684856, lng: 51.499144 },
                            },
                            {
                              maneuver: "turn-left",
                              start_location: {
                                lat: 25.2684856,
                                lng: 51.499144,
                              },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              end_location: {
                                lng: 51.49855549999999,
                                lat: 25.2683307,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 19 },
                              distance: { value: 62, text: "62 m" },
                              polyline: { points: "agfyCsliyH\\vABNAJ" },
                            },
                          ],
                          start_address: "7GP7+6RG, Doha, Qatar",
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          via_waypoint: [],
                          end_address: "7F9X+C9V, Doha, Qatar",
                        },
                        {
                          traffic_speed_entry: [],
                          start_address: "7F9X+C9V, Doha, Qatar",
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          via_waypoint: [],
                          end_location: {
                            lng: 51.43657289999999,
                            lat: 25.2132031,
                          },
                          steps: [
                            {
                              polyline: { points: "affyC_iiyH@KCO]wA" },
                              duration: { value: 14, text: "1 min" },
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                              start_location: {
                                lng: 51.49855549999999,
                                lat: 25.2683307,
                              },
                              distance: { value: 62, text: "62 m" },
                              travel_mode: "DRIVING",
                            },
                            {
                              html_instructions:
                                "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                              start_location: {
                                lat: 25.2684856,
                                lng: 51.499144,
                              },
                              end_location: { lng: 51.499066, lat: 25.2687298 },
                              polyline: { points: "agfyCsliyHo@L" },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 9 },
                              distance: { text: "28 m", value: 28 },
                              maneuver: "turn-left",
                            },
                            {
                              start_location: {
                                lat: 25.2687298,
                                lng: 51.499066,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 154, text: "0.2 km" },
                              maneuver: "turn-right",
                              duration: { text: "1 min", value: 32 },
                              end_location: { lat: 25.2692232, lng: 51.500496 },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Baraha</b>",
                              polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                            },
                            {
                              distance: { value: 69, text: "69 m" },
                              end_location: { lat: 25.269806, lng: 51.500265 },
                              start_location: {
                                lng: 51.500496,
                                lat: 25.2692232,
                              },
                              polyline: { points: "skfyCcuiyH{@Ty@X" },
                              duration: { text: "1 min", value: 13 },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                              maneuver: "turn-left",
                            },
                            {
                              end_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              maneuver: "turn-right",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                              start_location: {
                                lng: 51.500265,
                                lat: 25.269806,
                              },
                              polyline: {
                                points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                              },
                              duration: { value: 29, text: "1 min" },
                              distance: { text: "0.2 km", value: 223 },
                              travel_mode: "DRIVING",
                            },
                            {
                              end_location: {
                                lat: 25.2679045,
                                lng: 51.5033973,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "roundabout-right",
                              start_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              duration: { value: 48, text: "1 min" },
                              polyline: {
                                points:
                                  "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                              distance: { value: 326, text: "0.3 km" },
                            },
                            {
                              html_instructions: "Merge onto <b>Salwa Rd</b>",
                              end_location: { lat: 25.2368244, lng: 51.45117 },
                              travel_mode: "DRIVING",
                              distance: { value: 6288, text: "6.3 km" },
                              start_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              duration: { value: 283, text: "5 mins" },
                              polyline: {
                                points:
                                  "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                              },
                              maneuver: "merge",
                            },
                            {
                              duration: { value: 10, text: "1 min" },
                              maneuver: "ramp-right",
                              start_location: {
                                lat: 25.2368244,
                                lng: 51.45117,
                              },
                              html_instructions:
                                'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                              travel_mode: "DRIVING",
                              distance: { value: 194, text: "0.2 km" },
                              polyline: { points: "ca`yCy``yHpAnDlAdD" },
                              end_location: {
                                lat: 25.2360177,
                                lng: 51.4494587,
                              },
                            },
                            {
                              maneuver: "merge",
                              html_instructions:
                                "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.4494587,
                                lat: 25.2360177,
                              },
                              distance: { value: 397, text: "0.4 km" },
                              duration: { text: "1 min", value: 33 },
                              end_location: {
                                lng: 51.4461502,
                                lat: 25.2340694,
                              },
                              polyline: {
                                points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              end_location: {
                                lng: 51.4456607,
                                lat: 25.2337942,
                              },
                              html_instructions:
                                "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              maneuver: "straight",
                              polyline: { points: "}o_yCma_yHP`@d@~@" },
                              distance: { text: "58 m", value: 58 },
                              duration: { value: 8, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2340694,
                                lng: 51.4461502,
                              },
                            },
                            {
                              distance: { text: "1.5 km", value: 1451 },
                              maneuver: "straight",
                              start_location: {
                                lat: 25.2337942,
                                lng: 51.4456607,
                              },
                              end_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "2 mins", value: 90 },
                              polyline: {
                                points:
                                  "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                              },
                              html_instructions:
                                'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                            },
                            {
                              html_instructions:
                                "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                              },
                              start_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              maneuver: "turn-slight-left",
                              distance: { value: 92, text: "92 m" },
                              end_location: {
                                lat: 25.2262036,
                                lng: 51.4328437,
                              },
                              duration: { text: "1 min", value: 10 },
                            },
                            {
                              polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                              end_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              start_location: {
                                lng: 51.4328437,
                                lat: 25.2262036,
                              },
                              html_instructions: "Continue straight",
                              duration: { value: 19, text: "1 min" },
                              maneuver: "straight",
                              distance: { value: 71, text: "71 m" },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { value: 813, text: "0.8 km" },
                              travel_mode: "DRIVING",
                              duration: { value: 72, text: "1 min" },
                              end_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                              html_instructions:
                                "Continue onto <b>E Industrial Rd</b>",
                              start_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              polyline: {
                                points:
                                  "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                              },
                            },
                            {
                              duration: { text: "1 min", value: 27 },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 2</b>",
                              distance: { value: 263, text: "0.3 km" },
                              end_location: {
                                lng: 51.43371639999999,
                                lat: 25.2182661,
                              },
                              start_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                              polyline: {
                                points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                              },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { value: 682, text: "0.7 km" },
                              start_location: {
                                lat: 25.2182661,
                                lng: 51.43371639999999,
                              },
                              end_location: {
                                lng: 51.4366206,
                                lat: 25.2135618,
                              },
                              duration: { value: 78, text: "1 min" },
                              polyline: {
                                points:
                                  "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                              maneuver: "roundabout-right",
                            },
                            {
                              maneuver: "turn-right",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 8</b>",
                              polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                              end_location: {
                                lat: 25.2133289,
                                lng: 51.4364944,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 12 },
                              start_location: {
                                lng: 51.4366206,
                                lat: 25.2135618,
                              },
                              distance: { value: 29, text: "29 m" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              start_location: {
                                lat: 25.2133289,
                                lng: 51.4364944,
                              },
                              polyline: { points: "in{xCae}xHXO" },
                              distance: { text: "16 m", value: 16 },
                              html_instructions: "Turn <b>left</b>",
                              maneuver: "turn-left",
                              end_location: {
                                lng: 51.43657289999999,
                                lat: 25.2132031,
                              },
                            },
                          ],
                          duration: { value: 795, text: "13 mins" },
                          distance: { value: 11216, text: "11.2 km" },
                          end_address: "28 Street 8, Ar Rayyan, Qatar",
                        },
                      ],
                    },
                  ],
                },
                completedAt: 1707942351003,
                createdAt: 1707942285310,
                owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                destination: {
                  coordinates: {
                    longitude: 51.43657469999999,
                    latitude: 25.213204,
                  },
                  address: "Street 8, Doha, Qatar",
                  type: "corporate-branch",
                  id: "9VZwCrYZfl03ob5FrD4F.0",
                  geohash: "thkx6myvx1",
                },
                co2Amount: 12.612562888268984,
                origin: {
                  coordinates: { longitude: 51.489361, latitude: 25.282848 },
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                },
                car: {
                  year: 2021,
                  type: "carbon-emitting",
                  model: "LAND CRUSER WAGON 4WD",
                  make: "Toyota",
                  co2_p_km: 0.3379843740994449,
                  seat: 3,
                },
                rules: [
                  "No smoking",
                  "No eating or drinking",
                  "Keep off the AC",
                ],
                preferences: {
                  female_passengers_only: null,
                  verified_passengers_only: null,
                },
                skin: null,
                status: "completed",
                passengersArray: [
                  {
                    credits: 27.8,
                    username: "abcd",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    seat: 0,
                    id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                    name: "Jassim",
                    co2Amount: 4.313694566631215,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                    location: {
                      geohash: "thkxgdt7m0",
                      coordinates: {
                        latitude: 25.282848,
                        longitude: 51.489361,
                      },
                      address: "858, الدوحة، Qatar",
                    },
                    wallet_id: "spencer_carbon.testnet",
                  },
                  {
                    status: "picked-up",
                    id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                    seat: 2,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                    username: "sarah",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    wallet_id: "sarah_carbon.testnet",
                    credits: 13,
                    co2Amount: 3.7908327398993733,
                    name: "Faisal",
                    location: {
                      coordinates: {
                        longitude: 51.498513,
                        latitude: 25.268246,
                      },
                      address: "7F9X+C9V, Doha, Qatar",
                      geohash: "thkxezun4q",
                    },
                  },
                  {
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    status: "picked-up",
                    location: {
                      geohash: "thkxu5pfc8",
                      address: "7GP7+6RG, Doha, Qatar",
                      coordinates: {
                        longitude: 51.514615,
                        latitude: 25.285509,
                      },
                    },
                    username: "michaelscott",
                    id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                    credits: 13.9,
                    seat: 1,
                    name: "Ahmed",
                    wallet_id: "sofia_carbon.testnet",
                    co2Amount: 4.508035581738396,
                  },
                ],
              },
              type: "carpool_passenger",
              corporate: "9VZwCrYZfl03ob5FrD4F",
            },
            {
              userId: "user_id",
              name: "Faisal",
              username: "sarah",
              location: {
                coordinates: { longitude: 51.498513, latitude: 25.268246 },
                address: "7F9X+C9V, Doha, Qatar",
                geohash: "thkxezun4q",
              },
              credits: 13,
              co2Amount: 3.7908327398993733,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
              seat: 2,
              id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
              wallet_id: "sarah_carbon.testnet",
              status: "picked-up",
              carpool: {
                id: "8292057714690",
                directions: {
                  status: "OK",
                  geocoded_waypoints: [
                    {
                      geocoder_status: "OK",
                      types: [
                        "establishment",
                        "moving_company",
                        "point_of_interest",
                      ],
                      place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                    },
                    {
                      types: [
                        "establishment",
                        "general_contractor",
                        "point_of_interest",
                      ],
                      geocoder_status: "OK",
                      place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                    },
                    {
                      place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                      geocoder_status: "OK",
                      types: ["premise"],
                    },
                    {
                      types: ["street_address"],
                      geocoder_status: "OK",
                      place_id:
                        "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                    },
                  ],
                  routes: [
                    {
                      overview_polyline: {
                        points:
                          "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                      },
                      waypoint_order: [0, 1],
                      bounds: {
                        northeast: { lat: 25.291958, lng: 51.518743 },
                        southwest: { lat: 25.2132031, lng: 51.4319062 },
                      },
                      warnings: [],
                      copyrights: "Map data ©2024 Google",
                      summary: "Al Rayyan Rd",
                      legs: [
                        {
                          distance: { value: 5407, text: "5.4 km" },
                          start_address: "858, الدوحة، Qatar",
                          via_waypoint: [],
                          traffic_speed_entry: [],
                          start_location: { lng: 51.4906083, lat: 25.2827107 },
                          end_address: "7GP7+6RG, Doha, Qatar",
                          steps: [
                            {
                              polyline: { points: "}_iyCiwgyHz@B" },
                              distance: { value: 33, text: "33 m" },
                              end_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                              start_location: {
                                lng: 51.4906083,
                                lat: 25.2827107,
                              },
                              duration: { value: 4, text: "1 min" },
                            },
                            {
                              end_location: {
                                lat: 25.2801532,
                                lng: 51.4871118,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 574, text: "0.6 km" },
                              start_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              html_instructions:
                                "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                              polyline: {
                                points:
                                  "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                              },
                              duration: { text: "1 min", value: 49 },
                            },
                            {
                              start_location: {
                                lng: 51.4871118,
                                lat: 25.2801532,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 426, text: "0.4 km" },
                              polyline: {
                                points:
                                  "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                              },
                              maneuver: "turn-left",
                              end_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                              html_instructions: "Turn <b>left</b>",
                              duration: { value: 44, text: "1 min" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              maneuver: "straight",
                              html_instructions:
                                "Continue straight onto <b>Al Sadd St</b>",
                              distance: { value: 93, text: "93 m" },
                              end_location: {
                                lng: 51.4917143,
                                lat: 25.2802165,
                              },
                              polyline: { points: "cphyCoxgyHEaBAsA" },
                              start_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                            },
                            {
                              end_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                              duration: { text: "1 min", value: 71 },
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                              },
                              html_instructions: "Slight <b>left</b>",
                              distance: { text: "0.2 km", value: 236 },
                              maneuver: "turn-slight-left",
                              start_location: {
                                lng: 51.4917143,
                                lat: 25.2802165,
                              },
                            },
                            {
                              polyline: {
                                points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                              },
                              duration: { text: "1 min", value: 38 },
                              end_location: { lng: 51.4947254, lat: 25.281604 },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              distance: { text: "0.2 km", value: 163 },
                              start_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                            },
                            {
                              duration: { value: 76, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.4947254,
                                lat: 25.281604,
                              },
                              html_instructions:
                                "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                              polyline: {
                                points:
                                  "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                              },
                              maneuver: "keep-left",
                              distance: { text: "1.1 km", value: 1109 },
                              end_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                            },
                            {
                              duration: { text: "3 mins", value: 209 },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                              end_location: {
                                lat: 25.2893902,
                                lng: 51.51516729999999,
                              },
                              distance: { value: 1922, text: "1.9 km" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                              },
                            },
                            {
                              distance: { text: "0.1 km", value: 144 },
                              maneuver: "turn-right",
                              duration: { value: 15, text: "1 min" },
                              start_location: {
                                lng: 51.51516729999999,
                                lat: 25.2893902,
                              },
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej</b>",
                              end_location: {
                                lng: 51.51591370000001,
                                lat: 25.2884741,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              duration: { text: "1 min", value: 11 },
                              polyline: {
                                points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                              },
                              distance: { value: 122, text: "0.1 km" },
                              html_instructions:
                                'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                              end_location: { lat: 25.2874383, lng: 51.516228 },
                            },
                            {
                              duration: { text: "1 min", value: 21 },
                              end_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              start_location: {
                                lng: 51.516228,
                                lat: 25.2874383,
                              },
                              polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                              html_instructions:
                                'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                              travel_mode: "DRIVING",
                              maneuver: "turn-slight-right",
                              distance: { value: 283, text: "0.3 km" },
                            },
                            {
                              start_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              duration: { value: 32, text: "1 min" },
                              travel_mode: "DRIVING",
                              end_location: {
                                lng: 51.5155496,
                                lat: 25.2848165,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                              distance: { value: 125, text: "0.1 km" },
                              polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                              maneuver: "turn-right",
                            },
                            {
                              end_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              maneuver: "turn-right",
                              duration: { text: "1 min", value: 21 },
                              distance: { value: 74, text: "74 m" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                              },
                              start_location: {
                                lat: 25.2848165,
                                lng: 51.5155496,
                              },
                            },
                            {
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              start_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              end_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              polyline: { points: "uniyC_plyHqBJ" },
                              duration: { text: "1 min", value: 23 },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              distance: { value: 64, text: "64 m" },
                            },
                            {
                              polyline: { points: "griyCsolyH?p@DZ" },
                              duration: { text: "1 min", value: 14 },
                              distance: { text: "39 m", value: 39 },
                              end_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              start_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              travel_mode: "DRIVING",
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              maneuver: "turn-left",
                            },
                          ],
                          duration: { text: "11 mins", value: 636 },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                        },
                        {
                          traffic_speed_entry: [],
                          duration: { text: "10 mins", value: 598 },
                          start_location: { lng: 51.5145907, lat: 25.2856124 },
                          distance: { text: "4.5 km", value: 4489 },
                          steps: [
                            {
                              start_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              end_location: {
                                lat: 25.2859334,
                                lng: 51.5165533,
                              },
                              duration: { text: "1 min", value: 73 },
                              distance: { value: 201, text: "0.2 km" },
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                              },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                            },
                            {
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                              end_location: { lng: 51.518743, lat: 25.2796568 },
                              distance: { text: "0.7 km", value: 735 },
                              duration: { value: 78, text: "1 min" },
                              start_location: {
                                lng: 51.5165533,
                                lat: 25.2859334,
                              },
                              maneuver: "turn-right",
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                              end_location: {
                                lat: 25.2769643,
                                lng: 51.5164061,
                              },
                              polyline: {
                                points:
                                  "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                              },
                              start_location: {
                                lat: 25.2796568,
                                lng: 51.518743,
                              },
                              distance: { value: 393, text: "0.4 km" },
                              travel_mode: "DRIVING",
                              duration: { value: 59, text: "1 min" },
                              maneuver: "turn-slight-right",
                            },
                            {
                              end_location: { lng: 51.500835, lat: 25.2663847 },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.5164061,
                                lat: 25.2769643,
                              },
                              maneuver: "merge",
                              distance: { value: 1963, text: "2.0 km" },
                              polyline: {
                                points:
                                  "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                              },
                              duration: { text: "4 mins", value: 229 },
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                              distance: { value: 428, text: "0.4 km" },
                              duration: { text: "1 min", value: 40 },
                              start_location: {
                                lng: 51.500835,
                                lat: 25.2663847,
                              },
                              end_location: { lat: 25.2643805, lng: 51.497229 },
                              maneuver: "turn-slight-right",
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                              },
                            },
                            {
                              start_location: {
                                lat: 25.2643805,
                                lng: 51.497229,
                              },
                              maneuver: "roundabout-right",
                              polyline: {
                                points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                              end_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              distance: { value: 182, text: "0.2 km" },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 22 },
                            },
                            {
                              maneuver: "turn-slight-right",
                              distance: { value: 25, text: "25 m" },
                              polyline: { points: "oueyCmzhyH]DKB" },
                              duration: { text: "1 min", value: 3 },
                              html_instructions:
                                "Slight <b>right</b> onto <b>Al Nasr St</b>",
                              end_location: {
                                lng: 51.4961765,
                                lat: 25.2658942,
                              },
                              start_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { text: "0.4 km", value: 389 },
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                              maneuver: "turn-slight-right",
                              travel_mode: "DRIVING",
                              duration: { value: 53, text: "1 min" },
                              polyline: {
                                points:
                                  "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                              },
                              end_location: { lat: 25.267547, lng: 51.4995317 },
                              start_location: {
                                lng: 51.4961765,
                                lat: 25.2658942,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              polyline: { points: "eafyCaoiyHQFiDdA" },
                              distance: { text: "0.1 km", value: 111 },
                              start_location: {
                                lat: 25.267547,
                                lng: 51.4995317,
                              },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohammed St</b>",
                              duration: { value: 22, text: "1 min" },
                              maneuver: "turn-left",
                              end_location: { lat: 25.2684856, lng: 51.499144 },
                            },
                            {
                              maneuver: "turn-left",
                              start_location: {
                                lat: 25.2684856,
                                lng: 51.499144,
                              },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              end_location: {
                                lng: 51.49855549999999,
                                lat: 25.2683307,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 19 },
                              distance: { value: 62, text: "62 m" },
                              polyline: { points: "agfyCsliyH\\vABNAJ" },
                            },
                          ],
                          start_address: "7GP7+6RG, Doha, Qatar",
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          via_waypoint: [],
                          end_address: "7F9X+C9V, Doha, Qatar",
                        },
                        {
                          traffic_speed_entry: [],
                          start_address: "7F9X+C9V, Doha, Qatar",
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          via_waypoint: [],
                          end_location: {
                            lng: 51.43657289999999,
                            lat: 25.2132031,
                          },
                          steps: [
                            {
                              polyline: { points: "affyC_iiyH@KCO]wA" },
                              duration: { value: 14, text: "1 min" },
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                              start_location: {
                                lng: 51.49855549999999,
                                lat: 25.2683307,
                              },
                              distance: { value: 62, text: "62 m" },
                              travel_mode: "DRIVING",
                            },
                            {
                              html_instructions:
                                "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                              start_location: {
                                lat: 25.2684856,
                                lng: 51.499144,
                              },
                              end_location: { lng: 51.499066, lat: 25.2687298 },
                              polyline: { points: "agfyCsliyHo@L" },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 9 },
                              distance: { text: "28 m", value: 28 },
                              maneuver: "turn-left",
                            },
                            {
                              start_location: {
                                lat: 25.2687298,
                                lng: 51.499066,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 154, text: "0.2 km" },
                              maneuver: "turn-right",
                              duration: { text: "1 min", value: 32 },
                              end_location: { lat: 25.2692232, lng: 51.500496 },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Baraha</b>",
                              polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                            },
                            {
                              distance: { value: 69, text: "69 m" },
                              end_location: { lat: 25.269806, lng: 51.500265 },
                              start_location: {
                                lng: 51.500496,
                                lat: 25.2692232,
                              },
                              polyline: { points: "skfyCcuiyH{@Ty@X" },
                              duration: { text: "1 min", value: 13 },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                              maneuver: "turn-left",
                            },
                            {
                              end_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              maneuver: "turn-right",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                              start_location: {
                                lng: 51.500265,
                                lat: 25.269806,
                              },
                              polyline: {
                                points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                              },
                              duration: { value: 29, text: "1 min" },
                              distance: { text: "0.2 km", value: 223 },
                              travel_mode: "DRIVING",
                            },
                            {
                              end_location: {
                                lat: 25.2679045,
                                lng: 51.5033973,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "roundabout-right",
                              start_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              duration: { value: 48, text: "1 min" },
                              polyline: {
                                points:
                                  "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                              distance: { value: 326, text: "0.3 km" },
                            },
                            {
                              html_instructions: "Merge onto <b>Salwa Rd</b>",
                              end_location: { lat: 25.2368244, lng: 51.45117 },
                              travel_mode: "DRIVING",
                              distance: { value: 6288, text: "6.3 km" },
                              start_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              duration: { value: 283, text: "5 mins" },
                              polyline: {
                                points:
                                  "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                              },
                              maneuver: "merge",
                            },
                            {
                              duration: { value: 10, text: "1 min" },
                              maneuver: "ramp-right",
                              start_location: {
                                lat: 25.2368244,
                                lng: 51.45117,
                              },
                              html_instructions:
                                'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                              travel_mode: "DRIVING",
                              distance: { value: 194, text: "0.2 km" },
                              polyline: { points: "ca`yCy``yHpAnDlAdD" },
                              end_location: {
                                lat: 25.2360177,
                                lng: 51.4494587,
                              },
                            },
                            {
                              maneuver: "merge",
                              html_instructions:
                                "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.4494587,
                                lat: 25.2360177,
                              },
                              distance: { value: 397, text: "0.4 km" },
                              duration: { text: "1 min", value: 33 },
                              end_location: {
                                lng: 51.4461502,
                                lat: 25.2340694,
                              },
                              polyline: {
                                points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              end_location: {
                                lng: 51.4456607,
                                lat: 25.2337942,
                              },
                              html_instructions:
                                "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              maneuver: "straight",
                              polyline: { points: "}o_yCma_yHP`@d@~@" },
                              distance: { text: "58 m", value: 58 },
                              duration: { value: 8, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2340694,
                                lng: 51.4461502,
                              },
                            },
                            {
                              distance: { text: "1.5 km", value: 1451 },
                              maneuver: "straight",
                              start_location: {
                                lat: 25.2337942,
                                lng: 51.4456607,
                              },
                              end_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "2 mins", value: 90 },
                              polyline: {
                                points:
                                  "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                              },
                              html_instructions:
                                'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                            },
                            {
                              html_instructions:
                                "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                              },
                              start_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              maneuver: "turn-slight-left",
                              distance: { value: 92, text: "92 m" },
                              end_location: {
                                lat: 25.2262036,
                                lng: 51.4328437,
                              },
                              duration: { text: "1 min", value: 10 },
                            },
                            {
                              polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                              end_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              start_location: {
                                lng: 51.4328437,
                                lat: 25.2262036,
                              },
                              html_instructions: "Continue straight",
                              duration: { value: 19, text: "1 min" },
                              maneuver: "straight",
                              distance: { value: 71, text: "71 m" },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { value: 813, text: "0.8 km" },
                              travel_mode: "DRIVING",
                              duration: { value: 72, text: "1 min" },
                              end_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                              html_instructions:
                                "Continue onto <b>E Industrial Rd</b>",
                              start_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              polyline: {
                                points:
                                  "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                              },
                            },
                            {
                              duration: { text: "1 min", value: 27 },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 2</b>",
                              distance: { value: 263, text: "0.3 km" },
                              end_location: {
                                lng: 51.43371639999999,
                                lat: 25.2182661,
                              },
                              start_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                              polyline: {
                                points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                              },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { value: 682, text: "0.7 km" },
                              start_location: {
                                lat: 25.2182661,
                                lng: 51.43371639999999,
                              },
                              end_location: {
                                lng: 51.4366206,
                                lat: 25.2135618,
                              },
                              duration: { value: 78, text: "1 min" },
                              polyline: {
                                points:
                                  "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                              },
                              html_instructions:
                                "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                              maneuver: "roundabout-right",
                            },
                            {
                              maneuver: "turn-right",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 8</b>",
                              polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                              end_location: {
                                lat: 25.2133289,
                                lng: 51.4364944,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 12 },
                              start_location: {
                                lng: 51.4366206,
                                lat: 25.2135618,
                              },
                              distance: { value: 29, text: "29 m" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              start_location: {
                                lat: 25.2133289,
                                lng: 51.4364944,
                              },
                              polyline: { points: "in{xCae}xHXO" },
                              distance: { text: "16 m", value: 16 },
                              html_instructions: "Turn <b>left</b>",
                              maneuver: "turn-left",
                              end_location: {
                                lng: 51.43657289999999,
                                lat: 25.2132031,
                              },
                            },
                          ],
                          duration: { value: 795, text: "13 mins" },
                          distance: { value: 11216, text: "11.2 km" },
                          end_address: "28 Street 8, Ar Rayyan, Qatar",
                        },
                      ],
                    },
                  ],
                },
                completedAt: 1707942351003,
                createdAt: 1707942285310,
                owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                destination: {
                  coordinates: {
                    longitude: 51.43657469999999,
                    latitude: 25.213204,
                  },
                  address: "Street 8, Doha, Qatar",
                  type: "corporate-branch",
                  id: "9VZwCrYZfl03ob5FrD4F.0",
                  geohash: "thkx6myvx1",
                },
                co2Amount: 12.612562888268984,
                origin: {
                  coordinates: { longitude: 51.489361, latitude: 25.282848 },
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                },
                car: {
                  year: 2021,
                  type: "carbon-emitting",
                  model: "LAND CRUSER WAGON 4WD",
                  make: "Toyota",
                  co2_p_km: 0.3379843740994449,
                  seat: 3,
                },
                rules: [
                  "No smoking",
                  "No eating or drinking",
                  "Keep off the AC",
                ],
                preferences: {
                  female_passengers_only: null,
                  verified_passengers_only: null,
                },
                skin: null,
                status: "completed",
                passengersArray: [
                  {
                    userId: "user_id",
                    name: "Jassim",
                    username: "abcd",
                    location: {
                      geohash: "thkxgdt7m0",
                      coordinates: {
                        latitude: 25.282848,
                        longitude: 51.489361,
                      },
                      address: "858, الدوحة، Qatar",
                    },
                    credits: 27.8,
                    co2Amount: 4.313694566631215,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                    seat: 0,
                    id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                    wallet_id: "spencer_carbon.testnet",
                    carpool: {
                      id: "8292057714690",
                      directions: {
                        status: "OK",
                        geocoded_waypoints: [
                          {
                            geocoder_status: "OK",
                            types: [
                              "establishment",
                              "moving_company",
                              "point_of_interest",
                            ],
                            place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                          },
                          {
                            types: [
                              "establishment",
                              "general_contractor",
                              "point_of_interest",
                            ],
                            geocoder_status: "OK",
                            place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                          },
                          {
                            place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                            geocoder_status: "OK",
                            types: ["premise"],
                          },
                          {
                            types: ["street_address"],
                            geocoder_status: "OK",
                            place_id:
                              "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                          },
                        ],
                        routes: [
                          {
                            overview_polyline: {
                              points:
                                "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                            },
                            waypoint_order: [0, 1],
                            bounds: {
                              northeast: { lat: 25.291958, lng: 51.518743 },
                              southwest: { lat: 25.2132031, lng: 51.4319062 },
                            },
                            warnings: [],
                            copyrights: "Map data ©2024 Google",
                            summary: "Al Rayyan Rd",
                            legs: [
                              {
                                distance: { value: 5407, text: "5.4 km" },
                                start_address: "858, الدوحة، Qatar",
                                via_waypoint: [],
                                traffic_speed_entry: [],
                                start_location: {
                                  lng: 51.4906083,
                                  lat: 25.2827107,
                                },
                                end_address: "7GP7+6RG, Doha, Qatar",
                                steps: [
                                  {
                                    polyline: { points: "}_iyCiwgyHz@B" },
                                    distance: { value: 33, text: "33 m" },
                                    end_location: {
                                      lng: 51.4905936,
                                      lat: 25.2824136,
                                    },
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                    start_location: {
                                      lng: 51.4906083,
                                      lat: 25.2827107,
                                    },
                                    duration: { value: 4, text: "1 min" },
                                  },
                                  {
                                    end_location: {
                                      lat: 25.2801532,
                                      lng: 51.4871118,
                                    },
                                    travel_mode: "DRIVING",
                                    distance: { value: 574, text: "0.6 km" },
                                    start_location: {
                                      lng: 51.4905936,
                                      lat: 25.2824136,
                                    },
                                    html_instructions:
                                      "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                    polyline: {
                                      points:
                                        "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                    },
                                    duration: { text: "1 min", value: 49 },
                                  },
                                  {
                                    start_location: {
                                      lng: 51.4871118,
                                      lat: 25.2801532,
                                    },
                                    travel_mode: "DRIVING",
                                    distance: { value: 426, text: "0.4 km" },
                                    polyline: {
                                      points:
                                        "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                    },
                                    maneuver: "turn-left",
                                    end_location: {
                                      lat: 25.2801774,
                                      lng: 51.49079529999999,
                                    },
                                    html_instructions: "Turn <b>left</b>",
                                    duration: { value: 44, text: "1 min" },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    duration: { value: 8, text: "1 min" },
                                    maneuver: "straight",
                                    html_instructions:
                                      "Continue straight onto <b>Al Sadd St</b>",
                                    distance: { value: 93, text: "93 m" },
                                    end_location: {
                                      lng: 51.4917143,
                                      lat: 25.2802165,
                                    },
                                    polyline: { points: "cphyCoxgyHEaBAsA" },
                                    start_location: {
                                      lat: 25.2801774,
                                      lng: 51.49079529999999,
                                    },
                                  },
                                  {
                                    end_location: {
                                      lat: 25.2804496,
                                      lng: 51.4940386,
                                    },
                                    duration: { text: "1 min", value: 71 },
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                    },
                                    html_instructions: "Slight <b>left</b>",
                                    distance: { text: "0.2 km", value: 236 },
                                    maneuver: "turn-slight-left",
                                    start_location: {
                                      lng: 51.4917143,
                                      lat: 25.2802165,
                                    },
                                  },
                                  {
                                    polyline: {
                                      points:
                                        "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                    },
                                    duration: { text: "1 min", value: 38 },
                                    end_location: {
                                      lng: 51.4947254,
                                      lat: 25.281604,
                                    },
                                    html_instructions:
                                      'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                                    travel_mode: "DRIVING",
                                    maneuver: "turn-left",
                                    distance: { text: "0.2 km", value: 163 },
                                    start_location: {
                                      lat: 25.2804496,
                                      lng: 51.4940386,
                                    },
                                  },
                                  {
                                    duration: { value: 76, text: "1 min" },
                                    travel_mode: "DRIVING",
                                    start_location: {
                                      lng: 51.4947254,
                                      lat: 25.281604,
                                    },
                                    html_instructions:
                                      "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                    polyline: {
                                      points:
                                        "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                    },
                                    maneuver: "keep-left",
                                    distance: { text: "1.1 km", value: 1109 },
                                    end_location: {
                                      lat: 25.2914195,
                                      lng: 51.4965617,
                                    },
                                  },
                                  {
                                    duration: { text: "3 mins", value: 209 },
                                    travel_mode: "DRIVING",
                                    start_location: {
                                      lat: 25.2914195,
                                      lng: 51.4965617,
                                    },
                                    end_location: {
                                      lat: 25.2893902,
                                      lng: 51.51516729999999,
                                    },
                                    distance: { value: 1922, text: "1.9 km" },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                    maneuver: "turn-right",
                                    polyline: {
                                      points:
                                        "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                    },
                                  },
                                  {
                                    distance: { text: "0.1 km", value: 144 },
                                    maneuver: "turn-right",
                                    duration: { value: 15, text: "1 min" },
                                    start_location: {
                                      lng: 51.51516729999999,
                                      lat: 25.2893902,
                                    },
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points:
                                        "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                    end_location: {
                                      lng: 51.51591370000001,
                                      lat: 25.2884741,
                                    },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    start_location: {
                                      lat: 25.2884741,
                                      lng: 51.51591370000001,
                                    },
                                    duration: { text: "1 min", value: 11 },
                                    polyline: {
                                      points:
                                        "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                    },
                                    distance: { value: 122, text: "0.1 km" },
                                    html_instructions:
                                      'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                                    end_location: {
                                      lat: 25.2874383,
                                      lng: 51.516228,
                                    },
                                  },
                                  {
                                    duration: { text: "1 min", value: 21 },
                                    end_location: {
                                      lng: 51.51678399999999,
                                      lat: 25.2849434,
                                    },
                                    start_location: {
                                      lng: 51.516228,
                                      lat: 25.2874383,
                                    },
                                    polyline: {
                                      points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                    },
                                    html_instructions:
                                      'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                                    travel_mode: "DRIVING",
                                    maneuver: "turn-slight-right",
                                    distance: { value: 283, text: "0.3 km" },
                                  },
                                  {
                                    start_location: {
                                      lng: 51.51678399999999,
                                      lat: 25.2849434,
                                    },
                                    duration: { value: 32, text: "1 min" },
                                    travel_mode: "DRIVING",
                                    end_location: {
                                      lng: 51.5155496,
                                      lat: 25.2848165,
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                    distance: { value: 125, text: "0.1 km" },
                                    polyline: {
                                      points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                    },
                                    maneuver: "turn-right",
                                  },
                                  {
                                    end_location: {
                                      lat: 25.2850716,
                                      lng: 51.5150352,
                                    },
                                    maneuver: "turn-right",
                                    duration: { text: "1 min", value: 21 },
                                    distance: { value: 74, text: "74 m" },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points:
                                        "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                    },
                                    start_location: {
                                      lat: 25.2848165,
                                      lng: 51.5155496,
                                    },
                                  },
                                  {
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                    start_location: {
                                      lat: 25.2850716,
                                      lng: 51.5150352,
                                    },
                                    end_location: {
                                      lat: 25.2856417,
                                      lng: 51.51497639999999,
                                    },
                                    polyline: { points: "uniyC_plyHqBJ" },
                                    duration: { text: "1 min", value: 23 },
                                    maneuver: "turn-right",
                                    travel_mode: "DRIVING",
                                    distance: { value: 64, text: "64 m" },
                                  },
                                  {
                                    polyline: { points: "griyCsolyH?p@DZ" },
                                    duration: { text: "1 min", value: 14 },
                                    distance: { text: "39 m", value: 39 },
                                    end_location: {
                                      lng: 51.5145907,
                                      lat: 25.2856124,
                                    },
                                    start_location: {
                                      lat: 25.2856417,
                                      lng: 51.51497639999999,
                                    },
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                    maneuver: "turn-left",
                                  },
                                ],
                                duration: { text: "11 mins", value: 636 },
                                end_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                              },
                              {
                                traffic_speed_entry: [],
                                duration: { text: "10 mins", value: 598 },
                                start_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                distance: { text: "4.5 km", value: 4489 },
                                steps: [
                                  {
                                    start_location: {
                                      lng: 51.5145907,
                                      lat: 25.2856124,
                                    },
                                    end_location: {
                                      lat: 25.2859334,
                                      lng: 51.5165533,
                                    },
                                    duration: { text: "1 min", value: 73 },
                                    distance: { value: 201, text: "0.2 km" },
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points:
                                        "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                    },
                                    html_instructions:
                                      "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points:
                                        "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                    end_location: {
                                      lng: 51.518743,
                                      lat: 25.2796568,
                                    },
                                    distance: { text: "0.7 km", value: 735 },
                                    duration: { value: 78, text: "1 min" },
                                    start_location: {
                                      lng: 51.5165533,
                                      lat: 25.2859334,
                                    },
                                    maneuver: "turn-right",
                                  },
                                  {
                                    html_instructions:
                                      "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                    end_location: {
                                      lat: 25.2769643,
                                      lng: 51.5164061,
                                    },
                                    polyline: {
                                      points:
                                        "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                    },
                                    start_location: {
                                      lat: 25.2796568,
                                      lng: 51.518743,
                                    },
                                    distance: { value: 393, text: "0.4 km" },
                                    travel_mode: "DRIVING",
                                    duration: { value: 59, text: "1 min" },
                                    maneuver: "turn-slight-right",
                                  },
                                  {
                                    end_location: {
                                      lng: 51.500835,
                                      lat: 25.2663847,
                                    },
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                                    start_location: {
                                      lng: 51.5164061,
                                      lat: 25.2769643,
                                    },
                                    maneuver: "merge",
                                    distance: { value: 1963, text: "2.0 km" },
                                    polyline: {
                                      points:
                                        "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                    },
                                    duration: { text: "4 mins", value: 229 },
                                  },
                                  {
                                    html_instructions:
                                      "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                    distance: { value: 428, text: "0.4 km" },
                                    duration: { text: "1 min", value: 40 },
                                    start_location: {
                                      lng: 51.500835,
                                      lat: 25.2663847,
                                    },
                                    end_location: {
                                      lat: 25.2643805,
                                      lng: 51.497229,
                                    },
                                    maneuver: "turn-slight-right",
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points:
                                        "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                    },
                                  },
                                  {
                                    start_location: {
                                      lat: 25.2643805,
                                      lng: 51.497229,
                                    },
                                    maneuver: "roundabout-right",
                                    polyline: {
                                      points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                    },
                                    html_instructions:
                                      "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                    end_location: {
                                      lat: 25.2656775,
                                      lng: 51.4962302,
                                    },
                                    distance: { value: 182, text: "0.2 km" },
                                    travel_mode: "DRIVING",
                                    duration: { text: "1 min", value: 22 },
                                  },
                                  {
                                    maneuver: "turn-slight-right",
                                    distance: { value: 25, text: "25 m" },
                                    polyline: { points: "oueyCmzhyH]DKB" },
                                    duration: { text: "1 min", value: 3 },
                                    html_instructions:
                                      "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                    end_location: {
                                      lng: 51.4961765,
                                      lat: 25.2658942,
                                    },
                                    start_location: {
                                      lat: 25.2656775,
                                      lng: 51.4962302,
                                    },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    distance: { text: "0.4 km", value: 389 },
                                    html_instructions:
                                      "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                    maneuver: "turn-slight-right",
                                    travel_mode: "DRIVING",
                                    duration: { value: 53, text: "1 min" },
                                    polyline: {
                                      points:
                                        "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                    },
                                    end_location: {
                                      lat: 25.267547,
                                      lng: 51.4995317,
                                    },
                                    start_location: {
                                      lng: 51.4961765,
                                      lat: 25.2658942,
                                    },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    polyline: { points: "eafyCaoiyHQFiDdA" },
                                    distance: { text: "0.1 km", value: 111 },
                                    start_location: {
                                      lat: 25.267547,
                                      lng: 51.4995317,
                                    },
                                    html_instructions:
                                      "Turn <b>left</b> onto <b>Mohammed St</b>",
                                    duration: { value: 22, text: "1 min" },
                                    maneuver: "turn-left",
                                    end_location: {
                                      lat: 25.2684856,
                                      lng: 51.499144,
                                    },
                                  },
                                  {
                                    maneuver: "turn-left",
                                    start_location: {
                                      lat: 25.2684856,
                                      lng: 51.499144,
                                    },
                                    html_instructions:
                                      'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                    end_location: {
                                      lng: 51.49855549999999,
                                      lat: 25.2683307,
                                    },
                                    travel_mode: "DRIVING",
                                    duration: { text: "1 min", value: 19 },
                                    distance: { value: 62, text: "62 m" },
                                    polyline: { points: "agfyCsliyH\\vABNAJ" },
                                  },
                                ],
                                start_address: "7GP7+6RG, Doha, Qatar",
                                end_location: {
                                  lat: 25.2683307,
                                  lng: 51.49855549999999,
                                },
                                via_waypoint: [],
                                end_address: "7F9X+C9V, Doha, Qatar",
                              },
                              {
                                traffic_speed_entry: [],
                                start_address: "7F9X+C9V, Doha, Qatar",
                                start_location: {
                                  lng: 51.49855549999999,
                                  lat: 25.2683307,
                                },
                                via_waypoint: [],
                                end_location: {
                                  lng: 51.43657289999999,
                                  lat: 25.2132031,
                                },
                                steps: [
                                  {
                                    polyline: { points: "affyC_iiyH@KCO]wA" },
                                    duration: { value: 14, text: "1 min" },
                                    end_location: {
                                      lng: 51.499144,
                                      lat: 25.2684856,
                                    },
                                    html_instructions:
                                      "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                                    start_location: {
                                      lng: 51.49855549999999,
                                      lat: 25.2683307,
                                    },
                                    distance: { value: 62, text: "62 m" },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    html_instructions:
                                      "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                    start_location: {
                                      lat: 25.2684856,
                                      lng: 51.499144,
                                    },
                                    end_location: {
                                      lng: 51.499066,
                                      lat: 25.2687298,
                                    },
                                    polyline: { points: "agfyCsliyHo@L" },
                                    travel_mode: "DRIVING",
                                    duration: { text: "1 min", value: 9 },
                                    distance: { text: "28 m", value: 28 },
                                    maneuver: "turn-left",
                                  },
                                  {
                                    start_location: {
                                      lat: 25.2687298,
                                      lng: 51.499066,
                                    },
                                    travel_mode: "DRIVING",
                                    distance: { value: 154, text: "0.2 km" },
                                    maneuver: "turn-right",
                                    duration: { text: "1 min", value: 32 },
                                    end_location: {
                                      lat: 25.2692232,
                                      lng: 51.500496,
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Baraha</b>",
                                    polyline: {
                                      points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                    },
                                  },
                                  {
                                    distance: { value: 69, text: "69 m" },
                                    end_location: {
                                      lat: 25.269806,
                                      lng: 51.500265,
                                    },
                                    start_location: {
                                      lng: 51.500496,
                                      lat: 25.2692232,
                                    },
                                    polyline: { points: "skfyCcuiyH{@Ty@X" },
                                    duration: { text: "1 min", value: 13 },
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                    maneuver: "turn-left",
                                  },
                                  {
                                    end_location: {
                                      lat: 25.2705523,
                                      lng: 51.50232339999999,
                                    },
                                    maneuver: "turn-right",
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                                    start_location: {
                                      lng: 51.500265,
                                      lat: 25.269806,
                                    },
                                    polyline: {
                                      points:
                                        "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                    },
                                    duration: { value: 29, text: "1 min" },
                                    distance: { text: "0.2 km", value: 223 },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    end_location: {
                                      lat: 25.2679045,
                                      lng: 51.5033973,
                                    },
                                    travel_mode: "DRIVING",
                                    maneuver: "roundabout-right",
                                    start_location: {
                                      lat: 25.2705523,
                                      lng: 51.50232339999999,
                                    },
                                    duration: { value: 48, text: "1 min" },
                                    polyline: {
                                      points:
                                        "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                    },
                                    html_instructions:
                                      "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                    distance: { value: 326, text: "0.3 km" },
                                  },
                                  {
                                    html_instructions:
                                      "Merge onto <b>Salwa Rd</b>",
                                    end_location: {
                                      lat: 25.2368244,
                                      lng: 51.45117,
                                    },
                                    travel_mode: "DRIVING",
                                    distance: { value: 6288, text: "6.3 km" },
                                    start_location: {
                                      lng: 51.5033973,
                                      lat: 25.2679045,
                                    },
                                    duration: { value: 283, text: "5 mins" },
                                    polyline: {
                                      points:
                                        "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                    },
                                    maneuver: "merge",
                                  },
                                  {
                                    duration: { value: 10, text: "1 min" },
                                    maneuver: "ramp-right",
                                    start_location: {
                                      lat: 25.2368244,
                                      lng: 51.45117,
                                    },
                                    html_instructions:
                                      'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                    travel_mode: "DRIVING",
                                    distance: { value: 194, text: "0.2 km" },
                                    polyline: { points: "ca`yCy``yHpAnDlAdD" },
                                    end_location: {
                                      lat: 25.2360177,
                                      lng: 51.4494587,
                                    },
                                  },
                                  {
                                    maneuver: "merge",
                                    html_instructions:
                                      "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                    start_location: {
                                      lng: 51.4494587,
                                      lat: 25.2360177,
                                    },
                                    distance: { value: 397, text: "0.4 km" },
                                    duration: { text: "1 min", value: 33 },
                                    end_location: {
                                      lng: 51.4461502,
                                      lat: 25.2340694,
                                    },
                                    polyline: {
                                      points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                    },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    end_location: {
                                      lng: 51.4456607,
                                      lat: 25.2337942,
                                    },
                                    html_instructions:
                                      "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                    maneuver: "straight",
                                    polyline: { points: "}o_yCma_yHP`@d@~@" },
                                    distance: { text: "58 m", value: 58 },
                                    duration: { value: 8, text: "1 min" },
                                    travel_mode: "DRIVING",
                                    start_location: {
                                      lat: 25.2340694,
                                      lng: 51.4461502,
                                    },
                                  },
                                  {
                                    distance: { text: "1.5 km", value: 1451 },
                                    maneuver: "straight",
                                    start_location: {
                                      lat: 25.2337942,
                                      lng: 51.4456607,
                                    },
                                    end_location: {
                                      lat: 25.2267513,
                                      lng: 51.43351759999999,
                                    },
                                    travel_mode: "DRIVING",
                                    duration: { text: "2 mins", value: 90 },
                                    polyline: {
                                      points:
                                        "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                    },
                                    html_instructions:
                                      'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                                  },
                                  {
                                    html_instructions:
                                      "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                    },
                                    start_location: {
                                      lat: 25.2267513,
                                      lng: 51.43351759999999,
                                    },
                                    maneuver: "turn-slight-left",
                                    distance: { value: 92, text: "92 m" },
                                    end_location: {
                                      lat: 25.2262036,
                                      lng: 51.4328437,
                                    },
                                    duration: { text: "1 min", value: 10 },
                                  },
                                  {
                                    polyline: {
                                      points: "w~}xCgn|xHLRNNXZFDVTD@",
                                    },
                                    end_location: {
                                      lat: 25.2257335,
                                      lng: 51.4323655,
                                    },
                                    start_location: {
                                      lng: 51.4328437,
                                      lat: 25.2262036,
                                    },
                                    html_instructions: "Continue straight",
                                    duration: { value: 19, text: "1 min" },
                                    maneuver: "straight",
                                    distance: { value: 71, text: "71 m" },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    distance: { value: 813, text: "0.8 km" },
                                    travel_mode: "DRIVING",
                                    duration: { value: 72, text: "1 min" },
                                    end_location: {
                                      lng: 51.4357808,
                                      lat: 25.2196178,
                                    },
                                    html_instructions:
                                      "Continue onto <b>E Industrial Rd</b>",
                                    start_location: {
                                      lat: 25.2257335,
                                      lng: 51.4323655,
                                    },
                                    polyline: {
                                      points:
                                        "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                    },
                                  },
                                  {
                                    duration: { text: "1 min", value: 27 },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Street 2</b>",
                                    distance: { value: 263, text: "0.3 km" },
                                    end_location: {
                                      lng: 51.43371639999999,
                                      lat: 25.2182661,
                                    },
                                    start_location: {
                                      lng: 51.4357808,
                                      lat: 25.2196178,
                                    },
                                    polyline: {
                                      points:
                                        "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                    },
                                    maneuver: "turn-right",
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    distance: { value: 682, text: "0.7 km" },
                                    start_location: {
                                      lat: 25.2182661,
                                      lng: 51.43371639999999,
                                    },
                                    end_location: {
                                      lng: 51.4366206,
                                      lat: 25.2135618,
                                    },
                                    duration: { value: 78, text: "1 min" },
                                    polyline: {
                                      points:
                                        "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                    },
                                    html_instructions:
                                      "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                    maneuver: "roundabout-right",
                                  },
                                  {
                                    maneuver: "turn-right",
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Street 8</b>",
                                    polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                                    end_location: {
                                      lat: 25.2133289,
                                      lng: 51.4364944,
                                    },
                                    travel_mode: "DRIVING",
                                    duration: { text: "1 min", value: 12 },
                                    start_location: {
                                      lng: 51.4366206,
                                      lat: 25.2135618,
                                    },
                                    distance: { value: 29, text: "29 m" },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    duration: { value: 8, text: "1 min" },
                                    start_location: {
                                      lat: 25.2133289,
                                      lng: 51.4364944,
                                    },
                                    polyline: { points: "in{xCae}xHXO" },
                                    distance: { text: "16 m", value: 16 },
                                    html_instructions: "Turn <b>left</b>",
                                    maneuver: "turn-left",
                                    end_location: {
                                      lng: 51.43657289999999,
                                      lat: 25.2132031,
                                    },
                                  },
                                ],
                                duration: { value: 795, text: "13 mins" },
                                distance: { value: 11216, text: "11.2 km" },
                                end_address: "28 Street 8, Ar Rayyan, Qatar",
                              },
                            ],
                          },
                        ],
                      },
                      completedAt: 1707942351003,
                      createdAt: 1707942285310,
                      owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                      destination: {
                        coordinates: {
                          longitude: 51.43657469999999,
                          latitude: 25.213204,
                        },
                        address: "Street 8, Doha, Qatar",
                        type: "corporate-branch",
                        id: "9VZwCrYZfl03ob5FrD4F.0",
                        geohash: "thkx6myvx1",
                      },
                      co2Amount: 12.612562888268984,
                      origin: {
                        coordinates: {
                          longitude: 51.489361,
                          latitude: 25.282848,
                        },
                        geohash: "thkxgdt7m0",
                        address: "858, الدوحة، Qatar",
                      },
                      car: {
                        year: 2021,
                        type: "carbon-emitting",
                        model: "LAND CRUSER WAGON 4WD",
                        make: "Toyota",
                        co2_p_km: 0.3379843740994449,
                        seat: 3,
                      },
                      rules: [
                        "No smoking",
                        "No eating or drinking",
                        "Keep off the AC",
                      ],
                      preferences: {
                        female_passengers_only: null,
                        verified_passengers_only: null,
                      },
                      skin: null,
                      status: "completed",
                      passengersArray: [
                        {
                          credits: 27.8,
                          username: "abcd",
                          corporate: "9VZwCrYZfl03ob5FrD4F",
                          seat: 0,
                          id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                          name: "Jassim",
                          co2Amount: 4.313694566631215,
                          avatar:
                            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                          location: {
                            geohash: "thkxgdt7m0",
                            coordinates: {
                              latitude: 25.282848,
                              longitude: 51.489361,
                            },
                            address: "858, الدوحة، Qatar",
                          },
                          wallet_id: "spencer_carbon.testnet",
                        },
                        {
                          status: "picked-up",
                          id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                          seat: 2,
                          avatar:
                            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                          username: "sarah",
                          corporate: "9VZwCrYZfl03ob5FrD4F",
                          wallet_id: "sarah_carbon.testnet",
                          credits: 13,
                          co2Amount: 3.7908327398993733,
                          name: "Faisal",
                          location: {
                            coordinates: {
                              longitude: 51.498513,
                              latitude: 25.268246,
                            },
                            address: "7F9X+C9V, Doha, Qatar",
                            geohash: "thkxezun4q",
                          },
                        },
                        {
                          avatar:
                            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                          corporate: "9VZwCrYZfl03ob5FrD4F",
                          status: "picked-up",
                          location: {
                            geohash: "thkxu5pfc8",
                            address: "7GP7+6RG, Doha, Qatar",
                            coordinates: {
                              longitude: 51.514615,
                              latitude: 25.285509,
                            },
                          },
                          username: "michaelscott",
                          id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                          credits: 13.9,
                          seat: 1,
                          name: "Ahmed",
                          wallet_id: "sofia_carbon.testnet",
                          co2Amount: 4.508035581738396,
                        },
                      ],
                    },
                    type: "carpool_passenger",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                  },
                  {
                    status: "picked-up",
                    id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                    seat: 2,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                    username: "sarah",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    wallet_id: "sarah_carbon.testnet",
                    credits: 13,
                    co2Amount: 3.7908327398993733,
                    name: "Faisal",
                    location: {
                      coordinates: {
                        longitude: 51.498513,
                        latitude: 25.268246,
                      },
                      address: "7F9X+C9V, Doha, Qatar",
                      geohash: "thkxezun4q",
                    },
                  },
                  {
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    status: "picked-up",
                    location: {
                      geohash: "thkxu5pfc8",
                      address: "7GP7+6RG, Doha, Qatar",
                      coordinates: {
                        longitude: 51.514615,
                        latitude: 25.285509,
                      },
                    },
                    username: "michaelscott",
                    id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                    credits: 13.9,
                    seat: 1,
                    name: "Ahmed",
                    wallet_id: "sofia_carbon.testnet",
                    co2Amount: 4.508035581738396,
                  },
                ],
              },
              type: "carpool_passenger",
              corporate: "9VZwCrYZfl03ob5FrD4F",
            },
            {
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              status: "picked-up",
              location: {
                geohash: "thkxu5pfc8",
                address: "7GP7+6RG, Doha, Qatar",
                coordinates: { longitude: 51.514615, latitude: 25.285509 },
              },
              username: "michaelscott",
              id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
              credits: 13.9,
              seat: 1,
              name: "Ahmed",
              wallet_id: "sofia_carbon.testnet",
              co2Amount: 4.508035581738396,
            },
          ],
        },
        type: "carpool_passenger",
        corporate: "9VZwCrYZfl03ob5FrD4F",
      },
    },
    skin: null,
    car: {
      year: 2021,
      type: "carbon-emitting",
      model: "LAND CRUSER WAGON 4WD",
      make: "Toyota",
      co2_p_km: 0.3379843740994449,
      seat: 3,
    },
    owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
    status: "completed",
    id: "8292057714690",
    origin: {
      coordinates: { longitude: 51.489361, latitude: 25.282848 },
      geohash: "thkxgdt7m0",
      address: "858, الدوحة، Qatar",
    },
    destination: {
      coordinates: { longitude: 51.43657469999999, latitude: 25.213204 },
      address: "Street 8, Doha, Qatar",
      type: "corporate-branch",
      id: "9VZwCrYZfl03ob5FrD4F.0",
      geohash: "thkx6myvx1",
    },
    credits: 0,
    createdAt: 1707942285310,
    co2Amount: 12.612562888268984,
    autoUpdateTime: 0,
    ownerRating: "",
    type: "carpool",
    rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
    preferences: {
      female_passengers_only: null,
      verified_passengers_only: null,
    },
    completedAt: 1707942351003,
  },
  {
    raw: {
      id: "8292058029514",
      owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
      co2Amount: 12.612562888268984,
      directions: {
        status: "OK",
        geocoded_waypoints: [
          {
            geocoder_status: "OK",
            types: ["establishment", "moving_company", "point_of_interest"],
            place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
          },
          {
            place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
            types: ["establishment", "general_contractor", "point_of_interest"],
            geocoder_status: "OK",
          },
          {
            types: ["premise"],
            place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
            geocoder_status: "OK",
          },
          {
            types: ["street_address"],
            geocoder_status: "OK",
            place_id:
              "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
          },
        ],
        routes: [
          {
            summary: "Al Rayyan Rd",
            bounds: {
              northeast: { lng: 51.518743, lat: 25.291958 },
              southwest: { lat: 25.2132031, lng: 51.4319062 },
            },
            legs: [
              {
                end_location: { lat: 25.2856124, lng: 51.5145907 },
                start_location: { lat: 25.2827107, lng: 51.4906083 },
                steps: [
                  {
                    polyline: { points: "}_iyCiwgyHz@B" },
                    end_location: { lng: 51.4905936, lat: 25.2824136 },
                    start_location: { lat: 25.2827107, lng: 51.4906083 },
                    distance: { value: 33, text: "33 m" },
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                    duration: { value: 4, text: "1 min" },
                  },
                  {
                    html_instructions:
                      "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                    travel_mode: "DRIVING",
                    distance: { value: 574, text: "0.6 km" },
                    duration: { value: 49, text: "1 min" },
                    end_location: { lng: 51.4871118, lat: 25.2801532 },
                    polyline: {
                      points:
                        "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                    },
                    start_location: { lng: 51.4905936, lat: 25.2824136 },
                  },
                  {
                    html_instructions: "Turn <b>left</b>",
                    duration: { text: "1 min", value: 44 },
                    maneuver: "turn-left",
                    polyline: {
                      points:
                        "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                    },
                    end_location: { lat: 25.2801774, lng: 51.49079529999999 },
                    distance: { value: 426, text: "0.4 km" },
                    start_location: { lat: 25.2801532, lng: 51.4871118 },
                    travel_mode: "DRIVING",
                  },
                  {
                    travel_mode: "DRIVING",
                    duration: { value: 8, text: "1 min" },
                    polyline: { points: "cphyCoxgyHEaBAsA" },
                    html_instructions:
                      "Continue straight onto <b>Al Sadd St</b>",
                    end_location: { lat: 25.2802165, lng: 51.4917143 },
                    maneuver: "straight",
                    distance: { value: 93, text: "93 m" },
                    start_location: { lat: 25.2801774, lng: 51.49079529999999 },
                  },
                  {
                    distance: { value: 236, text: "0.2 km" },
                    html_instructions: "Slight <b>left</b>",
                    polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                    start_location: { lat: 25.2802165, lng: 51.4917143 },
                    travel_mode: "DRIVING",
                    end_location: { lat: 25.2804496, lng: 51.4940386 },
                    duration: { text: "1 min", value: 71 },
                    maneuver: "turn-slight-left",
                  },
                  {
                    duration: { text: "1 min", value: 38 },
                    end_location: { lat: 25.281604, lng: 51.4947254 },
                    polyline: { points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O" },
                    travel_mode: "DRIVING",
                    maneuver: "turn-left",
                    start_location: { lng: 51.4940386, lat: 25.2804496 },
                    distance: { value: 163, text: "0.2 km" },
                    html_instructions:
                      'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                  },
                  {
                    duration: { text: "1 min", value: 76 },
                    polyline: {
                      points:
                        "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                    },
                    start_location: { lat: 25.281604, lng: 51.4947254 },
                    html_instructions:
                      "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                    maneuver: "keep-left",
                    end_location: { lng: 51.4965617, lat: 25.2914195 },
                    travel_mode: "DRIVING",
                    distance: { text: "1.1 km", value: 1109 },
                  },
                  {
                    start_location: { lat: 25.2914195, lng: 51.4965617 },
                    maneuver: "turn-right",
                    duration: { value: 209, text: "3 mins" },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                    polyline: {
                      points:
                        "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                    },
                    end_location: { lat: 25.2893902, lng: 51.51516729999999 },
                    distance: { value: 1922, text: "1.9 km" },
                    travel_mode: "DRIVING",
                  },
                  {
                    distance: { value: 144, text: "0.1 km" },
                    start_location: { lng: 51.51516729999999, lat: 25.2893902 },
                    end_location: { lat: 25.2884741, lng: 51.51591370000001 },
                    maneuver: "turn-right",
                    polyline: {
                      points:
                        "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                    },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Khaleej</b>",
                    duration: { value: 15, text: "1 min" },
                    travel_mode: "DRIVING",
                  },
                  {
                    polyline: { points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E" },
                    duration: { text: "1 min", value: 11 },
                    end_location: { lng: 51.516228, lat: 25.2874383 },
                    start_location: { lat: 25.2884741, lng: 51.51591370000001 },
                    travel_mode: "DRIVING",
                    distance: { text: "0.1 km", value: 122 },
                    html_instructions:
                      'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                  },
                  {
                    travel_mode: "DRIVING",
                    maneuver: "turn-slight-right",
                    duration: { text: "1 min", value: 21 },
                    polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                    start_location: { lng: 51.516228, lat: 25.2874383 },
                    end_location: { lng: 51.51678399999999, lat: 25.2849434 },
                    distance: { text: "0.3 km", value: 283 },
                    html_instructions:
                      'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                  },
                  {
                    travel_mode: "DRIVING",
                    maneuver: "turn-right",
                    start_location: { lng: 51.51678399999999, lat: 25.2849434 },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                    duration: { text: "1 min", value: 32 },
                    polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                    distance: { text: "0.1 km", value: 125 },
                    end_location: { lng: 51.5155496, lat: 25.2848165 },
                  },
                  {
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                    duration: { text: "1 min", value: 21 },
                    maneuver: "turn-right",
                    distance: { value: 74, text: "74 m" },
                    polyline: { points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X" },
                    end_location: { lat: 25.2850716, lng: 51.5150352 },
                    start_location: { lat: 25.2848165, lng: 51.5155496 },
                  },
                  {
                    polyline: { points: "uniyC_plyHqBJ" },
                    duration: { text: "1 min", value: 23 },
                    end_location: { lat: 25.2856417, lng: 51.51497639999999 },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                    start_location: { lat: 25.2850716, lng: 51.5150352 },
                    travel_mode: "DRIVING",
                    maneuver: "turn-right",
                    distance: { value: 64, text: "64 m" },
                  },
                  {
                    travel_mode: "DRIVING",
                    duration: { text: "1 min", value: 14 },
                    start_location: { lat: 25.2856417, lng: 51.51497639999999 },
                    end_location: { lng: 51.5145907, lat: 25.2856124 },
                    distance: { text: "39 m", value: 39 },
                    polyline: { points: "griyCsolyH?p@DZ" },
                    html_instructions:
                      'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                    maneuver: "turn-left",
                  },
                ],
                start_address: "858, الدوحة، Qatar",
                end_address: "7GP7+6RG, Doha, Qatar",
                traffic_speed_entry: [],
                duration: { value: 636, text: "11 mins" },
                distance: { text: "5.4 km", value: 5407 },
                via_waypoint: [],
              },
              {
                start_address: "7GP7+6RG, Doha, Qatar",
                duration: { value: 598, text: "10 mins" },
                traffic_speed_entry: [],
                start_location: { lat: 25.2856124, lng: 51.5145907 },
                steps: [
                  {
                    duration: { value: 73, text: "1 min" },
                    end_location: { lat: 25.2859334, lng: 51.5165533 },
                    start_location: { lat: 25.2856124, lng: 51.5145907 },
                    distance: { text: "0.2 km", value: 201 },
                    html_instructions:
                      "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                    travel_mode: "DRIVING",
                    polyline: {
                      points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                    },
                  },
                  {
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                    distance: { text: "0.7 km", value: 735 },
                    end_location: { lng: 51.518743, lat: 25.2796568 },
                    maneuver: "turn-right",
                    polyline: {
                      points:
                        "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                    },
                    duration: { text: "1 min", value: 78 },
                    start_location: { lng: 51.5165533, lat: 25.2859334 },
                  },
                  {
                    travel_mode: "DRIVING",
                    distance: { text: "0.4 km", value: 393 },
                    end_location: { lat: 25.2769643, lng: 51.5164061 },
                    maneuver: "turn-slight-right",
                    start_location: { lat: 25.2796568, lng: 51.518743 },
                    polyline: {
                      points:
                        "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                    },
                    html_instructions:
                      "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                    duration: { text: "1 min", value: 59 },
                  },
                  {
                    travel_mode: "DRIVING",
                    start_location: { lng: 51.5164061, lat: 25.2769643 },
                    polyline: {
                      points:
                        "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                    },
                    end_location: { lng: 51.500835, lat: 25.2663847 },
                    duration: { value: 229, text: "4 mins" },
                    distance: { value: 1963, text: "2.0 km" },
                    maneuver: "merge",
                    html_instructions:
                      "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                  },
                  {
                    maneuver: "turn-slight-right",
                    html_instructions:
                      "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                    end_location: { lng: 51.497229, lat: 25.2643805 },
                    distance: { text: "0.4 km", value: 428 },
                    travel_mode: "DRIVING",
                    start_location: { lat: 25.2663847, lng: 51.500835 },
                    polyline: {
                      points:
                        "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                    },
                    duration: { value: 40, text: "1 min" },
                  },
                  {
                    html_instructions:
                      "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                    maneuver: "roundabout-right",
                    polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                    duration: { value: 22, text: "1 min" },
                    travel_mode: "DRIVING",
                    start_location: { lng: 51.497229, lat: 25.2643805 },
                    distance: { text: "0.2 km", value: 182 },
                    end_location: { lng: 51.4962302, lat: 25.2656775 },
                  },
                  {
                    polyline: { points: "oueyCmzhyH]DKB" },
                    start_location: { lat: 25.2656775, lng: 51.4962302 },
                    end_location: { lat: 25.2658942, lng: 51.4961765 },
                    travel_mode: "DRIVING",
                    distance: { value: 25, text: "25 m" },
                    maneuver: "turn-slight-right",
                    html_instructions:
                      "Slight <b>right</b> onto <b>Al Nasr St</b>",
                    duration: { value: 3, text: "1 min" },
                  },
                  {
                    html_instructions:
                      "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                    maneuver: "turn-slight-right",
                    end_location: { lng: 51.4995317, lat: 25.267547 },
                    travel_mode: "DRIVING",
                    polyline: {
                      points: "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                    },
                    distance: { value: 389, text: "0.4 km" },
                    duration: { value: 53, text: "1 min" },
                    start_location: { lat: 25.2658942, lng: 51.4961765 },
                  },
                  {
                    distance: { value: 111, text: "0.1 km" },
                    start_location: { lat: 25.267547, lng: 51.4995317 },
                    html_instructions:
                      "Turn <b>left</b> onto <b>Mohammed St</b>",
                    duration: { value: 22, text: "1 min" },
                    end_location: { lng: 51.499144, lat: 25.2684856 },
                    travel_mode: "DRIVING",
                    polyline: { points: "eafyCaoiyHQFiDdA" },
                    maneuver: "turn-left",
                  },
                  {
                    travel_mode: "DRIVING",
                    maneuver: "turn-left",
                    end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                    distance: { text: "62 m", value: 62 },
                    duration: { text: "1 min", value: 19 },
                    polyline: { points: "agfyCsliyH\\vABNAJ" },
                    html_instructions:
                      'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                    start_location: { lng: 51.499144, lat: 25.2684856 },
                  },
                ],
                via_waypoint: [],
                end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                distance: { text: "4.5 km", value: 4489 },
                end_address: "7F9X+C9V, Doha, Qatar",
              },
              {
                end_address: "28 Street 8, Ar Rayyan, Qatar",
                start_address: "7F9X+C9V, Doha, Qatar",
                via_waypoint: [],
                duration: { text: "13 mins", value: 795 },
                start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                distance: { value: 11216, text: "11.2 km" },
                end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                steps: [
                  {
                    end_location: { lng: 51.499144, lat: 25.2684856 },
                    start_location: { lat: 25.2683307, lng: 51.49855549999999 },
                    distance: { value: 62, text: "62 m" },
                    duration: { text: "1 min", value: 14 },
                    polyline: { points: "affyC_iiyH@KCO]wA" },
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                  },
                  {
                    distance: { value: 28, text: "28 m" },
                    duration: { value: 9, text: "1 min" },
                    maneuver: "turn-left",
                    start_location: { lng: 51.499144, lat: 25.2684856 },
                    end_location: { lng: 51.499066, lat: 25.2687298 },
                    html_instructions:
                      "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                    travel_mode: "DRIVING",
                    polyline: { points: "agfyCsliyHo@L" },
                  },
                  {
                    html_instructions:
                      "Turn <b>right</b> onto <b>Al Baraha</b>",
                    polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                    maneuver: "turn-right",
                    travel_mode: "DRIVING",
                    distance: { text: "0.2 km", value: 154 },
                    end_location: { lat: 25.2692232, lng: 51.500496 },
                    duration: { value: 32, text: "1 min" },
                    start_location: { lng: 51.499066, lat: 25.2687298 },
                  },
                  {
                    end_location: { lng: 51.500265, lat: 25.269806 },
                    polyline: { points: "skfyCcuiyH{@Ty@X" },
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                    duration: { value: 13, text: "1 min" },
                    distance: { text: "69 m", value: 69 },
                    maneuver: "turn-left",
                    start_location: { lng: 51.500496, lat: 25.2692232 },
                  },
                  {
                    polyline: { points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA" },
                    start_location: { lat: 25.269806, lng: 51.500265 },
                    distance: { text: "0.2 km", value: 223 },
                    duration: { value: 29, text: "1 min" },
                    maneuver: "turn-right",
                    travel_mode: "DRIVING",
                    end_location: { lat: 25.2705523, lng: 51.50232339999999 },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                  },
                  {
                    polyline: {
                      points:
                        "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                    },
                    duration: { value: 48, text: "1 min" },
                    end_location: { lng: 51.5033973, lat: 25.2679045 },
                    travel_mode: "DRIVING",
                    maneuver: "roundabout-right",
                    distance: { text: "0.3 km", value: 326 },
                    html_instructions:
                      "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                    start_location: { lng: 51.50232339999999, lat: 25.2705523 },
                  },
                  {
                    end_location: { lng: 51.45117, lat: 25.2368244 },
                    polyline: {
                      points:
                        "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                    },
                    duration: { value: 283, text: "5 mins" },
                    html_instructions: "Merge onto <b>Salwa Rd</b>",
                    start_location: { lng: 51.5033973, lat: 25.2679045 },
                    travel_mode: "DRIVING",
                    distance: { text: "6.3 km", value: 6288 },
                    maneuver: "merge",
                  },
                  {
                    start_location: { lng: 51.45117, lat: 25.2368244 },
                    maneuver: "ramp-right",
                    duration: { text: "1 min", value: 10 },
                    travel_mode: "DRIVING",
                    distance: { text: "0.2 km", value: 194 },
                    html_instructions:
                      'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                    polyline: { points: "ca`yCy``yHpAnDlAdD" },
                    end_location: { lng: 51.4494587, lat: 25.2360177 },
                  },
                  {
                    travel_mode: "DRIVING",
                    distance: { value: 397, text: "0.4 km" },
                    end_location: { lat: 25.2340694, lng: 51.4461502 },
                    duration: { text: "1 min", value: 33 },
                    html_instructions:
                      "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                    maneuver: "merge",
                    start_location: { lat: 25.2360177, lng: 51.4494587 },
                    polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                  },
                  {
                    duration: { value: 8, text: "1 min" },
                    maneuver: "straight",
                    start_location: { lng: 51.4461502, lat: 25.2340694 },
                    html_instructions:
                      "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                    end_location: { lat: 25.2337942, lng: 51.4456607 },
                    polyline: { points: "}o_yCma_yHP`@d@~@" },
                    distance: { text: "58 m", value: 58 },
                    travel_mode: "DRIVING",
                  },
                  {
                    maneuver: "straight",
                    travel_mode: "DRIVING",
                    start_location: { lng: 51.4456607, lat: 25.2337942 },
                    duration: { text: "2 mins", value: 90 },
                    end_location: { lat: 25.2267513, lng: 51.43351759999999 },
                    html_instructions:
                      'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                    polyline: {
                      points:
                        "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                    },
                    distance: { value: 1451, text: "1.5 km" },
                  },
                  {
                    duration: { text: "1 min", value: 10 },
                    html_instructions:
                      "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                    travel_mode: "DRIVING",
                    distance: { value: 92, text: "92 m" },
                    end_location: { lng: 51.4328437, lat: 25.2262036 },
                    start_location: { lat: 25.2267513, lng: 51.43351759999999 },
                    maneuver: "turn-slight-left",
                    polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                  },
                  {
                    end_location: { lat: 25.2257335, lng: 51.4323655 },
                    html_instructions: "Continue straight",
                    maneuver: "straight",
                    travel_mode: "DRIVING",
                    duration: { text: "1 min", value: 19 },
                    polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                    distance: { value: 71, text: "71 m" },
                    start_location: { lat: 25.2262036, lng: 51.4328437 },
                  },
                  {
                    polyline: {
                      points:
                        "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                    },
                    travel_mode: "DRIVING",
                    duration: { value: 72, text: "1 min" },
                    distance: { text: "0.8 km", value: 813 },
                    start_location: { lng: 51.4323655, lat: 25.2257335 },
                    html_instructions: "Continue onto <b>E Industrial Rd</b>",
                    end_location: { lng: 51.4357808, lat: 25.2196178 },
                  },
                  {
                    distance: { value: 263, text: "0.3 km" },
                    maneuver: "turn-right",
                    polyline: { points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA" },
                    end_location: { lat: 25.2182661, lng: 51.43371639999999 },
                    start_location: { lat: 25.2196178, lng: 51.4357808 },
                    html_instructions: "Turn <b>right</b> onto <b>Street 2</b>",
                    duration: { text: "1 min", value: 27 },
                    travel_mode: "DRIVING",
                  },
                  {
                    travel_mode: "DRIVING",
                    end_location: { lat: 25.2135618, lng: 51.4366206 },
                    duration: { value: 78, text: "1 min" },
                    maneuver: "roundabout-right",
                    start_location: { lng: 51.43371639999999, lat: 25.2182661 },
                    distance: { value: 682, text: "0.7 km" },
                    html_instructions:
                      "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                    polyline: {
                      points:
                        "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                    },
                  },
                  {
                    distance: { text: "29 m", value: 29 },
                    start_location: { lat: 25.2135618, lng: 51.4366206 },
                    maneuver: "turn-right",
                    travel_mode: "DRIVING",
                    html_instructions: "Turn <b>right</b> onto <b>Street 8</b>",
                    end_location: { lng: 51.4364944, lat: 25.2133289 },
                    duration: { value: 12, text: "1 min" },
                    polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                  },
                  {
                    distance: { value: 16, text: "16 m" },
                    maneuver: "turn-left",
                    duration: { value: 8, text: "1 min" },
                    travel_mode: "DRIVING",
                    end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                    start_location: { lng: 51.4364944, lat: 25.2133289 },
                    polyline: { points: "in{xCae}xHXO" },
                    html_instructions: "Turn <b>left</b>",
                  },
                ],
                traffic_speed_entry: [],
              },
            ],
            waypoint_order: [0, 1],
            warnings: [],
            copyrights: "Map data ©2024 Google",
            overview_polyline: {
              points:
                "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
            },
          },
        ],
      },
      completedAt: 1707942203548,
      destination: {
        coordinates: { latitude: 25.213204, longitude: 51.43657469999999 },
        geohash: "thkx6myvx1",
        type: "corporate-branch",
        address: "Street 8, Doha, Qatar",
        id: "9VZwCrYZfl03ob5FrD4F.0",
      },
      status: "completed",
      skin: null,
      origin: {
        geohash: "thkxgdt7m0",
        address: "858, الدوحة، Qatar",
        coordinates: { longitude: 51.489361, latitude: 25.282848 },
      },
      preferences: {
        female_passengers_only: null,
        verified_passengers_only: null,
      },
      car: {
        seat: 3,
        co2_p_km: 0.3379843740994449,
        year: 2021,
        make: "Toyota",
        type: "carbon-emitting",
        model: "LAND CRUSER WAGON 4WD",
      },
      passengers: {
        C1GECLApV4W0bVtgeFk4iRpZYRl1: {
          userId: "user_id",
          name: "Jassim",
          username: "abcd",
          location: {
            geohash: "thkxgdt7m0",
            address: "858, الدوحة، Qatar",
            coordinates: { latitude: 25.282848, longitude: 51.489361 },
          },
          credits: 27.8,
          co2Amount: 4.313694566631215,
          avatar:
            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
          seat: 0,
          id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
          wallet_id: "spencer_carbon.testnet",
          carpool: {
            id: "8292058029514",
            owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
            co2Amount: 12.612562888268984,
            directions: {
              status: "OK",
              geocoded_waypoints: [
                {
                  geocoder_status: "OK",
                  types: [
                    "establishment",
                    "moving_company",
                    "point_of_interest",
                  ],
                  place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                },
                {
                  place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                  types: [
                    "establishment",
                    "general_contractor",
                    "point_of_interest",
                  ],
                  geocoder_status: "OK",
                },
                {
                  types: ["premise"],
                  place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                  geocoder_status: "OK",
                },
                {
                  types: ["street_address"],
                  geocoder_status: "OK",
                  place_id:
                    "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                },
              ],
              routes: [
                {
                  summary: "Al Rayyan Rd",
                  bounds: {
                    northeast: { lng: 51.518743, lat: 25.291958 },
                    southwest: { lat: 25.2132031, lng: 51.4319062 },
                  },
                  legs: [
                    {
                      end_location: { lat: 25.2856124, lng: 51.5145907 },
                      start_location: { lat: 25.2827107, lng: 51.4906083 },
                      steps: [
                        {
                          polyline: { points: "}_iyCiwgyHz@B" },
                          end_location: { lng: 51.4905936, lat: 25.2824136 },
                          start_location: { lat: 25.2827107, lng: 51.4906083 },
                          distance: { value: 33, text: "33 m" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                          duration: { value: 4, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                          travel_mode: "DRIVING",
                          distance: { value: 574, text: "0.6 km" },
                          duration: { value: 49, text: "1 min" },
                          end_location: { lng: 51.4871118, lat: 25.2801532 },
                          polyline: {
                            points:
                              "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                          },
                          start_location: { lng: 51.4905936, lat: 25.2824136 },
                        },
                        {
                          html_instructions: "Turn <b>left</b>",
                          duration: { text: "1 min", value: 44 },
                          maneuver: "turn-left",
                          polyline: {
                            points:
                              "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                          },
                          end_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                          distance: { value: 426, text: "0.4 km" },
                          start_location: { lat: 25.2801532, lng: 51.4871118 },
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          polyline: { points: "cphyCoxgyHEaBAsA" },
                          html_instructions:
                            "Continue straight onto <b>Al Sadd St</b>",
                          end_location: { lat: 25.2802165, lng: 51.4917143 },
                          maneuver: "straight",
                          distance: { value: 93, text: "93 m" },
                          start_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                        },
                        {
                          distance: { value: 236, text: "0.2 km" },
                          html_instructions: "Slight <b>left</b>",
                          polyline: {
                            points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                          },
                          start_location: { lat: 25.2802165, lng: 51.4917143 },
                          travel_mode: "DRIVING",
                          end_location: { lat: 25.2804496, lng: 51.4940386 },
                          duration: { text: "1 min", value: 71 },
                          maneuver: "turn-slight-left",
                        },
                        {
                          duration: { text: "1 min", value: 38 },
                          end_location: { lat: 25.281604, lng: 51.4947254 },
                          polyline: {
                            points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                          },
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          start_location: { lng: 51.4940386, lat: 25.2804496 },
                          distance: { value: 163, text: "0.2 km" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                        },
                        {
                          duration: { text: "1 min", value: 76 },
                          polyline: {
                            points:
                              "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                          },
                          start_location: { lat: 25.281604, lng: 51.4947254 },
                          html_instructions:
                            "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                          maneuver: "keep-left",
                          end_location: { lng: 51.4965617, lat: 25.2914195 },
                          travel_mode: "DRIVING",
                          distance: { text: "1.1 km", value: 1109 },
                        },
                        {
                          start_location: { lat: 25.2914195, lng: 51.4965617 },
                          maneuver: "turn-right",
                          duration: { value: 209, text: "3 mins" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                          polyline: {
                            points:
                              "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                          },
                          end_location: {
                            lat: 25.2893902,
                            lng: 51.51516729999999,
                          },
                          distance: { value: 1922, text: "1.9 km" },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { value: 144, text: "0.1 km" },
                          start_location: {
                            lng: 51.51516729999999,
                            lat: 25.2893902,
                          },
                          end_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej</b>",
                          duration: { value: 15, text: "1 min" },
                          travel_mode: "DRIVING",
                        },
                        {
                          polyline: {
                            points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                          },
                          duration: { text: "1 min", value: 11 },
                          end_location: { lng: 51.516228, lat: 25.2874383 },
                          start_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          travel_mode: "DRIVING",
                          distance: { text: "0.1 km", value: 122 },
                          html_instructions:
                            'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-slight-right",
                          duration: { text: "1 min", value: 21 },
                          polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                          start_location: { lng: 51.516228, lat: 25.2874383 },
                          end_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          distance: { text: "0.3 km", value: 283 },
                          html_instructions:
                            'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          start_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                          duration: { text: "1 min", value: 32 },
                          polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                          distance: { text: "0.1 km", value: 125 },
                          end_location: { lng: 51.5155496, lat: 25.2848165 },
                        },
                        {
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          duration: { text: "1 min", value: 21 },
                          maneuver: "turn-right",
                          distance: { value: 74, text: "74 m" },
                          polyline: {
                            points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                          },
                          end_location: { lat: 25.2850716, lng: 51.5150352 },
                          start_location: { lat: 25.2848165, lng: 51.5155496 },
                        },
                        {
                          polyline: { points: "uniyC_plyHqBJ" },
                          duration: { text: "1 min", value: 23 },
                          end_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          start_location: { lat: 25.2850716, lng: 51.5150352 },
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          distance: { value: 64, text: "64 m" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 14 },
                          start_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                          distance: { text: "39 m", value: 39 },
                          polyline: { points: "griyCsolyH?p@DZ" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          maneuver: "turn-left",
                        },
                      ],
                      start_address: "858, الدوحة، Qatar",
                      end_address: "7GP7+6RG, Doha, Qatar",
                      traffic_speed_entry: [],
                      duration: { value: 636, text: "11 mins" },
                      distance: { text: "5.4 km", value: 5407 },
                      via_waypoint: [],
                    },
                    {
                      start_address: "7GP7+6RG, Doha, Qatar",
                      duration: { value: 598, text: "10 mins" },
                      traffic_speed_entry: [],
                      start_location: { lat: 25.2856124, lng: 51.5145907 },
                      steps: [
                        {
                          duration: { value: 73, text: "1 min" },
                          end_location: { lat: 25.2859334, lng: 51.5165533 },
                          start_location: { lat: 25.2856124, lng: 51.5145907 },
                          distance: { text: "0.2 km", value: 201 },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                          },
                        },
                        {
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                          distance: { text: "0.7 km", value: 735 },
                          end_location: { lng: 51.518743, lat: 25.2796568 },
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                          },
                          duration: { text: "1 min", value: 78 },
                          start_location: { lng: 51.5165533, lat: 25.2859334 },
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { text: "0.4 km", value: 393 },
                          end_location: { lat: 25.2769643, lng: 51.5164061 },
                          maneuver: "turn-slight-right",
                          start_location: { lat: 25.2796568, lng: 51.518743 },
                          polyline: {
                            points:
                              "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                          },
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                          duration: { text: "1 min", value: 59 },
                        },
                        {
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.5164061, lat: 25.2769643 },
                          polyline: {
                            points:
                              "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                          },
                          end_location: { lng: 51.500835, lat: 25.2663847 },
                          duration: { value: 229, text: "4 mins" },
                          distance: { value: 1963, text: "2.0 km" },
                          maneuver: "merge",
                          html_instructions:
                            "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                        },
                        {
                          maneuver: "turn-slight-right",
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                          end_location: { lng: 51.497229, lat: 25.2643805 },
                          distance: { text: "0.4 km", value: 428 },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2663847, lng: 51.500835 },
                          polyline: {
                            points:
                              "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                          },
                          duration: { value: 40, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                          maneuver: "roundabout-right",
                          polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                          duration: { value: 22, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.497229, lat: 25.2643805 },
                          distance: { text: "0.2 km", value: 182 },
                          end_location: { lng: 51.4962302, lat: 25.2656775 },
                        },
                        {
                          polyline: { points: "oueyCmzhyH]DKB" },
                          start_location: { lat: 25.2656775, lng: 51.4962302 },
                          end_location: { lat: 25.2658942, lng: 51.4961765 },
                          travel_mode: "DRIVING",
                          distance: { value: 25, text: "25 m" },
                          maneuver: "turn-slight-right",
                          html_instructions:
                            "Slight <b>right</b> onto <b>Al Nasr St</b>",
                          duration: { value: 3, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                          maneuver: "turn-slight-right",
                          end_location: { lng: 51.4995317, lat: 25.267547 },
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                          },
                          distance: { value: 389, text: "0.4 km" },
                          duration: { value: 53, text: "1 min" },
                          start_location: { lat: 25.2658942, lng: 51.4961765 },
                        },
                        {
                          distance: { value: 111, text: "0.1 km" },
                          start_location: { lat: 25.267547, lng: 51.4995317 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohammed St</b>",
                          duration: { value: 22, text: "1 min" },
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          travel_mode: "DRIVING",
                          polyline: { points: "eafyCaoiyHQFiDdA" },
                          maneuver: "turn-left",
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { text: "62 m", value: 62 },
                          duration: { text: "1 min", value: 19 },
                          polyline: { points: "agfyCsliyH\\vABNAJ" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          start_location: { lng: 51.499144, lat: 25.2684856 },
                        },
                      ],
                      via_waypoint: [],
                      end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                      distance: { text: "4.5 km", value: 4489 },
                      end_address: "7F9X+C9V, Doha, Qatar",
                    },
                    {
                      end_address: "28 Street 8, Ar Rayyan, Qatar",
                      start_address: "7F9X+C9V, Doha, Qatar",
                      via_waypoint: [],
                      duration: { text: "13 mins", value: 795 },
                      start_location: {
                        lng: 51.49855549999999,
                        lat: 25.2683307,
                      },
                      distance: { value: 11216, text: "11.2 km" },
                      end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                      steps: [
                        {
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          start_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { value: 62, text: "62 m" },
                          duration: { text: "1 min", value: 14 },
                          polyline: { points: "affyC_iiyH@KCO]wA" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                        },
                        {
                          distance: { value: 28, text: "28 m" },
                          duration: { value: 9, text: "1 min" },
                          maneuver: "turn-left",
                          start_location: { lng: 51.499144, lat: 25.2684856 },
                          end_location: { lng: 51.499066, lat: 25.2687298 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                          travel_mode: "DRIVING",
                          polyline: { points: "agfyCsliyHo@L" },
                        },
                        {
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Baraha</b>",
                          polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          distance: { text: "0.2 km", value: 154 },
                          end_location: { lat: 25.2692232, lng: 51.500496 },
                          duration: { value: 32, text: "1 min" },
                          start_location: { lng: 51.499066, lat: 25.2687298 },
                        },
                        {
                          end_location: { lng: 51.500265, lat: 25.269806 },
                          polyline: { points: "skfyCcuiyH{@Ty@X" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                          duration: { value: 13, text: "1 min" },
                          distance: { text: "69 m", value: 69 },
                          maneuver: "turn-left",
                          start_location: { lng: 51.500496, lat: 25.2692232 },
                        },
                        {
                          polyline: {
                            points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                          },
                          start_location: { lat: 25.269806, lng: 51.500265 },
                          distance: { text: "0.2 km", value: 223 },
                          duration: { value: 29, text: "1 min" },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          end_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                        },
                        {
                          polyline: {
                            points:
                              "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                          },
                          duration: { value: 48, text: "1 min" },
                          end_location: { lng: 51.5033973, lat: 25.2679045 },
                          travel_mode: "DRIVING",
                          maneuver: "roundabout-right",
                          distance: { text: "0.3 km", value: 326 },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                          start_location: {
                            lng: 51.50232339999999,
                            lat: 25.2705523,
                          },
                        },
                        {
                          end_location: { lng: 51.45117, lat: 25.2368244 },
                          polyline: {
                            points:
                              "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                          },
                          duration: { value: 283, text: "5 mins" },
                          html_instructions: "Merge onto <b>Salwa Rd</b>",
                          start_location: { lng: 51.5033973, lat: 25.2679045 },
                          travel_mode: "DRIVING",
                          distance: { text: "6.3 km", value: 6288 },
                          maneuver: "merge",
                        },
                        {
                          start_location: { lng: 51.45117, lat: 25.2368244 },
                          maneuver: "ramp-right",
                          duration: { text: "1 min", value: 10 },
                          travel_mode: "DRIVING",
                          distance: { text: "0.2 km", value: 194 },
                          html_instructions:
                            'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                          polyline: { points: "ca`yCy``yHpAnDlAdD" },
                          end_location: { lng: 51.4494587, lat: 25.2360177 },
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { value: 397, text: "0.4 km" },
                          end_location: { lat: 25.2340694, lng: 51.4461502 },
                          duration: { text: "1 min", value: 33 },
                          html_instructions:
                            "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          maneuver: "merge",
                          start_location: { lat: 25.2360177, lng: 51.4494587 },
                          polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                        },
                        {
                          duration: { value: 8, text: "1 min" },
                          maneuver: "straight",
                          start_location: { lng: 51.4461502, lat: 25.2340694 },
                          html_instructions:
                            "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          end_location: { lat: 25.2337942, lng: 51.4456607 },
                          polyline: { points: "}o_yCma_yHP`@d@~@" },
                          distance: { text: "58 m", value: 58 },
                          travel_mode: "DRIVING",
                        },
                        {
                          maneuver: "straight",
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.4456607, lat: 25.2337942 },
                          duration: { text: "2 mins", value: 90 },
                          end_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          html_instructions:
                            'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                          polyline: {
                            points:
                              "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                          },
                          distance: { value: 1451, text: "1.5 km" },
                        },
                        {
                          duration: { text: "1 min", value: 10 },
                          html_instructions:
                            "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                          travel_mode: "DRIVING",
                          distance: { value: 92, text: "92 m" },
                          end_location: { lng: 51.4328437, lat: 25.2262036 },
                          start_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          maneuver: "turn-slight-left",
                          polyline: {
                            points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                          },
                        },
                        {
                          end_location: { lat: 25.2257335, lng: 51.4323655 },
                          html_instructions: "Continue straight",
                          maneuver: "straight",
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 19 },
                          polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                          distance: { value: 71, text: "71 m" },
                          start_location: { lat: 25.2262036, lng: 51.4328437 },
                        },
                        {
                          polyline: {
                            points:
                              "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                          },
                          travel_mode: "DRIVING",
                          duration: { value: 72, text: "1 min" },
                          distance: { text: "0.8 km", value: 813 },
                          start_location: { lng: 51.4323655, lat: 25.2257335 },
                          html_instructions:
                            "Continue onto <b>E Industrial Rd</b>",
                          end_location: { lng: 51.4357808, lat: 25.2196178 },
                        },
                        {
                          distance: { value: 263, text: "0.3 km" },
                          maneuver: "turn-right",
                          polyline: {
                            points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                          },
                          end_location: {
                            lat: 25.2182661,
                            lng: 51.43371639999999,
                          },
                          start_location: { lat: 25.2196178, lng: 51.4357808 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 2</b>",
                          duration: { text: "1 min", value: 27 },
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          end_location: { lat: 25.2135618, lng: 51.4366206 },
                          duration: { value: 78, text: "1 min" },
                          maneuver: "roundabout-right",
                          start_location: {
                            lng: 51.43371639999999,
                            lat: 25.2182661,
                          },
                          distance: { value: 682, text: "0.7 km" },
                          html_instructions:
                            "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                          polyline: {
                            points:
                              "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                          },
                        },
                        {
                          distance: { text: "29 m", value: 29 },
                          start_location: { lat: 25.2135618, lng: 51.4366206 },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 8</b>",
                          end_location: { lng: 51.4364944, lat: 25.2133289 },
                          duration: { value: 12, text: "1 min" },
                          polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                        },
                        {
                          distance: { value: 16, text: "16 m" },
                          maneuver: "turn-left",
                          duration: { value: 8, text: "1 min" },
                          travel_mode: "DRIVING",
                          end_location: {
                            lat: 25.2132031,
                            lng: 51.43657289999999,
                          },
                          start_location: { lng: 51.4364944, lat: 25.2133289 },
                          polyline: { points: "in{xCae}xHXO" },
                          html_instructions: "Turn <b>left</b>",
                        },
                      ],
                      traffic_speed_entry: [],
                    },
                  ],
                  waypoint_order: [0, 1],
                  warnings: [],
                  copyrights: "Map data ©2024 Google",
                  overview_polyline: {
                    points:
                      "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                  },
                },
              ],
            },
            completedAt: 1707942203548,
            destination: {
              coordinates: {
                latitude: 25.213204,
                longitude: 51.43657469999999,
              },
              geohash: "thkx6myvx1",
              type: "corporate-branch",
              address: "Street 8, Doha, Qatar",
              id: "9VZwCrYZfl03ob5FrD4F.0",
            },
            status: "completed",
            skin: null,
            origin: {
              geohash: "thkxgdt7m0",
              address: "858, الدوحة، Qatar",
              coordinates: { longitude: 51.489361, latitude: 25.282848 },
            },
            preferences: {
              female_passengers_only: null,
              verified_passengers_only: null,
            },
            car: {
              seat: 3,
              co2_p_km: 0.3379843740994449,
              year: 2021,
              make: "Toyota",
              type: "carbon-emitting",
              model: "LAND CRUSER WAGON 4WD",
            },
            rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
            createdAt: 1707941970485,
            passengersArray: [
              {
                corporate: "9VZwCrYZfl03ob5FrD4F",
                wallet_id: "spencer_carbon.testnet",
                location: {
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                  coordinates: { latitude: 25.282848, longitude: 51.489361 },
                },
                co2Amount: 4.313694566631215,
                seat: 0,
                name: "Jassim",
                id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                username: "abcd",
                credits: 27.8,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
              },
              {
                co2Amount: 4.508035581738396,
                seat: 2,
                wallet_id: "sofia_carbon.testnet",
                username: "michaelscott",
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                status: "picked-up",
                location: {
                  coordinates: { longitude: 51.514615, latitude: 25.285509 },
                  geohash: "thkxu5pfc8",
                  address: "7GP7+6RG, Doha, Qatar",
                },
                credits: 13.9,
                corporate: "9VZwCrYZfl03ob5FrD4F",
                id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                name: "Ahmed",
              },
              {
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                status: "picked-up",
                seat: 1,
                location: {
                  address: "7F9X+C9V, Doha, Qatar",
                  coordinates: { latitude: 25.268246, longitude: 51.498513 },
                  geohash: "thkxezun4q",
                },
                co2Amount: 3.7908327398993733,
                wallet_id: "sarah_carbon.testnet",
                credits: 13,
                username: "sarah",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                name: "Faisal",
              },
            ],
          },
          type: "carpool_passenger",
          corporate: "9VZwCrYZfl03ob5FrD4F",
        },
        lpd29qTdlURFvrSDO0w5WBODcSp2: {
          userId: "user_id",
          name: "Ahmed",
          username: "michaelscott",
          location: {
            coordinates: { longitude: 51.514615, latitude: 25.285509 },
            geohash: "thkxu5pfc8",
            address: "7GP7+6RG, Doha, Qatar",
          },
          credits: 13.9,
          co2Amount: 4.508035581738396,
          avatar:
            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
          seat: 2,
          id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
          wallet_id: "sofia_carbon.testnet",
          status: "picked-up",
          carpool: {
            id: "8292058029514",
            owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
            co2Amount: 12.612562888268984,
            directions: {
              status: "OK",
              geocoded_waypoints: [
                {
                  geocoder_status: "OK",
                  types: [
                    "establishment",
                    "moving_company",
                    "point_of_interest",
                  ],
                  place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                },
                {
                  place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                  types: [
                    "establishment",
                    "general_contractor",
                    "point_of_interest",
                  ],
                  geocoder_status: "OK",
                },
                {
                  types: ["premise"],
                  place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                  geocoder_status: "OK",
                },
                {
                  types: ["street_address"],
                  geocoder_status: "OK",
                  place_id:
                    "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                },
              ],
              routes: [
                {
                  summary: "Al Rayyan Rd",
                  bounds: {
                    northeast: { lng: 51.518743, lat: 25.291958 },
                    southwest: { lat: 25.2132031, lng: 51.4319062 },
                  },
                  legs: [
                    {
                      end_location: { lat: 25.2856124, lng: 51.5145907 },
                      start_location: { lat: 25.2827107, lng: 51.4906083 },
                      steps: [
                        {
                          polyline: { points: "}_iyCiwgyHz@B" },
                          end_location: { lng: 51.4905936, lat: 25.2824136 },
                          start_location: { lat: 25.2827107, lng: 51.4906083 },
                          distance: { value: 33, text: "33 m" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                          duration: { value: 4, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                          travel_mode: "DRIVING",
                          distance: { value: 574, text: "0.6 km" },
                          duration: { value: 49, text: "1 min" },
                          end_location: { lng: 51.4871118, lat: 25.2801532 },
                          polyline: {
                            points:
                              "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                          },
                          start_location: { lng: 51.4905936, lat: 25.2824136 },
                        },
                        {
                          html_instructions: "Turn <b>left</b>",
                          duration: { text: "1 min", value: 44 },
                          maneuver: "turn-left",
                          polyline: {
                            points:
                              "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                          },
                          end_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                          distance: { value: 426, text: "0.4 km" },
                          start_location: { lat: 25.2801532, lng: 51.4871118 },
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          polyline: { points: "cphyCoxgyHEaBAsA" },
                          html_instructions:
                            "Continue straight onto <b>Al Sadd St</b>",
                          end_location: { lat: 25.2802165, lng: 51.4917143 },
                          maneuver: "straight",
                          distance: { value: 93, text: "93 m" },
                          start_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                        },
                        {
                          distance: { value: 236, text: "0.2 km" },
                          html_instructions: "Slight <b>left</b>",
                          polyline: {
                            points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                          },
                          start_location: { lat: 25.2802165, lng: 51.4917143 },
                          travel_mode: "DRIVING",
                          end_location: { lat: 25.2804496, lng: 51.4940386 },
                          duration: { text: "1 min", value: 71 },
                          maneuver: "turn-slight-left",
                        },
                        {
                          duration: { text: "1 min", value: 38 },
                          end_location: { lat: 25.281604, lng: 51.4947254 },
                          polyline: {
                            points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                          },
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          start_location: { lng: 51.4940386, lat: 25.2804496 },
                          distance: { value: 163, text: "0.2 km" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                        },
                        {
                          duration: { text: "1 min", value: 76 },
                          polyline: {
                            points:
                              "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                          },
                          start_location: { lat: 25.281604, lng: 51.4947254 },
                          html_instructions:
                            "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                          maneuver: "keep-left",
                          end_location: { lng: 51.4965617, lat: 25.2914195 },
                          travel_mode: "DRIVING",
                          distance: { text: "1.1 km", value: 1109 },
                        },
                        {
                          start_location: { lat: 25.2914195, lng: 51.4965617 },
                          maneuver: "turn-right",
                          duration: { value: 209, text: "3 mins" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                          polyline: {
                            points:
                              "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                          },
                          end_location: {
                            lat: 25.2893902,
                            lng: 51.51516729999999,
                          },
                          distance: { value: 1922, text: "1.9 km" },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { value: 144, text: "0.1 km" },
                          start_location: {
                            lng: 51.51516729999999,
                            lat: 25.2893902,
                          },
                          end_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej</b>",
                          duration: { value: 15, text: "1 min" },
                          travel_mode: "DRIVING",
                        },
                        {
                          polyline: {
                            points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                          },
                          duration: { text: "1 min", value: 11 },
                          end_location: { lng: 51.516228, lat: 25.2874383 },
                          start_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          travel_mode: "DRIVING",
                          distance: { text: "0.1 km", value: 122 },
                          html_instructions:
                            'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-slight-right",
                          duration: { text: "1 min", value: 21 },
                          polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                          start_location: { lng: 51.516228, lat: 25.2874383 },
                          end_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          distance: { text: "0.3 km", value: 283 },
                          html_instructions:
                            'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          start_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                          duration: { text: "1 min", value: 32 },
                          polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                          distance: { text: "0.1 km", value: 125 },
                          end_location: { lng: 51.5155496, lat: 25.2848165 },
                        },
                        {
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          duration: { text: "1 min", value: 21 },
                          maneuver: "turn-right",
                          distance: { value: 74, text: "74 m" },
                          polyline: {
                            points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                          },
                          end_location: { lat: 25.2850716, lng: 51.5150352 },
                          start_location: { lat: 25.2848165, lng: 51.5155496 },
                        },
                        {
                          polyline: { points: "uniyC_plyHqBJ" },
                          duration: { text: "1 min", value: 23 },
                          end_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          start_location: { lat: 25.2850716, lng: 51.5150352 },
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          distance: { value: 64, text: "64 m" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 14 },
                          start_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                          distance: { text: "39 m", value: 39 },
                          polyline: { points: "griyCsolyH?p@DZ" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          maneuver: "turn-left",
                        },
                      ],
                      start_address: "858, الدوحة، Qatar",
                      end_address: "7GP7+6RG, Doha, Qatar",
                      traffic_speed_entry: [],
                      duration: { value: 636, text: "11 mins" },
                      distance: { text: "5.4 km", value: 5407 },
                      via_waypoint: [],
                    },
                    {
                      start_address: "7GP7+6RG, Doha, Qatar",
                      duration: { value: 598, text: "10 mins" },
                      traffic_speed_entry: [],
                      start_location: { lat: 25.2856124, lng: 51.5145907 },
                      steps: [
                        {
                          duration: { value: 73, text: "1 min" },
                          end_location: { lat: 25.2859334, lng: 51.5165533 },
                          start_location: { lat: 25.2856124, lng: 51.5145907 },
                          distance: { text: "0.2 km", value: 201 },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                          },
                        },
                        {
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                          distance: { text: "0.7 km", value: 735 },
                          end_location: { lng: 51.518743, lat: 25.2796568 },
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                          },
                          duration: { text: "1 min", value: 78 },
                          start_location: { lng: 51.5165533, lat: 25.2859334 },
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { text: "0.4 km", value: 393 },
                          end_location: { lat: 25.2769643, lng: 51.5164061 },
                          maneuver: "turn-slight-right",
                          start_location: { lat: 25.2796568, lng: 51.518743 },
                          polyline: {
                            points:
                              "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                          },
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                          duration: { text: "1 min", value: 59 },
                        },
                        {
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.5164061, lat: 25.2769643 },
                          polyline: {
                            points:
                              "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                          },
                          end_location: { lng: 51.500835, lat: 25.2663847 },
                          duration: { value: 229, text: "4 mins" },
                          distance: { value: 1963, text: "2.0 km" },
                          maneuver: "merge",
                          html_instructions:
                            "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                        },
                        {
                          maneuver: "turn-slight-right",
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                          end_location: { lng: 51.497229, lat: 25.2643805 },
                          distance: { text: "0.4 km", value: 428 },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2663847, lng: 51.500835 },
                          polyline: {
                            points:
                              "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                          },
                          duration: { value: 40, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                          maneuver: "roundabout-right",
                          polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                          duration: { value: 22, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.497229, lat: 25.2643805 },
                          distance: { text: "0.2 km", value: 182 },
                          end_location: { lng: 51.4962302, lat: 25.2656775 },
                        },
                        {
                          polyline: { points: "oueyCmzhyH]DKB" },
                          start_location: { lat: 25.2656775, lng: 51.4962302 },
                          end_location: { lat: 25.2658942, lng: 51.4961765 },
                          travel_mode: "DRIVING",
                          distance: { value: 25, text: "25 m" },
                          maneuver: "turn-slight-right",
                          html_instructions:
                            "Slight <b>right</b> onto <b>Al Nasr St</b>",
                          duration: { value: 3, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                          maneuver: "turn-slight-right",
                          end_location: { lng: 51.4995317, lat: 25.267547 },
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                          },
                          distance: { value: 389, text: "0.4 km" },
                          duration: { value: 53, text: "1 min" },
                          start_location: { lat: 25.2658942, lng: 51.4961765 },
                        },
                        {
                          distance: { value: 111, text: "0.1 km" },
                          start_location: { lat: 25.267547, lng: 51.4995317 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohammed St</b>",
                          duration: { value: 22, text: "1 min" },
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          travel_mode: "DRIVING",
                          polyline: { points: "eafyCaoiyHQFiDdA" },
                          maneuver: "turn-left",
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { text: "62 m", value: 62 },
                          duration: { text: "1 min", value: 19 },
                          polyline: { points: "agfyCsliyH\\vABNAJ" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          start_location: { lng: 51.499144, lat: 25.2684856 },
                        },
                      ],
                      via_waypoint: [],
                      end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                      distance: { text: "4.5 km", value: 4489 },
                      end_address: "7F9X+C9V, Doha, Qatar",
                    },
                    {
                      end_address: "28 Street 8, Ar Rayyan, Qatar",
                      start_address: "7F9X+C9V, Doha, Qatar",
                      via_waypoint: [],
                      duration: { text: "13 mins", value: 795 },
                      start_location: {
                        lng: 51.49855549999999,
                        lat: 25.2683307,
                      },
                      distance: { value: 11216, text: "11.2 km" },
                      end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                      steps: [
                        {
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          start_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { value: 62, text: "62 m" },
                          duration: { text: "1 min", value: 14 },
                          polyline: { points: "affyC_iiyH@KCO]wA" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                        },
                        {
                          distance: { value: 28, text: "28 m" },
                          duration: { value: 9, text: "1 min" },
                          maneuver: "turn-left",
                          start_location: { lng: 51.499144, lat: 25.2684856 },
                          end_location: { lng: 51.499066, lat: 25.2687298 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                          travel_mode: "DRIVING",
                          polyline: { points: "agfyCsliyHo@L" },
                        },
                        {
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Baraha</b>",
                          polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          distance: { text: "0.2 km", value: 154 },
                          end_location: { lat: 25.2692232, lng: 51.500496 },
                          duration: { value: 32, text: "1 min" },
                          start_location: { lng: 51.499066, lat: 25.2687298 },
                        },
                        {
                          end_location: { lng: 51.500265, lat: 25.269806 },
                          polyline: { points: "skfyCcuiyH{@Ty@X" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                          duration: { value: 13, text: "1 min" },
                          distance: { text: "69 m", value: 69 },
                          maneuver: "turn-left",
                          start_location: { lng: 51.500496, lat: 25.2692232 },
                        },
                        {
                          polyline: {
                            points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                          },
                          start_location: { lat: 25.269806, lng: 51.500265 },
                          distance: { text: "0.2 km", value: 223 },
                          duration: { value: 29, text: "1 min" },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          end_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                        },
                        {
                          polyline: {
                            points:
                              "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                          },
                          duration: { value: 48, text: "1 min" },
                          end_location: { lng: 51.5033973, lat: 25.2679045 },
                          travel_mode: "DRIVING",
                          maneuver: "roundabout-right",
                          distance: { text: "0.3 km", value: 326 },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                          start_location: {
                            lng: 51.50232339999999,
                            lat: 25.2705523,
                          },
                        },
                        {
                          end_location: { lng: 51.45117, lat: 25.2368244 },
                          polyline: {
                            points:
                              "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                          },
                          duration: { value: 283, text: "5 mins" },
                          html_instructions: "Merge onto <b>Salwa Rd</b>",
                          start_location: { lng: 51.5033973, lat: 25.2679045 },
                          travel_mode: "DRIVING",
                          distance: { text: "6.3 km", value: 6288 },
                          maneuver: "merge",
                        },
                        {
                          start_location: { lng: 51.45117, lat: 25.2368244 },
                          maneuver: "ramp-right",
                          duration: { text: "1 min", value: 10 },
                          travel_mode: "DRIVING",
                          distance: { text: "0.2 km", value: 194 },
                          html_instructions:
                            'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                          polyline: { points: "ca`yCy``yHpAnDlAdD" },
                          end_location: { lng: 51.4494587, lat: 25.2360177 },
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { value: 397, text: "0.4 km" },
                          end_location: { lat: 25.2340694, lng: 51.4461502 },
                          duration: { text: "1 min", value: 33 },
                          html_instructions:
                            "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          maneuver: "merge",
                          start_location: { lat: 25.2360177, lng: 51.4494587 },
                          polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                        },
                        {
                          duration: { value: 8, text: "1 min" },
                          maneuver: "straight",
                          start_location: { lng: 51.4461502, lat: 25.2340694 },
                          html_instructions:
                            "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          end_location: { lat: 25.2337942, lng: 51.4456607 },
                          polyline: { points: "}o_yCma_yHP`@d@~@" },
                          distance: { text: "58 m", value: 58 },
                          travel_mode: "DRIVING",
                        },
                        {
                          maneuver: "straight",
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.4456607, lat: 25.2337942 },
                          duration: { text: "2 mins", value: 90 },
                          end_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          html_instructions:
                            'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                          polyline: {
                            points:
                              "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                          },
                          distance: { value: 1451, text: "1.5 km" },
                        },
                        {
                          duration: { text: "1 min", value: 10 },
                          html_instructions:
                            "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                          travel_mode: "DRIVING",
                          distance: { value: 92, text: "92 m" },
                          end_location: { lng: 51.4328437, lat: 25.2262036 },
                          start_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          maneuver: "turn-slight-left",
                          polyline: {
                            points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                          },
                        },
                        {
                          end_location: { lat: 25.2257335, lng: 51.4323655 },
                          html_instructions: "Continue straight",
                          maneuver: "straight",
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 19 },
                          polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                          distance: { value: 71, text: "71 m" },
                          start_location: { lat: 25.2262036, lng: 51.4328437 },
                        },
                        {
                          polyline: {
                            points:
                              "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                          },
                          travel_mode: "DRIVING",
                          duration: { value: 72, text: "1 min" },
                          distance: { text: "0.8 km", value: 813 },
                          start_location: { lng: 51.4323655, lat: 25.2257335 },
                          html_instructions:
                            "Continue onto <b>E Industrial Rd</b>",
                          end_location: { lng: 51.4357808, lat: 25.2196178 },
                        },
                        {
                          distance: { value: 263, text: "0.3 km" },
                          maneuver: "turn-right",
                          polyline: {
                            points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                          },
                          end_location: {
                            lat: 25.2182661,
                            lng: 51.43371639999999,
                          },
                          start_location: { lat: 25.2196178, lng: 51.4357808 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 2</b>",
                          duration: { text: "1 min", value: 27 },
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          end_location: { lat: 25.2135618, lng: 51.4366206 },
                          duration: { value: 78, text: "1 min" },
                          maneuver: "roundabout-right",
                          start_location: {
                            lng: 51.43371639999999,
                            lat: 25.2182661,
                          },
                          distance: { value: 682, text: "0.7 km" },
                          html_instructions:
                            "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                          polyline: {
                            points:
                              "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                          },
                        },
                        {
                          distance: { text: "29 m", value: 29 },
                          start_location: { lat: 25.2135618, lng: 51.4366206 },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 8</b>",
                          end_location: { lng: 51.4364944, lat: 25.2133289 },
                          duration: { value: 12, text: "1 min" },
                          polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                        },
                        {
                          distance: { value: 16, text: "16 m" },
                          maneuver: "turn-left",
                          duration: { value: 8, text: "1 min" },
                          travel_mode: "DRIVING",
                          end_location: {
                            lat: 25.2132031,
                            lng: 51.43657289999999,
                          },
                          start_location: { lng: 51.4364944, lat: 25.2133289 },
                          polyline: { points: "in{xCae}xHXO" },
                          html_instructions: "Turn <b>left</b>",
                        },
                      ],
                      traffic_speed_entry: [],
                    },
                  ],
                  waypoint_order: [0, 1],
                  warnings: [],
                  copyrights: "Map data ©2024 Google",
                  overview_polyline: {
                    points:
                      "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                  },
                },
              ],
            },
            completedAt: 1707942203548,
            destination: {
              coordinates: {
                latitude: 25.213204,
                longitude: 51.43657469999999,
              },
              geohash: "thkx6myvx1",
              type: "corporate-branch",
              address: "Street 8, Doha, Qatar",
              id: "9VZwCrYZfl03ob5FrD4F.0",
            },
            status: "completed",
            skin: null,
            origin: {
              geohash: "thkxgdt7m0",
              address: "858, الدوحة، Qatar",
              coordinates: { longitude: 51.489361, latitude: 25.282848 },
            },
            preferences: {
              female_passengers_only: null,
              verified_passengers_only: null,
            },
            car: {
              seat: 3,
              co2_p_km: 0.3379843740994449,
              year: 2021,
              make: "Toyota",
              type: "carbon-emitting",
              model: "LAND CRUSER WAGON 4WD",
            },
            rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
            createdAt: 1707941970485,
            passengersArray: [
              {
                userId: "user_id",
                name: "Jassim",
                username: "abcd",
                location: {
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                  coordinates: { latitude: 25.282848, longitude: 51.489361 },
                },
                credits: 27.8,
                co2Amount: 4.313694566631215,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                seat: 0,
                id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                wallet_id: "spencer_carbon.testnet",
                carpool: {
                  id: "8292058029514",
                  owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                  co2Amount: 12.612562888268984,
                  directions: {
                    status: "OK",
                    geocoded_waypoints: [
                      {
                        geocoder_status: "OK",
                        types: [
                          "establishment",
                          "moving_company",
                          "point_of_interest",
                        ],
                        place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                      },
                      {
                        place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                        types: [
                          "establishment",
                          "general_contractor",
                          "point_of_interest",
                        ],
                        geocoder_status: "OK",
                      },
                      {
                        types: ["premise"],
                        place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                        geocoder_status: "OK",
                      },
                      {
                        types: ["street_address"],
                        geocoder_status: "OK",
                        place_id:
                          "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                      },
                    ],
                    routes: [
                      {
                        summary: "Al Rayyan Rd",
                        bounds: {
                          northeast: { lng: 51.518743, lat: 25.291958 },
                          southwest: { lat: 25.2132031, lng: 51.4319062 },
                        },
                        legs: [
                          {
                            end_location: { lat: 25.2856124, lng: 51.5145907 },
                            start_location: {
                              lat: 25.2827107,
                              lng: 51.4906083,
                            },
                            steps: [
                              {
                                polyline: { points: "}_iyCiwgyHz@B" },
                                end_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                start_location: {
                                  lat: 25.2827107,
                                  lng: 51.4906083,
                                },
                                distance: { value: 33, text: "33 m" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                duration: { value: 4, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                travel_mode: "DRIVING",
                                distance: { value: 574, text: "0.6 km" },
                                duration: { value: 49, text: "1 min" },
                                end_location: {
                                  lng: 51.4871118,
                                  lat: 25.2801532,
                                },
                                polyline: {
                                  points:
                                    "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                },
                                start_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                              },
                              {
                                html_instructions: "Turn <b>left</b>",
                                duration: { text: "1 min", value: 44 },
                                maneuver: "turn-left",
                                polyline: {
                                  points:
                                    "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                },
                                end_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                                distance: { value: 426, text: "0.4 km" },
                                start_location: {
                                  lat: 25.2801532,
                                  lng: 51.4871118,
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                polyline: { points: "cphyCoxgyHEaBAsA" },
                                html_instructions:
                                  "Continue straight onto <b>Al Sadd St</b>",
                                end_location: {
                                  lat: 25.2802165,
                                  lng: 51.4917143,
                                },
                                maneuver: "straight",
                                distance: { value: 93, text: "93 m" },
                                start_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                              },
                              {
                                distance: { value: 236, text: "0.2 km" },
                                html_instructions: "Slight <b>left</b>",
                                polyline: {
                                  points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                },
                                start_location: {
                                  lat: 25.2802165,
                                  lng: 51.4917143,
                                },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                                duration: { text: "1 min", value: 71 },
                                maneuver: "turn-slight-left",
                              },
                              {
                                duration: { text: "1 min", value: 38 },
                                end_location: {
                                  lat: 25.281604,
                                  lng: 51.4947254,
                                },
                                polyline: {
                                  points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                },
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.4940386,
                                  lat: 25.2804496,
                                },
                                distance: { value: 163, text: "0.2 km" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                              },
                              {
                                duration: { text: "1 min", value: 76 },
                                polyline: {
                                  points:
                                    "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                },
                                start_location: {
                                  lat: 25.281604,
                                  lng: 51.4947254,
                                },
                                html_instructions:
                                  "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                maneuver: "keep-left",
                                end_location: {
                                  lng: 51.4965617,
                                  lat: 25.2914195,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "1.1 km", value: 1109 },
                              },
                              {
                                start_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                                maneuver: "turn-right",
                                duration: { value: 209, text: "3 mins" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                polyline: {
                                  points:
                                    "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                },
                                end_location: {
                                  lat: 25.2893902,
                                  lng: 51.51516729999999,
                                },
                                distance: { value: 1922, text: "1.9 km" },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { value: 144, text: "0.1 km" },
                                start_location: {
                                  lng: 51.51516729999999,
                                  lat: 25.2893902,
                                },
                                end_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                duration: { value: 15, text: "1 min" },
                                travel_mode: "DRIVING",
                              },
                              {
                                polyline: {
                                  points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                },
                                duration: { text: "1 min", value: 11 },
                                end_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                start_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "0.1 km", value: 122 },
                                html_instructions:
                                  'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-slight-right",
                                duration: { text: "1 min", value: 21 },
                                polyline: {
                                  points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                },
                                start_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                end_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                distance: { text: "0.3 km", value: 283 },
                                html_instructions:
                                  'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-right",
                                start_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                duration: { text: "1 min", value: 32 },
                                polyline: {
                                  points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                },
                                distance: { text: "0.1 km", value: 125 },
                                end_location: {
                                  lng: 51.5155496,
                                  lat: 25.2848165,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                duration: { text: "1 min", value: 21 },
                                maneuver: "turn-right",
                                distance: { value: 74, text: "74 m" },
                                polyline: {
                                  points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                },
                                end_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                start_location: {
                                  lat: 25.2848165,
                                  lng: 51.5155496,
                                },
                              },
                              {
                                polyline: { points: "uniyC_plyHqBJ" },
                                duration: { text: "1 min", value: 23 },
                                end_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                start_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "turn-right",
                                distance: { value: 64, text: "64 m" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 14 },
                                start_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                end_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                distance: { text: "39 m", value: 39 },
                                polyline: { points: "griyCsolyH?p@DZ" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                maneuver: "turn-left",
                              },
                            ],
                            start_address: "858, الدوحة، Qatar",
                            end_address: "7GP7+6RG, Doha, Qatar",
                            traffic_speed_entry: [],
                            duration: { value: 636, text: "11 mins" },
                            distance: { text: "5.4 km", value: 5407 },
                            via_waypoint: [],
                          },
                          {
                            start_address: "7GP7+6RG, Doha, Qatar",
                            duration: { value: 598, text: "10 mins" },
                            traffic_speed_entry: [],
                            start_location: {
                              lat: 25.2856124,
                              lng: 51.5145907,
                            },
                            steps: [
                              {
                                duration: { value: 73, text: "1 min" },
                                end_location: {
                                  lat: 25.2859334,
                                  lng: 51.5165533,
                                },
                                start_location: {
                                  lat: 25.2856124,
                                  lng: 51.5145907,
                                },
                                distance: { text: "0.2 km", value: 201 },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                distance: { text: "0.7 km", value: 735 },
                                end_location: {
                                  lng: 51.518743,
                                  lat: 25.2796568,
                                },
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                },
                                duration: { text: "1 min", value: 78 },
                                start_location: {
                                  lng: 51.5165533,
                                  lat: 25.2859334,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { text: "0.4 km", value: 393 },
                                end_location: {
                                  lat: 25.2769643,
                                  lng: 51.5164061,
                                },
                                maneuver: "turn-slight-right",
                                start_location: {
                                  lat: 25.2796568,
                                  lng: 51.518743,
                                },
                                polyline: {
                                  points:
                                    "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                },
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                duration: { text: "1 min", value: 59 },
                              },
                              {
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.5164061,
                                  lat: 25.2769643,
                                },
                                polyline: {
                                  points:
                                    "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                },
                                end_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                duration: { value: 229, text: "4 mins" },
                                distance: { value: 1963, text: "2.0 km" },
                                maneuver: "merge",
                                html_instructions:
                                  "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                              },
                              {
                                maneuver: "turn-slight-right",
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                end_location: {
                                  lng: 51.497229,
                                  lat: 25.2643805,
                                },
                                distance: { text: "0.4 km", value: 428 },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2663847,
                                  lng: 51.500835,
                                },
                                polyline: {
                                  points:
                                    "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                },
                                duration: { value: 40, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                maneuver: "roundabout-right",
                                polyline: {
                                  points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                },
                                duration: { value: 22, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.497229,
                                  lat: 25.2643805,
                                },
                                distance: { text: "0.2 km", value: 182 },
                                end_location: {
                                  lng: 51.4962302,
                                  lat: 25.2656775,
                                },
                              },
                              {
                                polyline: { points: "oueyCmzhyH]DKB" },
                                start_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                end_location: {
                                  lat: 25.2658942,
                                  lng: 51.4961765,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 25, text: "25 m" },
                                maneuver: "turn-slight-right",
                                html_instructions:
                                  "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                duration: { value: 3, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                maneuver: "turn-slight-right",
                                end_location: {
                                  lng: 51.4995317,
                                  lat: 25.267547,
                                },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                },
                                distance: { value: 389, text: "0.4 km" },
                                duration: { value: 53, text: "1 min" },
                                start_location: {
                                  lat: 25.2658942,
                                  lng: 51.4961765,
                                },
                              },
                              {
                                distance: { value: 111, text: "0.1 km" },
                                start_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohammed St</b>",
                                duration: { value: 22, text: "1 min" },
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                travel_mode: "DRIVING",
                                polyline: { points: "eafyCaoiyHQFiDdA" },
                                maneuver: "turn-left",
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2683307,
                                  lng: 51.49855549999999,
                                },
                                distance: { text: "62 m", value: 62 },
                                duration: { text: "1 min", value: 19 },
                                polyline: { points: "agfyCsliyH\\vABNAJ" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                start_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                              },
                            ],
                            via_waypoint: [],
                            end_location: {
                              lat: 25.2683307,
                              lng: 51.49855549999999,
                            },
                            distance: { text: "4.5 km", value: 4489 },
                            end_address: "7F9X+C9V, Doha, Qatar",
                          },
                          {
                            end_address: "28 Street 8, Ar Rayyan, Qatar",
                            start_address: "7F9X+C9V, Doha, Qatar",
                            via_waypoint: [],
                            duration: { text: "13 mins", value: 795 },
                            start_location: {
                              lng: 51.49855549999999,
                              lat: 25.2683307,
                            },
                            distance: { value: 11216, text: "11.2 km" },
                            end_location: {
                              lat: 25.2132031,
                              lng: 51.43657289999999,
                            },
                            steps: [
                              {
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                start_location: {
                                  lat: 25.2683307,
                                  lng: 51.49855549999999,
                                },
                                distance: { value: 62, text: "62 m" },
                                duration: { text: "1 min", value: 14 },
                                polyline: { points: "affyC_iiyH@KCO]wA" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                              },
                              {
                                distance: { value: 28, text: "28 m" },
                                duration: { value: 9, text: "1 min" },
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                end_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                travel_mode: "DRIVING",
                                polyline: { points: "agfyCsliyHo@L" },
                              },
                              {
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Baraha</b>",
                                polyline: {
                                  points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                distance: { text: "0.2 km", value: 154 },
                                end_location: {
                                  lat: 25.2692232,
                                  lng: 51.500496,
                                },
                                duration: { value: 32, text: "1 min" },
                                start_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                              },
                              {
                                end_location: {
                                  lng: 51.500265,
                                  lat: 25.269806,
                                },
                                polyline: { points: "skfyCcuiyH{@Ty@X" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                duration: { value: 13, text: "1 min" },
                                distance: { text: "69 m", value: 69 },
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.500496,
                                  lat: 25.2692232,
                                },
                              },
                              {
                                polyline: {
                                  points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                },
                                start_location: {
                                  lat: 25.269806,
                                  lng: 51.500265,
                                },
                                distance: { text: "0.2 km", value: 223 },
                                duration: { value: 29, text: "1 min" },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                              },
                              {
                                polyline: {
                                  points:
                                    "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                },
                                duration: { value: 48, text: "1 min" },
                                end_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "roundabout-right",
                                distance: { text: "0.3 km", value: 326 },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                start_location: {
                                  lng: 51.50232339999999,
                                  lat: 25.2705523,
                                },
                              },
                              {
                                end_location: {
                                  lng: 51.45117,
                                  lat: 25.2368244,
                                },
                                polyline: {
                                  points:
                                    "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                },
                                duration: { value: 283, text: "5 mins" },
                                html_instructions: "Merge onto <b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "6.3 km", value: 6288 },
                                maneuver: "merge",
                              },
                              {
                                start_location: {
                                  lng: 51.45117,
                                  lat: 25.2368244,
                                },
                                maneuver: "ramp-right",
                                duration: { text: "1 min", value: 10 },
                                travel_mode: "DRIVING",
                                distance: { text: "0.2 km", value: 194 },
                                html_instructions:
                                  'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                polyline: { points: "ca`yCy``yHpAnDlAdD" },
                                end_location: {
                                  lng: 51.4494587,
                                  lat: 25.2360177,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { value: 397, text: "0.4 km" },
                                end_location: {
                                  lat: 25.2340694,
                                  lng: 51.4461502,
                                },
                                duration: { text: "1 min", value: 33 },
                                html_instructions:
                                  "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                maneuver: "merge",
                                start_location: {
                                  lat: 25.2360177,
                                  lng: 51.4494587,
                                },
                                polyline: {
                                  points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                },
                              },
                              {
                                duration: { value: 8, text: "1 min" },
                                maneuver: "straight",
                                start_location: {
                                  lng: 51.4461502,
                                  lat: 25.2340694,
                                },
                                html_instructions:
                                  "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                end_location: {
                                  lat: 25.2337942,
                                  lng: 51.4456607,
                                },
                                polyline: { points: "}o_yCma_yHP`@d@~@" },
                                distance: { text: "58 m", value: 58 },
                                travel_mode: "DRIVING",
                              },
                              {
                                maneuver: "straight",
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.4456607,
                                  lat: 25.2337942,
                                },
                                duration: { text: "2 mins", value: 90 },
                                end_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                html_instructions:
                                  'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                                polyline: {
                                  points:
                                    "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                },
                                distance: { value: 1451, text: "1.5 km" },
                              },
                              {
                                duration: { text: "1 min", value: 10 },
                                html_instructions:
                                  "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                travel_mode: "DRIVING",
                                distance: { value: 92, text: "92 m" },
                                end_location: {
                                  lng: 51.4328437,
                                  lat: 25.2262036,
                                },
                                start_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                maneuver: "turn-slight-left",
                                polyline: {
                                  points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                },
                              },
                              {
                                end_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                html_instructions: "Continue straight",
                                maneuver: "straight",
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 19 },
                                polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                                distance: { value: 71, text: "71 m" },
                                start_location: {
                                  lat: 25.2262036,
                                  lng: 51.4328437,
                                },
                              },
                              {
                                polyline: {
                                  points:
                                    "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                },
                                travel_mode: "DRIVING",
                                duration: { value: 72, text: "1 min" },
                                distance: { text: "0.8 km", value: 813 },
                                start_location: {
                                  lng: 51.4323655,
                                  lat: 25.2257335,
                                },
                                html_instructions:
                                  "Continue onto <b>E Industrial Rd</b>",
                                end_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                              },
                              {
                                distance: { value: 263, text: "0.3 km" },
                                maneuver: "turn-right",
                                polyline: {
                                  points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                },
                                end_location: {
                                  lat: 25.2182661,
                                  lng: 51.43371639999999,
                                },
                                start_location: {
                                  lat: 25.2196178,
                                  lng: 51.4357808,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 2</b>",
                                duration: { text: "1 min", value: 27 },
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2135618,
                                  lng: 51.4366206,
                                },
                                duration: { value: 78, text: "1 min" },
                                maneuver: "roundabout-right",
                                start_location: {
                                  lng: 51.43371639999999,
                                  lat: 25.2182661,
                                },
                                distance: { value: 682, text: "0.7 km" },
                                html_instructions:
                                  "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                polyline: {
                                  points:
                                    "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                },
                              },
                              {
                                distance: { text: "29 m", value: 29 },
                                start_location: {
                                  lat: 25.2135618,
                                  lng: 51.4366206,
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 8</b>",
                                end_location: {
                                  lng: 51.4364944,
                                  lat: 25.2133289,
                                },
                                duration: { value: 12, text: "1 min" },
                                polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                              },
                              {
                                distance: { value: 16, text: "16 m" },
                                maneuver: "turn-left",
                                duration: { value: 8, text: "1 min" },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2132031,
                                  lng: 51.43657289999999,
                                },
                                start_location: {
                                  lng: 51.4364944,
                                  lat: 25.2133289,
                                },
                                polyline: { points: "in{xCae}xHXO" },
                                html_instructions: "Turn <b>left</b>",
                              },
                            ],
                            traffic_speed_entry: [],
                          },
                        ],
                        waypoint_order: [0, 1],
                        warnings: [],
                        copyrights: "Map data ©2024 Google",
                        overview_polyline: {
                          points:
                            "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                        },
                      },
                    ],
                  },
                  completedAt: 1707942203548,
                  destination: {
                    coordinates: {
                      latitude: 25.213204,
                      longitude: 51.43657469999999,
                    },
                    geohash: "thkx6myvx1",
                    type: "corporate-branch",
                    address: "Street 8, Doha, Qatar",
                    id: "9VZwCrYZfl03ob5FrD4F.0",
                  },
                  status: "completed",
                  skin: null,
                  origin: {
                    geohash: "thkxgdt7m0",
                    address: "858, الدوحة، Qatar",
                    coordinates: { longitude: 51.489361, latitude: 25.282848 },
                  },
                  preferences: {
                    female_passengers_only: null,
                    verified_passengers_only: null,
                  },
                  car: {
                    seat: 3,
                    co2_p_km: 0.3379843740994449,
                    year: 2021,
                    make: "Toyota",
                    type: "carbon-emitting",
                    model: "LAND CRUSER WAGON 4WD",
                  },
                  rules: [
                    "No smoking",
                    "No eating or drinking",
                    "Keep off the AC",
                  ],
                  createdAt: 1707941970485,
                  passengersArray: [
                    {
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      wallet_id: "spencer_carbon.testnet",
                      location: {
                        geohash: "thkxgdt7m0",
                        address: "858, الدوحة، Qatar",
                        coordinates: {
                          latitude: 25.282848,
                          longitude: 51.489361,
                        },
                      },
                      co2Amount: 4.313694566631215,
                      seat: 0,
                      name: "Jassim",
                      id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                      username: "abcd",
                      credits: 27.8,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                    },
                    {
                      co2Amount: 4.508035581738396,
                      seat: 2,
                      wallet_id: "sofia_carbon.testnet",
                      username: "michaelscott",
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                      status: "picked-up",
                      location: {
                        coordinates: {
                          longitude: 51.514615,
                          latitude: 25.285509,
                        },
                        geohash: "thkxu5pfc8",
                        address: "7GP7+6RG, Doha, Qatar",
                      },
                      credits: 13.9,
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                      name: "Ahmed",
                    },
                    {
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                      status: "picked-up",
                      seat: 1,
                      location: {
                        address: "7F9X+C9V, Doha, Qatar",
                        coordinates: {
                          latitude: 25.268246,
                          longitude: 51.498513,
                        },
                        geohash: "thkxezun4q",
                      },
                      co2Amount: 3.7908327398993733,
                      wallet_id: "sarah_carbon.testnet",
                      credits: 13,
                      username: "sarah",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                      name: "Faisal",
                    },
                  ],
                },
                type: "carpool_passenger",
                corporate: "9VZwCrYZfl03ob5FrD4F",
              },
              {
                co2Amount: 4.508035581738396,
                seat: 2,
                wallet_id: "sofia_carbon.testnet",
                username: "michaelscott",
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                status: "picked-up",
                location: {
                  coordinates: { longitude: 51.514615, latitude: 25.285509 },
                  geohash: "thkxu5pfc8",
                  address: "7GP7+6RG, Doha, Qatar",
                },
                credits: 13.9,
                corporate: "9VZwCrYZfl03ob5FrD4F",
                id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                name: "Ahmed",
              },
              {
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                status: "picked-up",
                seat: 1,
                location: {
                  address: "7F9X+C9V, Doha, Qatar",
                  coordinates: { latitude: 25.268246, longitude: 51.498513 },
                  geohash: "thkxezun4q",
                },
                co2Amount: 3.7908327398993733,
                wallet_id: "sarah_carbon.testnet",
                credits: 13,
                username: "sarah",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                name: "Faisal",
              },
            ],
          },
          type: "carpool_passenger",
          corporate: "9VZwCrYZfl03ob5FrD4F",
        },
        "6Qj01PCY1lfLasd74HFgTxpcDRt1": {
          userId: "user_id",
          name: "Faisal",
          username: "sarah",
          location: {
            address: "7F9X+C9V, Doha, Qatar",
            coordinates: { latitude: 25.268246, longitude: 51.498513 },
            geohash: "thkxezun4q",
          },
          credits: 13,
          co2Amount: 3.7908327398993733,
          avatar:
            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
          seat: 1,
          id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
          wallet_id: "sarah_carbon.testnet",
          status: "picked-up",
          carpool: {
            id: "8292058029514",
            owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
            co2Amount: 12.612562888268984,
            directions: {
              status: "OK",
              geocoded_waypoints: [
                {
                  geocoder_status: "OK",
                  types: [
                    "establishment",
                    "moving_company",
                    "point_of_interest",
                  ],
                  place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                },
                {
                  place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                  types: [
                    "establishment",
                    "general_contractor",
                    "point_of_interest",
                  ],
                  geocoder_status: "OK",
                },
                {
                  types: ["premise"],
                  place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                  geocoder_status: "OK",
                },
                {
                  types: ["street_address"],
                  geocoder_status: "OK",
                  place_id:
                    "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                },
              ],
              routes: [
                {
                  summary: "Al Rayyan Rd",
                  bounds: {
                    northeast: { lng: 51.518743, lat: 25.291958 },
                    southwest: { lat: 25.2132031, lng: 51.4319062 },
                  },
                  legs: [
                    {
                      end_location: { lat: 25.2856124, lng: 51.5145907 },
                      start_location: { lat: 25.2827107, lng: 51.4906083 },
                      steps: [
                        {
                          polyline: { points: "}_iyCiwgyHz@B" },
                          end_location: { lng: 51.4905936, lat: 25.2824136 },
                          start_location: { lat: 25.2827107, lng: 51.4906083 },
                          distance: { value: 33, text: "33 m" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                          duration: { value: 4, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                          travel_mode: "DRIVING",
                          distance: { value: 574, text: "0.6 km" },
                          duration: { value: 49, text: "1 min" },
                          end_location: { lng: 51.4871118, lat: 25.2801532 },
                          polyline: {
                            points:
                              "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                          },
                          start_location: { lng: 51.4905936, lat: 25.2824136 },
                        },
                        {
                          html_instructions: "Turn <b>left</b>",
                          duration: { text: "1 min", value: 44 },
                          maneuver: "turn-left",
                          polyline: {
                            points:
                              "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                          },
                          end_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                          distance: { value: 426, text: "0.4 km" },
                          start_location: { lat: 25.2801532, lng: 51.4871118 },
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 8, text: "1 min" },
                          polyline: { points: "cphyCoxgyHEaBAsA" },
                          html_instructions:
                            "Continue straight onto <b>Al Sadd St</b>",
                          end_location: { lat: 25.2802165, lng: 51.4917143 },
                          maneuver: "straight",
                          distance: { value: 93, text: "93 m" },
                          start_location: {
                            lat: 25.2801774,
                            lng: 51.49079529999999,
                          },
                        },
                        {
                          distance: { value: 236, text: "0.2 km" },
                          html_instructions: "Slight <b>left</b>",
                          polyline: {
                            points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                          },
                          start_location: { lat: 25.2802165, lng: 51.4917143 },
                          travel_mode: "DRIVING",
                          end_location: { lat: 25.2804496, lng: 51.4940386 },
                          duration: { text: "1 min", value: 71 },
                          maneuver: "turn-slight-left",
                        },
                        {
                          duration: { text: "1 min", value: 38 },
                          end_location: { lat: 25.281604, lng: 51.4947254 },
                          polyline: {
                            points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                          },
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          start_location: { lng: 51.4940386, lat: 25.2804496 },
                          distance: { value: 163, text: "0.2 km" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                        },
                        {
                          duration: { text: "1 min", value: 76 },
                          polyline: {
                            points:
                              "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                          },
                          start_location: { lat: 25.281604, lng: 51.4947254 },
                          html_instructions:
                            "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                          maneuver: "keep-left",
                          end_location: { lng: 51.4965617, lat: 25.2914195 },
                          travel_mode: "DRIVING",
                          distance: { text: "1.1 km", value: 1109 },
                        },
                        {
                          start_location: { lat: 25.2914195, lng: 51.4965617 },
                          maneuver: "turn-right",
                          duration: { value: 209, text: "3 mins" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                          polyline: {
                            points:
                              "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                          },
                          end_location: {
                            lat: 25.2893902,
                            lng: 51.51516729999999,
                          },
                          distance: { value: 1922, text: "1.9 km" },
                          travel_mode: "DRIVING",
                        },
                        {
                          distance: { value: 144, text: "0.1 km" },
                          start_location: {
                            lng: 51.51516729999999,
                            lat: 25.2893902,
                          },
                          end_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej</b>",
                          duration: { value: 15, text: "1 min" },
                          travel_mode: "DRIVING",
                        },
                        {
                          polyline: {
                            points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                          },
                          duration: { text: "1 min", value: 11 },
                          end_location: { lng: 51.516228, lat: 25.2874383 },
                          start_location: {
                            lat: 25.2884741,
                            lng: 51.51591370000001,
                          },
                          travel_mode: "DRIVING",
                          distance: { text: "0.1 km", value: 122 },
                          html_instructions:
                            'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-slight-right",
                          duration: { text: "1 min", value: 21 },
                          polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                          start_location: { lng: 51.516228, lat: 25.2874383 },
                          end_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          distance: { text: "0.3 km", value: 283 },
                          html_instructions:
                            'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          start_location: {
                            lng: 51.51678399999999,
                            lat: 25.2849434,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                          duration: { text: "1 min", value: 32 },
                          polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                          distance: { text: "0.1 km", value: 125 },
                          end_location: { lng: 51.5155496, lat: 25.2848165 },
                        },
                        {
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          duration: { text: "1 min", value: 21 },
                          maneuver: "turn-right",
                          distance: { value: 74, text: "74 m" },
                          polyline: {
                            points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                          },
                          end_location: { lat: 25.2850716, lng: 51.5150352 },
                          start_location: { lat: 25.2848165, lng: 51.5155496 },
                        },
                        {
                          polyline: { points: "uniyC_plyHqBJ" },
                          duration: { text: "1 min", value: 23 },
                          end_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                          start_location: { lat: 25.2850716, lng: 51.5150352 },
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          distance: { value: 64, text: "64 m" },
                        },
                        {
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 14 },
                          start_location: {
                            lat: 25.2856417,
                            lng: 51.51497639999999,
                          },
                          end_location: { lng: 51.5145907, lat: 25.2856124 },
                          distance: { text: "39 m", value: 39 },
                          polyline: { points: "griyCsolyH?p@DZ" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          maneuver: "turn-left",
                        },
                      ],
                      start_address: "858, الدوحة، Qatar",
                      end_address: "7GP7+6RG, Doha, Qatar",
                      traffic_speed_entry: [],
                      duration: { value: 636, text: "11 mins" },
                      distance: { text: "5.4 km", value: 5407 },
                      via_waypoint: [],
                    },
                    {
                      start_address: "7GP7+6RG, Doha, Qatar",
                      duration: { value: 598, text: "10 mins" },
                      traffic_speed_entry: [],
                      start_location: { lat: 25.2856124, lng: 51.5145907 },
                      steps: [
                        {
                          duration: { value: 73, text: "1 min" },
                          end_location: { lat: 25.2859334, lng: 51.5165533 },
                          start_location: { lat: 25.2856124, lng: 51.5145907 },
                          distance: { text: "0.2 km", value: 201 },
                          html_instructions:
                            "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                          },
                        },
                        {
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                          distance: { text: "0.7 km", value: 735 },
                          end_location: { lng: 51.518743, lat: 25.2796568 },
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                          },
                          duration: { text: "1 min", value: 78 },
                          start_location: { lng: 51.5165533, lat: 25.2859334 },
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { text: "0.4 km", value: 393 },
                          end_location: { lat: 25.2769643, lng: 51.5164061 },
                          maneuver: "turn-slight-right",
                          start_location: { lat: 25.2796568, lng: 51.518743 },
                          polyline: {
                            points:
                              "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                          },
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                          duration: { text: "1 min", value: 59 },
                        },
                        {
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.5164061, lat: 25.2769643 },
                          polyline: {
                            points:
                              "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                          },
                          end_location: { lng: 51.500835, lat: 25.2663847 },
                          duration: { value: 229, text: "4 mins" },
                          distance: { value: 1963, text: "2.0 km" },
                          maneuver: "merge",
                          html_instructions:
                            "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                        },
                        {
                          maneuver: "turn-slight-right",
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                          end_location: { lng: 51.497229, lat: 25.2643805 },
                          distance: { text: "0.4 km", value: 428 },
                          travel_mode: "DRIVING",
                          start_location: { lat: 25.2663847, lng: 51.500835 },
                          polyline: {
                            points:
                              "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                          },
                          duration: { value: 40, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                          maneuver: "roundabout-right",
                          polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                          duration: { value: 22, text: "1 min" },
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.497229, lat: 25.2643805 },
                          distance: { text: "0.2 km", value: 182 },
                          end_location: { lng: 51.4962302, lat: 25.2656775 },
                        },
                        {
                          polyline: { points: "oueyCmzhyH]DKB" },
                          start_location: { lat: 25.2656775, lng: 51.4962302 },
                          end_location: { lat: 25.2658942, lng: 51.4961765 },
                          travel_mode: "DRIVING",
                          distance: { value: 25, text: "25 m" },
                          maneuver: "turn-slight-right",
                          html_instructions:
                            "Slight <b>right</b> onto <b>Al Nasr St</b>",
                          duration: { value: 3, text: "1 min" },
                        },
                        {
                          html_instructions:
                            "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                          maneuver: "turn-slight-right",
                          end_location: { lng: 51.4995317, lat: 25.267547 },
                          travel_mode: "DRIVING",
                          polyline: {
                            points:
                              "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                          },
                          distance: { value: 389, text: "0.4 km" },
                          duration: { value: 53, text: "1 min" },
                          start_location: { lat: 25.2658942, lng: 51.4961765 },
                        },
                        {
                          distance: { value: 111, text: "0.1 km" },
                          start_location: { lat: 25.267547, lng: 51.4995317 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohammed St</b>",
                          duration: { value: 22, text: "1 min" },
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          travel_mode: "DRIVING",
                          polyline: { points: "eafyCaoiyHQFiDdA" },
                          maneuver: "turn-left",
                        },
                        {
                          travel_mode: "DRIVING",
                          maneuver: "turn-left",
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { text: "62 m", value: 62 },
                          duration: { text: "1 min", value: 19 },
                          polyline: { points: "agfyCsliyH\\vABNAJ" },
                          html_instructions:
                            'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                          start_location: { lng: 51.499144, lat: 25.2684856 },
                        },
                      ],
                      via_waypoint: [],
                      end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                      distance: { text: "4.5 km", value: 4489 },
                      end_address: "7F9X+C9V, Doha, Qatar",
                    },
                    {
                      end_address: "28 Street 8, Ar Rayyan, Qatar",
                      start_address: "7F9X+C9V, Doha, Qatar",
                      via_waypoint: [],
                      duration: { text: "13 mins", value: 795 },
                      start_location: {
                        lng: 51.49855549999999,
                        lat: 25.2683307,
                      },
                      distance: { value: 11216, text: "11.2 km" },
                      end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                      steps: [
                        {
                          end_location: { lng: 51.499144, lat: 25.2684856 },
                          start_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { value: 62, text: "62 m" },
                          duration: { text: "1 min", value: 14 },
                          polyline: { points: "affyC_iiyH@KCO]wA" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                        },
                        {
                          distance: { value: 28, text: "28 m" },
                          duration: { value: 9, text: "1 min" },
                          maneuver: "turn-left",
                          start_location: { lng: 51.499144, lat: 25.2684856 },
                          end_location: { lng: 51.499066, lat: 25.2687298 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                          travel_mode: "DRIVING",
                          polyline: { points: "agfyCsliyHo@L" },
                        },
                        {
                          html_instructions:
                            "Turn <b>right</b> onto <b>Al Baraha</b>",
                          polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          distance: { text: "0.2 km", value: 154 },
                          end_location: { lat: 25.2692232, lng: 51.500496 },
                          duration: { value: 32, text: "1 min" },
                          start_location: { lng: 51.499066, lat: 25.2687298 },
                        },
                        {
                          end_location: { lng: 51.500265, lat: 25.269806 },
                          polyline: { points: "skfyCcuiyH{@Ty@X" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                          duration: { value: 13, text: "1 min" },
                          distance: { text: "69 m", value: 69 },
                          maneuver: "turn-left",
                          start_location: { lng: 51.500496, lat: 25.2692232 },
                        },
                        {
                          polyline: {
                            points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                          },
                          start_location: { lat: 25.269806, lng: 51.500265 },
                          distance: { text: "0.2 km", value: 223 },
                          duration: { value: 29, text: "1 min" },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          end_location: {
                            lat: 25.2705523,
                            lng: 51.50232339999999,
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                        },
                        {
                          polyline: {
                            points:
                              "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                          },
                          duration: { value: 48, text: "1 min" },
                          end_location: { lng: 51.5033973, lat: 25.2679045 },
                          travel_mode: "DRIVING",
                          maneuver: "roundabout-right",
                          distance: { text: "0.3 km", value: 326 },
                          html_instructions:
                            "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                          start_location: {
                            lng: 51.50232339999999,
                            lat: 25.2705523,
                          },
                        },
                        {
                          end_location: { lng: 51.45117, lat: 25.2368244 },
                          polyline: {
                            points:
                              "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                          },
                          duration: { value: 283, text: "5 mins" },
                          html_instructions: "Merge onto <b>Salwa Rd</b>",
                          start_location: { lng: 51.5033973, lat: 25.2679045 },
                          travel_mode: "DRIVING",
                          distance: { text: "6.3 km", value: 6288 },
                          maneuver: "merge",
                        },
                        {
                          start_location: { lng: 51.45117, lat: 25.2368244 },
                          maneuver: "ramp-right",
                          duration: { text: "1 min", value: 10 },
                          travel_mode: "DRIVING",
                          distance: { text: "0.2 km", value: 194 },
                          html_instructions:
                            'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                          polyline: { points: "ca`yCy``yHpAnDlAdD" },
                          end_location: { lng: 51.4494587, lat: 25.2360177 },
                        },
                        {
                          travel_mode: "DRIVING",
                          distance: { value: 397, text: "0.4 km" },
                          end_location: { lat: 25.2340694, lng: 51.4461502 },
                          duration: { text: "1 min", value: 33 },
                          html_instructions:
                            "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          maneuver: "merge",
                          start_location: { lat: 25.2360177, lng: 51.4494587 },
                          polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                        },
                        {
                          duration: { value: 8, text: "1 min" },
                          maneuver: "straight",
                          start_location: { lng: 51.4461502, lat: 25.2340694 },
                          html_instructions:
                            "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                          end_location: { lat: 25.2337942, lng: 51.4456607 },
                          polyline: { points: "}o_yCma_yHP`@d@~@" },
                          distance: { text: "58 m", value: 58 },
                          travel_mode: "DRIVING",
                        },
                        {
                          maneuver: "straight",
                          travel_mode: "DRIVING",
                          start_location: { lng: 51.4456607, lat: 25.2337942 },
                          duration: { text: "2 mins", value: 90 },
                          end_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          html_instructions:
                            'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                          polyline: {
                            points:
                              "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                          },
                          distance: { value: 1451, text: "1.5 km" },
                        },
                        {
                          duration: { text: "1 min", value: 10 },
                          html_instructions:
                            "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                          travel_mode: "DRIVING",
                          distance: { value: 92, text: "92 m" },
                          end_location: { lng: 51.4328437, lat: 25.2262036 },
                          start_location: {
                            lat: 25.2267513,
                            lng: 51.43351759999999,
                          },
                          maneuver: "turn-slight-left",
                          polyline: {
                            points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                          },
                        },
                        {
                          end_location: { lat: 25.2257335, lng: 51.4323655 },
                          html_instructions: "Continue straight",
                          maneuver: "straight",
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 19 },
                          polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                          distance: { value: 71, text: "71 m" },
                          start_location: { lat: 25.2262036, lng: 51.4328437 },
                        },
                        {
                          polyline: {
                            points:
                              "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                          },
                          travel_mode: "DRIVING",
                          duration: { value: 72, text: "1 min" },
                          distance: { text: "0.8 km", value: 813 },
                          start_location: { lng: 51.4323655, lat: 25.2257335 },
                          html_instructions:
                            "Continue onto <b>E Industrial Rd</b>",
                          end_location: { lng: 51.4357808, lat: 25.2196178 },
                        },
                        {
                          distance: { value: 263, text: "0.3 km" },
                          maneuver: "turn-right",
                          polyline: {
                            points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                          },
                          end_location: {
                            lat: 25.2182661,
                            lng: 51.43371639999999,
                          },
                          start_location: { lat: 25.2196178, lng: 51.4357808 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 2</b>",
                          duration: { text: "1 min", value: 27 },
                          travel_mode: "DRIVING",
                        },
                        {
                          travel_mode: "DRIVING",
                          end_location: { lat: 25.2135618, lng: 51.4366206 },
                          duration: { value: 78, text: "1 min" },
                          maneuver: "roundabout-right",
                          start_location: {
                            lng: 51.43371639999999,
                            lat: 25.2182661,
                          },
                          distance: { value: 682, text: "0.7 km" },
                          html_instructions:
                            "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                          polyline: {
                            points:
                              "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                          },
                        },
                        {
                          distance: { text: "29 m", value: 29 },
                          start_location: { lat: 25.2135618, lng: 51.4366206 },
                          maneuver: "turn-right",
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>right</b> onto <b>Street 8</b>",
                          end_location: { lng: 51.4364944, lat: 25.2133289 },
                          duration: { value: 12, text: "1 min" },
                          polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                        },
                        {
                          distance: { value: 16, text: "16 m" },
                          maneuver: "turn-left",
                          duration: { value: 8, text: "1 min" },
                          travel_mode: "DRIVING",
                          end_location: {
                            lat: 25.2132031,
                            lng: 51.43657289999999,
                          },
                          start_location: { lng: 51.4364944, lat: 25.2133289 },
                          polyline: { points: "in{xCae}xHXO" },
                          html_instructions: "Turn <b>left</b>",
                        },
                      ],
                      traffic_speed_entry: [],
                    },
                  ],
                  waypoint_order: [0, 1],
                  warnings: [],
                  copyrights: "Map data ©2024 Google",
                  overview_polyline: {
                    points:
                      "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                  },
                },
              ],
            },
            completedAt: 1707942203548,
            destination: {
              coordinates: {
                latitude: 25.213204,
                longitude: 51.43657469999999,
              },
              geohash: "thkx6myvx1",
              type: "corporate-branch",
              address: "Street 8, Doha, Qatar",
              id: "9VZwCrYZfl03ob5FrD4F.0",
            },
            status: "completed",
            skin: null,
            origin: {
              geohash: "thkxgdt7m0",
              address: "858, الدوحة، Qatar",
              coordinates: { longitude: 51.489361, latitude: 25.282848 },
            },
            preferences: {
              female_passengers_only: null,
              verified_passengers_only: null,
            },
            car: {
              seat: 3,
              co2_p_km: 0.3379843740994449,
              year: 2021,
              make: "Toyota",
              type: "carbon-emitting",
              model: "LAND CRUSER WAGON 4WD",
            },
            rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
            createdAt: 1707941970485,
            passengersArray: [
              {
                userId: "user_id",
                name: "Jassim",
                username: "abcd",
                location: {
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                  coordinates: { latitude: 25.282848, longitude: 51.489361 },
                },
                credits: 27.8,
                co2Amount: 4.313694566631215,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                seat: 0,
                id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                wallet_id: "spencer_carbon.testnet",
                carpool: {
                  id: "8292058029514",
                  owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                  co2Amount: 12.612562888268984,
                  directions: {
                    status: "OK",
                    geocoded_waypoints: [
                      {
                        geocoder_status: "OK",
                        types: [
                          "establishment",
                          "moving_company",
                          "point_of_interest",
                        ],
                        place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                      },
                      {
                        place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                        types: [
                          "establishment",
                          "general_contractor",
                          "point_of_interest",
                        ],
                        geocoder_status: "OK",
                      },
                      {
                        types: ["premise"],
                        place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                        geocoder_status: "OK",
                      },
                      {
                        types: ["street_address"],
                        geocoder_status: "OK",
                        place_id:
                          "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                      },
                    ],
                    routes: [
                      {
                        summary: "Al Rayyan Rd",
                        bounds: {
                          northeast: { lng: 51.518743, lat: 25.291958 },
                          southwest: { lat: 25.2132031, lng: 51.4319062 },
                        },
                        legs: [
                          {
                            end_location: { lat: 25.2856124, lng: 51.5145907 },
                            start_location: {
                              lat: 25.2827107,
                              lng: 51.4906083,
                            },
                            steps: [
                              {
                                polyline: { points: "}_iyCiwgyHz@B" },
                                end_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                start_location: {
                                  lat: 25.2827107,
                                  lng: 51.4906083,
                                },
                                distance: { value: 33, text: "33 m" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                duration: { value: 4, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                travel_mode: "DRIVING",
                                distance: { value: 574, text: "0.6 km" },
                                duration: { value: 49, text: "1 min" },
                                end_location: {
                                  lng: 51.4871118,
                                  lat: 25.2801532,
                                },
                                polyline: {
                                  points:
                                    "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                },
                                start_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                              },
                              {
                                html_instructions: "Turn <b>left</b>",
                                duration: { text: "1 min", value: 44 },
                                maneuver: "turn-left",
                                polyline: {
                                  points:
                                    "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                },
                                end_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                                distance: { value: 426, text: "0.4 km" },
                                start_location: {
                                  lat: 25.2801532,
                                  lng: 51.4871118,
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                polyline: { points: "cphyCoxgyHEaBAsA" },
                                html_instructions:
                                  "Continue straight onto <b>Al Sadd St</b>",
                                end_location: {
                                  lat: 25.2802165,
                                  lng: 51.4917143,
                                },
                                maneuver: "straight",
                                distance: { value: 93, text: "93 m" },
                                start_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                              },
                              {
                                distance: { value: 236, text: "0.2 km" },
                                html_instructions: "Slight <b>left</b>",
                                polyline: {
                                  points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                },
                                start_location: {
                                  lat: 25.2802165,
                                  lng: 51.4917143,
                                },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                                duration: { text: "1 min", value: 71 },
                                maneuver: "turn-slight-left",
                              },
                              {
                                duration: { text: "1 min", value: 38 },
                                end_location: {
                                  lat: 25.281604,
                                  lng: 51.4947254,
                                },
                                polyline: {
                                  points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                },
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.4940386,
                                  lat: 25.2804496,
                                },
                                distance: { value: 163, text: "0.2 km" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                              },
                              {
                                duration: { text: "1 min", value: 76 },
                                polyline: {
                                  points:
                                    "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                },
                                start_location: {
                                  lat: 25.281604,
                                  lng: 51.4947254,
                                },
                                html_instructions:
                                  "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                maneuver: "keep-left",
                                end_location: {
                                  lng: 51.4965617,
                                  lat: 25.2914195,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "1.1 km", value: 1109 },
                              },
                              {
                                start_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                                maneuver: "turn-right",
                                duration: { value: 209, text: "3 mins" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                polyline: {
                                  points:
                                    "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                },
                                end_location: {
                                  lat: 25.2893902,
                                  lng: 51.51516729999999,
                                },
                                distance: { value: 1922, text: "1.9 km" },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { value: 144, text: "0.1 km" },
                                start_location: {
                                  lng: 51.51516729999999,
                                  lat: 25.2893902,
                                },
                                end_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                duration: { value: 15, text: "1 min" },
                                travel_mode: "DRIVING",
                              },
                              {
                                polyline: {
                                  points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                },
                                duration: { text: "1 min", value: 11 },
                                end_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                start_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "0.1 km", value: 122 },
                                html_instructions:
                                  'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-slight-right",
                                duration: { text: "1 min", value: 21 },
                                polyline: {
                                  points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                },
                                start_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                end_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                distance: { text: "0.3 km", value: 283 },
                                html_instructions:
                                  'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-right",
                                start_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                duration: { text: "1 min", value: 32 },
                                polyline: {
                                  points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                },
                                distance: { text: "0.1 km", value: 125 },
                                end_location: {
                                  lng: 51.5155496,
                                  lat: 25.2848165,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                duration: { text: "1 min", value: 21 },
                                maneuver: "turn-right",
                                distance: { value: 74, text: "74 m" },
                                polyline: {
                                  points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                },
                                end_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                start_location: {
                                  lat: 25.2848165,
                                  lng: 51.5155496,
                                },
                              },
                              {
                                polyline: { points: "uniyC_plyHqBJ" },
                                duration: { text: "1 min", value: 23 },
                                end_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                start_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "turn-right",
                                distance: { value: 64, text: "64 m" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 14 },
                                start_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                end_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                distance: { text: "39 m", value: 39 },
                                polyline: { points: "griyCsolyH?p@DZ" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                maneuver: "turn-left",
                              },
                            ],
                            start_address: "858, الدوحة، Qatar",
                            end_address: "7GP7+6RG, Doha, Qatar",
                            traffic_speed_entry: [],
                            duration: { value: 636, text: "11 mins" },
                            distance: { text: "5.4 km", value: 5407 },
                            via_waypoint: [],
                          },
                          {
                            start_address: "7GP7+6RG, Doha, Qatar",
                            duration: { value: 598, text: "10 mins" },
                            traffic_speed_entry: [],
                            start_location: {
                              lat: 25.2856124,
                              lng: 51.5145907,
                            },
                            steps: [
                              {
                                duration: { value: 73, text: "1 min" },
                                end_location: {
                                  lat: 25.2859334,
                                  lng: 51.5165533,
                                },
                                start_location: {
                                  lat: 25.2856124,
                                  lng: 51.5145907,
                                },
                                distance: { text: "0.2 km", value: 201 },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                distance: { text: "0.7 km", value: 735 },
                                end_location: {
                                  lng: 51.518743,
                                  lat: 25.2796568,
                                },
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                },
                                duration: { text: "1 min", value: 78 },
                                start_location: {
                                  lng: 51.5165533,
                                  lat: 25.2859334,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { text: "0.4 km", value: 393 },
                                end_location: {
                                  lat: 25.2769643,
                                  lng: 51.5164061,
                                },
                                maneuver: "turn-slight-right",
                                start_location: {
                                  lat: 25.2796568,
                                  lng: 51.518743,
                                },
                                polyline: {
                                  points:
                                    "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                },
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                duration: { text: "1 min", value: 59 },
                              },
                              {
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.5164061,
                                  lat: 25.2769643,
                                },
                                polyline: {
                                  points:
                                    "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                },
                                end_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                duration: { value: 229, text: "4 mins" },
                                distance: { value: 1963, text: "2.0 km" },
                                maneuver: "merge",
                                html_instructions:
                                  "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                              },
                              {
                                maneuver: "turn-slight-right",
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                end_location: {
                                  lng: 51.497229,
                                  lat: 25.2643805,
                                },
                                distance: { text: "0.4 km", value: 428 },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2663847,
                                  lng: 51.500835,
                                },
                                polyline: {
                                  points:
                                    "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                },
                                duration: { value: 40, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                maneuver: "roundabout-right",
                                polyline: {
                                  points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                },
                                duration: { value: 22, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.497229,
                                  lat: 25.2643805,
                                },
                                distance: { text: "0.2 km", value: 182 },
                                end_location: {
                                  lng: 51.4962302,
                                  lat: 25.2656775,
                                },
                              },
                              {
                                polyline: { points: "oueyCmzhyH]DKB" },
                                start_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                end_location: {
                                  lat: 25.2658942,
                                  lng: 51.4961765,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 25, text: "25 m" },
                                maneuver: "turn-slight-right",
                                html_instructions:
                                  "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                duration: { value: 3, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                maneuver: "turn-slight-right",
                                end_location: {
                                  lng: 51.4995317,
                                  lat: 25.267547,
                                },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                },
                                distance: { value: 389, text: "0.4 km" },
                                duration: { value: 53, text: "1 min" },
                                start_location: {
                                  lat: 25.2658942,
                                  lng: 51.4961765,
                                },
                              },
                              {
                                distance: { value: 111, text: "0.1 km" },
                                start_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohammed St</b>",
                                duration: { value: 22, text: "1 min" },
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                travel_mode: "DRIVING",
                                polyline: { points: "eafyCaoiyHQFiDdA" },
                                maneuver: "turn-left",
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2683307,
                                  lng: 51.49855549999999,
                                },
                                distance: { text: "62 m", value: 62 },
                                duration: { text: "1 min", value: 19 },
                                polyline: { points: "agfyCsliyH\\vABNAJ" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                start_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                              },
                            ],
                            via_waypoint: [],
                            end_location: {
                              lat: 25.2683307,
                              lng: 51.49855549999999,
                            },
                            distance: { text: "4.5 km", value: 4489 },
                            end_address: "7F9X+C9V, Doha, Qatar",
                          },
                          {
                            end_address: "28 Street 8, Ar Rayyan, Qatar",
                            start_address: "7F9X+C9V, Doha, Qatar",
                            via_waypoint: [],
                            duration: { text: "13 mins", value: 795 },
                            start_location: {
                              lng: 51.49855549999999,
                              lat: 25.2683307,
                            },
                            distance: { value: 11216, text: "11.2 km" },
                            end_location: {
                              lat: 25.2132031,
                              lng: 51.43657289999999,
                            },
                            steps: [
                              {
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                start_location: {
                                  lat: 25.2683307,
                                  lng: 51.49855549999999,
                                },
                                distance: { value: 62, text: "62 m" },
                                duration: { text: "1 min", value: 14 },
                                polyline: { points: "affyC_iiyH@KCO]wA" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                              },
                              {
                                distance: { value: 28, text: "28 m" },
                                duration: { value: 9, text: "1 min" },
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                end_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                travel_mode: "DRIVING",
                                polyline: { points: "agfyCsliyHo@L" },
                              },
                              {
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Baraha</b>",
                                polyline: {
                                  points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                distance: { text: "0.2 km", value: 154 },
                                end_location: {
                                  lat: 25.2692232,
                                  lng: 51.500496,
                                },
                                duration: { value: 32, text: "1 min" },
                                start_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                              },
                              {
                                end_location: {
                                  lng: 51.500265,
                                  lat: 25.269806,
                                },
                                polyline: { points: "skfyCcuiyH{@Ty@X" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                duration: { value: 13, text: "1 min" },
                                distance: { text: "69 m", value: 69 },
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.500496,
                                  lat: 25.2692232,
                                },
                              },
                              {
                                polyline: {
                                  points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                },
                                start_location: {
                                  lat: 25.269806,
                                  lng: 51.500265,
                                },
                                distance: { text: "0.2 km", value: 223 },
                                duration: { value: 29, text: "1 min" },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                              },
                              {
                                polyline: {
                                  points:
                                    "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                },
                                duration: { value: 48, text: "1 min" },
                                end_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "roundabout-right",
                                distance: { text: "0.3 km", value: 326 },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                start_location: {
                                  lng: 51.50232339999999,
                                  lat: 25.2705523,
                                },
                              },
                              {
                                end_location: {
                                  lng: 51.45117,
                                  lat: 25.2368244,
                                },
                                polyline: {
                                  points:
                                    "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                },
                                duration: { value: 283, text: "5 mins" },
                                html_instructions: "Merge onto <b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "6.3 km", value: 6288 },
                                maneuver: "merge",
                              },
                              {
                                start_location: {
                                  lng: 51.45117,
                                  lat: 25.2368244,
                                },
                                maneuver: "ramp-right",
                                duration: { text: "1 min", value: 10 },
                                travel_mode: "DRIVING",
                                distance: { text: "0.2 km", value: 194 },
                                html_instructions:
                                  'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                polyline: { points: "ca`yCy``yHpAnDlAdD" },
                                end_location: {
                                  lng: 51.4494587,
                                  lat: 25.2360177,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { value: 397, text: "0.4 km" },
                                end_location: {
                                  lat: 25.2340694,
                                  lng: 51.4461502,
                                },
                                duration: { text: "1 min", value: 33 },
                                html_instructions:
                                  "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                maneuver: "merge",
                                start_location: {
                                  lat: 25.2360177,
                                  lng: 51.4494587,
                                },
                                polyline: {
                                  points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                },
                              },
                              {
                                duration: { value: 8, text: "1 min" },
                                maneuver: "straight",
                                start_location: {
                                  lng: 51.4461502,
                                  lat: 25.2340694,
                                },
                                html_instructions:
                                  "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                end_location: {
                                  lat: 25.2337942,
                                  lng: 51.4456607,
                                },
                                polyline: { points: "}o_yCma_yHP`@d@~@" },
                                distance: { text: "58 m", value: 58 },
                                travel_mode: "DRIVING",
                              },
                              {
                                maneuver: "straight",
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.4456607,
                                  lat: 25.2337942,
                                },
                                duration: { text: "2 mins", value: 90 },
                                end_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                html_instructions:
                                  'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                                polyline: {
                                  points:
                                    "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                },
                                distance: { value: 1451, text: "1.5 km" },
                              },
                              {
                                duration: { text: "1 min", value: 10 },
                                html_instructions:
                                  "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                travel_mode: "DRIVING",
                                distance: { value: 92, text: "92 m" },
                                end_location: {
                                  lng: 51.4328437,
                                  lat: 25.2262036,
                                },
                                start_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                maneuver: "turn-slight-left",
                                polyline: {
                                  points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                },
                              },
                              {
                                end_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                html_instructions: "Continue straight",
                                maneuver: "straight",
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 19 },
                                polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                                distance: { value: 71, text: "71 m" },
                                start_location: {
                                  lat: 25.2262036,
                                  lng: 51.4328437,
                                },
                              },
                              {
                                polyline: {
                                  points:
                                    "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                },
                                travel_mode: "DRIVING",
                                duration: { value: 72, text: "1 min" },
                                distance: { text: "0.8 km", value: 813 },
                                start_location: {
                                  lng: 51.4323655,
                                  lat: 25.2257335,
                                },
                                html_instructions:
                                  "Continue onto <b>E Industrial Rd</b>",
                                end_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                              },
                              {
                                distance: { value: 263, text: "0.3 km" },
                                maneuver: "turn-right",
                                polyline: {
                                  points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                },
                                end_location: {
                                  lat: 25.2182661,
                                  lng: 51.43371639999999,
                                },
                                start_location: {
                                  lat: 25.2196178,
                                  lng: 51.4357808,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 2</b>",
                                duration: { text: "1 min", value: 27 },
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2135618,
                                  lng: 51.4366206,
                                },
                                duration: { value: 78, text: "1 min" },
                                maneuver: "roundabout-right",
                                start_location: {
                                  lng: 51.43371639999999,
                                  lat: 25.2182661,
                                },
                                distance: { value: 682, text: "0.7 km" },
                                html_instructions:
                                  "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                polyline: {
                                  points:
                                    "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                },
                              },
                              {
                                distance: { text: "29 m", value: 29 },
                                start_location: {
                                  lat: 25.2135618,
                                  lng: 51.4366206,
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 8</b>",
                                end_location: {
                                  lng: 51.4364944,
                                  lat: 25.2133289,
                                },
                                duration: { value: 12, text: "1 min" },
                                polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                              },
                              {
                                distance: { value: 16, text: "16 m" },
                                maneuver: "turn-left",
                                duration: { value: 8, text: "1 min" },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2132031,
                                  lng: 51.43657289999999,
                                },
                                start_location: {
                                  lng: 51.4364944,
                                  lat: 25.2133289,
                                },
                                polyline: { points: "in{xCae}xHXO" },
                                html_instructions: "Turn <b>left</b>",
                              },
                            ],
                            traffic_speed_entry: [],
                          },
                        ],
                        waypoint_order: [0, 1],
                        warnings: [],
                        copyrights: "Map data ©2024 Google",
                        overview_polyline: {
                          points:
                            "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                        },
                      },
                    ],
                  },
                  completedAt: 1707942203548,
                  destination: {
                    coordinates: {
                      latitude: 25.213204,
                      longitude: 51.43657469999999,
                    },
                    geohash: "thkx6myvx1",
                    type: "corporate-branch",
                    address: "Street 8, Doha, Qatar",
                    id: "9VZwCrYZfl03ob5FrD4F.0",
                  },
                  status: "completed",
                  skin: null,
                  origin: {
                    geohash: "thkxgdt7m0",
                    address: "858, الدوحة، Qatar",
                    coordinates: { longitude: 51.489361, latitude: 25.282848 },
                  },
                  preferences: {
                    female_passengers_only: null,
                    verified_passengers_only: null,
                  },
                  car: {
                    seat: 3,
                    co2_p_km: 0.3379843740994449,
                    year: 2021,
                    make: "Toyota",
                    type: "carbon-emitting",
                    model: "LAND CRUSER WAGON 4WD",
                  },
                  rules: [
                    "No smoking",
                    "No eating or drinking",
                    "Keep off the AC",
                  ],
                  createdAt: 1707941970485,
                  passengersArray: [
                    {
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      wallet_id: "spencer_carbon.testnet",
                      location: {
                        geohash: "thkxgdt7m0",
                        address: "858, الدوحة، Qatar",
                        coordinates: {
                          latitude: 25.282848,
                          longitude: 51.489361,
                        },
                      },
                      co2Amount: 4.313694566631215,
                      seat: 0,
                      name: "Jassim",
                      id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                      username: "abcd",
                      credits: 27.8,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                    },
                    {
                      co2Amount: 4.508035581738396,
                      seat: 2,
                      wallet_id: "sofia_carbon.testnet",
                      username: "michaelscott",
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                      status: "picked-up",
                      location: {
                        coordinates: {
                          longitude: 51.514615,
                          latitude: 25.285509,
                        },
                        geohash: "thkxu5pfc8",
                        address: "7GP7+6RG, Doha, Qatar",
                      },
                      credits: 13.9,
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                      name: "Ahmed",
                    },
                    {
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                      status: "picked-up",
                      seat: 1,
                      location: {
                        address: "7F9X+C9V, Doha, Qatar",
                        coordinates: {
                          latitude: 25.268246,
                          longitude: 51.498513,
                        },
                        geohash: "thkxezun4q",
                      },
                      co2Amount: 3.7908327398993733,
                      wallet_id: "sarah_carbon.testnet",
                      credits: 13,
                      username: "sarah",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                      name: "Faisal",
                    },
                  ],
                },
                type: "carpool_passenger",
                corporate: "9VZwCrYZfl03ob5FrD4F",
              },
              {
                userId: "user_id",
                name: "Ahmed",
                username: "michaelscott",
                location: {
                  coordinates: { longitude: 51.514615, latitude: 25.285509 },
                  geohash: "thkxu5pfc8",
                  address: "7GP7+6RG, Doha, Qatar",
                },
                credits: 13.9,
                co2Amount: 4.508035581738396,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                seat: 2,
                id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                wallet_id: "sofia_carbon.testnet",
                status: "picked-up",
                carpool: {
                  id: "8292058029514",
                  owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                  co2Amount: 12.612562888268984,
                  directions: {
                    status: "OK",
                    geocoded_waypoints: [
                      {
                        geocoder_status: "OK",
                        types: [
                          "establishment",
                          "moving_company",
                          "point_of_interest",
                        ],
                        place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                      },
                      {
                        place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                        types: [
                          "establishment",
                          "general_contractor",
                          "point_of_interest",
                        ],
                        geocoder_status: "OK",
                      },
                      {
                        types: ["premise"],
                        place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                        geocoder_status: "OK",
                      },
                      {
                        types: ["street_address"],
                        geocoder_status: "OK",
                        place_id:
                          "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                      },
                    ],
                    routes: [
                      {
                        summary: "Al Rayyan Rd",
                        bounds: {
                          northeast: { lng: 51.518743, lat: 25.291958 },
                          southwest: { lat: 25.2132031, lng: 51.4319062 },
                        },
                        legs: [
                          {
                            end_location: { lat: 25.2856124, lng: 51.5145907 },
                            start_location: {
                              lat: 25.2827107,
                              lng: 51.4906083,
                            },
                            steps: [
                              {
                                polyline: { points: "}_iyCiwgyHz@B" },
                                end_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                                start_location: {
                                  lat: 25.2827107,
                                  lng: 51.4906083,
                                },
                                distance: { value: 33, text: "33 m" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                duration: { value: 4, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                travel_mode: "DRIVING",
                                distance: { value: 574, text: "0.6 km" },
                                duration: { value: 49, text: "1 min" },
                                end_location: {
                                  lng: 51.4871118,
                                  lat: 25.2801532,
                                },
                                polyline: {
                                  points:
                                    "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                },
                                start_location: {
                                  lng: 51.4905936,
                                  lat: 25.2824136,
                                },
                              },
                              {
                                html_instructions: "Turn <b>left</b>",
                                duration: { text: "1 min", value: 44 },
                                maneuver: "turn-left",
                                polyline: {
                                  points:
                                    "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                },
                                end_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                                distance: { value: 426, text: "0.4 km" },
                                start_location: {
                                  lat: 25.2801532,
                                  lng: 51.4871118,
                                },
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 8, text: "1 min" },
                                polyline: { points: "cphyCoxgyHEaBAsA" },
                                html_instructions:
                                  "Continue straight onto <b>Al Sadd St</b>",
                                end_location: {
                                  lat: 25.2802165,
                                  lng: 51.4917143,
                                },
                                maneuver: "straight",
                                distance: { value: 93, text: "93 m" },
                                start_location: {
                                  lat: 25.2801774,
                                  lng: 51.49079529999999,
                                },
                              },
                              {
                                distance: { value: 236, text: "0.2 km" },
                                html_instructions: "Slight <b>left</b>",
                                polyline: {
                                  points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                },
                                start_location: {
                                  lat: 25.2802165,
                                  lng: 51.4917143,
                                },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2804496,
                                  lng: 51.4940386,
                                },
                                duration: { text: "1 min", value: 71 },
                                maneuver: "turn-slight-left",
                              },
                              {
                                duration: { text: "1 min", value: 38 },
                                end_location: {
                                  lat: 25.281604,
                                  lng: 51.4947254,
                                },
                                polyline: {
                                  points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                },
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.4940386,
                                  lat: 25.2804496,
                                },
                                distance: { value: 163, text: "0.2 km" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                              },
                              {
                                duration: { text: "1 min", value: 76 },
                                polyline: {
                                  points:
                                    "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                },
                                start_location: {
                                  lat: 25.281604,
                                  lng: 51.4947254,
                                },
                                html_instructions:
                                  "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                maneuver: "keep-left",
                                end_location: {
                                  lng: 51.4965617,
                                  lat: 25.2914195,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "1.1 km", value: 1109 },
                              },
                              {
                                start_location: {
                                  lat: 25.2914195,
                                  lng: 51.4965617,
                                },
                                maneuver: "turn-right",
                                duration: { value: 209, text: "3 mins" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                polyline: {
                                  points:
                                    "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                },
                                end_location: {
                                  lat: 25.2893902,
                                  lng: 51.51516729999999,
                                },
                                distance: { value: 1922, text: "1.9 km" },
                                travel_mode: "DRIVING",
                              },
                              {
                                distance: { value: 144, text: "0.1 km" },
                                start_location: {
                                  lng: 51.51516729999999,
                                  lat: 25.2893902,
                                },
                                end_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                duration: { value: 15, text: "1 min" },
                                travel_mode: "DRIVING",
                              },
                              {
                                polyline: {
                                  points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                },
                                duration: { text: "1 min", value: 11 },
                                end_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                start_location: {
                                  lat: 25.2884741,
                                  lng: 51.51591370000001,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "0.1 km", value: 122 },
                                html_instructions:
                                  'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-slight-right",
                                duration: { text: "1 min", value: 21 },
                                polyline: {
                                  points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                },
                                start_location: {
                                  lng: 51.516228,
                                  lat: 25.2874383,
                                },
                                end_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                distance: { text: "0.3 km", value: 283 },
                                html_instructions:
                                  'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-right",
                                start_location: {
                                  lng: 51.51678399999999,
                                  lat: 25.2849434,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                duration: { text: "1 min", value: 32 },
                                polyline: {
                                  points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                },
                                distance: { text: "0.1 km", value: 125 },
                                end_location: {
                                  lng: 51.5155496,
                                  lat: 25.2848165,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                duration: { text: "1 min", value: 21 },
                                maneuver: "turn-right",
                                distance: { value: 74, text: "74 m" },
                                polyline: {
                                  points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                },
                                end_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                start_location: {
                                  lat: 25.2848165,
                                  lng: 51.5155496,
                                },
                              },
                              {
                                polyline: { points: "uniyC_plyHqBJ" },
                                duration: { text: "1 min", value: 23 },
                                end_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                start_location: {
                                  lat: 25.2850716,
                                  lng: 51.5150352,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "turn-right",
                                distance: { value: 64, text: "64 m" },
                              },
                              {
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 14 },
                                start_location: {
                                  lat: 25.2856417,
                                  lng: 51.51497639999999,
                                },
                                end_location: {
                                  lng: 51.5145907,
                                  lat: 25.2856124,
                                },
                                distance: { text: "39 m", value: 39 },
                                polyline: { points: "griyCsolyH?p@DZ" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                maneuver: "turn-left",
                              },
                            ],
                            start_address: "858, الدوحة، Qatar",
                            end_address: "7GP7+6RG, Doha, Qatar",
                            traffic_speed_entry: [],
                            duration: { value: 636, text: "11 mins" },
                            distance: { text: "5.4 km", value: 5407 },
                            via_waypoint: [],
                          },
                          {
                            start_address: "7GP7+6RG, Doha, Qatar",
                            duration: { value: 598, text: "10 mins" },
                            traffic_speed_entry: [],
                            start_location: {
                              lat: 25.2856124,
                              lng: 51.5145907,
                            },
                            steps: [
                              {
                                duration: { value: 73, text: "1 min" },
                                end_location: {
                                  lat: 25.2859334,
                                  lng: 51.5165533,
                                },
                                start_location: {
                                  lat: 25.2856124,
                                  lng: 51.5145907,
                                },
                                distance: { text: "0.2 km", value: 201 },
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                distance: { text: "0.7 km", value: 735 },
                                end_location: {
                                  lng: 51.518743,
                                  lat: 25.2796568,
                                },
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                },
                                duration: { text: "1 min", value: 78 },
                                start_location: {
                                  lng: 51.5165533,
                                  lat: 25.2859334,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { text: "0.4 km", value: 393 },
                                end_location: {
                                  lat: 25.2769643,
                                  lng: 51.5164061,
                                },
                                maneuver: "turn-slight-right",
                                start_location: {
                                  lat: 25.2796568,
                                  lng: 51.518743,
                                },
                                polyline: {
                                  points:
                                    "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                },
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                duration: { text: "1 min", value: 59 },
                              },
                              {
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.5164061,
                                  lat: 25.2769643,
                                },
                                polyline: {
                                  points:
                                    "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                },
                                end_location: {
                                  lng: 51.500835,
                                  lat: 25.2663847,
                                },
                                duration: { value: 229, text: "4 mins" },
                                distance: { value: 1963, text: "2.0 km" },
                                maneuver: "merge",
                                html_instructions:
                                  "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                              },
                              {
                                maneuver: "turn-slight-right",
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                end_location: {
                                  lng: 51.497229,
                                  lat: 25.2643805,
                                },
                                distance: { text: "0.4 km", value: 428 },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 25.2663847,
                                  lng: 51.500835,
                                },
                                polyline: {
                                  points:
                                    "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                },
                                duration: { value: 40, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                maneuver: "roundabout-right",
                                polyline: {
                                  points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                },
                                duration: { value: 22, text: "1 min" },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.497229,
                                  lat: 25.2643805,
                                },
                                distance: { text: "0.2 km", value: 182 },
                                end_location: {
                                  lng: 51.4962302,
                                  lat: 25.2656775,
                                },
                              },
                              {
                                polyline: { points: "oueyCmzhyH]DKB" },
                                start_location: {
                                  lat: 25.2656775,
                                  lng: 51.4962302,
                                },
                                end_location: {
                                  lat: 25.2658942,
                                  lng: 51.4961765,
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 25, text: "25 m" },
                                maneuver: "turn-slight-right",
                                html_instructions:
                                  "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                duration: { value: 3, text: "1 min" },
                              },
                              {
                                html_instructions:
                                  "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                maneuver: "turn-slight-right",
                                end_location: {
                                  lng: 51.4995317,
                                  lat: 25.267547,
                                },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points:
                                    "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                },
                                distance: { value: 389, text: "0.4 km" },
                                duration: { value: 53, text: "1 min" },
                                start_location: {
                                  lat: 25.2658942,
                                  lng: 51.4961765,
                                },
                              },
                              {
                                distance: { value: 111, text: "0.1 km" },
                                start_location: {
                                  lat: 25.267547,
                                  lng: 51.4995317,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohammed St</b>",
                                duration: { value: 22, text: "1 min" },
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                travel_mode: "DRIVING",
                                polyline: { points: "eafyCaoiyHQFiDdA" },
                                maneuver: "turn-left",
                              },
                              {
                                travel_mode: "DRIVING",
                                maneuver: "turn-left",
                                end_location: {
                                  lat: 25.2683307,
                                  lng: 51.49855549999999,
                                },
                                distance: { text: "62 m", value: 62 },
                                duration: { text: "1 min", value: 19 },
                                polyline: { points: "agfyCsliyH\\vABNAJ" },
                                html_instructions:
                                  'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                start_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                              },
                            ],
                            via_waypoint: [],
                            end_location: {
                              lat: 25.2683307,
                              lng: 51.49855549999999,
                            },
                            distance: { text: "4.5 km", value: 4489 },
                            end_address: "7F9X+C9V, Doha, Qatar",
                          },
                          {
                            end_address: "28 Street 8, Ar Rayyan, Qatar",
                            start_address: "7F9X+C9V, Doha, Qatar",
                            via_waypoint: [],
                            duration: { text: "13 mins", value: 795 },
                            start_location: {
                              lng: 51.49855549999999,
                              lat: 25.2683307,
                            },
                            distance: { value: 11216, text: "11.2 km" },
                            end_location: {
                              lat: 25.2132031,
                              lng: 51.43657289999999,
                            },
                            steps: [
                              {
                                end_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                start_location: {
                                  lat: 25.2683307,
                                  lng: 51.49855549999999,
                                },
                                distance: { value: 62, text: "62 m" },
                                duration: { text: "1 min", value: 14 },
                                polyline: { points: "affyC_iiyH@KCO]wA" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                              },
                              {
                                distance: { value: 28, text: "28 m" },
                                duration: { value: 9, text: "1 min" },
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.499144,
                                  lat: 25.2684856,
                                },
                                end_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                travel_mode: "DRIVING",
                                polyline: { points: "agfyCsliyHo@L" },
                              },
                              {
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Al Baraha</b>",
                                polyline: {
                                  points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                distance: { text: "0.2 km", value: 154 },
                                end_location: {
                                  lat: 25.2692232,
                                  lng: 51.500496,
                                },
                                duration: { value: 32, text: "1 min" },
                                start_location: {
                                  lng: 51.499066,
                                  lat: 25.2687298,
                                },
                              },
                              {
                                end_location: {
                                  lng: 51.500265,
                                  lat: 25.269806,
                                },
                                polyline: { points: "skfyCcuiyH{@Ty@X" },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                duration: { value: 13, text: "1 min" },
                                distance: { text: "69 m", value: 69 },
                                maneuver: "turn-left",
                                start_location: {
                                  lng: 51.500496,
                                  lat: 25.2692232,
                                },
                              },
                              {
                                polyline: {
                                  points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                },
                                start_location: {
                                  lat: 25.269806,
                                  lng: 51.500265,
                                },
                                distance: { text: "0.2 km", value: 223 },
                                duration: { value: 29, text: "1 min" },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2705523,
                                  lng: 51.50232339999999,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                              },
                              {
                                polyline: {
                                  points:
                                    "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                },
                                duration: { value: 48, text: "1 min" },
                                end_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                travel_mode: "DRIVING",
                                maneuver: "roundabout-right",
                                distance: { text: "0.3 km", value: 326 },
                                html_instructions:
                                  "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                start_location: {
                                  lng: 51.50232339999999,
                                  lat: 25.2705523,
                                },
                              },
                              {
                                end_location: {
                                  lng: 51.45117,
                                  lat: 25.2368244,
                                },
                                polyline: {
                                  points:
                                    "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                },
                                duration: { value: 283, text: "5 mins" },
                                html_instructions: "Merge onto <b>Salwa Rd</b>",
                                start_location: {
                                  lng: 51.5033973,
                                  lat: 25.2679045,
                                },
                                travel_mode: "DRIVING",
                                distance: { text: "6.3 km", value: 6288 },
                                maneuver: "merge",
                              },
                              {
                                start_location: {
                                  lng: 51.45117,
                                  lat: 25.2368244,
                                },
                                maneuver: "ramp-right",
                                duration: { text: "1 min", value: 10 },
                                travel_mode: "DRIVING",
                                distance: { text: "0.2 km", value: 194 },
                                html_instructions:
                                  'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                polyline: { points: "ca`yCy``yHpAnDlAdD" },
                                end_location: {
                                  lng: 51.4494587,
                                  lat: 25.2360177,
                                },
                              },
                              {
                                travel_mode: "DRIVING",
                                distance: { value: 397, text: "0.4 km" },
                                end_location: {
                                  lat: 25.2340694,
                                  lng: 51.4461502,
                                },
                                duration: { text: "1 min", value: 33 },
                                html_instructions:
                                  "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                maneuver: "merge",
                                start_location: {
                                  lat: 25.2360177,
                                  lng: 51.4494587,
                                },
                                polyline: {
                                  points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                },
                              },
                              {
                                duration: { value: 8, text: "1 min" },
                                maneuver: "straight",
                                start_location: {
                                  lng: 51.4461502,
                                  lat: 25.2340694,
                                },
                                html_instructions:
                                  "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                end_location: {
                                  lat: 25.2337942,
                                  lng: 51.4456607,
                                },
                                polyline: { points: "}o_yCma_yHP`@d@~@" },
                                distance: { text: "58 m", value: 58 },
                                travel_mode: "DRIVING",
                              },
                              {
                                maneuver: "straight",
                                travel_mode: "DRIVING",
                                start_location: {
                                  lng: 51.4456607,
                                  lat: 25.2337942,
                                },
                                duration: { text: "2 mins", value: 90 },
                                end_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                html_instructions:
                                  'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                                polyline: {
                                  points:
                                    "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                },
                                distance: { value: 1451, text: "1.5 km" },
                              },
                              {
                                duration: { text: "1 min", value: 10 },
                                html_instructions:
                                  "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                travel_mode: "DRIVING",
                                distance: { value: 92, text: "92 m" },
                                end_location: {
                                  lng: 51.4328437,
                                  lat: 25.2262036,
                                },
                                start_location: {
                                  lat: 25.2267513,
                                  lng: 51.43351759999999,
                                },
                                maneuver: "turn-slight-left",
                                polyline: {
                                  points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                },
                              },
                              {
                                end_location: {
                                  lat: 25.2257335,
                                  lng: 51.4323655,
                                },
                                html_instructions: "Continue straight",
                                maneuver: "straight",
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 19 },
                                polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                                distance: { value: 71, text: "71 m" },
                                start_location: {
                                  lat: 25.2262036,
                                  lng: 51.4328437,
                                },
                              },
                              {
                                polyline: {
                                  points:
                                    "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                },
                                travel_mode: "DRIVING",
                                duration: { value: 72, text: "1 min" },
                                distance: { text: "0.8 km", value: 813 },
                                start_location: {
                                  lng: 51.4323655,
                                  lat: 25.2257335,
                                },
                                html_instructions:
                                  "Continue onto <b>E Industrial Rd</b>",
                                end_location: {
                                  lng: 51.4357808,
                                  lat: 25.2196178,
                                },
                              },
                              {
                                distance: { value: 263, text: "0.3 km" },
                                maneuver: "turn-right",
                                polyline: {
                                  points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                },
                                end_location: {
                                  lat: 25.2182661,
                                  lng: 51.43371639999999,
                                },
                                start_location: {
                                  lat: 25.2196178,
                                  lng: 51.4357808,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 2</b>",
                                duration: { text: "1 min", value: 27 },
                                travel_mode: "DRIVING",
                              },
                              {
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2135618,
                                  lng: 51.4366206,
                                },
                                duration: { value: 78, text: "1 min" },
                                maneuver: "roundabout-right",
                                start_location: {
                                  lng: 51.43371639999999,
                                  lat: 25.2182661,
                                },
                                distance: { value: 682, text: "0.7 km" },
                                html_instructions:
                                  "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                polyline: {
                                  points:
                                    "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                },
                              },
                              {
                                distance: { text: "29 m", value: 29 },
                                start_location: {
                                  lat: 25.2135618,
                                  lng: 51.4366206,
                                },
                                maneuver: "turn-right",
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Street 8</b>",
                                end_location: {
                                  lng: 51.4364944,
                                  lat: 25.2133289,
                                },
                                duration: { value: 12, text: "1 min" },
                                polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                              },
                              {
                                distance: { value: 16, text: "16 m" },
                                maneuver: "turn-left",
                                duration: { value: 8, text: "1 min" },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 25.2132031,
                                  lng: 51.43657289999999,
                                },
                                start_location: {
                                  lng: 51.4364944,
                                  lat: 25.2133289,
                                },
                                polyline: { points: "in{xCae}xHXO" },
                                html_instructions: "Turn <b>left</b>",
                              },
                            ],
                            traffic_speed_entry: [],
                          },
                        ],
                        waypoint_order: [0, 1],
                        warnings: [],
                        copyrights: "Map data ©2024 Google",
                        overview_polyline: {
                          points:
                            "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                        },
                      },
                    ],
                  },
                  completedAt: 1707942203548,
                  destination: {
                    coordinates: {
                      latitude: 25.213204,
                      longitude: 51.43657469999999,
                    },
                    geohash: "thkx6myvx1",
                    type: "corporate-branch",
                    address: "Street 8, Doha, Qatar",
                    id: "9VZwCrYZfl03ob5FrD4F.0",
                  },
                  status: "completed",
                  skin: null,
                  origin: {
                    geohash: "thkxgdt7m0",
                    address: "858, الدوحة، Qatar",
                    coordinates: { longitude: 51.489361, latitude: 25.282848 },
                  },
                  preferences: {
                    female_passengers_only: null,
                    verified_passengers_only: null,
                  },
                  car: {
                    seat: 3,
                    co2_p_km: 0.3379843740994449,
                    year: 2021,
                    make: "Toyota",
                    type: "carbon-emitting",
                    model: "LAND CRUSER WAGON 4WD",
                  },
                  rules: [
                    "No smoking",
                    "No eating or drinking",
                    "Keep off the AC",
                  ],
                  createdAt: 1707941970485,
                  passengersArray: [
                    {
                      userId: "user_id",
                      name: "Jassim",
                      username: "abcd",
                      location: {
                        geohash: "thkxgdt7m0",
                        address: "858, الدوحة، Qatar",
                        coordinates: {
                          latitude: 25.282848,
                          longitude: 51.489361,
                        },
                      },
                      credits: 27.8,
                      co2Amount: 4.313694566631215,
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                      seat: 0,
                      id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                      wallet_id: "spencer_carbon.testnet",
                      carpool: {
                        id: "8292058029514",
                        owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                        co2Amount: 12.612562888268984,
                        directions: {
                          status: "OK",
                          geocoded_waypoints: [
                            {
                              geocoder_status: "OK",
                              types: [
                                "establishment",
                                "moving_company",
                                "point_of_interest",
                              ],
                              place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                            },
                            {
                              place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                              types: [
                                "establishment",
                                "general_contractor",
                                "point_of_interest",
                              ],
                              geocoder_status: "OK",
                            },
                            {
                              types: ["premise"],
                              place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                              geocoder_status: "OK",
                            },
                            {
                              types: ["street_address"],
                              geocoder_status: "OK",
                              place_id:
                                "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                            },
                          ],
                          routes: [
                            {
                              summary: "Al Rayyan Rd",
                              bounds: {
                                northeast: { lng: 51.518743, lat: 25.291958 },
                                southwest: { lat: 25.2132031, lng: 51.4319062 },
                              },
                              legs: [
                                {
                                  end_location: {
                                    lat: 25.2856124,
                                    lng: 51.5145907,
                                  },
                                  start_location: {
                                    lat: 25.2827107,
                                    lng: 51.4906083,
                                  },
                                  steps: [
                                    {
                                      polyline: { points: "}_iyCiwgyHz@B" },
                                      end_location: {
                                        lng: 51.4905936,
                                        lat: 25.2824136,
                                      },
                                      start_location: {
                                        lat: 25.2827107,
                                        lng: 51.4906083,
                                      },
                                      distance: { value: 33, text: "33 m" },
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                      duration: { value: 4, text: "1 min" },
                                    },
                                    {
                                      html_instructions:
                                        "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                      travel_mode: "DRIVING",
                                      distance: { value: 574, text: "0.6 km" },
                                      duration: { value: 49, text: "1 min" },
                                      end_location: {
                                        lng: 51.4871118,
                                        lat: 25.2801532,
                                      },
                                      polyline: {
                                        points:
                                          "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                      },
                                      start_location: {
                                        lng: 51.4905936,
                                        lat: 25.2824136,
                                      },
                                    },
                                    {
                                      html_instructions: "Turn <b>left</b>",
                                      duration: { text: "1 min", value: 44 },
                                      maneuver: "turn-left",
                                      polyline: {
                                        points:
                                          "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                      },
                                      end_location: {
                                        lat: 25.2801774,
                                        lng: 51.49079529999999,
                                      },
                                      distance: { value: 426, text: "0.4 km" },
                                      start_location: {
                                        lat: 25.2801532,
                                        lng: 51.4871118,
                                      },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      duration: { value: 8, text: "1 min" },
                                      polyline: { points: "cphyCoxgyHEaBAsA" },
                                      html_instructions:
                                        "Continue straight onto <b>Al Sadd St</b>",
                                      end_location: {
                                        lat: 25.2802165,
                                        lng: 51.4917143,
                                      },
                                      maneuver: "straight",
                                      distance: { value: 93, text: "93 m" },
                                      start_location: {
                                        lat: 25.2801774,
                                        lng: 51.49079529999999,
                                      },
                                    },
                                    {
                                      distance: { value: 236, text: "0.2 km" },
                                      html_instructions: "Slight <b>left</b>",
                                      polyline: {
                                        points:
                                          "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                      },
                                      start_location: {
                                        lat: 25.2802165,
                                        lng: 51.4917143,
                                      },
                                      travel_mode: "DRIVING",
                                      end_location: {
                                        lat: 25.2804496,
                                        lng: 51.4940386,
                                      },
                                      duration: { text: "1 min", value: 71 },
                                      maneuver: "turn-slight-left",
                                    },
                                    {
                                      duration: { text: "1 min", value: 38 },
                                      end_location: {
                                        lat: 25.281604,
                                        lng: 51.4947254,
                                      },
                                      polyline: {
                                        points:
                                          "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                      },
                                      travel_mode: "DRIVING",
                                      maneuver: "turn-left",
                                      start_location: {
                                        lng: 51.4940386,
                                        lat: 25.2804496,
                                      },
                                      distance: { value: 163, text: "0.2 km" },
                                      html_instructions:
                                        'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                                    },
                                    {
                                      duration: { text: "1 min", value: 76 },
                                      polyline: {
                                        points:
                                          "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                      },
                                      start_location: {
                                        lat: 25.281604,
                                        lng: 51.4947254,
                                      },
                                      html_instructions:
                                        "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                      maneuver: "keep-left",
                                      end_location: {
                                        lng: 51.4965617,
                                        lat: 25.2914195,
                                      },
                                      travel_mode: "DRIVING",
                                      distance: { text: "1.1 km", value: 1109 },
                                    },
                                    {
                                      start_location: {
                                        lat: 25.2914195,
                                        lng: 51.4965617,
                                      },
                                      maneuver: "turn-right",
                                      duration: { value: 209, text: "3 mins" },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                      polyline: {
                                        points:
                                          "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                      },
                                      end_location: {
                                        lat: 25.2893902,
                                        lng: 51.51516729999999,
                                      },
                                      distance: { value: 1922, text: "1.9 km" },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      distance: { value: 144, text: "0.1 km" },
                                      start_location: {
                                        lng: 51.51516729999999,
                                        lat: 25.2893902,
                                      },
                                      end_location: {
                                        lat: 25.2884741,
                                        lng: 51.51591370000001,
                                      },
                                      maneuver: "turn-right",
                                      polyline: {
                                        points:
                                          "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                      duration: { value: 15, text: "1 min" },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      polyline: {
                                        points:
                                          "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                      },
                                      duration: { text: "1 min", value: 11 },
                                      end_location: {
                                        lng: 51.516228,
                                        lat: 25.2874383,
                                      },
                                      start_location: {
                                        lat: 25.2884741,
                                        lng: 51.51591370000001,
                                      },
                                      travel_mode: "DRIVING",
                                      distance: { text: "0.1 km", value: 122 },
                                      html_instructions:
                                        'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      maneuver: "turn-slight-right",
                                      duration: { text: "1 min", value: 21 },
                                      polyline: {
                                        points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                      },
                                      start_location: {
                                        lng: 51.516228,
                                        lat: 25.2874383,
                                      },
                                      end_location: {
                                        lng: 51.51678399999999,
                                        lat: 25.2849434,
                                      },
                                      distance: { text: "0.3 km", value: 283 },
                                      html_instructions:
                                        'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      maneuver: "turn-right",
                                      start_location: {
                                        lng: 51.51678399999999,
                                        lat: 25.2849434,
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                      duration: { text: "1 min", value: 32 },
                                      polyline: {
                                        points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                      },
                                      distance: { text: "0.1 km", value: 125 },
                                      end_location: {
                                        lng: 51.5155496,
                                        lat: 25.2848165,
                                      },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                      duration: { text: "1 min", value: 21 },
                                      maneuver: "turn-right",
                                      distance: { value: 74, text: "74 m" },
                                      polyline: {
                                        points:
                                          "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                      },
                                      end_location: {
                                        lat: 25.2850716,
                                        lng: 51.5150352,
                                      },
                                      start_location: {
                                        lat: 25.2848165,
                                        lng: 51.5155496,
                                      },
                                    },
                                    {
                                      polyline: { points: "uniyC_plyHqBJ" },
                                      duration: { text: "1 min", value: 23 },
                                      end_location: {
                                        lat: 25.2856417,
                                        lng: 51.51497639999999,
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                      start_location: {
                                        lat: 25.2850716,
                                        lng: 51.5150352,
                                      },
                                      travel_mode: "DRIVING",
                                      maneuver: "turn-right",
                                      distance: { value: 64, text: "64 m" },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      duration: { text: "1 min", value: 14 },
                                      start_location: {
                                        lat: 25.2856417,
                                        lng: 51.51497639999999,
                                      },
                                      end_location: {
                                        lng: 51.5145907,
                                        lat: 25.2856124,
                                      },
                                      distance: { text: "39 m", value: 39 },
                                      polyline: { points: "griyCsolyH?p@DZ" },
                                      html_instructions:
                                        'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                      maneuver: "turn-left",
                                    },
                                  ],
                                  start_address: "858, الدوحة، Qatar",
                                  end_address: "7GP7+6RG, Doha, Qatar",
                                  traffic_speed_entry: [],
                                  duration: { value: 636, text: "11 mins" },
                                  distance: { text: "5.4 km", value: 5407 },
                                  via_waypoint: [],
                                },
                                {
                                  start_address: "7GP7+6RG, Doha, Qatar",
                                  duration: { value: 598, text: "10 mins" },
                                  traffic_speed_entry: [],
                                  start_location: {
                                    lat: 25.2856124,
                                    lng: 51.5145907,
                                  },
                                  steps: [
                                    {
                                      duration: { value: 73, text: "1 min" },
                                      end_location: {
                                        lat: 25.2859334,
                                        lng: 51.5165533,
                                      },
                                      start_location: {
                                        lat: 25.2856124,
                                        lng: 51.5145907,
                                      },
                                      distance: { text: "0.2 km", value: 201 },
                                      html_instructions:
                                        "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                      },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                      distance: { text: "0.7 km", value: 735 },
                                      end_location: {
                                        lng: 51.518743,
                                        lat: 25.2796568,
                                      },
                                      maneuver: "turn-right",
                                      polyline: {
                                        points:
                                          "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                      },
                                      duration: { text: "1 min", value: 78 },
                                      start_location: {
                                        lng: 51.5165533,
                                        lat: 25.2859334,
                                      },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      distance: { text: "0.4 km", value: 393 },
                                      end_location: {
                                        lat: 25.2769643,
                                        lng: 51.5164061,
                                      },
                                      maneuver: "turn-slight-right",
                                      start_location: {
                                        lat: 25.2796568,
                                        lng: 51.518743,
                                      },
                                      polyline: {
                                        points:
                                          "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                      },
                                      html_instructions:
                                        "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                      duration: { text: "1 min", value: 59 },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      start_location: {
                                        lng: 51.5164061,
                                        lat: 25.2769643,
                                      },
                                      polyline: {
                                        points:
                                          "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                      },
                                      end_location: {
                                        lng: 51.500835,
                                        lat: 25.2663847,
                                      },
                                      duration: { value: 229, text: "4 mins" },
                                      distance: { value: 1963, text: "2.0 km" },
                                      maneuver: "merge",
                                      html_instructions:
                                        "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                                    },
                                    {
                                      maneuver: "turn-slight-right",
                                      html_instructions:
                                        "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                      end_location: {
                                        lng: 51.497229,
                                        lat: 25.2643805,
                                      },
                                      distance: { text: "0.4 km", value: 428 },
                                      travel_mode: "DRIVING",
                                      start_location: {
                                        lat: 25.2663847,
                                        lng: 51.500835,
                                      },
                                      polyline: {
                                        points:
                                          "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                      },
                                      duration: { value: 40, text: "1 min" },
                                    },
                                    {
                                      html_instructions:
                                        "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                      maneuver: "roundabout-right",
                                      polyline: {
                                        points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                      },
                                      duration: { value: 22, text: "1 min" },
                                      travel_mode: "DRIVING",
                                      start_location: {
                                        lng: 51.497229,
                                        lat: 25.2643805,
                                      },
                                      distance: { text: "0.2 km", value: 182 },
                                      end_location: {
                                        lng: 51.4962302,
                                        lat: 25.2656775,
                                      },
                                    },
                                    {
                                      polyline: { points: "oueyCmzhyH]DKB" },
                                      start_location: {
                                        lat: 25.2656775,
                                        lng: 51.4962302,
                                      },
                                      end_location: {
                                        lat: 25.2658942,
                                        lng: 51.4961765,
                                      },
                                      travel_mode: "DRIVING",
                                      distance: { value: 25, text: "25 m" },
                                      maneuver: "turn-slight-right",
                                      html_instructions:
                                        "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                      duration: { value: 3, text: "1 min" },
                                    },
                                    {
                                      html_instructions:
                                        "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                      maneuver: "turn-slight-right",
                                      end_location: {
                                        lng: 51.4995317,
                                        lat: 25.267547,
                                      },
                                      travel_mode: "DRIVING",
                                      polyline: {
                                        points:
                                          "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                      },
                                      distance: { value: 389, text: "0.4 km" },
                                      duration: { value: 53, text: "1 min" },
                                      start_location: {
                                        lat: 25.2658942,
                                        lng: 51.4961765,
                                      },
                                    },
                                    {
                                      distance: { value: 111, text: "0.1 km" },
                                      start_location: {
                                        lat: 25.267547,
                                        lng: 51.4995317,
                                      },
                                      html_instructions:
                                        "Turn <b>left</b> onto <b>Mohammed St</b>",
                                      duration: { value: 22, text: "1 min" },
                                      end_location: {
                                        lng: 51.499144,
                                        lat: 25.2684856,
                                      },
                                      travel_mode: "DRIVING",
                                      polyline: { points: "eafyCaoiyHQFiDdA" },
                                      maneuver: "turn-left",
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      maneuver: "turn-left",
                                      end_location: {
                                        lat: 25.2683307,
                                        lng: 51.49855549999999,
                                      },
                                      distance: { text: "62 m", value: 62 },
                                      duration: { text: "1 min", value: 19 },
                                      polyline: {
                                        points: "agfyCsliyH\\vABNAJ",
                                      },
                                      html_instructions:
                                        'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                      start_location: {
                                        lng: 51.499144,
                                        lat: 25.2684856,
                                      },
                                    },
                                  ],
                                  via_waypoint: [],
                                  end_location: {
                                    lat: 25.2683307,
                                    lng: 51.49855549999999,
                                  },
                                  distance: { text: "4.5 km", value: 4489 },
                                  end_address: "7F9X+C9V, Doha, Qatar",
                                },
                                {
                                  end_address: "28 Street 8, Ar Rayyan, Qatar",
                                  start_address: "7F9X+C9V, Doha, Qatar",
                                  via_waypoint: [],
                                  duration: { text: "13 mins", value: 795 },
                                  start_location: {
                                    lng: 51.49855549999999,
                                    lat: 25.2683307,
                                  },
                                  distance: { value: 11216, text: "11.2 km" },
                                  end_location: {
                                    lat: 25.2132031,
                                    lng: 51.43657289999999,
                                  },
                                  steps: [
                                    {
                                      end_location: {
                                        lng: 51.499144,
                                        lat: 25.2684856,
                                      },
                                      start_location: {
                                        lat: 25.2683307,
                                        lng: 51.49855549999999,
                                      },
                                      distance: { value: 62, text: "62 m" },
                                      duration: { text: "1 min", value: 14 },
                                      polyline: { points: "affyC_iiyH@KCO]wA" },
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                                    },
                                    {
                                      distance: { value: 28, text: "28 m" },
                                      duration: { value: 9, text: "1 min" },
                                      maneuver: "turn-left",
                                      start_location: {
                                        lng: 51.499144,
                                        lat: 25.2684856,
                                      },
                                      end_location: {
                                        lng: 51.499066,
                                        lat: 25.2687298,
                                      },
                                      html_instructions:
                                        "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                      travel_mode: "DRIVING",
                                      polyline: { points: "agfyCsliyHo@L" },
                                    },
                                    {
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Al Baraha</b>",
                                      polyline: {
                                        points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                      },
                                      maneuver: "turn-right",
                                      travel_mode: "DRIVING",
                                      distance: { text: "0.2 km", value: 154 },
                                      end_location: {
                                        lat: 25.2692232,
                                        lng: 51.500496,
                                      },
                                      duration: { value: 32, text: "1 min" },
                                      start_location: {
                                        lng: 51.499066,
                                        lat: 25.2687298,
                                      },
                                    },
                                    {
                                      end_location: {
                                        lng: 51.500265,
                                        lat: 25.269806,
                                      },
                                      polyline: { points: "skfyCcuiyH{@Ty@X" },
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                      duration: { value: 13, text: "1 min" },
                                      distance: { text: "69 m", value: 69 },
                                      maneuver: "turn-left",
                                      start_location: {
                                        lng: 51.500496,
                                        lat: 25.2692232,
                                      },
                                    },
                                    {
                                      polyline: {
                                        points:
                                          "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                      },
                                      start_location: {
                                        lat: 25.269806,
                                        lng: 51.500265,
                                      },
                                      distance: { text: "0.2 km", value: 223 },
                                      duration: { value: 29, text: "1 min" },
                                      maneuver: "turn-right",
                                      travel_mode: "DRIVING",
                                      end_location: {
                                        lat: 25.2705523,
                                        lng: 51.50232339999999,
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                                    },
                                    {
                                      polyline: {
                                        points:
                                          "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                      },
                                      duration: { value: 48, text: "1 min" },
                                      end_location: {
                                        lng: 51.5033973,
                                        lat: 25.2679045,
                                      },
                                      travel_mode: "DRIVING",
                                      maneuver: "roundabout-right",
                                      distance: { text: "0.3 km", value: 326 },
                                      html_instructions:
                                        "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                      start_location: {
                                        lng: 51.50232339999999,
                                        lat: 25.2705523,
                                      },
                                    },
                                    {
                                      end_location: {
                                        lng: 51.45117,
                                        lat: 25.2368244,
                                      },
                                      polyline: {
                                        points:
                                          "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                      },
                                      duration: { value: 283, text: "5 mins" },
                                      html_instructions:
                                        "Merge onto <b>Salwa Rd</b>",
                                      start_location: {
                                        lng: 51.5033973,
                                        lat: 25.2679045,
                                      },
                                      travel_mode: "DRIVING",
                                      distance: { text: "6.3 km", value: 6288 },
                                      maneuver: "merge",
                                    },
                                    {
                                      start_location: {
                                        lng: 51.45117,
                                        lat: 25.2368244,
                                      },
                                      maneuver: "ramp-right",
                                      duration: { text: "1 min", value: 10 },
                                      travel_mode: "DRIVING",
                                      distance: { text: "0.2 km", value: 194 },
                                      html_instructions:
                                        'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                      polyline: {
                                        points: "ca`yCy``yHpAnDlAdD",
                                      },
                                      end_location: {
                                        lng: 51.4494587,
                                        lat: 25.2360177,
                                      },
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      distance: { value: 397, text: "0.4 km" },
                                      end_location: {
                                        lat: 25.2340694,
                                        lng: 51.4461502,
                                      },
                                      duration: { text: "1 min", value: 33 },
                                      html_instructions:
                                        "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                      maneuver: "merge",
                                      start_location: {
                                        lat: 25.2360177,
                                        lng: 51.4494587,
                                      },
                                      polyline: {
                                        points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                      },
                                    },
                                    {
                                      duration: { value: 8, text: "1 min" },
                                      maneuver: "straight",
                                      start_location: {
                                        lng: 51.4461502,
                                        lat: 25.2340694,
                                      },
                                      html_instructions:
                                        "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                      end_location: {
                                        lat: 25.2337942,
                                        lng: 51.4456607,
                                      },
                                      polyline: { points: "}o_yCma_yHP`@d@~@" },
                                      distance: { text: "58 m", value: 58 },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      maneuver: "straight",
                                      travel_mode: "DRIVING",
                                      start_location: {
                                        lng: 51.4456607,
                                        lat: 25.2337942,
                                      },
                                      duration: { text: "2 mins", value: 90 },
                                      end_location: {
                                        lat: 25.2267513,
                                        lng: 51.43351759999999,
                                      },
                                      html_instructions:
                                        'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                                      polyline: {
                                        points:
                                          "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                      },
                                      distance: { value: 1451, text: "1.5 km" },
                                    },
                                    {
                                      duration: { text: "1 min", value: 10 },
                                      html_instructions:
                                        "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                      travel_mode: "DRIVING",
                                      distance: { value: 92, text: "92 m" },
                                      end_location: {
                                        lng: 51.4328437,
                                        lat: 25.2262036,
                                      },
                                      start_location: {
                                        lat: 25.2267513,
                                        lng: 51.43351759999999,
                                      },
                                      maneuver: "turn-slight-left",
                                      polyline: {
                                        points:
                                          "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                      },
                                    },
                                    {
                                      end_location: {
                                        lat: 25.2257335,
                                        lng: 51.4323655,
                                      },
                                      html_instructions: "Continue straight",
                                      maneuver: "straight",
                                      travel_mode: "DRIVING",
                                      duration: { text: "1 min", value: 19 },
                                      polyline: {
                                        points: "w~}xCgn|xHLRNNXZFDVTD@",
                                      },
                                      distance: { value: 71, text: "71 m" },
                                      start_location: {
                                        lat: 25.2262036,
                                        lng: 51.4328437,
                                      },
                                    },
                                    {
                                      polyline: {
                                        points:
                                          "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                      },
                                      travel_mode: "DRIVING",
                                      duration: { value: 72, text: "1 min" },
                                      distance: { text: "0.8 km", value: 813 },
                                      start_location: {
                                        lng: 51.4323655,
                                        lat: 25.2257335,
                                      },
                                      html_instructions:
                                        "Continue onto <b>E Industrial Rd</b>",
                                      end_location: {
                                        lng: 51.4357808,
                                        lat: 25.2196178,
                                      },
                                    },
                                    {
                                      distance: { value: 263, text: "0.3 km" },
                                      maneuver: "turn-right",
                                      polyline: {
                                        points:
                                          "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                      },
                                      end_location: {
                                        lat: 25.2182661,
                                        lng: 51.43371639999999,
                                      },
                                      start_location: {
                                        lat: 25.2196178,
                                        lng: 51.4357808,
                                      },
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Street 2</b>",
                                      duration: { text: "1 min", value: 27 },
                                      travel_mode: "DRIVING",
                                    },
                                    {
                                      travel_mode: "DRIVING",
                                      end_location: {
                                        lat: 25.2135618,
                                        lng: 51.4366206,
                                      },
                                      duration: { value: 78, text: "1 min" },
                                      maneuver: "roundabout-right",
                                      start_location: {
                                        lng: 51.43371639999999,
                                        lat: 25.2182661,
                                      },
                                      distance: { value: 682, text: "0.7 km" },
                                      html_instructions:
                                        "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                      polyline: {
                                        points:
                                          "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                      },
                                    },
                                    {
                                      distance: { text: "29 m", value: 29 },
                                      start_location: {
                                        lat: 25.2135618,
                                        lng: 51.4366206,
                                      },
                                      maneuver: "turn-right",
                                      travel_mode: "DRIVING",
                                      html_instructions:
                                        "Turn <b>right</b> onto <b>Street 8</b>",
                                      end_location: {
                                        lng: 51.4364944,
                                        lat: 25.2133289,
                                      },
                                      duration: { value: 12, text: "1 min" },
                                      polyline: {
                                        points: "wo{xC{e}xHLDJBHFHF",
                                      },
                                    },
                                    {
                                      distance: { value: 16, text: "16 m" },
                                      maneuver: "turn-left",
                                      duration: { value: 8, text: "1 min" },
                                      travel_mode: "DRIVING",
                                      end_location: {
                                        lat: 25.2132031,
                                        lng: 51.43657289999999,
                                      },
                                      start_location: {
                                        lng: 51.4364944,
                                        lat: 25.2133289,
                                      },
                                      polyline: { points: "in{xCae}xHXO" },
                                      html_instructions: "Turn <b>left</b>",
                                    },
                                  ],
                                  traffic_speed_entry: [],
                                },
                              ],
                              waypoint_order: [0, 1],
                              warnings: [],
                              copyrights: "Map data ©2024 Google",
                              overview_polyline: {
                                points:
                                  "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                              },
                            },
                          ],
                        },
                        completedAt: 1707942203548,
                        destination: {
                          coordinates: {
                            latitude: 25.213204,
                            longitude: 51.43657469999999,
                          },
                          geohash: "thkx6myvx1",
                          type: "corporate-branch",
                          address: "Street 8, Doha, Qatar",
                          id: "9VZwCrYZfl03ob5FrD4F.0",
                        },
                        status: "completed",
                        skin: null,
                        origin: {
                          geohash: "thkxgdt7m0",
                          address: "858, الدوحة، Qatar",
                          coordinates: {
                            longitude: 51.489361,
                            latitude: 25.282848,
                          },
                        },
                        preferences: {
                          female_passengers_only: null,
                          verified_passengers_only: null,
                        },
                        car: {
                          seat: 3,
                          co2_p_km: 0.3379843740994449,
                          year: 2021,
                          make: "Toyota",
                          type: "carbon-emitting",
                          model: "LAND CRUSER WAGON 4WD",
                        },
                        rules: [
                          "No smoking",
                          "No eating or drinking",
                          "Keep off the AC",
                        ],
                        createdAt: 1707941970485,
                        passengersArray: [
                          {
                            corporate: "9VZwCrYZfl03ob5FrD4F",
                            wallet_id: "spencer_carbon.testnet",
                            location: {
                              geohash: "thkxgdt7m0",
                              address: "858, الدوحة، Qatar",
                              coordinates: {
                                latitude: 25.282848,
                                longitude: 51.489361,
                              },
                            },
                            co2Amount: 4.313694566631215,
                            seat: 0,
                            name: "Jassim",
                            id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                            username: "abcd",
                            credits: 27.8,
                            avatar:
                              "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                          },
                          {
                            co2Amount: 4.508035581738396,
                            seat: 2,
                            wallet_id: "sofia_carbon.testnet",
                            username: "michaelscott",
                            avatar:
                              "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                            status: "picked-up",
                            location: {
                              coordinates: {
                                longitude: 51.514615,
                                latitude: 25.285509,
                              },
                              geohash: "thkxu5pfc8",
                              address: "7GP7+6RG, Doha, Qatar",
                            },
                            credits: 13.9,
                            corporate: "9VZwCrYZfl03ob5FrD4F",
                            id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                            name: "Ahmed",
                          },
                          {
                            avatar:
                              "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                            status: "picked-up",
                            seat: 1,
                            location: {
                              address: "7F9X+C9V, Doha, Qatar",
                              coordinates: {
                                latitude: 25.268246,
                                longitude: 51.498513,
                              },
                              geohash: "thkxezun4q",
                            },
                            co2Amount: 3.7908327398993733,
                            wallet_id: "sarah_carbon.testnet",
                            credits: 13,
                            username: "sarah",
                            corporate: "9VZwCrYZfl03ob5FrD4F",
                            id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                            name: "Faisal",
                          },
                        ],
                      },
                      type: "carpool_passenger",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                    },
                    {
                      co2Amount: 4.508035581738396,
                      seat: 2,
                      wallet_id: "sofia_carbon.testnet",
                      username: "michaelscott",
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                      status: "picked-up",
                      location: {
                        coordinates: {
                          longitude: 51.514615,
                          latitude: 25.285509,
                        },
                        geohash: "thkxu5pfc8",
                        address: "7GP7+6RG, Doha, Qatar",
                      },
                      credits: 13.9,
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                      name: "Ahmed",
                    },
                    {
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                      status: "picked-up",
                      seat: 1,
                      location: {
                        address: "7F9X+C9V, Doha, Qatar",
                        coordinates: {
                          latitude: 25.268246,
                          longitude: 51.498513,
                        },
                        geohash: "thkxezun4q",
                      },
                      co2Amount: 3.7908327398993733,
                      wallet_id: "sarah_carbon.testnet",
                      credits: 13,
                      username: "sarah",
                      corporate: "9VZwCrYZfl03ob5FrD4F",
                      id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                      name: "Faisal",
                    },
                  ],
                },
                type: "carpool_passenger",
                corporate: "9VZwCrYZfl03ob5FrD4F",
              },
              {
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                status: "picked-up",
                seat: 1,
                location: {
                  address: "7F9X+C9V, Doha, Qatar",
                  coordinates: { latitude: 25.268246, longitude: 51.498513 },
                  geohash: "thkxezun4q",
                },
                co2Amount: 3.7908327398993733,
                wallet_id: "sarah_carbon.testnet",
                credits: 13,
                username: "sarah",
                corporate: "9VZwCrYZfl03ob5FrD4F",
                id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                name: "Faisal",
              },
            ],
          },
          type: "carpool_passenger",
          corporate: "9VZwCrYZfl03ob5FrD4F",
        },
      },
      rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
      createdAt: 1707941970485,
    },
    userId: "user_id",
    company: Companies.Company2,
    team: Teams.TeamA,
    tripDirection: TripDirection.FromWork,
    directions: {
      status: "OK",
      geocoded_waypoints: [
        {
          geocoder_status: "OK",
          types: ["establishment", "moving_company", "point_of_interest"],
          place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
        },
        {
          place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
          types: ["establishment", "general_contractor", "point_of_interest"],
          geocoder_status: "OK",
        },
        {
          types: ["premise"],
          place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
          geocoder_status: "OK",
        },
        {
          types: ["street_address"],
          geocoder_status: "OK",
          place_id:
            "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
        },
      ],
      routes: [
        {
          summary: "Al Rayyan Rd",
          bounds: {
            northeast: { lng: 51.518743, lat: 25.291958 },
            southwest: { lat: 25.2132031, lng: 51.4319062 },
          },
          legs: [
            {
              end_location: { lat: 25.2856124, lng: 51.5145907 },
              start_location: { lat: 25.2827107, lng: 51.4906083 },
              steps: [
                {
                  polyline: { points: "}_iyCiwgyHz@B" },
                  end_location: { lng: 51.4905936, lat: 25.2824136 },
                  start_location: { lat: 25.2827107, lng: 51.4906083 },
                  distance: { value: 33, text: "33 m" },
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                  duration: { value: 4, text: "1 min" },
                },
                {
                  html_instructions:
                    "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                  travel_mode: "DRIVING",
                  distance: { value: 574, text: "0.6 km" },
                  duration: { value: 49, text: "1 min" },
                  end_location: { lng: 51.4871118, lat: 25.2801532 },
                  polyline: {
                    points:
                      "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                  },
                  start_location: { lng: 51.4905936, lat: 25.2824136 },
                },
                {
                  html_instructions: "Turn <b>left</b>",
                  duration: { text: "1 min", value: 44 },
                  maneuver: "turn-left",
                  polyline: {
                    points:
                      "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                  },
                  end_location: { lat: 25.2801774, lng: 51.49079529999999 },
                  distance: { value: 426, text: "0.4 km" },
                  start_location: { lat: 25.2801532, lng: 51.4871118 },
                  travel_mode: "DRIVING",
                },
                {
                  travel_mode: "DRIVING",
                  duration: { value: 8, text: "1 min" },
                  polyline: { points: "cphyCoxgyHEaBAsA" },
                  html_instructions: "Continue straight onto <b>Al Sadd St</b>",
                  end_location: { lat: 25.2802165, lng: 51.4917143 },
                  maneuver: "straight",
                  distance: { value: 93, text: "93 m" },
                  start_location: { lat: 25.2801774, lng: 51.49079529999999 },
                },
                {
                  distance: { value: 236, text: "0.2 km" },
                  html_instructions: "Slight <b>left</b>",
                  polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                  start_location: { lat: 25.2802165, lng: 51.4917143 },
                  travel_mode: "DRIVING",
                  end_location: { lat: 25.2804496, lng: 51.4940386 },
                  duration: { text: "1 min", value: 71 },
                  maneuver: "turn-slight-left",
                },
                {
                  duration: { text: "1 min", value: 38 },
                  end_location: { lat: 25.281604, lng: 51.4947254 },
                  polyline: { points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O" },
                  travel_mode: "DRIVING",
                  maneuver: "turn-left",
                  start_location: { lng: 51.4940386, lat: 25.2804496 },
                  distance: { value: 163, text: "0.2 km" },
                  html_instructions:
                    'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                },
                {
                  duration: { text: "1 min", value: 76 },
                  polyline: {
                    points:
                      "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                  },
                  start_location: { lat: 25.281604, lng: 51.4947254 },
                  html_instructions:
                    "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                  maneuver: "keep-left",
                  end_location: { lng: 51.4965617, lat: 25.2914195 },
                  travel_mode: "DRIVING",
                  distance: { text: "1.1 km", value: 1109 },
                },
                {
                  start_location: { lat: 25.2914195, lng: 51.4965617 },
                  maneuver: "turn-right",
                  duration: { value: 209, text: "3 mins" },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                  polyline: {
                    points:
                      "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                  },
                  end_location: { lat: 25.2893902, lng: 51.51516729999999 },
                  distance: { value: 1922, text: "1.9 km" },
                  travel_mode: "DRIVING",
                },
                {
                  distance: { value: 144, text: "0.1 km" },
                  start_location: { lng: 51.51516729999999, lat: 25.2893902 },
                  end_location: { lat: 25.2884741, lng: 51.51591370000001 },
                  maneuver: "turn-right",
                  polyline: {
                    points: "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                  },
                  html_instructions: "Turn <b>right</b> onto <b>Al Khaleej</b>",
                  duration: { value: 15, text: "1 min" },
                  travel_mode: "DRIVING",
                },
                {
                  polyline: { points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E" },
                  duration: { text: "1 min", value: 11 },
                  end_location: { lng: 51.516228, lat: 25.2874383 },
                  start_location: { lat: 25.2884741, lng: 51.51591370000001 },
                  travel_mode: "DRIVING",
                  distance: { text: "0.1 km", value: 122 },
                  html_instructions:
                    'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                },
                {
                  travel_mode: "DRIVING",
                  maneuver: "turn-slight-right",
                  duration: { text: "1 min", value: 21 },
                  polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                  start_location: { lng: 51.516228, lat: 25.2874383 },
                  end_location: { lng: 51.51678399999999, lat: 25.2849434 },
                  distance: { text: "0.3 km", value: 283 },
                  html_instructions:
                    'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                },
                {
                  travel_mode: "DRIVING",
                  maneuver: "turn-right",
                  start_location: { lng: 51.51678399999999, lat: 25.2849434 },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                  duration: { text: "1 min", value: 32 },
                  polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                  distance: { text: "0.1 km", value: 125 },
                  end_location: { lng: 51.5155496, lat: 25.2848165 },
                },
                {
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                  duration: { text: "1 min", value: 21 },
                  maneuver: "turn-right",
                  distance: { value: 74, text: "74 m" },
                  polyline: { points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X" },
                  end_location: { lat: 25.2850716, lng: 51.5150352 },
                  start_location: { lat: 25.2848165, lng: 51.5155496 },
                },
                {
                  polyline: { points: "uniyC_plyHqBJ" },
                  duration: { text: "1 min", value: 23 },
                  end_location: { lat: 25.2856417, lng: 51.51497639999999 },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                  start_location: { lat: 25.2850716, lng: 51.5150352 },
                  travel_mode: "DRIVING",
                  maneuver: "turn-right",
                  distance: { value: 64, text: "64 m" },
                },
                {
                  travel_mode: "DRIVING",
                  duration: { text: "1 min", value: 14 },
                  start_location: { lat: 25.2856417, lng: 51.51497639999999 },
                  end_location: { lng: 51.5145907, lat: 25.2856124 },
                  distance: { text: "39 m", value: 39 },
                  polyline: { points: "griyCsolyH?p@DZ" },
                  html_instructions:
                    'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                  maneuver: "turn-left",
                },
              ],
              start_address: "858, الدوحة، Qatar",
              end_address: "7GP7+6RG, Doha, Qatar",
              traffic_speed_entry: [],
              duration: { value: 636, text: "11 mins" },
              distance: { text: "5.4 km", value: 5407 },
              via_waypoint: [],
            },
            {
              start_address: "7GP7+6RG, Doha, Qatar",
              duration: { value: 598, text: "10 mins" },
              traffic_speed_entry: [],
              start_location: { lat: 25.2856124, lng: 51.5145907 },
              steps: [
                {
                  duration: { value: 73, text: "1 min" },
                  end_location: { lat: 25.2859334, lng: 51.5165533 },
                  start_location: { lat: 25.2856124, lng: 51.5145907 },
                  distance: { text: "0.2 km", value: 201 },
                  html_instructions:
                    "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                  travel_mode: "DRIVING",
                  polyline: { points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q" },
                },
                {
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                  distance: { text: "0.7 km", value: 735 },
                  end_location: { lng: 51.518743, lat: 25.2796568 },
                  maneuver: "turn-right",
                  polyline: {
                    points:
                      "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                  },
                  duration: { text: "1 min", value: 78 },
                  start_location: { lng: 51.5165533, lat: 25.2859334 },
                },
                {
                  travel_mode: "DRIVING",
                  distance: { text: "0.4 km", value: 393 },
                  end_location: { lat: 25.2769643, lng: 51.5164061 },
                  maneuver: "turn-slight-right",
                  start_location: { lat: 25.2796568, lng: 51.518743 },
                  polyline: {
                    points:
                      "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                  },
                  html_instructions:
                    "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                  duration: { text: "1 min", value: 59 },
                },
                {
                  travel_mode: "DRIVING",
                  start_location: { lng: 51.5164061, lat: 25.2769643 },
                  polyline: {
                    points:
                      "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                  },
                  end_location: { lng: 51.500835, lat: 25.2663847 },
                  duration: { value: 229, text: "4 mins" },
                  distance: { value: 1963, text: "2.0 km" },
                  maneuver: "merge",
                  html_instructions:
                    "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                },
                {
                  maneuver: "turn-slight-right",
                  html_instructions:
                    "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                  end_location: { lng: 51.497229, lat: 25.2643805 },
                  distance: { text: "0.4 km", value: 428 },
                  travel_mode: "DRIVING",
                  start_location: { lat: 25.2663847, lng: 51.500835 },
                  polyline: {
                    points:
                      "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                  },
                  duration: { value: 40, text: "1 min" },
                },
                {
                  html_instructions:
                    "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                  maneuver: "roundabout-right",
                  polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                  duration: { value: 22, text: "1 min" },
                  travel_mode: "DRIVING",
                  start_location: { lng: 51.497229, lat: 25.2643805 },
                  distance: { text: "0.2 km", value: 182 },
                  end_location: { lng: 51.4962302, lat: 25.2656775 },
                },
                {
                  polyline: { points: "oueyCmzhyH]DKB" },
                  start_location: { lat: 25.2656775, lng: 51.4962302 },
                  end_location: { lat: 25.2658942, lng: 51.4961765 },
                  travel_mode: "DRIVING",
                  distance: { value: 25, text: "25 m" },
                  maneuver: "turn-slight-right",
                  html_instructions:
                    "Slight <b>right</b> onto <b>Al Nasr St</b>",
                  duration: { value: 3, text: "1 min" },
                },
                {
                  html_instructions:
                    "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                  maneuver: "turn-slight-right",
                  end_location: { lng: 51.4995317, lat: 25.267547 },
                  travel_mode: "DRIVING",
                  polyline: {
                    points: "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                  },
                  distance: { value: 389, text: "0.4 km" },
                  duration: { value: 53, text: "1 min" },
                  start_location: { lat: 25.2658942, lng: 51.4961765 },
                },
                {
                  distance: { value: 111, text: "0.1 km" },
                  start_location: { lat: 25.267547, lng: 51.4995317 },
                  html_instructions: "Turn <b>left</b> onto <b>Mohammed St</b>",
                  duration: { value: 22, text: "1 min" },
                  end_location: { lng: 51.499144, lat: 25.2684856 },
                  travel_mode: "DRIVING",
                  polyline: { points: "eafyCaoiyHQFiDdA" },
                  maneuver: "turn-left",
                },
                {
                  travel_mode: "DRIVING",
                  maneuver: "turn-left",
                  end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                  distance: { text: "62 m", value: 62 },
                  duration: { text: "1 min", value: 19 },
                  polyline: { points: "agfyCsliyH\\vABNAJ" },
                  html_instructions:
                    'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                  start_location: { lng: 51.499144, lat: 25.2684856 },
                },
              ],
              via_waypoint: [],
              end_location: { lat: 25.2683307, lng: 51.49855549999999 },
              distance: { text: "4.5 km", value: 4489 },
              end_address: "7F9X+C9V, Doha, Qatar",
            },
            {
              end_address: "28 Street 8, Ar Rayyan, Qatar",
              start_address: "7F9X+C9V, Doha, Qatar",
              via_waypoint: [],
              duration: { text: "13 mins", value: 795 },
              start_location: { lng: 51.49855549999999, lat: 25.2683307 },
              distance: { value: 11216, text: "11.2 km" },
              end_location: { lat: 25.2132031, lng: 51.43657289999999 },
              steps: [
                {
                  end_location: { lng: 51.499144, lat: 25.2684856 },
                  start_location: { lat: 25.2683307, lng: 51.49855549999999 },
                  distance: { value: 62, text: "62 m" },
                  duration: { text: "1 min", value: 14 },
                  polyline: { points: "affyC_iiyH@KCO]wA" },
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                },
                {
                  distance: { value: 28, text: "28 m" },
                  duration: { value: 9, text: "1 min" },
                  maneuver: "turn-left",
                  start_location: { lng: 51.499144, lat: 25.2684856 },
                  end_location: { lng: 51.499066, lat: 25.2687298 },
                  html_instructions:
                    "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                  travel_mode: "DRIVING",
                  polyline: { points: "agfyCsliyHo@L" },
                },
                {
                  html_instructions: "Turn <b>right</b> onto <b>Al Baraha</b>",
                  polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                  maneuver: "turn-right",
                  travel_mode: "DRIVING",
                  distance: { text: "0.2 km", value: 154 },
                  end_location: { lat: 25.2692232, lng: 51.500496 },
                  duration: { value: 32, text: "1 min" },
                  start_location: { lng: 51.499066, lat: 25.2687298 },
                },
                {
                  end_location: { lng: 51.500265, lat: 25.269806 },
                  polyline: { points: "skfyCcuiyH{@Ty@X" },
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                  duration: { value: 13, text: "1 min" },
                  distance: { text: "69 m", value: 69 },
                  maneuver: "turn-left",
                  start_location: { lng: 51.500496, lat: 25.2692232 },
                },
                {
                  polyline: { points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA" },
                  start_location: { lat: 25.269806, lng: 51.500265 },
                  distance: { text: "0.2 km", value: 223 },
                  duration: { value: 29, text: "1 min" },
                  maneuver: "turn-right",
                  travel_mode: "DRIVING",
                  end_location: { lat: 25.2705523, lng: 51.50232339999999 },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                },
                {
                  polyline: {
                    points:
                      "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                  },
                  duration: { value: 48, text: "1 min" },
                  end_location: { lng: 51.5033973, lat: 25.2679045 },
                  travel_mode: "DRIVING",
                  maneuver: "roundabout-right",
                  distance: { text: "0.3 km", value: 326 },
                  html_instructions:
                    "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                  start_location: { lng: 51.50232339999999, lat: 25.2705523 },
                },
                {
                  end_location: { lng: 51.45117, lat: 25.2368244 },
                  polyline: {
                    points:
                      "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                  },
                  duration: { value: 283, text: "5 mins" },
                  html_instructions: "Merge onto <b>Salwa Rd</b>",
                  start_location: { lng: 51.5033973, lat: 25.2679045 },
                  travel_mode: "DRIVING",
                  distance: { text: "6.3 km", value: 6288 },
                  maneuver: "merge",
                },
                {
                  start_location: { lng: 51.45117, lat: 25.2368244 },
                  maneuver: "ramp-right",
                  duration: { text: "1 min", value: 10 },
                  travel_mode: "DRIVING",
                  distance: { text: "0.2 km", value: 194 },
                  html_instructions:
                    'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                  polyline: { points: "ca`yCy``yHpAnDlAdD" },
                  end_location: { lng: 51.4494587, lat: 25.2360177 },
                },
                {
                  travel_mode: "DRIVING",
                  distance: { value: 397, text: "0.4 km" },
                  end_location: { lat: 25.2340694, lng: 51.4461502 },
                  duration: { text: "1 min", value: 33 },
                  html_instructions:
                    "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                  maneuver: "merge",
                  start_location: { lat: 25.2360177, lng: 51.4494587 },
                  polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                },
                {
                  duration: { value: 8, text: "1 min" },
                  maneuver: "straight",
                  start_location: { lng: 51.4461502, lat: 25.2340694 },
                  html_instructions:
                    "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                  end_location: { lat: 25.2337942, lng: 51.4456607 },
                  polyline: { points: "}o_yCma_yHP`@d@~@" },
                  distance: { text: "58 m", value: 58 },
                  travel_mode: "DRIVING",
                },
                {
                  maneuver: "straight",
                  travel_mode: "DRIVING",
                  start_location: { lng: 51.4456607, lat: 25.2337942 },
                  duration: { text: "2 mins", value: 90 },
                  end_location: { lat: 25.2267513, lng: 51.43351759999999 },
                  html_instructions:
                    'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                  polyline: {
                    points:
                      "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                  },
                  distance: { value: 1451, text: "1.5 km" },
                },
                {
                  duration: { text: "1 min", value: 10 },
                  html_instructions:
                    "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                  travel_mode: "DRIVING",
                  distance: { value: 92, text: "92 m" },
                  end_location: { lng: 51.4328437, lat: 25.2262036 },
                  start_location: { lat: 25.2267513, lng: 51.43351759999999 },
                  maneuver: "turn-slight-left",
                  polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                },
                {
                  end_location: { lat: 25.2257335, lng: 51.4323655 },
                  html_instructions: "Continue straight",
                  maneuver: "straight",
                  travel_mode: "DRIVING",
                  duration: { text: "1 min", value: 19 },
                  polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                  distance: { value: 71, text: "71 m" },
                  start_location: { lat: 25.2262036, lng: 51.4328437 },
                },
                {
                  polyline: {
                    points:
                      "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                  },
                  travel_mode: "DRIVING",
                  duration: { value: 72, text: "1 min" },
                  distance: { text: "0.8 km", value: 813 },
                  start_location: { lng: 51.4323655, lat: 25.2257335 },
                  html_instructions: "Continue onto <b>E Industrial Rd</b>",
                  end_location: { lng: 51.4357808, lat: 25.2196178 },
                },
                {
                  distance: { value: 263, text: "0.3 km" },
                  maneuver: "turn-right",
                  polyline: { points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA" },
                  end_location: { lat: 25.2182661, lng: 51.43371639999999 },
                  start_location: { lat: 25.2196178, lng: 51.4357808 },
                  html_instructions: "Turn <b>right</b> onto <b>Street 2</b>",
                  duration: { text: "1 min", value: 27 },
                  travel_mode: "DRIVING",
                },
                {
                  travel_mode: "DRIVING",
                  end_location: { lat: 25.2135618, lng: 51.4366206 },
                  duration: { value: 78, text: "1 min" },
                  maneuver: "roundabout-right",
                  start_location: { lng: 51.43371639999999, lat: 25.2182661 },
                  distance: { value: 682, text: "0.7 km" },
                  html_instructions:
                    "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                  polyline: {
                    points:
                      "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                  },
                },
                {
                  distance: { text: "29 m", value: 29 },
                  start_location: { lat: 25.2135618, lng: 51.4366206 },
                  maneuver: "turn-right",
                  travel_mode: "DRIVING",
                  html_instructions: "Turn <b>right</b> onto <b>Street 8</b>",
                  end_location: { lng: 51.4364944, lat: 25.2133289 },
                  duration: { value: 12, text: "1 min" },
                  polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                },
                {
                  distance: { value: 16, text: "16 m" },
                  maneuver: "turn-left",
                  duration: { value: 8, text: "1 min" },
                  travel_mode: "DRIVING",
                  end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                  start_location: { lng: 51.4364944, lat: 25.2133289 },
                  polyline: { points: "in{xCae}xHXO" },
                  html_instructions: "Turn <b>left</b>",
                },
              ],
              traffic_speed_entry: [],
            },
          ],
          waypoint_order: [0, 1],
          warnings: [],
          copyrights: "Map data ©2024 Google",
          overview_polyline: {
            points:
              "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
          },
        },
      ],
    },
    passengers: {
      C1GECLApV4W0bVtgeFk4iRpZYRl1: {
        userId: "user_id",
        name: "Jassim",
        username: "abcd",
        location: {
          geohash: "thkxgdt7m0",
          address: "858, الدوحة، Qatar",
          coordinates: { latitude: 25.282848, longitude: 51.489361 },
        },
        credits: 27.8,
        co2Amount: 4.313694566631215,
        avatar:
          "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
        seat: 0,
        id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
        wallet_id: "spencer_carbon.testnet",
        carpool: {
          id: "8292058029514",
          owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
          co2Amount: 12.612562888268984,
          directions: {
            status: "OK",
            geocoded_waypoints: [
              {
                geocoder_status: "OK",
                types: ["establishment", "moving_company", "point_of_interest"],
                place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
              },
              {
                place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                types: [
                  "establishment",
                  "general_contractor",
                  "point_of_interest",
                ],
                geocoder_status: "OK",
              },
              {
                types: ["premise"],
                place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                geocoder_status: "OK",
              },
              {
                types: ["street_address"],
                geocoder_status: "OK",
                place_id:
                  "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
              },
            ],
            routes: [
              {
                summary: "Al Rayyan Rd",
                bounds: {
                  northeast: { lng: 51.518743, lat: 25.291958 },
                  southwest: { lat: 25.2132031, lng: 51.4319062 },
                },
                legs: [
                  {
                    end_location: { lat: 25.2856124, lng: 51.5145907 },
                    start_location: { lat: 25.2827107, lng: 51.4906083 },
                    steps: [
                      {
                        polyline: { points: "}_iyCiwgyHz@B" },
                        end_location: { lng: 51.4905936, lat: 25.2824136 },
                        start_location: { lat: 25.2827107, lng: 51.4906083 },
                        distance: { value: 33, text: "33 m" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                        duration: { value: 4, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                        travel_mode: "DRIVING",
                        distance: { value: 574, text: "0.6 km" },
                        duration: { value: 49, text: "1 min" },
                        end_location: { lng: 51.4871118, lat: 25.2801532 },
                        polyline: {
                          points:
                            "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                        },
                        start_location: { lng: 51.4905936, lat: 25.2824136 },
                      },
                      {
                        html_instructions: "Turn <b>left</b>",
                        duration: { text: "1 min", value: 44 },
                        maneuver: "turn-left",
                        polyline: {
                          points:
                            "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                        },
                        end_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                        distance: { value: 426, text: "0.4 km" },
                        start_location: { lat: 25.2801532, lng: 51.4871118 },
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        polyline: { points: "cphyCoxgyHEaBAsA" },
                        html_instructions:
                          "Continue straight onto <b>Al Sadd St</b>",
                        end_location: { lat: 25.2802165, lng: 51.4917143 },
                        maneuver: "straight",
                        distance: { value: 93, text: "93 m" },
                        start_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                      },
                      {
                        distance: { value: 236, text: "0.2 km" },
                        html_instructions: "Slight <b>left</b>",
                        polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                        start_location: { lat: 25.2802165, lng: 51.4917143 },
                        travel_mode: "DRIVING",
                        end_location: { lat: 25.2804496, lng: 51.4940386 },
                        duration: { text: "1 min", value: 71 },
                        maneuver: "turn-slight-left",
                      },
                      {
                        duration: { text: "1 min", value: 38 },
                        end_location: { lat: 25.281604, lng: 51.4947254 },
                        polyline: {
                          points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                        },
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        start_location: { lng: 51.4940386, lat: 25.2804496 },
                        distance: { value: 163, text: "0.2 km" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                      },
                      {
                        duration: { text: "1 min", value: 76 },
                        polyline: {
                          points:
                            "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                        },
                        start_location: { lat: 25.281604, lng: 51.4947254 },
                        html_instructions:
                          "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                        maneuver: "keep-left",
                        end_location: { lng: 51.4965617, lat: 25.2914195 },
                        travel_mode: "DRIVING",
                        distance: { text: "1.1 km", value: 1109 },
                      },
                      {
                        start_location: { lat: 25.2914195, lng: 51.4965617 },
                        maneuver: "turn-right",
                        duration: { value: 209, text: "3 mins" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                        polyline: {
                          points:
                            "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                        },
                        end_location: {
                          lat: 25.2893902,
                          lng: 51.51516729999999,
                        },
                        distance: { value: 1922, text: "1.9 km" },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { value: 144, text: "0.1 km" },
                        start_location: {
                          lng: 51.51516729999999,
                          lat: 25.2893902,
                        },
                        end_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej</b>",
                        duration: { value: 15, text: "1 min" },
                        travel_mode: "DRIVING",
                      },
                      {
                        polyline: {
                          points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                        },
                        duration: { text: "1 min", value: 11 },
                        end_location: { lng: 51.516228, lat: 25.2874383 },
                        start_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        travel_mode: "DRIVING",
                        distance: { text: "0.1 km", value: 122 },
                        html_instructions:
                          'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-slight-right",
                        duration: { text: "1 min", value: 21 },
                        polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                        start_location: { lng: 51.516228, lat: 25.2874383 },
                        end_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        distance: { text: "0.3 km", value: 283 },
                        html_instructions:
                          'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        start_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                        duration: { text: "1 min", value: 32 },
                        polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                        distance: { text: "0.1 km", value: 125 },
                        end_location: { lng: 51.5155496, lat: 25.2848165 },
                      },
                      {
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        duration: { text: "1 min", value: 21 },
                        maneuver: "turn-right",
                        distance: { value: 74, text: "74 m" },
                        polyline: {
                          points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                        },
                        end_location: { lat: 25.2850716, lng: 51.5150352 },
                        start_location: { lat: 25.2848165, lng: 51.5155496 },
                      },
                      {
                        polyline: { points: "uniyC_plyHqBJ" },
                        duration: { text: "1 min", value: 23 },
                        end_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        start_location: { lat: 25.2850716, lng: 51.5150352 },
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        distance: { value: 64, text: "64 m" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 14 },
                        start_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        end_location: { lng: 51.5145907, lat: 25.2856124 },
                        distance: { text: "39 m", value: 39 },
                        polyline: { points: "griyCsolyH?p@DZ" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        maneuver: "turn-left",
                      },
                    ],
                    start_address: "858, الدوحة، Qatar",
                    end_address: "7GP7+6RG, Doha, Qatar",
                    traffic_speed_entry: [],
                    duration: { value: 636, text: "11 mins" },
                    distance: { text: "5.4 km", value: 5407 },
                    via_waypoint: [],
                  },
                  {
                    start_address: "7GP7+6RG, Doha, Qatar",
                    duration: { value: 598, text: "10 mins" },
                    traffic_speed_entry: [],
                    start_location: { lat: 25.2856124, lng: 51.5145907 },
                    steps: [
                      {
                        duration: { value: 73, text: "1 min" },
                        end_location: { lat: 25.2859334, lng: 51.5165533 },
                        start_location: { lat: 25.2856124, lng: 51.5145907 },
                        distance: { text: "0.2 km", value: 201 },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                        },
                      },
                      {
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                        distance: { text: "0.7 km", value: 735 },
                        end_location: { lng: 51.518743, lat: 25.2796568 },
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                        },
                        duration: { text: "1 min", value: 78 },
                        start_location: { lng: 51.5165533, lat: 25.2859334 },
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { text: "0.4 km", value: 393 },
                        end_location: { lat: 25.2769643, lng: 51.5164061 },
                        maneuver: "turn-slight-right",
                        start_location: { lat: 25.2796568, lng: 51.518743 },
                        polyline: {
                          points:
                            "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                        },
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                        duration: { text: "1 min", value: 59 },
                      },
                      {
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.5164061, lat: 25.2769643 },
                        polyline: {
                          points:
                            "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                        },
                        end_location: { lng: 51.500835, lat: 25.2663847 },
                        duration: { value: 229, text: "4 mins" },
                        distance: { value: 1963, text: "2.0 km" },
                        maneuver: "merge",
                        html_instructions:
                          "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                      },
                      {
                        maneuver: "turn-slight-right",
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                        end_location: { lng: 51.497229, lat: 25.2643805 },
                        distance: { text: "0.4 km", value: 428 },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2663847, lng: 51.500835 },
                        polyline: {
                          points:
                            "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                        },
                        duration: { value: 40, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                        maneuver: "roundabout-right",
                        polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                        duration: { value: 22, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.497229, lat: 25.2643805 },
                        distance: { text: "0.2 km", value: 182 },
                        end_location: { lng: 51.4962302, lat: 25.2656775 },
                      },
                      {
                        polyline: { points: "oueyCmzhyH]DKB" },
                        start_location: { lat: 25.2656775, lng: 51.4962302 },
                        end_location: { lat: 25.2658942, lng: 51.4961765 },
                        travel_mode: "DRIVING",
                        distance: { value: 25, text: "25 m" },
                        maneuver: "turn-slight-right",
                        html_instructions:
                          "Slight <b>right</b> onto <b>Al Nasr St</b>",
                        duration: { value: 3, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                        maneuver: "turn-slight-right",
                        end_location: { lng: 51.4995317, lat: 25.267547 },
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                        },
                        distance: { value: 389, text: "0.4 km" },
                        duration: { value: 53, text: "1 min" },
                        start_location: { lat: 25.2658942, lng: 51.4961765 },
                      },
                      {
                        distance: { value: 111, text: "0.1 km" },
                        start_location: { lat: 25.267547, lng: 51.4995317 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohammed St</b>",
                        duration: { value: 22, text: "1 min" },
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        travel_mode: "DRIVING",
                        polyline: { points: "eafyCaoiyHQFiDdA" },
                        maneuver: "turn-left",
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        end_location: {
                          lat: 25.2683307,
                          lng: 51.49855549999999,
                        },
                        distance: { text: "62 m", value: 62 },
                        duration: { text: "1 min", value: 19 },
                        polyline: { points: "agfyCsliyH\\vABNAJ" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        start_location: { lng: 51.499144, lat: 25.2684856 },
                      },
                    ],
                    via_waypoint: [],
                    end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                    distance: { text: "4.5 km", value: 4489 },
                    end_address: "7F9X+C9V, Doha, Qatar",
                  },
                  {
                    end_address: "28 Street 8, Ar Rayyan, Qatar",
                    start_address: "7F9X+C9V, Doha, Qatar",
                    via_waypoint: [],
                    duration: { text: "13 mins", value: 795 },
                    start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                    distance: { value: 11216, text: "11.2 km" },
                    end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                    steps: [
                      {
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        start_location: {
                          lat: 25.2683307,
                          lng: 51.49855549999999,
                        },
                        distance: { value: 62, text: "62 m" },
                        duration: { text: "1 min", value: 14 },
                        polyline: { points: "affyC_iiyH@KCO]wA" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                      },
                      {
                        distance: { value: 28, text: "28 m" },
                        duration: { value: 9, text: "1 min" },
                        maneuver: "turn-left",
                        start_location: { lng: 51.499144, lat: 25.2684856 },
                        end_location: { lng: 51.499066, lat: 25.2687298 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                        travel_mode: "DRIVING",
                        polyline: { points: "agfyCsliyHo@L" },
                      },
                      {
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Baraha</b>",
                        polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        distance: { text: "0.2 km", value: 154 },
                        end_location: { lat: 25.2692232, lng: 51.500496 },
                        duration: { value: 32, text: "1 min" },
                        start_location: { lng: 51.499066, lat: 25.2687298 },
                      },
                      {
                        end_location: { lng: 51.500265, lat: 25.269806 },
                        polyline: { points: "skfyCcuiyH{@Ty@X" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                        duration: { value: 13, text: "1 min" },
                        distance: { text: "69 m", value: 69 },
                        maneuver: "turn-left",
                        start_location: { lng: 51.500496, lat: 25.2692232 },
                      },
                      {
                        polyline: {
                          points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                        },
                        start_location: { lat: 25.269806, lng: 51.500265 },
                        distance: { text: "0.2 km", value: 223 },
                        duration: { value: 29, text: "1 min" },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        end_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                      },
                      {
                        polyline: {
                          points:
                            "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                        },
                        duration: { value: 48, text: "1 min" },
                        end_location: { lng: 51.5033973, lat: 25.2679045 },
                        travel_mode: "DRIVING",
                        maneuver: "roundabout-right",
                        distance: { text: "0.3 km", value: 326 },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                        start_location: {
                          lng: 51.50232339999999,
                          lat: 25.2705523,
                        },
                      },
                      {
                        end_location: { lng: 51.45117, lat: 25.2368244 },
                        polyline: {
                          points:
                            "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                        },
                        duration: { value: 283, text: "5 mins" },
                        html_instructions: "Merge onto <b>Salwa Rd</b>",
                        start_location: { lng: 51.5033973, lat: 25.2679045 },
                        travel_mode: "DRIVING",
                        distance: { text: "6.3 km", value: 6288 },
                        maneuver: "merge",
                      },
                      {
                        start_location: { lng: 51.45117, lat: 25.2368244 },
                        maneuver: "ramp-right",
                        duration: { text: "1 min", value: 10 },
                        travel_mode: "DRIVING",
                        distance: { text: "0.2 km", value: 194 },
                        html_instructions:
                          'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                        polyline: { points: "ca`yCy``yHpAnDlAdD" },
                        end_location: { lng: 51.4494587, lat: 25.2360177 },
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { value: 397, text: "0.4 km" },
                        end_location: { lat: 25.2340694, lng: 51.4461502 },
                        duration: { text: "1 min", value: 33 },
                        html_instructions:
                          "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        maneuver: "merge",
                        start_location: { lat: 25.2360177, lng: 51.4494587 },
                        polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                      },
                      {
                        duration: { value: 8, text: "1 min" },
                        maneuver: "straight",
                        start_location: { lng: 51.4461502, lat: 25.2340694 },
                        html_instructions:
                          "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        end_location: { lat: 25.2337942, lng: 51.4456607 },
                        polyline: { points: "}o_yCma_yHP`@d@~@" },
                        distance: { text: "58 m", value: 58 },
                        travel_mode: "DRIVING",
                      },
                      {
                        maneuver: "straight",
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.4456607, lat: 25.2337942 },
                        duration: { text: "2 mins", value: 90 },
                        end_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        html_instructions:
                          'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                        polyline: {
                          points:
                            "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                        },
                        distance: { value: 1451, text: "1.5 km" },
                      },
                      {
                        duration: { text: "1 min", value: 10 },
                        html_instructions:
                          "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                        travel_mode: "DRIVING",
                        distance: { value: 92, text: "92 m" },
                        end_location: { lng: 51.4328437, lat: 25.2262036 },
                        start_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        maneuver: "turn-slight-left",
                        polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                      },
                      {
                        end_location: { lat: 25.2257335, lng: 51.4323655 },
                        html_instructions: "Continue straight",
                        maneuver: "straight",
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 19 },
                        polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                        distance: { value: 71, text: "71 m" },
                        start_location: { lat: 25.2262036, lng: 51.4328437 },
                      },
                      {
                        polyline: {
                          points:
                            "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                        },
                        travel_mode: "DRIVING",
                        duration: { value: 72, text: "1 min" },
                        distance: { text: "0.8 km", value: 813 },
                        start_location: { lng: 51.4323655, lat: 25.2257335 },
                        html_instructions:
                          "Continue onto <b>E Industrial Rd</b>",
                        end_location: { lng: 51.4357808, lat: 25.2196178 },
                      },
                      {
                        distance: { value: 263, text: "0.3 km" },
                        maneuver: "turn-right",
                        polyline: {
                          points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                        },
                        end_location: {
                          lat: 25.2182661,
                          lng: 51.43371639999999,
                        },
                        start_location: { lat: 25.2196178, lng: 51.4357808 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 2</b>",
                        duration: { text: "1 min", value: 27 },
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        end_location: { lat: 25.2135618, lng: 51.4366206 },
                        duration: { value: 78, text: "1 min" },
                        maneuver: "roundabout-right",
                        start_location: {
                          lng: 51.43371639999999,
                          lat: 25.2182661,
                        },
                        distance: { value: 682, text: "0.7 km" },
                        html_instructions:
                          "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                        polyline: {
                          points:
                            "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                        },
                      },
                      {
                        distance: { text: "29 m", value: 29 },
                        start_location: { lat: 25.2135618, lng: 51.4366206 },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 8</b>",
                        end_location: { lng: 51.4364944, lat: 25.2133289 },
                        duration: { value: 12, text: "1 min" },
                        polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                      },
                      {
                        distance: { value: 16, text: "16 m" },
                        maneuver: "turn-left",
                        duration: { value: 8, text: "1 min" },
                        travel_mode: "DRIVING",
                        end_location: {
                          lat: 25.2132031,
                          lng: 51.43657289999999,
                        },
                        start_location: { lng: 51.4364944, lat: 25.2133289 },
                        polyline: { points: "in{xCae}xHXO" },
                        html_instructions: "Turn <b>left</b>",
                      },
                    ],
                    traffic_speed_entry: [],
                  },
                ],
                waypoint_order: [0, 1],
                warnings: [],
                copyrights: "Map data ©2024 Google",
                overview_polyline: {
                  points:
                    "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                },
              },
            ],
          },
          completedAt: 1707942203548,
          destination: {
            coordinates: { latitude: 25.213204, longitude: 51.43657469999999 },
            geohash: "thkx6myvx1",
            type: "corporate-branch",
            address: "Street 8, Doha, Qatar",
            id: "9VZwCrYZfl03ob5FrD4F.0",
          },
          status: "completed",
          skin: null,
          origin: {
            geohash: "thkxgdt7m0",
            address: "858, الدوحة، Qatar",
            coordinates: { longitude: 51.489361, latitude: 25.282848 },
          },
          preferences: {
            female_passengers_only: null,
            verified_passengers_only: null,
          },
          car: {
            seat: 3,
            co2_p_km: 0.3379843740994449,
            year: 2021,
            make: "Toyota",
            type: "carbon-emitting",
            model: "LAND CRUSER WAGON 4WD",
          },
          rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
          createdAt: 1707941970485,
          passengersArray: [
            {
              corporate: "9VZwCrYZfl03ob5FrD4F",
              wallet_id: "spencer_carbon.testnet",
              location: {
                geohash: "thkxgdt7m0",
                address: "858, الدوحة، Qatar",
                coordinates: { latitude: 25.282848, longitude: 51.489361 },
              },
              co2Amount: 4.313694566631215,
              seat: 0,
              name: "Jassim",
              id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
              username: "abcd",
              credits: 27.8,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
            },
            {
              co2Amount: 4.508035581738396,
              seat: 2,
              wallet_id: "sofia_carbon.testnet",
              username: "michaelscott",
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
              status: "picked-up",
              location: {
                coordinates: { longitude: 51.514615, latitude: 25.285509 },
                geohash: "thkxu5pfc8",
                address: "7GP7+6RG, Doha, Qatar",
              },
              credits: 13.9,
              corporate: "9VZwCrYZfl03ob5FrD4F",
              id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
              name: "Ahmed",
            },
            {
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
              status: "picked-up",
              seat: 1,
              location: {
                address: "7F9X+C9V, Doha, Qatar",
                coordinates: { latitude: 25.268246, longitude: 51.498513 },
                geohash: "thkxezun4q",
              },
              co2Amount: 3.7908327398993733,
              wallet_id: "sarah_carbon.testnet",
              credits: 13,
              username: "sarah",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
              name: "Faisal",
            },
          ],
        },
        type: "carpool_passenger",
        corporate: "9VZwCrYZfl03ob5FrD4F",
      },
      lpd29qTdlURFvrSDO0w5WBODcSp2: {
        userId: "user_id",
        name: "Ahmed",
        username: "michaelscott",
        location: {
          coordinates: { longitude: 51.514615, latitude: 25.285509 },
          geohash: "thkxu5pfc8",
          address: "7GP7+6RG, Doha, Qatar",
        },
        credits: 13.9,
        co2Amount: 4.508035581738396,
        avatar:
          "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
        seat: 2,
        id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
        wallet_id: "sofia_carbon.testnet",
        status: "picked-up",
        carpool: {
          id: "8292058029514",
          owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
          co2Amount: 12.612562888268984,
          directions: {
            status: "OK",
            geocoded_waypoints: [
              {
                geocoder_status: "OK",
                types: ["establishment", "moving_company", "point_of_interest"],
                place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
              },
              {
                place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                types: [
                  "establishment",
                  "general_contractor",
                  "point_of_interest",
                ],
                geocoder_status: "OK",
              },
              {
                types: ["premise"],
                place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                geocoder_status: "OK",
              },
              {
                types: ["street_address"],
                geocoder_status: "OK",
                place_id:
                  "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
              },
            ],
            routes: [
              {
                summary: "Al Rayyan Rd",
                bounds: {
                  northeast: { lng: 51.518743, lat: 25.291958 },
                  southwest: { lat: 25.2132031, lng: 51.4319062 },
                },
                legs: [
                  {
                    end_location: { lat: 25.2856124, lng: 51.5145907 },
                    start_location: { lat: 25.2827107, lng: 51.4906083 },
                    steps: [
                      {
                        polyline: { points: "}_iyCiwgyHz@B" },
                        end_location: { lng: 51.4905936, lat: 25.2824136 },
                        start_location: { lat: 25.2827107, lng: 51.4906083 },
                        distance: { value: 33, text: "33 m" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                        duration: { value: 4, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                        travel_mode: "DRIVING",
                        distance: { value: 574, text: "0.6 km" },
                        duration: { value: 49, text: "1 min" },
                        end_location: { lng: 51.4871118, lat: 25.2801532 },
                        polyline: {
                          points:
                            "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                        },
                        start_location: { lng: 51.4905936, lat: 25.2824136 },
                      },
                      {
                        html_instructions: "Turn <b>left</b>",
                        duration: { text: "1 min", value: 44 },
                        maneuver: "turn-left",
                        polyline: {
                          points:
                            "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                        },
                        end_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                        distance: { value: 426, text: "0.4 km" },
                        start_location: { lat: 25.2801532, lng: 51.4871118 },
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        polyline: { points: "cphyCoxgyHEaBAsA" },
                        html_instructions:
                          "Continue straight onto <b>Al Sadd St</b>",
                        end_location: { lat: 25.2802165, lng: 51.4917143 },
                        maneuver: "straight",
                        distance: { value: 93, text: "93 m" },
                        start_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                      },
                      {
                        distance: { value: 236, text: "0.2 km" },
                        html_instructions: "Slight <b>left</b>",
                        polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                        start_location: { lat: 25.2802165, lng: 51.4917143 },
                        travel_mode: "DRIVING",
                        end_location: { lat: 25.2804496, lng: 51.4940386 },
                        duration: { text: "1 min", value: 71 },
                        maneuver: "turn-slight-left",
                      },
                      {
                        duration: { text: "1 min", value: 38 },
                        end_location: { lat: 25.281604, lng: 51.4947254 },
                        polyline: {
                          points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                        },
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        start_location: { lng: 51.4940386, lat: 25.2804496 },
                        distance: { value: 163, text: "0.2 km" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                      },
                      {
                        duration: { text: "1 min", value: 76 },
                        polyline: {
                          points:
                            "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                        },
                        start_location: { lat: 25.281604, lng: 51.4947254 },
                        html_instructions:
                          "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                        maneuver: "keep-left",
                        end_location: { lng: 51.4965617, lat: 25.2914195 },
                        travel_mode: "DRIVING",
                        distance: { text: "1.1 km", value: 1109 },
                      },
                      {
                        start_location: { lat: 25.2914195, lng: 51.4965617 },
                        maneuver: "turn-right",
                        duration: { value: 209, text: "3 mins" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                        polyline: {
                          points:
                            "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                        },
                        end_location: {
                          lat: 25.2893902,
                          lng: 51.51516729999999,
                        },
                        distance: { value: 1922, text: "1.9 km" },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { value: 144, text: "0.1 km" },
                        start_location: {
                          lng: 51.51516729999999,
                          lat: 25.2893902,
                        },
                        end_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej</b>",
                        duration: { value: 15, text: "1 min" },
                        travel_mode: "DRIVING",
                      },
                      {
                        polyline: {
                          points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                        },
                        duration: { text: "1 min", value: 11 },
                        end_location: { lng: 51.516228, lat: 25.2874383 },
                        start_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        travel_mode: "DRIVING",
                        distance: { text: "0.1 km", value: 122 },
                        html_instructions:
                          'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-slight-right",
                        duration: { text: "1 min", value: 21 },
                        polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                        start_location: { lng: 51.516228, lat: 25.2874383 },
                        end_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        distance: { text: "0.3 km", value: 283 },
                        html_instructions:
                          'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        start_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                        duration: { text: "1 min", value: 32 },
                        polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                        distance: { text: "0.1 km", value: 125 },
                        end_location: { lng: 51.5155496, lat: 25.2848165 },
                      },
                      {
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        duration: { text: "1 min", value: 21 },
                        maneuver: "turn-right",
                        distance: { value: 74, text: "74 m" },
                        polyline: {
                          points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                        },
                        end_location: { lat: 25.2850716, lng: 51.5150352 },
                        start_location: { lat: 25.2848165, lng: 51.5155496 },
                      },
                      {
                        polyline: { points: "uniyC_plyHqBJ" },
                        duration: { text: "1 min", value: 23 },
                        end_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        start_location: { lat: 25.2850716, lng: 51.5150352 },
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        distance: { value: 64, text: "64 m" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 14 },
                        start_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        end_location: { lng: 51.5145907, lat: 25.2856124 },
                        distance: { text: "39 m", value: 39 },
                        polyline: { points: "griyCsolyH?p@DZ" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        maneuver: "turn-left",
                      },
                    ],
                    start_address: "858, الدوحة، Qatar",
                    end_address: "7GP7+6RG, Doha, Qatar",
                    traffic_speed_entry: [],
                    duration: { value: 636, text: "11 mins" },
                    distance: { text: "5.4 km", value: 5407 },
                    via_waypoint: [],
                  },
                  {
                    start_address: "7GP7+6RG, Doha, Qatar",
                    duration: { value: 598, text: "10 mins" },
                    traffic_speed_entry: [],
                    start_location: { lat: 25.2856124, lng: 51.5145907 },
                    steps: [
                      {
                        duration: { value: 73, text: "1 min" },
                        end_location: { lat: 25.2859334, lng: 51.5165533 },
                        start_location: { lat: 25.2856124, lng: 51.5145907 },
                        distance: { text: "0.2 km", value: 201 },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                        },
                      },
                      {
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                        distance: { text: "0.7 km", value: 735 },
                        end_location: { lng: 51.518743, lat: 25.2796568 },
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                        },
                        duration: { text: "1 min", value: 78 },
                        start_location: { lng: 51.5165533, lat: 25.2859334 },
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { text: "0.4 km", value: 393 },
                        end_location: { lat: 25.2769643, lng: 51.5164061 },
                        maneuver: "turn-slight-right",
                        start_location: { lat: 25.2796568, lng: 51.518743 },
                        polyline: {
                          points:
                            "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                        },
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                        duration: { text: "1 min", value: 59 },
                      },
                      {
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.5164061, lat: 25.2769643 },
                        polyline: {
                          points:
                            "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                        },
                        end_location: { lng: 51.500835, lat: 25.2663847 },
                        duration: { value: 229, text: "4 mins" },
                        distance: { value: 1963, text: "2.0 km" },
                        maneuver: "merge",
                        html_instructions:
                          "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                      },
                      {
                        maneuver: "turn-slight-right",
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                        end_location: { lng: 51.497229, lat: 25.2643805 },
                        distance: { text: "0.4 km", value: 428 },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2663847, lng: 51.500835 },
                        polyline: {
                          points:
                            "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                        },
                        duration: { value: 40, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                        maneuver: "roundabout-right",
                        polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                        duration: { value: 22, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.497229, lat: 25.2643805 },
                        distance: { text: "0.2 km", value: 182 },
                        end_location: { lng: 51.4962302, lat: 25.2656775 },
                      },
                      {
                        polyline: { points: "oueyCmzhyH]DKB" },
                        start_location: { lat: 25.2656775, lng: 51.4962302 },
                        end_location: { lat: 25.2658942, lng: 51.4961765 },
                        travel_mode: "DRIVING",
                        distance: { value: 25, text: "25 m" },
                        maneuver: "turn-slight-right",
                        html_instructions:
                          "Slight <b>right</b> onto <b>Al Nasr St</b>",
                        duration: { value: 3, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                        maneuver: "turn-slight-right",
                        end_location: { lng: 51.4995317, lat: 25.267547 },
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                        },
                        distance: { value: 389, text: "0.4 km" },
                        duration: { value: 53, text: "1 min" },
                        start_location: { lat: 25.2658942, lng: 51.4961765 },
                      },
                      {
                        distance: { value: 111, text: "0.1 km" },
                        start_location: { lat: 25.267547, lng: 51.4995317 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohammed St</b>",
                        duration: { value: 22, text: "1 min" },
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        travel_mode: "DRIVING",
                        polyline: { points: "eafyCaoiyHQFiDdA" },
                        maneuver: "turn-left",
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        end_location: {
                          lat: 25.2683307,
                          lng: 51.49855549999999,
                        },
                        distance: { text: "62 m", value: 62 },
                        duration: { text: "1 min", value: 19 },
                        polyline: { points: "agfyCsliyH\\vABNAJ" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        start_location: { lng: 51.499144, lat: 25.2684856 },
                      },
                    ],
                    via_waypoint: [],
                    end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                    distance: { text: "4.5 km", value: 4489 },
                    end_address: "7F9X+C9V, Doha, Qatar",
                  },
                  {
                    end_address: "28 Street 8, Ar Rayyan, Qatar",
                    start_address: "7F9X+C9V, Doha, Qatar",
                    via_waypoint: [],
                    duration: { text: "13 mins", value: 795 },
                    start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                    distance: { value: 11216, text: "11.2 km" },
                    end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                    steps: [
                      {
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        start_location: {
                          lat: 25.2683307,
                          lng: 51.49855549999999,
                        },
                        distance: { value: 62, text: "62 m" },
                        duration: { text: "1 min", value: 14 },
                        polyline: { points: "affyC_iiyH@KCO]wA" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                      },
                      {
                        distance: { value: 28, text: "28 m" },
                        duration: { value: 9, text: "1 min" },
                        maneuver: "turn-left",
                        start_location: { lng: 51.499144, lat: 25.2684856 },
                        end_location: { lng: 51.499066, lat: 25.2687298 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                        travel_mode: "DRIVING",
                        polyline: { points: "agfyCsliyHo@L" },
                      },
                      {
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Baraha</b>",
                        polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        distance: { text: "0.2 km", value: 154 },
                        end_location: { lat: 25.2692232, lng: 51.500496 },
                        duration: { value: 32, text: "1 min" },
                        start_location: { lng: 51.499066, lat: 25.2687298 },
                      },
                      {
                        end_location: { lng: 51.500265, lat: 25.269806 },
                        polyline: { points: "skfyCcuiyH{@Ty@X" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                        duration: { value: 13, text: "1 min" },
                        distance: { text: "69 m", value: 69 },
                        maneuver: "turn-left",
                        start_location: { lng: 51.500496, lat: 25.2692232 },
                      },
                      {
                        polyline: {
                          points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                        },
                        start_location: { lat: 25.269806, lng: 51.500265 },
                        distance: { text: "0.2 km", value: 223 },
                        duration: { value: 29, text: "1 min" },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        end_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                      },
                      {
                        polyline: {
                          points:
                            "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                        },
                        duration: { value: 48, text: "1 min" },
                        end_location: { lng: 51.5033973, lat: 25.2679045 },
                        travel_mode: "DRIVING",
                        maneuver: "roundabout-right",
                        distance: { text: "0.3 km", value: 326 },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                        start_location: {
                          lng: 51.50232339999999,
                          lat: 25.2705523,
                        },
                      },
                      {
                        end_location: { lng: 51.45117, lat: 25.2368244 },
                        polyline: {
                          points:
                            "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                        },
                        duration: { value: 283, text: "5 mins" },
                        html_instructions: "Merge onto <b>Salwa Rd</b>",
                        start_location: { lng: 51.5033973, lat: 25.2679045 },
                        travel_mode: "DRIVING",
                        distance: { text: "6.3 km", value: 6288 },
                        maneuver: "merge",
                      },
                      {
                        start_location: { lng: 51.45117, lat: 25.2368244 },
                        maneuver: "ramp-right",
                        duration: { text: "1 min", value: 10 },
                        travel_mode: "DRIVING",
                        distance: { text: "0.2 km", value: 194 },
                        html_instructions:
                          'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                        polyline: { points: "ca`yCy``yHpAnDlAdD" },
                        end_location: { lng: 51.4494587, lat: 25.2360177 },
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { value: 397, text: "0.4 km" },
                        end_location: { lat: 25.2340694, lng: 51.4461502 },
                        duration: { text: "1 min", value: 33 },
                        html_instructions:
                          "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        maneuver: "merge",
                        start_location: { lat: 25.2360177, lng: 51.4494587 },
                        polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                      },
                      {
                        duration: { value: 8, text: "1 min" },
                        maneuver: "straight",
                        start_location: { lng: 51.4461502, lat: 25.2340694 },
                        html_instructions:
                          "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        end_location: { lat: 25.2337942, lng: 51.4456607 },
                        polyline: { points: "}o_yCma_yHP`@d@~@" },
                        distance: { text: "58 m", value: 58 },
                        travel_mode: "DRIVING",
                      },
                      {
                        maneuver: "straight",
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.4456607, lat: 25.2337942 },
                        duration: { text: "2 mins", value: 90 },
                        end_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        html_instructions:
                          'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                        polyline: {
                          points:
                            "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                        },
                        distance: { value: 1451, text: "1.5 km" },
                      },
                      {
                        duration: { text: "1 min", value: 10 },
                        html_instructions:
                          "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                        travel_mode: "DRIVING",
                        distance: { value: 92, text: "92 m" },
                        end_location: { lng: 51.4328437, lat: 25.2262036 },
                        start_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        maneuver: "turn-slight-left",
                        polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                      },
                      {
                        end_location: { lat: 25.2257335, lng: 51.4323655 },
                        html_instructions: "Continue straight",
                        maneuver: "straight",
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 19 },
                        polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                        distance: { value: 71, text: "71 m" },
                        start_location: { lat: 25.2262036, lng: 51.4328437 },
                      },
                      {
                        polyline: {
                          points:
                            "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                        },
                        travel_mode: "DRIVING",
                        duration: { value: 72, text: "1 min" },
                        distance: { text: "0.8 km", value: 813 },
                        start_location: { lng: 51.4323655, lat: 25.2257335 },
                        html_instructions:
                          "Continue onto <b>E Industrial Rd</b>",
                        end_location: { lng: 51.4357808, lat: 25.2196178 },
                      },
                      {
                        distance: { value: 263, text: "0.3 km" },
                        maneuver: "turn-right",
                        polyline: {
                          points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                        },
                        end_location: {
                          lat: 25.2182661,
                          lng: 51.43371639999999,
                        },
                        start_location: { lat: 25.2196178, lng: 51.4357808 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 2</b>",
                        duration: { text: "1 min", value: 27 },
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        end_location: { lat: 25.2135618, lng: 51.4366206 },
                        duration: { value: 78, text: "1 min" },
                        maneuver: "roundabout-right",
                        start_location: {
                          lng: 51.43371639999999,
                          lat: 25.2182661,
                        },
                        distance: { value: 682, text: "0.7 km" },
                        html_instructions:
                          "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                        polyline: {
                          points:
                            "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                        },
                      },
                      {
                        distance: { text: "29 m", value: 29 },
                        start_location: { lat: 25.2135618, lng: 51.4366206 },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 8</b>",
                        end_location: { lng: 51.4364944, lat: 25.2133289 },
                        duration: { value: 12, text: "1 min" },
                        polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                      },
                      {
                        distance: { value: 16, text: "16 m" },
                        maneuver: "turn-left",
                        duration: { value: 8, text: "1 min" },
                        travel_mode: "DRIVING",
                        end_location: {
                          lat: 25.2132031,
                          lng: 51.43657289999999,
                        },
                        start_location: { lng: 51.4364944, lat: 25.2133289 },
                        polyline: { points: "in{xCae}xHXO" },
                        html_instructions: "Turn <b>left</b>",
                      },
                    ],
                    traffic_speed_entry: [],
                  },
                ],
                waypoint_order: [0, 1],
                warnings: [],
                copyrights: "Map data ©2024 Google",
                overview_polyline: {
                  points:
                    "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                },
              },
            ],
          },
          completedAt: 1707942203548,
          destination: {
            coordinates: { latitude: 25.213204, longitude: 51.43657469999999 },
            geohash: "thkx6myvx1",
            type: "corporate-branch",
            address: "Street 8, Doha, Qatar",
            id: "9VZwCrYZfl03ob5FrD4F.0",
          },
          status: "completed",
          skin: null,
          origin: {
            geohash: "thkxgdt7m0",
            address: "858, الدوحة، Qatar",
            coordinates: { longitude: 51.489361, latitude: 25.282848 },
          },
          preferences: {
            female_passengers_only: null,
            verified_passengers_only: null,
          },
          car: {
            seat: 3,
            co2_p_km: 0.3379843740994449,
            year: 2021,
            make: "Toyota",
            type: "carbon-emitting",
            model: "LAND CRUSER WAGON 4WD",
          },
          rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
          createdAt: 1707941970485,
          passengersArray: [
            {
              userId: "user_id",
              name: "Jassim",
              username: "abcd",
              location: {
                geohash: "thkxgdt7m0",
                address: "858, الدوحة، Qatar",
                coordinates: { latitude: 25.282848, longitude: 51.489361 },
              },
              credits: 27.8,
              co2Amount: 4.313694566631215,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
              seat: 0,
              id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
              wallet_id: "spencer_carbon.testnet",
              carpool: {
                id: "8292058029514",
                owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                co2Amount: 12.612562888268984,
                directions: {
                  status: "OK",
                  geocoded_waypoints: [
                    {
                      geocoder_status: "OK",
                      types: [
                        "establishment",
                        "moving_company",
                        "point_of_interest",
                      ],
                      place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                    },
                    {
                      place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                      types: [
                        "establishment",
                        "general_contractor",
                        "point_of_interest",
                      ],
                      geocoder_status: "OK",
                    },
                    {
                      types: ["premise"],
                      place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                      geocoder_status: "OK",
                    },
                    {
                      types: ["street_address"],
                      geocoder_status: "OK",
                      place_id:
                        "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                    },
                  ],
                  routes: [
                    {
                      summary: "Al Rayyan Rd",
                      bounds: {
                        northeast: { lng: 51.518743, lat: 25.291958 },
                        southwest: { lat: 25.2132031, lng: 51.4319062 },
                      },
                      legs: [
                        {
                          end_location: { lat: 25.2856124, lng: 51.5145907 },
                          start_location: { lat: 25.2827107, lng: 51.4906083 },
                          steps: [
                            {
                              polyline: { points: "}_iyCiwgyHz@B" },
                              end_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              start_location: {
                                lat: 25.2827107,
                                lng: 51.4906083,
                              },
                              distance: { value: 33, text: "33 m" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                              duration: { value: 4, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                              travel_mode: "DRIVING",
                              distance: { value: 574, text: "0.6 km" },
                              duration: { value: 49, text: "1 min" },
                              end_location: {
                                lng: 51.4871118,
                                lat: 25.2801532,
                              },
                              polyline: {
                                points:
                                  "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                              },
                              start_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                            },
                            {
                              html_instructions: "Turn <b>left</b>",
                              duration: { text: "1 min", value: 44 },
                              maneuver: "turn-left",
                              polyline: {
                                points:
                                  "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                              },
                              end_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                              distance: { value: 426, text: "0.4 km" },
                              start_location: {
                                lat: 25.2801532,
                                lng: 51.4871118,
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              polyline: { points: "cphyCoxgyHEaBAsA" },
                              html_instructions:
                                "Continue straight onto <b>Al Sadd St</b>",
                              end_location: {
                                lat: 25.2802165,
                                lng: 51.4917143,
                              },
                              maneuver: "straight",
                              distance: { value: 93, text: "93 m" },
                              start_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                            },
                            {
                              distance: { value: 236, text: "0.2 km" },
                              html_instructions: "Slight <b>left</b>",
                              polyline: {
                                points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                              },
                              start_location: {
                                lat: 25.2802165,
                                lng: 51.4917143,
                              },
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                              duration: { text: "1 min", value: 71 },
                              maneuver: "turn-slight-left",
                            },
                            {
                              duration: { text: "1 min", value: 38 },
                              end_location: { lat: 25.281604, lng: 51.4947254 },
                              polyline: {
                                points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                              },
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.4940386,
                                lat: 25.2804496,
                              },
                              distance: { value: 163, text: "0.2 km" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                            },
                            {
                              duration: { text: "1 min", value: 76 },
                              polyline: {
                                points:
                                  "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                              },
                              start_location: {
                                lat: 25.281604,
                                lng: 51.4947254,
                              },
                              html_instructions:
                                "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                              maneuver: "keep-left",
                              end_location: {
                                lng: 51.4965617,
                                lat: 25.2914195,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "1.1 km", value: 1109 },
                            },
                            {
                              start_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                              maneuver: "turn-right",
                              duration: { value: 209, text: "3 mins" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                              polyline: {
                                points:
                                  "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                              },
                              end_location: {
                                lat: 25.2893902,
                                lng: 51.51516729999999,
                              },
                              distance: { value: 1922, text: "1.9 km" },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { value: 144, text: "0.1 km" },
                              start_location: {
                                lng: 51.51516729999999,
                                lat: 25.2893902,
                              },
                              end_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej</b>",
                              duration: { value: 15, text: "1 min" },
                              travel_mode: "DRIVING",
                            },
                            {
                              polyline: {
                                points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                              },
                              duration: { text: "1 min", value: 11 },
                              end_location: { lng: 51.516228, lat: 25.2874383 },
                              start_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "0.1 km", value: 122 },
                              html_instructions:
                                'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-slight-right",
                              duration: { text: "1 min", value: 21 },
                              polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                              start_location: {
                                lng: 51.516228,
                                lat: 25.2874383,
                              },
                              end_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              distance: { text: "0.3 km", value: 283 },
                              html_instructions:
                                'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-right",
                              start_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                              duration: { text: "1 min", value: 32 },
                              polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                              distance: { text: "0.1 km", value: 125 },
                              end_location: {
                                lng: 51.5155496,
                                lat: 25.2848165,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              duration: { text: "1 min", value: 21 },
                              maneuver: "turn-right",
                              distance: { value: 74, text: "74 m" },
                              polyline: {
                                points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                              },
                              end_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              start_location: {
                                lat: 25.2848165,
                                lng: 51.5155496,
                              },
                            },
                            {
                              polyline: { points: "uniyC_plyHqBJ" },
                              duration: { text: "1 min", value: 23 },
                              end_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              start_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "turn-right",
                              distance: { value: 64, text: "64 m" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 14 },
                              start_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              end_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              distance: { text: "39 m", value: 39 },
                              polyline: { points: "griyCsolyH?p@DZ" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              maneuver: "turn-left",
                            },
                          ],
                          start_address: "858, الدوحة، Qatar",
                          end_address: "7GP7+6RG, Doha, Qatar",
                          traffic_speed_entry: [],
                          duration: { value: 636, text: "11 mins" },
                          distance: { text: "5.4 km", value: 5407 },
                          via_waypoint: [],
                        },
                        {
                          start_address: "7GP7+6RG, Doha, Qatar",
                          duration: { value: 598, text: "10 mins" },
                          traffic_speed_entry: [],
                          start_location: { lat: 25.2856124, lng: 51.5145907 },
                          steps: [
                            {
                              duration: { value: 73, text: "1 min" },
                              end_location: {
                                lat: 25.2859334,
                                lng: 51.5165533,
                              },
                              start_location: {
                                lat: 25.2856124,
                                lng: 51.5145907,
                              },
                              distance: { text: "0.2 km", value: 201 },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                              distance: { text: "0.7 km", value: 735 },
                              end_location: { lng: 51.518743, lat: 25.2796568 },
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                              },
                              duration: { text: "1 min", value: 78 },
                              start_location: {
                                lng: 51.5165533,
                                lat: 25.2859334,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { text: "0.4 km", value: 393 },
                              end_location: {
                                lat: 25.2769643,
                                lng: 51.5164061,
                              },
                              maneuver: "turn-slight-right",
                              start_location: {
                                lat: 25.2796568,
                                lng: 51.518743,
                              },
                              polyline: {
                                points:
                                  "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                              },
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                              duration: { text: "1 min", value: 59 },
                            },
                            {
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.5164061,
                                lat: 25.2769643,
                              },
                              polyline: {
                                points:
                                  "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                              },
                              end_location: { lng: 51.500835, lat: 25.2663847 },
                              duration: { value: 229, text: "4 mins" },
                              distance: { value: 1963, text: "2.0 km" },
                              maneuver: "merge",
                              html_instructions:
                                "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                            },
                            {
                              maneuver: "turn-slight-right",
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                              end_location: { lng: 51.497229, lat: 25.2643805 },
                              distance: { text: "0.4 km", value: 428 },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2663847,
                                lng: 51.500835,
                              },
                              polyline: {
                                points:
                                  "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                              },
                              duration: { value: 40, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                              maneuver: "roundabout-right",
                              polyline: {
                                points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                              },
                              duration: { value: 22, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.497229,
                                lat: 25.2643805,
                              },
                              distance: { text: "0.2 km", value: 182 },
                              end_location: {
                                lng: 51.4962302,
                                lat: 25.2656775,
                              },
                            },
                            {
                              polyline: { points: "oueyCmzhyH]DKB" },
                              start_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              end_location: {
                                lat: 25.2658942,
                                lng: 51.4961765,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 25, text: "25 m" },
                              maneuver: "turn-slight-right",
                              html_instructions:
                                "Slight <b>right</b> onto <b>Al Nasr St</b>",
                              duration: { value: 3, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                              maneuver: "turn-slight-right",
                              end_location: { lng: 51.4995317, lat: 25.267547 },
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                              },
                              distance: { value: 389, text: "0.4 km" },
                              duration: { value: 53, text: "1 min" },
                              start_location: {
                                lat: 25.2658942,
                                lng: 51.4961765,
                              },
                            },
                            {
                              distance: { value: 111, text: "0.1 km" },
                              start_location: {
                                lat: 25.267547,
                                lng: 51.4995317,
                              },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohammed St</b>",
                              duration: { value: 22, text: "1 min" },
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              travel_mode: "DRIVING",
                              polyline: { points: "eafyCaoiyHQFiDdA" },
                              maneuver: "turn-left",
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              end_location: {
                                lat: 25.2683307,
                                lng: 51.49855549999999,
                              },
                              distance: { text: "62 m", value: 62 },
                              duration: { text: "1 min", value: 19 },
                              polyline: { points: "agfyCsliyH\\vABNAJ" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              start_location: {
                                lng: 51.499144,
                                lat: 25.2684856,
                              },
                            },
                          ],
                          via_waypoint: [],
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { text: "4.5 km", value: 4489 },
                          end_address: "7F9X+C9V, Doha, Qatar",
                        },
                        {
                          end_address: "28 Street 8, Ar Rayyan, Qatar",
                          start_address: "7F9X+C9V, Doha, Qatar",
                          via_waypoint: [],
                          duration: { text: "13 mins", value: 795 },
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          distance: { value: 11216, text: "11.2 km" },
                          end_location: {
                            lat: 25.2132031,
                            lng: 51.43657289999999,
                          },
                          steps: [
                            {
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              start_location: {
                                lat: 25.2683307,
                                lng: 51.49855549999999,
                              },
                              distance: { value: 62, text: "62 m" },
                              duration: { text: "1 min", value: 14 },
                              polyline: { points: "affyC_iiyH@KCO]wA" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                            },
                            {
                              distance: { value: 28, text: "28 m" },
                              duration: { value: 9, text: "1 min" },
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.499144,
                                lat: 25.2684856,
                              },
                              end_location: { lng: 51.499066, lat: 25.2687298 },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                              travel_mode: "DRIVING",
                              polyline: { points: "agfyCsliyHo@L" },
                            },
                            {
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Baraha</b>",
                              polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              distance: { text: "0.2 km", value: 154 },
                              end_location: { lat: 25.2692232, lng: 51.500496 },
                              duration: { value: 32, text: "1 min" },
                              start_location: {
                                lng: 51.499066,
                                lat: 25.2687298,
                              },
                            },
                            {
                              end_location: { lng: 51.500265, lat: 25.269806 },
                              polyline: { points: "skfyCcuiyH{@Ty@X" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                              duration: { value: 13, text: "1 min" },
                              distance: { text: "69 m", value: 69 },
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.500496,
                                lat: 25.2692232,
                              },
                            },
                            {
                              polyline: {
                                points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                              },
                              start_location: {
                                lat: 25.269806,
                                lng: 51.500265,
                              },
                              distance: { text: "0.2 km", value: 223 },
                              duration: { value: 29, text: "1 min" },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                            },
                            {
                              polyline: {
                                points:
                                  "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                              },
                              duration: { value: 48, text: "1 min" },
                              end_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "roundabout-right",
                              distance: { text: "0.3 km", value: 326 },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                              start_location: {
                                lng: 51.50232339999999,
                                lat: 25.2705523,
                              },
                            },
                            {
                              end_location: { lng: 51.45117, lat: 25.2368244 },
                              polyline: {
                                points:
                                  "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                              },
                              duration: { value: 283, text: "5 mins" },
                              html_instructions: "Merge onto <b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "6.3 km", value: 6288 },
                              maneuver: "merge",
                            },
                            {
                              start_location: {
                                lng: 51.45117,
                                lat: 25.2368244,
                              },
                              maneuver: "ramp-right",
                              duration: { text: "1 min", value: 10 },
                              travel_mode: "DRIVING",
                              distance: { text: "0.2 km", value: 194 },
                              html_instructions:
                                'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                              polyline: { points: "ca`yCy``yHpAnDlAdD" },
                              end_location: {
                                lng: 51.4494587,
                                lat: 25.2360177,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { value: 397, text: "0.4 km" },
                              end_location: {
                                lat: 25.2340694,
                                lng: 51.4461502,
                              },
                              duration: { text: "1 min", value: 33 },
                              html_instructions:
                                "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              maneuver: "merge",
                              start_location: {
                                lat: 25.2360177,
                                lng: 51.4494587,
                              },
                              polyline: {
                                points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                              },
                            },
                            {
                              duration: { value: 8, text: "1 min" },
                              maneuver: "straight",
                              start_location: {
                                lng: 51.4461502,
                                lat: 25.2340694,
                              },
                              html_instructions:
                                "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              end_location: {
                                lat: 25.2337942,
                                lng: 51.4456607,
                              },
                              polyline: { points: "}o_yCma_yHP`@d@~@" },
                              distance: { text: "58 m", value: 58 },
                              travel_mode: "DRIVING",
                            },
                            {
                              maneuver: "straight",
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.4456607,
                                lat: 25.2337942,
                              },
                              duration: { text: "2 mins", value: 90 },
                              end_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              html_instructions:
                                'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                              polyline: {
                                points:
                                  "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                              },
                              distance: { value: 1451, text: "1.5 km" },
                            },
                            {
                              duration: { text: "1 min", value: 10 },
                              html_instructions:
                                "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                              travel_mode: "DRIVING",
                              distance: { value: 92, text: "92 m" },
                              end_location: {
                                lng: 51.4328437,
                                lat: 25.2262036,
                              },
                              start_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              maneuver: "turn-slight-left",
                              polyline: {
                                points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                              },
                            },
                            {
                              end_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              html_instructions: "Continue straight",
                              maneuver: "straight",
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 19 },
                              polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                              distance: { value: 71, text: "71 m" },
                              start_location: {
                                lat: 25.2262036,
                                lng: 51.4328437,
                              },
                            },
                            {
                              polyline: {
                                points:
                                  "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                              },
                              travel_mode: "DRIVING",
                              duration: { value: 72, text: "1 min" },
                              distance: { text: "0.8 km", value: 813 },
                              start_location: {
                                lng: 51.4323655,
                                lat: 25.2257335,
                              },
                              html_instructions:
                                "Continue onto <b>E Industrial Rd</b>",
                              end_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                            },
                            {
                              distance: { value: 263, text: "0.3 km" },
                              maneuver: "turn-right",
                              polyline: {
                                points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                              },
                              end_location: {
                                lat: 25.2182661,
                                lng: 51.43371639999999,
                              },
                              start_location: {
                                lat: 25.2196178,
                                lng: 51.4357808,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 2</b>",
                              duration: { text: "1 min", value: 27 },
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2135618,
                                lng: 51.4366206,
                              },
                              duration: { value: 78, text: "1 min" },
                              maneuver: "roundabout-right",
                              start_location: {
                                lng: 51.43371639999999,
                                lat: 25.2182661,
                              },
                              distance: { value: 682, text: "0.7 km" },
                              html_instructions:
                                "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                              polyline: {
                                points:
                                  "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                              },
                            },
                            {
                              distance: { text: "29 m", value: 29 },
                              start_location: {
                                lat: 25.2135618,
                                lng: 51.4366206,
                              },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 8</b>",
                              end_location: {
                                lng: 51.4364944,
                                lat: 25.2133289,
                              },
                              duration: { value: 12, text: "1 min" },
                              polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                            },
                            {
                              distance: { value: 16, text: "16 m" },
                              maneuver: "turn-left",
                              duration: { value: 8, text: "1 min" },
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2132031,
                                lng: 51.43657289999999,
                              },
                              start_location: {
                                lng: 51.4364944,
                                lat: 25.2133289,
                              },
                              polyline: { points: "in{xCae}xHXO" },
                              html_instructions: "Turn <b>left</b>",
                            },
                          ],
                          traffic_speed_entry: [],
                        },
                      ],
                      waypoint_order: [0, 1],
                      warnings: [],
                      copyrights: "Map data ©2024 Google",
                      overview_polyline: {
                        points:
                          "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                      },
                    },
                  ],
                },
                completedAt: 1707942203548,
                destination: {
                  coordinates: {
                    latitude: 25.213204,
                    longitude: 51.43657469999999,
                  },
                  geohash: "thkx6myvx1",
                  type: "corporate-branch",
                  address: "Street 8, Doha, Qatar",
                  id: "9VZwCrYZfl03ob5FrD4F.0",
                },
                status: "completed",
                skin: null,
                origin: {
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                  coordinates: { longitude: 51.489361, latitude: 25.282848 },
                },
                preferences: {
                  female_passengers_only: null,
                  verified_passengers_only: null,
                },
                car: {
                  seat: 3,
                  co2_p_km: 0.3379843740994449,
                  year: 2021,
                  make: "Toyota",
                  type: "carbon-emitting",
                  model: "LAND CRUSER WAGON 4WD",
                },
                rules: [
                  "No smoking",
                  "No eating or drinking",
                  "Keep off the AC",
                ],
                createdAt: 1707941970485,
                passengersArray: [
                  {
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    wallet_id: "spencer_carbon.testnet",
                    location: {
                      geohash: "thkxgdt7m0",
                      address: "858, الدوحة، Qatar",
                      coordinates: {
                        latitude: 25.282848,
                        longitude: 51.489361,
                      },
                    },
                    co2Amount: 4.313694566631215,
                    seat: 0,
                    name: "Jassim",
                    id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                    username: "abcd",
                    credits: 27.8,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                  },
                  {
                    co2Amount: 4.508035581738396,
                    seat: 2,
                    wallet_id: "sofia_carbon.testnet",
                    username: "michaelscott",
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                    status: "picked-up",
                    location: {
                      coordinates: {
                        longitude: 51.514615,
                        latitude: 25.285509,
                      },
                      geohash: "thkxu5pfc8",
                      address: "7GP7+6RG, Doha, Qatar",
                    },
                    credits: 13.9,
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                    name: "Ahmed",
                  },
                  {
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                    status: "picked-up",
                    seat: 1,
                    location: {
                      address: "7F9X+C9V, Doha, Qatar",
                      coordinates: {
                        latitude: 25.268246,
                        longitude: 51.498513,
                      },
                      geohash: "thkxezun4q",
                    },
                    co2Amount: 3.7908327398993733,
                    wallet_id: "sarah_carbon.testnet",
                    credits: 13,
                    username: "sarah",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                    name: "Faisal",
                  },
                ],
              },
              type: "carpool_passenger",
              corporate: "9VZwCrYZfl03ob5FrD4F",
            },
            {
              co2Amount: 4.508035581738396,
              seat: 2,
              wallet_id: "sofia_carbon.testnet",
              username: "michaelscott",
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
              status: "picked-up",
              location: {
                coordinates: { longitude: 51.514615, latitude: 25.285509 },
                geohash: "thkxu5pfc8",
                address: "7GP7+6RG, Doha, Qatar",
              },
              credits: 13.9,
              corporate: "9VZwCrYZfl03ob5FrD4F",
              id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
              name: "Ahmed",
            },
            {
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
              status: "picked-up",
              seat: 1,
              location: {
                address: "7F9X+C9V, Doha, Qatar",
                coordinates: { latitude: 25.268246, longitude: 51.498513 },
                geohash: "thkxezun4q",
              },
              co2Amount: 3.7908327398993733,
              wallet_id: "sarah_carbon.testnet",
              credits: 13,
              username: "sarah",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
              name: "Faisal",
            },
          ],
        },
        type: "carpool_passenger",
        corporate: "9VZwCrYZfl03ob5FrD4F",
      },
      "6Qj01PCY1lfLasd74HFgTxpcDRt1": {
        userId: "user_id",
        name: "Faisal",
        username: "sarah",
        location: {
          address: "7F9X+C9V, Doha, Qatar",
          coordinates: { latitude: 25.268246, longitude: 51.498513 },
          geohash: "thkxezun4q",
        },
        credits: 13,
        co2Amount: 3.7908327398993733,
        avatar:
          "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
        seat: 1,
        id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
        wallet_id: "sarah_carbon.testnet",
        status: "picked-up",
        carpool: {
          id: "8292058029514",
          owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
          co2Amount: 12.612562888268984,
          directions: {
            status: "OK",
            geocoded_waypoints: [
              {
                geocoder_status: "OK",
                types: ["establishment", "moving_company", "point_of_interest"],
                place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
              },
              {
                place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                types: [
                  "establishment",
                  "general_contractor",
                  "point_of_interest",
                ],
                geocoder_status: "OK",
              },
              {
                types: ["premise"],
                place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                geocoder_status: "OK",
              },
              {
                types: ["street_address"],
                geocoder_status: "OK",
                place_id:
                  "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
              },
            ],
            routes: [
              {
                summary: "Al Rayyan Rd",
                bounds: {
                  northeast: { lng: 51.518743, lat: 25.291958 },
                  southwest: { lat: 25.2132031, lng: 51.4319062 },
                },
                legs: [
                  {
                    end_location: { lat: 25.2856124, lng: 51.5145907 },
                    start_location: { lat: 25.2827107, lng: 51.4906083 },
                    steps: [
                      {
                        polyline: { points: "}_iyCiwgyHz@B" },
                        end_location: { lng: 51.4905936, lat: 25.2824136 },
                        start_location: { lat: 25.2827107, lng: 51.4906083 },
                        distance: { value: 33, text: "33 m" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                        duration: { value: 4, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                        travel_mode: "DRIVING",
                        distance: { value: 574, text: "0.6 km" },
                        duration: { value: 49, text: "1 min" },
                        end_location: { lng: 51.4871118, lat: 25.2801532 },
                        polyline: {
                          points:
                            "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                        },
                        start_location: { lng: 51.4905936, lat: 25.2824136 },
                      },
                      {
                        html_instructions: "Turn <b>left</b>",
                        duration: { text: "1 min", value: 44 },
                        maneuver: "turn-left",
                        polyline: {
                          points:
                            "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                        },
                        end_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                        distance: { value: 426, text: "0.4 km" },
                        start_location: { lat: 25.2801532, lng: 51.4871118 },
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 8, text: "1 min" },
                        polyline: { points: "cphyCoxgyHEaBAsA" },
                        html_instructions:
                          "Continue straight onto <b>Al Sadd St</b>",
                        end_location: { lat: 25.2802165, lng: 51.4917143 },
                        maneuver: "straight",
                        distance: { value: 93, text: "93 m" },
                        start_location: {
                          lat: 25.2801774,
                          lng: 51.49079529999999,
                        },
                      },
                      {
                        distance: { value: 236, text: "0.2 km" },
                        html_instructions: "Slight <b>left</b>",
                        polyline: { points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK" },
                        start_location: { lat: 25.2802165, lng: 51.4917143 },
                        travel_mode: "DRIVING",
                        end_location: { lat: 25.2804496, lng: 51.4940386 },
                        duration: { text: "1 min", value: 71 },
                        maneuver: "turn-slight-left",
                      },
                      {
                        duration: { text: "1 min", value: 38 },
                        end_location: { lat: 25.281604, lng: 51.4947254 },
                        polyline: {
                          points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                        },
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        start_location: { lng: 51.4940386, lat: 25.2804496 },
                        distance: { value: 163, text: "0.2 km" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                      },
                      {
                        duration: { text: "1 min", value: 76 },
                        polyline: {
                          points:
                            "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                        },
                        start_location: { lat: 25.281604, lng: 51.4947254 },
                        html_instructions:
                          "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                        maneuver: "keep-left",
                        end_location: { lng: 51.4965617, lat: 25.2914195 },
                        travel_mode: "DRIVING",
                        distance: { text: "1.1 km", value: 1109 },
                      },
                      {
                        start_location: { lat: 25.2914195, lng: 51.4965617 },
                        maneuver: "turn-right",
                        duration: { value: 209, text: "3 mins" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                        polyline: {
                          points:
                            "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                        },
                        end_location: {
                          lat: 25.2893902,
                          lng: 51.51516729999999,
                        },
                        distance: { value: 1922, text: "1.9 km" },
                        travel_mode: "DRIVING",
                      },
                      {
                        distance: { value: 144, text: "0.1 km" },
                        start_location: {
                          lng: 51.51516729999999,
                          lat: 25.2893902,
                        },
                        end_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej</b>",
                        duration: { value: 15, text: "1 min" },
                        travel_mode: "DRIVING",
                      },
                      {
                        polyline: {
                          points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                        },
                        duration: { text: "1 min", value: 11 },
                        end_location: { lng: 51.516228, lat: 25.2874383 },
                        start_location: {
                          lat: 25.2884741,
                          lng: 51.51591370000001,
                        },
                        travel_mode: "DRIVING",
                        distance: { text: "0.1 km", value: 122 },
                        html_instructions:
                          'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-slight-right",
                        duration: { text: "1 min", value: 21 },
                        polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                        start_location: { lng: 51.516228, lat: 25.2874383 },
                        end_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        distance: { text: "0.3 km", value: 283 },
                        html_instructions:
                          'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        start_location: {
                          lng: 51.51678399999999,
                          lat: 25.2849434,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                        duration: { text: "1 min", value: 32 },
                        polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                        distance: { text: "0.1 km", value: 125 },
                        end_location: { lng: 51.5155496, lat: 25.2848165 },
                      },
                      {
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        duration: { text: "1 min", value: 21 },
                        maneuver: "turn-right",
                        distance: { value: 74, text: "74 m" },
                        polyline: {
                          points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                        },
                        end_location: { lat: 25.2850716, lng: 51.5150352 },
                        start_location: { lat: 25.2848165, lng: 51.5155496 },
                      },
                      {
                        polyline: { points: "uniyC_plyHqBJ" },
                        duration: { text: "1 min", value: 23 },
                        end_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                        start_location: { lat: 25.2850716, lng: 51.5150352 },
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        distance: { value: 64, text: "64 m" },
                      },
                      {
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 14 },
                        start_location: {
                          lat: 25.2856417,
                          lng: 51.51497639999999,
                        },
                        end_location: { lng: 51.5145907, lat: 25.2856124 },
                        distance: { text: "39 m", value: 39 },
                        polyline: { points: "griyCsolyH?p@DZ" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        maneuver: "turn-left",
                      },
                    ],
                    start_address: "858, الدوحة، Qatar",
                    end_address: "7GP7+6RG, Doha, Qatar",
                    traffic_speed_entry: [],
                    duration: { value: 636, text: "11 mins" },
                    distance: { text: "5.4 km", value: 5407 },
                    via_waypoint: [],
                  },
                  {
                    start_address: "7GP7+6RG, Doha, Qatar",
                    duration: { value: 598, text: "10 mins" },
                    traffic_speed_entry: [],
                    start_location: { lat: 25.2856124, lng: 51.5145907 },
                    steps: [
                      {
                        duration: { value: 73, text: "1 min" },
                        end_location: { lat: 25.2859334, lng: 51.5165533 },
                        start_location: { lat: 25.2856124, lng: 51.5145907 },
                        distance: { text: "0.2 km", value: 201 },
                        html_instructions:
                          "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                        },
                      },
                      {
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                        distance: { text: "0.7 km", value: 735 },
                        end_location: { lng: 51.518743, lat: 25.2796568 },
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                        },
                        duration: { text: "1 min", value: 78 },
                        start_location: { lng: 51.5165533, lat: 25.2859334 },
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { text: "0.4 km", value: 393 },
                        end_location: { lat: 25.2769643, lng: 51.5164061 },
                        maneuver: "turn-slight-right",
                        start_location: { lat: 25.2796568, lng: 51.518743 },
                        polyline: {
                          points:
                            "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                        },
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                        duration: { text: "1 min", value: 59 },
                      },
                      {
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.5164061, lat: 25.2769643 },
                        polyline: {
                          points:
                            "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                        },
                        end_location: { lng: 51.500835, lat: 25.2663847 },
                        duration: { value: 229, text: "4 mins" },
                        distance: { value: 1963, text: "2.0 km" },
                        maneuver: "merge",
                        html_instructions:
                          "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                      },
                      {
                        maneuver: "turn-slight-right",
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                        end_location: { lng: 51.497229, lat: 25.2643805 },
                        distance: { text: "0.4 km", value: 428 },
                        travel_mode: "DRIVING",
                        start_location: { lat: 25.2663847, lng: 51.500835 },
                        polyline: {
                          points:
                            "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                        },
                        duration: { value: 40, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                        maneuver: "roundabout-right",
                        polyline: { points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB" },
                        duration: { value: 22, text: "1 min" },
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.497229, lat: 25.2643805 },
                        distance: { text: "0.2 km", value: 182 },
                        end_location: { lng: 51.4962302, lat: 25.2656775 },
                      },
                      {
                        polyline: { points: "oueyCmzhyH]DKB" },
                        start_location: { lat: 25.2656775, lng: 51.4962302 },
                        end_location: { lat: 25.2658942, lng: 51.4961765 },
                        travel_mode: "DRIVING",
                        distance: { value: 25, text: "25 m" },
                        maneuver: "turn-slight-right",
                        html_instructions:
                          "Slight <b>right</b> onto <b>Al Nasr St</b>",
                        duration: { value: 3, text: "1 min" },
                      },
                      {
                        html_instructions:
                          "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                        maneuver: "turn-slight-right",
                        end_location: { lng: 51.4995317, lat: 25.267547 },
                        travel_mode: "DRIVING",
                        polyline: {
                          points:
                            "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                        },
                        distance: { value: 389, text: "0.4 km" },
                        duration: { value: 53, text: "1 min" },
                        start_location: { lat: 25.2658942, lng: 51.4961765 },
                      },
                      {
                        distance: { value: 111, text: "0.1 km" },
                        start_location: { lat: 25.267547, lng: 51.4995317 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohammed St</b>",
                        duration: { value: 22, text: "1 min" },
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        travel_mode: "DRIVING",
                        polyline: { points: "eafyCaoiyHQFiDdA" },
                        maneuver: "turn-left",
                      },
                      {
                        travel_mode: "DRIVING",
                        maneuver: "turn-left",
                        end_location: {
                          lat: 25.2683307,
                          lng: 51.49855549999999,
                        },
                        distance: { text: "62 m", value: 62 },
                        duration: { text: "1 min", value: 19 },
                        polyline: { points: "agfyCsliyH\\vABNAJ" },
                        html_instructions:
                          'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                        start_location: { lng: 51.499144, lat: 25.2684856 },
                      },
                    ],
                    via_waypoint: [],
                    end_location: { lat: 25.2683307, lng: 51.49855549999999 },
                    distance: { text: "4.5 km", value: 4489 },
                    end_address: "7F9X+C9V, Doha, Qatar",
                  },
                  {
                    end_address: "28 Street 8, Ar Rayyan, Qatar",
                    start_address: "7F9X+C9V, Doha, Qatar",
                    via_waypoint: [],
                    duration: { text: "13 mins", value: 795 },
                    start_location: { lng: 51.49855549999999, lat: 25.2683307 },
                    distance: { value: 11216, text: "11.2 km" },
                    end_location: { lat: 25.2132031, lng: 51.43657289999999 },
                    steps: [
                      {
                        end_location: { lng: 51.499144, lat: 25.2684856 },
                        start_location: {
                          lat: 25.2683307,
                          lng: 51.49855549999999,
                        },
                        distance: { value: 62, text: "62 m" },
                        duration: { text: "1 min", value: 14 },
                        polyline: { points: "affyC_iiyH@KCO]wA" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                      },
                      {
                        distance: { value: 28, text: "28 m" },
                        duration: { value: 9, text: "1 min" },
                        maneuver: "turn-left",
                        start_location: { lng: 51.499144, lat: 25.2684856 },
                        end_location: { lng: 51.499066, lat: 25.2687298 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                        travel_mode: "DRIVING",
                        polyline: { points: "agfyCsliyHo@L" },
                      },
                      {
                        html_instructions:
                          "Turn <b>right</b> onto <b>Al Baraha</b>",
                        polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        distance: { text: "0.2 km", value: 154 },
                        end_location: { lat: 25.2692232, lng: 51.500496 },
                        duration: { value: 32, text: "1 min" },
                        start_location: { lng: 51.499066, lat: 25.2687298 },
                      },
                      {
                        end_location: { lng: 51.500265, lat: 25.269806 },
                        polyline: { points: "skfyCcuiyH{@Ty@X" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                        duration: { value: 13, text: "1 min" },
                        distance: { text: "69 m", value: 69 },
                        maneuver: "turn-left",
                        start_location: { lng: 51.500496, lat: 25.2692232 },
                      },
                      {
                        polyline: {
                          points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                        },
                        start_location: { lat: 25.269806, lng: 51.500265 },
                        distance: { text: "0.2 km", value: 223 },
                        duration: { value: 29, text: "1 min" },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        end_location: {
                          lat: 25.2705523,
                          lng: 51.50232339999999,
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                      },
                      {
                        polyline: {
                          points:
                            "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                        },
                        duration: { value: 48, text: "1 min" },
                        end_location: { lng: 51.5033973, lat: 25.2679045 },
                        travel_mode: "DRIVING",
                        maneuver: "roundabout-right",
                        distance: { text: "0.3 km", value: 326 },
                        html_instructions:
                          "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                        start_location: {
                          lng: 51.50232339999999,
                          lat: 25.2705523,
                        },
                      },
                      {
                        end_location: { lng: 51.45117, lat: 25.2368244 },
                        polyline: {
                          points:
                            "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                        },
                        duration: { value: 283, text: "5 mins" },
                        html_instructions: "Merge onto <b>Salwa Rd</b>",
                        start_location: { lng: 51.5033973, lat: 25.2679045 },
                        travel_mode: "DRIVING",
                        distance: { text: "6.3 km", value: 6288 },
                        maneuver: "merge",
                      },
                      {
                        start_location: { lng: 51.45117, lat: 25.2368244 },
                        maneuver: "ramp-right",
                        duration: { text: "1 min", value: 10 },
                        travel_mode: "DRIVING",
                        distance: { text: "0.2 km", value: 194 },
                        html_instructions:
                          'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                        polyline: { points: "ca`yCy``yHpAnDlAdD" },
                        end_location: { lng: 51.4494587, lat: 25.2360177 },
                      },
                      {
                        travel_mode: "DRIVING",
                        distance: { value: 397, text: "0.4 km" },
                        end_location: { lat: 25.2340694, lng: 51.4461502 },
                        duration: { text: "1 min", value: 33 },
                        html_instructions:
                          "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        maneuver: "merge",
                        start_location: { lat: 25.2360177, lng: 51.4494587 },
                        polyline: { points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV" },
                      },
                      {
                        duration: { value: 8, text: "1 min" },
                        maneuver: "straight",
                        start_location: { lng: 51.4461502, lat: 25.2340694 },
                        html_instructions:
                          "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                        end_location: { lat: 25.2337942, lng: 51.4456607 },
                        polyline: { points: "}o_yCma_yHP`@d@~@" },
                        distance: { text: "58 m", value: 58 },
                        travel_mode: "DRIVING",
                      },
                      {
                        maneuver: "straight",
                        travel_mode: "DRIVING",
                        start_location: { lng: 51.4456607, lat: 25.2337942 },
                        duration: { text: "2 mins", value: 90 },
                        end_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        html_instructions:
                          'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                        polyline: {
                          points:
                            "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                        },
                        distance: { value: 1451, text: "1.5 km" },
                      },
                      {
                        duration: { text: "1 min", value: 10 },
                        html_instructions:
                          "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                        travel_mode: "DRIVING",
                        distance: { value: 92, text: "92 m" },
                        end_location: { lng: 51.4328437, lat: 25.2262036 },
                        start_location: {
                          lat: 25.2267513,
                          lng: 51.43351759999999,
                        },
                        maneuver: "turn-slight-left",
                        polyline: { points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH" },
                      },
                      {
                        end_location: { lat: 25.2257335, lng: 51.4323655 },
                        html_instructions: "Continue straight",
                        maneuver: "straight",
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 19 },
                        polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                        distance: { value: 71, text: "71 m" },
                        start_location: { lat: 25.2262036, lng: 51.4328437 },
                      },
                      {
                        polyline: {
                          points:
                            "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                        },
                        travel_mode: "DRIVING",
                        duration: { value: 72, text: "1 min" },
                        distance: { text: "0.8 km", value: 813 },
                        start_location: { lng: 51.4323655, lat: 25.2257335 },
                        html_instructions:
                          "Continue onto <b>E Industrial Rd</b>",
                        end_location: { lng: 51.4357808, lat: 25.2196178 },
                      },
                      {
                        distance: { value: 263, text: "0.3 km" },
                        maneuver: "turn-right",
                        polyline: {
                          points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                        },
                        end_location: {
                          lat: 25.2182661,
                          lng: 51.43371639999999,
                        },
                        start_location: { lat: 25.2196178, lng: 51.4357808 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 2</b>",
                        duration: { text: "1 min", value: 27 },
                        travel_mode: "DRIVING",
                      },
                      {
                        travel_mode: "DRIVING",
                        end_location: { lat: 25.2135618, lng: 51.4366206 },
                        duration: { value: 78, text: "1 min" },
                        maneuver: "roundabout-right",
                        start_location: {
                          lng: 51.43371639999999,
                          lat: 25.2182661,
                        },
                        distance: { value: 682, text: "0.7 km" },
                        html_instructions:
                          "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                        polyline: {
                          points:
                            "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                        },
                      },
                      {
                        distance: { text: "29 m", value: 29 },
                        start_location: { lat: 25.2135618, lng: 51.4366206 },
                        maneuver: "turn-right",
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>right</b> onto <b>Street 8</b>",
                        end_location: { lng: 51.4364944, lat: 25.2133289 },
                        duration: { value: 12, text: "1 min" },
                        polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                      },
                      {
                        distance: { value: 16, text: "16 m" },
                        maneuver: "turn-left",
                        duration: { value: 8, text: "1 min" },
                        travel_mode: "DRIVING",
                        end_location: {
                          lat: 25.2132031,
                          lng: 51.43657289999999,
                        },
                        start_location: { lng: 51.4364944, lat: 25.2133289 },
                        polyline: { points: "in{xCae}xHXO" },
                        html_instructions: "Turn <b>left</b>",
                      },
                    ],
                    traffic_speed_entry: [],
                  },
                ],
                waypoint_order: [0, 1],
                warnings: [],
                copyrights: "Map data ©2024 Google",
                overview_polyline: {
                  points:
                    "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                },
              },
            ],
          },
          completedAt: 1707942203548,
          destination: {
            coordinates: { latitude: 25.213204, longitude: 51.43657469999999 },
            geohash: "thkx6myvx1",
            type: "corporate-branch",
            address: "Street 8, Doha, Qatar",
            id: "9VZwCrYZfl03ob5FrD4F.0",
          },
          status: "completed",
          skin: null,
          origin: {
            geohash: "thkxgdt7m0",
            address: "858, الدوحة، Qatar",
            coordinates: { longitude: 51.489361, latitude: 25.282848 },
          },
          preferences: {
            female_passengers_only: null,
            verified_passengers_only: null,
          },
          car: {
            seat: 3,
            co2_p_km: 0.3379843740994449,
            year: 2021,
            make: "Toyota",
            type: "carbon-emitting",
            model: "LAND CRUSER WAGON 4WD",
          },
          rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
          createdAt: 1707941970485,
          passengersArray: [
            {
              userId: "user_id",
              name: "Jassim",
              username: "abcd",
              location: {
                geohash: "thkxgdt7m0",
                address: "858, الدوحة، Qatar",
                coordinates: { latitude: 25.282848, longitude: 51.489361 },
              },
              credits: 27.8,
              co2Amount: 4.313694566631215,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
              seat: 0,
              id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
              wallet_id: "spencer_carbon.testnet",
              carpool: {
                id: "8292058029514",
                owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                co2Amount: 12.612562888268984,
                directions: {
                  status: "OK",
                  geocoded_waypoints: [
                    {
                      geocoder_status: "OK",
                      types: [
                        "establishment",
                        "moving_company",
                        "point_of_interest",
                      ],
                      place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                    },
                    {
                      place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                      types: [
                        "establishment",
                        "general_contractor",
                        "point_of_interest",
                      ],
                      geocoder_status: "OK",
                    },
                    {
                      types: ["premise"],
                      place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                      geocoder_status: "OK",
                    },
                    {
                      types: ["street_address"],
                      geocoder_status: "OK",
                      place_id:
                        "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                    },
                  ],
                  routes: [
                    {
                      summary: "Al Rayyan Rd",
                      bounds: {
                        northeast: { lng: 51.518743, lat: 25.291958 },
                        southwest: { lat: 25.2132031, lng: 51.4319062 },
                      },
                      legs: [
                        {
                          end_location: { lat: 25.2856124, lng: 51.5145907 },
                          start_location: { lat: 25.2827107, lng: 51.4906083 },
                          steps: [
                            {
                              polyline: { points: "}_iyCiwgyHz@B" },
                              end_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              start_location: {
                                lat: 25.2827107,
                                lng: 51.4906083,
                              },
                              distance: { value: 33, text: "33 m" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                              duration: { value: 4, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                              travel_mode: "DRIVING",
                              distance: { value: 574, text: "0.6 km" },
                              duration: { value: 49, text: "1 min" },
                              end_location: {
                                lng: 51.4871118,
                                lat: 25.2801532,
                              },
                              polyline: {
                                points:
                                  "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                              },
                              start_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                            },
                            {
                              html_instructions: "Turn <b>left</b>",
                              duration: { text: "1 min", value: 44 },
                              maneuver: "turn-left",
                              polyline: {
                                points:
                                  "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                              },
                              end_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                              distance: { value: 426, text: "0.4 km" },
                              start_location: {
                                lat: 25.2801532,
                                lng: 51.4871118,
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              polyline: { points: "cphyCoxgyHEaBAsA" },
                              html_instructions:
                                "Continue straight onto <b>Al Sadd St</b>",
                              end_location: {
                                lat: 25.2802165,
                                lng: 51.4917143,
                              },
                              maneuver: "straight",
                              distance: { value: 93, text: "93 m" },
                              start_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                            },
                            {
                              distance: { value: 236, text: "0.2 km" },
                              html_instructions: "Slight <b>left</b>",
                              polyline: {
                                points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                              },
                              start_location: {
                                lat: 25.2802165,
                                lng: 51.4917143,
                              },
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                              duration: { text: "1 min", value: 71 },
                              maneuver: "turn-slight-left",
                            },
                            {
                              duration: { text: "1 min", value: 38 },
                              end_location: { lat: 25.281604, lng: 51.4947254 },
                              polyline: {
                                points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                              },
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.4940386,
                                lat: 25.2804496,
                              },
                              distance: { value: 163, text: "0.2 km" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                            },
                            {
                              duration: { text: "1 min", value: 76 },
                              polyline: {
                                points:
                                  "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                              },
                              start_location: {
                                lat: 25.281604,
                                lng: 51.4947254,
                              },
                              html_instructions:
                                "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                              maneuver: "keep-left",
                              end_location: {
                                lng: 51.4965617,
                                lat: 25.2914195,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "1.1 km", value: 1109 },
                            },
                            {
                              start_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                              maneuver: "turn-right",
                              duration: { value: 209, text: "3 mins" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                              polyline: {
                                points:
                                  "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                              },
                              end_location: {
                                lat: 25.2893902,
                                lng: 51.51516729999999,
                              },
                              distance: { value: 1922, text: "1.9 km" },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { value: 144, text: "0.1 km" },
                              start_location: {
                                lng: 51.51516729999999,
                                lat: 25.2893902,
                              },
                              end_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej</b>",
                              duration: { value: 15, text: "1 min" },
                              travel_mode: "DRIVING",
                            },
                            {
                              polyline: {
                                points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                              },
                              duration: { text: "1 min", value: 11 },
                              end_location: { lng: 51.516228, lat: 25.2874383 },
                              start_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "0.1 km", value: 122 },
                              html_instructions:
                                'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-slight-right",
                              duration: { text: "1 min", value: 21 },
                              polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                              start_location: {
                                lng: 51.516228,
                                lat: 25.2874383,
                              },
                              end_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              distance: { text: "0.3 km", value: 283 },
                              html_instructions:
                                'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-right",
                              start_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                              duration: { text: "1 min", value: 32 },
                              polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                              distance: { text: "0.1 km", value: 125 },
                              end_location: {
                                lng: 51.5155496,
                                lat: 25.2848165,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              duration: { text: "1 min", value: 21 },
                              maneuver: "turn-right",
                              distance: { value: 74, text: "74 m" },
                              polyline: {
                                points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                              },
                              end_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              start_location: {
                                lat: 25.2848165,
                                lng: 51.5155496,
                              },
                            },
                            {
                              polyline: { points: "uniyC_plyHqBJ" },
                              duration: { text: "1 min", value: 23 },
                              end_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              start_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "turn-right",
                              distance: { value: 64, text: "64 m" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 14 },
                              start_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              end_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              distance: { text: "39 m", value: 39 },
                              polyline: { points: "griyCsolyH?p@DZ" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              maneuver: "turn-left",
                            },
                          ],
                          start_address: "858, الدوحة، Qatar",
                          end_address: "7GP7+6RG, Doha, Qatar",
                          traffic_speed_entry: [],
                          duration: { value: 636, text: "11 mins" },
                          distance: { text: "5.4 km", value: 5407 },
                          via_waypoint: [],
                        },
                        {
                          start_address: "7GP7+6RG, Doha, Qatar",
                          duration: { value: 598, text: "10 mins" },
                          traffic_speed_entry: [],
                          start_location: { lat: 25.2856124, lng: 51.5145907 },
                          steps: [
                            {
                              duration: { value: 73, text: "1 min" },
                              end_location: {
                                lat: 25.2859334,
                                lng: 51.5165533,
                              },
                              start_location: {
                                lat: 25.2856124,
                                lng: 51.5145907,
                              },
                              distance: { text: "0.2 km", value: 201 },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                              distance: { text: "0.7 km", value: 735 },
                              end_location: { lng: 51.518743, lat: 25.2796568 },
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                              },
                              duration: { text: "1 min", value: 78 },
                              start_location: {
                                lng: 51.5165533,
                                lat: 25.2859334,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { text: "0.4 km", value: 393 },
                              end_location: {
                                lat: 25.2769643,
                                lng: 51.5164061,
                              },
                              maneuver: "turn-slight-right",
                              start_location: {
                                lat: 25.2796568,
                                lng: 51.518743,
                              },
                              polyline: {
                                points:
                                  "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                              },
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                              duration: { text: "1 min", value: 59 },
                            },
                            {
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.5164061,
                                lat: 25.2769643,
                              },
                              polyline: {
                                points:
                                  "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                              },
                              end_location: { lng: 51.500835, lat: 25.2663847 },
                              duration: { value: 229, text: "4 mins" },
                              distance: { value: 1963, text: "2.0 km" },
                              maneuver: "merge",
                              html_instructions:
                                "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                            },
                            {
                              maneuver: "turn-slight-right",
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                              end_location: { lng: 51.497229, lat: 25.2643805 },
                              distance: { text: "0.4 km", value: 428 },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2663847,
                                lng: 51.500835,
                              },
                              polyline: {
                                points:
                                  "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                              },
                              duration: { value: 40, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                              maneuver: "roundabout-right",
                              polyline: {
                                points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                              },
                              duration: { value: 22, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.497229,
                                lat: 25.2643805,
                              },
                              distance: { text: "0.2 km", value: 182 },
                              end_location: {
                                lng: 51.4962302,
                                lat: 25.2656775,
                              },
                            },
                            {
                              polyline: { points: "oueyCmzhyH]DKB" },
                              start_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              end_location: {
                                lat: 25.2658942,
                                lng: 51.4961765,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 25, text: "25 m" },
                              maneuver: "turn-slight-right",
                              html_instructions:
                                "Slight <b>right</b> onto <b>Al Nasr St</b>",
                              duration: { value: 3, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                              maneuver: "turn-slight-right",
                              end_location: { lng: 51.4995317, lat: 25.267547 },
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                              },
                              distance: { value: 389, text: "0.4 km" },
                              duration: { value: 53, text: "1 min" },
                              start_location: {
                                lat: 25.2658942,
                                lng: 51.4961765,
                              },
                            },
                            {
                              distance: { value: 111, text: "0.1 km" },
                              start_location: {
                                lat: 25.267547,
                                lng: 51.4995317,
                              },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohammed St</b>",
                              duration: { value: 22, text: "1 min" },
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              travel_mode: "DRIVING",
                              polyline: { points: "eafyCaoiyHQFiDdA" },
                              maneuver: "turn-left",
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              end_location: {
                                lat: 25.2683307,
                                lng: 51.49855549999999,
                              },
                              distance: { text: "62 m", value: 62 },
                              duration: { text: "1 min", value: 19 },
                              polyline: { points: "agfyCsliyH\\vABNAJ" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              start_location: {
                                lng: 51.499144,
                                lat: 25.2684856,
                              },
                            },
                          ],
                          via_waypoint: [],
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { text: "4.5 km", value: 4489 },
                          end_address: "7F9X+C9V, Doha, Qatar",
                        },
                        {
                          end_address: "28 Street 8, Ar Rayyan, Qatar",
                          start_address: "7F9X+C9V, Doha, Qatar",
                          via_waypoint: [],
                          duration: { text: "13 mins", value: 795 },
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          distance: { value: 11216, text: "11.2 km" },
                          end_location: {
                            lat: 25.2132031,
                            lng: 51.43657289999999,
                          },
                          steps: [
                            {
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              start_location: {
                                lat: 25.2683307,
                                lng: 51.49855549999999,
                              },
                              distance: { value: 62, text: "62 m" },
                              duration: { text: "1 min", value: 14 },
                              polyline: { points: "affyC_iiyH@KCO]wA" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                            },
                            {
                              distance: { value: 28, text: "28 m" },
                              duration: { value: 9, text: "1 min" },
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.499144,
                                lat: 25.2684856,
                              },
                              end_location: { lng: 51.499066, lat: 25.2687298 },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                              travel_mode: "DRIVING",
                              polyline: { points: "agfyCsliyHo@L" },
                            },
                            {
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Baraha</b>",
                              polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              distance: { text: "0.2 km", value: 154 },
                              end_location: { lat: 25.2692232, lng: 51.500496 },
                              duration: { value: 32, text: "1 min" },
                              start_location: {
                                lng: 51.499066,
                                lat: 25.2687298,
                              },
                            },
                            {
                              end_location: { lng: 51.500265, lat: 25.269806 },
                              polyline: { points: "skfyCcuiyH{@Ty@X" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                              duration: { value: 13, text: "1 min" },
                              distance: { text: "69 m", value: 69 },
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.500496,
                                lat: 25.2692232,
                              },
                            },
                            {
                              polyline: {
                                points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                              },
                              start_location: {
                                lat: 25.269806,
                                lng: 51.500265,
                              },
                              distance: { text: "0.2 km", value: 223 },
                              duration: { value: 29, text: "1 min" },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                            },
                            {
                              polyline: {
                                points:
                                  "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                              },
                              duration: { value: 48, text: "1 min" },
                              end_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "roundabout-right",
                              distance: { text: "0.3 km", value: 326 },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                              start_location: {
                                lng: 51.50232339999999,
                                lat: 25.2705523,
                              },
                            },
                            {
                              end_location: { lng: 51.45117, lat: 25.2368244 },
                              polyline: {
                                points:
                                  "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                              },
                              duration: { value: 283, text: "5 mins" },
                              html_instructions: "Merge onto <b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "6.3 km", value: 6288 },
                              maneuver: "merge",
                            },
                            {
                              start_location: {
                                lng: 51.45117,
                                lat: 25.2368244,
                              },
                              maneuver: "ramp-right",
                              duration: { text: "1 min", value: 10 },
                              travel_mode: "DRIVING",
                              distance: { text: "0.2 km", value: 194 },
                              html_instructions:
                                'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                              polyline: { points: "ca`yCy``yHpAnDlAdD" },
                              end_location: {
                                lng: 51.4494587,
                                lat: 25.2360177,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { value: 397, text: "0.4 km" },
                              end_location: {
                                lat: 25.2340694,
                                lng: 51.4461502,
                              },
                              duration: { text: "1 min", value: 33 },
                              html_instructions:
                                "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              maneuver: "merge",
                              start_location: {
                                lat: 25.2360177,
                                lng: 51.4494587,
                              },
                              polyline: {
                                points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                              },
                            },
                            {
                              duration: { value: 8, text: "1 min" },
                              maneuver: "straight",
                              start_location: {
                                lng: 51.4461502,
                                lat: 25.2340694,
                              },
                              html_instructions:
                                "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              end_location: {
                                lat: 25.2337942,
                                lng: 51.4456607,
                              },
                              polyline: { points: "}o_yCma_yHP`@d@~@" },
                              distance: { text: "58 m", value: 58 },
                              travel_mode: "DRIVING",
                            },
                            {
                              maneuver: "straight",
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.4456607,
                                lat: 25.2337942,
                              },
                              duration: { text: "2 mins", value: 90 },
                              end_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              html_instructions:
                                'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                              polyline: {
                                points:
                                  "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                              },
                              distance: { value: 1451, text: "1.5 km" },
                            },
                            {
                              duration: { text: "1 min", value: 10 },
                              html_instructions:
                                "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                              travel_mode: "DRIVING",
                              distance: { value: 92, text: "92 m" },
                              end_location: {
                                lng: 51.4328437,
                                lat: 25.2262036,
                              },
                              start_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              maneuver: "turn-slight-left",
                              polyline: {
                                points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                              },
                            },
                            {
                              end_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              html_instructions: "Continue straight",
                              maneuver: "straight",
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 19 },
                              polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                              distance: { value: 71, text: "71 m" },
                              start_location: {
                                lat: 25.2262036,
                                lng: 51.4328437,
                              },
                            },
                            {
                              polyline: {
                                points:
                                  "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                              },
                              travel_mode: "DRIVING",
                              duration: { value: 72, text: "1 min" },
                              distance: { text: "0.8 km", value: 813 },
                              start_location: {
                                lng: 51.4323655,
                                lat: 25.2257335,
                              },
                              html_instructions:
                                "Continue onto <b>E Industrial Rd</b>",
                              end_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                            },
                            {
                              distance: { value: 263, text: "0.3 km" },
                              maneuver: "turn-right",
                              polyline: {
                                points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                              },
                              end_location: {
                                lat: 25.2182661,
                                lng: 51.43371639999999,
                              },
                              start_location: {
                                lat: 25.2196178,
                                lng: 51.4357808,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 2</b>",
                              duration: { text: "1 min", value: 27 },
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2135618,
                                lng: 51.4366206,
                              },
                              duration: { value: 78, text: "1 min" },
                              maneuver: "roundabout-right",
                              start_location: {
                                lng: 51.43371639999999,
                                lat: 25.2182661,
                              },
                              distance: { value: 682, text: "0.7 km" },
                              html_instructions:
                                "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                              polyline: {
                                points:
                                  "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                              },
                            },
                            {
                              distance: { text: "29 m", value: 29 },
                              start_location: {
                                lat: 25.2135618,
                                lng: 51.4366206,
                              },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 8</b>",
                              end_location: {
                                lng: 51.4364944,
                                lat: 25.2133289,
                              },
                              duration: { value: 12, text: "1 min" },
                              polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                            },
                            {
                              distance: { value: 16, text: "16 m" },
                              maneuver: "turn-left",
                              duration: { value: 8, text: "1 min" },
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2132031,
                                lng: 51.43657289999999,
                              },
                              start_location: {
                                lng: 51.4364944,
                                lat: 25.2133289,
                              },
                              polyline: { points: "in{xCae}xHXO" },
                              html_instructions: "Turn <b>left</b>",
                            },
                          ],
                          traffic_speed_entry: [],
                        },
                      ],
                      waypoint_order: [0, 1],
                      warnings: [],
                      copyrights: "Map data ©2024 Google",
                      overview_polyline: {
                        points:
                          "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                      },
                    },
                  ],
                },
                completedAt: 1707942203548,
                destination: {
                  coordinates: {
                    latitude: 25.213204,
                    longitude: 51.43657469999999,
                  },
                  geohash: "thkx6myvx1",
                  type: "corporate-branch",
                  address: "Street 8, Doha, Qatar",
                  id: "9VZwCrYZfl03ob5FrD4F.0",
                },
                status: "completed",
                skin: null,
                origin: {
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                  coordinates: { longitude: 51.489361, latitude: 25.282848 },
                },
                preferences: {
                  female_passengers_only: null,
                  verified_passengers_only: null,
                },
                car: {
                  seat: 3,
                  co2_p_km: 0.3379843740994449,
                  year: 2021,
                  make: "Toyota",
                  type: "carbon-emitting",
                  model: "LAND CRUSER WAGON 4WD",
                },
                rules: [
                  "No smoking",
                  "No eating or drinking",
                  "Keep off the AC",
                ],
                createdAt: 1707941970485,
                passengersArray: [
                  {
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    wallet_id: "spencer_carbon.testnet",
                    location: {
                      geohash: "thkxgdt7m0",
                      address: "858, الدوحة، Qatar",
                      coordinates: {
                        latitude: 25.282848,
                        longitude: 51.489361,
                      },
                    },
                    co2Amount: 4.313694566631215,
                    seat: 0,
                    name: "Jassim",
                    id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                    username: "abcd",
                    credits: 27.8,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                  },
                  {
                    co2Amount: 4.508035581738396,
                    seat: 2,
                    wallet_id: "sofia_carbon.testnet",
                    username: "michaelscott",
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                    status: "picked-up",
                    location: {
                      coordinates: {
                        longitude: 51.514615,
                        latitude: 25.285509,
                      },
                      geohash: "thkxu5pfc8",
                      address: "7GP7+6RG, Doha, Qatar",
                    },
                    credits: 13.9,
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                    name: "Ahmed",
                  },
                  {
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                    status: "picked-up",
                    seat: 1,
                    location: {
                      address: "7F9X+C9V, Doha, Qatar",
                      coordinates: {
                        latitude: 25.268246,
                        longitude: 51.498513,
                      },
                      geohash: "thkxezun4q",
                    },
                    co2Amount: 3.7908327398993733,
                    wallet_id: "sarah_carbon.testnet",
                    credits: 13,
                    username: "sarah",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                    name: "Faisal",
                  },
                ],
              },
              type: "carpool_passenger",
              corporate: "9VZwCrYZfl03ob5FrD4F",
            },
            {
              userId: "user_id",
              name: "Ahmed",
              username: "michaelscott",
              location: {
                coordinates: { longitude: 51.514615, latitude: 25.285509 },
                geohash: "thkxu5pfc8",
                address: "7GP7+6RG, Doha, Qatar",
              },
              credits: 13.9,
              co2Amount: 4.508035581738396,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
              seat: 2,
              id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
              wallet_id: "sofia_carbon.testnet",
              status: "picked-up",
              carpool: {
                id: "8292058029514",
                owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                co2Amount: 12.612562888268984,
                directions: {
                  status: "OK",
                  geocoded_waypoints: [
                    {
                      geocoder_status: "OK",
                      types: [
                        "establishment",
                        "moving_company",
                        "point_of_interest",
                      ],
                      place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                    },
                    {
                      place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                      types: [
                        "establishment",
                        "general_contractor",
                        "point_of_interest",
                      ],
                      geocoder_status: "OK",
                    },
                    {
                      types: ["premise"],
                      place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                      geocoder_status: "OK",
                    },
                    {
                      types: ["street_address"],
                      geocoder_status: "OK",
                      place_id:
                        "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                    },
                  ],
                  routes: [
                    {
                      summary: "Al Rayyan Rd",
                      bounds: {
                        northeast: { lng: 51.518743, lat: 25.291958 },
                        southwest: { lat: 25.2132031, lng: 51.4319062 },
                      },
                      legs: [
                        {
                          end_location: { lat: 25.2856124, lng: 51.5145907 },
                          start_location: { lat: 25.2827107, lng: 51.4906083 },
                          steps: [
                            {
                              polyline: { points: "}_iyCiwgyHz@B" },
                              end_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                              start_location: {
                                lat: 25.2827107,
                                lng: 51.4906083,
                              },
                              distance: { value: 33, text: "33 m" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                              duration: { value: 4, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                              travel_mode: "DRIVING",
                              distance: { value: 574, text: "0.6 km" },
                              duration: { value: 49, text: "1 min" },
                              end_location: {
                                lng: 51.4871118,
                                lat: 25.2801532,
                              },
                              polyline: {
                                points:
                                  "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                              },
                              start_location: {
                                lng: 51.4905936,
                                lat: 25.2824136,
                              },
                            },
                            {
                              html_instructions: "Turn <b>left</b>",
                              duration: { text: "1 min", value: 44 },
                              maneuver: "turn-left",
                              polyline: {
                                points:
                                  "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                              },
                              end_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                              distance: { value: 426, text: "0.4 km" },
                              start_location: {
                                lat: 25.2801532,
                                lng: 51.4871118,
                              },
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 8, text: "1 min" },
                              polyline: { points: "cphyCoxgyHEaBAsA" },
                              html_instructions:
                                "Continue straight onto <b>Al Sadd St</b>",
                              end_location: {
                                lat: 25.2802165,
                                lng: 51.4917143,
                              },
                              maneuver: "straight",
                              distance: { value: 93, text: "93 m" },
                              start_location: {
                                lat: 25.2801774,
                                lng: 51.49079529999999,
                              },
                            },
                            {
                              distance: { value: 236, text: "0.2 km" },
                              html_instructions: "Slight <b>left</b>",
                              polyline: {
                                points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                              },
                              start_location: {
                                lat: 25.2802165,
                                lng: 51.4917143,
                              },
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2804496,
                                lng: 51.4940386,
                              },
                              duration: { text: "1 min", value: 71 },
                              maneuver: "turn-slight-left",
                            },
                            {
                              duration: { text: "1 min", value: 38 },
                              end_location: { lat: 25.281604, lng: 51.4947254 },
                              polyline: {
                                points: "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                              },
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.4940386,
                                lat: 25.2804496,
                              },
                              distance: { value: 163, text: "0.2 km" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                            },
                            {
                              duration: { text: "1 min", value: 76 },
                              polyline: {
                                points:
                                  "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                              },
                              start_location: {
                                lat: 25.281604,
                                lng: 51.4947254,
                              },
                              html_instructions:
                                "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                              maneuver: "keep-left",
                              end_location: {
                                lng: 51.4965617,
                                lat: 25.2914195,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "1.1 km", value: 1109 },
                            },
                            {
                              start_location: {
                                lat: 25.2914195,
                                lng: 51.4965617,
                              },
                              maneuver: "turn-right",
                              duration: { value: 209, text: "3 mins" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                              polyline: {
                                points:
                                  "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                              },
                              end_location: {
                                lat: 25.2893902,
                                lng: 51.51516729999999,
                              },
                              distance: { value: 1922, text: "1.9 km" },
                              travel_mode: "DRIVING",
                            },
                            {
                              distance: { value: 144, text: "0.1 km" },
                              start_location: {
                                lng: 51.51516729999999,
                                lat: 25.2893902,
                              },
                              end_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej</b>",
                              duration: { value: 15, text: "1 min" },
                              travel_mode: "DRIVING",
                            },
                            {
                              polyline: {
                                points: "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                              },
                              duration: { text: "1 min", value: 11 },
                              end_location: { lng: 51.516228, lat: 25.2874383 },
                              start_location: {
                                lat: 25.2884741,
                                lng: 51.51591370000001,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "0.1 km", value: 122 },
                              html_instructions:
                                'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-slight-right",
                              duration: { text: "1 min", value: 21 },
                              polyline: { points: "o}iyCmwlyHtC_@rB[b@CdEm@" },
                              start_location: {
                                lng: 51.516228,
                                lat: 25.2874383,
                              },
                              end_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              distance: { text: "0.3 km", value: 283 },
                              html_instructions:
                                'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-right",
                              start_location: {
                                lng: 51.51678399999999,
                                lat: 25.2849434,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                              duration: { text: "1 min", value: 32 },
                              polyline: { points: "{miyC{zlyHB`@Db@D`@DxA@r@" },
                              distance: { text: "0.1 km", value: 125 },
                              end_location: {
                                lng: 51.5155496,
                                lat: 25.2848165,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              duration: { text: "1 min", value: 21 },
                              maneuver: "turn-right",
                              distance: { value: 74, text: "74 m" },
                              polyline: {
                                points: "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                              },
                              end_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              start_location: {
                                lat: 25.2848165,
                                lng: 51.5155496,
                              },
                            },
                            {
                              polyline: { points: "uniyC_plyHqBJ" },
                              duration: { text: "1 min", value: 23 },
                              end_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                              start_location: {
                                lat: 25.2850716,
                                lng: 51.5150352,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "turn-right",
                              distance: { value: 64, text: "64 m" },
                            },
                            {
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 14 },
                              start_location: {
                                lat: 25.2856417,
                                lng: 51.51497639999999,
                              },
                              end_location: {
                                lng: 51.5145907,
                                lat: 25.2856124,
                              },
                              distance: { text: "39 m", value: 39 },
                              polyline: { points: "griyCsolyH?p@DZ" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              maneuver: "turn-left",
                            },
                          ],
                          start_address: "858, الدوحة، Qatar",
                          end_address: "7GP7+6RG, Doha, Qatar",
                          traffic_speed_entry: [],
                          duration: { value: 636, text: "11 mins" },
                          distance: { text: "5.4 km", value: 5407 },
                          via_waypoint: [],
                        },
                        {
                          start_address: "7GP7+6RG, Doha, Qatar",
                          duration: { value: 598, text: "10 mins" },
                          traffic_speed_entry: [],
                          start_location: { lat: 25.2856124, lng: 51.5145907 },
                          steps: [
                            {
                              duration: { value: 73, text: "1 min" },
                              end_location: {
                                lat: 25.2859334,
                                lng: 51.5165533,
                              },
                              start_location: {
                                lat: 25.2856124,
                                lng: 51.5145907,
                              },
                              distance: { text: "0.2 km", value: 201 },
                              html_instructions:
                                "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                              distance: { text: "0.7 km", value: 735 },
                              end_location: { lng: 51.518743, lat: 25.2796568 },
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                              },
                              duration: { text: "1 min", value: 78 },
                              start_location: {
                                lng: 51.5165533,
                                lat: 25.2859334,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { text: "0.4 km", value: 393 },
                              end_location: {
                                lat: 25.2769643,
                                lng: 51.5164061,
                              },
                              maneuver: "turn-slight-right",
                              start_location: {
                                lat: 25.2796568,
                                lng: 51.518743,
                              },
                              polyline: {
                                points:
                                  "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                              },
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                              duration: { text: "1 min", value: 59 },
                            },
                            {
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.5164061,
                                lat: 25.2769643,
                              },
                              polyline: {
                                points:
                                  "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                              },
                              end_location: { lng: 51.500835, lat: 25.2663847 },
                              duration: { value: 229, text: "4 mins" },
                              distance: { value: 1963, text: "2.0 km" },
                              maneuver: "merge",
                              html_instructions:
                                "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                            },
                            {
                              maneuver: "turn-slight-right",
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                              end_location: { lng: 51.497229, lat: 25.2643805 },
                              distance: { text: "0.4 km", value: 428 },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 25.2663847,
                                lng: 51.500835,
                              },
                              polyline: {
                                points:
                                  "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                              },
                              duration: { value: 40, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                              maneuver: "roundabout-right",
                              polyline: {
                                points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                              },
                              duration: { value: 22, text: "1 min" },
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.497229,
                                lat: 25.2643805,
                              },
                              distance: { text: "0.2 km", value: 182 },
                              end_location: {
                                lng: 51.4962302,
                                lat: 25.2656775,
                              },
                            },
                            {
                              polyline: { points: "oueyCmzhyH]DKB" },
                              start_location: {
                                lat: 25.2656775,
                                lng: 51.4962302,
                              },
                              end_location: {
                                lat: 25.2658942,
                                lng: 51.4961765,
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 25, text: "25 m" },
                              maneuver: "turn-slight-right",
                              html_instructions:
                                "Slight <b>right</b> onto <b>Al Nasr St</b>",
                              duration: { value: 3, text: "1 min" },
                            },
                            {
                              html_instructions:
                                "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                              maneuver: "turn-slight-right",
                              end_location: { lng: 51.4995317, lat: 25.267547 },
                              travel_mode: "DRIVING",
                              polyline: {
                                points:
                                  "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                              },
                              distance: { value: 389, text: "0.4 km" },
                              duration: { value: 53, text: "1 min" },
                              start_location: {
                                lat: 25.2658942,
                                lng: 51.4961765,
                              },
                            },
                            {
                              distance: { value: 111, text: "0.1 km" },
                              start_location: {
                                lat: 25.267547,
                                lng: 51.4995317,
                              },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohammed St</b>",
                              duration: { value: 22, text: "1 min" },
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              travel_mode: "DRIVING",
                              polyline: { points: "eafyCaoiyHQFiDdA" },
                              maneuver: "turn-left",
                            },
                            {
                              travel_mode: "DRIVING",
                              maneuver: "turn-left",
                              end_location: {
                                lat: 25.2683307,
                                lng: 51.49855549999999,
                              },
                              distance: { text: "62 m", value: 62 },
                              duration: { text: "1 min", value: 19 },
                              polyline: { points: "agfyCsliyH\\vABNAJ" },
                              html_instructions:
                                'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                              start_location: {
                                lng: 51.499144,
                                lat: 25.2684856,
                              },
                            },
                          ],
                          via_waypoint: [],
                          end_location: {
                            lat: 25.2683307,
                            lng: 51.49855549999999,
                          },
                          distance: { text: "4.5 km", value: 4489 },
                          end_address: "7F9X+C9V, Doha, Qatar",
                        },
                        {
                          end_address: "28 Street 8, Ar Rayyan, Qatar",
                          start_address: "7F9X+C9V, Doha, Qatar",
                          via_waypoint: [],
                          duration: { text: "13 mins", value: 795 },
                          start_location: {
                            lng: 51.49855549999999,
                            lat: 25.2683307,
                          },
                          distance: { value: 11216, text: "11.2 km" },
                          end_location: {
                            lat: 25.2132031,
                            lng: 51.43657289999999,
                          },
                          steps: [
                            {
                              end_location: { lng: 51.499144, lat: 25.2684856 },
                              start_location: {
                                lat: 25.2683307,
                                lng: 51.49855549999999,
                              },
                              distance: { value: 62, text: "62 m" },
                              duration: { text: "1 min", value: 14 },
                              polyline: { points: "affyC_iiyH@KCO]wA" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                            },
                            {
                              distance: { value: 28, text: "28 m" },
                              duration: { value: 9, text: "1 min" },
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.499144,
                                lat: 25.2684856,
                              },
                              end_location: { lng: 51.499066, lat: 25.2687298 },
                              html_instructions:
                                "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                              travel_mode: "DRIVING",
                              polyline: { points: "agfyCsliyHo@L" },
                            },
                            {
                              html_instructions:
                                "Turn <b>right</b> onto <b>Al Baraha</b>",
                              polyline: { points: "qhfyCeliyHg@gBOg@S{@Mu@G[" },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              distance: { text: "0.2 km", value: 154 },
                              end_location: { lat: 25.2692232, lng: 51.500496 },
                              duration: { value: 32, text: "1 min" },
                              start_location: {
                                lng: 51.499066,
                                lat: 25.2687298,
                              },
                            },
                            {
                              end_location: { lng: 51.500265, lat: 25.269806 },
                              polyline: { points: "skfyCcuiyH{@Ty@X" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                              duration: { value: 13, text: "1 min" },
                              distance: { text: "69 m", value: 69 },
                              maneuver: "turn-left",
                              start_location: {
                                lng: 51.500496,
                                lat: 25.2692232,
                              },
                            },
                            {
                              polyline: {
                                points: "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                              },
                              start_location: {
                                lat: 25.269806,
                                lng: 51.500265,
                              },
                              distance: { text: "0.2 km", value: 223 },
                              duration: { value: 29, text: "1 min" },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2705523,
                                lng: 51.50232339999999,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                            },
                            {
                              polyline: {
                                points:
                                  "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                              },
                              duration: { value: 48, text: "1 min" },
                              end_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              travel_mode: "DRIVING",
                              maneuver: "roundabout-right",
                              distance: { text: "0.3 km", value: 326 },
                              html_instructions:
                                "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                              start_location: {
                                lng: 51.50232339999999,
                                lat: 25.2705523,
                              },
                            },
                            {
                              end_location: { lng: 51.45117, lat: 25.2368244 },
                              polyline: {
                                points:
                                  "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                              },
                              duration: { value: 283, text: "5 mins" },
                              html_instructions: "Merge onto <b>Salwa Rd</b>",
                              start_location: {
                                lng: 51.5033973,
                                lat: 25.2679045,
                              },
                              travel_mode: "DRIVING",
                              distance: { text: "6.3 km", value: 6288 },
                              maneuver: "merge",
                            },
                            {
                              start_location: {
                                lng: 51.45117,
                                lat: 25.2368244,
                              },
                              maneuver: "ramp-right",
                              duration: { text: "1 min", value: 10 },
                              travel_mode: "DRIVING",
                              distance: { text: "0.2 km", value: 194 },
                              html_instructions:
                                'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                              polyline: { points: "ca`yCy``yHpAnDlAdD" },
                              end_location: {
                                lng: 51.4494587,
                                lat: 25.2360177,
                              },
                            },
                            {
                              travel_mode: "DRIVING",
                              distance: { value: 397, text: "0.4 km" },
                              end_location: {
                                lat: 25.2340694,
                                lng: 51.4461502,
                              },
                              duration: { text: "1 min", value: 33 },
                              html_instructions:
                                "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              maneuver: "merge",
                              start_location: {
                                lat: 25.2360177,
                                lng: 51.4494587,
                              },
                              polyline: {
                                points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                              },
                            },
                            {
                              duration: { value: 8, text: "1 min" },
                              maneuver: "straight",
                              start_location: {
                                lng: 51.4461502,
                                lat: 25.2340694,
                              },
                              html_instructions:
                                "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                              end_location: {
                                lat: 25.2337942,
                                lng: 51.4456607,
                              },
                              polyline: { points: "}o_yCma_yHP`@d@~@" },
                              distance: { text: "58 m", value: 58 },
                              travel_mode: "DRIVING",
                            },
                            {
                              maneuver: "straight",
                              travel_mode: "DRIVING",
                              start_location: {
                                lng: 51.4456607,
                                lat: 25.2337942,
                              },
                              duration: { text: "2 mins", value: 90 },
                              end_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              html_instructions:
                                'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                              polyline: {
                                points:
                                  "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                              },
                              distance: { value: 1451, text: "1.5 km" },
                            },
                            {
                              duration: { text: "1 min", value: 10 },
                              html_instructions:
                                "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                              travel_mode: "DRIVING",
                              distance: { value: 92, text: "92 m" },
                              end_location: {
                                lng: 51.4328437,
                                lat: 25.2262036,
                              },
                              start_location: {
                                lat: 25.2267513,
                                lng: 51.43351759999999,
                              },
                              maneuver: "turn-slight-left",
                              polyline: {
                                points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                              },
                            },
                            {
                              end_location: {
                                lat: 25.2257335,
                                lng: 51.4323655,
                              },
                              html_instructions: "Continue straight",
                              maneuver: "straight",
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 19 },
                              polyline: { points: "w~}xCgn|xHLRNNXZFDVTD@" },
                              distance: { value: 71, text: "71 m" },
                              start_location: {
                                lat: 25.2262036,
                                lng: 51.4328437,
                              },
                            },
                            {
                              polyline: {
                                points:
                                  "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                              },
                              travel_mode: "DRIVING",
                              duration: { value: 72, text: "1 min" },
                              distance: { text: "0.8 km", value: 813 },
                              start_location: {
                                lng: 51.4323655,
                                lat: 25.2257335,
                              },
                              html_instructions:
                                "Continue onto <b>E Industrial Rd</b>",
                              end_location: {
                                lng: 51.4357808,
                                lat: 25.2196178,
                              },
                            },
                            {
                              distance: { value: 263, text: "0.3 km" },
                              maneuver: "turn-right",
                              polyline: {
                                points: "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                              },
                              end_location: {
                                lat: 25.2182661,
                                lng: 51.43371639999999,
                              },
                              start_location: {
                                lat: 25.2196178,
                                lng: 51.4357808,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 2</b>",
                              duration: { text: "1 min", value: 27 },
                              travel_mode: "DRIVING",
                            },
                            {
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2135618,
                                lng: 51.4366206,
                              },
                              duration: { value: 78, text: "1 min" },
                              maneuver: "roundabout-right",
                              start_location: {
                                lng: 51.43371639999999,
                                lat: 25.2182661,
                              },
                              distance: { value: 682, text: "0.7 km" },
                              html_instructions:
                                "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                              polyline: {
                                points:
                                  "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                              },
                            },
                            {
                              distance: { text: "29 m", value: 29 },
                              start_location: {
                                lat: 25.2135618,
                                lng: 51.4366206,
                              },
                              maneuver: "turn-right",
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>right</b> onto <b>Street 8</b>",
                              end_location: {
                                lng: 51.4364944,
                                lat: 25.2133289,
                              },
                              duration: { value: 12, text: "1 min" },
                              polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                            },
                            {
                              distance: { value: 16, text: "16 m" },
                              maneuver: "turn-left",
                              duration: { value: 8, text: "1 min" },
                              travel_mode: "DRIVING",
                              end_location: {
                                lat: 25.2132031,
                                lng: 51.43657289999999,
                              },
                              start_location: {
                                lng: 51.4364944,
                                lat: 25.2133289,
                              },
                              polyline: { points: "in{xCae}xHXO" },
                              html_instructions: "Turn <b>left</b>",
                            },
                          ],
                          traffic_speed_entry: [],
                        },
                      ],
                      waypoint_order: [0, 1],
                      warnings: [],
                      copyrights: "Map data ©2024 Google",
                      overview_polyline: {
                        points:
                          "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                      },
                    },
                  ],
                },
                completedAt: 1707942203548,
                destination: {
                  coordinates: {
                    latitude: 25.213204,
                    longitude: 51.43657469999999,
                  },
                  geohash: "thkx6myvx1",
                  type: "corporate-branch",
                  address: "Street 8, Doha, Qatar",
                  id: "9VZwCrYZfl03ob5FrD4F.0",
                },
                status: "completed",
                skin: null,
                origin: {
                  geohash: "thkxgdt7m0",
                  address: "858, الدوحة، Qatar",
                  coordinates: { longitude: 51.489361, latitude: 25.282848 },
                },
                preferences: {
                  female_passengers_only: null,
                  verified_passengers_only: null,
                },
                car: {
                  seat: 3,
                  co2_p_km: 0.3379843740994449,
                  year: 2021,
                  make: "Toyota",
                  type: "carbon-emitting",
                  model: "LAND CRUSER WAGON 4WD",
                },
                rules: [
                  "No smoking",
                  "No eating or drinking",
                  "Keep off the AC",
                ],
                createdAt: 1707941970485,
                passengersArray: [
                  {
                    userId: "user_id",
                    name: "Jassim",
                    username: "abcd",
                    location: {
                      geohash: "thkxgdt7m0",
                      address: "858, الدوحة، Qatar",
                      coordinates: {
                        latitude: 25.282848,
                        longitude: 51.489361,
                      },
                    },
                    credits: 27.8,
                    co2Amount: 4.313694566631215,
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                    seat: 0,
                    id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                    wallet_id: "spencer_carbon.testnet",
                    carpool: {
                      id: "8292058029514",
                      owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                      co2Amount: 12.612562888268984,
                      directions: {
                        status: "OK",
                        geocoded_waypoints: [
                          {
                            geocoder_status: "OK",
                            types: [
                              "establishment",
                              "moving_company",
                              "point_of_interest",
                            ],
                            place_id: "ChIJl48V45nbRT4RtcV9DnjjY1o",
                          },
                          {
                            place_id: "ChIJPWgV0czaRT4RSaw0WKbAecA",
                            types: [
                              "establishment",
                              "general_contractor",
                              "point_of_interest",
                            ],
                            geocoder_status: "OK",
                          },
                          {
                            types: ["premise"],
                            place_id: "ChIJIcoEp77aRT4RBnFTE2MHjKM",
                            geocoder_status: "OK",
                          },
                          {
                            types: ["street_address"],
                            geocoder_status: "OK",
                            place_id:
                              "Eh0yOCBTdHJlZXQgOCwgQXIgUmF5eWFuLCBRYXRhciIaEhgKFAoSCdmr9fTJ0EU-EXN7ZBaeAilaEBw",
                          },
                        ],
                        routes: [
                          {
                            summary: "Al Rayyan Rd",
                            bounds: {
                              northeast: { lng: 51.518743, lat: 25.291958 },
                              southwest: { lat: 25.2132031, lng: 51.4319062 },
                            },
                            legs: [
                              {
                                end_location: {
                                  lat: 25.2856124,
                                  lng: 51.5145907,
                                },
                                start_location: {
                                  lat: 25.2827107,
                                  lng: 51.4906083,
                                },
                                steps: [
                                  {
                                    polyline: { points: "}_iyCiwgyHz@B" },
                                    end_location: {
                                      lng: 51.4905936,
                                      lat: 25.2824136,
                                    },
                                    start_location: {
                                      lat: 25.2827107,
                                      lng: 51.4906083,
                                    },
                                    distance: { value: 33, text: "33 m" },
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Head <b>south</b> on <b>Asmah Bint Abi Bakr</b>",
                                    duration: { value: 4, text: "1 min" },
                                  },
                                  {
                                    html_instructions:
                                      "Continue onto <b>Jasim Bin Hamad St</b>/<wbr/><b>Meshab</b>",
                                    travel_mode: "DRIVING",
                                    distance: { value: 574, text: "0.6 km" },
                                    duration: { value: 49, text: "1 min" },
                                    end_location: {
                                      lng: 51.4871118,
                                      lat: 25.2801532,
                                    },
                                    polyline: {
                                      points:
                                        "a~hyCewgyH|GQfAED@D?B@BBFFBD@H@DFxAJtBDf@B`@?XLfELlD",
                                    },
                                    start_location: {
                                      lng: 51.4905936,
                                      lat: 25.2824136,
                                    },
                                  },
                                  {
                                    html_instructions: "Turn <b>left</b>",
                                    duration: { text: "1 min", value: 44 },
                                    maneuver: "turn-left",
                                    polyline: {
                                      points:
                                        "}ohyCmagyHFLHHDBB@B?F?HCFEBE@EFKAQAUEs@IwBIwBIcCOiCEkAOw@",
                                    },
                                    end_location: {
                                      lat: 25.2801774,
                                      lng: 51.49079529999999,
                                    },
                                    distance: { value: 426, text: "0.4 km" },
                                    start_location: {
                                      lat: 25.2801532,
                                      lng: 51.4871118,
                                    },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    duration: { value: 8, text: "1 min" },
                                    polyline: { points: "cphyCoxgyHEaBAsA" },
                                    html_instructions:
                                      "Continue straight onto <b>Al Sadd St</b>",
                                    end_location: {
                                      lat: 25.2802165,
                                      lng: 51.4917143,
                                    },
                                    maneuver: "straight",
                                    distance: { value: 93, text: "93 m" },
                                    start_location: {
                                      lat: 25.2801774,
                                      lng: 51.49079529999999,
                                    },
                                  },
                                  {
                                    distance: { value: 236, text: "0.2 km" },
                                    html_instructions: "Slight <b>left</b>",
                                    polyline: {
                                      points: "kphyCe~gyHMa@Eg@IwCASIqDAQ?KAK",
                                    },
                                    start_location: {
                                      lat: 25.2802165,
                                      lng: 51.4917143,
                                    },
                                    travel_mode: "DRIVING",
                                    end_location: {
                                      lat: 25.2804496,
                                      lng: 51.4940386,
                                    },
                                    duration: { text: "1 min", value: 71 },
                                    maneuver: "turn-slight-left",
                                  },
                                  {
                                    duration: { text: "1 min", value: 38 },
                                    end_location: {
                                      lat: 25.281604,
                                      lng: 51.4947254,
                                    },
                                    polyline: {
                                      points:
                                        "yqhyCwlhyHA]A_@WGm@Uk@M[IIAOCy@O",
                                    },
                                    travel_mode: "DRIVING",
                                    maneuver: "turn-left",
                                    start_location: {
                                      lng: 51.4940386,
                                      lat: 25.2804496,
                                    },
                                    distance: { value: 163, text: "0.2 km" },
                                    html_instructions:
                                      'Turn <b>left</b> onto <b>Al Sadd</b>/<wbr/><b>Jawaan St</b><div style="font-size:0.9em">Continue to follow Jawaan St</div>',
                                  },
                                  {
                                    duration: { text: "1 min", value: 76 },
                                    polyline: {
                                      points:
                                        "_yhyCaqhyHeBQeBOeAQYEOAu@IaBQaAKeBSgAMeAKiBSgAMgAKwFm@sAKeAI[Cy@IgBKo@Y_CUgD_@",
                                    },
                                    start_location: {
                                      lat: 25.281604,
                                      lng: 51.4947254,
                                    },
                                    html_instructions:
                                      "Keep <b>left</b> to stay on <b>Jawaan St</b>",
                                    maneuver: "keep-left",
                                    end_location: {
                                      lng: 51.4965617,
                                      lat: 25.2914195,
                                    },
                                    travel_mode: "DRIVING",
                                    distance: { text: "1.1 km", value: 1109 },
                                  },
                                  {
                                    start_location: {
                                      lat: 25.2914195,
                                      lng: 51.4965617,
                                    },
                                    maneuver: "turn-right",
                                    duration: { value: 209, text: "3 mins" },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Rayyan Rd</b>",
                                    polyline: {
                                      points:
                                        "kvjyCo|hyHCEAACAAAEAGEKCKCAAECCCCCKOCEEICICIAKAO?I?KAGAGCGHiAJmAN{A^oEd@iIDe@Dk@LuA`@oEb@iFPgATsBLmBXoCBYBUFa@@SJcAT_BH_ANgCX{DR}CRkCR}BDo@Da@Bk@Fm@DgAL{DBa@FcAHkB",
                                    },
                                    end_location: {
                                      lat: 25.2893902,
                                      lng: 51.51516729999999,
                                    },
                                    distance: { value: 1922, text: "1.9 km" },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    distance: { value: 144, text: "0.1 km" },
                                    start_location: {
                                      lng: 51.51516729999999,
                                      lat: 25.2893902,
                                    },
                                    end_location: {
                                      lat: 25.2884741,
                                      lng: 51.51591370000001,
                                    },
                                    maneuver: "turn-right",
                                    polyline: {
                                      points:
                                        "uijyCyplyHB?BC@A?ABIBM@KBO?ABIDMBGFOBEBCFGx@I~@I",
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Khaleej</b>",
                                    duration: { value: 15, text: "1 min" },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    polyline: {
                                      points:
                                        "}cjyCmulyHv@Or@Kz@MXEBABA@A?A@A?E",
                                    },
                                    duration: { text: "1 min", value: 11 },
                                    end_location: {
                                      lng: 51.516228,
                                      lat: 25.2874383,
                                    },
                                    start_location: {
                                      lat: 25.2884741,
                                      lng: 51.51591370000001,
                                    },
                                    travel_mode: "DRIVING",
                                    distance: { text: "0.1 km", value: 122 },
                                    html_instructions:
                                      'Continue onto <b><span dir="rtl">Al Khaleej Street</span>‎</b>',
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    maneuver: "turn-slight-right",
                                    duration: { text: "1 min", value: 21 },
                                    polyline: {
                                      points: "o}iyCmwlyHtC_@rB[b@CdEm@",
                                    },
                                    start_location: {
                                      lng: 51.516228,
                                      lat: 25.2874383,
                                    },
                                    end_location: {
                                      lng: 51.51678399999999,
                                      lat: 25.2849434,
                                    },
                                    distance: { text: "0.3 km", value: 283 },
                                    html_instructions:
                                      'Slight <b>right</b> onto <b>Al Istiqlal St</b>/<wbr/><b>Al Khaleej St</b><div style="font-size:0.9em">Continue to follow Al Khaleej St</div>',
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    maneuver: "turn-right",
                                    start_location: {
                                      lng: 51.51678399999999,
                                      lat: 25.2849434,
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Saeed Al Ali St</b>",
                                    duration: { text: "1 min", value: 32 },
                                    polyline: {
                                      points: "{miyC{zlyHB`@Db@D`@DxA@r@",
                                    },
                                    distance: { text: "0.1 km", value: 125 },
                                    end_location: {
                                      lng: 51.5155496,
                                      lat: 25.2848165,
                                    },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Hakeem Bin Muawiya</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                    duration: { text: "1 min", value: 21 },
                                    maneuver: "turn-right",
                                    distance: { value: 74, text: "74 m" },
                                    polyline: {
                                      points:
                                        "cmiyCeslyHe@DA@C@A@GH?DAB?@Bh@@X",
                                    },
                                    end_location: {
                                      lat: 25.2850716,
                                      lng: 51.5150352,
                                    },
                                    start_location: {
                                      lat: 25.2848165,
                                      lng: 51.5155496,
                                    },
                                  },
                                  {
                                    polyline: { points: "uniyC_plyHqBJ" },
                                    duration: { text: "1 min", value: 23 },
                                    end_location: {
                                      lat: 25.2856417,
                                      lng: 51.51497639999999,
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Ikhaa</b>/<wbr/><b>Zurara Bin Qais St</b>",
                                    start_location: {
                                      lat: 25.2850716,
                                      lng: 51.5150352,
                                    },
                                    travel_mode: "DRIVING",
                                    maneuver: "turn-right",
                                    distance: { value: 64, text: "64 m" },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    duration: { text: "1 min", value: 14 },
                                    start_location: {
                                      lat: 25.2856417,
                                      lng: 51.51497639999999,
                                    },
                                    end_location: {
                                      lng: 51.5145907,
                                      lat: 25.2856124,
                                    },
                                    distance: { text: "39 m", value: 39 },
                                    polyline: { points: "griyCsolyH?p@DZ" },
                                    html_instructions:
                                      'Turn <b>left</b> onto <b>Al Ikhaa St</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                    maneuver: "turn-left",
                                  },
                                ],
                                start_address: "858, الدوحة، Qatar",
                                end_address: "7GP7+6RG, Doha, Qatar",
                                traffic_speed_entry: [],
                                duration: { value: 636, text: "11 mins" },
                                distance: { text: "5.4 km", value: 5407 },
                                via_waypoint: [],
                              },
                              {
                                start_address: "7GP7+6RG, Doha, Qatar",
                                duration: { value: 598, text: "10 mins" },
                                traffic_speed_entry: [],
                                start_location: {
                                  lat: 25.2856124,
                                  lng: 51.5145907,
                                },
                                steps: [
                                  {
                                    duration: { value: 73, text: "1 min" },
                                    end_location: {
                                      lat: 25.2859334,
                                      lng: 51.5165533,
                                    },
                                    start_location: {
                                      lat: 25.2856124,
                                      lng: 51.5145907,
                                    },
                                    distance: { text: "0.2 km", value: 201 },
                                    html_instructions:
                                      "Head <b>east</b> on <b>Al Ikhaa St</b> toward <b>Ibn Al Ameed St</b>",
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points:
                                        "ariyCemlyHE[?q@SmAIe@Ec@Gc@I_ACM?]?Q",
                                    },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Khaleej St</b>",
                                    distance: { text: "0.7 km", value: 735 },
                                    end_location: {
                                      lng: 51.518743,
                                      lat: 25.2796568,
                                    },
                                    maneuver: "turn-right",
                                    polyline: {
                                      points:
                                        "atiyCmylyHdEm@xAMB?|@OLCB?rBe@^KPGd@ONExFmBTITI^MLGr@WB??Ab@Oz@YpAc@NCDCJCLCHAB?DAD?LA",
                                    },
                                    duration: { text: "1 min", value: 78 },
                                    start_location: {
                                      lng: 51.5165533,
                                      lat: 25.2859334,
                                    },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    distance: { text: "0.4 km", value: 393 },
                                    end_location: {
                                      lat: 25.2769643,
                                      lng: 51.5164061,
                                    },
                                    maneuver: "turn-slight-right",
                                    start_location: {
                                      lat: 25.2796568,
                                      lng: 51.518743,
                                    },
                                    polyline: {
                                      points:
                                        "{lhyCcgmyHBBB@@@LBN@NBJBTBTFNDNFJF^NTJRHLJb@n@t@fA|A|Bx@fADDDDHHDDDBDBLD",
                                    },
                                    html_instructions:
                                      "Slight <b>right</b> to stay on <b>Al Khaleej St</b>",
                                    duration: { text: "1 min", value: 59 },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    start_location: {
                                      lng: 51.5164061,
                                      lat: 25.2769643,
                                    },
                                    polyline: {
                                      points:
                                        "_|gyCqxlyHXb@v@fAFHRXHLJLZb@PVtA~AnAxArAbBnClDzCbEl@z@X`@Xb@d@z@HNJPNTNTJPFJXf@R`@z@`BfAtBxAnCXj@x@xAjA|Bj@hApAbCxAlCb@v@p@pAl@hAb@|@^r@\\n@^t@`@z@r@vAJT@BR^x@|A|A~C",
                                    },
                                    end_location: {
                                      lng: 51.500835,
                                      lat: 25.2663847,
                                    },
                                    duration: { value: 229, text: "4 mins" },
                                    distance: { value: 1963, text: "2.0 km" },
                                    maneuver: "merge",
                                    html_instructions:
                                      "Merge onto <b>Al Khaleej</b>/<wbr/><b>Salwa Rd</b>",
                                  },
                                  {
                                    maneuver: "turn-slight-right",
                                    html_instructions:
                                      "Slight <b>right</b> to stay on <b>Salwa Rd</b>",
                                    end_location: {
                                      lng: 51.497229,
                                      lat: 25.2643805,
                                    },
                                    distance: { text: "0.4 km", value: 428 },
                                    travel_mode: "DRIVING",
                                    start_location: {
                                      lat: 25.2663847,
                                      lng: 51.500835,
                                    },
                                    polyline: {
                                      points:
                                        "{yeyCewiyHAR?B?@@@?@?@BD`@dAJRFPFJFLR\\Rb@P\\^t@P`@P^L\\tAlCJNd@`AR`@R^HJ",
                                    },
                                    duration: { value: 40, text: "1 min" },
                                  },
                                  {
                                    html_instructions:
                                      "At the roundabout, take the <b>1st</b> exit onto <b>Al Amir St</b>",
                                    maneuver: "roundabout-right",
                                    polyline: {
                                      points: "kmeyCu`iyH?@CHCH?H?FcAn@wDnB",
                                    },
                                    duration: { value: 22, text: "1 min" },
                                    travel_mode: "DRIVING",
                                    start_location: {
                                      lng: 51.497229,
                                      lat: 25.2643805,
                                    },
                                    distance: { text: "0.2 km", value: 182 },
                                    end_location: {
                                      lng: 51.4962302,
                                      lat: 25.2656775,
                                    },
                                  },
                                  {
                                    polyline: { points: "oueyCmzhyH]DKB" },
                                    start_location: {
                                      lat: 25.2656775,
                                      lng: 51.4962302,
                                    },
                                    end_location: {
                                      lat: 25.2658942,
                                      lng: 51.4961765,
                                    },
                                    travel_mode: "DRIVING",
                                    distance: { value: 25, text: "25 m" },
                                    maneuver: "turn-slight-right",
                                    html_instructions:
                                      "Slight <b>right</b> onto <b>Al Nasr St</b>",
                                    duration: { value: 3, text: "1 min" },
                                  },
                                  {
                                    html_instructions:
                                      "Slight <b>right</b> to stay on <b>Al Nasr St</b>",
                                    maneuver: "turn-slight-right",
                                    end_location: {
                                      lng: 51.4995317,
                                      lat: 25.267547,
                                    },
                                    travel_mode: "DRIVING",
                                    polyline: {
                                      points:
                                        "yveyCczhyHO?IEEGo@oBmAkDa@gAe@sA[_AYy@K[c@aA",
                                    },
                                    distance: { value: 389, text: "0.4 km" },
                                    duration: { value: 53, text: "1 min" },
                                    start_location: {
                                      lat: 25.2658942,
                                      lng: 51.4961765,
                                    },
                                  },
                                  {
                                    distance: { value: 111, text: "0.1 km" },
                                    start_location: {
                                      lat: 25.267547,
                                      lng: 51.4995317,
                                    },
                                    html_instructions:
                                      "Turn <b>left</b> onto <b>Mohammed St</b>",
                                    duration: { value: 22, text: "1 min" },
                                    end_location: {
                                      lng: 51.499144,
                                      lat: 25.2684856,
                                    },
                                    travel_mode: "DRIVING",
                                    polyline: { points: "eafyCaoiyHQFiDdA" },
                                    maneuver: "turn-left",
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    maneuver: "turn-left",
                                    end_location: {
                                      lat: 25.2683307,
                                      lng: 51.49855549999999,
                                    },
                                    distance: { text: "62 m", value: 62 },
                                    duration: { text: "1 min", value: 19 },
                                    polyline: { points: "agfyCsliyH\\vABNAJ" },
                                    html_instructions:
                                      'Turn <b>left</b> onto <b>Al Baraha</b><div style="font-size:0.9em">Destination will be on the left</div>',
                                    start_location: {
                                      lng: 51.499144,
                                      lat: 25.2684856,
                                    },
                                  },
                                ],
                                via_waypoint: [],
                                end_location: {
                                  lat: 25.2683307,
                                  lng: 51.49855549999999,
                                },
                                distance: { text: "4.5 km", value: 4489 },
                                end_address: "7F9X+C9V, Doha, Qatar",
                              },
                              {
                                end_address: "28 Street 8, Ar Rayyan, Qatar",
                                start_address: "7F9X+C9V, Doha, Qatar",
                                via_waypoint: [],
                                duration: { text: "13 mins", value: 795 },
                                start_location: {
                                  lng: 51.49855549999999,
                                  lat: 25.2683307,
                                },
                                distance: { value: 11216, text: "11.2 km" },
                                end_location: {
                                  lat: 25.2132031,
                                  lng: 51.43657289999999,
                                },
                                steps: [
                                  {
                                    end_location: {
                                      lng: 51.499144,
                                      lat: 25.2684856,
                                    },
                                    start_location: {
                                      lat: 25.2683307,
                                      lng: 51.49855549999999,
                                    },
                                    distance: { value: 62, text: "62 m" },
                                    duration: { text: "1 min", value: 14 },
                                    polyline: { points: "affyC_iiyH@KCO]wA" },
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Head <b>east</b> on <b>Al Baraha</b> toward <b>Mohammed St</b>",
                                  },
                                  {
                                    distance: { value: 28, text: "28 m" },
                                    duration: { value: 9, text: "1 min" },
                                    maneuver: "turn-left",
                                    start_location: {
                                      lng: 51.499144,
                                      lat: 25.2684856,
                                    },
                                    end_location: {
                                      lng: 51.499066,
                                      lat: 25.2687298,
                                    },
                                    html_instructions:
                                      "Turn <b>left</b> onto <b>Al Baraha</b>/<wbr/><b>Mohammed St</b>",
                                    travel_mode: "DRIVING",
                                    polyline: { points: "agfyCsliyHo@L" },
                                  },
                                  {
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Al Baraha</b>",
                                    polyline: {
                                      points: "qhfyCeliyHg@gBOg@S{@Mu@G[",
                                    },
                                    maneuver: "turn-right",
                                    travel_mode: "DRIVING",
                                    distance: { text: "0.2 km", value: 154 },
                                    end_location: {
                                      lat: 25.2692232,
                                      lng: 51.500496,
                                    },
                                    duration: { value: 32, text: "1 min" },
                                    start_location: {
                                      lng: 51.499066,
                                      lat: 25.2687298,
                                    },
                                  },
                                  {
                                    end_location: {
                                      lng: 51.500265,
                                      lat: 25.269806,
                                    },
                                    polyline: { points: "skfyCcuiyH{@Ty@X" },
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Turn <b>left</b> onto <b>Mohamed Bin Al Qassim St</b>",
                                    duration: { value: 13, text: "1 min" },
                                    distance: { text: "69 m", value: 69 },
                                    maneuver: "turn-left",
                                    start_location: {
                                      lng: 51.500496,
                                      lat: 25.2692232,
                                    },
                                  },
                                  {
                                    polyline: {
                                      points:
                                        "iofyCssiyHCKQs@_@qAQq@AOg@oBa@wA",
                                    },
                                    start_location: {
                                      lat: 25.269806,
                                      lng: 51.500265,
                                    },
                                    distance: { text: "0.2 km", value: 223 },
                                    duration: { value: 29, text: "1 min" },
                                    maneuver: "turn-right",
                                    travel_mode: "DRIVING",
                                    end_location: {
                                      lat: 25.2705523,
                                      lng: 51.50232339999999,
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Yasser Bin Ammar St</b>",
                                  },
                                  {
                                    polyline: {
                                      points:
                                        "}sfyCo`jyH?A@?@A?A@??A?A?A?A?AA??A^KZKdA]JC~@]BAl@SVKZMXM`@QZOVKNGHEJAFAF?B?D?D@D@D@VH",
                                    },
                                    duration: { value: 48, text: "1 min" },
                                    end_location: {
                                      lng: 51.5033973,
                                      lat: 25.2679045,
                                    },
                                    travel_mode: "DRIVING",
                                    maneuver: "roundabout-right",
                                    distance: { text: "0.3 km", value: 326 },
                                    html_instructions:
                                      "At the roundabout, take the <b>1st</b> exit onto <b>Al Basateen St</b>",
                                    start_location: {
                                      lng: 51.50232339999999,
                                      lat: 25.2705523,
                                    },
                                  },
                                  {
                                    end_location: {
                                      lng: 51.45117,
                                      lat: 25.2368244,
                                    },
                                    polyline: {
                                      points:
                                        "kcfyCggjyH^t@`@z@r@vAJT@BR^x@|A|A~CjA~BfAxBx@`BtBfEd@|@f@hAl@fAl@hAx@|Az@|A|@bBvDvHdAvBbD`HpEzIhEtIR`@vElJf@`Ax@bBb@z@R^P`@BBjA`CnGlMjFnK`O`ZR^R`@pHbOjCvFnB~DnIrPR`@Vf@bJrQ`ApB~@pB|HpOR`@rCvFjBvDlOfZvAlC`F`J",
                                    },
                                    duration: { value: 283, text: "5 mins" },
                                    html_instructions:
                                      "Merge onto <b>Salwa Rd</b>",
                                    start_location: {
                                      lng: 51.5033973,
                                      lat: 25.2679045,
                                    },
                                    travel_mode: "DRIVING",
                                    distance: { text: "6.3 km", value: 6288 },
                                    maneuver: "merge",
                                  },
                                  {
                                    start_location: {
                                      lng: 51.45117,
                                      lat: 25.2368244,
                                    },
                                    maneuver: "ramp-right",
                                    duration: { text: "1 min", value: 10 },
                                    travel_mode: "DRIVING",
                                    distance: { text: "0.2 km", value: 194 },
                                    html_instructions:
                                      'Take exit <b>12</b> toward <b>12</b>/<wbr/><b><span dir="rtl">لقاطع خالد بن محمد القاسم</span>‎</b>/<wbr/><b><span dir="rtl">الشارع الفروسية</span>‎</b>/<wbr/><b><span dir="rtl">الغانم الجديد</span>‎</b>',
                                    polyline: { points: "ca`yCy``yHpAnDlAdD" },
                                    end_location: {
                                      lng: 51.4494587,
                                      lat: 25.2360177,
                                    },
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    distance: { value: 397, text: "0.4 km" },
                                    end_location: {
                                      lat: 25.2340694,
                                      lng: 51.4461502,
                                    },
                                    duration: { text: "1 min", value: 33 },
                                    html_instructions:
                                      "Merge onto <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                    maneuver: "merge",
                                    start_location: {
                                      lat: 25.2360177,
                                      lng: 51.4494587,
                                    },
                                    polyline: {
                                      points: "c|_yCcv_yHhBpDj@lAzDxHd@bALV",
                                    },
                                  },
                                  {
                                    duration: { value: 8, text: "1 min" },
                                    maneuver: "straight",
                                    start_location: {
                                      lng: 51.4461502,
                                      lat: 25.2340694,
                                    },
                                    html_instructions:
                                      "Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b>",
                                    end_location: {
                                      lat: 25.2337942,
                                      lng: 51.4456607,
                                    },
                                    polyline: { points: "}o_yCma_yHP`@d@~@" },
                                    distance: { text: "58 m", value: 58 },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    maneuver: "straight",
                                    travel_mode: "DRIVING",
                                    start_location: {
                                      lng: 51.4456607,
                                      lat: 25.2337942,
                                    },
                                    duration: { text: "2 mins", value: 90 },
                                    end_location: {
                                      lat: 25.2267513,
                                      lng: 51.43351759999999,
                                    },
                                    html_instructions:
                                      'Continue straight to stay on <b>Al Muntazah</b>/<wbr/><b>Salwa Rd</b><div style="font-size:0.9em">Continue to follow Al Muntazah</div>',
                                    polyline: {
                                      points:
                                        "en_yCk~~xHHNl@fANVrAlCdFzJ`AhBzDfIhBrDp@|ApDtHVj@h@dA\\r@p@|A`@v@Rb@dBrD~@tBj@lAHVFJDJ",
                                    },
                                    distance: { value: 1451, text: "1.5 km" },
                                  },
                                  {
                                    duration: { text: "1 min", value: 10 },
                                    html_instructions:
                                      "Slight <b>left</b> toward <b>E Industrial Rd</b>",
                                    travel_mode: "DRIVING",
                                    distance: { value: 92, text: "92 m" },
                                    end_location: {
                                      lng: 51.4328437,
                                      lat: 25.2262036,
                                    },
                                    start_location: {
                                      lat: 25.2267513,
                                      lng: 51.43351759999999,
                                    },
                                    maneuver: "turn-slight-left",
                                    polyline: {
                                      points: "eb~xCor|xHFBDBFFDFHLRVNTNTJRFH",
                                    },
                                  },
                                  {
                                    end_location: {
                                      lat: 25.2257335,
                                      lng: 51.4323655,
                                    },
                                    html_instructions: "Continue straight",
                                    maneuver: "straight",
                                    travel_mode: "DRIVING",
                                    duration: { text: "1 min", value: 19 },
                                    polyline: {
                                      points: "w~}xCgn|xHLRNNXZFDVTD@",
                                    },
                                    distance: { value: 71, text: "71 m" },
                                    start_location: {
                                      lat: 25.2262036,
                                      lng: 51.4328437,
                                    },
                                  },
                                  {
                                    polyline: {
                                      points:
                                        "y{}xCik|xH^VFDZRRHTJ\\JJDJ@J?F@TCLIhAs@d@]d@]|BuArA_A`@YpBwA^Y|@_Al@_@z@o@PKZW~AgAvCkB",
                                    },
                                    travel_mode: "DRIVING",
                                    duration: { value: 72, text: "1 min" },
                                    distance: { text: "0.8 km", value: 813 },
                                    start_location: {
                                      lng: 51.4323655,
                                      lat: 25.2257335,
                                    },
                                    html_instructions:
                                      "Continue onto <b>E Industrial Rd</b>",
                                    end_location: {
                                      lng: 51.4357808,
                                      lat: 25.2196178,
                                    },
                                  },
                                  {
                                    distance: { value: 263, text: "0.3 km" },
                                    maneuver: "turn-right",
                                    polyline: {
                                      points:
                                        "su|xCs`}xHR?D@FBBBDDLPnCpFVl@j@xA",
                                    },
                                    end_location: {
                                      lat: 25.2182661,
                                      lng: 51.43371639999999,
                                    },
                                    start_location: {
                                      lat: 25.2196178,
                                      lng: 51.4357808,
                                    },
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Street 2</b>",
                                    duration: { text: "1 min", value: 27 },
                                    travel_mode: "DRIVING",
                                  },
                                  {
                                    travel_mode: "DRIVING",
                                    end_location: {
                                      lat: 25.2135618,
                                      lng: 51.4366206,
                                    },
                                    duration: { value: 78, text: "1 min" },
                                    maneuver: "roundabout-right",
                                    start_location: {
                                      lng: 51.43371639999999,
                                      lat: 25.2182661,
                                    },
                                    distance: { value: 682, text: "0.7 km" },
                                    html_instructions:
                                      "At the roundabout, take the <b>3rd</b> exit onto <b>Al Wakalat Street</b>",
                                    polyline: {
                                      points:
                                        "em|xCws|xHAFCF?FAH@F?D?@BF@FBFDDDDDBFBDBF?F@B?BADAFADCDEDE?ABCBE@G@G@E?GAGAG`Ai@f@[vDcCdBiADAtA}@zDeChCeB\\W",
                                    },
                                  },
                                  {
                                    distance: { text: "29 m", value: 29 },
                                    start_location: {
                                      lat: 25.2135618,
                                      lng: 51.4366206,
                                    },
                                    maneuver: "turn-right",
                                    travel_mode: "DRIVING",
                                    html_instructions:
                                      "Turn <b>right</b> onto <b>Street 8</b>",
                                    end_location: {
                                      lng: 51.4364944,
                                      lat: 25.2133289,
                                    },
                                    duration: { value: 12, text: "1 min" },
                                    polyline: { points: "wo{xC{e}xHLDJBHFHF" },
                                  },
                                  {
                                    distance: { value: 16, text: "16 m" },
                                    maneuver: "turn-left",
                                    duration: { value: 8, text: "1 min" },
                                    travel_mode: "DRIVING",
                                    end_location: {
                                      lat: 25.2132031,
                                      lng: 51.43657289999999,
                                    },
                                    start_location: {
                                      lng: 51.4364944,
                                      lat: 25.2133289,
                                    },
                                    polyline: { points: "in{xCae}xHXO" },
                                    html_instructions: "Turn <b>left</b>",
                                  },
                                ],
                                traffic_speed_entry: [],
                              },
                            ],
                            waypoint_order: [0, 1],
                            warnings: [],
                            copyrights: "Map data ©2024 Google",
                            overview_polyline: {
                              points:
                                "}_iyCiwgyHz@B|GQlACH@JJDNH~ATxEZtJPVHDJ?PIDKFKAQGiAm@}NEkAOw@GuDMa@Eg@KkDM{EC}@eA]aB]_Da@eBOeAQi@GwC[uHy@qN{AoFc@gBKo@YgHu@KKe@OOMOUISEUAYGc@dAcMj@oJRaCdAyLf@{Df@}FFo@j@yEfAaPf@iGJqAJyARcGJeBHkBB?DEBKHk@L_@JUJKxBSjB[|AWBKhG{@b@CdEm@HdAJzB@r@e@DEBKTBj@@XqBJ?p@DZE[?q@]sBMgAMmA?o@dEm@xAM`AOPCrCq@`IkCxAi@zAi@bDeAf@IJALABBDB\\DfARjAd@h@TLJb@n@rCdE~@lAZXRHpAjBp@~@l@z@dDxDbFpGhE~Fr@dAn@jAv@nApBvDtFjKbHxMtAhCpAfC~BtEtApCvC|FAV@DhAlCxAtCb@`AbBjDxArCHLGR?PcAn@wDnBi@HO?IEu@wBoBsFgBiFc@aAQFiDdA\\vA@ZA[]wAo@Lw@oCa@qBG[{@Ty@XCKq@eCSaAiAgE?A@?@A@A?EACz@YtCaAzB{@dBu@TGNATB\\J`ApBtApCjHvNnDhHlAfCpElItFzKhFxKfS`a@jOvZtWrh@pHbOjCvF~LrVpMnW|JbSfDxGxR~_@xHnN~CtItC~F`F|J^x@lBnDxHhO`AhBzDfIzCpGhE`JfAxBrAtCxDlIbA|BLFLN|@pAR\\\\b@~@x@vAz@~@\\V@\\AbDyBpEuCrCqB^Y|@_AhBoAdHwEX@JFRVfD~Gj@xAAFCN?XN\\RLXDVINQH[AOAG`Ai@~E_DjBkApGcEfD}BXHRNXO",
                            },
                          },
                        ],
                      },
                      completedAt: 1707942203548,
                      destination: {
                        coordinates: {
                          latitude: 25.213204,
                          longitude: 51.43657469999999,
                        },
                        geohash: "thkx6myvx1",
                        type: "corporate-branch",
                        address: "Street 8, Doha, Qatar",
                        id: "9VZwCrYZfl03ob5FrD4F.0",
                      },
                      status: "completed",
                      skin: null,
                      origin: {
                        geohash: "thkxgdt7m0",
                        address: "858, الدوحة، Qatar",
                        coordinates: {
                          longitude: 51.489361,
                          latitude: 25.282848,
                        },
                      },
                      preferences: {
                        female_passengers_only: null,
                        verified_passengers_only: null,
                      },
                      car: {
                        seat: 3,
                        co2_p_km: 0.3379843740994449,
                        year: 2021,
                        make: "Toyota",
                        type: "carbon-emitting",
                        model: "LAND CRUSER WAGON 4WD",
                      },
                      rules: [
                        "No smoking",
                        "No eating or drinking",
                        "Keep off the AC",
                      ],
                      createdAt: 1707941970485,
                      passengersArray: [
                        {
                          corporate: "9VZwCrYZfl03ob5FrD4F",
                          wallet_id: "spencer_carbon.testnet",
                          location: {
                            geohash: "thkxgdt7m0",
                            address: "858, الدوحة، Qatar",
                            coordinates: {
                              latitude: 25.282848,
                              longitude: 51.489361,
                            },
                          },
                          co2Amount: 4.313694566631215,
                          seat: 0,
                          name: "Jassim",
                          id: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
                          username: "abcd",
                          credits: 27.8,
                          avatar:
                            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_shirt_brown_Avatarz_brown706.png",
                        },
                        {
                          co2Amount: 4.508035581738396,
                          seat: 2,
                          wallet_id: "sofia_carbon.testnet",
                          username: "michaelscott",
                          avatar:
                            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                          status: "picked-up",
                          location: {
                            coordinates: {
                              longitude: 51.514615,
                              latitude: 25.285509,
                            },
                            geohash: "thkxu5pfc8",
                            address: "7GP7+6RG, Doha, Qatar",
                          },
                          credits: 13.9,
                          corporate: "9VZwCrYZfl03ob5FrD4F",
                          id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                          name: "Ahmed",
                        },
                        {
                          avatar:
                            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                          status: "picked-up",
                          seat: 1,
                          location: {
                            address: "7F9X+C9V, Doha, Qatar",
                            coordinates: {
                              latitude: 25.268246,
                              longitude: 51.498513,
                            },
                            geohash: "thkxezun4q",
                          },
                          co2Amount: 3.7908327398993733,
                          wallet_id: "sarah_carbon.testnet",
                          credits: 13,
                          username: "sarah",
                          corporate: "9VZwCrYZfl03ob5FrD4F",
                          id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                          name: "Faisal",
                        },
                      ],
                    },
                    type: "carpool_passenger",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                  },
                  {
                    co2Amount: 4.508035581738396,
                    seat: 2,
                    wallet_id: "sofia_carbon.testnet",
                    username: "michaelscott",
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_suite_white_Avatarz_white0678.png",
                    status: "picked-up",
                    location: {
                      coordinates: {
                        longitude: 51.514615,
                        latitude: 25.285509,
                      },
                      geohash: "thkxu5pfc8",
                      address: "7GP7+6RG, Doha, Qatar",
                    },
                    credits: 13.9,
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    id: "lpd29qTdlURFvrSDO0w5WBODcSp2",
                    name: "Ahmed",
                  },
                  {
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
                    status: "picked-up",
                    seat: 1,
                    location: {
                      address: "7F9X+C9V, Doha, Qatar",
                      coordinates: {
                        latitude: 25.268246,
                        longitude: 51.498513,
                      },
                      geohash: "thkxezun4q",
                    },
                    co2Amount: 3.7908327398993733,
                    wallet_id: "sarah_carbon.testnet",
                    credits: 13,
                    username: "sarah",
                    corporate: "9VZwCrYZfl03ob5FrD4F",
                    id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
                    name: "Faisal",
                  },
                ],
              },
              type: "carpool_passenger",
              corporate: "9VZwCrYZfl03ob5FrD4F",
            },
            {
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_t-shirt_brown_Avatarz_brown1089.png",
              status: "picked-up",
              seat: 1,
              location: {
                address: "7F9X+C9V, Doha, Qatar",
                coordinates: { latitude: 25.268246, longitude: 51.498513 },
                geohash: "thkxezun4q",
              },
              co2Amount: 3.7908327398993733,
              wallet_id: "sarah_carbon.testnet",
              credits: 13,
              username: "sarah",
              corporate: "9VZwCrYZfl03ob5FrD4F",
              id: "6Qj01PCY1lfLasd74HFgTxpcDRt1",
              name: "Faisal",
            },
          ],
        },
        type: "carpool_passenger",
        corporate: "9VZwCrYZfl03ob5FrD4F",
      },
    },
    skin: null,
    car: {
      seat: 3,
      co2_p_km: 0.3379843740994449,
      year: 2021,
      make: "Toyota",
      type: "carbon-emitting",
      model: "LAND CRUSER WAGON 4WD",
    },
    owner: "C1GECLApV4W0bVtgeFk4iRpZYRl1",
    status: "completed",
    id: "8292058029514",
    origin: {
      geohash: "thkxgdt7m0",
      address: "858, الدوحة، Qatar",
      coordinates: { longitude: 51.489361, latitude: 25.282848 },
    },
    destination: {
      coordinates: { latitude: 25.213204, longitude: 51.43657469999999 },
      geohash: "thkx6myvx1",
      type: "corporate-branch",
      address: "Street 8, Doha, Qatar",
      id: "9VZwCrYZfl03ob5FrD4F.0",
    },
    credits: 0,
    createdAt: 1707941970485,
    co2Amount: 12.612562888268984,
    autoUpdateTime: 0,
    ownerRating: "",
    type: "carpool",
    rules: ["No smoking", "No eating or drinking", "Keep off the AC"],
    preferences: {
      female_passengers_only: null,
      verified_passengers_only: null,
    },
    completedAt: 1707942203548,
  },
  {
    raw: {
      id: "8297410931707",
      directions: {
        geocoded_waypoints: [
          {
            geocoder_status: "OK",
            place_id: "ChIJq3xQuZYzGQ0RcQsgGU0xD54",
            types: ["premise"],
          },
          {
            place_id: "ChIJe2N7vJYzGQ0RR9iXjvxvpbc",
            types: ["street_address"],
            geocoder_status: "OK",
          },
          {
            geocoder_status: "OK",
            place_id: "ChIJkz-Ae6AzGQ0RdB9kIWyzb00",
            types: ["establishment", "point_of_interest", "real_estate_agency"],
          },
        ],
        status: "OK",
        routes: [
          {
            copyrights: "Map data ©2023 Google",
            overview_polyline: {
              points:
                "krkkFnevv@?s@JcABc@Is@CQIBU?SCWIYxBo@zEM`A[@yEL]rBi@bDW~AQdAKb@WbB]rAbA?dAF`CHhCLfADZBCp@GxBKrBIdBAT]bJY|HMxBE`@C@EFER@TLRFDD?AVEHcA|B}@tBe@jAi@dA{AzCi@bAMTEAMEMCW?OBURQZGh@gEt@cALUOKC}@J",
            },
            warnings: [],
            waypoint_order: [0],
            summary: "R. Neves Ferreira",
            bounds: {
              southwest: { lng: -9.1451804, lat: 38.7307037 },
              northeast: { lat: 38.7359366, lng: -9.128815399999999 },
            },
            legs: [
              {
                traffic_speed_entry: [],
                distance: { value: 14, text: "14 m" },
                duration: { value: 4, text: "1 min" },
                steps: [
                  {
                    travel_mode: "DRIVING",
                    duration: { value: 4, text: "1 min" },
                    end_location: { lng: -9.1298339, lat: 38.7307811 },
                    html_instructions:
                      "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                    start_location: { lat: 38.7307779, lng: -9.1299961 },
                    polyline: { points: "krkkFnevv@?]?C" },
                    distance: { value: 14, text: "14 m" },
                  },
                ],
                start_address:
                  "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                end_address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                start_location: { lat: 38.7307779, lng: -9.1299961 },
                end_location: { lat: 38.7307811, lng: -9.1298339 },
                via_waypoint: [],
              },
              {
                end_location: { lat: 38.7359366, lng: -9.1451447 },
                duration: { value: 577, text: "10 mins" },
                via_waypoint: [],
                distance: { text: "2.3 km", value: 2281 },
                start_location: { lat: 38.7307811, lng: -9.1298339 },
                start_address:
                  "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                end_address: "Av. da República 18, 1050-191 Lisboa, Portugal",
                traffic_speed_entry: [],
                steps: [
                  {
                    end_location: { lng: -9.1288743, lat: 38.7307747 },
                    distance: { value: 85, text: "85 m" },
                    start_location: { lng: -9.1298339, lat: 38.7307811 },
                    travel_mode: "DRIVING",
                    polyline: { points: "krkkFldvv@?K?EBO@EBU@W@U@MAIGi@CQ" },
                    duration: { value: 24, text: "1 min" },
                    html_instructions:
                      "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                  },
                  {
                    maneuver: "turn-left",
                    duration: { value: 17, text: "1 min" },
                    polyline: { points: "irkkFl~uv@IBI?K?IAIAKEKC" },
                    travel_mode: "DRIVING",
                    html_instructions:
                      "Turn <b>left</b> onto <b>R. Dr. Lacerda e Almeida</b>",
                    start_location: { lat: 38.7307747, lng: -9.1288743 },
                    end_location: { lng: -9.128815399999999, lat: 38.7311533 },
                    distance: { text: "43 m", value: 43 },
                  },
                  {
                    duration: { value: 41, text: "1 min" },
                    end_location: { lat: 38.7315862, lng: -9.130856399999999 },
                    polyline: {
                      points: "utkkFb~uv@If@CTG\\C\\CNM`AG\\UjBIn@CP",
                    },
                    travel_mode: "DRIVING",
                    distance: { value: 183, text: "0.2 km" },
                    start_location: {
                      lng: -9.128815399999999,
                      lat: 38.7311533,
                    },
                    html_instructions:
                      "Turn <b>left</b> onto <b>R. Jacinto Nunes</b>",
                    maneuver: "turn-left",
                  },
                  {
                    duration: { value: 42, text: "1 min" },
                    start_location: {
                      lat: 38.7315862,
                      lng: -9.130856399999999,
                    },
                    distance: { value: 138, text: "0.1 km" },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Calçada Poço dos Mouros</b>",
                    polyline: { points: "mwkkFzjvv@G?S@S?]?Y@mCJ" },
                    travel_mode: "DRIVING",
                    maneuver: "turn-right",
                    end_location: {
                      lng: -9.130940700000002,
                      lat: 38.73282380000001,
                    },
                  },
                  {
                    polyline: {
                      points:
                        "c_lkFjkvv@If@CRI`@ETADIf@EXEVADETCRERIh@CNId@Kl@EVGPCPGZOfA",
                    },
                    maneuver: "turn-left",
                    travel_mode: "DRIVING",
                    end_location: { lat: 38.733574, lng: -9.1338505 },
                    duration: { text: "1 min", value: 81 },
                    html_instructions:
                      "Turn <b>left</b> onto <b>R. Morais Soares</b>",
                    start_location: {
                      lng: -9.130940700000002,
                      lat: 38.73282380000001,
                    },
                    distance: { value: 266, text: "0.3 km" },
                  },
                  {
                    travel_mode: "DRIVING",
                    polyline: { points: "yclkFp}vv@Sx@" },
                    html_instructions:
                      "Continue straight to stay on <b>R. Morais Soares</b>",
                    maneuver: "straight",
                    duration: { text: "1 min", value: 13 },
                    distance: { text: "27 m", value: 27 },
                    start_location: { lng: -9.1338505, lat: 38.733574 },
                    end_location: { lat: 38.7336686, lng: -9.134135299999999 },
                  },
                  {
                    html_instructions:
                      "Turn <b>left</b> onto <b>Av. Alm. Reis</b>",
                    start_location: {
                      lat: 38.7336686,
                      lng: -9.134135299999999,
                    },
                    distance: { value: 294, text: "0.3 km" },
                    maneuver: "turn-left",
                    polyline: {
                      points:
                        "mdlkFj_wv@IXP?p@?T@n@DT@d@?F@|@DX@d@Bd@Bb@B^@f@BZB",
                    },
                    end_location: { lat: 38.7311914, lng: -9.134476099999999 },
                    travel_mode: "DRIVING",
                    duration: { text: "1 min", value: 74 },
                  },
                  {
                    start_location: {
                      lat: 38.7311914,
                      lng: -9.134476099999999,
                    },
                    distance: { value: 542, text: "0.5 km" },
                    polyline: {
                      points:
                        "}tkkFnawv@Cp@Ct@CbAGfACj@CZEv@?J?D?@A@?H?FAF?FARCh@Cz@ElACbAGfAEpAG|AEz@EpACf@Ch@E\\?HC^",
                    },
                    duration: { value: 96, text: "2 mins" },
                    html_instructions:
                      "Turn <b>right</b> onto <b>R. Pascoal de Melo</b>",
                    travel_mode: "DRIVING",
                    maneuver: "turn-right",
                    end_location: { lng: -9.140677499999999, lat: 38.7317382 },
                  },
                  {
                    html_instructions:
                      "At the roundabout, take the <b>2nd</b> exit onto <b>Av. Casal Ribeiro</b>",
                    polyline: {
                      points:
                        "kxkkFfhxv@A@A@A?A@?@A@A@?@A@?@?@A@?@AB?B?@?B?@?B?@@B?@?@@D@@@B?@@@@@@@@?@@@@@?@@@?B?AVEHs@~AO\\k@nAQd@Uj@O^e@`ACBUd@Q`@KPg@`Ag@|@ADMT",
                    },
                    travel_mode: "DRIVING",
                    duration: { text: "2 mins", value: 100 },
                    distance: { value: 404, text: "0.4 km" },
                    start_location: {
                      lng: -9.140677499999999,
                      lat: 38.7317382,
                    },
                    end_location: { lng: -9.1444337, lat: 38.7335126 },
                    maneuver: "roundabout-right",
                  },
                  {
                    distance: { text: "92 m", value: 92 },
                    end_location: { lng: -9.1448416, lat: 38.734123 },
                    travel_mode: "DRIVING",
                    start_location: { lat: 38.7335126, lng: -9.1444337 },
                    maneuver: "turn-right",
                    polyline: {
                      points:
                        "mclkFt_yv@EAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?BAF?B?D",
                    },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                    duration: { text: "1 min", value: 36 },
                  },
                  {
                    polyline: { points: "gglkFfbyv@[FWDqCf@A?cAL" },
                    distance: { value: 152, text: "0.2 km" },
                    travel_mode: "DRIVING",
                    end_location: { lng: -9.1451804, lat: 38.7354649 },
                    html_instructions:
                      "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b>",
                    start_location: { lng: -9.1448416, lat: 38.734123 },
                    duration: { text: "1 min", value: 38 },
                    maneuver: "turn-right",
                  },
                  {
                    maneuver: "turn-right",
                    html_instructions:
                      'Turn <b>right</b> to stay on <b>Av. da República</b>/<wbr/><b>Av. República</b><div style="font-size:0.9em">Destination will be on the right</div>',
                    end_location: { lng: -9.1451447, lat: 38.7359366 },
                    polyline: { points: "solkFjdyv@IGKGEAA?AAA?SBi@F" },
                    start_location: { lng: -9.1451804, lat: 38.7354649 },
                    distance: { text: "55 m", value: 55 },
                    travel_mode: "DRIVING",
                    duration: { value: 15, text: "1 min" },
                  },
                ],
              },
            ],
          },
        ],
      },
      passengers: {
        hATZMj8GpVZUMPZRs4FogRpnWDZ2: {
          userId: "user_id",
          name: "Nik",
          username: "nikhil",
          location: {
            address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
            geohash: "eycs24xtzc",
            coordinates: { latitude: 38.7305463, longitude: -9.1299888 },
          },
          credits: 18,
          co2Amount: 0.4214863588047714,
          avatar:
            "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_jumper_brown_Avatarz_brown464.png",
          seat: 0,
          id: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
          wallet_id: null,
          carpool: {
            id: "8297410931707",
            directions: {
              geocoded_waypoints: [
                {
                  geocoder_status: "OK",
                  place_id: "ChIJq3xQuZYzGQ0RcQsgGU0xD54",
                  types: ["premise"],
                },
                {
                  place_id: "ChIJe2N7vJYzGQ0RR9iXjvxvpbc",
                  types: ["street_address"],
                  geocoder_status: "OK",
                },
                {
                  geocoder_status: "OK",
                  place_id: "ChIJkz-Ae6AzGQ0RdB9kIWyzb00",
                  types: [
                    "establishment",
                    "point_of_interest",
                    "real_estate_agency",
                  ],
                },
              ],
              status: "OK",
              routes: [
                {
                  copyrights: "Map data ©2023 Google",
                  overview_polyline: {
                    points:
                      "krkkFnevv@?s@JcABc@Is@CQIBU?SCWIYxBo@zEM`A[@yEL]rBi@bDW~AQdAKb@WbB]rAbA?dAF`CHhCLfADZBCp@GxBKrBIdBAT]bJY|HMxBE`@C@EFER@TLRFDD?AVEHcA|B}@tBe@jAi@dA{AzCi@bAMTEAMEMCW?OBURQZGh@gEt@cALUOKC}@J",
                  },
                  warnings: [],
                  waypoint_order: [0],
                  summary: "R. Neves Ferreira",
                  bounds: {
                    southwest: { lng: -9.1451804, lat: 38.7307037 },
                    northeast: { lat: 38.7359366, lng: -9.128815399999999 },
                  },
                  legs: [
                    {
                      traffic_speed_entry: [],
                      distance: { value: 14, text: "14 m" },
                      duration: { value: 4, text: "1 min" },
                      steps: [
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 4, text: "1 min" },
                          end_location: { lng: -9.1298339, lat: 38.7307811 },
                          html_instructions:
                            "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                          start_location: { lat: 38.7307779, lng: -9.1299961 },
                          polyline: { points: "krkkFnevv@?]?C" },
                          distance: { value: 14, text: "14 m" },
                        },
                      ],
                      start_address:
                        "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                      end_address:
                        "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                      start_location: { lat: 38.7307779, lng: -9.1299961 },
                      end_location: { lat: 38.7307811, lng: -9.1298339 },
                      via_waypoint: [],
                    },
                    {
                      end_location: { lat: 38.7359366, lng: -9.1451447 },
                      duration: { value: 577, text: "10 mins" },
                      via_waypoint: [],
                      distance: { text: "2.3 km", value: 2281 },
                      start_location: { lat: 38.7307811, lng: -9.1298339 },
                      start_address:
                        "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                      end_address:
                        "Av. da República 18, 1050-191 Lisboa, Portugal",
                      traffic_speed_entry: [],
                      steps: [
                        {
                          end_location: { lng: -9.1288743, lat: 38.7307747 },
                          distance: { value: 85, text: "85 m" },
                          start_location: { lng: -9.1298339, lat: 38.7307811 },
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "krkkFldvv@?K?EBO@EBU@W@U@MAIGi@CQ",
                          },
                          duration: { value: 24, text: "1 min" },
                          html_instructions:
                            "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                        },
                        {
                          maneuver: "turn-left",
                          duration: { value: 17, text: "1 min" },
                          polyline: { points: "irkkFl~uv@IBI?K?IAIAKEKC" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>left</b> onto <b>R. Dr. Lacerda e Almeida</b>",
                          start_location: { lat: 38.7307747, lng: -9.1288743 },
                          end_location: {
                            lng: -9.128815399999999,
                            lat: 38.7311533,
                          },
                          distance: { text: "43 m", value: 43 },
                        },
                        {
                          duration: { value: 41, text: "1 min" },
                          end_location: {
                            lat: 38.7315862,
                            lng: -9.130856399999999,
                          },
                          polyline: {
                            points: "utkkFb~uv@If@CTG\\C\\CNM`AG\\UjBIn@CP",
                          },
                          travel_mode: "DRIVING",
                          distance: { value: 183, text: "0.2 km" },
                          start_location: {
                            lng: -9.128815399999999,
                            lat: 38.7311533,
                          },
                          html_instructions:
                            "Turn <b>left</b> onto <b>R. Jacinto Nunes</b>",
                          maneuver: "turn-left",
                        },
                        {
                          duration: { value: 42, text: "1 min" },
                          start_location: {
                            lat: 38.7315862,
                            lng: -9.130856399999999,
                          },
                          distance: { value: 138, text: "0.1 km" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Calçada Poço dos Mouros</b>",
                          polyline: { points: "mwkkFzjvv@G?S@S?]?Y@mCJ" },
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          end_location: {
                            lng: -9.130940700000002,
                            lat: 38.73282380000001,
                          },
                        },
                        {
                          polyline: {
                            points:
                              "c_lkFjkvv@If@CRI`@ETADIf@EXEVADETCRERIh@CNId@Kl@EVGPCPGZOfA",
                          },
                          maneuver: "turn-left",
                          travel_mode: "DRIVING",
                          end_location: { lat: 38.733574, lng: -9.1338505 },
                          duration: { text: "1 min", value: 81 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>R. Morais Soares</b>",
                          start_location: {
                            lng: -9.130940700000002,
                            lat: 38.73282380000001,
                          },
                          distance: { value: 266, text: "0.3 km" },
                        },
                        {
                          travel_mode: "DRIVING",
                          polyline: { points: "yclkFp}vv@Sx@" },
                          html_instructions:
                            "Continue straight to stay on <b>R. Morais Soares</b>",
                          maneuver: "straight",
                          duration: { text: "1 min", value: 13 },
                          distance: { text: "27 m", value: 27 },
                          start_location: { lng: -9.1338505, lat: 38.733574 },
                          end_location: {
                            lat: 38.7336686,
                            lng: -9.134135299999999,
                          },
                        },
                        {
                          html_instructions:
                            "Turn <b>left</b> onto <b>Av. Alm. Reis</b>",
                          start_location: {
                            lat: 38.7336686,
                            lng: -9.134135299999999,
                          },
                          distance: { value: 294, text: "0.3 km" },
                          maneuver: "turn-left",
                          polyline: {
                            points:
                              "mdlkFj_wv@IXP?p@?T@n@DT@d@?F@|@DX@d@Bd@Bb@B^@f@BZB",
                          },
                          end_location: {
                            lat: 38.7311914,
                            lng: -9.134476099999999,
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 74 },
                        },
                        {
                          start_location: {
                            lat: 38.7311914,
                            lng: -9.134476099999999,
                          },
                          distance: { value: 542, text: "0.5 km" },
                          polyline: {
                            points:
                              "}tkkFnawv@Cp@Ct@CbAGfACj@CZEv@?J?D?@A@?H?FAF?FARCh@Cz@ElACbAGfAEpAG|AEz@EpACf@Ch@E\\?HC^",
                          },
                          duration: { value: 96, text: "2 mins" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>R. Pascoal de Melo</b>",
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          end_location: {
                            lng: -9.140677499999999,
                            lat: 38.7317382,
                          },
                        },
                        {
                          html_instructions:
                            "At the roundabout, take the <b>2nd</b> exit onto <b>Av. Casal Ribeiro</b>",
                          polyline: {
                            points:
                              "kxkkFfhxv@A@A@A?A@?@A@A@?@A@?@?@A@?@AB?B?@?B?@?B?@@B?@?@@D@@@B?@@@@@@@@?@@@@@?@@@?B?AVEHs@~AO\\k@nAQd@Uj@O^e@`ACBUd@Q`@KPg@`Ag@|@ADMT",
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "2 mins", value: 100 },
                          distance: { value: 404, text: "0.4 km" },
                          start_location: {
                            lng: -9.140677499999999,
                            lat: 38.7317382,
                          },
                          end_location: { lng: -9.1444337, lat: 38.7335126 },
                          maneuver: "roundabout-right",
                        },
                        {
                          distance: { text: "92 m", value: 92 },
                          end_location: { lng: -9.1448416, lat: 38.734123 },
                          travel_mode: "DRIVING",
                          start_location: { lat: 38.7335126, lng: -9.1444337 },
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "mclkFt_yv@EAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?BAF?B?D",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                          duration: { text: "1 min", value: 36 },
                        },
                        {
                          polyline: { points: "gglkFfbyv@[FWDqCf@A?cAL" },
                          distance: { value: 152, text: "0.2 km" },
                          travel_mode: "DRIVING",
                          end_location: { lng: -9.1451804, lat: 38.7354649 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b>",
                          start_location: { lng: -9.1448416, lat: 38.734123 },
                          duration: { text: "1 min", value: 38 },
                          maneuver: "turn-right",
                        },
                        {
                          maneuver: "turn-right",
                          html_instructions:
                            'Turn <b>right</b> to stay on <b>Av. da República</b>/<wbr/><b>Av. República</b><div style="font-size:0.9em">Destination will be on the right</div>',
                          end_location: { lng: -9.1451447, lat: 38.7359366 },
                          polyline: { points: "solkFjdyv@IGKGEAA?AAA?SBi@F" },
                          start_location: { lng: -9.1451804, lat: 38.7354649 },
                          distance: { text: "55 m", value: 55 },
                          travel_mode: "DRIVING",
                          duration: { value: 15, text: "1 min" },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            rules: [],
            destination: {
              geohash: "eyckrgsv30",
              type: "corporate-branch",
              coordinates: { longitude: -9.145036, latitude: 38.7359506 },
              address: "Av. da República 18, 1050-191 Lisboa, Portugal",
              id: "NrSFyTBixEIf1JmBf05T.0",
            },
            origin: {
              geohash: "eycs24xtzc",
              address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
              coordinates: { longitude: -9.1299888, latitude: 38.7305463 },
            },
            car: {
              co2_p_km: 0.18365418684303766,
              year: 2010,
              make: "Audi",
              seat: 3,
              model: "A3",
              type: "carbon-emitting",
            },
            owner: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
            preferences: {
              verified_passengers_only: null,
              female_passengers_only: null,
            },
            status: "completed",
            co2Amount: 0.8404015589937404,
            createdAt: 1702589068293,
            completedAt: 1702592855766,
            skin: null,
            passengersArray: [
              {
                corporate: null,
                location: {
                  address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                  geohash: "eycs24xtzc",
                  coordinates: { latitude: 38.7305463, longitude: -9.1299888 },
                },
                co2Amount: 0.4214863588047714,
                name: "Nik",
                seat: 0,
                wallet_id: null,
                username: "nikhil",
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_jumper_brown_Avatarz_brown464.png",
                credits: 18,
                id: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
              },
              {
                corporate: null,
                username: null,
                location: {
                  coordinates: {
                    longitude: -9.129832556674573,
                    latitude: 38.730743408203125,
                  },
                  address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                  geohash: "eycs24xygm",
                },
                wallet_id: null,
                avatar: null,
                status: "picked-up",
                id: "ATscMiDcscTqZitr120PAhorx5D3",
                credits: 9,
                name: "F. Taveira",
                seat: 3,
                co2Amount: 0.41891520018896894,
              },
            ],
          },
          type: "carpool_passenger",
          corporate: null,
        },
        ATscMiDcscTqZitr120PAhorx5D3: {
          userId: "user_id",
          name: "F. Taveira",
          username: null,
          location: {
            coordinates: {
              longitude: -9.129832556674573,
              latitude: 38.730743408203125,
            },
            address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
            geohash: "eycs24xygm",
          },
          credits: 9,
          co2Amount: 0.41891520018896894,
          avatar: null,
          seat: 3,
          id: "ATscMiDcscTqZitr120PAhorx5D3",
          wallet_id: null,
          status: "picked-up",
          carpool: {
            id: "8297410931707",
            directions: {
              geocoded_waypoints: [
                {
                  geocoder_status: "OK",
                  place_id: "ChIJq3xQuZYzGQ0RcQsgGU0xD54",
                  types: ["premise"],
                },
                {
                  place_id: "ChIJe2N7vJYzGQ0RR9iXjvxvpbc",
                  types: ["street_address"],
                  geocoder_status: "OK",
                },
                {
                  geocoder_status: "OK",
                  place_id: "ChIJkz-Ae6AzGQ0RdB9kIWyzb00",
                  types: [
                    "establishment",
                    "point_of_interest",
                    "real_estate_agency",
                  ],
                },
              ],
              status: "OK",
              routes: [
                {
                  copyrights: "Map data ©2023 Google",
                  overview_polyline: {
                    points:
                      "krkkFnevv@?s@JcABc@Is@CQIBU?SCWIYxBo@zEM`A[@yEL]rBi@bDW~AQdAKb@WbB]rAbA?dAF`CHhCLfADZBCp@GxBKrBIdBAT]bJY|HMxBE`@C@EFER@TLRFDD?AVEHcA|B}@tBe@jAi@dA{AzCi@bAMTEAMEMCW?OBURQZGh@gEt@cALUOKC}@J",
                  },
                  warnings: [],
                  waypoint_order: [0],
                  summary: "R. Neves Ferreira",
                  bounds: {
                    southwest: { lng: -9.1451804, lat: 38.7307037 },
                    northeast: { lat: 38.7359366, lng: -9.128815399999999 },
                  },
                  legs: [
                    {
                      traffic_speed_entry: [],
                      distance: { value: 14, text: "14 m" },
                      duration: { value: 4, text: "1 min" },
                      steps: [
                        {
                          travel_mode: "DRIVING",
                          duration: { value: 4, text: "1 min" },
                          end_location: { lng: -9.1298339, lat: 38.7307811 },
                          html_instructions:
                            "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                          start_location: { lat: 38.7307779, lng: -9.1299961 },
                          polyline: { points: "krkkFnevv@?]?C" },
                          distance: { value: 14, text: "14 m" },
                        },
                      ],
                      start_address:
                        "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                      end_address:
                        "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                      start_location: { lat: 38.7307779, lng: -9.1299961 },
                      end_location: { lat: 38.7307811, lng: -9.1298339 },
                      via_waypoint: [],
                    },
                    {
                      end_location: { lat: 38.7359366, lng: -9.1451447 },
                      duration: { value: 577, text: "10 mins" },
                      via_waypoint: [],
                      distance: { text: "2.3 km", value: 2281 },
                      start_location: { lat: 38.7307811, lng: -9.1298339 },
                      start_address:
                        "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                      end_address:
                        "Av. da República 18, 1050-191 Lisboa, Portugal",
                      traffic_speed_entry: [],
                      steps: [
                        {
                          end_location: { lng: -9.1288743, lat: 38.7307747 },
                          distance: { value: 85, text: "85 m" },
                          start_location: { lng: -9.1298339, lat: 38.7307811 },
                          travel_mode: "DRIVING",
                          polyline: {
                            points: "krkkFldvv@?K?EBO@EBU@W@U@MAIGi@CQ",
                          },
                          duration: { value: 24, text: "1 min" },
                          html_instructions:
                            "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                        },
                        {
                          maneuver: "turn-left",
                          duration: { value: 17, text: "1 min" },
                          polyline: { points: "irkkFl~uv@IBI?K?IAIAKEKC" },
                          travel_mode: "DRIVING",
                          html_instructions:
                            "Turn <b>left</b> onto <b>R. Dr. Lacerda e Almeida</b>",
                          start_location: { lat: 38.7307747, lng: -9.1288743 },
                          end_location: {
                            lng: -9.128815399999999,
                            lat: 38.7311533,
                          },
                          distance: { text: "43 m", value: 43 },
                        },
                        {
                          duration: { value: 41, text: "1 min" },
                          end_location: {
                            lat: 38.7315862,
                            lng: -9.130856399999999,
                          },
                          polyline: {
                            points: "utkkFb~uv@If@CTG\\C\\CNM`AG\\UjBIn@CP",
                          },
                          travel_mode: "DRIVING",
                          distance: { value: 183, text: "0.2 km" },
                          start_location: {
                            lng: -9.128815399999999,
                            lat: 38.7311533,
                          },
                          html_instructions:
                            "Turn <b>left</b> onto <b>R. Jacinto Nunes</b>",
                          maneuver: "turn-left",
                        },
                        {
                          duration: { value: 42, text: "1 min" },
                          start_location: {
                            lat: 38.7315862,
                            lng: -9.130856399999999,
                          },
                          distance: { value: 138, text: "0.1 km" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Calçada Poço dos Mouros</b>",
                          polyline: { points: "mwkkFzjvv@G?S@S?]?Y@mCJ" },
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          end_location: {
                            lng: -9.130940700000002,
                            lat: 38.73282380000001,
                          },
                        },
                        {
                          polyline: {
                            points:
                              "c_lkFjkvv@If@CRI`@ETADIf@EXEVADETCRERIh@CNId@Kl@EVGPCPGZOfA",
                          },
                          maneuver: "turn-left",
                          travel_mode: "DRIVING",
                          end_location: { lat: 38.733574, lng: -9.1338505 },
                          duration: { text: "1 min", value: 81 },
                          html_instructions:
                            "Turn <b>left</b> onto <b>R. Morais Soares</b>",
                          start_location: {
                            lng: -9.130940700000002,
                            lat: 38.73282380000001,
                          },
                          distance: { value: 266, text: "0.3 km" },
                        },
                        {
                          travel_mode: "DRIVING",
                          polyline: { points: "yclkFp}vv@Sx@" },
                          html_instructions:
                            "Continue straight to stay on <b>R. Morais Soares</b>",
                          maneuver: "straight",
                          duration: { text: "1 min", value: 13 },
                          distance: { text: "27 m", value: 27 },
                          start_location: { lng: -9.1338505, lat: 38.733574 },
                          end_location: {
                            lat: 38.7336686,
                            lng: -9.134135299999999,
                          },
                        },
                        {
                          html_instructions:
                            "Turn <b>left</b> onto <b>Av. Alm. Reis</b>",
                          start_location: {
                            lat: 38.7336686,
                            lng: -9.134135299999999,
                          },
                          distance: { value: 294, text: "0.3 km" },
                          maneuver: "turn-left",
                          polyline: {
                            points:
                              "mdlkFj_wv@IXP?p@?T@n@DT@d@?F@|@DX@d@Bd@Bb@B^@f@BZB",
                          },
                          end_location: {
                            lat: 38.7311914,
                            lng: -9.134476099999999,
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "1 min", value: 74 },
                        },
                        {
                          start_location: {
                            lat: 38.7311914,
                            lng: -9.134476099999999,
                          },
                          distance: { value: 542, text: "0.5 km" },
                          polyline: {
                            points:
                              "}tkkFnawv@Cp@Ct@CbAGfACj@CZEv@?J?D?@A@?H?FAF?FARCh@Cz@ElACbAGfAEpAG|AEz@EpACf@Ch@E\\?HC^",
                          },
                          duration: { value: 96, text: "2 mins" },
                          html_instructions:
                            "Turn <b>right</b> onto <b>R. Pascoal de Melo</b>",
                          travel_mode: "DRIVING",
                          maneuver: "turn-right",
                          end_location: {
                            lng: -9.140677499999999,
                            lat: 38.7317382,
                          },
                        },
                        {
                          html_instructions:
                            "At the roundabout, take the <b>2nd</b> exit onto <b>Av. Casal Ribeiro</b>",
                          polyline: {
                            points:
                              "kxkkFfhxv@A@A@A?A@?@A@A@?@A@?@?@A@?@AB?B?@?B?@?B?@@B?@?@@D@@@B?@@@@@@@@?@@@@@?@@@?B?AVEHs@~AO\\k@nAQd@Uj@O^e@`ACBUd@Q`@KPg@`Ag@|@ADMT",
                          },
                          travel_mode: "DRIVING",
                          duration: { text: "2 mins", value: 100 },
                          distance: { value: 404, text: "0.4 km" },
                          start_location: {
                            lng: -9.140677499999999,
                            lat: 38.7317382,
                          },
                          end_location: { lng: -9.1444337, lat: 38.7335126 },
                          maneuver: "roundabout-right",
                        },
                        {
                          distance: { text: "92 m", value: 92 },
                          end_location: { lng: -9.1448416, lat: 38.734123 },
                          travel_mode: "DRIVING",
                          start_location: { lat: 38.7335126, lng: -9.1444337 },
                          maneuver: "turn-right",
                          polyline: {
                            points:
                              "mclkFt_yv@EAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?BAF?B?D",
                          },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                          duration: { text: "1 min", value: 36 },
                        },
                        {
                          polyline: { points: "gglkFfbyv@[FWDqCf@A?cAL" },
                          distance: { value: 152, text: "0.2 km" },
                          travel_mode: "DRIVING",
                          end_location: { lng: -9.1451804, lat: 38.7354649 },
                          html_instructions:
                            "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b>",
                          start_location: { lng: -9.1448416, lat: 38.734123 },
                          duration: { text: "1 min", value: 38 },
                          maneuver: "turn-right",
                        },
                        {
                          maneuver: "turn-right",
                          html_instructions:
                            'Turn <b>right</b> to stay on <b>Av. da República</b>/<wbr/><b>Av. República</b><div style="font-size:0.9em">Destination will be on the right</div>',
                          end_location: { lng: -9.1451447, lat: 38.7359366 },
                          polyline: { points: "solkFjdyv@IGKGEAA?AAA?SBi@F" },
                          start_location: { lng: -9.1451804, lat: 38.7354649 },
                          distance: { text: "55 m", value: 55 },
                          travel_mode: "DRIVING",
                          duration: { value: 15, text: "1 min" },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            rules: [],
            destination: {
              geohash: "eyckrgsv30",
              type: "corporate-branch",
              coordinates: { longitude: -9.145036, latitude: 38.7359506 },
              address: "Av. da República 18, 1050-191 Lisboa, Portugal",
              id: "NrSFyTBixEIf1JmBf05T.0",
            },
            origin: {
              geohash: "eycs24xtzc",
              address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
              coordinates: { longitude: -9.1299888, latitude: 38.7305463 },
            },
            car: {
              co2_p_km: 0.18365418684303766,
              year: 2010,
              make: "Audi",
              seat: 3,
              model: "A3",
              type: "carbon-emitting",
            },
            owner: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
            preferences: {
              verified_passengers_only: null,
              female_passengers_only: null,
            },
            status: "completed",
            co2Amount: 0.8404015589937404,
            createdAt: 1702589068293,
            completedAt: 1702592855766,
            skin: null,
            passengersArray: [
              {
                userId: "user_id",
                name: "Nik",
                username: "nikhil",
                location: {
                  address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                  geohash: "eycs24xtzc",
                  coordinates: { latitude: 38.7305463, longitude: -9.1299888 },
                },
                credits: 18,
                co2Amount: 0.4214863588047714,
                avatar:
                  "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_jumper_brown_Avatarz_brown464.png",
                seat: 0,
                id: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
                wallet_id: null,
                carpool: {
                  id: "8297410931707",
                  directions: {
                    geocoded_waypoints: [
                      {
                        geocoder_status: "OK",
                        place_id: "ChIJq3xQuZYzGQ0RcQsgGU0xD54",
                        types: ["premise"],
                      },
                      {
                        place_id: "ChIJe2N7vJYzGQ0RR9iXjvxvpbc",
                        types: ["street_address"],
                        geocoder_status: "OK",
                      },
                      {
                        geocoder_status: "OK",
                        place_id: "ChIJkz-Ae6AzGQ0RdB9kIWyzb00",
                        types: [
                          "establishment",
                          "point_of_interest",
                          "real_estate_agency",
                        ],
                      },
                    ],
                    status: "OK",
                    routes: [
                      {
                        copyrights: "Map data ©2023 Google",
                        overview_polyline: {
                          points:
                            "krkkFnevv@?s@JcABc@Is@CQIBU?SCWIYxBo@zEM`A[@yEL]rBi@bDW~AQdAKb@WbB]rAbA?dAF`CHhCLfADZBCp@GxBKrBIdBAT]bJY|HMxBE`@C@EFER@TLRFDD?AVEHcA|B}@tBe@jAi@dA{AzCi@bAMTEAMEMCW?OBURQZGh@gEt@cALUOKC}@J",
                        },
                        warnings: [],
                        waypoint_order: [0],
                        summary: "R. Neves Ferreira",
                        bounds: {
                          southwest: { lng: -9.1451804, lat: 38.7307037 },
                          northeast: {
                            lat: 38.7359366,
                            lng: -9.128815399999999,
                          },
                        },
                        legs: [
                          {
                            traffic_speed_entry: [],
                            distance: { value: 14, text: "14 m" },
                            duration: { value: 4, text: "1 min" },
                            steps: [
                              {
                                travel_mode: "DRIVING",
                                duration: { value: 4, text: "1 min" },
                                end_location: {
                                  lng: -9.1298339,
                                  lat: 38.7307811,
                                },
                                html_instructions:
                                  "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                                start_location: {
                                  lat: 38.7307779,
                                  lng: -9.1299961,
                                },
                                polyline: { points: "krkkFnevv@?]?C" },
                                distance: { value: 14, text: "14 m" },
                              },
                            ],
                            start_address:
                              "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                            end_address:
                              "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                            start_location: {
                              lat: 38.7307779,
                              lng: -9.1299961,
                            },
                            end_location: { lat: 38.7307811, lng: -9.1298339 },
                            via_waypoint: [],
                          },
                          {
                            end_location: { lat: 38.7359366, lng: -9.1451447 },
                            duration: { value: 577, text: "10 mins" },
                            via_waypoint: [],
                            distance: { text: "2.3 km", value: 2281 },
                            start_location: {
                              lat: 38.7307811,
                              lng: -9.1298339,
                            },
                            start_address:
                              "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                            end_address:
                              "Av. da República 18, 1050-191 Lisboa, Portugal",
                            traffic_speed_entry: [],
                            steps: [
                              {
                                end_location: {
                                  lng: -9.1288743,
                                  lat: 38.7307747,
                                },
                                distance: { value: 85, text: "85 m" },
                                start_location: {
                                  lng: -9.1298339,
                                  lat: 38.7307811,
                                },
                                travel_mode: "DRIVING",
                                polyline: {
                                  points: "krkkFldvv@?K?EBO@EBU@W@U@MAIGi@CQ",
                                },
                                duration: { value: 24, text: "1 min" },
                                html_instructions:
                                  "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                              },
                              {
                                maneuver: "turn-left",
                                duration: { value: 17, text: "1 min" },
                                polyline: {
                                  points: "irkkFl~uv@IBI?K?IAIAKEKC",
                                },
                                travel_mode: "DRIVING",
                                html_instructions:
                                  "Turn <b>left</b> onto <b>R. Dr. Lacerda e Almeida</b>",
                                start_location: {
                                  lat: 38.7307747,
                                  lng: -9.1288743,
                                },
                                end_location: {
                                  lng: -9.128815399999999,
                                  lat: 38.7311533,
                                },
                                distance: { text: "43 m", value: 43 },
                              },
                              {
                                duration: { value: 41, text: "1 min" },
                                end_location: {
                                  lat: 38.7315862,
                                  lng: -9.130856399999999,
                                },
                                polyline: {
                                  points:
                                    "utkkFb~uv@If@CTG\\C\\CNM`AG\\UjBIn@CP",
                                },
                                travel_mode: "DRIVING",
                                distance: { value: 183, text: "0.2 km" },
                                start_location: {
                                  lng: -9.128815399999999,
                                  lat: 38.7311533,
                                },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>R. Jacinto Nunes</b>",
                                maneuver: "turn-left",
                              },
                              {
                                duration: { value: 42, text: "1 min" },
                                start_location: {
                                  lat: 38.7315862,
                                  lng: -9.130856399999999,
                                },
                                distance: { value: 138, text: "0.1 km" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Calçada Poço dos Mouros</b>",
                                polyline: { points: "mwkkFzjvv@G?S@S?]?Y@mCJ" },
                                travel_mode: "DRIVING",
                                maneuver: "turn-right",
                                end_location: {
                                  lng: -9.130940700000002,
                                  lat: 38.73282380000001,
                                },
                              },
                              {
                                polyline: {
                                  points:
                                    "c_lkFjkvv@If@CRI`@ETADIf@EXEVADETCRERIh@CNId@Kl@EVGPCPGZOfA",
                                },
                                maneuver: "turn-left",
                                travel_mode: "DRIVING",
                                end_location: {
                                  lat: 38.733574,
                                  lng: -9.1338505,
                                },
                                duration: { text: "1 min", value: 81 },
                                html_instructions:
                                  "Turn <b>left</b> onto <b>R. Morais Soares</b>",
                                start_location: {
                                  lng: -9.130940700000002,
                                  lat: 38.73282380000001,
                                },
                                distance: { value: 266, text: "0.3 km" },
                              },
                              {
                                travel_mode: "DRIVING",
                                polyline: { points: "yclkFp}vv@Sx@" },
                                html_instructions:
                                  "Continue straight to stay on <b>R. Morais Soares</b>",
                                maneuver: "straight",
                                duration: { text: "1 min", value: 13 },
                                distance: { text: "27 m", value: 27 },
                                start_location: {
                                  lng: -9.1338505,
                                  lat: 38.733574,
                                },
                                end_location: {
                                  lat: 38.7336686,
                                  lng: -9.134135299999999,
                                },
                              },
                              {
                                html_instructions:
                                  "Turn <b>left</b> onto <b>Av. Alm. Reis</b>",
                                start_location: {
                                  lat: 38.7336686,
                                  lng: -9.134135299999999,
                                },
                                distance: { value: 294, text: "0.3 km" },
                                maneuver: "turn-left",
                                polyline: {
                                  points:
                                    "mdlkFj_wv@IXP?p@?T@n@DT@d@?F@|@DX@d@Bd@Bb@B^@f@BZB",
                                },
                                end_location: {
                                  lat: 38.7311914,
                                  lng: -9.134476099999999,
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "1 min", value: 74 },
                              },
                              {
                                start_location: {
                                  lat: 38.7311914,
                                  lng: -9.134476099999999,
                                },
                                distance: { value: 542, text: "0.5 km" },
                                polyline: {
                                  points:
                                    "}tkkFnawv@Cp@Ct@CbAGfACj@CZEv@?J?D?@A@?H?FAF?FARCh@Cz@ElACbAGfAEpAG|AEz@EpACf@Ch@E\\?HC^",
                                },
                                duration: { value: 96, text: "2 mins" },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>R. Pascoal de Melo</b>",
                                travel_mode: "DRIVING",
                                maneuver: "turn-right",
                                end_location: {
                                  lng: -9.140677499999999,
                                  lat: 38.7317382,
                                },
                              },
                              {
                                html_instructions:
                                  "At the roundabout, take the <b>2nd</b> exit onto <b>Av. Casal Ribeiro</b>",
                                polyline: {
                                  points:
                                    "kxkkFfhxv@A@A@A?A@?@A@A@?@A@?@?@A@?@AB?B?@?B?@?B?@@B?@?@@D@@@B?@@@@@@@@?@@@@@?@@@?B?AVEHs@~AO\\k@nAQd@Uj@O^e@`ACBUd@Q`@KPg@`Ag@|@ADMT",
                                },
                                travel_mode: "DRIVING",
                                duration: { text: "2 mins", value: 100 },
                                distance: { value: 404, text: "0.4 km" },
                                start_location: {
                                  lng: -9.140677499999999,
                                  lat: 38.7317382,
                                },
                                end_location: {
                                  lng: -9.1444337,
                                  lat: 38.7335126,
                                },
                                maneuver: "roundabout-right",
                              },
                              {
                                distance: { text: "92 m", value: 92 },
                                end_location: {
                                  lng: -9.1448416,
                                  lat: 38.734123,
                                },
                                travel_mode: "DRIVING",
                                start_location: {
                                  lat: 38.7335126,
                                  lng: -9.1444337,
                                },
                                maneuver: "turn-right",
                                polyline: {
                                  points:
                                    "mclkFt_yv@EAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?BAF?B?D",
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                duration: { text: "1 min", value: 36 },
                              },
                              {
                                polyline: { points: "gglkFfbyv@[FWDqCf@A?cAL" },
                                distance: { value: 152, text: "0.2 km" },
                                travel_mode: "DRIVING",
                                end_location: {
                                  lng: -9.1451804,
                                  lat: 38.7354649,
                                },
                                html_instructions:
                                  "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b>",
                                start_location: {
                                  lng: -9.1448416,
                                  lat: 38.734123,
                                },
                                duration: { text: "1 min", value: 38 },
                                maneuver: "turn-right",
                              },
                              {
                                maneuver: "turn-right",
                                html_instructions:
                                  'Turn <b>right</b> to stay on <b>Av. da República</b>/<wbr/><b>Av. República</b><div style="font-size:0.9em">Destination will be on the right</div>',
                                end_location: {
                                  lng: -9.1451447,
                                  lat: 38.7359366,
                                },
                                polyline: {
                                  points: "solkFjdyv@IGKGEAA?AAA?SBi@F",
                                },
                                start_location: {
                                  lng: -9.1451804,
                                  lat: 38.7354649,
                                },
                                distance: { text: "55 m", value: 55 },
                                travel_mode: "DRIVING",
                                duration: { value: 15, text: "1 min" },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  rules: [],
                  destination: {
                    geohash: "eyckrgsv30",
                    type: "corporate-branch",
                    coordinates: { longitude: -9.145036, latitude: 38.7359506 },
                    address: "Av. da República 18, 1050-191 Lisboa, Portugal",
                    id: "NrSFyTBixEIf1JmBf05T.0",
                  },
                  origin: {
                    geohash: "eycs24xtzc",
                    address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                    coordinates: {
                      longitude: -9.1299888,
                      latitude: 38.7305463,
                    },
                  },
                  car: {
                    co2_p_km: 0.18365418684303766,
                    year: 2010,
                    make: "Audi",
                    seat: 3,
                    model: "A3",
                    type: "carbon-emitting",
                  },
                  owner: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
                  preferences: {
                    verified_passengers_only: null,
                    female_passengers_only: null,
                  },
                  status: "completed",
                  co2Amount: 0.8404015589937404,
                  createdAt: 1702589068293,
                  completedAt: 1702592855766,
                  skin: null,
                  passengersArray: [
                    {
                      corporate: null,
                      location: {
                        address:
                          "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                        geohash: "eycs24xtzc",
                        coordinates: {
                          latitude: 38.7305463,
                          longitude: -9.1299888,
                        },
                      },
                      co2Amount: 0.4214863588047714,
                      name: "Nik",
                      seat: 0,
                      wallet_id: null,
                      username: "nikhil",
                      avatar:
                        "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_jumper_brown_Avatarz_brown464.png",
                      credits: 18,
                      id: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
                    },
                    {
                      corporate: null,
                      username: null,
                      location: {
                        coordinates: {
                          longitude: -9.129832556674573,
                          latitude: 38.730743408203125,
                        },
                        address:
                          "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                        geohash: "eycs24xygm",
                      },
                      wallet_id: null,
                      avatar: null,
                      status: "picked-up",
                      id: "ATscMiDcscTqZitr120PAhorx5D3",
                      credits: 9,
                      name: "F. Taveira",
                      seat: 3,
                      co2Amount: 0.41891520018896894,
                    },
                  ],
                },
                type: "carpool_passenger",
                corporate: null,
              },
              {
                corporate: null,
                username: null,
                location: {
                  coordinates: {
                    longitude: -9.129832556674573,
                    latitude: 38.730743408203125,
                  },
                  address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                  geohash: "eycs24xygm",
                },
                wallet_id: null,
                avatar: null,
                status: "picked-up",
                id: "ATscMiDcscTqZitr120PAhorx5D3",
                credits: 9,
                name: "F. Taveira",
                seat: 3,
                co2Amount: 0.41891520018896894,
              },
            ],
          },
          type: "carpool_passenger",
          corporate: null,
        },
      },
      rules: [],
      destination: {
        geohash: "eyckrgsv30",
        type: "corporate-branch",
        coordinates: { longitude: -9.145036, latitude: 38.7359506 },
        address: "Av. da República 18, 1050-191 Lisboa, Portugal",
        id: "NrSFyTBixEIf1JmBf05T.0",
      },
      origin: {
        geohash: "eycs24xtzc",
        address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
        coordinates: { longitude: -9.1299888, latitude: 38.7305463 },
      },
      car: {
        co2_p_km: 0.18365418684303766,
        year: 2010,
        make: "Audi",
        seat: 3,
        model: "A3",
        type: "carbon-emitting",
      },
      owner: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
      preferences: {
        verified_passengers_only: null,
        female_passengers_only: null,
      },
      status: "completed",
      co2Amount: 0.8404015589937404,
      createdAt: 1702589068293,
      completedAt: 1702592855766,
      skin: null,
    },
    company: Companies.Company1,
    team: Teams.TeamA,
    userId: "user_id",
    directions: {
      geocoded_waypoints: [
        {
          geocoder_status: "OK",
          place_id: "ChIJq3xQuZYzGQ0RcQsgGU0xD54",
          types: ["premise"],
        },
        {
          place_id: "ChIJe2N7vJYzGQ0RR9iXjvxvpbc",
          types: ["street_address"],
          geocoder_status: "OK",
        },
        {
          geocoder_status: "OK",
          place_id: "ChIJkz-Ae6AzGQ0RdB9kIWyzb00",
          types: ["establishment", "point_of_interest", "real_estate_agency"],
        },
      ],
      status: "OK",
      routes: [
        {
          copyrights: "Map data ©2023 Google",
          overview_polyline: {
            points:
              "krkkFnevv@?s@JcABc@Is@CQIBU?SCWIYxBo@zEM`A[@yEL]rBi@bDW~AQdAKb@WbB]rAbA?dAF`CHhCLfADZBCp@GxBKrBIdBAT]bJY|HMxBE`@C@EFER@TLRFDD?AVEHcA|B}@tBe@jAi@dA{AzCi@bAMTEAMEMCW?OBURQZGh@gEt@cALUOKC}@J",
          },
          warnings: [],
          waypoint_order: [0],
          summary: "R. Neves Ferreira",
          bounds: {
            southwest: { lng: -9.1451804, lat: 38.7307037 },
            northeast: { lat: 38.7359366, lng: -9.128815399999999 },
          },
          legs: [
            {
              traffic_speed_entry: [],
              distance: { value: 14, text: "14 m" },
              duration: { value: 4, text: "1 min" },
              steps: [
                {
                  travel_mode: "DRIVING",
                  duration: { value: 4, text: "1 min" },
                  end_location: { lng: -9.1298339, lat: 38.7307811 },
                  html_instructions:
                    "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                  start_location: { lat: 38.7307779, lng: -9.1299961 },
                  polyline: { points: "krkkFnevv@?]?C" },
                  distance: { value: 14, text: "14 m" },
                },
              ],
              start_address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
              end_address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
              start_location: { lat: 38.7307779, lng: -9.1299961 },
              end_location: { lat: 38.7307811, lng: -9.1298339 },
              via_waypoint: [],
            },
            {
              end_location: { lat: 38.7359366, lng: -9.1451447 },
              duration: { value: 577, text: "10 mins" },
              via_waypoint: [],
              distance: { text: "2.3 km", value: 2281 },
              start_location: { lat: 38.7307811, lng: -9.1298339 },
              start_address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
              end_address: "Av. da República 18, 1050-191 Lisboa, Portugal",
              traffic_speed_entry: [],
              steps: [
                {
                  end_location: { lng: -9.1288743, lat: 38.7307747 },
                  distance: { value: 85, text: "85 m" },
                  start_location: { lng: -9.1298339, lat: 38.7307811 },
                  travel_mode: "DRIVING",
                  polyline: { points: "krkkFldvv@?K?EBO@EBU@W@U@MAIGi@CQ" },
                  duration: { value: 24, text: "1 min" },
                  html_instructions:
                    "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                },
                {
                  maneuver: "turn-left",
                  duration: { value: 17, text: "1 min" },
                  polyline: { points: "irkkFl~uv@IBI?K?IAIAKEKC" },
                  travel_mode: "DRIVING",
                  html_instructions:
                    "Turn <b>left</b> onto <b>R. Dr. Lacerda e Almeida</b>",
                  start_location: { lat: 38.7307747, lng: -9.1288743 },
                  end_location: { lng: -9.128815399999999, lat: 38.7311533 },
                  distance: { text: "43 m", value: 43 },
                },
                {
                  duration: { value: 41, text: "1 min" },
                  end_location: { lat: 38.7315862, lng: -9.130856399999999 },
                  polyline: { points: "utkkFb~uv@If@CTG\\C\\CNM`AG\\UjBIn@CP" },
                  travel_mode: "DRIVING",
                  distance: { value: 183, text: "0.2 km" },
                  start_location: { lng: -9.128815399999999, lat: 38.7311533 },
                  html_instructions:
                    "Turn <b>left</b> onto <b>R. Jacinto Nunes</b>",
                  maneuver: "turn-left",
                },
                {
                  duration: { value: 42, text: "1 min" },
                  start_location: { lat: 38.7315862, lng: -9.130856399999999 },
                  distance: { value: 138, text: "0.1 km" },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Calçada Poço dos Mouros</b>",
                  polyline: { points: "mwkkFzjvv@G?S@S?]?Y@mCJ" },
                  travel_mode: "DRIVING",
                  maneuver: "turn-right",
                  end_location: {
                    lng: -9.130940700000002,
                    lat: 38.73282380000001,
                  },
                },
                {
                  polyline: {
                    points:
                      "c_lkFjkvv@If@CRI`@ETADIf@EXEVADETCRERIh@CNId@Kl@EVGPCPGZOfA",
                  },
                  maneuver: "turn-left",
                  travel_mode: "DRIVING",
                  end_location: { lat: 38.733574, lng: -9.1338505 },
                  duration: { text: "1 min", value: 81 },
                  html_instructions:
                    "Turn <b>left</b> onto <b>R. Morais Soares</b>",
                  start_location: {
                    lng: -9.130940700000002,
                    lat: 38.73282380000001,
                  },
                  distance: { value: 266, text: "0.3 km" },
                },
                {
                  travel_mode: "DRIVING",
                  polyline: { points: "yclkFp}vv@Sx@" },
                  html_instructions:
                    "Continue straight to stay on <b>R. Morais Soares</b>",
                  maneuver: "straight",
                  duration: { text: "1 min", value: 13 },
                  distance: { text: "27 m", value: 27 },
                  start_location: { lng: -9.1338505, lat: 38.733574 },
                  end_location: { lat: 38.7336686, lng: -9.134135299999999 },
                },
                {
                  html_instructions:
                    "Turn <b>left</b> onto <b>Av. Alm. Reis</b>",
                  start_location: { lat: 38.7336686, lng: -9.134135299999999 },
                  distance: { value: 294, text: "0.3 km" },
                  maneuver: "turn-left",
                  polyline: {
                    points:
                      "mdlkFj_wv@IXP?p@?T@n@DT@d@?F@|@DX@d@Bd@Bb@B^@f@BZB",
                  },
                  end_location: { lat: 38.7311914, lng: -9.134476099999999 },
                  travel_mode: "DRIVING",
                  duration: { text: "1 min", value: 74 },
                },
                {
                  start_location: { lat: 38.7311914, lng: -9.134476099999999 },
                  distance: { value: 542, text: "0.5 km" },
                  polyline: {
                    points:
                      "}tkkFnawv@Cp@Ct@CbAGfACj@CZEv@?J?D?@A@?H?FAF?FARCh@Cz@ElACbAGfAEpAG|AEz@EpACf@Ch@E\\?HC^",
                  },
                  duration: { value: 96, text: "2 mins" },
                  html_instructions:
                    "Turn <b>right</b> onto <b>R. Pascoal de Melo</b>",
                  travel_mode: "DRIVING",
                  maneuver: "turn-right",
                  end_location: { lng: -9.140677499999999, lat: 38.7317382 },
                },
                {
                  html_instructions:
                    "At the roundabout, take the <b>2nd</b> exit onto <b>Av. Casal Ribeiro</b>",
                  polyline: {
                    points:
                      "kxkkFfhxv@A@A@A?A@?@A@A@?@A@?@?@A@?@AB?B?@?B?@?B?@@B?@?@@D@@@B?@@@@@@@@?@@@@@?@@@?B?AVEHs@~AO\\k@nAQd@Uj@O^e@`ACBUd@Q`@KPg@`Ag@|@ADMT",
                  },
                  travel_mode: "DRIVING",
                  duration: { text: "2 mins", value: 100 },
                  distance: { value: 404, text: "0.4 km" },
                  start_location: { lng: -9.140677499999999, lat: 38.7317382 },
                  end_location: { lng: -9.1444337, lat: 38.7335126 },
                  maneuver: "roundabout-right",
                },
                {
                  distance: { text: "92 m", value: 92 },
                  end_location: { lng: -9.1448416, lat: 38.734123 },
                  travel_mode: "DRIVING",
                  start_location: { lat: 38.7335126, lng: -9.1444337 },
                  maneuver: "turn-right",
                  polyline: {
                    points:
                      "mclkFt_yv@EAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?BAF?B?D",
                  },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                  duration: { text: "1 min", value: 36 },
                },
                {
                  polyline: { points: "gglkFfbyv@[FWDqCf@A?cAL" },
                  distance: { value: 152, text: "0.2 km" },
                  travel_mode: "DRIVING",
                  end_location: { lng: -9.1451804, lat: 38.7354649 },
                  html_instructions:
                    "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b>",
                  start_location: { lng: -9.1448416, lat: 38.734123 },
                  duration: { text: "1 min", value: 38 },
                  maneuver: "turn-right",
                },
                {
                  maneuver: "turn-right",
                  html_instructions:
                    'Turn <b>right</b> to stay on <b>Av. da República</b>/<wbr/><b>Av. República</b><div style="font-size:0.9em">Destination will be on the right</div>',
                  end_location: { lng: -9.1451447, lat: 38.7359366 },
                  polyline: { points: "solkFjdyv@IGKGEAA?AAA?SBi@F" },
                  start_location: { lng: -9.1451804, lat: 38.7354649 },
                  distance: { text: "55 m", value: 55 },
                  travel_mode: "DRIVING",
                  duration: { value: 15, text: "1 min" },
                },
              ],
            },
          ],
        },
      ],
    },
    passengers: {
      hATZMj8GpVZUMPZRs4FogRpnWDZ2: {
        userId: "user_id",
        name: "Nik",
        username: "nikhil",
        location: {
          address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
          geohash: "eycs24xtzc",
          coordinates: { latitude: 38.7305463, longitude: -9.1299888 },
        },
        credits: 18,
        co2Amount: 0.4214863588047714,
        avatar:
          "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_jumper_brown_Avatarz_brown464.png",
        seat: 0,
        id: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
        wallet_id: null,
        carpool: {
          id: "8297410931707",
          directions: {
            geocoded_waypoints: [
              {
                geocoder_status: "OK",
                place_id: "ChIJq3xQuZYzGQ0RcQsgGU0xD54",
                types: ["premise"],
              },
              {
                place_id: "ChIJe2N7vJYzGQ0RR9iXjvxvpbc",
                types: ["street_address"],
                geocoder_status: "OK",
              },
              {
                geocoder_status: "OK",
                place_id: "ChIJkz-Ae6AzGQ0RdB9kIWyzb00",
                types: [
                  "establishment",
                  "point_of_interest",
                  "real_estate_agency",
                ],
              },
            ],
            status: "OK",
            routes: [
              {
                copyrights: "Map data ©2023 Google",
                overview_polyline: {
                  points:
                    "krkkFnevv@?s@JcABc@Is@CQIBU?SCWIYxBo@zEM`A[@yEL]rBi@bDW~AQdAKb@WbB]rAbA?dAF`CHhCLfADZBCp@GxBKrBIdBAT]bJY|HMxBE`@C@EFER@TLRFDD?AVEHcA|B}@tBe@jAi@dA{AzCi@bAMTEAMEMCW?OBURQZGh@gEt@cALUOKC}@J",
                },
                warnings: [],
                waypoint_order: [0],
                summary: "R. Neves Ferreira",
                bounds: {
                  southwest: { lng: -9.1451804, lat: 38.7307037 },
                  northeast: { lat: 38.7359366, lng: -9.128815399999999 },
                },
                legs: [
                  {
                    traffic_speed_entry: [],
                    distance: { value: 14, text: "14 m" },
                    duration: { value: 4, text: "1 min" },
                    steps: [
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 4, text: "1 min" },
                        end_location: { lng: -9.1298339, lat: 38.7307811 },
                        html_instructions:
                          "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                        start_location: { lat: 38.7307779, lng: -9.1299961 },
                        polyline: { points: "krkkFnevv@?]?C" },
                        distance: { value: 14, text: "14 m" },
                      },
                    ],
                    start_address:
                      "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                    end_address:
                      "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                    start_location: { lat: 38.7307779, lng: -9.1299961 },
                    end_location: { lat: 38.7307811, lng: -9.1298339 },
                    via_waypoint: [],
                  },
                  {
                    end_location: { lat: 38.7359366, lng: -9.1451447 },
                    duration: { value: 577, text: "10 mins" },
                    via_waypoint: [],
                    distance: { text: "2.3 km", value: 2281 },
                    start_location: { lat: 38.7307811, lng: -9.1298339 },
                    start_address:
                      "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                    end_address:
                      "Av. da República 18, 1050-191 Lisboa, Portugal",
                    traffic_speed_entry: [],
                    steps: [
                      {
                        end_location: { lng: -9.1288743, lat: 38.7307747 },
                        distance: { value: 85, text: "85 m" },
                        start_location: { lng: -9.1298339, lat: 38.7307811 },
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "krkkFldvv@?K?EBO@EBU@W@U@MAIGi@CQ",
                        },
                        duration: { value: 24, text: "1 min" },
                        html_instructions:
                          "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                      },
                      {
                        maneuver: "turn-left",
                        duration: { value: 17, text: "1 min" },
                        polyline: { points: "irkkFl~uv@IBI?K?IAIAKEKC" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>left</b> onto <b>R. Dr. Lacerda e Almeida</b>",
                        start_location: { lat: 38.7307747, lng: -9.1288743 },
                        end_location: {
                          lng: -9.128815399999999,
                          lat: 38.7311533,
                        },
                        distance: { text: "43 m", value: 43 },
                      },
                      {
                        duration: { value: 41, text: "1 min" },
                        end_location: {
                          lat: 38.7315862,
                          lng: -9.130856399999999,
                        },
                        polyline: {
                          points: "utkkFb~uv@If@CTG\\C\\CNM`AG\\UjBIn@CP",
                        },
                        travel_mode: "DRIVING",
                        distance: { value: 183, text: "0.2 km" },
                        start_location: {
                          lng: -9.128815399999999,
                          lat: 38.7311533,
                        },
                        html_instructions:
                          "Turn <b>left</b> onto <b>R. Jacinto Nunes</b>",
                        maneuver: "turn-left",
                      },
                      {
                        duration: { value: 42, text: "1 min" },
                        start_location: {
                          lat: 38.7315862,
                          lng: -9.130856399999999,
                        },
                        distance: { value: 138, text: "0.1 km" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Calçada Poço dos Mouros</b>",
                        polyline: { points: "mwkkFzjvv@G?S@S?]?Y@mCJ" },
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        end_location: {
                          lng: -9.130940700000002,
                          lat: 38.73282380000001,
                        },
                      },
                      {
                        polyline: {
                          points:
                            "c_lkFjkvv@If@CRI`@ETADIf@EXEVADETCRERIh@CNId@Kl@EVGPCPGZOfA",
                        },
                        maneuver: "turn-left",
                        travel_mode: "DRIVING",
                        end_location: { lat: 38.733574, lng: -9.1338505 },
                        duration: { text: "1 min", value: 81 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>R. Morais Soares</b>",
                        start_location: {
                          lng: -9.130940700000002,
                          lat: 38.73282380000001,
                        },
                        distance: { value: 266, text: "0.3 km" },
                      },
                      {
                        travel_mode: "DRIVING",
                        polyline: { points: "yclkFp}vv@Sx@" },
                        html_instructions:
                          "Continue straight to stay on <b>R. Morais Soares</b>",
                        maneuver: "straight",
                        duration: { text: "1 min", value: 13 },
                        distance: { text: "27 m", value: 27 },
                        start_location: { lng: -9.1338505, lat: 38.733574 },
                        end_location: {
                          lat: 38.7336686,
                          lng: -9.134135299999999,
                        },
                      },
                      {
                        html_instructions:
                          "Turn <b>left</b> onto <b>Av. Alm. Reis</b>",
                        start_location: {
                          lat: 38.7336686,
                          lng: -9.134135299999999,
                        },
                        distance: { value: 294, text: "0.3 km" },
                        maneuver: "turn-left",
                        polyline: {
                          points:
                            "mdlkFj_wv@IXP?p@?T@n@DT@d@?F@|@DX@d@Bd@Bb@B^@f@BZB",
                        },
                        end_location: {
                          lat: 38.7311914,
                          lng: -9.134476099999999,
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 74 },
                      },
                      {
                        start_location: {
                          lat: 38.7311914,
                          lng: -9.134476099999999,
                        },
                        distance: { value: 542, text: "0.5 km" },
                        polyline: {
                          points:
                            "}tkkFnawv@Cp@Ct@CbAGfACj@CZEv@?J?D?@A@?H?FAF?FARCh@Cz@ElACbAGfAEpAG|AEz@EpACf@Ch@E\\?HC^",
                        },
                        duration: { value: 96, text: "2 mins" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>R. Pascoal de Melo</b>",
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        end_location: {
                          lng: -9.140677499999999,
                          lat: 38.7317382,
                        },
                      },
                      {
                        html_instructions:
                          "At the roundabout, take the <b>2nd</b> exit onto <b>Av. Casal Ribeiro</b>",
                        polyline: {
                          points:
                            "kxkkFfhxv@A@A@A?A@?@A@A@?@A@?@?@A@?@AB?B?@?B?@?B?@@B?@?@@D@@@B?@@@@@@@@?@@@@@?@@@?B?AVEHs@~AO\\k@nAQd@Uj@O^e@`ACBUd@Q`@KPg@`Ag@|@ADMT",
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "2 mins", value: 100 },
                        distance: { value: 404, text: "0.4 km" },
                        start_location: {
                          lng: -9.140677499999999,
                          lat: 38.7317382,
                        },
                        end_location: { lng: -9.1444337, lat: 38.7335126 },
                        maneuver: "roundabout-right",
                      },
                      {
                        distance: { text: "92 m", value: 92 },
                        end_location: { lng: -9.1448416, lat: 38.734123 },
                        travel_mode: "DRIVING",
                        start_location: { lat: 38.7335126, lng: -9.1444337 },
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "mclkFt_yv@EAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?BAF?B?D",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                        duration: { text: "1 min", value: 36 },
                      },
                      {
                        polyline: { points: "gglkFfbyv@[FWDqCf@A?cAL" },
                        distance: { value: 152, text: "0.2 km" },
                        travel_mode: "DRIVING",
                        end_location: { lng: -9.1451804, lat: 38.7354649 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b>",
                        start_location: { lng: -9.1448416, lat: 38.734123 },
                        duration: { text: "1 min", value: 38 },
                        maneuver: "turn-right",
                      },
                      {
                        maneuver: "turn-right",
                        html_instructions:
                          'Turn <b>right</b> to stay on <b>Av. da República</b>/<wbr/><b>Av. República</b><div style="font-size:0.9em">Destination will be on the right</div>',
                        end_location: { lng: -9.1451447, lat: 38.7359366 },
                        polyline: { points: "solkFjdyv@IGKGEAA?AAA?SBi@F" },
                        start_location: { lng: -9.1451804, lat: 38.7354649 },
                        distance: { text: "55 m", value: 55 },
                        travel_mode: "DRIVING",
                        duration: { value: 15, text: "1 min" },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          rules: [],
          destination: {
            geohash: "eyckrgsv30",
            type: "corporate-branch",
            coordinates: { longitude: -9.145036, latitude: 38.7359506 },
            address: "Av. da República 18, 1050-191 Lisboa, Portugal",
            id: "NrSFyTBixEIf1JmBf05T.0",
          },
          origin: {
            geohash: "eycs24xtzc",
            address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
            coordinates: { longitude: -9.1299888, latitude: 38.7305463 },
          },
          car: {
            co2_p_km: 0.18365418684303766,
            year: 2010,
            make: "Audi",
            seat: 3,
            model: "A3",
            type: "carbon-emitting",
          },
          owner: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
          preferences: {
            verified_passengers_only: null,
            female_passengers_only: null,
          },
          status: "completed",
          co2Amount: 0.8404015589937404,
          createdAt: 1702589068293,
          completedAt: 1702592855766,
          skin: null,
          passengersArray: [
            {
              corporate: null,
              location: {
                address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                geohash: "eycs24xtzc",
                coordinates: { latitude: 38.7305463, longitude: -9.1299888 },
              },
              co2Amount: 0.4214863588047714,
              name: "Nik",
              seat: 0,
              wallet_id: null,
              username: "nikhil",
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_jumper_brown_Avatarz_brown464.png",
              credits: 18,
              id: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
            },
            {
              corporate: null,
              username: null,
              location: {
                coordinates: {
                  longitude: -9.129832556674573,
                  latitude: 38.730743408203125,
                },
                address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                geohash: "eycs24xygm",
              },
              wallet_id: null,
              avatar: null,
              status: "picked-up",
              id: "ATscMiDcscTqZitr120PAhorx5D3",
              credits: 9,
              name: "F. Taveira",
              seat: 3,
              co2Amount: 0.41891520018896894,
            },
          ],
        },
        type: "carpool_passenger",
        corporate: null,
      },
      ATscMiDcscTqZitr120PAhorx5D3: {
        userId: "user_id",
        name: "F. Taveira",
        username: null,
        location: {
          coordinates: {
            longitude: -9.129832556674573,
            latitude: 38.730743408203125,
          },
          address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
          geohash: "eycs24xygm",
        },
        credits: 9,
        co2Amount: 0.41891520018896894,
        avatar: null,
        seat: 3,
        id: "ATscMiDcscTqZitr120PAhorx5D3",
        wallet_id: null,
        status: "picked-up",
        carpool: {
          id: "8297410931707",
          directions: {
            geocoded_waypoints: [
              {
                geocoder_status: "OK",
                place_id: "ChIJq3xQuZYzGQ0RcQsgGU0xD54",
                types: ["premise"],
              },
              {
                place_id: "ChIJe2N7vJYzGQ0RR9iXjvxvpbc",
                types: ["street_address"],
                geocoder_status: "OK",
              },
              {
                geocoder_status: "OK",
                place_id: "ChIJkz-Ae6AzGQ0RdB9kIWyzb00",
                types: [
                  "establishment",
                  "point_of_interest",
                  "real_estate_agency",
                ],
              },
            ],
            status: "OK",
            routes: [
              {
                copyrights: "Map data ©2023 Google",
                overview_polyline: {
                  points:
                    "krkkFnevv@?s@JcABc@Is@CQIBU?SCWIYxBo@zEM`A[@yEL]rBi@bDW~AQdAKb@WbB]rAbA?dAF`CHhCLfADZBCp@GxBKrBIdBAT]bJY|HMxBE`@C@EFER@TLRFDD?AVEHcA|B}@tBe@jAi@dA{AzCi@bAMTEAMEMCW?OBURQZGh@gEt@cALUOKC}@J",
                },
                warnings: [],
                waypoint_order: [0],
                summary: "R. Neves Ferreira",
                bounds: {
                  southwest: { lng: -9.1451804, lat: 38.7307037 },
                  northeast: { lat: 38.7359366, lng: -9.128815399999999 },
                },
                legs: [
                  {
                    traffic_speed_entry: [],
                    distance: { value: 14, text: "14 m" },
                    duration: { value: 4, text: "1 min" },
                    steps: [
                      {
                        travel_mode: "DRIVING",
                        duration: { value: 4, text: "1 min" },
                        end_location: { lng: -9.1298339, lat: 38.7307811 },
                        html_instructions:
                          "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                        start_location: { lat: 38.7307779, lng: -9.1299961 },
                        polyline: { points: "krkkFnevv@?]?C" },
                        distance: { value: 14, text: "14 m" },
                      },
                    ],
                    start_address:
                      "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                    end_address:
                      "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                    start_location: { lat: 38.7307779, lng: -9.1299961 },
                    end_location: { lat: 38.7307811, lng: -9.1298339 },
                    via_waypoint: [],
                  },
                  {
                    end_location: { lat: 38.7359366, lng: -9.1451447 },
                    duration: { value: 577, text: "10 mins" },
                    via_waypoint: [],
                    distance: { text: "2.3 km", value: 2281 },
                    start_location: { lat: 38.7307811, lng: -9.1298339 },
                    start_address:
                      "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                    end_address:
                      "Av. da República 18, 1050-191 Lisboa, Portugal",
                    traffic_speed_entry: [],
                    steps: [
                      {
                        end_location: { lng: -9.1288743, lat: 38.7307747 },
                        distance: { value: 85, text: "85 m" },
                        start_location: { lng: -9.1298339, lat: 38.7307811 },
                        travel_mode: "DRIVING",
                        polyline: {
                          points: "krkkFldvv@?K?EBO@EBU@W@U@MAIGi@CQ",
                        },
                        duration: { value: 24, text: "1 min" },
                        html_instructions:
                          "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                      },
                      {
                        maneuver: "turn-left",
                        duration: { value: 17, text: "1 min" },
                        polyline: { points: "irkkFl~uv@IBI?K?IAIAKEKC" },
                        travel_mode: "DRIVING",
                        html_instructions:
                          "Turn <b>left</b> onto <b>R. Dr. Lacerda e Almeida</b>",
                        start_location: { lat: 38.7307747, lng: -9.1288743 },
                        end_location: {
                          lng: -9.128815399999999,
                          lat: 38.7311533,
                        },
                        distance: { text: "43 m", value: 43 },
                      },
                      {
                        duration: { value: 41, text: "1 min" },
                        end_location: {
                          lat: 38.7315862,
                          lng: -9.130856399999999,
                        },
                        polyline: {
                          points: "utkkFb~uv@If@CTG\\C\\CNM`AG\\UjBIn@CP",
                        },
                        travel_mode: "DRIVING",
                        distance: { value: 183, text: "0.2 km" },
                        start_location: {
                          lng: -9.128815399999999,
                          lat: 38.7311533,
                        },
                        html_instructions:
                          "Turn <b>left</b> onto <b>R. Jacinto Nunes</b>",
                        maneuver: "turn-left",
                      },
                      {
                        duration: { value: 42, text: "1 min" },
                        start_location: {
                          lat: 38.7315862,
                          lng: -9.130856399999999,
                        },
                        distance: { value: 138, text: "0.1 km" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Calçada Poço dos Mouros</b>",
                        polyline: { points: "mwkkFzjvv@G?S@S?]?Y@mCJ" },
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        end_location: {
                          lng: -9.130940700000002,
                          lat: 38.73282380000001,
                        },
                      },
                      {
                        polyline: {
                          points:
                            "c_lkFjkvv@If@CRI`@ETADIf@EXEVADETCRERIh@CNId@Kl@EVGPCPGZOfA",
                        },
                        maneuver: "turn-left",
                        travel_mode: "DRIVING",
                        end_location: { lat: 38.733574, lng: -9.1338505 },
                        duration: { text: "1 min", value: 81 },
                        html_instructions:
                          "Turn <b>left</b> onto <b>R. Morais Soares</b>",
                        start_location: {
                          lng: -9.130940700000002,
                          lat: 38.73282380000001,
                        },
                        distance: { value: 266, text: "0.3 km" },
                      },
                      {
                        travel_mode: "DRIVING",
                        polyline: { points: "yclkFp}vv@Sx@" },
                        html_instructions:
                          "Continue straight to stay on <b>R. Morais Soares</b>",
                        maneuver: "straight",
                        duration: { text: "1 min", value: 13 },
                        distance: { text: "27 m", value: 27 },
                        start_location: { lng: -9.1338505, lat: 38.733574 },
                        end_location: {
                          lat: 38.7336686,
                          lng: -9.134135299999999,
                        },
                      },
                      {
                        html_instructions:
                          "Turn <b>left</b> onto <b>Av. Alm. Reis</b>",
                        start_location: {
                          lat: 38.7336686,
                          lng: -9.134135299999999,
                        },
                        distance: { value: 294, text: "0.3 km" },
                        maneuver: "turn-left",
                        polyline: {
                          points:
                            "mdlkFj_wv@IXP?p@?T@n@DT@d@?F@|@DX@d@Bd@Bb@B^@f@BZB",
                        },
                        end_location: {
                          lat: 38.7311914,
                          lng: -9.134476099999999,
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "1 min", value: 74 },
                      },
                      {
                        start_location: {
                          lat: 38.7311914,
                          lng: -9.134476099999999,
                        },
                        distance: { value: 542, text: "0.5 km" },
                        polyline: {
                          points:
                            "}tkkFnawv@Cp@Ct@CbAGfACj@CZEv@?J?D?@A@?H?FAF?FARCh@Cz@ElACbAGfAEpAG|AEz@EpACf@Ch@E\\?HC^",
                        },
                        duration: { value: 96, text: "2 mins" },
                        html_instructions:
                          "Turn <b>right</b> onto <b>R. Pascoal de Melo</b>",
                        travel_mode: "DRIVING",
                        maneuver: "turn-right",
                        end_location: {
                          lng: -9.140677499999999,
                          lat: 38.7317382,
                        },
                      },
                      {
                        html_instructions:
                          "At the roundabout, take the <b>2nd</b> exit onto <b>Av. Casal Ribeiro</b>",
                        polyline: {
                          points:
                            "kxkkFfhxv@A@A@A?A@?@A@A@?@A@?@?@A@?@AB?B?@?B?@?B?@@B?@?@@D@@@B?@@@@@@@@?@@@@@?@@@?B?AVEHs@~AO\\k@nAQd@Uj@O^e@`ACBUd@Q`@KPg@`Ag@|@ADMT",
                        },
                        travel_mode: "DRIVING",
                        duration: { text: "2 mins", value: 100 },
                        distance: { value: 404, text: "0.4 km" },
                        start_location: {
                          lng: -9.140677499999999,
                          lat: 38.7317382,
                        },
                        end_location: { lng: -9.1444337, lat: 38.7335126 },
                        maneuver: "roundabout-right",
                      },
                      {
                        distance: { text: "92 m", value: 92 },
                        end_location: { lng: -9.1448416, lat: 38.734123 },
                        travel_mode: "DRIVING",
                        start_location: { lat: 38.7335126, lng: -9.1444337 },
                        maneuver: "turn-right",
                        polyline: {
                          points:
                            "mclkFt_yv@EAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?BAF?B?D",
                        },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                        duration: { text: "1 min", value: 36 },
                      },
                      {
                        polyline: { points: "gglkFfbyv@[FWDqCf@A?cAL" },
                        distance: { value: 152, text: "0.2 km" },
                        travel_mode: "DRIVING",
                        end_location: { lng: -9.1451804, lat: 38.7354649 },
                        html_instructions:
                          "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b>",
                        start_location: { lng: -9.1448416, lat: 38.734123 },
                        duration: { text: "1 min", value: 38 },
                        maneuver: "turn-right",
                      },
                      {
                        maneuver: "turn-right",
                        html_instructions:
                          'Turn <b>right</b> to stay on <b>Av. da República</b>/<wbr/><b>Av. República</b><div style="font-size:0.9em">Destination will be on the right</div>',
                        end_location: { lng: -9.1451447, lat: 38.7359366 },
                        polyline: { points: "solkFjdyv@IGKGEAA?AAA?SBi@F" },
                        start_location: { lng: -9.1451804, lat: 38.7354649 },
                        distance: { text: "55 m", value: 55 },
                        travel_mode: "DRIVING",
                        duration: { value: 15, text: "1 min" },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          rules: [],
          destination: {
            geohash: "eyckrgsv30",
            type: "corporate-branch",
            coordinates: { longitude: -9.145036, latitude: 38.7359506 },
            address: "Av. da República 18, 1050-191 Lisboa, Portugal",
            id: "NrSFyTBixEIf1JmBf05T.0",
          },
          origin: {
            geohash: "eycs24xtzc",
            address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
            coordinates: { longitude: -9.1299888, latitude: 38.7305463 },
          },
          car: {
            co2_p_km: 0.18365418684303766,
            year: 2010,
            make: "Audi",
            seat: 3,
            model: "A3",
            type: "carbon-emitting",
          },
          owner: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
          preferences: {
            verified_passengers_only: null,
            female_passengers_only: null,
          },
          status: "completed",
          co2Amount: 0.8404015589937404,
          createdAt: 1702589068293,
          completedAt: 1702592855766,
          skin: null,
          passengersArray: [
            {
              userId: "user_id",
              name: "Nik",
              username: "nikhil",
              location: {
                address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                geohash: "eycs24xtzc",
                coordinates: { latitude: 38.7305463, longitude: -9.1299888 },
              },
              credits: 18,
              co2Amount: 0.4214863588047714,
              avatar:
                "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_jumper_brown_Avatarz_brown464.png",
              seat: 0,
              id: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
              wallet_id: null,
              carpool: {
                id: "8297410931707",
                directions: {
                  geocoded_waypoints: [
                    {
                      geocoder_status: "OK",
                      place_id: "ChIJq3xQuZYzGQ0RcQsgGU0xD54",
                      types: ["premise"],
                    },
                    {
                      place_id: "ChIJe2N7vJYzGQ0RR9iXjvxvpbc",
                      types: ["street_address"],
                      geocoder_status: "OK",
                    },
                    {
                      geocoder_status: "OK",
                      place_id: "ChIJkz-Ae6AzGQ0RdB9kIWyzb00",
                      types: [
                        "establishment",
                        "point_of_interest",
                        "real_estate_agency",
                      ],
                    },
                  ],
                  status: "OK",
                  routes: [
                    {
                      copyrights: "Map data ©2023 Google",
                      overview_polyline: {
                        points:
                          "krkkFnevv@?s@JcABc@Is@CQIBU?SCWIYxBo@zEM`A[@yEL]rBi@bDW~AQdAKb@WbB]rAbA?dAF`CHhCLfADZBCp@GxBKrBIdBAT]bJY|HMxBE`@C@EFER@TLRFDD?AVEHcA|B}@tBe@jAi@dA{AzCi@bAMTEAMEMCW?OBURQZGh@gEt@cALUOKC}@J",
                      },
                      warnings: [],
                      waypoint_order: [0],
                      summary: "R. Neves Ferreira",
                      bounds: {
                        southwest: { lng: -9.1451804, lat: 38.7307037 },
                        northeast: { lat: 38.7359366, lng: -9.128815399999999 },
                      },
                      legs: [
                        {
                          traffic_speed_entry: [],
                          distance: { value: 14, text: "14 m" },
                          duration: { value: 4, text: "1 min" },
                          steps: [
                            {
                              travel_mode: "DRIVING",
                              duration: { value: 4, text: "1 min" },
                              end_location: {
                                lng: -9.1298339,
                                lat: 38.7307811,
                              },
                              html_instructions:
                                "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                              start_location: {
                                lat: 38.7307779,
                                lng: -9.1299961,
                              },
                              polyline: { points: "krkkFnevv@?]?C" },
                              distance: { value: 14, text: "14 m" },
                            },
                          ],
                          start_address:
                            "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                          end_address:
                            "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                          start_location: { lat: 38.7307779, lng: -9.1299961 },
                          end_location: { lat: 38.7307811, lng: -9.1298339 },
                          via_waypoint: [],
                        },
                        {
                          end_location: { lat: 38.7359366, lng: -9.1451447 },
                          duration: { value: 577, text: "10 mins" },
                          via_waypoint: [],
                          distance: { text: "2.3 km", value: 2281 },
                          start_location: { lat: 38.7307811, lng: -9.1298339 },
                          start_address:
                            "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                          end_address:
                            "Av. da República 18, 1050-191 Lisboa, Portugal",
                          traffic_speed_entry: [],
                          steps: [
                            {
                              end_location: {
                                lng: -9.1288743,
                                lat: 38.7307747,
                              },
                              distance: { value: 85, text: "85 m" },
                              start_location: {
                                lng: -9.1298339,
                                lat: 38.7307811,
                              },
                              travel_mode: "DRIVING",
                              polyline: {
                                points: "krkkFldvv@?K?EBO@EBU@W@U@MAIGi@CQ",
                              },
                              duration: { value: 24, text: "1 min" },
                              html_instructions:
                                "Head <b>east</b> on <b>R. Neves Ferreira</b> toward <b>R. Dr. Lacerda e Almeida</b>",
                            },
                            {
                              maneuver: "turn-left",
                              duration: { value: 17, text: "1 min" },
                              polyline: { points: "irkkFl~uv@IBI?K?IAIAKEKC" },
                              travel_mode: "DRIVING",
                              html_instructions:
                                "Turn <b>left</b> onto <b>R. Dr. Lacerda e Almeida</b>",
                              start_location: {
                                lat: 38.7307747,
                                lng: -9.1288743,
                              },
                              end_location: {
                                lng: -9.128815399999999,
                                lat: 38.7311533,
                              },
                              distance: { text: "43 m", value: 43 },
                            },
                            {
                              duration: { value: 41, text: "1 min" },
                              end_location: {
                                lat: 38.7315862,
                                lng: -9.130856399999999,
                              },
                              polyline: {
                                points: "utkkFb~uv@If@CTG\\C\\CNM`AG\\UjBIn@CP",
                              },
                              travel_mode: "DRIVING",
                              distance: { value: 183, text: "0.2 km" },
                              start_location: {
                                lng: -9.128815399999999,
                                lat: 38.7311533,
                              },
                              html_instructions:
                                "Turn <b>left</b> onto <b>R. Jacinto Nunes</b>",
                              maneuver: "turn-left",
                            },
                            {
                              duration: { value: 42, text: "1 min" },
                              start_location: {
                                lat: 38.7315862,
                                lng: -9.130856399999999,
                              },
                              distance: { value: 138, text: "0.1 km" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Calçada Poço dos Mouros</b>",
                              polyline: { points: "mwkkFzjvv@G?S@S?]?Y@mCJ" },
                              travel_mode: "DRIVING",
                              maneuver: "turn-right",
                              end_location: {
                                lng: -9.130940700000002,
                                lat: 38.73282380000001,
                              },
                            },
                            {
                              polyline: {
                                points:
                                  "c_lkFjkvv@If@CRI`@ETADIf@EXEVADETCRERIh@CNId@Kl@EVGPCPGZOfA",
                              },
                              maneuver: "turn-left",
                              travel_mode: "DRIVING",
                              end_location: { lat: 38.733574, lng: -9.1338505 },
                              duration: { text: "1 min", value: 81 },
                              html_instructions:
                                "Turn <b>left</b> onto <b>R. Morais Soares</b>",
                              start_location: {
                                lng: -9.130940700000002,
                                lat: 38.73282380000001,
                              },
                              distance: { value: 266, text: "0.3 km" },
                            },
                            {
                              travel_mode: "DRIVING",
                              polyline: { points: "yclkFp}vv@Sx@" },
                              html_instructions:
                                "Continue straight to stay on <b>R. Morais Soares</b>",
                              maneuver: "straight",
                              duration: { text: "1 min", value: 13 },
                              distance: { text: "27 m", value: 27 },
                              start_location: {
                                lng: -9.1338505,
                                lat: 38.733574,
                              },
                              end_location: {
                                lat: 38.7336686,
                                lng: -9.134135299999999,
                              },
                            },
                            {
                              html_instructions:
                                "Turn <b>left</b> onto <b>Av. Alm. Reis</b>",
                              start_location: {
                                lat: 38.7336686,
                                lng: -9.134135299999999,
                              },
                              distance: { value: 294, text: "0.3 km" },
                              maneuver: "turn-left",
                              polyline: {
                                points:
                                  "mdlkFj_wv@IXP?p@?T@n@DT@d@?F@|@DX@d@Bd@Bb@B^@f@BZB",
                              },
                              end_location: {
                                lat: 38.7311914,
                                lng: -9.134476099999999,
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "1 min", value: 74 },
                            },
                            {
                              start_location: {
                                lat: 38.7311914,
                                lng: -9.134476099999999,
                              },
                              distance: { value: 542, text: "0.5 km" },
                              polyline: {
                                points:
                                  "}tkkFnawv@Cp@Ct@CbAGfACj@CZEv@?J?D?@A@?H?FAF?FARCh@Cz@ElACbAGfAEpAG|AEz@EpACf@Ch@E\\?HC^",
                              },
                              duration: { value: 96, text: "2 mins" },
                              html_instructions:
                                "Turn <b>right</b> onto <b>R. Pascoal de Melo</b>",
                              travel_mode: "DRIVING",
                              maneuver: "turn-right",
                              end_location: {
                                lng: -9.140677499999999,
                                lat: 38.7317382,
                              },
                            },
                            {
                              html_instructions:
                                "At the roundabout, take the <b>2nd</b> exit onto <b>Av. Casal Ribeiro</b>",
                              polyline: {
                                points:
                                  "kxkkFfhxv@A@A@A?A@?@A@A@?@A@?@?@A@?@AB?B?@?B?@?B?@@B?@?@@D@@@B?@@@@@@@@?@@@@@?@@@?B?AVEHs@~AO\\k@nAQd@Uj@O^e@`ACBUd@Q`@KPg@`Ag@|@ADMT",
                              },
                              travel_mode: "DRIVING",
                              duration: { text: "2 mins", value: 100 },
                              distance: { value: 404, text: "0.4 km" },
                              start_location: {
                                lng: -9.140677499999999,
                                lat: 38.7317382,
                              },
                              end_location: {
                                lng: -9.1444337,
                                lat: 38.7335126,
                              },
                              maneuver: "roundabout-right",
                            },
                            {
                              distance: { text: "92 m", value: 92 },
                              end_location: { lng: -9.1448416, lat: 38.734123 },
                              travel_mode: "DRIVING",
                              start_location: {
                                lat: 38.7335126,
                                lng: -9.1444337,
                              },
                              maneuver: "turn-right",
                              polyline: {
                                points:
                                  "mclkFt_yv@EAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?BAF?B?D",
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                              duration: { text: "1 min", value: 36 },
                            },
                            {
                              polyline: { points: "gglkFfbyv@[FWDqCf@A?cAL" },
                              distance: { value: 152, text: "0.2 km" },
                              travel_mode: "DRIVING",
                              end_location: {
                                lng: -9.1451804,
                                lat: 38.7354649,
                              },
                              html_instructions:
                                "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b>",
                              start_location: {
                                lng: -9.1448416,
                                lat: 38.734123,
                              },
                              duration: { text: "1 min", value: 38 },
                              maneuver: "turn-right",
                            },
                            {
                              maneuver: "turn-right",
                              html_instructions:
                                'Turn <b>right</b> to stay on <b>Av. da República</b>/<wbr/><b>Av. República</b><div style="font-size:0.9em">Destination will be on the right</div>',
                              end_location: {
                                lng: -9.1451447,
                                lat: 38.7359366,
                              },
                              polyline: {
                                points: "solkFjdyv@IGKGEAA?AAA?SBi@F",
                              },
                              start_location: {
                                lng: -9.1451804,
                                lat: 38.7354649,
                              },
                              distance: { text: "55 m", value: 55 },
                              travel_mode: "DRIVING",
                              duration: { value: 15, text: "1 min" },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                rules: [],
                destination: {
                  geohash: "eyckrgsv30",
                  type: "corporate-branch",
                  coordinates: { longitude: -9.145036, latitude: 38.7359506 },
                  address: "Av. da República 18, 1050-191 Lisboa, Portugal",
                  id: "NrSFyTBixEIf1JmBf05T.0",
                },
                origin: {
                  geohash: "eycs24xtzc",
                  address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                  coordinates: { longitude: -9.1299888, latitude: 38.7305463 },
                },
                car: {
                  co2_p_km: 0.18365418684303766,
                  year: 2010,
                  make: "Audi",
                  seat: 3,
                  model: "A3",
                  type: "carbon-emitting",
                },
                owner: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
                preferences: {
                  verified_passengers_only: null,
                  female_passengers_only: null,
                },
                status: "completed",
                co2Amount: 0.8404015589937404,
                createdAt: 1702589068293,
                completedAt: 1702592855766,
                skin: null,
                passengersArray: [
                  {
                    corporate: null,
                    location: {
                      address:
                        "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
                      geohash: "eycs24xtzc",
                      coordinates: {
                        latitude: 38.7305463,
                        longitude: -9.1299888,
                      },
                    },
                    co2Amount: 0.4214863588047714,
                    name: "Nik",
                    seat: 0,
                    wallet_id: null,
                    username: "nikhil",
                    avatar:
                      "https://storage.googleapis.com/thecarbongames.appspot.com/compressed_avatars%2Fmale_jumper_brown_Avatarz_brown464.png",
                    credits: 18,
                    id: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
                  },
                  {
                    corporate: null,
                    username: null,
                    location: {
                      coordinates: {
                        longitude: -9.129832556674573,
                        latitude: 38.730743408203125,
                      },
                      address:
                        "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                      geohash: "eycs24xygm",
                    },
                    wallet_id: null,
                    avatar: null,
                    status: "picked-up",
                    id: "ATscMiDcscTqZitr120PAhorx5D3",
                    credits: 9,
                    name: "F. Taveira",
                    seat: 3,
                    co2Amount: 0.41891520018896894,
                  },
                ],
              },
              type: "carpool_passenger",
              corporate: null,
            },
            {
              corporate: null,
              username: null,
              location: {
                coordinates: {
                  longitude: -9.129832556674573,
                  latitude: 38.730743408203125,
                },
                address: "R. Neves Ferreira 13B, 1170-070 Lisboa, Portugal",
                geohash: "eycs24xygm",
              },
              wallet_id: null,
              avatar: null,
              status: "picked-up",
              id: "ATscMiDcscTqZitr120PAhorx5D3",
              credits: 9,
              name: "F. Taveira",
              seat: 3,
              co2Amount: 0.41891520018896894,
            },
          ],
        },
        type: "carpool_passenger",
        corporate: null,
      },
    },
    skin: null,
    car: {
      co2_p_km: 0.18365418684303766,
      year: 2010,
      make: "Audi",
      seat: 3,
      model: "A3",
      type: "carbon-emitting",
    },
    owner: "hATZMj8GpVZUMPZRs4FogRpnWDZ2",
    status: "completed",
    id: "8297410931707",
    origin: {
      geohash: "eycs24xtzc",
      address: "R. Neves Ferreira 13D, 1170-070 Lisboa, Portugal",
      coordinates: { longitude: -9.1299888, latitude: 38.7305463 },
    },
    destination: {
      geohash: "eyckrgsv30",
      type: "corporate-branch",
      coordinates: { longitude: -9.145036, latitude: 38.7359506 },
      address: "Av. da República 18, 1050-191 Lisboa, Portugal",
      id: "NrSFyTBixEIf1JmBf05T.0",
    },
    credits: 0,
    createdAt: 1702589068293,
    co2Amount: 0.8404015589937404,
    autoUpdateTime: 0,
    ownerRating: "",
    type: "carpool",
    rules: [],
    preferences: {
      verified_passengers_only: null,
      female_passengers_only: null,
    },
    completedAt: 1702592855766,
  },
];
