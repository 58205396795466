import { useContext } from "react";
import { useMutation } from "react-query";
import firebase from "firebase/compat/app";

import { GamificationContext } from "../providers/GamificationProvider";
import { db } from "../config/firebase";
import { useAuth } from "../providers/AuthProvider";

const gamificationDataColRef = db.collection("gamification_data");

const useGamification = () => {
  const context = useContext(GamificationContext);
  const { user } = useAuth();

  const switchCards = useMutation(
    async (params: { id: string; status: "active" | "inactive" }[]) => {
      const batch = db.batch();

      const docRef = gamificationDataColRef.doc(user?.uid || "-");

      for (let i = 0; i < params.length; i++) {
        const param = params[i];
        const existingCard = context.userGamificationData?.cards.find(
          (c) => c.id === param.id
        );

        if (existingCard) {
          batch.set(
            docRef,
            { cards: firebase.firestore.FieldValue.arrayRemove(existingCard) },
            { merge: true }
          );
        }

        const updatedCard = {
          id: param.id,
          exp_at: existingCard?.exp_at || -1,
          progress: existingCard?.progress || 0,
          status: param.status,
        };

        batch.set(
          docRef,
          { cards: firebase.firestore.FieldValue.arrayUnion(updatedCard) },
          { merge: true }
        );
      }

      await batch.commit();
    }
  );

  return {
    ...context,
    switchCards,
  };
};

export default useGamification;
