import colors from "../../assets/colors"
import ModalHeader from "../common/ModalHeader"
import Icons from "../../assets/Icons"
import ImageIcon from "../common/ImageIcon"
import CustomButton from "../common/CustomButton"
import { useEffect, useMemo, useState } from "react"
import ControlledInput from "../common/ControlledInput"
import { SubmitHandler, useForm } from "react-hook-form"
import ControlledDatePicker from "../common/ControlledDatePicker"
import ControlledGoogleAutoComplete from "../common/ControlledGoogleAutoComplete"
import ControlledPicker from "../common/ControlledPicker"
import ControlledSlider from "../common/ControlledSlider"
import { useToggle, useUpdateEffect } from "react-use"
import CustomCheckbox from "../common/CustomCheckbox"
import ControlledFileInput from "../common/ControlledFileInput"


import moment from "moment"
import useModal from "../../hooks/useModal"

import { cleanObject, convertToId } from "../../common/Utils"
import useEvent from "../../hooks/useEvent"
import Alert, { AlertModalProps } from "./Alert"
import { Event } from "../../types/Event"
import useStripe from "../../hooks/useStripe"
import { useMap } from "../common/MapComponent"
import { da } from "date-fns/locale"

export type AddEventModalProps = {
    event: Event
}

type FieldValues = {
    date: Date
    endDate: Date
    name: string
    description: string
    location: {
        coordinates: {
            latitude: number,
            longitude: number
        },
        address: string,
        geohash: string
    }
    code: string
    status: 'active' | 'inactive' | 'archived'
    access: 'locked' | 'open'
    partner: string
    venue: string
    goals: {
        co2: number
    }
    eventLogo: string
    images?: {
        image1: string | null
        image2: string | null
        image3: string | null
        image4: string | null
        image5: string | null
    }
    expectedTurnout: { min: number, max: number }
}

const SubmitEvent = ({ hide, props }: { hide?: () => void, props?: AddEventModalProps }) => {

    const [map] = useMap()

    const { show: showAlertModal, ModalComp: AlertModal } = useModal<AlertModalProps>(({ hide, props }) => <Alert hide={hide} props={props!} />)

    const { addEvent, deleteEvent, updateEvent } = useEvent()
    const { createCheckoutSession, redirectToStripeCustomerPortal } = useStripe()
    const { register, control, formState: { errors, dirtyFields }, watch, setValue, handleSubmit, } = useForm<FieldValues>({
        defaultValues: {
            ...props?.event,
            date: new Date(props?.event?.date || Date.now()),
            endDate: new Date(props?.event?.endDate || Date.now())
        }
    })
    const formValues = watch()

    const [addLogo, toggleLogoInput] = useToggle(!!props?.event?.eventLogo)
    const [useLogoColors, toggleUseLogoColor] = useToggle(false)
    const [addImages, toggleImageInput] = useToggle(!!props?.event?.images && Object.values(props.event.images).reduce((t, c) => !!t || !!c, false))
    const [addEventBounties, toggleEventBounties] = useToggle(false)

    useUpdateEffect(() => {
        if (formValues.expectedTurnout) setValue('goals.co2', formValues.expectedTurnout.min + formValues.expectedTurnout.max)
    }, [JSON.stringify(formValues.expectedTurnout)])

    const onSubmit: SubmitHandler<FieldValues> = (data) => {

        console.log({
            ...data,
            date: data.date.getTime(),
            endDate: data.endDate.getTime(),
        })
        if (!props?.event) {
            addEvent.mutate(
                {
                    ...data,
                    date: (data.date || new Date()).getTime(),
                    endDate: (data.endDate || new Date()).getTime(),
                    endTime: moment(data.endDate).format('hh:mm'),
                    startTime: moment(data.date).format('hh:mm'),
                    slug: convertToId(data.name)
                },
                {
                    onSuccess: () => {
                        hide?.()
                        map?.moveCamera({
                            center: { lat: data.location.coordinates.latitude, lng: data.location.coordinates.longitude },
                            zoom: 16
                        })
                    }
                })
        } else {
            const obj = {
                ...data,
                date: (data.date || new Date()).getTime(),
                endDate: (data.endDate || new Date()).getTime(),
                endTime: moment(data.endDate).format('hh:mm'),
                startTime: moment(data.date).format('hh:mm'),
                slug: convertToId(data.name)
            }
            console.log(cleanObject(obj))
            updateEvent.mutate({ data: cleanObject(obj), event_id: props.event.id, }, {
                onSuccess: () => {
                    hide?.()
                    map?.moveCamera({
                        center: { lat: data.location.coordinates.latitude, lng: data.location.coordinates.longitude },
                        zoom: 16
                    })
                }
            })
        }
    }

    const onDelete = () => {
        showAlertModal({
            title: 'Delete',
            subTitle: 'Are you sure you want to delete?',
            buttons: [{ text: 'Cancel' }, { text: 'Yes', onClick: () => (deleteEvent(props!.event.id), hide?.()) }]
        })
    }

    return (
        <div style={{ margin: -30, minWidth: 500 }}>
            {AlertModal}
            {hide && <ModalHeader style={{ padding: 20 }} hide={hide} title="Submit Event" subtitle="Register to get carpool insights on your event." />}

            {hide && <div style={{ height: 1, background: colors.BLACK_20 }} />}

            <div style={{ overflowY: 'scroll', flex: 1, display: 'flex', flexDirection: 'column', paddingTop: 20, maxHeight: '75vh', padding: 30 }} >

                {hide && <p style={{ fontWeight: '500', fontSize: 14, marginBottom: 10 }} >Event Details</p>}
                <p style={{ fontSize: 10, opacity: .7, marginBottom: 20, textAlign: 'start' }} >Let’s have accurate details about the event. This is used to populate our custom event descriptions to give credibility to events on our platform.</p>

                <ControlledInput
                    label='Name'
                    inputProps={{ placeholder: 'Add event name here...' }}
                    containerStyle={{ marginBottom: 15 }}
                    formProps={{ register, errors, name: 'name', options: { required: true } }} />

                <ControlledInput
                    label='Description'
                    multiline
                    inputProps={{ placeholder: 'Add event description here...', style: { height: 100 } }}
                    containerStyle={{ marginBottom: 15 }}
                    formProps={{ register, errors, name: 'description', options: { required: true } }} />

                <div style={{ display: 'flex', marginBottom: 15 }} >
                    <ControlledDatePicker
                        containerStyle={{ flex: 1 }}
                        label='Start Date'
                        formProps={{ errors, name: 'date', options: { required: true }, control }} />
                    <div style={{ width: 10 }} />
                    <ControlledDatePicker
                        containerStyle={{ flex: 1 }}
                        label='End Date'
                        formProps={{ errors, name: 'endDate', options: { required: true }, control }} />
                </div>

                <ControlledInput
                    label='Venue'
                    containerStyle={{ marginBottom: 15 }}
                    inputProps={{ placeholder: 'Ex. Secret gardens' }}
                    formProps={{ register, errors, name: 'venue', options: { required: true } }} />

                <ControlledGoogleAutoComplete
                    label='Location'
                    containerStyle={{ marginBottom: 15 }}
                    formProps={{ errors, name: 'location', options: { required: true }, control }} />

                {/* <div style={{ display: 'flex', marginBottom: 15 }} >
                    <ControlledPicker
                        label='Status'
                        containerStyle={{ flex: 1 }}
                        options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }, ...(props?.event ? [{ label: 'Archived', value: 'archived' }] : [])]}
                        formProps={{ errors, name: 'status', options: { required: true }, control }} />
                    <div style={{ width: 10 }} />
                    <ControlledPicker
                        label='Locked'
                        containerStyle={{ flex: 1 }}
                        options={[{ label: 'Locked', value: 'locked' }, { label: 'Open', value: 'open' }]}
                        formProps={{ errors, name: 'access', options: { required: true }, control }} />
                </div> */}

                {formValues.access == 'locked' && <ControlledInput
                    label='Code'
                    inputProps={{ placeholder: '* * * * * *' }}
                    containerStyle={{ marginBottom: 15 }}
                    formProps={{ register, errors, name: 'code', options: { required: true } }} />}

                <ControlledPicker
                    label='Expected Turnout'
                    containerStyle={{ flex: 1, marginBottom: 15 }}
                    options={expectedTurnouts}
                    formProps={{ errors, name: 'expectedTurnout', options: { required: true }, control }} />

                {formValues.expectedTurnout && <div >

                    <p style={{ color: colors.BLACK_100, fontSize: 12, fontWeight: '500', marginBottom: 6, textAlign: 'start' }} >Expected CO2 Saving Goal</p>
                    <p style={{ fontSize: 10, opacity: .5, maxWidth: 400, textAlign: 'start' }} >The average carpool user will save 3 kgs in CO2 by Carpooling for this event. We’d love to know what your goals are with respect to CO2 savings.</p>

                    <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginTop: 25, marginBottom: 25, }}>
                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 60 }} >
                            <img src={Icons.hexagon_2} style={{ width: 60, position: 'absolute', zIndex: -1 }} />
                            <p style={{}} >{formValues.goals?.co2}</p>
                            <p style={{ fontSize: 10 }} >Kgs</p>
                        </div>

                        <div style={{ flex: 1 }} >
                            <ControlledSlider
                                min={formValues.expectedTurnout.min * 2}
                                max={formValues.expectedTurnout.max * 2}
                                formProps={{ errors, name: 'goals.co2', options: { required: true }, control }} />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15, alignItems: 'center' }} >
                                <p style={{ fontSize: 13, fontWeight: '500' }} >{formValues.expectedTurnout.min * 2} Kgs</p>
                                <p style={{ fontSize: 13, backgroundColor: colors.BLACK_10, color: colors.BLACK_30, padding: 10, borderRadius: 10 }} >Expected CO2 Savings</p>
                                <p style={{ fontSize: 13, fontWeight: '500' }} >{formValues.expectedTurnout.max * 2} Kgs</p>
                            </div>
                        </div>

                    </div>

                </div>}

                <div>
                    <CustomCheckbox containerStyle={{ marginBottom: 15 }} value={addLogo} label='Add Organization Logo' onClick={toggleLogoInput} />
                    {addLogo && <ControlledFileInput containerStyle={{ marginBottom: 15 }} formProps={{ errors, name: 'eventLogo', options: { required: false }, control }} />}
                </div>

                {addLogo && <CustomCheckbox containerStyle={{ marginBottom: 15 }} value={useLogoColors} label='Create skin colors from logo' onClick={toggleUseLogoColor} />}

                <div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }} >
                        <CustomCheckbox containerStyle={{ marginRight: 6 }} value={addImages} label='Add Images' onClick={toggleImageInput} />
                        {/* <p style={{ color: colors.BLACK_100, fontSize: 12, fontWeight: '500', }} >Add Images</p> */}
                    </div>
                    {addImages && <div style={{ display: 'flex', marginBottom: 15, gap: 15 }} >
                        <ControlledFileInput containerStyle={{ flex: 1 }} formProps={{ errors, name: 'images.image1', options: { required: false }, control }} />
                        <ControlledFileInput containerStyle={{ flex: 1 }} formProps={{ errors, name: 'images.image2', options: { required: false }, control }} />
                        <ControlledFileInput containerStyle={{ flex: 1 }} formProps={{ errors, name: 'images.image3', options: { required: false }, control }} />
                        <ControlledFileInput containerStyle={{ flex: 1 }} formProps={{ errors, name: 'images.image4', options: { required: false }, control }} />
                        <ControlledFileInput containerStyle={{ flex: 1 }} formProps={{ errors, name: 'images.image5', options: { required: false }, control }} />
                    </div>}
                </div>

                {/* <div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15, }} >
                        <CustomCheckbox containerStyle={{ marginRight: 6 }} value={addEventBounties} onClick={toggleEventBounties} />
                        <p style={{ color: colors.BLACK_100, fontSize: 12, fontWeight: '500', }} >Add Event Bounties</p>
                        <p style={{ color: colors.BLACK_60, fontSize: 8, paddingTop: 3, paddingLeft: 2 }} >(Add incentives for highest ranking attendees on the leaderboard)</p>
                    </div>
                    {addEventBounties && <ControlledInput
                        inputProps={{ placeholder: 'Bounty for Venue 1' }}
                        formProps={{ register, errors, name: 'venue', options: { required: true } }} />}
                </div> */}

                {!!props?.event && props.event.payment_status !== 'active' && <div style={{ borderColor: colors.RED }} className="mt-5 border border-dashed rounded relative p-4" >

                    <p style={{ color: colors.RED }} className="font-semibold text-sm bg-white text-start absolute top-[-10px] left-4" >PAYMENT STATUS - {props.event.payment_status === 'active' ? 'ACTIVE' : 'INACTIVE'}</p>
                    <CustomButton
                        secondary
                        loading={redirectToStripeCustomerPortal.isLoading || createCheckoutSession.isLoading}
                        style={{ borderRadius: 4, background: colors.PRIMARY + '20' }}
                        onClick={() => createCheckoutSession.mutate({ event_id: props!.event!.id })}
                        label={'Proceed with Payment'} />
                </div>}

                {formValues.name && <p className="bg-gray-100 rounded px-4 py-2 text-center text-xs" >{`https://dashboard.thecarbongames.com/${convertToId(formValues.name)}`}</p>}

            </div>

            <div style={{ height: 1, background: colors.BLACK_20 }} />

            <div style={{ display: 'flex', alignItems: 'center', padding: 20, gap: 20 }} >
                <CustomButton
                    onClick={handleSubmit(onSubmit)}
                    loading={addEvent.isLoading || updateEvent.isLoading}
                    style={{ flex: 1 }}
                    label={props?.event ? "Update" : "Create"} />
                {props?.event && <CustomButton
                    onClick={onDelete}
                    style={{ background: colors.RED, paddingLeft: 40, paddingRight: 40 }}
                    label="Delete" />}
            </div>


        </div>
    )
}

export default SubmitEvent

const expectedTurnouts = [
    { label: '0-50', value: { max: 50, min: 0 } },
    { label: '50-100', value: { max: 100, min: 50 } },
    { label: '100-200', value: { max: 200, min: 100 } },
    { label: '200-500', value: { max: 500, min: 200 } },
    { label: '500-1000', value: { max: 1000, min: 500 } },
    { label: '1000+', value: { max: 10000, min: 1000 } }
]