import * as Checkbox from '@radix-ui/react-checkbox';
import { CSSProperties } from 'react';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import colors from '../../assets/colors';

type Props = {
    value?: boolean
    onClick?: () => void
    label?: string,
    containerStyle? : CSSProperties
}
const CustomCheckbox = ({ value, onClick, label, containerStyle }: Props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 7,...containerStyle }} >
            <Checkbox.Root style={{ borderWidth: 0, margin: 0, paddingBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent' }} checked={value || 'indeterminate'} onClick={onClick} >
                <Checkbox.Indicator>
                    {value ? <ImCheckboxChecked style={{ color: colors.PRIMARY, fontSize: 18, marginTop: 3 }} /> : <ImCheckboxUnchecked style={{ fontSize: 18, marginTop: 3, color: colors.BLACK_40 }} />}
                </Checkbox.Indicator>
            </Checkbox.Root>
            {label && <p onClick={onClick} style={{fontSize: 14, cursor : 'pointer', marginTop : 4}} >{label}</p>}
        </div>
    )
}

export default CustomCheckbox