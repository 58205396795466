import Constants from "../common/Constants"
import CustomCheckbox from "../components/common/CustomCheckbox"
import useBonus from "../hooks/useBonus"
import useCorporate from "../hooks/useCorporate"
import Bonus from "../types/Bonus"

const Bonuses = () => {
    const { useAllBonuses } = useBonus()
    const { data: bonuses = [] } = useAllBonuses()
    const { useMyCorporate, updateCorporate } = useCorporate()
    const { data: corporate } = useMyCorporate()

    const onBonusClick = (bonus: Bonus) => {
        if (corporate?.activated_bonuses?.includes(bonus.id)) {
            updateCorporate.mutate({ activated_bonuses: corporate.activated_bonuses.filter(b => b !== bonus.id) })
        } else {
            updateCorporate.mutate({ activated_bonuses: [...(corporate?.activated_bonuses || []), bonus.id] })
        }
    }

    return (
        <div className="flex-1 bg-gray-50" >
            <div
                style={{ maxHeight: `calc(100vh - ${Constants.header_height}px)` }}
                className="grid grid-cols-4 gap-4 overflow-y-auto p-4 " >
                {bonuses.map((bonus, index) => (
                    <BonusItem
                        onClick={() => onBonusClick(bonus)}
                        selected={!!corporate?.activated_bonuses?.includes(bonus.id)}
                        key={index}
                        bonus={bonus} />
                ))}
            </div>
        </div>

    )
}

export default Bonuses

const BonusItem = ({ bonus, selected, onClick }: { bonus: Bonus, selected: boolean, onClick: () => void }) => {
    return (
        <div
        onClick={onClick}
            className={`rounded border ${selected ? 'border-emerald-600' : 'border-gray-200'} bg-white hover:bg-gray-100 cursor-pointer transition-all flex flex-col items-start p-3 relative`}>
            <p className="font-semibold text-lg text-gray-600" >{bonus.name}</p>
            <p className="text-start text-sm text-gray-500 mb-2" >{bonus.description}</p>
            {!!bonus.remark && <p className="text-xs bg-gray-100 rounded text-gray-500 mt-auto py-1 px-3 text-start" >{bonus.remark}</p>}

            <CustomCheckbox
                value={selected}
                containerStyle={{ marginTop: 0, position: 'absolute', top: 13, right: 16 }} />

        </div>
    )
}