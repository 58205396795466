
import React, { useMemo } from 'react'
import { FieldErrorsImpl, Path, RegisterOptions, Controller, Control } from "react-hook-form";
import colors from '../../assets/colors';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

type Props<FieldValues> = {
    containerStyle?: React.CSSProperties
    label: string
    inputProps?: React.InputHTMLAttributes<any>
    formProps: {
        errors: Partial<FieldErrorsImpl<{ [x: string]: any; }>>
        name: Path<FieldValues>
        options?: Partial<RegisterOptions>
        control: Control<any, any>
    }
}

const ControlledDatePicker = <FieldValues,>({ label, formProps, containerStyle, inputProps }: Props<FieldValues>) => {

    const errorMessage = useMemo(() => {
        const error = formProps.errors[formProps.name]
        if (!error) return ''
        if (error?.message) return error.message + ''
        if (error.type === 'pattern') return `Please enter a valid ${label}`
        return `${label} is required`
    }, [formProps.errors,formProps.name, label])


    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', alignSelf: 'stretch', ...containerStyle }} >
            <p style={{ fontSize: 14, marginBottom: 6 }} >{label}</p>
            <Controller
                name={formProps.name}
                control={formProps.control}
                render={({ field: { onChange, onBlur, value } }) => (
                    <DatePicker
                        showTimeSelect
                        selected={value}
                        value={format(value || new Date(), 'dd-MM-yyyy, hh:mm a')}
                        dateFormat={'dd-MM-yyyy, hh:mm a'}
                        customInput={
                            <input
                                {...inputProps}
                                
                                style={{ backgroundColor: colors.BLUE + '12', borderRadius: 5, padding: 12, borderWidth: 0, minWidth: 200, paddingLeft: 16, paddingRight: 16, alignSelf: 'stretch', width : '100%' }} />
                        }
                        onChange={onChange} />
                )} />

            {errorMessage && <p style={{ color: 'red', fontSize: 12, marginTop: 5 }} >{errorMessage}</p>}

        </div>
    )
}

export default ControlledDatePicker