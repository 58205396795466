import { createGlobalState, } from "react-use"
import { db } from "../config/firebase"
import { useNearWallet } from "./useNearWallet"
import { Event } from "../types/Event"
import { Bounty } from "../types/Bounty"
import { useMutation, useQuery } from "react-query"
import useEvent from "./useEvent"
import { useAuth } from "../providers/AuthProvider"
import { cleanObject } from "../common/Utils"

const bountyColRef = db.collection('bounties')

const useBounty = () => {

    const { addBounty } = useNearWallet()
    const { updateEvent } = useEvent()
    const {user} = useAuth()

    return {
        createBounty: useMutation(async (data: Bounty) => {
            console.log(data)
            const doc = await bountyColRef.add(cleanObject(JSON.parse(JSON.stringify(data))))
            // await Promise.all(data.event_bounties.map(event_bounty=> updateEvent({event_id :event_bounty.event_id, data : {bounty : firebase.firestore.FieldValue.increment(event_bounty.amount) as any }})))
            await addBounty(data)
        }),

        useMyBounties: ()=>useQuery({
            queryKey : ['my-bounties'],
            queryFn : async ()=> bountyColRef.where('createdBy', '==', user?.uid||'').get(),
            select : snapshot=>snapshot.docs.map(doc=>new Bounty({id : doc.id,...doc.data() }))
        })
    }
}


export default useBounty