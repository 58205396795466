export enum Roles {
  Admin = "admin",
  AccountManager = "account_manager",
  ClientAdmin = "client_admin",
  ClientAccountManager = "client_account_manager",
  ClientCarpoolingUser = "client_carpooling_user",
}
/*
  admin@gmail.com
  accountmanager@gmail.com
  clientadmin@gmail.com
  clientaccountmanager@gmail.com
  clientcarpoolinguser@gmail.com

  password: test123
*/
