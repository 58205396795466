import { CSSProperties } from "react"
import { MdListAlt } from "react-icons/md"

type Props = {
    message : string,
    retry? : ()=>void,
    containerStyle? : CSSProperties
}

const Empty = ({message, retry, containerStyle}: Props)=>{
    return (
        <div style={{display : 'flex', flexDirection : 'column', alignItems : 'center', padding : 50,...containerStyle}} >
            <MdListAlt style={{ fontSize : 50, opacity : .3, }} />
            <p style={{fontSize : 30, opacity : .3}} >{message}</p>
        </div>
    )
}

export default Empty