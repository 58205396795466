import { CSSProperties } from "react"
import colors from "../../assets/colors"
import ImageIcon from "./ImageIcon"

type Props = {
    hide: () => void
    title: string
    subtitle: string,
    style? : CSSProperties
}

const ModalHeader = ({ hide, title, subtitle,style }: Props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', ...style }} >
            <div>
                <p style={{ fontWeight: '600', fontSize: 18, marginBottom: 10 }} >{title}</p>
                <p style={{ opacity: .8, fontSize: 12 }} >{subtitle}</p>
            </div>
            <div style={{flex : 1}} />
            <ImageIcon
                onClick={hide}
                containerStyle={{ padding: 6, background: colors.BLACK_100 + '20', borderRadius: 50 }}
                size={16}
                name='arrow_left_circle' />
        </div>
    )
}

export default ModalHeader