import React, { useMemo } from "react";
import { CorporateTeam } from "../types/CorporateTeam";
import { useQuery } from "react-query";
import { useAuth } from "./AuthProvider";
import { db } from "../config/firebase";

interface CorporateContextInterface {
  corporateTeams: CorporateTeam[];
  myCorporateTeam?: CorporateTeam;
}

const teamsColRef = db.collection("teams");

export const CorporateContext = React.createContext(
  {} as CorporateContextInterface
);

export const CorporateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { userData } = useAuth();

  const { data: corporateTeams = [] } = useQuery({
    queryKey: ["teams", userData?.corporate_team],
    enabled: !!userData?.corporate_team,
    queryFn: async () => {
      const snapshot = await teamsColRef
        .where("corporate_id", "==", userData?.corporate || "")
        .get();
      return snapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as CorporateTeam)
      );
    },
  });

  const myCorporateTeam = useMemo(
    () => corporateTeams.find((t) => t.id === userData?.corporate_team),
    [userData, corporateTeams]
  );
  return (
    <CorporateContext.Provider value={{ corporateTeams, myCorporateTeam }}>
      {children}
    </CorporateContext.Provider>
  );
};
