const colors = {
    PRIMARY: "#4FA58D",
    PRIMARY50 : '#C9E9D5',
    SOFT_GREEN: "#cdded6",
    LIGHT_GREEN: "#C9DFD5",
    ORANGE : '#F5B689',
    GREEN: "#93c052",
    DARK_TURQUOISE: "rgb(27,68,133)",
    BLACK_BRAND: "#181021",
    BLACK_80: "#181021",
    BLACK_70:"#2a292b",
    BLACK_60: "#747079",
    BLACK_40: "#ccc",
    BLACK_20: '#ECECEC',
    BLACK_10: '#2022260A',
    BLACK_05: '#f5f5f5',
    BLACK_30 : '#ddd',
    GRAY_LIGHT: "#ededee",
    WHITE: "#ffffff",
    GRAY: "#dcdbdd",
    GRAY_DARK: "#97949a",
    BLACK_100: "#000000",
    RED: "#C55E5E",
    YELLOW: "#ffee00",
    TRANSPARENT: "#00000000",
    BLUE : '#aaaaaa',
    DARK_YELLOW : '#BA9D26',
    TURQUOISE : '#23BABF'
  }

  export default colors