import { useParams } from "react-router-dom"
import MapComponent from "../components/common/MapComponent"
import useCarpool from "../hooks/useCarpool"
import { Fragment, useMemo } from "react"
import Card from "../components/common/Card"
import Avatar from "../components/common/Avatar"
import colors from "../assets/colors"
import moment from "moment"

const ActiveCarpoolDetail = () => {

    const { id } = useParams()

    const { useRealtimeCarpool, useCarpoolPreview } = useCarpool()
    const { data: carpool } = useRealtimeCarpool(id)
    const { data: carpoolPreview, isLoading } = useCarpoolPreview(id, carpool)

    const carpoolMarker = useMemo(() => carpool ? ({
        location: carpool?.current_location || carpool?.origin,
        data: carpool
    }) : null, [carpool])

    const destinationMarker = useMemo(() => carpool ? ({
        location: carpool?.destination,
        type: 'event' as const
    }) : null, [carpool])

    if (!carpool || !carpoolPreview || carpoolPreview?.status === 'completed' || carpoolPreview?.status === 'cancelled') return (
        <div className="flex flex-1 justify-center items-center" >
           {isLoading && <p className="text-4xl text-gray-400 text-center mt-20 italic" >Loading...</p>}
           {(carpoolPreview?.status === 'completed' || carpoolPreview?.status === 'cancelled') && <p className="text-4xl text-gray-400 text-center mt-20 italic" >Carpool is {carpoolPreview.status}</p>}
           {!isLoading && !carpoolPreview && <p className="text-4xl text-gray-400 text-center mt-20 italic" >Not Found</p>}
        </div>
    )

    return (
        <div className="flex-1" >

            <MapComponent
                polylines={carpoolPreview?.polyline}
                markers={[
                    carpoolMarker!,
                    destinationMarker!
                ]} />

            <Card style={{ position: 'absolute', top: 130, left: 50, padding: 20 }} >
                <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center' }} >
                    {carpoolPreview.passengersArray.reverse().map((passenger, index) => (
                        <Avatar
                            key={index}
                            style={{ marginRight: -5 }}
                            size={30} name={passenger.name} id={passenger.id} url={passenger.avatar} />
                    ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: 10, gap: 5 }} >
                    <p style={{ fontWeight: '600', fontSize: 16 }} >{carpoolPreview.destination.address?.split(',')[0]}</p>
                    <p style={{ color: colors.BLACK_70, fontSize: 14 }} >From Home via {carpoolPreview.car?.make} {carpoolPreview.car?.model}</p>
                    <p style={{ color: colors.BLACK_70, fontSize: 14, marginBottom: 10 }} >{moment(carpoolPreview.createdAt).format('Do MMMM, YYYY. HH:mm a')}</p>

                    <div style={{ display: 'inline', color: colors.BLACK_70, fontSize: 14 }} >
                        <p style={{ display: 'inline' }} >Participants : </p>
                        {carpoolPreview.passengersArray.map((passenger, index, arr) => (
                            <Fragment key={index} >
                                <p style={{ color: colors.BLACK_70, display: 'inline' }} >{index === 0 ? '' : arr.length - 1 === index ? ' and ' : ', '}</p>
                                <p
                                    key={index}
                                    style={{
                                        display: 'inline',
                                        color: passenger.isOwner ? colors.TURQUOISE : colors.DARK_YELLOW
                                    }}>{passenger.username || passenger.name}</p>
                            </Fragment>

                        ))}
                    </div>
                </div>
            </Card>
        </div>

    )
}

export default ActiveCarpoolDetail