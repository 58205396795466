import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { useEffect } from "react";

const LoginWithOkta = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { loginWithOkta } = useAuth();

  useEffect(() => {
    loginWithOkta.mutate(
      { token: params.token || "" },
      { onSuccess: () => navigate("/", { replace: true }) }
    );
  }, [navigate, params.token]);

  return (
    <div className="flex flex-1 justify-center items-center h-screen">
      {loginWithOkta.isLoading && (
        <p className="text-4xl text-gray-400 text-center italic">
          Logging in with okta...
        </p>
      )}
      {!!loginWithOkta.error && (
        <p className="text-4xl text-gray-400 text-center italic">
          ERROR
        </p>
      )}
    </div>
  );
};

export default LoginWithOkta;
