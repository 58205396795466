import { CSSProperties } from "@stitches/react"
import { Oval } from "react-loading-icons"
import colors from "../../assets/colors"

type Props = {
    style?: CSSProperties,
    color?: string,
    size? : number
}

const ActivityIndicator = ({ style, color, size=20 }: Props) => {
    return <Oval
        style={{ alignSelf: 'center', height : size, width : size, ...style }}
        stroke={color || colors.PRIMARY}
        fillOpacity={1}
        stopOpacity={1}
        strokeWidth={2}
        
        speed={1} />
}

export default ActivityIndicator