import CustomButton from "../common/CustomButton"

export type AlertModalProps = {
    buttons: {
        text: string,
        onClick?: () => void
    }[]
    title: string
    subTitle: string
}

const Alert = ({ hide, props }: { hide: () => void, props: AlertModalProps }) => {

    const onClick = (buttonOnClick?: () => void) => {
        hide()
        buttonOnClick?.()
    }

    return (
        <div style={{ minWidth: 300, margin: -10 }} >
            <p style={{ fontWeight: 'bold' }} >{props.title}</p>
            <p style={{ fontSize: 12, opacity: .6 }} >{props.subTitle}</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: 15, paddingTop: 15 }} >
                {props.buttons.map((button, index) => (
                    <CustomButton
                        key={index}
                        onClick={() => onClick(button.onClick)}
                        style={{ flex: 1 }}
                        label={button.text} />
                ))}
            </div>
        </div>
    )
}

export default Alert