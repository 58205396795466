import Icons from "../../assets/Icons"

const StatCardShoulder = ({ hueRotation, label }: {
  hueRotation?: number,
  label: string
}) => {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0,height : 60,width : 95, flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
      <img
      alt='to be removed'
        style={{ height: 110, filter: `hue-rotate(${hueRotation}deg)`, position : 'absolute', top : 0, left: 0, right : 0, bottom : 0 }}
        src={Icons.stat_shoulder} />
        
      {label.split(' ').map((str, index) => <p key={index} style={{ textAlign: 'center', fontSize: 14,  fontWeight: 'bold', zIndex : 0 }} >{str}</p>)}
    </div>
  )
}

export default StatCardShoulder