import { useMemo, useState } from "react";
import Constants from "../common/Constants";
import Tabs from "../components/common/Tabs";
import colors from "../assets/colors";
import { capitalizeFirstLetter } from "../common/Utils";
import useGamification from "../hooks/useGamification";

const Gamification = () => {
  const { template } = useGamification();
  const [category, setCategory] = useState("all");

  const categoryOptions = useMemo(
    () =>
      [{ label: "All", value: "all" }].concat(
        template?.card_categories.map((category: any) => ({
          label: category.name,
          value: category.id,
        })) || []
      ),
    [template?.card_categories]
  );

  return (
    <div className="flex-1">
      <div
        className="p-12 overflow-y-auto"
        style={{ maxHeight: `calc(100vh - ${Constants.header_height}px)` }}
      >
        <div className="flex mb-6 items-center gap-4">
          <p className="text-2xl font-bold">Gamification</p>

          <div style={{ flex: 1 }} />

          <Tabs
            tabStyle={{ padding: "9px 16px", minWidth: 100 }}
            hidable={false}
            value={category}
            onSelect={(v) => setCategory(v as any)}
            options={categoryOptions}
          />
        </div>

        <div className="flex gap-4 flex-col">
          {template?.card_categories
            .filter((c) => category === "all" || category === c.id)
            .map((c: any) => (
              <Category categoryId={c.id} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Gamification;

const Category = ({ categoryId }: { categoryId: string }) => {
  const { template } = useGamification();
  const category = useMemo(
    () => template?.card_categories.find((c: any) => c.id === categoryId),
    [template, categoryId]
  );

  const cards = useMemo(
    () => template?.cards.filter((c: any) => c.category === categoryId),
    [template, categoryId]
  );

  return (
    <div className="flex flex-col items-start">
      <p className="font-medium pl-4">{category?.long_name}</p>
      <p className="text-gray-400 pl-4">{category?.description}</p>
      <div
        style={{
          marginLeft: -48,
          marginRight: -48,
          width: `100vw`,
        }}
        className="flex overflow-x-auto p-4 px-12 gap-14 no-scrollbar"
      >
        {cards?.map((card: any) => (
          <Card cardId={card.id} />
        ))}
      </div>
    </div>
  );
};

const Card = ({ cardId }: { cardId: string }) => {
  const { template, userGamificationData, switchCards } = useGamification();

  const card = useMemo(
    () => template?.cards.find((c) => c.id === cardId),
    [template?.cards, cardId]
  );

  const userCardData = useMemo(
    () => userGamificationData?.cards.find((c) => c.id === cardId),
    [cardId, userGamificationData?.cards]
  );

  if (!card) return null;

  return (
    <div
      style={{ width: 390 }}
      className="border border-gray-200 rounded-2xl flex flex-col items-start p-4 shadow-md flex-shrink-0 gap-4"
    >
      <div className="flex justify-between self-stretch">
        <div className="border border-gray-200 rounded-full h-10 w-10" />
        <Switch
          value={
            (userCardData?.status || card.default_user_status) === "active"
          }
          setValue={(val) =>
            switchCards.mutate([
              { id: card.id, status: val ? "active" : "inactive" },
            ])
          }
        />
      </div>
      <p className="font-medium text-start">{card.name}</p>
      <p
        style={{ lineHeight: 1.2 }}
        className="text-gray-400 text-start mt-[-8px] text-sm"
      >
        {card.description}
      </p>
      {card.completion_threshold && card.unit_of_measurement && (
        <Progress
          value={userCardData?.progress}
          total={card.completion_threshold}
          unit={card.unit_of_measurement}
        />
      )}
    </div>
  );
};

const Switch = ({
  value,
  setValue,
}: {
  value: boolean;
  setValue: (val: boolean) => void;
}) => {
  return (
    <div
      onClick={() => setValue(!value)}
      style={{ background: value ? colors.PRIMARY : colors.BLACK_20 }}
      className="w-[28px] h-[16px] rounded-full flex flex-col justify-center p-[2px] cursor-pointer shadow-inner transition-all"
    >
      <div
        style={{
          background: value ? colors.PRIMARY : colors.BLACK_20,
          borderColor: value ? colors.BLACK_05 : colors.WHITE,
          marginLeft: value ? 12 : 0,
        }}
        className="w-[12px] h-[12px] rounded-full border-4 shadow-sm transition-all"
      />
    </div>
  );
};

const Progress = ({
  value = 0,
  total,
  unit,
}: {
  value?: number;
  total: number;
  unit: string;
}) => {
  return (
    <div className="flex self-stretch items-center gap-2">
      <div className="flex-1 bg-gray-100 h-[6px] rounded-full">
        <div
          style={{
            width: `${(value * 100) / total}%`,
            background: colors.PRIMARY,
          }}
          className="h-[6px] rounded-full"
        />
      </div>
      <p className="text-gray-400 text-sm">
        {value} / {total} {capitalizeFirstLetter(unit)}s
      </p>
    </div>
  );
};
