import { FC } from "react";
import { StatItem } from "../../../../pages/Statistics";
import { Carpool } from "../../../../types/Carpool";

interface Props {
  filteredCarpools: Carpool[];
}
const CarpoolsFromWork: FC<Props> = ({ filteredCarpools }): JSX.Element => {
  return (
    <StatItem
      background="#D3E9B9"
      title="Carpools From Work"
      subTitle=""
      unit="Carpool"
      icon="car_side"
      decimalPoints={0}
      value={filteredCarpools?.length}
    />
  );
};

export default CarpoolsFromWork;
